import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldAutocomplete, FieldNumber, FieldSelect, FieldText, FieldTextArea, Seperator } from '@dex/bubl-dash';
import CopyPaste from '../../../elements/CopyPaste/CopyPaste';

const LabTestsRaceReadyEquipment: React.FC<any> = ( props: any ) => {

    const { form, data, session } = props;

    const activity = data.activity;

    return useMemo( () => (

        <Row gutter={20} edge={true}>

            <Col col={{ xs: 24 }}>

                <CopyPaste
                    form={form}
                    name={"equipment"}
                />

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <Seperator
                    heading={"Measurement Tools"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 12 }}>

                        <FieldAutocomplete
                            form={form}
                            label={"Gas Analysis Unit"}
                            name={"equipment.gasAnalysisUnit"}
                            defaultValue={form.defaults?.equipment?.gasAnalysisUnit}
                            // options={[
                            //     { value: "Cortex Metalyzer 3B", label: "Cortex Metalyzer 3B" },
                            //     { value: "Vo2master", label: "Vo2master" },
                            //     { value: "Not Used", label: "Not Used" },
                            // ]}
                            preload
                            group={"equipment.gasAnalysisUnit"}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldAutocomplete
                            form={form}
                            label={"Spirometer"}
                            name={"equipment.spirometer"}
                            defaultValue={form.defaults?.equipment?.spirometer}
                            // options={[
                            //     { value: "Piko-6", label: "Piko-6" },
                            //     { value: "Cortex Metalyzer 3B", label: "Cortex Metalyzer 3B" },
                            //     { value: "Not Used", label: "Not Used" },
                            // ]}
                            preload
                            group={"equipment.spirometer"}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldAutocomplete
                            form={form}
                            label={"Lactate Analyzer"}
                            name={"equipment.lactateAnalyzer"}
                            defaultValue={form.values?.equipment?.lactateAnalyzer}
                            options={[
                                { value: "The Edge", label: "The Edge" },
                                { value: "Eaglenos", label: "Eaglenos" },
                            ]}
                            wrapClass={"manual-input"}
                            group='equipment.lactateAnalyzer'
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldAutocomplete
                            form={form}
                            label={"Glucose Analyzer"}
                            name={"equipment.glucoseAnalyzer"}
                            defaultValue={form.values?.equipment?.glucoseAnalyzer}
                            options={[
                                { value: "General Electric (GE)", label: "General Electric (GE)" },
                                { value: "AccuCheck", label: "AccuCheck" },
                            ]}
                            wrapClass={"manual-input"}
                            group='equipment.glucoseAnalyzer'
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldAutocomplete
                            form={form}
                            label={"Fat Caliper"}
                            name={"equipment.fatCaliper"}
                            defaultValue={form.defaults?.equipment?.fatCaliper}
                            // options={[
                            //     { value: "Harpendan", label: "Harpendan" },
                            // ]}
                            preload
                            group={"equipment.fatCaliper"}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            name={"equipment.moxySensorA"}
                            label={"Moxy Sensor A"}
                            defaultValue={form.defaults?.equipment?.moxySensorA}
                            options={[
                                { value: "3602", label: "3602" },
                                { value: "3603", label: "3603" }
                            ]}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            name={"equipment.moxyLocationA"}
                            label={"Moxy Location A"}
                            defaultValue={form.defaults?.equipment?.moxyLocationA}
                            options={[
                                { value: "Right Vastus Lateralis", label: "Right Vastus Lateralis" },
                                { value: "Left Vastus Lateralis", label: "Left Vastus Lateralis" },
                                { value: "Right Calf", label: "Right Calf" },
                                { value: "Left Calf", label: "Left Calf" },
                            ]}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            name={"equipment.moxySensorB"}
                            label={"Moxy Sensor B"}
                            defaultValue={form.defaults?.equipment?.moxySensorB}
                            options={[
                                { value: "3602", label: "3602" },
                                { value: "3603", label: "3603" }
                            ]}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            name={"equipment.moxyLocationB"}
                            label={"Moxy Location B"}
                            defaultValue={form.defaults?.equipment?.moxyLocationB}
                            options={[
                                { value: "Right Vastus Lateralis", label: "Right Vastus Lateralis" },
                                { value: "Left Vastus Lateralis", label: "Left Vastus Lateralis" },
                                { value: "Right Calf", label: "Right Calf" },
                                { value: "Left Calf", label: "Left Calf" },
                            ]}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Equipment Comments"}
                            name={"equipment.comments"}
                            defaultValue={form.defaults?.equipment?.comments}
                            rows={4}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <Seperator
                    heading={"Apparatus Setup"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldSelect
                            form={form}
                            label={"Test Apparatus"}
                            name={"equipment.testApparatus"}
                            defaultValue={form.defaults?.equipment?.testApparatus}
                            options={activity === "Cycling" ? [
                                { value: "Tacx Neo Smart Bike", label: "Tacx Neo Smart Bike" },
                                { value: "Magene Trainer", label: "Magene Trainer" },
                                { value: "Wahoo Kickr A", label: "Wahoo Kickr A" },
                                { value: "Wahoo Kickr B", label: "Wahoo Kickr B" },
                            ] : [
                                { value: "True TC400 Treadmill", label: "True TC400 Treadmill" },
                            ]}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    {activity === "Cycling" &&
                        <>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldAutocomplete
                                    form={form}
                                    label={"Bike Used"}
                                    name={"equipment.bikeUsed"}
                                    defaultValue={form.defaults?.equipment?.bikeUsed}
                                    group='equipment.bikeUsed'
                                    options={[
                                        { value: "Tacx Neo Smart Bike", label: "Tacx Neo Smart Bike" }
                                    ]}
                                    wrapClass={"manual-input"}
                                    preload
                                />

                            </Col>

                            <Col col={{ xs: 12 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Saddle Height"}
                                    name={"equipment.saddleHeight"}
                                    defaultValue={form.defaults?.equipment?.saddleHeight}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 12 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Saddle Setback"}
                                    name={"equipment.saddleSetback"}
                                    defaultValue={form.defaults?.equipment?.saddleSetback}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 12 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Handlebar Height"}
                                    name={"equipment.handlebarHeight"}
                                    defaultValue={form.defaults?.equipment?.handlebarHeight}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 12 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Handlebar Reach"}
                                    name={"equipment.handlebarReach"}
                                    defaultValue={form.defaults?.equipment?.handlebarReach}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Crank Length"}
                                    name={"equipment.crankLength"}
                                    defaultValue={form.defaults?.equipment?.crankLength}
                                    allowCustomOption
                                    options={[
                                        { value: "150", label: "150mm" },
                                        { value: "155", label: "155mm" },
                                        { value: "160", label: "160mm" },
                                        { value: "165", label: "165mm" },
                                        { value: "167.5", label: "167.5mm" },
                                        { value: "170", label: "170mm" },
                                        { value: "172.5", label: "172.5mm" },
                                        { value: "175", label: "175mm" },
                                        { value: "177.5", label: "177.5mm" },
                                        { value: "180", label: "180mm" },
                                        { value: "182.5", label: "182.5mm" },
                                        { value: "185", label: "185mm" },
                                    ]}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Pedals"}
                                    name={"equipment.pedals"}
                                    defaultValue={form.defaults?.equipment?.pedals}
                                    allowCustomOption={true}
                                    options={[
                                        { value: "Shimano", label: "Shimano" },
                                        { value: "Look", label: "Look" },
                                        { value: "Speedplay", label: "Speedplay" },
                                        { value: "Exustar", label: "Exustar" },
                                        { value: "Assioma", label: "Assioma" },
                                        { value: "Garmin", label: "Garmin" },
                                        { value: "Time", label: "Time" },
                                        { value: "Platform", label: "Platform" },
                                        { value: "MTB", label: "MTB" },
                                        { value: "Powertap", label: "Powertap" },
                                        { value: "Crank Brothers", label: "Crank Brothers" }
                                    ]}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                        </>
                    }

                    {activity === "Running" &&
                        <>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Running Shoes"}
                                    name={"equipment.runningShoes"}
                                    defaultValue={form.defaults?.equipment?.runningShoes}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Shoe Weight"}
                                    name={"equipment.shoeWeight"}
                                    defaultValue={form.defaults?.equipment?.shoeWeight}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                        </>
                    }

                </Row>
            </Col>

        </Row>

    ), [ form.hash ] );

}


export default LabTestsRaceReadyEquipment;
