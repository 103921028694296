import React from 'react';
import { titleCase, get, date } from "@dex/bubl-helpers";
import CoachingApi from './CoachingApi';

let settings;

export default settings = {
    key: 'coaching',
    zone: 'coaching',
    path: '/coaching',
    title: "Coaching",
    endpoint: CoachingApi.endpoint,
    modelName: 'Coachings',
    idKey: 'id',
    primaryKey: 'id',
    actions: ['trash', 'clone'],
    indexCaps: ["viewCoachings"],
    viewCaps: ["viewCoachings"],
    createCaps: ["createCoachings"],
    updateCaps: ["updateCoachings"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: CoachingApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = {
            where: {},
            include: [{ relation: "coach", preset: "link" }, { relation: "athlete", preset: "link" }],
            order: ['created.date DESC'],
            fields: [
                "id", "modelName", "startDate",
                "general",
                "coach", "coachId",
                "athlete", "athleteId",
                "created", "updated",
            ],
            limit: 10,
            skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ["id", "athleteName"];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Athlete",
                key: "athleteId",
                type: "relationship",
                model: "Athletes",
            },
            {
                label: "Start Date",
                key: "general.startDate",
                type: "date",
            },
            {
                label: "Appraisers",
                key: "coachId",
                type: "relationship",
                model: "Fitters",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "ID",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Athlete",
                key: "athlete",
                format: "ModelLink"
            },
            {
                label: "Start Date",
                key: "general.startDate",
                format: 'date',
            },
            {
                label: "Coach",
                key: "coach",
                format: "ModelLink"
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: CoachingApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: CoachingApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = {
            include: [
                'athlete',
                "plans",
                "events",
                "coach",
                "weeks",
                "intervals"
            ]
        };

        return filters;

    },

    reportFetch: CoachingApi.getReport,
    reportFetchParams: (routeName, params, users, fetch) => {

        const filters = { key: params?.reportKey };

        return filters;

    },

    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = `${data.athlete.fullName} - ${date(data.general.startDate)}`;
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleButtons: (routeName, params, users, fetch) => {

        const data = get(fetch, 'data');

        if (!data) return [];

        return [
            {
                label: "Athlete Profile",
                path: `/athletes/${data.athleteId}/view`,
                caps: settings.createCaps
            },
        ]

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "General",
                path: path + "/view"
            },
            {
                label: "Events",
                path: path + "/edit/events",
                caps: settings.updateCaps
            },
            {
                label: "Plans",
                path: path + "/edit/plans",
                caps: settings.updateCaps
            },
            {
                label: "Training",
                path: path + "/edit/training",
                caps: settings.updateCaps
            },
            {
                label: "Zones",
                path: path + "/edit/zones",
                caps: settings.updateCaps
            },
            {
                label: "Reports",
                path: path + "/edit/reports",
                caps: settings.updateCaps
            },
            {
                label: "Log",
                path: path + "/edit/log",
                caps: settings.updateCaps
            }
        ]

    },
};