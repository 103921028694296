import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { DeletePanel } from '@dex/bubl-dash';
import TestSessionsCreateEditForm from './TestSessionsCreateEditForm';

const TestSessionsGeneralForm: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <TestSessionsCreateEditForm
                    mode={"edit"}
                    form={form}
                    data={data}
                    handleSubmit={handleSubmit}
                    process={process}
                />

            </Col>

            <Col
                className={'side'}
            >

                <DeletePanel
                    data={data}
                />

            </Col>

        </Row>


    ), [props]);

}

export default TestSessionsGeneralForm;
