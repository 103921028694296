import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldSessionsApi from '../FieldSessionsApi';
import EditableContext from '../../../context/EditableContext';
import { get } from '@dex/bubl-helpers';
import FieldSessionsEditTabsInfo from './FieldSessionsEditTabsInfo';
import FieldSessionsEditTabsSessions from './FieldSessionsEditTabsSessions';
import FieldSessionsEditTabsReports from './FieldSessionsEditTabsReports';
import FieldSessionsEditTabsSelection from './FieldSessionsEditTabsSelection';
import FieldSessionsEditTabsFormat from './FieldSessionsEditTabsFormat';
import FieldSessionsEditTabsScores from './FieldSessionsEditTabsScores';

const FieldSessionsSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const tab = get(props, 'routeParams.tab');

    const form = useForm(data);

    let action = tab;

    if (tab === "info" || !tab) action = "update"
    else if (tab === "selection") action = "selection";
    else if (tab === "format") action = "format";
    else if (tab === "reports") action = "none";
    else if (tab === "sessions") action = "none";

    const update = FieldSessionsApi.update(data.id, action, {
        onComplete: (data) => {

            app.alert('Field Test Sessions Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    let TabElement: any = React.Fragment;

    if (tab === "info" || !tab) TabElement = FieldSessionsEditTabsInfo;
    else if (tab === "selection") TabElement = FieldSessionsEditTabsSelection;
    else if (tab === "format") TabElement = FieldSessionsEditTabsFormat;
    else if (tab === "scores") TabElement = FieldSessionsEditTabsScores;
    else if (tab === "reports") TabElement = FieldSessionsEditTabsReports;
    else if (tab === "sessions") TabElement = FieldSessionsEditTabsSessions;

    return useMemo(() => (

        <>
            <EditableContext.Provider value={true}>

                {data && TabElement &&

                    <TabElement
                        form={form}
                        data={data}
                        handleSubmit={handleSubmit}
                        process={update}
                    />

                }

            </EditableContext.Provider>

        </>

    ), [data, tab, form.hash, update]);

}

export default FieldSessionsSingleEdit;
