import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import ExercisesApi from '../../Exercises/ExercisesApi';
import { loop, slug } from '@dex/bubl-helpers';
import LabelWithImage from '../../../elements/LabelWithImage/LabelWithImage';
import { useApp } from '@dex/bubl-dash';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';

const SessionsEditTabsExercises: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const excersises = ExercisesApi.get();

    const [targets, setTargets] = useState<any>(null);

    const app = useApp();

    useEffect(() => {

        excersises.run({
            params: { limit: 1000, order: ['name asc'] },
            onComplete: (response) => {

                const targets = {};

                response.data.map((item) => {

                    const key = slug(item.target || "Other");

                    if (!targets[key]) targets[key] = {
                        title: item.target || "Other",
                        key: key,
                        options: [],
                    };

                    targets[key].options.push({
                        value: item.id,
                        label: <LabelWithImage
                            image={app.mediaUrl(item.photo?.fileId, { width: 200, height: 100, resizeMode: "contain", fallback: { background: "#f2f2f2" } }, "thumb")}
                            text={item.name}
                        />,
                    });

                });

                setTargets(Object.values(targets));

            }
        });

    }, []);

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Exercises"}
            {...props}
        >

            {targets && targets.length &&
                <>

                    {targets.map((target: any, index: string) => (
                        <React.Fragment key={index}>

                            <Seperator
                                heading={target.title}
                                top={index ? "auto" : null}
                                bottom={"auto"}
                            />

                            <FieldCheckbox
                                form={form}
                                name={`exercises.${target.title}`}
                                options={target.options}
                                button={true}
                                wrapClass={"checkbox-with-image checkbox-with-image-wrap"}
                                defaultValue={form.defaults.exercises[target.title]}
                            />

                        </React.Fragment>
                    ))}

                </>
            }

        </SessionsEditTabsPanel >


    ), [props, targets]);

}

export default SessionsEditTabsExercises;
