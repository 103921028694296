import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataTable, Modal, useApp, useForm } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';
import CoachingLogCreateEdit from './CoachingLogCreateEdit';
import CoachingTrainingLogsApi from '../../CoachingTrainingLogs/CoachingTrainingLogsApi';

const CoachingLogTable: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm();

    const app = useApp();

    const fetch = CoachingTrainingLogsApi.get();

    const [key, setKey] = useState(0);

    useEffect(() => {

        const query: any = { coachingId: data.id };

        fetch.run({
            params: {
                where: query,
                order: ['created.date DESC'],
                limit: 1000,
            }
        });

    }, [key])

    const columns = [
        {
            label: "",
            key: "id",
            render: (value, row) => {
                return (
                    <CoachingLogEditForm data={row} setKey={setKey} />
                );
            }
        },
        {
            label: "Package",
            key: "package",
            format: "status"
        },
        {
            label: "Time Period",
            key: "period",
        },
        {
            label: "Price Paid",
            key: "price"
        },
        {
            label: "Start Date",
            key: "startDate",
            format: "date"
        },
        {
            label: "End Date",
            key: "endDate",
            format: "date"
        },
        {
            label: "Total Hours",
            key: "totalHour"
        },
        {
            label: "Earning Rate",
            key: "earningRate",
            render: (value) => {
                return `RM ${value || ""}`
            }
        },
    ];

    return useMemo(() => (

        <>

            <DataTable
                data={get(fetch, 'data.data', [])}
                count={get(fetch, 'data.count', null)}
                columns={columns}
                loading={fetch.loading}
            />

        </>

    ), [fetch.loading, form.hash]);

}

const CoachingLogEditForm: React.FC<any> = (props: any) => {

    const { data, setKey } = props;

    const [showFormUpdate, setShowFormUpdate] = useState(false);

    const form = useForm(data)

    const app = useApp();

    const [nav] = app.store("nav");

    const process = CoachingTrainingLogsApi.update(data.id, "update");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            onComplete: (data) => {

                app.alert('Log Saved', 'success');

                setShowFormUpdate(false);

                app.navigate(nav, 'update', {});

                setKey(k => k + 1)

            }
        });

    }, []);

    return (

        <>

            <a href="#" onClick={() => setShowFormUpdate(true)}>Edit Log</a >

            {showFormUpdate &&

                <Modal
                    show={showFormUpdate}
                    onClose={setShowFormUpdate.bind(null, false)}
                    width={1080}
                >

                    <CoachingLogCreateEdit
                        form={form}
                        process={process}
                        handleSubmit={handleSubmit}
                        data={data}
                        mode={"edit"}
                    />

                </Modal>
            }

        </>
    )

}

export default CoachingLogTable;
