import React, { useMemo } from 'react';
import { Col, MediaImage, Row, Space } from '@dex/bubl-dash';
import styles from "./TestSessionsReportCover.module.scss";
import { date, get, upperCase } from '@dex/bubl-helpers';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';

const TestSessionsReportPerformanceTrackingCover: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const logo = [
        { img: require('../../../assets/images/Cover/VO2MASTER.png'), alt: "VO2MASTER" },
        { img: require('../../../assets/images/Cover/CORTEX.png'), alt: "CORTEX" },
        { img: require('../../../assets/images/Cover/MOXY.png'), alt: "MOXY" },
        { img: require('../../../assets/images/Cover/CSEP.png'), alt: "CSEP" },
        { img: require('../../../assets/images/Cover/IRONMAN.png'), alt: "IRONMAN" }
    ]

    return useMemo(() => (

        <div className={styles.cover}>

            <Space height={160} />

            <div className={styles.innerCover}>

                <div>
                    <img
                        src={require('../../../assets/images/Cover/VO2MAX.png')}
                        alt="VO2MAX"
                        className={styles.vo2Logo}
                    />
                </div>

                <div className={styles.borderBlue}>

                    <div className={styles.borderGreen} >

                        <div className={styles.top}>

                            <p className={styles.title}> <span>{data.reportTitle} - </span> {data.test?.activity} <span> Report</span></p>

                            {data?.reportTitle == "MET Test" &&
                                <p className={styles.title}> <span>{upperCase(data.test?.activity)} </span></p>
                            }

                            <div className={styles.blackText}>

                                <p>by</p>

                                {data.fitters.map((item: any, index: any) => (
                                    <div
                                        className={styles.value}
                                        key={index}
                                    >
                                        {index > 0 &&
                                            <p>  &  </p>
                                        }
                                        <p> {item.name} </p>
                                    </div>
                                ))}

                            </div>

                            <p className={styles.blackText}>{date(data.date, "D MMMM YYYY")}</p>

                            <div className={styles.imageWrapper}>
                                {data.general &&
                                    <MediaImage
                                        id={get(data.general, "photo.fileId", data.athlete?.photo?.fileId)}
                                        filters={{ height: 350, resizeMode: "contain", exact: true }}
                                        className={styles.image}
                                        imgProps={{
                                            crossOrigin: "anonymous"
                                        }}
                                    />
                                }
                            </div>

                            <p className={styles.athlete}>{data.athleteName}</p>

                        </div>

                        <Space height={8} />

                        <Row gutter={16} edge>

                            <Col col={{ xs: 24, md: 9 }}>

                                <ReportMetaTable
                                    data={data}
                                    columnSize={{ xs: 12 }}
                                    columnA={[
                                        {
                                            key: 'athlete.gender',
                                            label: 'Gender',
                                            format: "titleCase"
                                        }
                                    ]}
                                    columnB={[
                                        {
                                            key: 'athlete.age',
                                            label: 'Age',
                                            format: "number",
                                            suffix: " yrs",
                                        },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 9 }}>

                                <ReportMetaTable
                                    data={data}
                                    columnSize={{ xs: 12 }}
                                    columnA={[

                                        {
                                            key: 'athlete.mainSport',
                                            label: 'Main Sport',
                                        }
                                    ]}
                                    columnB={[
                                        {
                                            key: 'athlete.level',
                                            label: 'Level',
                                            format: "titleCase"
                                        }
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 6 }}>

                                <ReportMetaTable
                                    data={data}
                                    columnSize={{ xs: 24 }}
                                    columnA={[
                                        {
                                            key: 'general.nutritionDiet',
                                            label: 'Nutrition Diet',
                                        },
                                    ]}
                                />

                            </Col>

                        </Row>

                        <div className={styles.logoRow}>
                            {logo.map((item: any, index: any) => (
                                <div
                                    className={styles.logoContainer}
                                    key={index}
                                >
                                    <img
                                        src={item.img}
                                        alt={item.alt}
                                        className={styles.logo}
                                    />
                                </div>
                            ))}
                        </div>

                        <Space height={80} />

                    </div>

                </div>

            </div>

        </div>

    ), [data]);

}

export default TestSessionsReportPerformanceTrackingCover;