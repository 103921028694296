import { Col, FieldRadio, Row } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';
import { get } from '@dex/bubl-helpers';
import styles from "./TestSessionsReportPerformanceTrackingZones.module.scss";
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';

const TestSessionsReportPerformanceTrackingZones: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const activity: string = data.test.activity;

    const [index, setIndex] = useState(data.history[0].date);

    const trackingUse = get(form.values, `zones.trackingUse`, activity == "Cycling" ? "Power" : "Speed");
    const chartUse = get(form.values, `zones.chartUse`, "speed");

    const dataUse = (zoneIndex, zone, history, index) => {

        let dataUse;

        if (trackingUse == "HR") {

            if (zoneIndex == 0) {

                dataUse = "<" + zone.hrUpper

            } else if (zoneIndex <= 4) {

                dataUse = zone.hrLower

            } else if (zoneIndex == 5) {

                let beforeZone = history[history.length - 2]

                dataUse = ">" + beforeZone.hrUpper

            } else {
                return
            }

        } else if (trackingUse == "Power") {

            if (zoneIndex == 0) {

                dataUse = "<" + zone.powerUpper

            } else if (zoneIndex <= 4) {

                dataUse = zone.powerLower

            } else if (zoneIndex == 5) {

                dataUse = ">" + zone.powerLower

            } else {
                return
            }

        } else if (trackingUse == "Speed" && chartUse == "speed") {

            if (zoneIndex == 0) {

                dataUse = "<" + zone.powerUpper

            } else if (zoneIndex <= 4) {

                dataUse = zone.powerLower

            } else if (zoneIndex == 5) {

                dataUse = ">" + zone.powerLower

            } else {
                return
            }

        } else if (trackingUse == "Speed" && chartUse == "pace") {

            if (zoneIndex == 0) {

                dataUse = "<" + zone.pace_adj_upper

            } else if (zoneIndex <= 4) {

                dataUse = zone.pace_adj_lower

            } else if (zoneIndex == 5) {

                dataUse = ">" + zone.pace_adj_lower

            } else {
                return
            }

        } else {

        }

        return dataUse;
    }

    let description = "Your Training Zones over time are shown here. As your fitness changes so do your training zones, especially for Power and/or Speed. Generally, as one improves their fitness speed and/or Power for any given zone increases while HR may stay about the same!"

    return useMemo(() => (

        <>

            <Row edge gutter={8}>

                <Col col={{ xs: 20 }}>

                    <Row edge gutter={8} justify='center'>

                        {data?.history.map((history, index) => {

                            const historyLength = data?.history?.length;

                            return (

                                <Col grow={0} align='center' col={{ xs: 24 / historyLength }} key={index}>

                                    <div className={styles.barContainer}>

                                        <div>

                                            <img
                                                src={require("../../../assets/images/LabReport/icon-bar-chart.png")}
                                                alt='bar chart'
                                                style={{ width: "125px", height: "400px" }}
                                            />

                                            {history?.zones.map((zone, zoneIndex) => (

                                                <div className={[styles.zone, styles[`zone-` + zoneIndex]].join(" ")} key={zoneIndex}>

                                                    <p>

                                                        Z{zoneIndex + 1}  {dataUse(zoneIndex, zone, history?.zones, index)}

                                                    </p>

                                                </div>

                                            ))}

                                        </div>

                                        <p className={[styles.text, styles[`text-${trackingUse}`]].join(" ")}>
                                            {history.date}
                                        </p>

                                    </div>

                                </Col>

                            )
                        })}

                    </Row>

                </Col>

                <Col col={{ xs: 4 }}>

                    <FieldRadio
                        list=" vertical-list"
                        key={trackingUse}
                        form={form}
                        required={false}
                        label={"Tracking Over Time"}
                        name={"zones.trackingUse"}
                        defaultValue={form.values?.zones?.trackingUse || trackingUse}
                        options={[
                            activity === "Cycling" ? { label: "Power", value: "Power" } : null,
                            activity === "Running" ? { label: "Speed", value: "Speed" } : null,
                            { label: "HR", value: "HR" }
                        ].filter(Boolean)}
                    />

                    {trackingUse == "Speed" &&
                        <FieldRadio
                            list=" vertical-list"
                            key={form.values?.zones?.chartUse}
                            form={form}
                            required={false}
                            label={"Chart Data"}
                            name={"zones.chartUse"}
                            defaultValue={form.values?.zones?.chartUse}
                            options={[
                                { label: "kph", value: "speed" },
                                { label: "min/km", value: "pace" }
                            ]}
                        />
                    }
                </Col>

                <Col col={{ xs: 20 }}>

                    <ReportDesc
                        label={description}
                        className={["no-border", "h1"]}
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, trackingUse]);

}

export default TestSessionsReportPerformanceTrackingZones;