import React, { useMemo } from 'react';
import { Col, DataTable, Heading, InputTableCell, InputTableHeadCell, InputTableHeadRow, InputTableRow, Line, Row, Space } from '@dex/bubl-dash';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import { HiCheck } from 'react-icons/hi';
import styles from './FieldResult.module.scss';
import { calculateMissedPercentage } from '../../../functions/sharedFieldSession';
import { date, loop } from '@dex/bubl-helpers';

const FieldResultsAthleteGeneral: React.FC<any> = ( props: any ) => {

    const { report, form } = props;

    const summaryColumns = [
        {
            key: "__num",
            label: <>&nbsp;</>,
            style: { width: 44, paddingLeft: 12 },
        },
        {
            key: "totalTest",
            label: "Total Tests",
            style: { paddingLeft: 12 }
        },
        ...Object.keys( report.ratings ).map( ( key ) => ( {
            key: key,
            label: key,
            style: { paddingLeft: 12 },
            colSpan: report.ratings[ key ].length,
        } ) )
    ];

    const Check = <HiCheck className={styles.check} />;

    function calculateResultsWithoutScore( report ) {
        let totalWithoutScore = 0;

        loop( Object.values( report.tests ), ( key ) => {
            if ( !report.results[ key.value ].score ) {
                totalWithoutScore++;
            }
        } );

        return totalWithoutScore;
    }

    const totalMissingScores = calculateResultsWithoutScore( report );

    return useMemo( () => (

        <Row edge gutter={8}>

            <Col col={{ xs: 24 }}>
                <Heading size={2} space={false}>Assessment Details</Heading>
            </Col>

            <Col col={{ xs: 12 }}>

                <ReportMetaTable
                    data={report}
                    columnSize={8}
                    columnA={[
                        {
                            label: "Team",
                            key: "team.name",
                            format: 'titleCase',
                        },
                        {
                            label: "Short Name",
                            key: "athlete.callName",
                        },
                    ]}
                    columnB={[
                        {
                            label: "Main Sport",
                            key: "athlete.mainSport",
                            format: 'titleCase',
                        },
                        {
                            label: "Gender",
                            key: "athlete.gender",
                        },
                    ]}
                    columnC={[
                        {
                            label: "Discipline",
                            key: "athlete.discipline",
                            format: 'titleCase',
                        },
                        {
                            label: "Age",
                            key: "athlete.age",
                        },
                    ]}

                />

            </Col>

            <Col col={{ xs: 12 }}>
                <ReportMetaTable
                    data={report}
                    columnSize={8}
                    columnA={[
                        {
                            label: "Level",
                            key: "athlete.level",
                        },
                        {
                            label: "Position",
                            key: "athlete.position",
                        },
                    ]}
                    columnB={[
                        {
                            label: "Test Period",
                            key: "session.period",
                        },
                        {
                            label: "Session Date",
                            key: "session.fieldTests",
                            render: ( value, row ) => {
                                return ( value || [] ).map( ( test ) => {
                                    return date( test.date, "D MMMM YYYY" );
                                } ).join( ", " );
                            }
                        }
                    ]}
                    columnC={[ {
                        label: "Appraisers",
                        key: "fitters",
                        render: ( value, row ) => {
                            const fitterNames = ( value || [] ).map( ( val ) => {
                                return val.name;
                            } );
                            return fitterNames.join( ", " );
                        }
                    } ]}

                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Space />

                <Heading size={2} space={false}>Test Summary</Heading>

                <DataTable
                    data={null}
                    columns={summaryColumns}
                >

                    <InputTableHeadRow className="table-bg-gray">

                        <InputTableHeadCell>
                            #
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            <span className={styles.totalTest}>
                                {report.tests.length}
                            </span>
                        </InputTableHeadCell>

                        {Object.values( report.tests ).map( ( key: any ) => (
                            <InputTableHeadCell style={{ maxWidth: "50px", textWrap: "balance" }}>
                                {key.label}
                            </InputTableHeadCell>
                        ) )}

                    </InputTableHeadRow>

                    <InputTableRow>

                        <InputTableCell>
                            1
                        </InputTableCell>

                        <InputTableCell>
                            <span className={styles.test}>{calculateMissedPercentage( report.tests.length, totalMissingScores ).missedCount}</span>
                            <span> ({calculateMissedPercentage( report.tests.length, totalMissingScores ).percentage}%)</span>
                        </InputTableCell>

                        {Object.values( report.tests ).map( ( key: any ) => (
                            <InputTableHeadCell key={key.value}>
                                {report.results[ key.value ].score ? Check : null}
                            </InputTableHeadCell>
                        ) )}

                    </InputTableRow>

                </DataTable>

            </Col>

        </Row>

    ), [ report ] );

}

export default FieldResultsAthleteGeneral;
