import { Col, Row, Space } from '@dex/bubl-dash';
import Heading from '@dex/bubl-dash/src/lib/components/Heading/Heading';
import React, { useEffect, useMemo, useState } from 'react';

import styles from "./TestSessionsReportCirculation.module.scss";
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartHrMax from '../../../elements/ChartTestSessionReport/ChartHrMax';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import * as _ from "lodash";
import { fixedNum } from '@dex/bubl-helpers';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportCirculation: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const intensity = data?.test?.intensity;
    const zoneUse = data.test?.hr?.zoneUse || "max";

    let cssClass = "";

    if (zoneUse === "MaxPeak" || zoneUse == "max") {
        cssClass = "checkmark-start";
    } else if (zoneUse === "maxTraining") {
        cssClass = "checkmark-center";
    } else if (zoneUse === "maxEst") {
        cssClass = "checkmark-end";
    } else {
        cssClass = "checkmark-center";
    }

    const bloodFlow = (data.test?.muscle?.a?.thbTrend || "constant").toLowerCase();

    const numbers = [
        {
            number: data.test.hr.MaxPeak ? fixedNum(data.test.hr.MaxPeak, 0) : fixedNum(data.test.hr.max, 0),
            title: "Today's test",
            img: ""
        },
        {
            number: fixedNum(data.test.hr.maxTraining, 0),
            title: "In-Training",
            img: ""
        },
        {
            number: fixedNum(data.test.hr.maxEst, 0),
            title: "Age-Predicted",
            img: ""
        }
    ];

    const getImageForNumber = (numbers) => {
        const sortedNumbers = _.sortBy(numbers, "number");

        sortedNumbers.forEach((num, index) => {
            if (index > 0 && num.number === sortedNumbers[index - 1].number) {
                num.img = sortedNumbers[index - 1].img;
            } else {
                switch (index) {
                    case 0:
                        num.img = require('../../../assets/images/Circulation/hr-zone-a.png');
                        break;
                    case 1:
                        num.img = require('../../../assets/images/Circulation/hr-zone-b.png');
                        break;
                    case 2:
                        num.img = require('../../../assets/images/Circulation/hr-zone-c.png');
                        break;
                    default:
                        break;
                }
            }
        });

    };

    getImageForNumber(numbers);

    const activity = data.test.activity;

    const [index, setIndex] = useState(data.history[0].date);

    return useMemo(() => (

        <div className={styles.circulation}>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={16} edge>

                <Col col={{ xs: 24 }}>

                    <ReportHeading
                        text="Your Heart Rate"
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <div className="heart-rate">

                        <div className='dark-text'>
                            <p>Vo2max</p>
                            <p>{data.test.lactate?.zones[4]?.hrUpper} bpm</p>
                        </div>

                        <div className='yellow-text'>
                            <p>Threshold</p>
                            <p>{data.test.lactate?.zones[3]?.hrUpper} bpm</p>
                        </div>

                        <div className='green-text'>
                            <p>Tempo</p>
                            <p>{data.test.lactate?.zones[2]?.hrUpper} bpm</p>
                        </div>

                        <div className='blue-text'>
                            <p>Endurance</p>
                            <p>{data.test.lactate?.zones[0]?.hrUpper} bpm</p>
                        </div>

                        <div className='light-text'>
                            <p>Resting</p>
                            <p>{data.health?.restingHr} bpm</p>
                        </div>

                        <div className="cylinderImage">
                            <img
                                src={require('../../../assets/images/Circulation/hr-zone-levels.png')}
                                alt='cylinder image'
                            />
                        </div>

                    </div>

                    <p className='hr-description'>*Heart Rates shown are the upper limit of each zone.</p>


                </Col>

                <Col col={{ xs: 24, md: 12 }}  >

                    <ReportDesc
                        label="Maximum Heart Rate (HRmax)"
                        value={data.test.hr.hrDescription}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}  >

                    <div className='peak-hr'>

                        <Heading>Your Peak HRs</Heading>

                        <div className='hr-chart'>

                            {numbers.map((item: any, index: any) => (
                                <div
                                    className='today-hr'
                                    key={index}
                                >

                                    <p className='hr-bpm'>{isNaN(item.number) ? "-" : item.number} bpm</p>

                                    <div className="image">
                                        <img
                                            src={item.img}
                                        />
                                    </div>

                                    <p className='hr-title'>{item.title}</p>

                                </div>
                            ))}

                        </div>

                        <div className={cssClass}>
                            <div className='checkmarkContainer'>
                                <img
                                    src={require('../../../assets/images/Circulation/checkmark.png')}
                                    className='checkmarkImage'
                                />
                                <p>Used For Zones</p>
                            </div>
                        </div>

                    </div>

                </Col>

                <Col col={{ xs: 24, md: 12 }} align='center' >

                    <Space height={40} />

                    <ReportHeading
                        text="Your Blood Flow"
                    />

                    <div className="image">
                        <img
                            src={require(`../../../assets/images/Circulation/bf-${bloodFlow}.png`)}
                            className="imageGraph"
                        />
                    </div>

                </Col>

                {/* <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label='Good Blood Flow'
                        value={data.test.muscle.tbhDescription}
                    />

                </Col> */}

                <Col col={{ xs: 24, md: 24 }}>

                    <Space height={40} />

                    <ReportHeading
                        text="Your Maximum Heart Rate Over Time"
                    />

                    <PanelChart
                        heading={"Max HR Over Time"}
                        data={data.allData}
                        renderChart={(chartType, chartData) => (
                            <ChartHrMax
                                activity={activity}
                                data={chartData}
                                index={index}
                                date={data.recentDate}
                                intensity={intensity}
                                xAxis={{
                                    interval: ChartProps.interval(activity, 'avg'),
                                }}
                            />
                        )}
                    />

                    <ReportChartSummary
                        label={"Your HR max"}
                        valueKey={'hrMax'}
                        indexKey={"date"}
                        data={data.history}
                        type={"size"} //performance, size
                        onIndexChange={setIndex}
                    />

                </Col>

            </Row>

        </div>

    ), [data, index]);

}

export default TestSessionsReportCirculation;