import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import SimplePage from '@dex/bubl-dash/src/lib/pages/SimplePage';
import QuestionnairesComplete from './Components/QuestionnairesComplete';
import QuestionnairesCreateEditForm from './Components/QuestionnairesCreateEditForm';
import QuestionnairesFillForm from './Components/QuestionnairesFillForm';
import QuestionnairesApi from './QuestionnairesApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New Questionnaire",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: QuestionnairesCreateEditForm,
                getCreate: QuestionnairesApi.create,
                getClone: QuestionnairesApi.getOne,
                defaults: {},
                successMessage: "Questionnaire Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

    app.addRoute({
        name: settings.key + "Fill",
        zone: settings.zone,
        path: settings.key + "/fill",
        caps: settings.createCaps,
        public: true,
        sidebar: false,
        ribbon: false,
        component: IndexPage,
        componentProps: {
            create: {
                form: QuestionnairesFillForm,
                getCreate: QuestionnairesApi.create,
                getClone: QuestionnairesApi.getOne,
                defaults: {
                    cyclistInfo: {},
                    bikeDetails: [],
                    bikeSizing: {},
                    refitSame: {},
                    refitNew: {}
                },
                rowProps: { justify: "center" },
                colProps: { className: "main" },
                successMessage: "Questionnaire Saved",
                successRouteName: settings.key + "Complete",
                idKey: settings.idKey
            }
        },
    });

    app.addRoute({
        name: settings.key + "Complete",
        zone: settings.zone,
        path: settings.key + "/complete",
        caps: settings.createCaps,
        component: SimplePage,
        componentProps: {
            page: QuestionnairesComplete
        },
    });

}