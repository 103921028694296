import React from 'react';
import { getRandomInt } from '../../functions/sharedGeneral';
import { stepsMinMax, stepLabels, arrowPosition, DisplayAs } from './RatingScaleElements';

import styles from "./SummaryRating.module.scss";
import { summaryData } from './RatingSummary';

const SummaryRating: React.FC<SummaryRatingProps> = (props) => {

    let { value, classname } = props;

    const { steps, min, max }: any = stepsMinMax(summaryData, "untrained", "male");

    value = (props.value === "rand") ? getRandomInt(min, max) : parseFloat(value);

    const labels = stepLabels(steps);

    const left = arrowPosition(value, min, max);

    const rangeSteps = [{ num: 1 }, { num: 2 }, { num: 3 }, { num: 4 }, { num: 5 }];

    const label = {
        "0": "Poor",
        "0.5": "Poor",
        "1": "Poor",
        "1.5": "Low",
        "2": "Low",
        "2.5": "Fair",
        "3": "Fair",
        "3.5": "Good",
        "4": "Good",
        "4.5": "Very Good",
        "5": "Excellent",
    }

    return (

        <>
            <div className={classname ? classname : styles.row + " row"}>

                <div className={styles.measurement}>
                    {label[value]}
                </div>

                <div className={styles.displayShow}>

                    <DisplayAs
                        left={left}
                        labels={labels}
                        steps={steps}
                        data={summaryData}
                        min={min}
                        max={max}
                        demographic={"untrained"}
                        gender={"male"}
                        rangeSteps={rangeSteps}
                    />

                </div>

            </div>

        </>

    )

}

interface SummaryRatingProps {
    [key: string]: any,
}

export default SummaryRating;