import { Col, Line, MediaImage, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import styles from "./GaitReportStability.module.scss";
import { ColoredHeader } from './GaitReportPage';
import GaitReportComment from '../../../elements/GaitReportComment/GaitReportComment';
import MobilityBack from '../../../elements/Mobility/MobilityBack';
import MobilityFront from '../../../elements/Mobility/MobilityFront';
import { get, titleCase } from '@dex/bubl-helpers';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from "react-icons/io";

const GaitReportStability: React.FC<any> = (props: any) => {

    const { data, form } = props;

    let gender = titleCase(data.athlete?.gender || "Male");

    return useMemo(() => (

        <Row gutter={20} edge>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Mobility"
                />

            </Col>

            <Col col={{ xs: 3 }}>

                <div className={styles.ratingColumn}>

                    <div className="sub-heading-title">
                        RATING
                    </div>

                    <Space height={8} />

                    <RatingTable
                        rating={data.mobility.rating}
                    />

                </div>

            </Col>

            <Col col={{ xs: 7 }}>

                <Row gutter={20} edge>

                    <Col col={{ xs: 24 }} align='center'>

                        <div className="sub-heading-title">
                            TOE TOUCH
                        </div>

                    </Col>

                    <Col col={{ xs: get(data.mobility, "secondPhoto.fileId") ? 12 : 24 }} align='center'>

                        <MediaImage
                            id={get(data.mobility, "photo.fileId")}
                            filters={{ height: get(data.mobility, "secondPhoto.fileId") ? 125 : 175, width: get(data.mobility, "photo") ? "" : "100%", resizeMode: "contain", exact: true }}
                            className={styles.image}
                            imgProps={{
                                crossOrigin: "anonymous"
                            }}
                        />

                        <p className={styles.textLabel}>{data.mobility.photoCaption}</p>

                    </Col>

                    {get(data.mobility, "secondPhoto.fileId") &&
                        <Col col={{ xs: 12 }} align='center'>

                            <MediaImage
                                id={get(data.mobility, "secondPhoto.fileId")}
                                filters={{ height: 125, resizeMode: "contain", exact: true }}
                                className={styles.image}
                                imgProps={{
                                    crossOrigin: "anonymous"
                                }}
                            />

                            <p className={styles.textLabel}>{data.mobility.secondPhotoCaption}</p>

                        </Col>
                    }

                    <Col col={{ xs: 24 }} align='center'>

                        <div className="text-label">
                            Sacral Angle
                        </div>

                        <p className={styles.textLabel}>
                            {data.mobility.pelvicAngle} °
                        </p>

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 9 }} >

                <Row edge gutter={8}>

                    <Col col={{ xs: 24 }} align='center'>
                        <div className="sub-heading-title">
                            RESTRICTIONS
                        </div>
                    </Col>

                    <Col col={{ xs: 6 }} >

                        {data.mobility.left &&

                            <div className={styles.left}>

                                <p className='text-label'>Left</p>

                                {data.mobility.left.map((left, i) => (
                                    <small key={i}>{left}</small>
                                ))}

                            </div>

                        }

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <MobilityFront
                            gender={data.athlete.gender}
                            values={data.mobility}
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <MobilityBack
                            gender={data.athlete.gender}
                            values={data.mobility}
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        {data.mobility.right &&

                            <div >

                                <p className='text-label'>Right</p>

                                {data.mobility.right.map((right, i) => (
                                    <small key={i}>{right}</small>
                                ))}

                            </div>

                        }

                    </Col>

                    <Col col={{ xs: 11 }} align='end'>

                        <div className={styles.redRestricted}>
                            <div className={styles.circle} />
                            <p>
                                Restricted
                            </p>
                        </div>

                    </Col>

                    <Col col={{ xs: 2 }} />

                    <Col col={{ xs: 11 }}>

                        <div className={styles.orangeAdequate}>
                            <div className={styles.circle} />
                            <p>
                                Mild
                            </p>
                        </div>

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 5 }}>

                <GaitReportComment
                    label={`Comment`}
                    value={data.mobility.comments}
                    className="box-240"
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Hip & Leg Symmetry"
                />

            </Col>

            <Col col={{ xs: 3 }}>

                <div className={styles.ratingColumn}>

                    <div className="sub-heading-title">
                        RATING
                    </div>

                    <Space />

                    <RatingTable
                        rating={data.symmetry.rating}
                    />

                </div>

            </Col>

            <Col col={{ xs: 6 }} align='center'>

                <div className="sub-heading-title">
                    SYMMETRY
                </div>

                <div className={[styles.center, "text-label"].join(" ")}>
                    {data.symmetry.hipLevelStanding}
                </div>

                <div className={styles.imageSymmetry}>
                    <img
                        src={require(`../../../assets/images/Hip/${data.symmetry.fileName}.png`)}
                        alt={data.symmetry.fileName}
                    />
                </div>

                <div className={[styles.center, "text-label"].join(" ")}>
                    {data.symmetry.legLength}
                </div>

            </Col>

            <Col col={{ xs: 10 }}>

                <div className="sub-heading-title">
                    ASYMMETRY CORRECTION
                </div>

                <Row edge gutter={8}>

                    <Col col={{ xs: 12 }}>

                        <ReportMetaTable
                            data={data}
                            columnSize={{ xs: 24 }}
                            columnA={[
                                {
                                    label: "Hip/Leg Asymmetry",
                                    key: "symmetry.asymmetry",
                                    render: (value) => (
                                        value === "Both" ? "Both Functional & Structural" : value
                                    )
                                },
                                {
                                    label: "Reactive Eye",
                                    key: "symmetry.reactiveEye",
                                },
                                {
                                    label: "Improved",
                                    key: "symmetry.improved",
                                },
                                {
                                    label: "Hip Drop",
                                    key: "symmetry.hipDrop",
                                    format: "number",
                                    suffix: " mm"
                                }
                            ]}
                        />
                    </Col>

                    <Col col={{ xs: 12 }}>

                        <Space height={112} />

                        <ReportMetaTable
                            data={data}
                            columnSize={{ xs: 24 }}
                            columnA={[
                                {
                                    label: "With?",
                                    key: "symmetry.with",
                                },
                                data.symmetry.legLengthDetails ? {
                                    label: "Leg Length Difference",
                                    key: "symmetry.legLengthDetails",
                                    format: "number",
                                    suffix: " mm"
                                } : null,
                            ].filter(Boolean)}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 5 }}>

                <GaitReportComment
                    label={`Comment`}
                    value={data.symmetry.comments}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Stability"
                />

            </Col>

            <Col col={{ xs: 3 }}>

                <div className={styles.ratingColumn}>

                    <div className="sub-heading-title">
                        RATING
                    </div>

                    <Space />

                    <RatingTable
                        rating={data.stability.rating}
                    />

                </div>

            </Col>

            <Col col={{ xs: 8 }} align='center'>

                <Row edge gutter={8}>

                    <Col col={{ xs: 24 }} align='center'>
                        <div className={"sub-heading-title"}>
                            SINGLE-LEG SQUAT
                        </div>
                    </Col>

                    <Col col={{ xs: 24 }}>

                        <div className={styles.imageSymmetry}>

                            <img
                                src={require(`../../../assets/images/Stability/StabilitySquat${titleCase(gender)}.png`)}
                                alt="Stability Squat"
                            />

                            <SingleLegIndicator
                                label="Torso"
                                data={data}
                            />

                            <SingleLegIndicator
                                label="Hip"
                                data={data}
                            />


                            <SingleLegIndicator
                                label="Knee"
                                data={data}
                            />


                            <SingleLegIndicator
                                label="Foot"
                                data={data}
                            />

                        </div>

                    </Col>

                    <Col col={{ xs: 12 }} align='center'>
                        <div className={[styles.center, "text-label"].join(" ")}>
                            Left
                        </div>
                        {data.stability.legSquatLeft}
                    </Col>

                    <Col col={{ xs: 12 }} align='center'>
                        <div className={[styles.center, "text-label"].join(" ")}>
                            Right
                        </div>
                        {data.stability.legSquatRight}
                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 8 }} align='center'>

                <div className={[styles.center, "sub-heading-title"].join(" ")}>
                    CORE STABILITY
                </div>

                <CoreStability
                    data={data}
                    gender={gender}
                />

            </Col>

            <Col col={{ xs: 5 }}>

                <GaitReportComment
                    label={`Comment`}
                    value={data.stability.comments}
                />

            </Col>

        </Row >

    ), [data]);

}

const RatingTable: React.FC<any> = (props: any) => {

    const { data, rating } = props;

    return (
        <div className={styles.rating}>

            <div className={styles.ratingLabel}>Good</div>
            <div className={styles.ratingLabel}></div>
            <div className={styles.ratingLabel}></div>
            <div className={styles.ratingLabel}></div>
            <div className={styles.ratingLabel}></div>
            <div className={styles.ratingLabel}>Fair</div>
            <div className={styles.ratingLabel}></div>
            <div className={styles.ratingLabel}></div>
            <div className={styles.ratingLabel}></div>
            <div className={styles.ratingLabel}>Poor</div>
            <div className={`${styles.ratingLabel} ${styles.bottomBorder}`}></div>

            <div className={styles.ratingBar} style={{ height: `${rating * 10}%` }}></div>


        </div>
    )

}

const SingleLegIndicator: React.FC<any> = (props: any) => {

    const { label, data } = props;

    const Check = <IoIosCheckmarkCircleOutline className={styles.check} />
    const Close = <IoIosCloseCircleOutline className={styles.close} />

    return (

        <div className={[styles.indicator, styles["indicator" + label]].join(" ")}>

            <Row edge gutter={8}>

                <Col col={{ xs: 6 }}>

                    {data.stability.legSquatLeft &&
                        <>
                            {(data.stability.legSquatLeftDetails || []).includes(label) ? Close : Check}
                        </>
                    }

                </Col>

                <Col col={{ xs: 12 }} align='center'>
                    <p className='text-label'>
                        {label}
                    </p>
                </Col>

                <Col col={{ xs: 6 }}>

                    {data.stability.legSquatRight &&
                        <>
                            {(data.stability.legSquatRightDetails || []).includes(label) ? Close : Check}
                        </>
                    }

                </Col>

            </Row>

        </div>

    )
}

const CoreStability: React.FC<any> = (props: any) => {

    const { gender, data } = props;

    const Check = <IoIosCheckmarkCircleOutline className={styles.check} />
    const Close = <IoIosCloseCircleOutline className={styles.close} />

    return (
        <Row edge gutter={8} >

            <Col col={{ xs: 20 }} align='center'>
                <div
                    className={styles.coreImage}
                >
                    <img
                        src={require(`../../../assets/images/Stability/Core5${gender}.png`)}
                    />
                </div>
            </Col>

            {data.stability.coreStability &&
                <Col col={{ xs: 4 }} align='center' justify='center'>
                    {(data.stability.coreStability || []).includes("5") ? Check : Close}
                </Col>
            }

            <Col col={{ xs: 20 }} align='center'>
                <div
                    className={styles.coreImage}
                >
                    <img
                        src={require(`../../../assets/images/Stability/Core4${gender}.png`)}
                    />
                </div>
            </Col>

            {data.stability.coreStability &&
                <Col col={{ xs: 4 }} align='center' justify='center'>
                    {(data.stability.coreStability || []).includes("4") ? Check : Close}
                </Col>
            }

            <Col col={{ xs: 20 }} align='center'>
                <div
                    className={styles.coreImage}
                >
                    <img
                        src={require(`../../../assets/images/Stability/Core3${gender}.png`)}
                    />
                </div>
            </Col>

            {data.stability.coreStability &&
                <Col col={{ xs: 4 }} align='center' justify='center'>
                    {(data.stability.coreStability || []).includes("3") ? Check : Close}
                </Col>
            }

            <Col col={{ xs: 20 }} align='center'>
                <div
                    className={styles.coreImage}
                >
                    <img
                        src={require(`../../../assets/images/Stability/Core2${gender}.png`)}
                    />
                </div>
            </Col>

            {data.stability.coreStability &&
                <Col col={{ xs: 4 }} align='center' justify='center'>
                    {(data.stability.coreStability || []).includes("2") ? Check : Close}
                </Col>
            }

            <Col col={{ xs: 20 }} align='center'>
                <div
                    className={styles.coreImage}
                >
                    <img
                        src={require(`../../../assets/images/Stability/Core1${gender}.png`)}
                    />
                </div>
            </Col>

            {data.stability.coreStability &&
                <Col col={{ xs: 4 }} align='center' justify='center'>
                    {(data.stability.coreStability || []).includes("1") ? Check : Close}
                </Col>
            }

        </Row>
    )
}
export default GaitReportStability;