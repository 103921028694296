import React from 'react';

import styles from "./FieldResultsSummaryAttributes.module.scss";
import { Heading, Panel, Space } from '@dex/bubl-dash';
import { slug } from '@dex/bubl-helpers';

const FieldResultsSummaryAttributes: React.FC<FieldResultsSummaryAttributesProps> = (props) => {

    const { attributes } = props;

    const sortedAttributes = {
        good: (attributes.good || []).sort((a, b) => a[1].localeCompare(b[1])),
        fair: (attributes.fair || []).sort((a, b) => a[1].localeCompare(b[1])),
        weak: (attributes.weak || []).sort((a, b) => a[1].localeCompare(b[1])),
    };

    const gruops = [
        {
            title: "Strong Attributes - Maintain",
            list: sortedAttributes.good || [],
        },
        {
            title: "Fair Attributes - Work On",
            list: sortedAttributes.fair || [],
        },
        {
            title: "Weak Attributes - Work On A lot!",
            list: sortedAttributes.weak || [],
        },
    ]

    return (

        <Panel heading={props.heading ? props.heading : false} className={styles.panel} full={true}>

            <div className={styles.container}>

                {gruops.map((group, index) => (

                    <div className={styles.inner} key={index}>

                        <Heading size={3} space={false}>{group.title}</Heading>

                        <Space height={8} />

                        {group.list.map((item, index) => (

                            <div key={index} className={styles.list}>
                                <div className={[styles.dot, `cat-color-${slug(item[0])}`].join(" ")} />
                                <p className={`cat-color-${slug(item[0])}`}>{item[1]}</p>
                            </div>
                        ))}

                    </div>

                ))}

            </div>

        </Panel >

    )

}

interface FieldResultsSummaryAttributesProps {
    [key: string]: any,
}

export default FieldResultsSummaryAttributes;