import React, { useMemo } from 'react';
import FieldRatingsGridItem from './FieldRatingsGridItem';

import style from "./FieldRatingsGrid.module.scss";

const FieldRatingsGrid: React.FC<any> = (props: any) => {

    const { items } = props;

    return useMemo(() => (

        <>

            <div className={style.ratingsGrid}>

                {items.map((item, index) => (

                    <div className={style.ratingsGridItem} key={index}>

                        <FieldRatingsGridItem
                            {...item}
                        />

                    </div>

                ))}

            </div>

        </>

    ), [items]);

}

export default FieldRatingsGrid;
