import React, { useMemo } from 'react';
import { Col, Row, Space } from '@dex/bubl-dash';
import styles from "./TestSessionsReportBio.module.scss";
import ReportImage from '../../../elements/ReportImage/ReportImage';
import ReportLabelValue from '../../../elements/ReportLabelValue/ReportLabelValue';
import { fixedNum, get } from '@dex/bubl-helpers';
import { getAverage, getBmi } from '../../../functions/sharedTests';
import { getSum } from '../../../functions/sharedGeneral';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import RatingScaleTable from '../../../elements/RatingScale/RatingScaleTable';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartBodyWeightAndFat from '../../../elements/ChartTestSessionReport/ChartBodyWeightAndFat';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import { MetricRanking } from '../../../elements/RatingScale/RatingScaleElements';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportBio: React.FC<any> = ( props: any ) => {

    const { data, form } = props;

    const percentMass = ( a: any, b: any ) => {

        let percent: any = fixedNum( ( a / b ) * 100, 0 );

        if ( isNaN( percent ) ) percent = "-";

        return percent + "%"

    }

    const skinFoldsAverages = {
        triceps: getAverage( get( data.bio, `skinFoldsAttr.triceps` ) ),
        subscapular: getAverage( get( data.bio, `skinFoldsAttr.subscapular` ) ),
        biceps: getAverage( get( data.bio, `skinFoldsAttr.biceps` ) ),
        suprailium: getAverage( get( data.bio, `skinFoldsAttr.suprailium` ) ),
        abdomen: getAverage( get( data.bio, `skinFoldsAttr.abdomen` ) ),
        midThigh: getAverage( get( data.bio, `skinFoldsAttr.midThigh` ) ),
        calf: getAverage( get( data.bio, `skinFoldsAttr.calf` ) ),
    }

    const skinFoldsTotal = Object.values( skinFoldsAverages );

    const bmi = getBmi( data.bio.weight, data.bio.height );
    const skinfoldsTotal = getSum( skinFoldsTotal );

    return useMemo( () => (

        <div className={styles.bio}>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={16} edge>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportHeading
                        text="Stature & Girth"
                    />

                    <div className="girths">

                        <ReportLabelValue
                            label={"Height"}
                            value={data.bio.height}
                            suffix={"CM"}
                            className={"height"}

                        />

                        <ReportLabelValue
                            label={"Inseam"}
                            value={data.bio.inseam}
                            suffix={"CM"}
                            className={"inseam"}

                        />

                        <ReportLabelValue
                            label={"Arm"}
                            value={get( data.bio, "correctedGirthsRelaxedBiceps", data.bio.actualGirthsRelaxedBiceps )}
                            suffix={"CM"}
                            className={"arm"}
                        />

                        <ReportLabelValue
                            label={"Forearm"}
                            value={get( data.bio, "correctedGirthsUpperForearm", data.bio.actualGirthsUpperForearm )}
                            suffix={"CM"}
                            className={"forearm"}
                        />

                        <ReportLabelValue
                            label={"Mid-thigh"}
                            value={get( data.bio, "correctedGirthsMidThigh", data.bio.actualGirthsMidThigh )}
                            suffix={"CM"}
                            className={"midThigh"}
                        />

                        <ReportLabelValue
                            label={"Calf"}
                            value={get( data.bio, "correctedGirthsCalf", data.bio.actualGirthsCalf )}
                            suffix={"CM"}
                            className={"calf"}
                        />

                        <ReportLabelValue
                            label={"Ankle"}
                            value={get( data.bio, "correctedGirthsAnkle", data.bio.actualGirthsAnkle )}
                            suffix={"CM"}
                            className={"ankle"}
                        />

                        <ReportLabelValue
                            label={"Waist"}
                            value={get( data.bio, "correctedGirthsWaist", data.bio.actualGirthsWaist )}
                            suffix={"CM"}
                            className={"waist"}
                        />

                        <ReportLabelValue
                            label={"Hip"}
                            value={get( data.bio, "correctedGirthsHip", data.bio.actualGirthsHip )}
                            suffix={"CM"}
                            className={"hip"}
                        />

                        <div className="image">
                            <ReportImage
                                gender={data.athlete.gender}
                                path={`/report/bio/girths-:gender.png`}
                            />
                        </div>

                    </div>

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportHeading
                        text="Body Mass"
                    />

                    <div className='bodyContainer'>

                        <div className="body-mass">

                            <ReportLabelValue
                                label={"Lean Body Mass"}
                                value={data.bio.leanBodyWeight}
                                suffix={"KG"}
                                className={"bodyMass"}
                            />

                            <ReportLabelValue
                                label={"Fat Mass"}
                                value={fixedNum( data.bio.weight - data.bio.leanBodyWeight, 1 )}
                                suffix={" kg"}
                                className={"fatMass"}
                            />

                            <ReportLabelValue
                                label={"Body Weight"}
                                value={data.bio.weight}
                                suffix={"KG"}
                                className={"bodyWeight"}
                            />

                            <div className="image">
                                <ReportImage
                                    gender={data.athlete.gender}
                                    type={"med"}
                                    path={`/report/bio/body-mass-:gender-:type.png`}
                                />
                            </div>

                        </div>

                        <div className="muscle-mass">

                            <ReportLabelValue
                                label={"Upper Muscle"}
                                value={`${ data.bio.upperBodyMuscleMass }`}
                                suffix={`KG (${ percentMass( data.bio.upperBodyMuscleMass, data.bio.muscleMass ) })`}
                                className={"upperMuscle"}
                            />

                            <ReportLabelValue
                                label={"Lower Muscle"}
                                value={`${ data.bio.lowerBodyMuscleMass }`}
                                suffix={`KG (${ percentMass( data.bio.lowerBodyMuscleMass, data.bio.muscleMass ) })`}
                                className={"lowerMuscle"}
                            />

                            <ReportLabelValue
                                label={"Muscle Mass"}
                                value={`${ data.bio.muscleMass }`}
                                suffix={`KG (${ percentMass( data.bio.muscleMass, data.bio.weight ) } BW)`}
                                className={"muscleMass"}
                            />

                            <div className="image">
                                <ReportImage
                                    gender={data.athlete.gender}
                                    type={"equal"}
                                    path={`/report/bio/muscle-mass-:gender-:type.png`}
                                />
                            </div>

                        </div>

                    </div>

                    <Space height={40} />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportHeading
                        text="Skinfolds"
                    />

                    <div className="skinfolds">

                        <ReportLabelValue
                            label={"Biceps"}
                            value={data.bio.skinFoldsAttr?.bicepsAverage}
                            suffix={"mm"}
                            className={"bicep"}
                        />

                        <ReportLabelValue
                            label={"Hip"}
                            value={data.bio.skinFoldsAttr?.suprailiumAverage}
                            suffix={"mm"}
                            className={"hip"}
                        />

                        <ReportLabelValue
                            label={"Abdomin"}
                            value={data.bio.skinFoldsAttr?.abdomenAverage}
                            suffix={"mm"}
                            className={"abs"}
                        />

                        <ReportLabelValue
                            label={"Mid-Thigh"}
                            value={data.bio.skinFoldsAttr?.midThighAverage}
                            suffix={"mm"}
                            className={"midThigh"}
                        />

                        <ReportLabelValue
                            label={"Triceps"}
                            value={data.bio.skinFoldsAttr?.tricepsAverage}
                            suffix={"mm"}
                            className={"tricep"}
                        />

                        <ReportLabelValue
                            label={"Upper Back"}
                            value={data.bio.skinFoldsAttr?.subscapularAverage}
                            suffix={"mm"}
                            className={"upperBack"}
                        />

                        <ReportLabelValue
                            label={"Calf"}
                            value={data.bio.skinFoldsAttr?.calfAverage}
                            suffix={"mm"}
                            className={"calf"}
                        />

                        <div className="image">
                            <ReportImage
                                gender={data.athlete.gender}
                                path={`/report/bio/skinfolds-:gender.png`}
                            />
                        </div>

                        <ReportLabelValue
                            label={"Sum of Skinfolds (SOS)"}
                            value={fixedNum( data.bio.skinfoldsSosTotal, 2 )}
                            suffix={"mm"}
                            className={"sum"}
                        />

                    </div>

                    <Space height={60} />

                    <ReportDesc
                        label={"Body Composition"}
                        value={data?.bio?.description}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleTable
                        title={"Ratings"}
                        rows={[
                            {
                                metric: `BMI`,
                                value: fixedNum( bmi, 1 ),
                            },
                            {
                                metric: `Body Fat`,
                                value: data.bio.manualPercentageBf,
                            },
                            {
                                metric: `SOS`,
                                value: fixedNum( data.bio.skinfoldsSosTotal, 0 ),
                            },
                            {
                                metric: `Glycogen Load`,
                                value: data.bio.glycogenWholeBody,
                                altUnit: {
                                    value: data.bio.glycogenKcalWholeBody,
                                    suffix: "Kcal",
                                },
                                renderAfter: () => (
                                    <div className={styles.metricRanking}>

                                        <MetricRanking
                                            data={{
                                                title: "Upper Body",
                                                suffix: "g"
                                            }}
                                            value={isNaN( data.bio.glycogenUpperBody ) ? 0 : data.bio.glycogenUpperBody}
                                            rating={" "}
                                            altUnit={{
                                                value: data.bio.glycogenKcalUpperBody,
                                                suffix: "kcal",
                                                sep: "/"
                                            }}
                                        />

                                        <MetricRanking
                                            data={{
                                                title: "Lower Body",
                                                suffix: "g"
                                            }}
                                            value={isNaN( data.bio.glycogenLowerBody ) ? 0 : data.bio.glycogenLowerBody}
                                            rating={" "}
                                            altUnit={{
                                                value: data.bio.glycogenKcalLowerBody,
                                                suffix: "kcal",
                                                sep: "/"
                                            }}
                                        />

                                    </div>
                                )
                            },
                        ]}
                        form={form}
                        demographic={data.bio.bodyFatScale || data.demographic}
                        show={true}
                    />
                    {/* <Space />

                    <RatingScaleCard
                        metric={'Glycogen Load'}
                        value={data.bio.glycogenLoadKg}
                        form={form}
                        demographic={data.bio.glycogenScale || data.demographic}
                        altUnit={{
                            value: data.bio.glycogenKcalKg,
                            suffix: "Kcal/kg",
                        }}
                    /> */}

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <ReportHeading
                        text="Your Body Weight and % Fat Over Time"
                    />

                    <PanelChart
                        heading={"Your Body Weight and % Fat"}
                        addZero={true}
                        addZeroEnd={true}
                        data={data.history}
                        renderChart={( chartType, chartData ) => (
                            <ChartBodyWeightAndFat
                                data={chartData}
                            />
                        )}
                    />

                    <ReportChartSummary
                        label={"Your Body Weight"}
                        valueKey={'weight'}
                        indexKey={"date"}
                        data={data.history}
                        type={"size"} //performance, size
                    />

                </Col>

            </Row>

        </div>

    ), [ data ] );

}

export default TestSessionsReportBio;