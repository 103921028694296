import React from 'react';
import styles from "./GaitReportComment.module.scss";
import nl2br from 'react-nl2br';

const GaitReportComment: React.FC<GaitReportCommentProps> = (props) => {

    let { label, value, className, ...rest } = props;

    let classes = [styles.desc];

    if (className && Array.isArray(className)) classes = [...classes, ...className];
    else if (typeof className === "string") classes.push(styles[className]);

    return (

        <div
            className={classes.join(" ")}
            {...rest}
        >

            <span className={styles.label}>{nl2br(label)} </span>

            <div className={styles.valueBox}>
                {value &&
                    <div className={styles.value}>{nl2br(value)}</div>
                }
            </div>

        </div>

    )

}

interface GaitReportCommentProps {
    [key: string]: any,
}

export default GaitReportComment;