import { Col, Row, Space } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';

import styles from "./TestSessionsReport.module.scss";
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import PanelChart from '../../../elements/PanelChart/PanelChart'
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import RatingScaleTable from '../../../elements/RatingScale/RatingScaleTable';
import ChartBrDepth from '../../../elements/ChartTestSessionReport/ChartBrDepth';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartBeOverTime from '../../../elements/ChartTestSessionReport/ChartBeOverTime';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import { getHeightRating } from '../../../functions/sharedTests';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportBreathing: React.FC<any> = ( props: any ) => {

    const { data, form } = props;

    const activity = data.test.activity;
    const intensity = data?.test?.intensity;
    let gender = data.athlete?.gender;

    const [ index, setIndex ] = useState( data.history[ 0 ].date );

    const [ breathingIndex, setBreathingIndex ] = useState( data.history[ 0 ].date );

    const [ breathingKey, setBreathingKey ] = useState( "rate" );

    const getChartType = ( type ) => {
        setBreathingKey( type )
    };

    return useMemo( () => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={16} edge>

                <Col col={{ xs: 24 }}>

                    <Space height={20} />

                    <PanelChart

                        heading={"Breathing Rate And Depth"}
                        addZero={false}
                        data={data.test.avgData}
                        types={[
                            {
                                name: "rate",
                                label: "Rate",
                                data: data.allData
                            },
                            {
                                name: "depth",
                                label: "Depth",
                                data: data.allData
                            }
                        ]}
                        renderChart={( chartType, chartData ) => (
                            <ChartBrDepth
                                activity={activity}
                                data={chartData}
                                intensity={intensity}
                                type={chartType}
                                index={breathingIndex}
                                date={data.recentDate}
                                getChartType={getChartType}
                                xAxis={{
                                    interval: ChartProps.interval( activity, 'avg' ),
                                }}
                            />
                        )}
                    />

                    <ReportChartSummary
                        label={"Your Breathing Rate"}
                        valueKey={breathingKey == "rate" ? 'brMax' : 'beMax'}
                        indexKey={"date"}
                        data={data.history}
                        type={"performance"} //performance, size
                        onIndexChange={setBreathingIndex}
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <ReportHeading
                        text="Your Breathing Rate"
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleTable
                        title={"Breathing Rate"}
                        rows={[
                            {
                                metric: `Breathing Rate - Max`,
                                value: data.test.breathing.brMax
                            },
                            {
                                metric: `Breathing Rate - Avg`,
                                value: data.test.breathing.brAvg
                            },
                            {
                                metric: `Breathing Rate - Min`,
                                value: data.test.breathing.brMin
                            },
                        ]}
                        form={form}
                        demographic={data.bio.bodyFatScale || data.demographic}
                        show={true}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Breathing Rate"
                        value={data.test.breathing.breathingRateDescription}
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <Space height={20} />

                    <ReportHeading
                        text="Your Breathing Depth"
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={`Tidal Volume - ${ getHeightRating( data?.athlete?.bio?.height ) } Height`}
                        value={data.test.breathing.tvMax}
                        form={form}
                        demographic={data.test.breathing.veMaxScale || data.demographic}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Tidal Volume"
                        value={data.test.breathing.tidalVolumeDescription}
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <Space height={20} />

                    <ReportHeading
                        text="Your Ventilation Rate"
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={`Minute Ventilation - ${ getHeightRating( data?.athlete?.bio?.height ) } Height`}
                        value={data.test.breathing.vrMax}
                        form={form}
                        demographic={data.test.vo2.engineSizeScale || data.demographic}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Ventilation Rate"
                        value={data.test.breathing.ventilationRateDescription}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <Space />

                    <ReportHeading
                        text={"Your Breathing Efficiency"}
                    />

                    <PanelChart
                        heading={"Breathing Efficiency"}
                        addZero={false}
                        data={data.allData}
                        renderChart={( chartType, chartData ) => (
                            <ChartBeOverTime
                                activity={activity}
                                data={chartData}
                                index={index}
                                date={data.recentDate}
                                intensity={intensity}
                            />
                        )}
                    />

                    <ReportChartSummary
                        label={"Your Peak Breathing Efficiency"}
                        valueKey={'beMax'}
                        indexKey={"date"}
                        data={data.history}
                        type={"performance"} //performance, size
                        onIndexChange={setIndex}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={"Breathing Efficiency - Peak"}
                        value={data.test.breathing.beMax}
                        form={form}
                        demographic={data.health.demographic || data.demographic}
                        split={{
                            metric: "Breathing Efficiency - % Time",
                            value: data.test.breathing.beZoneTime,
                        }}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Breathing Efficiency"
                        value={data.test.breathing.breathingEfficiencyDescription}
                    />

                </Col>

            </Row>

        </>

    ), [ data, index, breathingIndex, breathingKey ] );

}

export default TestSessionsReportBreathing;