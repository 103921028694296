import { InputTable, InputTableCell, InputTableRow } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import { getEnabled } from '../../../functions/sweatTest';
import { DataTableCell } from '@dex/bubl-dash/src/lib/components/DataTable/DataTableRow';
import moment from 'moment';
import { getMinutesTime } from '../../../functions/sharedTests';
import * as _ from "lodash";
import { titleCase } from '@dex/bubl-helpers';

const TestSessionsReportSweatTestWorkout: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const workouts = data.test.workouts || [];
    const enabledWorkouts = getEnabled(workouts);

    const columns = enabledWorkouts.map((item, index) => {
        return { label: `Workout ${index + 1}` }
    })

    const column1 = {
        activity: "Activity",
        description: "Workout Description",
        location: "Location",
        date: "Date",
        time: "Start Time",
        weather: "Weather",
        temperature: "Temperature",
        header1: "Workout Volume & Intensity",
        duration: "Exercise Duration",
        intensity: "Intensity",
        avgHr: "Avg HR",
        avgPower: "Avg Pace/Power",
        header2: "Consumption During Activity",
        fluidIntake: "Fluids Drank",
        foodIntake: "Food Eaten",
        fluidIntakeRate: "Fluid Intake Rate",
        rehydration: "Rehydrate",
        header3: "Expenditure During Activity",
        weightloss: "Weight Loss",
        totalSweatLoss: "Sweat",
        respiratoryLoss: "Respiration",
        urineLoss: "Urine",
        energyBurn: "Energy",
        fluidLossRate: "Fluid Loss Rate",
        sodiumLossRate: "Sodium Loss Rate",
    };

    const formatDataTable = (output, key) => {

        if (output == null) return;

        let formatFunctions: any = {
            date: (output) => moment(output).format("D-MMM-YY"),
            duration: (output) => `${getMinutesTime(output)} min`,
            foodIntake: (output) => `${output} g`,
            avgHr: (output) => `${output} bpm`,
            fluidIntakeRate: (output) => `${output} ml/hr`,
            rehydration: (output) => `${output} %`,
            fluidIntake: (output) => `${output} ml`,
            weightloss: (output) => `${output} kg`,
            totalSweatLoss: (output) => `${output} ml`,
            respiratoryLoss: (output) => `${output} ml`,
            urineLoss: (output) => `${output} ml`,
            energyBurn: (output) => `${output} g`,
            fluidLossRate: (output) => `${output} ml/hr`,
            sodiumLossRate: (output) => `${output} mg/hr`,
            // avgPower: (output) => output?.includes(":") ? output : `${output} W`,
            weather: (output) => titleCase(output),
            temperature: (output) => titleCase(output),
            intensity: (output) => titleCase(output)
        };

        const formatter = formatFunctions[key];

        return formatter ? formatter(output) : output;
    };

    const header = ["header1", "header2", "header3"];

    return useMemo(() => (

        <>

            <InputTable
                columns={[
                    { label: "Description" },
                    ...columns
                ]}
            >

                {Object.entries(column1).map(([key, value], index) => (

                    <InputTableRow key={index} className={header.includes(key) ? "rmwc-data-table__head-cell" : ""}>

                        <DataTableCell style={header.includes(key) ? { fontWeight: "600" } : {}}>{value}</DataTableCell>

                        {enabledWorkouts.map((workout, workoutIndex) => (

                            <InputTableCell key={workoutIndex}  >
                                {formatDataTable(workout[key], key)}
                            </InputTableCell>

                        ))}

                    </InputTableRow>

                ))}

            </InputTable>

        </>

    ), [enabledWorkouts, column1])
}

export default TestSessionsReportSweatTestWorkout;
