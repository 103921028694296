import React from 'react';
import { BarChart, ComposedChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop, slug } from '@dex/bubl-helpers';
import { fancyTimeFormat, nearestMaxHour, nearestMaxHundered, nearestMaxThousand, nearestMinHour } from '../../functions/sharedChart';

const ChartCoachingWorkoutSwimInterval: React.FC<ChartCoachingWorkoutSwimIntervalProps> = (props) => {

    let { filters, data } = props;

    const distance = filters?.swimIntervalDistance;
    const metric = filters?.swimIntervalMetric || [];

    const getKey = (key: string) => {

        if (!key) return;

        const item = { "Avg Pace": 'paceAvg', "Best Pace": 'paceBest', "Avg HR": 'hrAvg', "Peak HR": 'hrBest', "Best Time": 'timeBest', "Avg Time": 'timeAvg' }

        return item[key];

    }

    const getAxisId = (key: string) => {

        if (!key) return;

        const item = { "Avg Pace": 'Pace [min/100m]', "Best Pace": 'Pace [min/100m]', "Avg HR": 'HR [bpm]', "Peak HR": 'HR [bpm]', "Best Time": 'Time [hh:mm]', "Avg Time": 'Time [hh:mm]' }

        return item[key];

    }

    const getDomain = (key: string) => {

        if (!key) return;

        const item = { "Avg Pace": [nearestMinHour, 240], "Best Pace": [nearestMinHour, 240], "Avg HR": [80, 200], "Peak HR": [80, 200], "Avg Time": [nearestMinHour, nearestMaxHour], "Best Time": [nearestMinHour, nearestMaxHour] }

        return item[key];

    };

    const getTick = (key: string) => {

        if (!key) return;

        const item = { "Avg Pace": fancyTimeFormat, "Best Pace": fancyTimeFormat, "Avg HR": "", "Peak HR": "", "Avg Time": fancyTimeFormat, "Best Time": fancyTimeFormat }

        return item[key];

    };

    const Axis = new ChartAxis();

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    Axis.x.label = "Week";
    Axis.x.format = undefined;
    Axis.x.key = "date";
    Axis.x.props = {
        interval: 0,
        angle: -45,
        textAnchor: "end",
    }

    if (distance == "Total") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 3,
            key: "totalDistance",
            axisLabel: distance + " [m]",
            label: distance,
            props: {
                yAxisId: distance,
                domain: [0, nearestMaxThousand],
            }
        });

    } else if (distance == "Interval") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 1,
            key: "intervalDistance",
            axisLabel: distance + " [m]",
            label: distance,
            props: {
                yAxisId: distance,
                domain: [0, nearestMaxThousand],
            }
        });
    }

    loop(metric, (_metric, i) => {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 0,
            key: getKey(_metric),
            axisLabel: getAxisId(_metric),
            label: _metric,
            props: {
                yAxisId: getAxisId(_metric),
                domain: getDomain(_metric) || [0, "dataMax"],
                tickFormatter: getTick(_metric) || undefined,
                strokeDasharray: _metric.includes("Best") || _metric.includes("Peak") ? "6 4" : "",
                stroke: ChartProps.metricColor(_metric),
                label: { fill: ChartProps.metricColor(_metric) }
            },
        });
    })

    Axis.legend = false;

    return (

        <Chart>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

            </ComposedChart>

        </Chart>

    )

}

interface ChartCoachingWorkoutSwimIntervalProps {
    [key: string]: any,
}

export default ChartCoachingWorkoutSwimInterval;