import React, { useMemo, useState } from 'react';
import TabBody from '../../../elements/TabBody/TabBody';
import LabTestsGlycogenManual from './LabTestsGlycogenManual';

const LabTestsGlycogen: React.FC<any> = (props: any) => {

    const { form, data, session, tab, setKey, editable } = props;

    const key = props._key;

    return useMemo(() => (

        <>

            <TabBody
                form={form}
                active={tab === "manual"}
                fields={["manual"]}
            >

                <LabTestsGlycogenManual
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

        </>

    ), [form.hash, data, session, tab, setKey, editable]);

}

export default LabTestsGlycogen;
