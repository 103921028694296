import React, { useMemo } from 'react';
import LabTestsSweatDehydration from '../../LabTests/Sweat/LabTestsSweatDehydration';
import { Col, FieldSelect, Row, Space } from '@dex/bubl-dash';
import { sweatTestGetActivities } from '../../../functions/sweatTest';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';

const TestSessionsReportSweatTestDehydration: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const activities = sweatTestGetActivities(form);

    return useMemo(() => (

        <>

            <Row edge gutter={"auto"} justify='center'>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldSelect
                        key={form.values.rehydration.activity}
                        form={form}
                        name='rehydration.activity'
                        defaultValue={form.values.rehydration?.activity}
                        label='Select Activity'
                        options={activities}
                        wrapClass={"w-400"}
                        isClearable={false}
                    />

                </Col>

            </Row>

            <Space height="small" />

            <ReportHeading
                text="Dehydration Over Time"
            />

            <LabTestsSweatDehydration
                form={form}
                data={data.tests}
                session={data}
                isReport={true}
            />

        </>

    ), [form.hash, data])
}

export default TestSessionsReportSweatTestDehydration;
