import React, { useMemo } from 'react';
import { Col, DataTable, DataTableCell, FieldSelect, Heading, InputTableHead, InputTableHeadCell, InputTableHeadRow, InputTableRow, Line, Row, Space } from '@dex/bubl-dash';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import { HiCheck } from 'react-icons/hi';
import styles from './FieldResult.module.scss';
import { date, fixedNum, loop, titleCase } from '@dex/bubl-helpers';
import { calculateMissedPercentage } from '../../../functions/sharedFieldSession';

const FieldResultsTeamGeneral: React.FC<any> = ( props: any ) => {

    const { report, form } = props;

    const columns = [
        {
            key: "__num",
            label: "#",
            style: { width: 44, paddingLeft: 12 },
        },
        {
            key: "fullName",
            label: "Athlete Name",
            style: { paddingLeft: 12 },
        },
        {
            key: "callName",
            label: "Short Name",
            style: { paddingLeft: 12 },
        },
        {
            key: "position",
            label: "Position",
            style: { paddingLeft: 12 },
        },
        {
            key: "gender",
            label: "Gender",
            style: { paddingLeft: 12 },
        },
        {
            key: "age",
            label: "Age",
            style: { paddingLeft: 12 },
        },
    ];

    const summaryColumns = [
        {
            key: "__num",
            label: <>&nbsp;</>,
            style: { width: 10 },
        },
        {
            key: "fullName",
            label: "Athletes",
            style: { paddingLeft: 6 },
        },
        {
            key: "totalTest",
            label: "Total Tests",
            style: { paddingLeft: 6 }
        },
        ...Object.keys( report.ratings ).map( ( key ) => ( {
            key: key,
            label: titleCase( key ),
            style: { paddingLeft: 6 },
            colSpan: report.ratings[ key ].length,
        } ) )
    ];

    const Check = <HiCheck className={styles.check} />;

    function calculateMissedTotal( report: any ): Record<string, number> {

        const missedTotal: Record<string, number> = {};

        loop( Object.values( report.tests ), ( test ) => {
            const testKey = test.value;

            if ( report.missed[ testKey ] ) {
                report.missed[ testKey ].forEach( ( missed: any ) => {
                    const athleteName = missed.name;

                    if ( missedTotal[ athleteName ] ) {
                        missedTotal[ athleteName ]++;
                    } else {
                        missedTotal[ athleteName ] = 1;
                    }
                } );
            }
        } )

        return missedTotal;
    }

    const totalMissedByAthlete = calculateMissedTotal( report );

    return useMemo( () => (

        <Row edge gutter={8}>

            <Col col={{ xs: 24 }}>
                <Heading size={2} space={false}>Assessment Details</Heading>
            </Col>

            <Col col={{ xs: 10 }}>

                <ReportMetaTable
                    data={report}
                    columnSize={8}
                    columnA={[
                        {
                            label: "Team",
                            key: "team.name",
                            format: 'titleCase',
                        },
                    ]}
                    columnB={[
                        {
                            label: "Main Sport",
                            key: "session.mainSport",
                            format: 'titleCase',
                        },
                    ]}
                    columnC={[
                        {
                            label: "Discipline",
                            key: "session.discipline",
                            format: 'titleCase',
                        },

                    ]}

                />

            </Col>

            <Col col={{ xs: 10 }}>
                <ReportMetaTable
                    data={report}
                    columnSize={8}
                    columnA={[
                        {
                            label: "Level",
                            key: "session.level",
                        },
                    ]}
                    columnB={[
                        {
                            label: "Test Period",
                            key: "session.period",
                        },
                    ]}
                    columnC={[
                        {
                            label: "Session Date",
                            key: "session.fieldTests",
                            render: ( value, row ) => {
                                return ( value || [] ).map( ( test ) => {
                                    return date( test.date, "D MMMM YYYY" );
                                } ).join( ", " );
                            }
                        }
                    ]}

                />

            </Col>

            <Col col={{ xs: 4 }}>
                <ReportMetaTable
                    data={report}
                    columnSize={24}
                    columnA={[ {
                        label: "Appraisers",
                        key: "fitters",
                        render: ( value, row ) => {
                            const fitterNames = ( value || [] ).map( ( val ) => {
                                return val.name;
                            } );
                            return fitterNames.join( ", " );
                        }
                    } ]}

                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Space />

                <Heading size={2} space={false}>Demographic</Heading>

                <DataTable
                    columns={columns}
                    data={Object.values( report.athletes )}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Space />

                <Heading size={2} space={false}>Test Summary</Heading>

                <DataTable
                    data={null}
                    columns={summaryColumns}
                >

                    <InputTableHeadRow className={styles.testSummaryHead + " table-bg-gray"} >

                        <InputTableHeadCell>
                            #
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            <span className={styles.totalAthlete}>
                                {Object.values( report.athletes ).length}
                            </span>
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            <span className={styles.totalTest}>
                                {report.tests.length}
                            </span>
                        </InputTableHeadCell>

                        {Object.values( report.tests ).map( ( key: any ) => (
                            <InputTableHeadCell style={{ maxWidth: "50px", textWrap: "balance" }} >
                                {key.label}
                            </InputTableHeadCell>
                        ) )}

                    </InputTableHeadRow>

                    {Object.values( report.athletes ).map( ( athlete: any, index ) => (

                        <InputTableRow key={index}>

                            <DataTableCell>
                                {index + 1}
                            </DataTableCell>

                            <DataTableCell>
                                {athlete.callName}
                            </DataTableCell>

                            <DataTableCell>
                                <span className={styles.test}>{calculateMissedPercentage( report.tests.length, totalMissedByAthlete[ athlete.callName ] ).missedCount}</span>
                                <span> ({calculateMissedPercentage( report.tests.length, totalMissedByAthlete[ athlete.callName ] ).percentage}%)</span>
                            </DataTableCell>

                            {Object.values( report.tests ).map( ( key: any ) => (
                                <InputTableHeadCell key={key.value}>
                                    {report.missed[ key.value ] ? (
                                        report.missed[ key.value ].some( ( missed: any ) => missed.name === athlete.callName ) ? (
                                            ""
                                        ) : (
                                            Check
                                        )
                                    ) : (
                                        Check
                                    )}
                                </InputTableHeadCell>
                            ) )}

                        </InputTableRow>

                    ) )}

                </DataTable>

            </Col>

        </Row>

    ), [ props ] );

}

export default FieldResultsTeamGeneral;
