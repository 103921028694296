import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldNumber, FieldText, Line, Seperator, useForm } from '@dex/bubl-dash';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { fixedNum, get, loop, parseNum, set } from '@dex/bubl-helpers';

const RatingCreator: React.FC<any> = (props: any) => {

    const { mainForm, gender, level, displayAs } = props;

    const form = useForm({ gender: "male", level: "untrained" });

    const bestScore = mainForm.values.bestScore || "highest";
    const decimalPlaces = parseNum(mainForm.values.decimalPlaces) || 2;

    const percentileRow = useMemo(() => {

        let percentileRow = [
            {
                min: fixedNum(parseNum(get(form.values, "mean")) + (-1.50 * get(form.values, "std")), decimalPlaces) || null,
                max: fixedNum(parseNum(get(form.values, "mean")) + (-1.28 * get(form.values, "std")), decimalPlaces) || null,
                description: "(<10% Z= -1.50)"
            },
            {
                min: fixedNum(parseNum(get(form.values, "mean")) + (-1.28 * get(form.values, "std")), decimalPlaces) || null,
                max: fixedNum(parseNum(get(form.values, "mean")) + (-0.84 * get(form.values, "std")), decimalPlaces) || null,
                description: "(10% to 20% Z= -0.84)"
            },
            {
                min: fixedNum(parseNum(get(form.values, "mean")) + (-0.84 * get(form.values, "std")), decimalPlaces) || null,
                max: fixedNum(parseNum(get(form.values, "mean")) + (-0.25 * get(form.values, "std")), decimalPlaces) || null,
                description: "(20% to 40% Z= -0.25)"
            },
            {
                min: fixedNum(parseNum(get(form.values, "mean")) + (-0.25 * get(form.values, "std")), decimalPlaces) || null,
                max: fixedNum(parseNum(get(form.values, "mean")) + (0.25 * get(form.values, "std")), decimalPlaces) || null,
                description: "(40% to 60% Z= 0.25)"
            },
            {
                min: fixedNum(parseNum(get(form.values, "mean")) + (0.25 * get(form.values, "std")), decimalPlaces) || null,
                max: fixedNum(parseNum(get(form.values, "mean")) + (0.84 * get(form.values, "std")), decimalPlaces) || null,
                description: "(60% to 80% Z= 0.84)"
            },
            {
                min: fixedNum(parseNum(get(form.values, "mean")) + (0.84 * get(form.values, "std")), decimalPlaces) || null,
                max: fixedNum(parseNum(get(form.values, "mean")) + (1.28 * get(form.values, "std")), decimalPlaces) || null,
                description: "(80% to 90% Z= 1.28)"
            },
            {
                min: fixedNum(parseNum(get(form.values, "mean")) + (1.28 * get(form.values, "std")), decimalPlaces) || null,
                max: fixedNum(parseNum(get(form.values, "mean")) + (1.50 * get(form.values, "std")), decimalPlaces) || null,
                description: "(>90% Z= 1.50)"
            },
        ];

        // if (displayAs === "steps") {

        // const end = percentileRow.length - 1;

        // percentileRow[0].min = fixedNum(parseNum(get(form.values, "mean")) + (-1.50 * get(form.values, "std")), decimalPlaces) || null;
        // percentileRow[end].max = fixedNum(parseNum(get(form.values, "mean")) + (1.50 * get(form.values, "std")), decimalPlaces) || null;

        // }

        if (bestScore === "highest") {

            percentileRow = percentileRow.reverse();

        }

        return percentileRow;

    }, [form.hash, bestScore, decimalPlaces, displayAs]);

    const handleSubmit = useCallback((values) => {

        const steps = get(mainForm, 'values.steps', []);

        let rows = [...percentileRow];

        let labels = ["Excellent", "Very Good", "Good", "Average", "Low", "Very Low", "Poor"];
        let hidden = [false, true, true, false, true, true, false];

        if (bestScore === "highest") {
            rows = rows.reverse();
            labels = labels.reverse();
            hidden = hidden.reverse();
        }

        loop(rows, (row, index) => {

            steps[index] = steps[index] || {};

            let key = `[${index}]`;

            set(steps, `${key}.label`, labels[index]);
            set(steps, `${key}.hidden`, hidden[index]);

            if (gender) key += `.${form.values.gender}`;
            if (level) key += `.${form.values.level}`;

            set(steps, `${key}.min`, parseNum(row.min));
            set(steps, `${key}.max`, parseNum(row.max));

        });

        // const min = parseNum(get(form.values, "min"));
        // const max = parseNum(get(form.values, "max"));
        // const avg = (min + max) / 2;

        if (level) {
            //steps[0].label
            //steps[0].hidden
            //steps[0].male.untrained.min
            //steps[0].male.untrained.max
        }

        mainForm.reset({
            ...mainForm.values,
            stpes: steps,
            std: get(form.values, "std"),
            mean: get(form.values, "mean"),
        });

    }, [percentileRow, mainForm.values, mainForm.key, form.values])

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            autoDiscardChanges={true}
        >

            <Panel
                heading={"Rating Creator"}
            >

                <Row gutter={"small"} edge>

                    <Col col={{ xs: 12 }}>
                        <FieldNumber
                            form={form}
                            label={"Mean"}
                            name="mean"
                            defaultValue={form.defaults?.mean}
                            required
                            allowNegative={true}
                        />
                    </Col>

                    <Col col={{ xs: 12 }}>
                        <FieldNumber
                            form={form}
                            label={"Standard Deviation"}
                            name="std"
                            defaultValue={form.defaults?.std}
                            required
                            allowNegative={true}
                        />
                    </Col>

                    {/* <Col col={{ xs: 12 }}>
                        <FieldNumber
                            form={form}
                            label={"Min"}
                            name="min"
                            defaultValue={form.defaults?.min}
                            required
                            allowNegative={true}
                        />
                    </Col>

                    <Col col={{ xs: 12 }}>
                        <FieldNumber
                            form={form}
                            label={"Max"}
                            name="max"
                            defaultValue={form.defaults?.max}
                            required
                            allowNegative={true}
                        />
                    </Col> */}

                    {level &&
                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                button
                                label="Level"
                                // key={form.values?.level}
                                form={form}
                                name={"level"}
                                defaultValue={form.defaults?.level}
                                required={true}
                                options={[
                                    { label: "Recreational", value: "untrained" },
                                    { label: "Competitive", value: "trained" },
                                    { label: "Pro", value: "elite" },
                                    { label: "All", value: "all" },
                                ]}
                            />

                        </Col>
                    }

                    {/* <Col col={{ xs: 24 }} >

                        <FieldRadio
                            button
                            form={form}
                            name="bestScore"
                            label="Best Score is"
                            defaultValue={form.defaults?.bestScore}
                            required
                            options={[
                                { value: "highest", label: "Highest" },
                                { value: "lowest", label: "Lowest" },
                            ]}
                        />

                    </Col> */}

                    {gender &&
                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                button
                                form={form}
                                name="gender"
                                label="Gender"
                                defaultValue={form.defaults.gender}
                                options={[
                                    { value: "male", label: "Male" },
                                    { value: "female", label: "Female" },
                                ]}
                                required={true}
                            />

                        </Col>
                    }

                    <Col col={{ xs: 24 }} >

                        <Line top={false} />

                        <ButtonRow>

                            <Button
                                label={"Apply"}
                            />

                            <Button
                                label={"Reset"}
                                type={"faded"}
                                onClick={form.reset}
                            />

                        </ButtonRow>

                    </Col>

                </Row>

                <Seperator
                    top={"auto"}
                    bottom={"auto"}
                    heading={"Percentiles"}
                />


                <Row gutter={2} >

                    {percentileRow.map((item, index) => (

                        <React.Fragment key={index}>

                            <Col col={{ xs: 6 }}>
                                <FieldText
                                    form={form}
                                    name={`percentile[${index}].min`}
                                    value={item.min}
                                    disabled
                                    hidden
                                />
                            </Col>

                            <Col col={{ xs: 2 }} align='center' justify='center'>
                                -
                            </Col>

                            <Col col={{ xs: 6 }}>
                                <FieldText
                                    form={form}
                                    name={`percentile[${index}].max`}
                                    value={item.max}
                                    disabled
                                    hidden
                                />
                            </Col>

                            <Col col={{ xs: 1 }} align='center' justify='center'>
                            </Col>

                            <Col col={{ xs: 9 }} align='start' justify='center'>
                                {item.description}
                            </Col>

                        </React.Fragment>

                    ))}

                </Row>

            </Panel>

        </Form>

    ), [props, handleSubmit, form.hash, percentileRow]);

}

export default RatingCreator;
