import { Col, Line, Row, Space, useMountEffect } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';

import styles from "./TestSessionsReportSummary.module.scss";
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import SummaryRating from '../../../elements/RatingScale/SummaryRating';
import { loop } from '@dex/bubl-helpers';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportSummary: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const isSprintReport = ["sprint-running", "sprint-cycling"].includes(data.reportType);

    let ratings = [
        {
            label: "Health",
            icon: require("../../../assets/images/LabReport/icon-athlete.png"),
            rating: data.summary?.ratings?.health,
        },
        {
            label: "Body Comp",
            icon: require("../../../assets/images/LabReport/icon-bio.png"),
            rating: data.summary?.ratings?.bodyComp,
        },
        isSprintReport ? null : {
            label: "Fitness",
            icon: require("../../../assets/images/LabReport/icon-fitness.png"),
            rating: data.summary?.ratings?.fitness,
        },
        isSprintReport ? null : {
            label: "Circulation",
            icon: require("../../../assets/images/LabReport/icon-circulation.png"),
            rating: data.summary?.ratings?.circulation,
        },
        isSprintReport ? null : {
            label: "Breathing",
            icon: require("../../../assets/images/LabReport/icon-breathing.png"),
            rating: data.summary?.ratings?.breathing,
        },
        isSprintReport ? null : {
            label: "Energy Metabolism",
            icon: require("../../../assets/images/LabReport/icon-energy.png"),
            rating: data.summary?.ratings?.energy,
        },
        {
            label: "Muscle Fitness",
            icon: require("../../../assets/images/LabReport/icon-muscle.png"),
            rating: data.summary?.ratings?.muscle,
        },
        {
            label: data.test.activity === "Running" ? "Speed" : "Power",
            icon: data.test.activity === "Running"
                ? require("../../../assets/images/LabReport/icon-running.png")
                : require("../../../assets/images/LabReport/icon-cycling.png"),
            rating:
                data.test.activity === "Running"
                    ? data.summary?.ratings?.speed
                    : data.summary?.ratings?.power,
        },
    ].filter(Boolean);

    return useMemo(() => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={16} edge>

                <Col col={{ xs: 24 }}>

                    <Row gutter={16} edge>

                        {ratings.map((item, index) => (
                            <React.Fragment key={index}>

                                <Col col={{ xs: 12, sm: 8, md: 6 }} justify='end' >

                                    <p className={styles.label}>{item?.label}</p>

                                    <Space height={5} />

                                    <div className={styles.iconContainer}>
                                        <img src={item?.icon} alt={"icon"} className={styles.icon} />
                                    </div>

                                    <Space height={5} />

                                    <SummaryRating value={item?.rating} />

                                </Col>

                            </React.Fragment>
                        ))}

                    </Row>

                    <Space height={30} />

                    <ReportHeading
                        text="Appraiser's Comment"
                    />

                    <ReportDesc
                        label="Test Results "
                        value={data.summary.comments ? `\n\n ${data.summary.comments}` : ""}
                    />

                    <Space height={10} />

                    <ReportDesc
                        label={`Going Forwards`}
                        value={data.summary.goingForward ? `\n\n ${data.summary.goingForward}` : ""}
                    />

                </Col>

            </Row>

        </>

    ), [data]);

}

export default TestSessionsReportSummary;