import React, { useMemo, useState } from 'react';
import PanelFieldReport from '../../../elements/PanelFieldReport/PanelFieldReport';
import FieldResultsTeamFocus from './FieldResultsTeamFocus';
import FieldResultsTeamFitness from './FieldResultsTeamFitness';
import FieldResultsTeamScore from './FieldResultsTeamScore';
import FieldResultsTeamRatings from './FieldResultsTeamRatings';
import FieldResultsTeamChange from './FieldResultsTeamChange';
import FieldResultsTeamGeneral from './FieldResultsTeamGeneral';

const FieldResultsTeam: React.FC<any> = ( props: any ) => {

    const { report, form } = props;

    const [ tab, setTab ] = useState( "general" );

    return useMemo( () => (

        <>

            <PanelFieldReport
                header={{
                    heading: report.team.name,
                    sport: report.session.period,
                    team: "TEAM RESULTS"
                }}
                tabs={[
                    { label: "General", name: "general" },
                    { label: "Scores", name: "scores" },
                    { label: "Ratings", name: "ratings" },
                    report.history.list ? { label: "% Change", name: "change" } : null,
                    { label: "Conditioning & Focus", name: "conditioning" },
                    { label: "Fitness Level", name: "fitness" },
                ].filter( Boolean )}
                tab={tab}
                setTab={setTab}
            >
                {tab === "general" &&
                    <>
                        <FieldResultsTeamGeneral
                            report={report}
                            form={form}
                        />
                    </>
                }

                {tab === "scores" &&
                    <>
                        <FieldResultsTeamScore
                            report={report}
                            form={form}
                        />
                    </>
                }

                {tab === "ratings" &&
                    <>
                        <FieldResultsTeamRatings
                            report={report}
                            form={form}
                        />
                    </>
                }

                {tab === "change" &&
                    <>
                        <FieldResultsTeamChange
                            report={report}
                            form={form}
                        />
                    </>
                }

                {tab === "conditioning" &&
                    <>
                        <FieldResultsTeamFocus
                            report={report}
                            form={form}
                        />
                    </>
                }
                {tab === "fitness" &&
                    <>
                        <FieldResultsTeamFitness
                            report={report}
                            form={form}
                        />
                    </>
                }

            </PanelFieldReport>

        </>

    ), [ props, tab ] );

}

export default FieldResultsTeam;
