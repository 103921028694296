import { Row, Col, FieldTextArea, Space, Button } from '@dex/bubl-dash';
import { get, set } from '@dex/bubl-helpers';
import React, { useCallback, useMemo } from 'react';

const LabTestsGlycogenManualSummary: React.FC<any> = (props: any) => {

    const { form, data, session, prefix } = props;

    const useDefaultText = useCallback((key: string) => {

        let text = `Glycogen is the storage form of carbohydrates (energy) in your muscle. Most people can store only enough of this fuel to last ~ 2 hours worth of moderate intensity exercise before they run out. Thus, it is important to consume additional carbohydrate fuel (gels, bars, energy drinks, fruits) when exercising over 1-2 hours.
Depletion of your energy stores(Glycogen) for different fuelling rates is illustrated in the chart above.The important information to note here is whether a selected fuelling rate is adequate enough to power you through your chosen activity at your targeted Event intensity and duration.The other important aspect is to ensure you do over consume on fuel intake.For most people, the GI tract can absorb a maximum of ~60 - 90 grams of carbohydrates per hour.By consuming any more than this, you will run the risk of upsetting your stomach and disrupting your performance / event.`;

        const values = form.values;

        set(values, key, text);

        form.reset(values);

    }, [props, form.hash]);

    return useMemo(() => (

        <Row edge gutter={8}>

            <Col col={{ xs: 20 }}>

                <FieldTextArea
                    form={form}
                    label={"Session Summary"}
                    name={`${prefix}.summary`}
                    required={false}
                    defaultValue={get(form.values, `${prefix}.summary`)}
                    rows={8}
                    maxLength={5000}
                />
            </Col>

            <Col col={{ xs: 4 }}>

                <Space />

                <Button
                    label={'Use Default Text'}
                    onClick={useDefaultText.bind(null, `${prefix}.summary`)}
                    type={'faded'}
                    confirm={
                        get(form.values, `${prefix}.summary`) ?
                            { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' }
                            :
                            undefined
                    }
                />

            </Col>

        </Row>

    ), [form.hash, data, session])
}


export default LabTestsGlycogenManualSummary;
