import { Row, Col, FieldSelect, FieldCheckbox, Box, FieldRadio, useForm, FieldAutocomplete, Space } from '@dex/bubl-dash';
import React, { useEffect, useMemo, useState } from 'react';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import ChartCoachingWorkoutSwimSummary from '../../../elements/ChartCoaching/ChartCoachingWorkoutSwimSummary';
import ChartCoachingWorkoutSwimInterval from '../../../elements/ChartCoaching/ChartCoachingWorkoutSwimInterval';
import CoachingReportWeeklyAverage from '../../../elements/CoachingReportWeeklyAverage/CoachingReportWeeklyAverage';
import CoachingReportAverageTable from '../../../elements/CoachingReportAverageTable/CoachingReportAverageTable';
import { PeriodFilter } from './CoachingReportTracking';
import CoachingApi from '../CoachingApi';
import { getClockTime } from '../../../functions/sharedTests';
import ChartProps from '../../../elements/Chart/Chart.Props';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import { fixedNum, hashCode, unslug } from '@dex/bubl-helpers';

const CoachingReportWorkoutsSwim: React.FC<any> = (props: any) => {

    const { data, form, type } = props;

    const filter = useForm({ period: "last-3-months", interval: { distances: "100 m", aids: "Without" }, endurance: {} });

    const [trackingData, setTrackingData]: any = useState([]);
    const fetch = CoachingApi.getReportData(data.id);

    useEffect(() => {

        fetch.run({
            params: { filters: filter.values, type: "workouts-swim" },
            onComplete: (response: any) => {

                setTrackingData(response);

            }

        })

    }, [filter.values.to, filter.values.from, hashCode(filter.values.interval), hashCode(filter.values.endurance)]);

    return useMemo(() => (

        <Row edge gutter={8}>

            <PeriodFilter data={data} filter={filter} />

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Swim Summary By Week"
                    classes="seperator"
                />

                <PanelChart
                    heading={("Swim Summary By Week")}
                    renderForm={(form, heading) => (

                        <Row edge gutter={8}>

                            <Col>
                                <FieldRadio
                                    form={form}
                                    name="swimSummaryVolume"
                                    defaultValue={form.defaults?.swimSummaryVolume}
                                    required
                                    // label='metric'
                                    options={[
                                        { label: "Hours", value: "Hours", color: ChartProps.indexColor(0) },
                                        { label: "KM", value: "KM", color: ChartProps.indexColor(1) },
                                        { label: "# of Swims", value: "# Of Swims", color: ChartProps.indexColor(2) },
                                    ]}
                                />
                            </Col>

                            <Col >
                                <FieldCheckbox
                                    form={form}
                                    name="swimSummaryIntensity"
                                    defaultValue={form.defaults?.swimSummaryIntensity}
                                    wrapClass={'white-checkbox'}
                                    options={[
                                        { label: "Hours > Zone 2", value: "Hours > Zone 2", color: ChartProps.metricColor("Hours > Zone 2") },
                                        { label: "Training Stimulus", value: "Training Stimulus", color: ChartProps.metricColor("Training Stimulus") },
                                    ]}
                                />
                            </Col>

                        </Row>

                    )}
                    formDefaults={{ swimSummaryVolume: "Hours", swimSummaryIntensity: ["Hours > Zone 2"] }}
                    renderChart={(chartType, chartData, values) => (
                        <ChartCoachingWorkoutSwimSummary
                            data={trackingData.weeks}
                            type={chartType}
                            rightType={form.values.swimSummary}
                            activity={type}
                            intensity={data.intensity}
                            filters={values}
                        />
                    )}

                />

            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text="Weekly Summary"
                />

                <ReportDesc
                    className="no-box"
                    value={`Your total swimming statistics for each week are shown here. Select duration, mileage, intensity or workout count to view your changes over time. Targets met for each week are also given by symbols at the bottom of each column as well as overall averages for time period selected.`}
                />

            </Col>

            <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                <CoachingReportWeeklyAverage
                    desc={unslug(filter.values?.period)}
                    columnA={[
                        { label: "Duration", value: getClockTime(trackingData?.averages?.duration / 60), suffix: "hh:mm" },
                        { label: "Mileage", value: fixedNum(trackingData?.averages?.distance, 1), suffix: "km" }
                    ]}
                    metTarget={trackingData?.averages?.durationTargetMet}
                    belowTarget={trackingData?.averages?.durationTargetBelow}
                    aboveTarget={trackingData?.averages?.durationTargetAbove}
                    classes="mini"
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Interval Workouts - Swim"
                    classes="seperator"
                />

                <PanelChart
                    heading={"Interval Workouts - Swim"}
                    renderForm={(form, heading) => (

                        <Row edge gutter={8}>

                            <Col>
                                <FieldRadio
                                    form={form}
                                    name="swimIntervalDistance"
                                    defaultValue={form.defaults?.swimIntervalDistance}
                                    // label='metric'
                                    options={[
                                        { label: "Interval", value: "Interval", color: ChartProps.indexColor(1) },
                                        { label: "Total", value: "Total", color: ChartProps.indexColor(3) },
                                    ]}
                                />
                            </Col>

                            <Col >
                                <FieldCheckbox
                                    form={form}
                                    name="swimIntervalMetric"
                                    defaultValue={form.defaults?.swimIntervalMetric}
                                    wrapClass={'white-checkbox'}
                                    // label='metric'
                                    options={[
                                        { label: "Avg Pace", value: "Avg Pace", color: ChartProps.metricColor("Avg Pace") },
                                        { label: "Best Pace", value: "Best Pace", color: ChartProps.metricColor("Best Pace") },
                                        { label: "Avg Time", value: "Avg Time", color: ChartProps.metricColor("Avg Time") },
                                        { label: "Best Time", value: "Best Time", color: ChartProps.metricColor("Best Time") },
                                        { label: "Avg HR", value: "Avg HR", color: ChartProps.metricColor("Avg HR") },
                                        { label: "Peak HR", value: "Peak HR", color: ChartProps.metricColor("Peak HR") },
                                    ]}
                                />
                            </Col>

                        </Row>

                    )}
                    addZero={false}
                    formDefaults={{ swimIntervalDistance: "Interval", swimIntervalMetric: ["Avg Pace"] }}
                    renderChart={(chartType, chartData, values) => (
                        <ChartCoachingWorkoutSwimInterval
                            data={trackingData?.intervals}
                            type={chartType}
                            activity={type}
                            intensity={data.intensity}
                            filters={values}
                        />
                    )}
                >

                </PanelChart>

            </Col>

            <Col col={{ xs: 24 }}>

                <Box
                    className='coaching-training-filters'
                    gutter={"auto"}
                >

                    <Row edge gutter={8}>

                        <Col col={{ xs: 6 }}>

                            <FieldSelect
                                form={filter}
                                name="interval.mode"
                                defaultValue={filter.defaults?.interval?.mode}
                                options={[
                                    { label: "All", value: "all" },
                                    { label: "Pool", value: "pool" },
                                    { label: "Pool - 25m", value: "pool-25m" },
                                    { label: "Pool - 50m", value: "pool-50m" },
                                    { label: "Open Water - Fresh", value: "open water-fresh" },
                                    { label: "Open Water - Salt", value: "open water-salt" },
                                ]}
                                label="Mode"
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 6 }}>

                            <FieldSelect
                                form={filter}
                                name="interval.type"
                                defaultValue={filter.defaults?.interval?.type}
                                options={[
                                    { label: "All", value: "all" },
                                    { label: "Training", value: "training" },
                                    { label: "Race Simulation", value: "race-simulation" },
                                    { label: "Event", value: "event" },
                                    { label: "Test", value: "test" },
                                    { label: "Other", value: "other" },
                                ]}
                                label="Type"
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12 }}>

                            <FieldRadio
                                form={filter}
                                name="interval.aids"
                                defaultValue={filter.defaults?.interval?.aids}
                                options={[
                                    { label: "Without", value: "Without" },
                                    { label: "With", value: "With" },
                                    { label: "All", value: "All" },
                                ]}
                                label="Aids"
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={filter}
                                name="interval.distances"
                                defaultValue={filter.defaults?.interval?.distances}
                                options={[
                                    { label: "25 m", value: "25 m" },
                                    { label: "50 m", value: "50 m" },
                                    { label: "100 m", value: "100 m" },
                                    { label: "200 m", value: "200 m" },
                                    { label: "300 m", value: "300 m" },
                                    { label: "400 m", value: "400 m" },
                                    { label: "500 m", value: "500 m" },
                                    { label: "500-1000 m", value: "500-1000 m" },
                                    { label: "1000-2000 m", value: "1000-2000 m" },
                                    { label: ">2000 m", value: ">2000 m" },
                                ]}
                                label="Distances"
                                wrapClass={'manual-input'}
                            />

                        </Col>

                    </Row>

                </Box>

                <Space />

            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text="Swim Interval Workouts"
                    desc={filter.values?.interval?.distances ? `-  ${filter.values?.interval?.distances} ` : ""}
                />

                <ReportDesc
                    className="no-box"
                    value={`Interval Workouts are those that were completed to achieve a specifc training effect usually consisting of intervals or repeat efforts. Select the measures your wish to view and filter down to see how you did on specific key workouts over time.`}
                />

            </Col>

            <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                <CoachingReportAverageTable
                    title="Interval Stats"
                    desc={unslug(filter.values?.period)}
                    avgATitle="Avg / Peak"
                    avgA={[
                        { title: "Time", valueA: getClockTime(trackingData?.intervalAverages?.timeAvg / 60), valueB: getClockTime(trackingData?.intervalMax?.timeAvg / 60), suffix: "h:mm:ss" },
                        { title: "Pace", valueA: getClockTime(trackingData?.intervalAverages?.paceAvg / 60), valueB: getClockTime(trackingData?.intervalMax?.paceAvg / 60), suffix: "min/km" }
                    ]}
                />

            </Col>

        </Row>

    ), [data, form.hash, trackingData, filter.hash]);

}


export default CoachingReportWorkoutsSwim;