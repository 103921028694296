import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, CardTabs, Modal, Panel, Space } from '@dex/bubl-dash';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';
import EditableContext from '../../../context/EditableContext';
import FieldTestsAddNew from '../FieldTest/FieldTestsAddNew';
import FieldFieldFieldTestsSingleEdit from '../../FieldTests/Components/FieldTestsSingleEdit';
import { date } from '@dex/bubl-helpers';

const FieldSessionsEditTabsSessions: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    const editable = useContext(EditableContext);

    const app = useApp();

    const [nav] = app.store("nav");

    const [testTab, setTestTab] = app.store("field-test-tab", null);

    const handleAddTest = useCallback((id) => {

        if (id && typeof id === "string") {

            setTestTab(id);
            app.navigate(nav, 'update', {});

        } else if (data.fieldTests && data.fieldTests[0]) {

            setTestTab(data.fieldTests[0].id);

        } else {

            setTestTab(null);

        }

    }, [data.fieldTestIds]);

    const tabs = useMemo(() => {

        const tabs: any = [];

        (data.fieldTests || []).map((fieldTest, index) => {

            tabs.push({
                label: date(fieldTest.date),
                desc: fieldTest.timeOfDay,
                name: fieldTest.id,
            })

        });

        if (editable) {
            tabs.push({
                label: "Add Session",
                name: "addTest"
            })
        }

        if (tabs[0] && tabs[0].name !== "addTest") {
            setTestTab(tabs[0].name);
        }

        return tabs;

    }, [data.fieldTestIds]);

    const [save, setSave] = useState(false);

    return useMemo(() => (

        <>

            {!editable &&
                <FieldSessionsEditTabsSessionsInner
                    data={data}
                    tabs={tabs}
                    tab={testTab}
                    setTab={setTestTab}
                    save={save}
                    setSave={setSave}
                    process={process}
                />
            }

            {editable &&
                <FieldSessionsEditTabsSessionsPanel
                    tab={testTab}
                    save={save}
                    setSave={setSave}
                >

                    <FieldSessionsEditTabsSessionsInner
                        data={data}
                        tabs={tabs}
                        tab={testTab}
                        setTab={setTestTab}
                        save={save}
                        setSave={setSave}
                        process={process}
                    />

                    {testTab === "addTest" &&

                        <Modal
                            show={true}
                            onClose={handleAddTest}
                            width={720}
                        >

                            <FieldTestsAddNew
                                data={data}
                                onComplete={handleAddTest}
                            />

                        </Modal>

                    }

                </FieldSessionsEditTabsSessionsPanel>

            }

        </>

    ), [props, tabs, testTab, save]);

}



const FieldSessionsEditTabsSessionsPanel: React.FC<any> = (props: any) => {

    const { tab, save, setSave } = props;

    return (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Sessions"}
                    actions={
                        <>
                            {tab && tab !== "addTest" &&
                                <Button
                                    label={"Save"}
                                    onClick={setSave.bind(null, true)}
                                    loading={save}
                                />
                            }
                        </>
                    }
                >

                    {props.children}

                </Panel>

            </Col>

        </Row>

    )

};

const FieldSessionsEditTabsSessionsInner: React.FC<any> = (props: any) => {

    const { tab, setTab, tabs, data, save, setSave, process } = props;

    return (
        <>

            <CardTabs
                activeTab={tab}
                setTab={setTab}
                tabs={tabs}
            />

            <Space />

            {(data.fieldTests || []).map((fieldTests, index) => {

                return (

                    <React.Fragment key={index}>

                        {tab == fieldTests.id &&
                            <FieldFieldFieldTestsSingleEdit
                                id={fieldTests.id}
                                session={data}
                                save={save}
                                setSave={setSave}
                                editable={process ? true : false}
                            />
                        }

                    </React.Fragment>

                )

            })}

        </>

    )

};

export default FieldSessionsEditTabsSessions;
