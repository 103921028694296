import React, { useMemo } from 'react';
import CoachingReportWorkoutsSwim from './CoachingReportWorkoutsSwim';
import CoachingReportWorkoutsBike from './CoachingReportWorkoutsBike';
import CoachingReportWorkoutsRun from './CoachingReportWorkoutsRun';

const CoachingReportWorkouts: React.FC<any> = (props: any) => {

    const { data, form, type } = props;

    return useMemo(() => (

        <>
            {type == "swim" &&
                <CoachingReportWorkoutsSwim
                    form={form}
                    data={data}
                />
            }

            {type == "bike" &&
                <CoachingReportWorkoutsBike
                    form={form}
                    data={data}
                />
            }

            {type == "run" &&
                <CoachingReportWorkoutsRun
                    form={form}
                    data={data}
                />
            }

        </>

    ), [data]);

}


export default CoachingReportWorkouts;