import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { DataTableCell, FieldHidden, FieldNumber, FieldSelect, FieldSwitch, FieldText, FieldTextArea, InputTable, InputTableCell, InputTableRow, Line, Seperator, Space } from '@dex/bubl-dash';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { get, loop, parseNum, set } from '@dex/bubl-helpers';
import { getTableRow, glycogenEventList } from '../../../functions/glycogenTest';

const EventTargetsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const [gender, setGender] = useState("male");

    let eventType = getTableRow(form.values?.eventType);

    const baseColumn = {
        key: "",
        label: "",
        alignMiddle: true,
        desc: "From - To",
        style: { width: 100 },
        number: true,
    };

    const columns = [
        {
            key: "level",
            label: "Level",
            style: { width: 200 }
        },
        ...(eventType || []).map(item => ({
            ...baseColumn,
            key: item.type.toLowerCase(),
            label: item.type,
        })),
    ];

    const oppositeGender = gender === "male" ? "female" : "male";

    const handleCopy = useCallback((values) => {

        let rows = get(form.values, "steps", [])

        set(rows, `${oppositeGender}.${values}`, get(rows, `${gender}.${values}`));

    }, [form.values, gender])

    const defaultRows = [
        { label: "Recreational", key: "recreational" },
        { label: "Competitive", key: "competitive" },
        { label: "Pro", key: "pro" },
        { label: "All", key: "all" },
    ];

    const eventList = [
        { label: "All", value: "all" },
        ...glycogenEventList
    ];

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Panel
                heading={"Event Target Details"}
            >
                <Row gutter={8} edge={true} className='test'>

                    <Col col={{ xs: 12, sm: 6 }}>

                        <FieldSelect
                            form={form}
                            name="eventType"
                            label="Event Type"
                            required={true}
                            defaultValue={form.defaults.eventType}
                            options={eventList}
                        />

                    </Col>

                    <Col col={{ xs: 12, sm: 6 }}>

                        <FieldSelect
                            form={form}
                            name="metric"
                            label="Metric"
                            required={true}
                            defaultValue={form.defaults.metric}
                            options={[
                                { value: "%FTP", label: "%FTP" },
                                { value: "%HRmax", label: "%HRmax" },
                                { value: "%VO2max", label: "%VO2max" },
                                { value: "Lactate", label: "Lactate" },
                                { value: "Lactate VLA", label: "Lactate VLA" },
                                { value: "Breathing", label: "Breathing" },
                                { value: "Strides", label: "Strides" },
                                { value: "RPE", label: "RPE" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldText
                            form={form}
                            name="title"
                            label="Title"
                            defaultValue={form.defaults.title}
                            placeholder={"optional"}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldText
                            form={form}
                            name="suffix"
                            label="Unit"
                            defaultValue={form.defaults.suffix}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldNumber
                            form={form}
                            name="decimalPlaces"
                            label="Decimal Places"
                            defaultValue={form.defaults.decimalPlaces}
                        />

                    </Col>

                    <Col col={{ xs: 12, sm: 12 }}>

                        <FieldRadio
                            button
                            form={form}
                            name="displayAs"
                            label="Display As"
                            defaultValue={form.defaults.displayAs}
                            options={[
                                { value: "scale", label: "Scale" },
                                { value: "steps", label: "Steps" },
                            ]}
                            required
                        />

                    </Col>

                    <Col col={{ xs: 12, sm: 12 }}>

                        <FieldRadio
                            button
                            form={form}
                            name="bestScore"
                            label="Best Score is"
                            defaultValue={form.defaults.bestScore}
                            required
                            options={[
                                { value: "highest", label: "Highest" },
                                { value: "lowest", label: "Lowest" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldTextArea
                            form={form}
                            label={"Description"}
                            name={"description"}
                            defaultValue={form.defaults?.description}
                            rows={4}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldText
                            form={form}
                            name="source"
                            label="Source"
                            defaultValue={form.defaults.source}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Table"}
                    top={'auto'}
                    bottom={'auto'}
                    tabs={[
                        { name: "male", label: "Male" },
                        { name: "female", label: "Female" },
                    ]}
                    activeTab={gender}
                    setTab={setGender}
                />

                <FieldHidden
                    form={form}
                    name={`steps.${oppositeGender}`}
                    value={get(form.values, `steps.${oppositeGender}`)}
                />

                <InputTable
                    key={gender}
                    columns={columns}
                >

                    {defaultRows.map((defaultRow, index) => {

                        return (

                            <InputTableRow key={index}>

                                {columns.map((column) => (

                                    <React.Fragment key={column.key}>

                                        {column.key === "level" &&
                                            <DataTableCell>
                                                {defaultRow.label}
                                            </DataTableCell>
                                        }

                                        {column.number &&

                                            <InputTableCell>

                                                <Row gutter={8} edge={true}>
                                                    <Col col={{ xs: 11 }} align="center">
                                                        <FieldNumber
                                                            form={form}
                                                            name={`steps.${gender}.${column.key}.${defaultRow.key}.min`}
                                                            defaultValue={get(form.values, `steps.${gender}.${column.key}.${defaultRow.key}.min`)}
                                                            wrapClass={"w-80"}
                                                            allowNegative={true}
                                                        />
                                                    </Col>
                                                    <Col col={{ xs: 2 }} align="center" justify='center'>
                                                        -
                                                    </Col>
                                                    <Col col={{ xs: 11 }} align="center">
                                                        <FieldNumber
                                                            form={form}
                                                            name={`steps.${gender}.${column.key}.${defaultRow.key}.max`}
                                                            defaultValue={get(form.values, `steps.${gender}.${column.key}.${defaultRow.key}.max`)}
                                                            wrapClass={"w-80"}
                                                            allowNegative={true}
                                                        />
                                                    </Col>
                                                </Row>

                                            </InputTableCell>
                                        }

                                    </React.Fragment>

                                ))}

                            </InputTableRow>
                        )
                    })}

                    <>
                        {[1].map((row, index) => (
                            <InputTableRow key={row}>

                                {columns.map((cols, colsIndex) => {

                                    return (
                                        <InputTableCell alignMiddle key={colsIndex}>
                                            {cols.key !== "level" &&
                                                <Button
                                                    label={`copy to ${gender == "male" ? "female" : "male"}`}
                                                    onClick={handleCopy.bind(null, cols.key)}
                                                    type={"faded"}
                                                    size='small'
                                                />
                                            }
                                        </InputTableCell>
                                    )
                                })}

                            </InputTableRow>

                        ))
                        }
                    </>

                </InputTable>

                <Space />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Panel>

        </Form>

    ), [gender, eventType]);

}

export default EventTargetsCreateEditForm;
