import React from 'react';

import styles from "./FieldRatingsGridLegend.module.scss";

const FieldRatingsGridLegend: React.FC<FieldRatingsGridLegendProps> = (props) => {

    return (
        <div className={styles.legend}>

            <div className={styles.item}>

                <div className={styles.dot} />
                <p>Indicates if higher or lower value is better</p>

            </div>

            <div className={styles.item}>

                <div className={styles.dashed}></div>
                <p>Indicates Team average Score</p>
            </div >
        </div >
    )
}
interface FieldRatingsGridLegendProps {
    [key: string]: any,
}

export default FieldRatingsGridLegend;