import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import EventTargetsCreateEditForm from './EventTargetsCreateEditForm';

const EventTargetsCreateNew: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    return useMemo(() => (

        <Row gutter={'auto'} edge={true}>

            <Col col={{ xs: 24 }}>

                <EventTargetsCreateEditForm
                    form={form}
                    handleSubmit={handleSubmit}
                    process={process}

                />

            </Col>

        </Row>

    ), [props, form.hash]);

}

export default EventTargetsCreateNew;
