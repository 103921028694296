import React, { useMemo, useState } from 'react';
import PanelFieldReport from '../../../elements/PanelFieldReport/PanelFieldReport';
import FieldResultsAthleteRatings from './FieldResultsAthleteRatings';
import FieldResultsAthleteRankings from './FieldResultsAthleteRankings';
import FieldResultsAthleteScore from './FieldResultsAthleteScore';
import FieldResultsAthleteSummary from './FieldResultsAthleteSummary';
import FieldResultsAthleteGeneral from './FieldResultsAthleteGeneral';

const FieldResultsAthlete: React.FC<any> = ( props: any ) => {

    const { report, form } = props;

    const [ tab, setTab ] = useState( "general" );

    return useMemo( () => (

        <PanelFieldReport
            header={{
                heading: report.athlete.fullName,
                sport: report.athlete.mainSport,
                position: report.athlete.position,
                age: report.athlete.age,
                team: report.team.name,
                date: report.session.period,
            }}
            tabs={[
                { label: "General", name: "general" },
                { label: "Scores", name: "scores" },
                { label: "Ratings", name: "ratings" },
                { label: "Rankings", name: "rankings" },
                { label: "Summary", name: "summary" },
            ]}
            tab={tab}
            setTab={setTab}
        >

            {tab === "general" &&
                <>
                    <FieldResultsAthleteGeneral
                        report={report}
                        form={form}
                    />
                </>
            }

            {tab === "scores" &&
                <>
                    <FieldResultsAthleteScore
                        report={report}
                        form={form}
                    />
                </>
            }

            {tab === "ratings" &&
                <>
                    <FieldResultsAthleteRatings
                        report={report}
                        form={form}
                    />
                </>
            }

            {tab === "rankings" &&
                <>
                    <FieldResultsAthleteRankings
                        report={report}
                        form={form}
                    />
                </>
            }

            {tab === "summary" &&
                <>
                    <FieldResultsAthleteSummary
                        report={report}
                        form={form}
                    />
                </>
            }

        </PanelFieldReport>

    ), [ props, tab ] );

}

export default FieldResultsAthlete;
