import SessionsIndex from './SessionsIndex';
import SessionsCreate from './SessionsCreate';
import SessionsSettings from './SessionsSettings';
import SessionsSingle from './SessionsSingle';

export default function (app) {

    const settings = SessionsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 8,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }
        ],
    });

    SessionsIndex(app, settings);

    SessionsCreate(app, settings);

    SessionsSingle(app, settings);

}