import { Button, Col, DataTableCell, FieldNumber, FieldSelect, FieldTextArea, FieldTextTime, Heading, InputTable, InputTableRow, Line, MediaImage, Row, Space, useMountEffect } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import { GoDash } from "react-icons/go";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import styles from "./GaitReportMetric.module.scss";
import { ColoredHeader } from './GaitReportPage';
import { get } from '@dex/bubl-helpers';
import { getRunDifference } from '../../../functions/sharedGait';
import nl2br from "react-nl2br"

const GaitReportMetric: React.FC<any> = (props: any) => {

    const { data, form, run, index } = props;

    const runData = run;

    const metricColumns = runData?.metrics?.map((item, index) => {
        if (index === 0) {
            return { label: `Trial ${index + 1}`, colSpan: 2 };
        } else {
            return [
                { label: `Trial ${index + 1}` },
                { label: "Changes" }
            ];
        }
    }).flat();

    return useMemo(() => (

        <Row gutter={20} edge>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Running Metrics"
                    desc={`${runData.date}`}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <InputTable
                    columns={[
                        {
                            label: (
                                <img
                                    src={require(`../../../assets/images/Cover/GARMINWATCH.png`)}
                                    style={{ width: "50px" }}
                                />
                            )
                        },
                        ...(metricColumns ? metricColumns : [])
                    ]}
                    style={{ overflow: "auto", minHeight: 600 }}
                >

                    <InputTableRow >

                        <DataTableCell >
                            <strong>Condition</strong>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell colSpan={2} className="table-bdr-left-gray">
                                    {column.condition}
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        <DataTableCell >
                            <strong>  Shoes </strong>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell colSpan={2} className="table-bdr-left-gray">
                                    {column.shoe}
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        <DataTableCell >
                            <strong>Duration / Distance</strong>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell className="table-bdr-left-gray">
                                    {column.trialDuration} / {column.trialDistance} m
                                </DataTableCell>

                                <DataTableCell >
                                    {i == 0 ?
                                        ""
                                        :
                                        <>
                                            {getRunDifference(get(runData, `metrics`), i, 'trialDuration')} <span className={styles.backslash}>/</span> {getRunDifference(get(runData, `metrics`), i, 'trialDistance')}
                                        </>
                                    }
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        <DataTableCell >
                            <strong>Treadmill - Speed/Pace</strong>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell className="table-bdr-left-gray">
                                    {column.treadmillSpeed} kph / {column.treadmillPace} min/km
                                </DataTableCell>

                                <DataTableCell >
                                    {i == 0 ?
                                        ""
                                        :
                                        getRunDifference(get(runData, `metrics`), i, 'treadmillSpeed')
                                    }
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        <DataTableCell >
                            <strong>Pod - Speed / Pace</strong>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell className="table-bdr-left-gray">
                                    {column.podSpeed} kph /{column.podPace} mm:ss/km
                                </DataTableCell>

                                <DataTableCell >
                                    {i == 0 ?
                                        ""
                                        :
                                        <>
                                            {getRunDifference(get(runData, `metrics`), i, 'podSpeed')} <span className={styles.backslash}>/</span> {getRunDifference(get(runData, `metrics`), i, 'podPace')}
                                        </>
                                    }
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        <DataTableCell >
                            <strong>Cadence - Avg/Max</strong>
                            <p>(170-180 spm)</p>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell className="table-bdr-left-gray">
                                    {column.cadence} / {column.cadenceMax} spm
                                </DataTableCell>

                                <DataTableCell >
                                    {i == 0 ? "" : getRunDifference(get(runData, `metrics`), i, 'cadence')}
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        <DataTableCell >
                            <strong>Stride Length</strong>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell className="table-bdr-left-gray">
                                    {column.strideLength} m
                                </DataTableCell>

                                <DataTableCell >
                                    {i == 0 ?
                                        ""
                                        :
                                        getRunDifference(get(runData, `metrics`), i, 'strideLength')
                                    }
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        <DataTableCell >
                            <strong>Vertical Oscillation</strong>
                            <p>(6 to 10 cm)</p>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell className="table-bdr-left-gray">
                                    {column.verticalOscillation} cm
                                </DataTableCell>

                                <DataTableCell >
                                    {i == 0 ? "" :
                                        getRunDifference(get(runData, `metrics`), i, 'verticalOscillation')
                                    }
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        <DataTableCell >
                            <strong>Vertical Ratio</strong>
                            <p>(6.3% to 9.9%)</p>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell className="table-bdr-left-gray">
                                    {column.verticalRatio} %
                                </DataTableCell>

                                <DataTableCell >
                                    {i == 0 ? "" :
                                        getRunDifference(get(runData, `metrics`), i, 'verticalRatio')
                                    }
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        <DataTableCell >
                            <strong>Ground Contact Time(GCT)</strong>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell className="table-bdr-left-gray">
                                    {column.groundContactTime} ms
                                </DataTableCell>

                                <DataTableCell >
                                    {i == 0 ?
                                        ""
                                        :
                                        getRunDifference(get(runData, `metrics`), i, 'groundContactTime')
                                    }
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        <DataTableCell >
                            <strong>GCT Balance</strong>
                            <p>Left / Right</p>
                            <p>(49.5% to 50.5%)</p>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell className="table-bdr-left-gray">
                                    <>{column.gtcLeft} % / {column.gtcRight} %</>
                                </DataTableCell>

                                <DataTableCell >
                                    {i == 0 ? "" :
                                        <> {getRunDifference(get(runData, `metrics`), i, 'gtcLeft')} <span className={styles.backslash}>/</span> {getRunDifference(get(runData, `metrics`), i, 'gtcRight')}</>
                                    }

                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    {/* <InputTableRow >

                        <DataTableCell >
                            <strong>Heartrate</strong>
                            <p>Avg / Max</p>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell className="table-bdr-left-gray">
                                    {column.hrAvg} / {column.hrMax} bpm
                                </DataTableCell>

                                <DataTableCell >
                                    {i == 0 ?
                                        ""
                                        :
                                        <> {getRunDifference(get(runData, `metrics`), i, 'hrAvg')} <span className={styles.backslash}>/</span> {getRunDifference(get(runData, `metrics`), i, 'hrMax')}</>
                                    }
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        <DataTableCell >
                            <strong>Elevation</strong>
                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <React.Fragment key={i}>

                                <DataTableCell className="table-bdr-left-gray">
                                    {column.elevation} m
                                </DataTableCell>

                                <DataTableCell >
                                    {i == 0 ?
                                        ""
                                        :
                                        getRunDifference(get(runData, `metrics`), i, 'elevation')
                                    }
                                </DataTableCell>

                            </React.Fragment>

                        ))}

                    </InputTableRow> */}

                    <InputTableRow >

                        <DataTableCell >

                            <strong>
                                Comment
                            </strong>

                        </DataTableCell>

                        {runData?.metrics?.map((column, i) => (

                            <DataTableCell colSpan={2} className="table-bdr-left-gray" style={{ maxWidth: "200px" }} key={i}>
                                <p className={styles.comment}>
                                    {nl2br(column.comments)}
                                </p>
                            </DataTableCell>

                        ))}

                    </InputTableRow>

                </InputTable>

            </Col>

            <Col col={{ xs: 24 }}>

                <p className='sub-heading-title'>Change Legend</p>

                <Space height={8} />

                <Row edge gutter={2} className={styles.legend}>

                    <Col col={{ xs: 8 }}>

                        <Row edge gutter={8} >

                            <Col col={{ xs: 4 }} align='end' >
                                <GoDash className={styles.dash} />
                            </Col>

                            <Col col={{ xs: 20 }} justify='center'>
                                <strong>
                                    No Change
                                </strong>
                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 8 }}>

                        <Row edge gutter={8} >

                            <Col col={{ xs: 4 }} align='end'>
                                <HiArrowUp className={styles.up} />
                            </Col>

                            <Col col={{ xs: 20 }} justify='center'>
                                <strong>
                                    Increased
                                </strong>
                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 8 }}>

                        <Row edge gutter={8} >

                            <Col col={{ xs: 4 }} align='end'>
                                <HiArrowDown className={styles.down} />
                            </Col>

                            <Col col={{ xs: 20 }} justify='center'>
                                <strong>
                                    Decreased
                                </strong>
                            </Col>

                        </Row>

                    </Col>

                </Row>

            </Col>

        </Row>

    ), [data]);

}

export default GaitReportMetric;