import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import { getFootPhoto, getFootLengthDifference, getArchLengthDifference, getWidthDifference } from '../../../functions/sharedBikeFit';

const SessionsEditTabsFoot: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Foot"}
            {...props}
        >
            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12, lg: 6 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldNumber
                                form={form}
                                name="foot.footLengthLeft"
                                label="Foot Length (Left)"
                                suffix={" mm"}
                                defaultValue={form.defaults.foot.footLengthLeft}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Width (Left)"}
                                name='foot.widthLeft'
                                defaultValue={form.defaults.foot.widthLeft}
                                options={[
                                    { value: "Narrow", label: "Narrow" },
                                    { value: "Normal", label: "Normal" },
                                    { value: "Wide", label: "Wide" },
                                    { value: "Somewhat Wide", label: "Somewhat Wide" },
                                    { value: "Very Wide", label: "Very Wide" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldNumber
                                form={form}
                                name="foot.widthMeasureLeft"
                                label="Width Measure (Left)"
                                defaultValue={form.defaults.foot.widthMeasureLeft}
                                suffix=" mm"
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldNumber
                                form={form}
                                name="foot.archLengthLeft"
                                label="Arch Length (Left)"
                                defaultValue={form.defaults.foot.archLengthLeft}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Arch Height (Left)"}
                                name='foot.archHeightLeft'
                                defaultValue={form.defaults.foot.archHeightLeft}
                                options={[
                                    { value: "Flat", label: "Flat" },
                                    { value: "Low", label: "Low" },
                                    { value: "Moderate", label: "Moderate" },
                                    { value: "High", label: "High" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Arch Flexibility (Left)"}
                                name='foot.archFlexibilityLeft'
                                defaultValue={form.defaults.foot.archFlexibilityLeft}
                                options={[
                                    { label: "Flexes a lot (Nearly or Fully Flattens)", value: "Flexes a lot" },
                                    { label: "Flexes Moderately (Some Movement)", value: "Flexes Moderately" },
                                    { label: "Flexes A bit (Only Slight Movement)", value: "Flexes A bit" },
                                    { label: "Rigid (No Movement)", value: "Rigid" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Forefoot (Left)"}
                                name='foot.forefootLeft'
                                defaultValue={form.defaults.foot.forefootLeft}
                                options={[
                                    { value: "Neutral", label: "Neutral" },
                                    { value: "Varus", label: "Varus" },
                                    { value: "Varus++", label: "Varus++" },
                                    { value: "Valgus", label: "Valgus" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Heel (Left)"}
                                name='foot.heelLeft'
                                defaultValue={form.defaults.foot.heelLeft}
                                options={[
                                    { value: "Neutral", label: "Neutral" },
                                    { value: "Inwards", label: "Inwards" },
                                    { value: "Inwards++", label: "Inwards++" },
                                    { value: "Outwards", label: "Outwards" },
                                    { value: "Outwards++", label: "Outwards++" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Foot Inversion Left (Unloaded)"}
                                name='foot.footInversionLeft'
                                required={false}
                                defaultValue={form.defaults.foot.footInversionLeft}
                                options={[
                                    { value: "Neutral", label: "Neutral" },
                                    { value: "Rolled Inwards", label: "Rolled Inwards" },
                                    { value: "Rolled Inwards++", label: "Rolled Inwards++" },
                                    { value: "Rolled Outwards", label: "Rolled Outwards" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Walking Orientation (Left)"}
                                name='foot.footWalkLeft'
                                required={false}
                                defaultValue={form.defaults.foot.footWalkLeft}
                                options={[
                                    // { value: "Neutral", label: "Neutral" },
                                    // { value: "Turned Outwards", label: "Turned Outwards" },
                                    // { value: "Turned Inwards", label: "Turned Inwards" }
                                    { value: "Straight", label: "Straight" },
                                    { value: "Toed Out", label: "Toed Out" },
                                    { value: "Toed In", label: "Toed In" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldNumber
                                form={form}
                                name="foot.measuredShoeLeft"
                                label="Measured Shoe Size (Left)"
                                defaultValue={form.defaults.foot.measuredShoeLeft}
                                suffix=" EUR"
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, md: 12, lg: 6 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }} align='center' justify='center' className='text-center'>

                            <img src={require('../../../assets/images/Foot/Foot' + getFootPhoto("Arch", "Left", (form.values.foot.archHeightLeft || "").replace("Flat", "Low") || "moderate") + '.png')}
                                alt="Foot Fore"
                                className="footImageArch"
                            />

                        </Col>

                        <Col col={{ xs: 24 }} align='center' justify='center' className='text-center'>

                            <img src={require('../../../assets/images/Foot/Foot' + getFootPhoto("Forefoot", "Left", (form.values.foot.forefootLeft || "").replace(/\+/g, "") || "neutral") + '.png')}
                                alt="Foot Fore"
                                className="footImageForeFoot"
                            />

                        </Col>

                        <Col col={{ xs: 24 }} align='center' justify='center' className='text-center'>

                            <img src={require('../../../assets/images/Foot/Foot' + getFootPhoto("LeftHeel", "", (form.values.foot.heelLeft || "").replace(/\+/g, "") || "neutral") + '.png')}
                                alt="Foot Left Heel"
                                className="footImagedHeel"
                            />

                        </Col>


                    </Row>


                </Col>

                <Col col={{ xs: 24, md: 12, lg: 6 }} >

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }} align='center' justify='center' className='text-center'>


                            <img src={require('../../../assets/images/Foot/Foot' + getFootPhoto("Arch", "Right", (form.values.foot.archHeightRight || "").replace("Flat", "Low") || "moderate") + '.png')}
                                alt="Foot Fore"
                                className="footImageArch"
                            />

                        </Col>

                        <Col col={{ xs: 24 }} align='center' justify='center' className='text-center'>

                            <img src={require('../../../assets/images/Foot/Foot' + getFootPhoto("Forefoot", "Right", (form.values.foot.forefootRight || "").replace(/\+/g, "") || "neutral") + '.png')}
                                alt="Foot Fore foot Right"
                                className="footImageForeFoot"
                            />

                        </Col>

                        <Col col={{ xs: 24 }} align='center' justify='center' className='text-center'>

                            <img src={require('../../../assets/images/Foot/Foot' + getFootPhoto("RightHeel", "", (form.values.foot.heelRight || "").replace(/\+/g, "") || "neutral") + '.png')}
                                alt="Foot Right Heel"
                                className="footImagedHeel"
                            />

                        </Col>

                    </Row>


                </Col>

                <Col col={{ xs: 24, md: 12, lg: 6 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldNumber
                                form={form}
                                name="foot.footLengthRight"
                                label="Foot Length (Right)"
                                suffix={" mm"}
                                defaultValue={form.defaults.foot.footLengthRight}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Width (Right)"}
                                name='foot.widthRight'
                                defaultValue={form.defaults.foot.widthRight}
                                options={[
                                    { value: "Narrow", label: "Narrow" },
                                    { value: "Normal", label: "Normal" },
                                    { value: "Wide", label: "Wide" },
                                    { value: "Somewhat Wide", label: "Somewhat Wide" },
                                    { value: "Very Wide", label: "Very Wide" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldNumber
                                form={form}
                                name="foot.widthMeasureRight"
                                label="Width Measure (Right)"
                                defaultValue={form.defaults.foot.widthMeasureRight}
                                suffix=" mm"
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldNumber
                                form={form}
                                name="foot.archLengthRight"
                                label="Arch Length (Right)"
                                defaultValue={form.defaults.foot.archLengthRight}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Arch Height (Right)"}
                                name='foot.archHeightRight'
                                defaultValue={form.defaults.foot.archHeightRight}
                                options={[
                                    { value: "Flat", label: "Flat" },
                                    { value: "Low", label: "Low" },
                                    { value: "Moderate", label: "Moderate" },
                                    { value: "High", label: "High" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Arch Flexibility (Right)"}
                                name='foot.archFlexibilityRight'
                                defaultValue={form.defaults.foot.archFlexibilityRight}
                                options={[
                                    { label: "Flexes a lot (Nearly or Fully Flattens)", value: "Flexes a lot" },
                                    { label: "Flexes Moderately (Some Movement)", value: "Flexes Moderately" },
                                    { label: "Flexes A bit (Only Slight Movement)", value: "Flexes A bit" },
                                    { label: "Rigid (No Movement)", value: "Rigid" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Forefoot (Right)"}
                                name='foot.forefootRight'
                                defaultValue={form.defaults.foot.forefootRight}
                                options={[
                                    { value: "Neutral", label: "Neutral" },
                                    { value: "Varus", label: "Varus" },
                                    { value: "Varus++", label: "Varus++" },
                                    { value: "Valgus", label: "Valgus" }
                                ]}
                            />

                        </Col>


                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Heel (Right)"}
                                name='foot.heelRight'
                                defaultValue={form.defaults.foot.heelRight}
                                options={[
                                    { value: "Neutral", label: "Neutral" },
                                    { value: "Inwards", label: "Inwards" },
                                    { value: "Inwards++", label: "Inwards++" },
                                    { value: "Outwards", label: "Outwards" },
                                    { value: "Outwards++", label: "Outwards++" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Foot Inversion Right (Unloaded)"}
                                name='foot.footInversionRight'
                                required={false}
                                defaultValue={form.defaults.foot.footInversionRight}
                                options={[
                                    { value: "Neutral", label: "Neutral" },
                                    { value: "Rolled Inwards", label: "Rolled Inwards" },
                                    { value: "Rolled Inwards++", label: "Rolled Inwards++" },
                                    { value: "Rolled Outwards", label: "Rolled Outwards" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSelect
                                form={form}
                                label={"Walking Orientation (Right)"}
                                name='foot.footWalkRight'
                                required={false}
                                defaultValue={form.defaults.foot.footWalkRight}
                                options={[
                                    // { value: "Neutral", label: "Neutral" },
                                    // { value: "Turned Outwards", label: "Turned Outwards" },
                                    // { value: "Turned Inwards", label: "Turned Inwards" }
                                    { value: "Straight", label: "Straight" },
                                    { value: "Toed Out", label: "Toed Out" },
                                    { value: "Toed In", label: "Toed In" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldNumber
                                form={form}
                                name="foot.measuredShoeRight"
                                label="Measured Shoe Size (Right)"
                                defaultValue={form.defaults.foot.measuredShoeRight}
                                suffix=" EUR"
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Line />

                </Col>

            </Row>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <Row gutter={8} edge={true}>


                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldText
                                form={form}
                                name="foot.footLengthDifference"
                                label="Foot Length Difference"
                                required={false}
                                disabled
                                hidden
                                value={getFootLengthDifference(form.values.foot.footLengthLeft, form.values.foot.footLengthRight)}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldText
                                form={form}
                                name="foot.archLengthDifference"
                                label="Arch Length Difference"
                                required={false}
                                disabled
                                hidden
                                value={getArchLengthDifference(form.values.foot.archLengthLeft, form.values.foot.archLengthRight)}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldText
                                form={form}
                                name="foot.widthDifference"
                                label="Width Difference"
                                required={false}
                                disabled
                                hidden
                                value={getWidthDifference(form.values.foot.widthMeasureLeft, form.values.foot.widthMeasureRight)}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <Line />

                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Comments"}
                        name={'foot.comments'}
                        required={false}
                        defaultValue={form.defaults.foot.comments}
                        rows={4}
                    />

                </Col>

            </Row>

        </SessionsEditTabsPanel>


    ), [props]);

}

export default SessionsEditTabsFoot;
