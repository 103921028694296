import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldAutocomplete, FieldList, FieldNumber, FieldRadio, FieldSelect, FieldSwitch, FieldText, FieldTextArea, FieldUpload } from '@dex/bubl-dash';
import { getFieldTestCategories, getFieldTestSubCategories } from '../../../functions/categories';

const FieldTemplatesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    // let categoryOptions = getFieldTestCategories();
    // let subCategoryOptions = getFieldTestSubCategories(form.values.category);

    return useMemo(() => (

        <Panel
            heading={"Field Test Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, lg: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, sm: 12 }}>

                                <FieldText
                                    form={form}
                                    name='name'
                                    label='Test Name'
                                    defaultValue={form.defaults.name}
                                    required
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12 }}>

                                <FieldSelect
                                    form={form}
                                    name='units'
                                    label='Units'
                                    defaultValue={form.defaults.suffix}
                                    options={[
                                        { value: "%", label: "%" },
                                        { value: "bpm", label: "bpm" },
                                        { value: "brpm", label: "brpm" },
                                        { value: "cm", label: "cm" },
                                        { value: "g", label: "g" },
                                        { value: "g/hr", label: "g/hr" },
                                        { value: "kcal/hr", label: "kcal/hr" },
                                        { value: "kg", label: "kg" },
                                        { value: "km", label: "km" },
                                        { value: "kph", label: "kph" },
                                        { value: "m", label: "m" },
                                        { value: "min/km", label: "min/km" },
                                        { value: "ml/kgmin", label: "ml/kgmin" },
                                        { value: "mm", label: "mm" },
                                        { value: "mmhg", label: "mmhg" },
                                        { value: "mmol/L", label: "mmol/L" },
                                        { value: "sec", label: "sec" },
                                        { value: "w", label: "w" },
                                        { value: "yards", label: "yards" }
                                    ]}
                                    allowCustomOption={true}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12 }}>

                                <FieldAutocomplete
                                    form={form}
                                    name="category"
                                    label=" Test Category"
                                    defaultValue={form.defaults.category}
                                    group={"FieldTestCategory"}
                                    required
                                    preload
                                    transform={"capitalize"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12 }}>

                                <FieldAutocomplete
                                    key={form.values.category}
                                    form={form}
                                    name="subcategory"
                                    label="Test Subcategory"
                                    defaultValue={form.defaults.subcategory}
                                    group={"FieldTestSubCategory." + form.values.category}
                                    preload
                                    transform={"capitalize"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    label='Description'
                                    name='description'
                                    defaultValue={form.defaults.description}
                                    row={4}

                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12 }}>

                                <FieldList
                                    form={form}
                                    name={'attributes'}
                                    label={"Attributes"}
                                    addButtonLabel={"Add Attribute"}
                                >

                                    {(form.values.attributes || []).map((attribute, n) => (

                                        <React.Fragment key={form.key + "-" + n}>

                                            <FieldAutocomplete
                                                form={form}
                                                name={`attributes[${n}]`}
                                                defaultValue={attribute}
                                                group='fitnessAttributes'
                                            />

                                        </React.Fragment>

                                    ))}

                                </FieldList>

                            </Col>

                            <Col col={{ xs: 24, sm: 12 }}>

                                <FieldSwitch
                                    form={form}
                                    label='Hide On Condition & Focus ?'
                                    name='disableOnConditionFocus'
                                    defaultValue={form.defaults.disableOnConditionFocus}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, lg: 8 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldSelect
                                    button
                                    form={form}
                                    name="options"
                                    label="Options"
                                    defaultValue={form.defaults.options}
                                    options={[
                                        { value: "left/right", label: "Left / Right" },
                                        { value: "7site", label: "7 Site" },
                                        { value: "overall", label: "Overall" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24 }}>

                                <FieldNumber
                                    form={form}
                                    name='decimalPlace'
                                    label='Decimal Places'
                                    defaultValue={form.defaults.decimalPlace}
                                    placeholder='#'
                                    min={0}
                                    max={3}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24 }}>

                                <FieldRadio
                                    button
                                    form={form}
                                    name="bestScore"
                                    label="Best Score is"
                                    defaultValue={form.defaults.bestScore}
                                    options={[
                                        { value: "highest", label: "Highest" },
                                        { value: "lowest", label: "Lowest" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    name='photo'
                                    folder='admin-media'
                                    label='Photo'
                                    defaultValue={form.defaults.photo}
                                    multiple={false}
                                    accept='image/*'
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default FieldTemplatesCreateEditForm;
