import React, { useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';

import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import { get } from '@dex/bubl-helpers';

const SessionsEditTabsPosture: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;


    let allParts = [
        ...get(form.values, 'posture.head', []),
        ...get(form.values, 'posture.upperExtremity', []),
        ...get(form.values, 'posture.spine', []),
        ...get(form.values, 'posture.lowerExtremity', []),
    ].filter(Boolean);

    useEffect(() => {

        if (!form.values.posture.normalPosture) return;
        if (!allParts.length) return;

        form.handleChange({ reset: true, name: "posture.head", value: [] });
        form.handleChange({ reset: true, name: "posture.upperExtremity", value: [] });
        form.handleChange({ reset: true, name: "posture.spine", value: [] });
        form.handleChange({ reset: true, name: "posture.lowerExtremity", value: [] });

        form.setKey();

    }, [form.values.posture.normalPosture]);

    useEffect(() => {

        if (!form.values.posture.normalPosture) return;

        if (!allParts.length) return;

        form.handleChange({ reset: true, name: "posture.normalPosture", value: false });

        form.setKey();

    }, [allParts]);

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Posture"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldSwitch
                                label={'Normal Posture'}
                                form={form}
                                name={'posture.normalPosture'}
                                defaultValue={form.defaults.posture.normalPosture}
                            />

                        </Col>

                        {/* <Col col={{ xs: 24, md: 24 }}>

                                <Line />

                            </Col> */}

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Head"}
                                name='posture.head'
                                required={false}
                                defaultValue={form.defaults.posture.head}
                                options={[
                                    { value: "Head Forward", label: "Forward" },
                                    { value: "Head Straight", label: "Straight" },
                                    { value: "Head Tilted Left", label: "Tilted Left" },
                                    { value: "Head Tilted Right", label: "Tilted Right" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Upper Extremity"}
                                name='posture.upperExtremity'
                                required={false}
                                defaultValue={form.defaults.posture.upperExtremity}
                                options={[
                                    { value: "Shoulders Rounded", label: "Shoulders Rounded" },
                                    { value: "Left Shoulder Higher", label: "Left Shoulder Higher" },
                                    { value: "Right Shoulder Higher", label: "Right Shoulder Higher" },
                                    { value: "Arms Rotated In", label: "Arms Rotated In" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Torso/Spine"}
                                name='posture.spine'
                                required={false}
                                defaultValue={form.defaults.posture.spine}
                                options={[
                                    { value: "Kyphosis", label: "Kyphosis" },
                                    { value: "Lordosis", label: "Lordosis" },
                                    { value: "Flat Back", label: "Flat Back" },
                                    { value: "Spinal Scoliosis", label: "Spinal Scoliosis" },
                                    { value: "Forward Sway", label: "Forward Sway" },
                                    { value: "Rearway Sway", label: "Rearway Sway" },
                                    { value: "Belly Protrusion", label: "Belly Protrusion" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Lower Extremity"}
                                name='posture.lowerExtremity'
                                required={false}
                                defaultValue={form.defaults.posture.lowerExtremity}
                                options={[
                                    { value: "Knees Inward", label: "Knees Inward" },
                                    { value: "Knees Outward", label: "Knees Outward" },
                                    { value: "Knees Hyperextended", label: "Knees Hyperextended" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldSelect
                                form={form}
                                label={"Pedal Stance"}
                                name='posture.pedalStance'
                                required={false}
                                defaultValue={form.defaults.posture.pedalStance}
                                options={[
                                    { value: 'Not Evaluated', label: "Not Evaluated" },
                                    { value: 'Wide', label: "Wide" },
                                    { value: 'Normal', label: "Normal" },
                                    { value: 'Narrrow', label: "Narrrow" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldSelect
                                form={form}
                                label={"Thigh Gap"}
                                name='posture.thighGap'
                                required={false}
                                defaultValue={form.defaults.posture.thighGap}
                                options={[
                                    { value: 'Not Evaluated', label: "Not Evaluated" },
                                    { value: 'Full Contact', label: "Full Contact" },
                                    { value: 'Partial Contact', label: "Partial Contact" },
                                    { value: 'No Contact', label: "No Contact" }
                                ]}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, md: 8 }} align='center' justify='center' className='text-center'>

                    {form.values.athlete.gender === 'female' &&

                        <img src={require('../../../assets/images/PostureFemale.png')}
                            alt="Posture"
                            className="postureImage"
                        />

                    }

                    {form.values.athlete.gender === 'male' &&

                        <img src={require('../../../assets/images/PostureMale.png')}
                            alt="Posture"
                            className="postureImage"
                        />

                    }

                </Col>

                <Col col={{ xs: 24, md: 16 }}>

                    <Line />

                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Comments"}
                        name={'posture.comments'}
                        required={false}
                        defaultValue={form.defaults.posture.comments}
                        rows={4}
                        maxLength={200}
                    />

                </Col>

            </Row>

        </SessionsEditTabsPanel>


    ), [props]);

}

export default SessionsEditTabsPosture;
