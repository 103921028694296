import TrainingZonesIndex from './TrainingZonesIndex';
import TrainingZonesCreate from './TrainingZonesCreate';
import TrainingZonesSettings from './TrainingZonesSettings';
import TrainingZonesSingle from './TrainingZonesSingle';

export default function (app) {

    const settings = TrainingZonesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }
        ],
    });

    TrainingZonesIndex(app, settings);

    TrainingZonesCreate(app, settings);

    TrainingZonesSingle(app, settings);

}