import React, { useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash';
import styles from "./ReportChartSummary.module.scss";
import { get, isEmpty, loop, parseNum } from '@dex/bubl-helpers';
import { FieldSelect, Space, useForm, useUpdateEffect } from '@dex/bubl-dash';
import { FiChevronDown } from 'react-icons/fi';

const ReportChartSummary: React.FC<ReportChartSummaryProps> = ( props ) => {

    let { label, valueKey, indexKey, data, space, type, className, index, onIndexChange } = props;

    let inverted = props.inverted || false;

    let classes = [ styles.summary ];

    if ( className && Array.isArray( className ) ) classes = [ ...classes, ...className ];
    else if ( typeof className === "string" ) classes.push( className );

    const diffLabels = {
        size: {
            plus: "increased by",
            minus: "decreased by",
            little: "changed little",
            none: "not changed",
        },
        performance: {
            plus: "improved by",
            minus: "regressed by",
            little: "changed little",
            none: "not changed",
        }
    }

    const { defined, points, options } = useMemo( () => {

        const points: any = {};
        const defined: any = [];
        const options: any = [];

        data.map( ( row: any, i ) => {

            const index = get( row, indexKey );
            const value = get( row, valueKey );

            defined.push( { date: index, isNotEmpty: !isEmpty( value ) } );
            points[ index ] = parseNum( value );

            if ( i !== data.length - 1 ) options.push( { label: index, value: index } );

        } )

        return { defined, points, options };

    }, [ props ] );

    const indexes = Object.keys( points );

    const form = useForm( { index: index != undefined ? index : indexes[ 0 ] } );

    const diff = useMemo( () => {

        const diff: any = [ null, diffLabels[ type ].none ];

        if ( points.length === 1 ) return diff;

        const current = points[ indexes[ indexes.length - 1 ] ];
        const since = points[ form.values.index ];

        const change = Math.round( ( ( current - since ) / since ) * 100 );

        diff[ 0 ] = Math.abs( change );

        if ( inverted == false ) {
            if ( change === 0 ) {

                diff[ 0 ] = null;

            } else if ( change > -1 && change < -1 ) {

                diff[ 1 ] = diffLabels[ type ].little;
                diff[ 0 ] = null;

            } else if ( change > 0 ) {

                diff[ 1 ] = diffLabels[ type ].plus;

            } else if ( change < 0 ) {

                diff[ 1 ] = diffLabels[ type ].minus;

            }
        } else {
            if ( change === 0 ) {

                diff[ 0 ] = null;

            } else if ( change > -1 && change < -1 ) {

                diff[ 1 ] = diffLabels[ type ].little;
                diff[ 0 ] = null;

            } else if ( change < 0 ) {

                diff[ 1 ] = diffLabels[ type ].plus;

            } else if ( change > 0 ) {

                diff[ 1 ] = diffLabels[ type ].minus;

            }
        }

        return diff;

    }, [ props, type, form.values.index ] );

    const handleIndexChange = ( e ) => {

        if ( onIndexChange ) onIndexChange( e.value );

    }

    useUpdateEffect( () => {
        form.handleChange( { reset: true, name: "index", value: index } )
    }, [ index ] )

    return useMemo( () => (

        <>

            {indexes.length > 1 &&

                <div
                    className={classes.join( " " )}
                >

                    {!defined[ defined.length - 1 ].isNotEmpty ?

                        <>{label} was not re-tested this time around. </>

                        :

                        diff[ 0 ] !== Infinity ?
                            <>
                                <>{label} has {diff[ 1 ]} </>

                                {diff[ 0 ] > 0 && <span className={styles.diff}>{diff[ 0 ]}%</span>}

                                <> since </>

                            </>
                            :
                            <>{label} cannot be compared to previous test. </>

                    }

                    <span className={styles.index}>

                        {form.values.index}

                        <FiChevronDown />

                        <FieldSelect
                            form={form}
                            name={"index"}
                            options={options}
                            required
                            isClearable={false}
                            onChange={handleIndexChange}
                        />
                    </span>

                    <Space height={space || "auto"} />

                </div>

            }

        </>

    ), [ points, form.values.index, diff ] );

}

interface ReportChartSummaryProps {
    [ key: string ]: any,
}

export default ReportChartSummary;