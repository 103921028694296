import React, { useCallback } from 'react';
import { Row, Col, } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldHidden, FieldSelect, FieldText, Space, useMountEffect } from '@dex/bubl-dash';
import { coachingGetLastWeek } from '../../../functions/sharedCoaching';

const CoachingTrainingGenericForm: React.FC<any> = (props: any) => {

    const { form, data, mode, week, block, weeks } = props;

    const onWeekChange = useCallback((value) => {

        // form.handleChange({ reset: true, silent: true, name: "weekNo", value: value.value })
        // form.setKey();

    }, [form.hash])

    useMountEffect(() => {

        if (mode !== "edit") {

            const { inBetweenPlan, targetWeek } = coachingGetLastWeek(data, true)

            form.handleChange({ reset: true, silent: true, name: "planId", value: inBetweenPlan?.id })
            form.handleChange({ reset: true, silent: true, name: "weekNo", value: targetWeek?.calendar })
        }

    })

    return (

        <>

            <Row edge gutter={8}>

                <Col col={{ xs: 6, lg: 6 }}>

                    <FieldSelect
                        form={form}
                        name='planId'
                        defaultValue={form.defaults.planId}
                        label='Plan'
                        options={data.planOptions}
                        required
                        wrapClass={"manual-input"}
                    />

                </Col>

                {form.values.planId &&
                    <Col col={{ xs: 12, lg: 10 }}>

                        <FieldSelect
                            form={form}
                            name='weekNo'
                            defaultValue={form.defaults.weekNo}
                            label='Week'
                            options={weeks}
                            required
                            wrapClass={"manual-input"}
                        // onChange={onWeekChange}
                        />

                    </Col>
                }

            </Row>

            <Row
                gutter={8}
                edge
            >

                <Col col={{ xs: 12, lg: 5 }}>

                    <FieldHidden
                        form={form}
                        name={`week.phase`}
                        defaultValue={form.defaults.week?.phase}
                        label='Training Phase'
                        options={[
                            { label: "General Prep", value: "General Prep" },
                            { label: "Specific Prep", value: "Specific Prep" },
                            { label: "Competition", value: "Competition" },
                            { label: "Transition", value: "Transition" },
                            { label: "Off-Training", value: "Off-Training" },
                        ]}
                        disabled
                    />

                </Col>

                <Col col={{ xs: 12, lg: 5 }}>

                    <FieldHidden
                        form={form}
                        name={`week.block`}
                        defaultValue={form.defaults.week?.block}
                        label='Training Block'
                        options={[
                            { label: "Base", value: "Base" },
                            { label: "Build", value: "Build" },
                            { label: "Taper", value: "Taper" },
                            { label: "Race", value: "Race" },
                            { label: "Recovery", value: "Recovery" },
                        ]}
                        disabled
                    />

                </Col>

                <Col col={{ xs: 2, lg: 2 }}>

                    <FieldHidden
                        form={form}
                        name={`week.blockNum`}
                        defaultValue={form.defaults.week?.blockNum}
                        key={form.values.week?.blockNum}
                        label="Block #"
                        disabled
                    />

                </Col>

                <Col col={{ xs: 2, lg: 2 }}>

                    <FieldHidden
                        form={form}
                        name={`week.week`}
                        defaultValue={form.defaults.week?.week}
                        key={form.values.week?.week}
                        label="Week #"
                        disabled
                    />

                </Col>

                <Col col={{ xs: 10, lg: 5 }}>

                    <FieldHidden
                        key={form.values.week?.startDate}
                        form={form}
                        name={'week.startDate'}
                        label={'Start Date'}
                        defaultValue={form.defaults.week?.startDate}
                        date
                        disabled
                    />

                </Col>

                <Col col={{ xs: 10, lg: 5 }}>

                    <FieldHidden
                        key={form.values.week?.endDate}
                        form={form}
                        name={'week.endDate'}
                        label={'End Date'}
                        defaultValue={form.defaults.week?.endDate}
                        date
                        disabled
                    />

                    <FieldHidden
                        form={form}
                        name={"week.eventId"}
                        value={week?.event}
                    />

                </Col>

            </Row>

            <Space />

        </>

    )
}

export default CoachingTrainingGenericForm
