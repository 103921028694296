import React, { useCallback, useContext, useMemo } from 'react';
import EditableContext from '../../../context/EditableContext';
import { Line, MetaTable, useApp, useForm } from '@dex/bubl-dash';

const FieldSessionsGeneralForm: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const editable = useContext(EditableContext);

    const form = useForm(data);

    const handleSubmit = useCallback((values) => {


    }, [props]);

    return useMemo(() => {

        return (

            <>

                <MetaTable
                    data={data}
                    columnA={[
                        {
                            label: "Team",
                            key: "team",
                            format: 'modelLink',
                        },
                        {
                            label: "Session Date",
                            key: "sessionDate",
                            format: 'date',
                        },
                        {
                            label: "Appraisers",
                            key: "fitters",
                            format: 'modelLink',
                        },
                    ]}
                    columnB={[
                        {
                            label: "Main Sport",
                            key: "mainSport",
                            format: 'titleCase',
                        },
                        {
                            label: "Discipline",
                            key: "discipline",
                            format: 'titleCase',
                        },
                        {
                            label: "Level",
                            key: "level",
                            format: 'titleCase',
                        },
                    ]}
                    columnC={[
                        {
                            label: "Test Period",
                            key: "period",
                            format: 'titleCase',
                        },

                    ]}

                />

            </>
        )

    }, [props]);

}

export default FieldSessionsGeneralForm;
