import { fixedNum, get, loop, parseNum, set } from "@dex/bubl-helpers"
import { getAdjustedRate, getDecimalTime, percentageOf } from "./sharedTests";
import { useEffect, useMemo } from "react";
import { chartRegressionLinear } from "./chartRegression";
import { useMountEffect, useUpdateEffect } from "@dex/bubl-dash";

export const getPercentHr = (averageHr: number, intensity: string, hrMax: number) => {
    if (averageHr == undefined || null) {
        if (intensity == "very easy") {
            return hrMax * 60 / 100
        } else if (intensity == "easy" || intensity == "not indicated") {
            return hrMax * 65 / 100
        } else if (intensity == "moderate") {
            return hrMax * 70 / 100
        } else if (intensity == "somewhat hard") {
            return hrMax * 75 / 100
        } else if (intensity == "hard") {
            return hrMax * 80 / 100
        } else if (intensity == "very hard") {
            return hrMax * 85 / 100
        } else if (intensity == "maximal") {
            return hrMax * 90 / 100
        } else {
            return 0
        }
    }
    else {
        return (averageHr / hrMax) * 100
    }
};

export const getVo2Estimate = (vo2Max: number, avgHr: number, percentHrMax: number, intensity: string, activity: string, form: any, prefix: string) => {

    if (avgHr && vo2Max) {
        return ((percentHrMax - 7) / 100) * vo2Max
    } else {
        if (intensity == "very easy") {
            return (vo2Max * 50) / 100
        } else if (intensity == "easy" || intensity == "not indicated") {
            return (vo2Max * 60) / 100
        } else if (intensity == "moderate") {
            return (vo2Max * 70) / 100
        } else if (intensity == "somewhat hard") {
            return (vo2Max * 75) / 100
        } else if (intensity == "hard") {
            return (vo2Max * 80) / 100
        } else if (intensity == "very hard") {
            return (vo2Max * 85) / 100
        } else if (intensity == "maximal") {
            return (vo2Max * 90) / 100
        } else {
            return 0
        }
    }
};

export const getPercentVo2 = (intensity: string) => {
    if (intensity == "very easy") {
        return 50
    } else if (intensity == "easy" || intensity == "not indicated") {
        return 60
    } else if (intensity == "moderate") {
        return 70
    } else if (intensity == "somewhat hard") {
        return 75
    } else if (intensity == "hard") {
        return 80
    } else if (intensity == "very hard") {
        return 85
    } else if (intensity == "maximal") {
        return 90
    } else {
        return 0
    }
};

export const getEnergyBurn = (RER, vo2Estimate, workoutDurationMinute) => {

    const convertKcalVo2 = (1.226734 * RER) + 3.819792;
    const convertKcalMin = convertKcalVo2 * vo2Estimate;
    const converttotalKcal = convertKcalMin * workoutDurationMinute;
    const computePercentCarb = (338.7903 * RER) - 238.907;
    const computePercentFat = (-338.79 * RER) + 338.9073;
    const convertCarbToG = (converttotalKcal * (computePercentCarb / 100)) / 4.12;
    const convertFatToG = (converttotalKcal * (computePercentFat / 100)) / 9;

    return fixedNum(convertCarbToG + convertFatToG, 0)
};

export const getEventHour = (event) => {
    if (!event) return 0
    if (event == "IM Triathlon") {
        return 18
    } else if (event == "70.3 Triathlon") {
        return 8
    } else if (event == "Olympic Triathlon") {
        return 4
    } else if (event == "Sprint Marathon" || event == "Half Marathon") {
        return 3
    } else if (event == "Marathon" || event == "Long Bike Race") {
        return 6
    } else if (event == "10km Run" || event == "Short Bike Race") {
        return 2
    } else if (event == "5km Run") {
        return 1
    } else if (event == "Ultra Endurance") {
        return 24
    } else {
        return 0
    }
};

export const getFluidLimit = (height) => {

    if (!height || height <= 155) {
        return 600
    } else if (height <= 165) {
        return 800
    } else if (height <= 175) {
        return 1000
    } else if (height <= 185) {
        return 1200
    } else {
        return 1500
    }
};

export const getBottleImage = (fluid) => {

    const thresholds = [0, 25, 200, 350, 500, 650, 800, 950, 1100, 1250, 1400];
    const images = [
        [0],
        [25],
        [50],
        [75],
        [100],
        [100, 25],
        [100, 50],
        [100, 75],
        [100, 100],
        [100, 100, 25],
        [100, 100, 50]
    ];

    for (let i = 0; i < thresholds.length; i++) {

        if (fluid <= thresholds[i]) {
            return i === 0 ? images[0] : images[i - 1];
        }
    }

    return images[images.length - 1];
};

export const getPillImage = (sodium) => {

    if (!sodium || sodium <= 100) {
        return [0]
    } else if (sodium <= 150) {
        return [25]
    } else if (sodium <= 250) {
        return [50]
    } else if (sodium <= 350) {
        return [75]
    } else if (sodium <= 450) {
        return [100]
    } else if (sodium <= 550) {
        return [100, 25]
    } else if (sodium <= 650) {
        return [100, 50]
    } else if (sodium <= 750) {
        return [100, 75]
    } else {
        return [100, 100]
    }
};

export const getArrayFluidIntake = (hours, lossRate, intake, weight) => {

    const output: any = [];

    let critical: any = false;

    let hour = 0;
    while (hour <= hours) {

        const fluidLossKg = fixedNum(((lossRate - intake) * hour) / 1000, 2);
        const bodyWeightLossKg = fixedNum(weight - fluidLossKg, 1);
        const bodyWeightLossPercent = percentageOf(fluidLossKg, weight, 2);

        output.push({
            hour: hour,
            fluidLossKg,
            bodyWeightLossKg,
            bodyWeightLossPercent,
            critical: (!critical && bodyWeightLossPercent && bodyWeightLossPercent >= 5) ? true : false
        });

        critical = (bodyWeightLossPercent && bodyWeightLossPercent >= 5) ? true : false;

        hour++;
    }

    return output;

};

export function getFractionNumber(numbersArray, suffix) {

    if (!Array.isArray(numbersArray)) return 0;

    const sum = numbersArray.reduce((total, number) => total + number, 0);
    const wholeNumber = Math.floor(sum / 100);
    const remainder = sum % 100;

    let fraction = "";
    if (remainder > 0) {
        const fractionalPart = remainder / 100;
        if (fractionalPart === 0.25) fraction = " 1/4";
        else if (fractionalPart === 0.5) fraction = " 1/2";
        else if (fractionalPart === 0.75) fraction = " 3/4";
        else fraction = ` ${remainder}/100`;
    }

    if (wholeNumber == 0 && !fraction) {
        return "Minimal"
    } else if (wholeNumber === 1 && !fraction) {
        return `${wholeNumber} ${suffix}`;
    } else if (wholeNumber === 0 && fraction) {
        return `${fraction} ${suffix}`;
    } else {
        return `${wholeNumber}${fraction} ${suffix}s`
    }
};

export const getEnabled = (enabledData) => {

    const enabledArray = enabledData?.map((isEnabled, index) => (isEnabled.enabled ? index : null)).filter(index => index !== null);

    const comparedData = enabledArray?.map(index => {
        const data = enabledData[index];
        const type = data.activity;
        return { ...data, type };
    });

    return comparedData
};

//calculate min max avg

export const calculateMinMaxAvgValues = (arr, key) => {

    if (!arr) return {};

    const values = arr.map(data => data[key]);

    const calculateMinMaxAvg = arr => {
        const length = arr.length;
        if (length > 1) {
            return {
                min: fixedNum(Math.min(...arr), 0),
                max: fixedNum(Math.max(...arr), 0),
                avg: fixedNum(arr.reduce((total, value) => total + value, 0) / length, 0)
            };
        } else if (length === 1) {
            const singleValue = arr[0];
            return {
                min: 0,
                max: singleValue,
                avg: singleValue
            };
        } else {
            return {
                min: 0,
                max: 0,
                avg: 0
            };
        }
    };

    return calculateMinMaxAvg(values);

};

export const sweatTestDehydration = (form, data) => {

    const activity = get(form.values, "rehydration.activity", "All");
    const climate = get(form.values, "dehydration.climate");
    const event = get(form.values, "dehydration.event");
    const rehydrationData = get(form.values, `rehydration.${climate}`)
    const rehydrationLow = get(form.values, "dehydration.rehydrationLow") || 30;
    const rehydrationHigh = get(form.values, "dehydration.rehydrationHigh") || 70;
    const fluidLossRate = rehydrationData?.fluidLossAdjustedRate || get(form.values, "dehydration.fluidLossRate");
    const hours = getEventHour(event);
    const weight = form.values?.rehydration?.avgWeight || 0;
    const targetTime = getDecimalTime(get(form.values, "dehydration.targetTime", 0))

    const getFluidIntakeRateLow = fixedNum(parseNum(fluidLossRate) * (parseNum(rehydrationLow) / 100), 0)
    const getFluidIntakeRateHigh = fixedNum(parseNum(fluidLossRate) * (parseNum(rehydrationHigh) / 100), 0)

    const fluidIntakeLowArray = getArrayFluidIntake(hours, fluidLossRate, getFluidIntakeRateLow, weight);
    const fluidIntakeHighArray = getArrayFluidIntake(hours, fluidLossRate, getFluidIntakeRateHigh, weight);

    const { chartData, lowCritical, highCritical, intersectLow, intersectHigh } = useMemo(() => {

        const data: any = [];

        const hours = fluidIntakeLowArray.reduce((value, row) => {
            value.push(row.hour);
            return value;
        }, []);

        const lowArr = fluidIntakeLowArray.reduce((value, row) => {
            value.push(row.bodyWeightLossPercent || 0);
            return value;
        }, []);

        const highArr = fluidIntakeHighArray.reduce((value, row) => {
            value.push(row.bodyWeightLossPercent || 0);
            return value;
        }, []);

        const low = chartRegressionLinear(lowArr, hours);
        const high = chartRegressionLinear(highArr, hours);

        loop([1, 2, 3, 4, 5, 6], (index) => {

            const lowH = fixedNum(low.regression.predict(index), 1);
            const highH = fixedNum(high.regression.predict(index), 1);

            data.push({
                duration: lowH,
                low: index,
            });

            data.push({
                duration: highH,
                high: index,
            });

        });

        const lowCritical = fixedNum(low.regression.predict(5), 1);
        const highCritical = fixedNum(high.regression.predict(5), 1);

        let intersectLow: number | any = fixedNum(low.regression.computeX(targetTime), 1);
        let intersectHigh: number | any = fixedNum(high.regression.computeX(targetTime), 1);

        if (intersectLow < 0 || intersectLow > 6) intersectLow = null;
        if (intersectHigh < 0 || intersectHigh > 6) intersectHigh = null;

        return {
            chartData: data,
            lowCritical: lowCritical,
            highCritical: highCritical,
            intersectLow: intersectLow,
            intersectHigh: intersectHigh,
        };

    }, [fluidIntakeHighArray, fluidIntakeLowArray, hours, targetTime]);

    useEffect(() => {

        form.handleChange({ silent: true, name: `dehydration.duration`, value: hours });
        form.handleChange({ silent: true, name: `dehydration.fluidLossRate`, value: fluidLossRate });

        // form.handleChange({ silent: true, name: `dehydration.rehydrationLow`, value: rehydrationLow });
        // form.handleChange({ silent: true, name: `dehydration.rehydrationHigh`, value: rehydrationHigh });
        // form.handleChange({ silent: true, name: `dehydration.fluidIntakeRateLow`, value: getFluidIntakeRateLow });
        // form.handleChange({ silent: true, name: `dehydration.fluidIntakeRateHigh`, value: getFluidIntakeRateHigh });
        // form.handleChange({ silent: true, name: `dehydration.criticalLow`, value: lowCritical });
        // form.handleChange({ silent: true, name: `dehydration.criticalHigh`, value: highCritical });
        // form.handleChange({ silent: true, name: `dehydration.intersectLow`, value: intersectHigh });
        // form.handleChange({ silent: true, name: `dehydration.intersectHigh`, value: intersectLow });

    }, [hours, fluidLossRate, activity]);

    return {
        chartData, lowCritical, highCritical, intersectLow, intersectHigh,
        getFluidIntakeRateLow, getFluidIntakeRateHigh,
        rehydrationData, rehydrationLow, rehydrationHigh, fluidLossRate,
        climate, event, hours, weight, targetTime,
    };

}

export const sweatTestGetActivities = (form) => {

    let activities: any = [{ value: "All", label: "All" }];

    loop(form.values.fluid?.enabledData || [], (data, activity) => {

        activities.push({ value: activity, label: activity });

    });

    return activities;

};

export const sweatTestRehydration = (form, data) => {

    const activity = get(form.values, "rehydration.activity", "All");
    const allData = getEnabled(get(form.values, "workouts", [])) || [];
    const selectedData = activity !== "All" ? get(form.values, `fluid.enabledData[${activity}]`, []) : allData;

    const numObjects = selectedData.length;

    const defaults = {
        averageHeight: 0,
        averageWeight: 0,
        averageFluidLossRate: 0,
        averageSodiumLossRate: 0,
        averageRehydration: 0,
        averageWeightloss: 0,
        averageFluidIntakeRate: 0
    };

    const averageData = selectedData.reduce((acc, obj) => {

        return {
            averageHeight: acc.averageHeight + parseNum(obj.height),
            averageWeight: acc.averageWeight + parseNum(obj.preWeight),
            averageFluidLossRate: acc.averageFluidLossRate + parseNum(obj.fluidLossRate),
            averageSodiumLossRate: acc.averageSodiumLossRate + parseNum(obj.sodiumLossRate),
            averageRehydration: acc.averageRehydration + parseNum(obj.rehydration),
            averageWeightloss: acc.averageWeightloss + parseNum(obj.weightloss),
            averageFluidIntakeRate: acc.averageFluidIntakeRate + parseNum(obj.fluidIntakeRate),
        };

    }, defaults);

    const finalAverageHeight = fixedNum(averageData.averageHeight / numObjects, 0);
    const finalAverageWeight = fixedNum(averageData.averageWeight / numObjects, 0);
    const finalAverageFluidLossRate = fixedNum(averageData.averageFluidLossRate / numObjects, 0);
    const finalAverageSodiumLossRate = fixedNum(averageData.averageSodiumLossRate / numObjects, 0);
    const finalAverageWeightLoss = fixedNum(averageData.averageWeightloss / numObjects, 1);
    const finalAverageFluidIntakeRate = fixedNum(averageData.averageFluidIntakeRate / numObjects, 0);
    const finalAverageRehydration = fixedNum(averageData.averageRehydration / numObjects, 0);

    useEffect(() => {

        form.handleChange({ silent: true, name: `rehydration.avgHeight`, value: finalAverageHeight });
        form.handleChange({ silent: true, name: `rehydration.avgWeight`, value: finalAverageWeight });
        form.handleChange({ silent: true, name: `rehydration.fluidLossRate`, value: finalAverageFluidLossRate });
        form.handleChange({ silent: true, name: `rehydration.fluidIntakeRate`, value: finalAverageFluidIntakeRate });
        form.handleChange({ silent: true, name: `rehydration.rehydration`, value: finalAverageRehydration });
        form.handleChange({ silent: true, name: `rehydration.sodiumLossRate`, value: finalAverageSodiumLossRate });
        form.handleChange({ silent: true, name: `rehydration.weightLoss`, value: finalAverageWeightLoss });

    }, [finalAverageHeight, finalAverageWeight, finalAverageRehydration, finalAverageFluidLossRate, finalAverageFluidIntakeRate, finalAverageSodiumLossRate, finalAverageWeightLoss])

    return {
        finalAverageWeight, finalAverageHeight,
        finalAverageWeightLoss, finalAverageRehydration,
        finalAverageFluidLossRate, finalAverageFluidIntakeRate,
        finalAverageSodiumLossRate
    };

};

export const sweatTestRehydrationFluidIntake = (form) => {

    const adjustedCoolFluid = getAdjustedRate(get(form.values, "rehydration.cool.fluidLossAdjustedRate"), get(form.values, "rehydration.cool.fluidIntakeAdjustment"))
    const adjustedWarmFluid = getAdjustedRate(get(form.values, "rehydration.warm.fluidLossAdjustedRate"), get(form.values, "rehydration.warm.fluidIntakeAdjustment"))
    const adjustedHotFluid = getAdjustedRate(get(form.values, "rehydration.hot.fluidLossAdjustedRate"), get(form.values, "rehydration.hot.fluidIntakeAdjustment"))

    useEffect(() => {

        form.handleChange({ silent: true, name: `rehydration.cool.fluidIntakeAdjustedRate`, value: adjustedCoolFluid });
        form.handleChange({ silent: true, name: `rehydration.warm.fluidIntakeAdjustedRate`, value: adjustedWarmFluid });
        form.handleChange({ silent: true, name: `rehydration.hot.fluidIntakeAdjustedRate`, value: adjustedHotFluid });

    }, [adjustedCoolFluid, adjustedWarmFluid, adjustedHotFluid]);

    return { adjustedCoolFluid, adjustedWarmFluid, adjustedHotFluid };

}

export const sweatTestRehydrationLossRate = (form, fluidLossRate, sodiumLossRate) => {

    useMountEffect(() => {
        if (!get(form.values, "rehydration.cool.fluidLossAdjustment")) set(form.values, "rehydration.cool.fluidLossAdjustment", 80)
        if (!get(form.values, "rehydration.cool.sodiumLossAdjustment")) set(form.values, "rehydration.cool.sodiumLossAdjustment", 80)
        if (!get(form.values, "rehydration.warm.fluidLossAdjustment")) set(form.values, "rehydration.warm.fluidLossAdjustment", 100)
        if (!get(form.values, "rehydration.warm.sodiumLossAdjustment")) set(form.values, "rehydration.warm.sodiumLossAdjustment", 100)
        if (!get(form.values, "rehydration.hot.fluidLossAdjustment")) set(form.values, "rehydration.hot.fluidLossAdjustment", 120)
        if (!get(form.values, "rehydration.hot.sodiumLossAdjustment")) set(form.values, "rehydration.hot.sodiumLossAdjustment", 120)
    })

    const adjustedCoolFluid = getAdjustedRate(fluidLossRate, get(form.values, "rehydration.cool.fluidLossAdjustment"))
    const adjustedCoolSodium = getAdjustedRate(sodiumLossRate, get(form.values, "rehydration.cool.sodiumLossAdjustment"))
    const adjustedWarmFluid = getAdjustedRate(fluidLossRate, get(form.values, "rehydration.warm.fluidLossAdjustment"))
    const adjustedWarmSodium = getAdjustedRate(sodiumLossRate, get(form.values, "rehydration.warm.sodiumLossAdjustment"))
    const adjustedHotFluid = getAdjustedRate(fluidLossRate, get(form.values, "rehydration.hot.fluidLossAdjustment"))
    const adjustedHotSodium = getAdjustedRate(sodiumLossRate, get(form.values, "rehydration.hot.sodiumLossAdjustment"))

    useEffect(() => {

        form.handleChange({ silent: true, name: `rehydration.cool.fluidLossAdjustedRate`, value: adjustedCoolFluid });
        form.handleChange({ silent: true, name: `rehydration.cool.sodiumLossAdjustedRate`, value: adjustedCoolSodium });
        form.handleChange({ silent: true, name: `rehydration.warm.fluidLossAdjustedRate`, value: adjustedWarmFluid });
        form.handleChange({ silent: true, name: `rehydration.warm.sodiumLossAdjustedRate`, value: adjustedWarmSodium });
        form.handleChange({ silent: true, name: `rehydration.hot.fluidLossAdjustedRate`, value: adjustedHotFluid });
        form.handleChange({ silent: true, name: `rehydration.hot.sodiumLossAdjustedRate`, value: adjustedHotSodium });

    }, [adjustedCoolFluid, adjustedCoolSodium, adjustedWarmFluid, adjustedWarmSodium, adjustedHotFluid, adjustedHotSodium]);

    return { adjustedCoolFluid, adjustedCoolSodium, adjustedWarmFluid, adjustedWarmSodium, adjustedHotFluid, adjustedHotSodium };

}

export const sweatTestRehydrationSodiumIntake = (form) => {

    const adjustedCoolSodium = getAdjustedRate(get(form.values, "rehydration.cool.sodiumLossAdjustedRate"), get(form.values, "rehydration.cool.sodiumIntakeAdjustment"));
    const adjustedWarmSodium = getAdjustedRate(get(form.values, "rehydration.warm.sodiumLossAdjustedRate"), get(form.values, "rehydration.warm.sodiumIntakeAdjustment"));
    const adjustedHotSodium = getAdjustedRate(get(form.values, "rehydration.hot.sodiumLossAdjustedRate"), get(form.values, "rehydration.hot.sodiumIntakeAdjustment"));

    useEffect(() => {

        form.handleChange({ silent: true, name: `rehydration.cool.sodiumIntakeAdjustedRate`, value: adjustedCoolSodium });
        form.handleChange({ silent: true, name: `rehydration.warm.sodiumIntakeAdjustedRate`, value: adjustedWarmSodium });
        form.handleChange({ silent: true, name: `rehydration.hot.sodiumIntakeAdjustedRate`, value: adjustedHotSodium });

    }, [adjustedCoolSodium, adjustedWarmSodium, adjustedHotSodium])

    return { adjustedCoolSodium, adjustedWarmSodium, adjustedHotSodium };

}

export const initialGlycogenTable = [
    { hour: 0, contribution: 100 },
    { hour: 1, contribution: 97 },
    { hour: 2, contribution: 94 },
    { hour: 3, contribution: 91 },
    { hour: 4, contribution: 88 },
    { hour: 5, contribution: 85 },
    { hour: 6, contribution: 83 },
    { hour: 7, contribution: 81 },
    { hour: 8, contribution: 79 },
    { hour: 9, contribution: 77 },
    { hour: 10, contribution: 75 },
    { hour: 11, contribution: 74 },
    { hour: 12, contribution: 73 },
    { hour: 13, contribution: 72 },
    { hour: 14, contribution: 71 },
    { hour: 15, contribution: 70 },
    { hour: 16, contribution: 69 },
    { hour: 17, contribution: 68 },
    { hour: 18, contribution: 67 },
    { hour: 19, contribution: 66 },
    { hour: 20, contribution: 65 },
    { hour: 21, contribution: 64 },
    { hour: 22, contribution: 63 },
    { hour: 23, contribution: 62 },
    { hour: 24, contribution: 61 },
]