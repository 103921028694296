import React from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, FieldSelect, FieldTable, FieldText, InputTableCell, InputTableHead, InputTableHeadCell, InputTableHeadRow, MetaTable } from '@dex/bubl-dash';
import { get, loop } from '@dex/bubl-helpers';
import { CoachingTrainingsIconBest, CoachingTrainingsIconTop5 } from './CoachingTrainingsBestProvider';
import CoachingApi from '../CoachingApi';
import moment from 'moment';

const CoachingTrainingsWeekHighlightsTab: React.FC<any> = (props: any) => {

    const { form, data, handleSubmit, mode } = props;

    const types = [
        { name: "swim", icon: "stimulusSwim" },
        { name: "bike", icon: "stimulusBike" },
        { name: "run", icon: "stimulusRun" },
        { name: "total", icon: "stimulusTotal" },
        { name: "hr", icon: "stimulusFitness" },
    ];

    const highlights = CoachingApi.format(data.id, 'getHighlights');

    const calculateHighlightsTop = (values) => {

        highlights.run({
            params: {
                type: "Weeks",
                values: { intensity: values.intensity, volume: values.volume, other: values.other },
                endDate: form.values.week?.endDate
            },
            onComplete: (response) => {

                loop(response, (values, type) => {
                    form.handleChange({ name: `highlights.` + type, value: values, reset: true })
                });

            },
        });

    };

    return (

        <>
            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, lg: 20 }}>

                    <p style={{ fontWeight: 600, fontSize: "1rem" }}>Highlight:</p>

                    {types.map((_type, i) => (

                        <React.Fragment key={i}>

                            <Space />

                            <img
                                src={require(`../../../assets/images/Coaching/${_type.icon}.png`)}
                                style={{ width: _type.icon == "stimulusTotal" ? "200px" : "80px", height: "50px", objectFit: "scale-down" }}
                            />

                            <FieldTable
                                key={highlights.loading ? "loading" : "loaded"}
                                form={form}
                                name={`highlights.` + _type.name}
                                defaultValue={get(form.values, `highlights.` + _type.name)}
                                defaultValues={{}}
                                head={(
                                    <InputTableHead>

                                        <InputTableHeadRow>

                                            <InputTableHeadCell
                                                label={"#"}
                                                style={{ width: 80 }}
                                            />

                                            <InputTableHeadCell
                                                label={"Actions"}
                                                style={{ width: 80 }}
                                            />

                                            <InputTableHeadCell
                                                label="Icon"
                                                style={{ width: 200 }}
                                            />

                                            <InputTableHeadCell
                                                label="Comment"
                                            />

                                        </InputTableHeadRow>

                                    </InputTableHead>
                                )}
                            >

                                {(get(form.values, `highlights.` + _type.name) || []).map((row, n) => (

                                    <React.Fragment key={form.key + "-" + n}>

                                        <InputTableCell>
                                            <FieldSelect
                                                form={form}
                                                name={`highlights.` + _type.name + `[${n}].icon`}
                                                defaultValue={get(form.values, `highlights.` + _type.name + `[${n}].icon`)}
                                                options={[
                                                    { label: <>Best<CoachingTrainingsIconBest size={25} /></>, value: "best" },
                                                    { label: <>Top 5%<CoachingTrainingsIconTop5 size={25} /></>, value: "top5" },
                                                ]}
                                            />

                                        </InputTableCell>

                                        <InputTableCell>
                                            <FieldText
                                                form={form}
                                                name={`highlights.` + _type.name + `[${n}].text`}
                                                defaultValue={get(form.values, `highlights.` + _type.name + `[${n}].text`)}
                                            />

                                        </InputTableCell>

                                    </React.Fragment>
                                ))}

                            </FieldTable>

                        </React.Fragment>

                    ))}

                </Col>

                <Col col={{ xs: 24, lg: 4 }}>

                    <Space />

                    <Button
                        label={'Calculate Best/Top 5%'}
                        onClick={calculateHighlightsTop.bind(null, form.values)}
                        type={'light'}
                        loading={highlights.loading}
                    />

                    <Space height={16} />

                    <MetaTable
                        data={{
                            startDate: form.values.week?.endDate ? moment(form.values.week?.endDate).subtract(1, "year").add(1, "week") : null,
                            endDate: form.values.week?.endDate,
                        }}
                        columnA={[
                            {
                                label: "Start Date",
                                key: "startDate",
                                format: "dateDay",
                            },
                        ]}
                        columnB={[
                            {
                                label: "End Date",
                                key: "endDate",
                                format: "dateDay",
                            },
                        ]}
                        columnSize={{ xs: 12 }}
                    />

                    <Space />

                </Col>

            </Row>
        </>

    );

}

export default CoachingTrainingsWeekHighlightsTab;
