import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line'
import { getArmRatio, getLegRatio, getThighShinRatio, getArmProportion, getLegProportion, getThighShinProportion } from '../../../functions/sharedBikeFit';
import RatingScalesApi from '../../RatingScales/RatingScalesApi';
import { arrowPositionLevels, ratingLabel, stepsMinMax } from '../../../elements/RatingScale/RatingScaleElements';
import { useMountEffect, useUpdateEffect } from '@dex/bubl-dash';
import { fixedNum, get } from '@dex/bubl-helpers';

const SessionsEditTabsBio: React.FC<any> = ( props: any ) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const gender = data.athlete.gender || "male";
    const bodyFat = get( form.values, `bio.bodyFat` );

    const group = RatingScalesApi.getData();

    const [ ratingData, setRatingData ]: any = useState( { metric: "Body Fat", demographic: "Scale Not Found" } );

    useMountEffect( () => {

        group.run( {
            params: {
                metric: "Body Fat",
                split: null,
            },
            onComplete: ( response ) => {

                if ( response ) setRatingData( response );

            }
        } );

    } );

    const { steps, min, max }: any = stepsMinMax( ratingData, "elite", gender );

    const rating = ( val ) => ratingLabel( val, steps, min, max );

    useUpdateEffect( () => {

        if ( bodyFat ) {
            form.handleChange( { reset: true, name: "bio.bodyFatRating", value: rating( bodyFat ) } );
        } else {
            form.handleChange( { reset: true, name: "bio.bodyFatRating", value: "" } );
        }

    }, [ bodyFat ] );

    return useMemo( () => {

        const torsoLength = form.values.bio.height - form.values.bio.inseam;
        const armRatio = getArmRatio( form.values.bio.armSpan, form.values.bio.height );
        const legRatio = getLegRatio( form.values.bio.inseam, form.values.bio.height );
        const shinRatio = getThighShinRatio( form.values.bio.shinLength, form.values.bio.thighLength );

        const saddleHeight = form.values.bio.inseam ? fixedNum( form.values.bio.inseam * 0.884, 1 ) : "";

        const armProportion = getArmProportion( armRatio )
        const legProportion = getLegProportion( legRatio )
        const thighShinProportion = getThighShinProportion( shinRatio )

        return (

            <SessionsEditTabsPanel
                heading={"Bio Data"}
                {...props}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, lg: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.weight"
                                    label="Weight (kg)"
                                    suffix={" kg"}
                                    defaultValue={form.defaults.bio.weight}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.height"
                                    label="Height (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.height}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.shoulderWidth"
                                    label="Shoulder Width (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.shoulderWidth}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.armLength"
                                    label="Arm Length (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.armLength}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.inseam"
                                    label="Inseam (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.inseam}
                                />

                            </Col>

                            {/* <Col col={{ xs: 24, md: 24 }}>

                                <Line top={null} bottom={null} />

                            </Col> */}

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.armSpan"
                                    label="Arm Span (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.armSpan}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.shoulderHeight"
                                    label="Shoulder Height (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.shoulderHeight}
                                />

                            </Col>

                            {/* <Col col={{ xs: 24, md: 24 }}>

                                <Line top={null} bottom={null} />

                            </Col> */}

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.thighLength"
                                    label="Thigh Length (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.thighLength}
                                />

                            </Col>


                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.shinLength"
                                    label="Shin Length (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.shinLength}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    disabled
                                    name="bio.torsoLength"
                                    label="Torso Length (cm)"
                                    suffix={" cm"}
                                    key={torsoLength}
                                    defaultValue={torsoLength.toFixed( 2 )}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    dynamicKey
                                    form={form}
                                    name="bio.bodyFat"
                                    label="%Body Fat"
                                    suffix={" %"}
                                    defaultValue={form.defaults.bio.bodyFat}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldText
                                    form={form}
                                    name="bio.bodyFatRating"
                                    label="Body Fat Rating"
                                    defaultValue={form.defaults.bio.bodyFatRating}
                                    key={form.values.bio.bodyFatRating}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>
                                <Line />
                            </Col>

                            <Col col={{ xs: 24, md: 6 }}>

                                <FieldNumber
                                    form={form}
                                    disabled
                                    name="bio.armRatio"
                                    label="Arm Ratio"
                                    key={armRatio}
                                    defaultValue={armRatio}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 6 }}>

                                <FieldNumber
                                    form={form}
                                    disabled
                                    name="bio.legRatio"
                                    label="Leg Ratio"
                                    key={legRatio}
                                    defaultValue={legRatio}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 6 }}>

                                <FieldNumber
                                    form={form}
                                    disabled
                                    name="bio.thighShinRatio"
                                    label="Thigh-Shin Ratio"
                                    key={shinRatio}
                                    defaultValue={shinRatio}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 6 }}>

                                <FieldNumber
                                    form={form}
                                    disabled
                                    name="bio.saddleHeight"
                                    label="Estimated Saddle Height"
                                    key={saddleHeight}
                                    defaultValue={saddleHeight}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 6 }}>

                                <FieldText
                                    form={form}
                                    disabled
                                    name="bio.armProportion"
                                    label="Arm Proportion"
                                    key={armProportion}
                                    defaultValue={armProportion}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 6 }}>

                                <FieldText
                                    form={form}
                                    disabled
                                    name="bio.legProportion"
                                    label="Leg Proportion"
                                    key={legProportion}
                                    defaultValue={legProportion}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 6 }}>

                                <FieldText
                                    form={form}
                                    disabled
                                    name="bio.thighShinProportion"
                                    label="Thigh-Shin Proportion"
                                    value={thighShinProportion}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, lg: 8 }} align='center' justify='center'>

                        {form.values.athlete.gender === 'female' &&

                            <img src={require( '../../../assets/images/BioStandingFemale.png' )}
                                alt="Bio Standing"
                                className="mobilityImage"
                            />

                        }

                        {form.values.athlete.gender === 'male' &&

                            <img src={require( '../../../assets/images/BioStandingMale.png' )}
                                alt="Bio Standing"
                                className="mobilityImage"
                            />

                        }

                    </Col>

                </Row>


            </SessionsEditTabsPanel>

        )

    }, [ props, form.hash ] );

}

export default SessionsEditTabsBio;
