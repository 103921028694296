import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import ExercisesCreateEditForm from './Components/ExercisesCreateEditForm';
import ExercisesApi from './ExercisesApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => `Add New Exercise`,
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: ExercisesCreateEditForm,
                getCreate: ExercisesApi.create,
                getClone: ExercisesApi.getOne,
                defaults: {},
                successMessage: "Exercise Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}