import { Box, Button, Col, FieldList, FieldNumber, FieldText, FieldTextTime, Form, Line, Modal, Panel, Row, useApp, useForm, useUpdateEffect } from '@dex/bubl-dash';
import React, { useCallback, useEffect, useState } from 'react';
import CoachingLogCreateEdit from '../Log/CoachingLogCreateEdit';
import CoachingLogTable from '../Log/CoachingLogTable';
import CoachingTrainingLogsApi from '../../CoachingTrainingLogs/CoachingTrainingLogsApi';
import { date } from '@dex/bubl-helpers';
const CoachingEditTabsLog: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm();
    const LogsForm = useForm();

    const app = useApp();

    const [nav] = app.store("nav");

    const process = CoachingTrainingLogsApi.create();

    useEffect(() => {

        const logs = data.logs;

        form.handleChange({ reset: true, silent: true, name: `totalHour`, value: logs.hours })
        form.handleChange({ reset: true, silent: true, name: `totalRevenue`, value: logs.revenue })
        form.handleChange({ reset: true, silent: true, name: `averageEarning`, value: logs.averageEarnings })
        form.handleChange({ reset: true, silent: true, name: `startDate`, value: date(logs.startDate) })

    }, [data]);

    const [showForm, setShowForm] = useState(false);

    const handleSubmit = useCallback((values) => {

        values.coachingId = data.id;

        process.run({
            data: values,
            onComplete: (data) => {

                app.alert('Log Saved', 'success');

                setShowForm(false);

                app.navigate(nav, 'update', {});
            }
        });

    }, [props]);

    return (

        <Box gutter={"auto"}>

            <Panel
                heading={`Coaching Logs`}
                full
                actions={
                    <Button
                        label="Add New Log"
                        onClick={setShowForm.bind(null, true)}
                    />
                }
            >

                <Box gutter={"auto"}>

                    <Row gutter={8} edge>

                        <Col col={{ xs: 4 }}>

                            <FieldNumber
                                form={form}
                                name='.totalRevenue'
                                defaultValue={form.values.totalRevenue}
                                label='Total Revenue To Date'
                                prefix="RM "
                                dynamicKey
                                disabled
                            />

                        </Col>

                        <Col col={{ xs: 4 }}>

                            <FieldText
                                form={form}
                                name='.startDate'
                                defaultValue={form.values.startDate}
                                label='Total Since'
                                dynamicKey
                                disabled
                            />

                        </Col>

                        <Col col={{ xs: 4 }}>

                            <FieldTextTime
                                key={form.values.totalHour}
                                form={form}
                                name='.totalHour'
                                defaultValue={form.values.totalHour}
                                label='Total Logged Hours'
                                dynamicKey
                                disabled
                            />

                        </Col>

                        <Col col={{ xs: 4 }}>

                            <FieldNumber
                                form={form}
                                name='.averageEarning'
                                defaultValue={form.values.averageEarning}
                                label='Average Hourly Earnings'
                                prefix="RM "
                                dynamicKey
                                disabled
                            />

                        </Col>

                    </Row>

                </Box>

                {!showForm &&
                    <CoachingLogTable
                        data={data}
                    />
                }

                {showForm &&

                    <Modal
                        show={showForm}
                        onClose={setShowForm.bind(null, false)}
                        width={1080}
                    >

                        <CoachingLogCreateEdit
                            form={LogsForm}
                            process={process}
                            handleSubmit={handleSubmit}
                            mode={"create"}
                            data={data}
                        />

                    </Modal>
                }

            </Panel>

        </Box>

    )
}

export default CoachingEditTabsLog;