import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { DataTable, DataTableCell, DataTableRow, FieldCheckbox, FieldHidden, FieldNumber, FieldPopup, FieldSelect, FieldTextArea, InputTable, InputTableCell, InputTableRow, Space } from '@dex/bubl-dash';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartHr from '../../../elements/ChartLabTests/ChartHr';
import { get } from '@dex/bubl-helpers';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartSprintHr from '../../../elements/ChartLabTests/ChartSprintHr';
import ChartSprintLactate from '../../../elements/ChartLabTests/ChartSprintLactate';

const LabTestsSprintLactate: React.FC<any> = (props: any) => {

    const { form, data, session, tab } = props;

    const activity = data.activity;

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24, md: 24 }}>

                <PanelChart
                    heading={"Lactate"}
                    data={form.values.manualData}
                    addZero={false}
                    renderChart={(chartType, chartData) => (

                        <>
                            <ChartSprintLactate
                                data={chartData}
                                activity={activity}
                                intensity={data.intensity}
                                xAxis={{
                                    interval: ChartProps.interval(activity, chartType),
                                }}
                                peak={form.values.manual?.timePeakLactate}
                            />
                        </>

                    )}
                />

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <InputTable
                    columns={[
                        { colSpan: 2, label: "Lactate Measures" },
                    ]}
                >
                    <InputTableRow>

                        <DataTableCell>
                            <strong>Peak Lactate</strong>
                        </DataTableCell>

                        <DataTableCell>

                            <strong>{form.values?.manual?.peakLactate} mmol/L</strong>

                        </DataTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <DataTableCell>
                            <strong>Vlamax</strong>
                        </DataTableCell>

                        <DataTableCell>

                            <strong>{form.values?.manual?.vlaMax} mmol/L/sec</strong>

                        </DataTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <DataTableCell>
                            <strong>Time To Peak Lac</strong>
                        </DataTableCell>

                        <DataTableCell>

                            <strong>{form.values?.manual.timePeakLactate} mm:ss</strong>

                        </DataTableCell>

                    </InputTableRow>

                </InputTable>

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <RatingScaleCard
                    form={form}
                    metric={'Vlamax'}
                    value={form.values?.manual?.vlaMax}
                    name="lactate.lactateRatingScale"
                    demographic={get(form.values, 'lactate.lactateRatingScale', session.demographic)}
                />

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <FieldTextArea
                    form={form}
                    label={"Peak Lactate Description"}
                    name={"lactate.sprintLactateDescription"}
                    defaultValue={form.defaults?.lactate?.sprintLactateDescription}
                    rows={3}
                />

            </Col>

        </Row>

    ), [props]);

}


export default LabTestsSprintLactate;
