import React from 'react';
import { Row, Col, useApp, MediaImage } from '@dex/bubl-dash';
import ReportMetaTable from '../ReportMetaTable/ReportMetaTable';
import styles from "./FieldResultsTestSummary.module.scss";
import { titleCase } from '@dex/bubl-helpers';

const FieldResultsTestSummary: React.FC<FieldResultsTestSummaryProps> = (props) => {

    const { template, details } = props;

    const app = useApp();

    return (

        <Row gutter={"auto"} edge={true}>

            <Col col={{ xs: 24 }}>
                <p className={styles.heading}>{template.name} {template.units && <>({template.units})</>}</p>
                <p className={styles.detail}>{titleCase(template.category)}, {titleCase(template.subcategory)}</p>
            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <Row gutter={"auto"} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <MediaImage
                            id={template.photo?.fileId}
                            filters={{ width: 800, height: 500, resizeMode: "contain", fallback: { background: "#272D60", text: template.name } }}
                            className={styles.image}
                            imgProps={{
                                crossOrigin: "anonymous"
                            }}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>
                        <p className={styles.details}>{template.description}</p>
                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <ReportMetaTable
                    data={details}
                    columnSize={{ xs: 24, sm: 12 }}
                    columnA={[
                        {
                            key: 'date',
                            label: 'Test Date',
                            format: "date"
                        },
                        {
                            key: 'timeOfDay',
                            label: 'Test Time'
                        },
                        {
                            key: 'weather',
                            label: 'Weather',
                            format: "titleCase",
                        },
                        {
                            key: 'climate',
                            label: 'Temperature',
                            format: "titleCase"

                        }
                    ]}
                    columnB={[
                        {
                            key: 'venue',
                            label: 'Venue',
                        },
                        {
                            key: 'facilityType',
                            label: 'Facility Type',
                            format: "titleCase"
                        },
                        {
                            key: 'flooring',
                            label: 'Flooring'
                        },
                        {
                            key: 'fitters',
                            label: 'Appraisers',
                        }
                    ]}
                />

            </Col>

        </Row>

    )

}

interface FieldResultsTestSummaryProps {
    [key: string]: any,
}

export default FieldResultsTestSummary;