import React, { useMemo } from 'react';
import { Box, Panel, Space } from '@dex/bubl-dash';

import styles from "./TestSessionsReportPage.module.scss";

const TestSessionsReportPage: React.FC<any> = (props: any) => {

    const { data, index, heading, icon, name, forwardedRef } = props;

    return (

        <>

            <Panel
                id={index}
                full={true}
                heading={false}
                forwardedRef={forwardedRef}
                className={styles.page + " report-page"}
            >

                {heading &&
                    <div className={styles.heading}>
                        <h1>{heading}</h1>
                        {name &&
                            <h2>{name}</h2>
                        }
                        {icon &&
                            <img src={icon} alt={"icon"} />
                        }

                    </div>
                }


                <div className={styles.body}>
                    {props.children}
                </div>

            </Panel>

        </>

    );

}

export default TestSessionsReportPage;
