import { Col, FieldRadio, FieldSelect, FieldTextArea, Line, MetaTable, Row } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';
import React, { useMemo } from 'react';
import EventTargetCard from '../../../elements/EventTargetCard/EventTargetCard';

const LabTestsRaceReadyRating: React.FC<any> = (props: any) => {

    const { form, data, session } = props;

    const activity = data.activity;

    return useMemo(() => (

        <>

            <Row gutter={"auto"} edge>

                <Col col={{ xs: 24, md: 24 }}>

                    <MetaTable
                        data={form.values.manual}
                        columnA={[
                            {
                                label: "Level",
                                key: "level",
                                format: "titleCase"
                            }
                        ]}
                    />

                </Col>

            </Row>

            <Line />

            <Row gutter={"auto"} edge>

                <Col col={{ xs: 24, md: 12 }}>

                    <EventTargetCard
                        metric={"%FTP"}
                        gender={session.athlete.gender}
                        level={form.values?.manual?.level}
                        activity={activity}
                        eventType={form.values?.manual?.type}
                        values={form.values?.result?.ftpCurrent || []}
                        altUnits={{
                            values: form.values?.result.sum?.ftp || [],
                            suffix: activity == "Running" ? "kph" : "w",
                            sep: "/",
                            position: "before",
                        }}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <EventTargetCard
                        metric={"%HRmax"}
                        gender={session.athlete.gender}
                        level={form.values?.manual?.level}
                        activity={activity}
                        eventType={form.values?.manual?.type}
                        values={form.values?.result?.vo2maxHrCurrent || []}
                        altUnits={{
                            values: form.values?.result.sum?.hr || [],
                            suffix: "bpm",
                            sep: "/",
                            position: "before",
                        }}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <EventTargetCard
                        metric={"%VO2max"}
                        gender={session.athlete.gender}
                        level={form.values?.manual?.level}
                        activity={activity}
                        eventType={form.values?.manual?.type}
                        values={form.values?.result?.vo2maxVo2Current || []}
                        altUnits={{
                            values: form.values?.result.sum?.vo2relative || [],
                            suffix: "ml/kg*min",
                            sep: "/",
                            position: "before",
                        }}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <EventTargetCard
                        metric={"Lactate"}
                        gender={session.athlete.gender}
                        level={form.values?.manual?.level}
                        activity={activity}
                        eventType={form.values?.manual?.type}
                        values={form.values?.result?.sum?.lactate || []}
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <Line
                        top={false}
                        bottom={false}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldTextArea
                        form={form}
                        name='rating.description'
                        defaultValue={get(form.defaults, "rating.description")}
                        label='Performance Ratings'
                        row={4}
                    />

                </Col>

            </Row>


        </>

    ), [form.hash, data, session]);

}


export default LabTestsRaceReadyRating;
