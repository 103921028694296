import React, { useMemo } from 'react';
import { MediaImage, Space } from '@dex/bubl-dash';
import styles from "./GaitReportCover.module.scss";
import { date, get } from '@dex/bubl-helpers';

const GaitReportCover: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const logo = [
        { img: require('../../../assets/images/Cover/GARMIN.png'), alt: "GARMIN" },
        { img: require('../../../assets/images/Cover/DARTFISH.png'), alt: "DARTFISH" },
    ]

    return useMemo(() => (

        <div className={styles.cover}>

            <Space height={160} />

            <div className={styles.innerCover}>

                <div>
                    <img
                        src={require('../../../assets/images/Cover/VO2MAX.png')}
                        alt="VO2MAX"
                        className={styles.vo2Logo}
                    />
                </div>

                <div className={styles.borderBlue}>

                    <div className={styles.borderGreen} >

                        <div className={styles.top}>

                            <p className={styles.altTitle}>{data.reportTitle} <span>Report</span></p>

                            <p className={styles.subHeading}>by {data.appraiserName}</p>

                            <p className={styles.subHeading}>{date(data.sessionDate)}</p>

                            <div className={styles.imageWrapper}>
                                <MediaImage
                                    id={get(data.athlete, "photo.fileId")}
                                    filters={{ height: 350, resizeMode: "contain", exact: true }}
                                    className={styles.image}
                                    imgProps={{
                                        crossOrigin: "anonymous"
                                    }}
                                />
                            </div>

                            <p className={styles.altTitle}>{data.athlete.fullName}</p>

                        </div>

                        <div className={styles.logoRow}>
                            {logo.map((item: any, index: any) => (
                                <div
                                    className={styles.logoContainer}
                                    key={index + 1}
                                >
                                    <img
                                        src={item.img}
                                        alt={item.alt}
                                        className={styles.logo}
                                    />
                                </div>
                            ))}
                        </div>

                        <Space height={80} />

                    </div>

                </div>

            </div>

        </div>

    ), [data]);

}

export default GaitReportCover;