import { Col, FieldRadio, Row } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';
import { fixedNum, get } from '@dex/bubl-helpers';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartPerformanceTrackingMetabolic from '../../../elements/ChartTestSessionReport/ChartPerformanceTrackingMetabolic';
import styles from "./TestSessionsReportPerformanceTrackingMetabolic.module.scss";
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';

const TestSessionsReportPerformanceTrackingMetabolic: React.FC<any> = (props: any) => {

    const { data, form } = props;

    let gender = data.athlete?.gender;
    const activity: string = data.test.activity;
    const intensity = data?.test?.intensity;

    const zones = data.test.lactate.zones;
    const anaerobic = zones["4"] || {};
    const ftp = get(data.test, `lactate.measures[0]`);
    const vo2Max = get(data.test, `lactate.measures[1]`);
    const [index, setIndex] = useState(data.history[0].date);
    const anaerobicPerKg = fixedNum(vo2Max?.vo2max_speed / data.bio?.weight, 1);

    const trackingUse = get(form.values, `metabolic.trackingUse`, "HR");
    const reportType = get(form.values, `metabolic.reportType`, "fatmax");

    let options;
    let summary;
    let description;

    if (trackingUse == "Power") {

        options = [
            { label: "Watts", name: "Watts", data: data.history },
            { label: "Watts/Kg", name: "WattsPerKg", data: data.history }
        ]
        summary = reportType == "fatmax" ? "fatmax" : "mep"
        description = "This is your power at FATMAX or MET levels. Generally, the higher this is, the more metabolically efficient or better fat-burner you are!"

    } else if (trackingUse == "Speed") {

        options = [
            { label: "kph", name: "speed", data: data.history },
            { label: "min/km", name: "pace", data: data.history }
        ]
        summary = reportType == "fatmax" ? "fatmax" : "mep"
        description = "This is your speed  at FATMAX or MET levels. Generally, the higher this is, the more metabolically efficient or better fat-burner you are!"

    } else if (trackingUse == "VO2") {

        options = [
            { label: "ml/kg*min", name: "relative", data: data.history },
            { label: "%Vo2max", name: "percent", data: data.history }
        ]
        summary = reportType == "fatmax" ? "fatmaxVo2" : "mepVo2"
        description = "This is your oxygen consumption at FATMAX or MET levels. Generally, the higher this is relative to your VO2max (i.e. higher %VO2max), the higher this is, the more metabolically efficient or better fat-burner you are!"

    } else if (trackingUse == "Carb Burn") {

        options = [
            { label: "g/hr", name: "gram", data: data.history },
            { label: "kcal/hr", name: "kcal", data: data.history }
        ]
        summary = reportType == "fatmax" ? "fatmaxCarbBurng" : "mepCarbBurng"
        description = "This is the rate of carbohydrates you burn at FATMAX or MET levels . For endurance-trained athletes, the lower this value the better while for power or sprint-based athletes, this likely will be higher!"

    } else if (trackingUse == "Fat Burn") {

        options = [
            { label: "g/hr", name: "gram", data: data.history },
            { label: "kcal/hr", name: "kcal", data: data.history }
        ]
        summary = reportType == "fatmax" ? "fatmaxFatBurng" : "mepFatBurng"
        description = "This is your rate of fat consumption at FATMAX or MET levels. For endurance-trained athletes, the lower this value the better while for power or sprint-based athletes, this likely will be higher! Those on a low-carbohydrate diet will demonstrate higher fat bun rates."

    } else if (trackingUse == "HR") {

        options = [
            { label: "bpm", name: "bpm", data: data.history },
            { label: "%Hrmax", name: "%Hrmax", data: data.history }
        ]
        summary = reportType == "fatmax" ? "fatmaxHR" : "mepHR"
        description = "This is your heart rate at FATMAX or MET levels.  Generally, the higher this is relative to your HRmax (i.e. higher %HRmax), the more metabolically efficient or better fat-burner you are!"

    }

    const isPerformance = ["Power", "Speed"];

    return useMemo(() => (

        <>

            <Row edge gutter={8}>

                <Col col={{ xs: 24 }}>

                    <FieldRadio
                        key={reportType}
                        form={form}
                        required={false}
                        label={""}
                        name={"metabolic.reportType"}
                        list
                        defaultValue={form.values?.metabolic?.reportType || reportType}
                        options={[
                            { label: "Fatmax    - is the power or speed at which you burn the highest rate of fat.", value: "fatmax" },
                            { label: `MET       - MET is the power or speed at which you burn an equal (50%-50%) percentage of Carbs and Fat.`, value: "met" },
                        ].filter(Boolean)}
                    />

                </Col>

                <Col col={{ xs: 20 }}>

                    <PanelChart
                        key={options + trackingUse}
                        heading={trackingUse + " Efficiency"}
                        addZero={false}
                        chartKey={form.hash}
                        types={options}
                        defaultTypes={options ? options[0] : ""}
                        data={data.history}
                        renderChart={(chartType, chartData) => (
                            <>
                                <ChartPerformanceTrackingMetabolic
                                    data={chartData}
                                    activity={activity}
                                    date={data.recentDate}
                                    index={index}
                                    intensity={intensity}
                                    chart={trackingUse}
                                    type={chartType}
                                    report={reportType}
                                />
                            </>
                        )}
                    />

                </Col>

                <Col col={{ xs: 4 }}>

                    <FieldRadio
                        list
                        key={trackingUse}
                        form={form}
                        required={false}
                        label={"Tracking Over Time"}
                        name={"metabolic.trackingUse"}
                        defaultValue={form.values?.metabolic?.trackingUse || trackingUse}
                        options={[
                            activity === "Cycling" ? { label: "Power", value: "Power" } : null,
                            activity === "Running" ? { label: "Speed", value: "Speed" } : null,
                            { label: "VO2", value: "VO2" },
                            { label: "HR", value: "HR" },
                            { label: "Carb Burn", value: "Carb Burn" },
                            { label: "Fat Burn", value: "Fat Burn" },
                        ].filter(Boolean)}
                    />

                </Col>

                <Col col={{ xs: 20 }}>

                    <ReportDesc
                        label={description}
                        className={["no-border", "h1"]}
                    />

                </Col>

                <Col col={{ xs: 20 }}>

                    {(trackingUse == "Power" || trackingUse == "Speed" || trackingUse == "VO2") &&

                        <ReportHeading
                            text={"Current Rating"}
                        />

                    }

                </Col>

                <Col col={{ xs: 20 }}>

                    <ReportChartSummary
                        label={""}
                        valueKey={summary}
                        indexKey={"date"}
                        data={data.history}
                        type={isPerformance.includes(trackingUse) ? "performance" : "size"}
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, trackingUse, options, reportType]);

}

export default TestSessionsReportPerformanceTrackingMetabolic;