import { Button, Col, DataTableCell, FieldNumber, FieldSelect, FieldTextArea, FieldTextTime, InputTable, InputTableCell, InputTableRow, Row, Space, useMountEffect, useUpdateEffect } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';
import React, { useMemo } from 'react';
import styles from './GaitsEditTabs.module.scss';
import { cmToM, getKphFromPace, getPaceFromKph, getStepLength, percentageOf } from '../../../functions/sharedTests';
import { getRunDifference } from '../../../functions/sharedGait';

const GaitsEditTabsRunMetric: React.FC<any> = ( props: any ) => {

    const { form, prefix, run, data } = props;

    useMountEffect( () => {

        const defaults = { condition: [ "Initial Assessment" ], shoe: get( form.values, `shoes[0].shoeBrand` ) };

        if ( !run.metrics ) {
            form.handleChange( { reset: true, name: `${ prefix }.metrics[0]`, value: defaults } )
        }
    } );

    const metricColumns = get( form.values, `${ prefix }.metrics`, [] ).map( ( item, index ) => {
        return { label: `Trial ${ index + 1 }`, colSpan: 2 }
    } )

    const handleAddTrial = () => {

        const trial = [ ...run.metrics, {} ];

        form.handleChange( { reset: true, name: `${ prefix }.metrics`, value: trial } );

    };

    const handleDeleteTrial = ( index ) => {

        const trial = run.metrics.filter( ( trial, i ) => i !== index );

        form.handleChange( { reset: true, name: `${ prefix }.metrics`, value: trial } );

    };

    const handleChange = ( index, key ) => {

        let value;

        if ( key == "treadmillPace" ) {

            value = getPaceFromKph( get( run, `metrics[${ index }].treadmillSpeed` ) )

        } else if ( key == "podSpeed" ) {

            value = getKphFromPace( get( run, `metrics[${ index }].podPace` ) )

        } else if ( key == "strideLength" ) {

            if ( get( run, `metrics[${ index }].treadmillSpeed` ) && get( run, `metrics[${ index }].cadence` ) ) {
                value = getStepLength( get( run, `metrics[${ index }].treadmillSpeed` ), get( run, `metrics[${ index }].cadence` ) )
            }

        } else if ( key == "verticalRatio" ) {

            if ( get( run, `metrics[${ index }].strideLength` ) && get( run, `metrics[${ index }].verticalOscillation` ) ) {

                const oscillationToCm = cmToM( get( run, `metrics[${ index }].verticalOscillation` ) );

                value = percentageOf( oscillationToCm, get( run, `metrics[${ index }].strideLength` ), 1 )
            }

        } else if ( key == "gtcRight" ) {

            value = 100 - get( run, `metrics[${ index }].gtcLeft` );

        }

        if ( value ) {
            form.handleChange( { reset: true, silent: true, name: `${ prefix }.metrics[${ index }].${ key }`, value: value } )
        }

    };

    return useMemo( () => (

        <>
            <Row edge gutter={8} justify='end'>

                <Button
                    label="Add Trial"
                    type='faded'
                    size='small'
                    onClick={handleAddTrial}
                />

            </Row>

            <Space />

            <InputTable
                columns={[
                    { label: " *Values in Brackets are Optimal Ranges. " },
                    ...metricColumns
                ]}
                style={{ overflow: "auto", minHeight: 600 }}
            >

                <InputTableRow >

                    <DataTableCell >
                        <strong>Condition</strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell colSpan={2}>

                                <FieldSelect
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].condition`}
                                    defaultValue={get( run, `metrics[${ i }].condition` )}
                                    options={[
                                        { value: "Initial Assessment", label: "Initial Assessment" },
                                        { value: "Different Shoes", label: "Different Shoes" },
                                        { value: "Outdoor Running", label: "Outdoor Running" },
                                        { value: "Faster Run Speed", label: "Faster Run Speed" },
                                        { value: "Slower Run Speed", label: "Slower Run Speed" },
                                        { value: "Barefoot Running", label: "Barefoot Running" },
                                        { value: "G8 Insoles", label: "G8 Insoles" },
                                        { value: "Push Insoles", label: "Push Insoles" },
                                        { value: "Corrective Insoles", label: "Corrective Insoles" },
                                        { value: "In-Shoe Wedges", label: "In-Shoe Wedges" },
                                    ]}
                                    wrapClass={'manual-input'}
                                    isMulti
                                />

                            </InputTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>  Shoes </strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell colSpan={2}>

                                <FieldSelect
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].shoe`}
                                    defaultValue={get( run, `metrics[${ i }].shoe` )}
                                    options={form.values.shoes.map( ( shoe: any ) => ( { value: shoe.shoeBrand, label: shoe.shoeBrand } ) )}
                                    wrapClass={'manual-input'}
                                />

                            </InputTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>Trial Duration</strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell style={{ minWidth: 150 }}>

                                <FieldTextTime
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].trialDuration`}
                                    defaultValue={get( run, `metrics[${ i }].trialDuration` )}
                                    wrapClass={'manual-input'}
                                    placeholder="mm:ss"
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ?
                                    ""
                                    :
                                    getRunDifference( get( run, `metrics` ), i, 'trialDuration' )
                                }
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>Trial Distance</strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].trialDistance`}
                                    defaultValue={get( run, `metrics[${ i }].trialDistance` )}
                                    wrapClass={'manual-input'}
                                    suffix=" m"
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ?
                                    ""
                                    :
                                    getRunDifference( get( run, `metrics` ), i, 'trialDistance' )
                                }
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>Treadmill Speed/Pace</strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <Row edge gutter={8}>

                                    <Col col={{ xs: 12 }}>
                                        <FieldNumber
                                            form={form}
                                            dynamicKey
                                            name={`${ prefix }.metrics[${ i }].treadmillSpeed`}
                                            defaultValue={get( run, `metrics[${ i }].treadmillSpeed` )}
                                            wrapClass={'manual-input'}
                                            onChange={handleChange.bind( null, i, "treadmillPace" )}
                                            suffix=" kph"
                                        />
                                    </Col>

                                    <Col col={{ xs: 12 }}>
                                        <FieldTextTime
                                            form={form}
                                            name={`${ prefix }.metrics[${ i }].treadmillPace`}
                                            defaultValue={get( run, `metrics[${ i }].treadmillPace` )}
                                            key={get( run, `metrics[${ i }].treadmillPace` )}
                                            wrapClass={''}
                                            disabled
                                            placeholder="min/km"
                                        />
                                    </Col>

                                </Row>

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ?
                                    ""
                                    :
                                    getRunDifference( get( run, `metrics` ), i, 'treadmillSpeed' )
                                }
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>Pod Speed/Pace</strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >


                                <Row edge gutter={8}>

                                    <Col col={{ xs: 12 }}>
                                        <FieldNumber
                                            form={form}
                                            name={`${ prefix }.metrics[${ i }].podSpeed`}
                                            defaultValue={get( run, `metrics[${ i }].podSpeed` )}
                                            dynamicKey
                                            suffix=" kph"
                                            disabled
                                        />
                                    </Col>

                                    <Col col={{ xs: 12 }}>
                                        <FieldTextTime
                                            form={form}
                                            name={`${ prefix }.metrics[${ i }].podPace`}
                                            defaultValue={get( run, `metrics[${ i }].podPace` )}
                                            onChange={handleChange.bind( null, i, "podSpeed" )}
                                            wrapClass={'manual-input'}
                                            placeholder="mm:ss"
                                        />
                                    </Col>

                                </Row>
                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ?
                                    ""
                                    :
                                    getRunDifference( get( run, `metrics` ), i, 'podSpeed' )
                                }
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>Cadence Avg</strong>
                        <p>(170-180 spm)</p>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].cadence`}
                                    defaultValue={get( run, `metrics[${ i }].cadence` )}
                                    wrapClass={'manual-input'}
                                    onChange={handleChange.bind( null, i, "strideLength" )}
                                    suffix=" spm"
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ? "" : getRunDifference( get( run, `metrics` ), i, 'cadence' )}
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>Cadence Max</strong>
                        <p>(170-180 spm)</p>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].cadenceMax`}
                                    defaultValue={get( run, `metrics[${ i }].cadenceMax` )}
                                    wrapClass={'manual-input'}
                                    suffix=" spm"
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ? "" : getRunDifference( get( run, `metrics` ), i, 'cadenceMax' )}
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>Step Length</strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].strideLength`}
                                    defaultValue={get( run, `metrics[${ i }].strideLength` )}
                                    disabled
                                    suffix=" m"
                                    onChange={handleChange.bind( null, i, "verticalRatio" )}
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ?
                                    ""
                                    :
                                    getRunDifference( get( run, `metrics` ), i, 'strideLength' )
                                }
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>Vertical Oscillation</strong>
                        <p>(6 to 10 cm)</p>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].verticalOscillation`}
                                    defaultValue={get( run, `metrics[${ i }].verticalOscillation` )}
                                    wrapClass={'manual-input'}
                                    suffix=" cm"
                                    onChange={handleChange.bind( null, i, "verticalRatio" )}
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ? "" :
                                    getRunDifference( get( run, `metrics` ), i, 'verticalOscillation' )
                                }
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>Vertical Ratio</strong>
                        <p>(6.3% to 9.9%)</p>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].verticalRatio`}
                                    defaultValue={get( run, `metrics[${ i }].verticalRatio` )}
                                    suffix=" %"
                                    disabled
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ? "" :
                                    getRunDifference( get( run, `metrics` ), i, 'verticalRatio' )
                                }
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>Ground Contact Time(GCT)</strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].groundContactTime`}
                                    defaultValue={get( run, `metrics[${ i }].groundContactTime` )}
                                    wrapClass={'manual-input'}
                                    suffix=" ms"
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ?
                                    ""
                                    :
                                    getRunDifference( get( run, `metrics` ), i, 'groundContactTime' )
                                }
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>GCT Balance</strong>
                        <p>(49.5% to 50.5%)</p>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].gtcLeft`}
                                    defaultValue={get( run, `metrics[${ i }].gtcLeft` )}
                                    wrapClass={'manual-input inline-label'}
                                    onChange={handleChange.bind( null, i, "gtcRight" )}
                                    label='Left'
                                    suffix=" %"
                                />

                                <Space height={8} />

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].gtcRight`}
                                    defaultValue={get( run, `metrics[${ i }].gtcRight` )}
                                    wrapClass={'inline-label'}
                                    label='Right'
                                    suffix=" %"
                                    disabled
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ? "" :
                                    <Row>
                                        <Col col={{ xs: 24 }}>
                                            {
                                                getRunDifference( get( run, `metrics` ), i, 'gtcLeft' )
                                            }
                                            <Space height={8} />
                                        </Col>
                                        <Col col={{ xs: 24 }}>
                                            <Space height={8} />
                                            {
                                                getRunDifference( get( run, `metrics` ), i, 'gtcRight' )
                                            }
                                        </Col>
                                    </Row>
                                }

                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>HR Avg</strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].hrAvg`}
                                    defaultValue={get( run, `metrics[${ i }].hrAvg` )}
                                    wrapClass={'manual-input'}
                                    suffix=" bpm"
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ?
                                    ""
                                    :
                                    getRunDifference( get( run, `metrics` ), i, 'hrAvg' )
                                }
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>HR Max</strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].hrMax`}
                                    defaultValue={get( run, `metrics[${ i }].hrMax` )}
                                    wrapClass={'manual-input'}
                                    suffix=" bpm"
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ?
                                    ""
                                    :
                                    getRunDifference( get( run, `metrics` ), i, 'hrMax' )
                                }
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>Elevation</strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell >

                                <FieldNumber
                                    form={form}
                                    dynamicKey
                                    name={`${ prefix }.metrics[${ i }].elevation`}
                                    defaultValue={get( run, `metrics[${ i }].elevation` )}
                                    wrapClass={'manual-input'}
                                    suffix=" m"
                                />

                            </InputTableCell>

                            <DataTableCell >
                                {i == 0 ?
                                    ""
                                    :
                                    getRunDifference( get( run, `metrics` ), i, 'elevation' )
                                }
                            </DataTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                        <strong>
                            Comment
                        </strong>
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell colSpan={2}>

                                <FieldTextArea
                                    form={form}
                                    dynamicKey
                                    // label={"Condition"}
                                    name={`${ prefix }.metrics[${ i }].comments`}
                                    defaultValue={get( run, `metrics[${ i }].comments` )}
                                    wrapClass={'manual-input'}
                                />

                            </InputTableCell>

                        </>

                    ) )}

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell >
                    </DataTableCell>

                    {get( form.values, `${ prefix }.metrics`, [] ).map( ( column, i ) => (

                        <>

                            <InputTableCell colSpan={2}>

                                {i == 0 ? "" :
                                    <Button
                                        label="Remove Trial"
                                        type='faded'
                                        size='small'
                                        onClick={handleDeleteTrial.bind( null, i )}
                                    />
                                }

                            </InputTableCell>

                        </>

                    ) )}

                </InputTableRow>

            </InputTable>

        </>

    ), [ form.hash, run ] );

}

export default GaitsEditTabsRunMetric;
