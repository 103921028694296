import { parseNum } from "@dex/bubl-helpers";

export const calculateRatingMobility = (values) => {

    let mobilityRating = 0;

    if (values.toeTouch === "Good" || values.toeTouch === "Not Assesed") {
        mobilityRating += 5;
    } else if (values.toeTouch === "Adequate") {
        mobilityRating += 2.5;
    }

    if (values.hamstrings === "Good" || values.hamstrings === "Not Assesed") {
        mobilityRating += 1;
    } else if (values.hamstrings === "Adequate") {
        mobilityRating += 0.5;
    }

    if (values.itBand === "Good" || values.itBand === "Not Assesed") {
        mobilityRating += 1;
    } else if (values.itBand === "Adequate") {
        mobilityRating += 0.5;
    }

    if (values.quads === "Good" || values.quads === "Not Assesed") {
        mobilityRating += 0.3;
    } else if (values.quads === "Adequate") {
        mobilityRating += 0.15;
    }

    if (values.hipFlexion === "Good" || values.hipFlexion === "Not Assesed") {
        mobilityRating += 0.3;
    } else if (values.hipFlexion === "Adequate") {
        mobilityRating += 0.15;
    }

    if (values.hipFlexors === "Good" || values.hipFlexors === "Not Assesed") {
        mobilityRating += 0.3;
    } else if (values.hipFlexors === "Adequate") {
        mobilityRating += 0.15;
    }

    if (values.hipInternalRotation === "Good" || values.hipInternalRotation === "Not Assesed") {
        mobilityRating += 0.3;
    } else if (values.hipInternalRotation === "Adequate") {
        mobilityRating += 0.15;
    }

    if (values.trunkRotation === "Good" || values.trunkRotation === "Not Assesed") {
        mobilityRating += 0.3;
    } else if (values.trunkRotation === "Adequate") {
        mobilityRating += 0.15;
    }

    if (values.plantarFlexion === "Good" || values.plantarFlexion === "Not Assesed") {
        mobilityRating += 0.3;
    } else if (values.plantarFlexion === "Adequate") {
        mobilityRating += 0.15;
    }

    if (values.dorsiFlexion === "Good" || values.dorsiFlexion === "Not Assesed") {
        mobilityRating += 0.3;
    } else if (values.dorsiFlexion === "Adequate") {
        mobilityRating += 0.15;
    }

    if (values.shoulders === "Good" || values.shoulders === "Not Assesed") {
        mobilityRating += 0.3;
    } else if (values.shoulders === "Adequate") {
        mobilityRating += 0.15;
    }

    if (values.upperMidBack === "Good" || values.upperMidBack === "Not Assesed") {
        mobilityRating += 0.3;
    } else if (values.upperMidBack === "Adequate") {
        mobilityRating += 0.15;
    }

    if (values.lowerBack === "Good" || values.lowerBack === "Not Assesed") {
        mobilityRating += 0.3;
    } else if (values.lowerBack === "Adequate") {
        mobilityRating += 0.15;
    }

    return mobilityRating.toFixed(0);

}

export const calculateRatingStability = (values) => {

    let rating = 0;

    if (values.legSquatLeft === "Stable") {
        rating += 3;
    } else if (values.legSquatLeft === "Some Instability") {
        rating += 1.5;
    } else if (values.legSquatLeft === "Lots of Instability") {
        rating += 0;
    }

    if (values.legSquatRight === "Stable") {
        rating += 3;
    } else if (values.legSquatRight === "Some Instability") {
        rating += 1.5;
    } else if (values.legSquatRight === "Lots of Instability") {
        rating += 0;
    }

    if (values.coreStability && values.coreStability.length > 0) {

        rating += parseNum(values.coreStability?.sort().reverse()[0]) - 1;

    }

    return rating.toFixed(0);

}

export const calculateRatingSymmetry = (values) => {

    let rating = 0;

    if (values.hipLevelStanding === "Even") {
        rating += 2;
    }

    if (values.hipLevelLying === "Even") {
        rating += 2;
    }

    if (values.hipRotationStanding === "Even") {
        rating += 2;
    }

    if (values.legLength === "Even") {
        rating += 4;
    }

    return rating.toFixed(0);

}