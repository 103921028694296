import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Space, useApp } from '@dex/bubl-dash';
import { parseNum } from '@dex/bubl-helpers';
import TestSessionsReportMenu from './TestSessionsReportMenu';
import TestSessionsReportPage from './TestSessionsReportPage';

import styles from "./TestSessionsReport.module.scss";
import TestSessionsReportCover from './TestSessionsReportCover';
import TestSessionsReportAthlete from './TestSessionsReportAthlete';
import TestSessionsReportBio from './TestSessionsReportBio';
import TestSessionsReportSprintPerformance from './TestSessionsReportSprintPerformance';
import TestSessionsReportSprintMuscleFitness from './TestSessionsReportSprintMuscleFitness';
import TestSessionsReportSprintHeartRate from './TestSessionsReportSprintHeartRate';
import TestSessionsReportSummary from './TestSessionsReportSummary';

const TestSessionsReportSprint: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const [activePage, setActivePage] = useState(0);

    const observer: any = useRef();

    const pages: any = [
        {
            key: "cover",
            label: "Cover",
            heading: false,
            el: TestSessionsReportCover,
            ref: useRef()
        },
        {
            key: "athlete-profile",
            label: "Athlete Profile",
            heading: "Athlete Profile and Wellness",
            icon: require("../../../assets/images/LabReport/icon-athlete.png"),
            el: TestSessionsReportAthlete,
            ref: useRef()
        },
        {
            key: "body-composition",
            label: "Body Composition",
            heading: "Body Composition",
            icon: require("../../../assets/images/LabReport/icon-bio.png"),
            el: TestSessionsReportBio,
            ref: useRef()
        },
        {
            key: "performance",
            label: "Performance",
            heading: "Performance",
            icon: data.test.activity == "Running" ? require("../../../assets/images/LabReport/icon-running.png") : require("../../../assets/images/LabReport/icon-cycling.png"),
            el: TestSessionsReportSprintPerformance,
            ref: useRef()
        },
        {
            key: "muscle-fitness",
            label: "Muscle Fitness",
            heading: "Muscle Fitness",
            icon: require("../../../assets/images/LabReport/icon-muscle.png"),
            el: TestSessionsReportSprintMuscleFitness,
            ref: useRef()
        },
        {
            key: "heart-rate",
            label: "Heart Rate",
            heading: "Heart Rate",
            icon: require("../../../assets/images/LabReport/icon-circulation.png"),
            el: TestSessionsReportSprintHeartRate,
            ref: useRef()
        },
        {
            key: "summary",
            label: "Summary",
            heading: "Summary",
            icon: require("../../../assets/images/LabReport/icon-summary.png"),
            el: TestSessionsReportSummary,
            ref: useRef()
        },
    ].filter((page) => {

        if (!data.reportPages || !data.reportPages.length) return true;

        return data.reportPages.includes(page.key) ? true : false;

    });

    useEffect(() => {

        const callback = (entries, observer) => {

            let inViewTarget: any = null;
            let inViewRatio = 0;

            entries.forEach((entry, index) => {

                if (entry.isIntersecting && entry.intersectionRatio > inViewRatio) {

                    inViewRatio = entry.intersectionRatio;
                    inViewTarget = entry.target;

                }

            })

            if (inViewTarget) setActivePage(parseNum(inViewTarget.id));

        };

        const observer = new IntersectionObserver(callback, {
            threshold: .4,
        });

        pages.map((page) => {

            observer.observe(page.ref.current);

        });

        () => {

            pages.map((page) => {

                observer.unobserve(page.ref.current);

            });

            observer.disconnect();

        }

    }, []);

    const handleScrollTo = useCallback((ref, event) => {

        if (event && event.preventDefault) event.preventDefault();

        ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

    }, []);

    const app = useApp();

    const [settings] = app.store("appSettings");

    return useMemo(() => (

        <>

            <TestSessionsReportMenu
                data={data}
                activePage={activePage}
                pages={pages}
                handleScrollTo={handleScrollTo}
                form={form}
            />

            {pages.map((page, index) => (
                <React.Fragment key={index}>

                    {index >= 0 &&
                        <Space />
                    }

                    <TestSessionsReportPage
                        heading={page.heading}
                        icon={page.icon}
                        index={index}
                        observer={observer}
                        forwardedRef={page.ref}
                        setActivePage={setActivePage}
                        form={form}
                    >

                        {page.el &&
                            <page.el
                                data={data}
                                form={form}
                                description={settings?.performanceTracking}
                            />
                        }

                    </TestSessionsReportPage>


                </React.Fragment>
            ))}

        </>

    ), [data, activePage, form.hash]);

}

export default TestSessionsReportSprint;