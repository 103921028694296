import { Col, InputTable, InputTableCell, InputTableRow, Row, Space, useMountEffect } from '@dex/bubl-dash';
import React, { useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash';
import styles from "./TestSessionsReportMetabolicRate.module.scss"
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import RatingScalesApi from '../../RatingScales/RatingScalesApi';
import { get, hash } from '@dex/bubl-helpers';
import { ratingLabel, stepsMinMax } from '../../../elements/RatingScale/RatingScaleElements';
import { getDiffRating } from '../../../functions/sharedTests';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportMetabolicRate: React.FC<any> = (props: any) => {

    const { data, form } = props;

    let gender = data.athlete.gender;

    const testData = data.test;

    const measurementTable = [
        { title: "VO2", content: `${testData.manual.vo2kg} ml/kg*min`, description: "Oxygen Consumption" },
        { title: "VCO2", content: `${testData.manual.vco2kg} ml/kg*min`, description: "Carbon Dioxide Expiration" },
        { title: "HR", content: `${testData.manual.restingHr} bpm`, description: "Heart Rate" },
        { title: "RQ", content: `${testData.manual.rq}`, description: "Fuel Ratio (Respiratory Quotient)" },
        { title: "TV", content: `${testData.manual.tv} L per breath`, description: "Breathing (Tidal) Volume" },
        { title: "BF", content: `${testData.manual.bf} per min`, description: "Breathing Rate" }
    ];

    const fuelTable = [
        { title: "CARBS", g: `${testData.manual.gram.cho} g`, kcal: `${testData.manual.kcal.cho} kcal`, description: "Carbohydrates Burned" },
        { title: "FAT", g: `${testData.manual.gram.fat} g`, kcal: `${testData.manual.kcal.fat} kcal`, description: "Fat Burned" },
        { title: "PROTEIN", g: `${testData.manual.gram.protein} g`, kcal: `${testData.manual.kcal.protein} kcal`, description: "Protein Burned" },
        { title: "TOTAL", g: `${testData.manual.gram.total} g`, kcal: `${testData.manual.kcal.total} kcal`, description: "Total Burned" },
    ];

    const zoneUse = testData.manual.level || "sedentary";

    let classIndex = 0;

    if (zoneUse === "sedentary" || zoneUse == "mild") {
        classIndex = 0;
    } else if (zoneUse === "moderate") {
        classIndex = 1;
    } else if (zoneUse === "high") {
        classIndex = 2;
    } else {
        classIndex = 3;
    };

    const energyData = [
        {
            score: parseFloat(testData.rmr.personal?.rest),
            label: "At Rest (RMR)"
        },
        {
            score: parseFloat(testData.rmr.personal?.light),
            label: "Light Activity"
        },
        {
            score: parseFloat(testData.rmr.personal?.moderate),
            label: "Moderate Activity"
        },
        {
            score: parseFloat(testData.rmr.personal?.high),
            label: "High Activity"
        },
    ];

    const fuelData = [
        {
            score: parseFloat(testData.manual.kcal.cho),
            label: "Carb"
        },
        {
            score: parseFloat(testData.manual.kcal.fat),
            label: "Fat"
        },
        {
            score: parseFloat(testData.manual.kcal.protein),
            label: "Protein"
        },
        {
            score: parseFloat(testData.manual.kcal.total),
            label: "Total"
        },
    ];

    const getColumnHeight = (items, score) => {
        const scores = _.map(items, "score");
        const max = _.max(scores);

        if (score >= max / 2) score = score * 0.8;

        const heightPercentage = (score / max) * 100;
        const maxHeight = 350;
        const heightInPixels = (heightPercentage / 100) * maxHeight;

        return heightInPixels + "px";
    };

    const metric = 'Metabolism';
    const group = RatingScalesApi.getData();
    const value = testData.rmr.norm?.restDif;
    const demographic = data.test?.rmr?.metabolismRating || data?.demographic

    const [metricData, setMetricData]: any = useState({ metric: metric, demographic: "Scale Not Found" });

    if (!gender && form) get(form.values, "gender");
    if (!gender) gender = "male";

    useEffect(() => {

        if (!metric) return;

        group.run({
            params: {
                metric: metric
            },
            onComplete: (response) => {

                if (response) setMetricData(response);

            }
        });

    }, [metricData, data]);

    const { steps, min, max }: any = stepsMinMax(metricData, demographic, gender);

    const rating = ratingLabel(value, steps, min, max);

    return useMemo(() => (

        <div className={styles.container}>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={16} edge>

                <Col col={{ xs: 24 }}>
                    <ReportHeading
                        text="Energy Expenditure"
                    />
                </Col>

                <Col col={{ xs: 24, lg: 12 }}>

                    <Space />

                    <div className='peak-hr'>

                        <div className='hr-chart'>

                            {energyData.map((item: any, index: any) => (
                                <Col
                                    className='today-hr'
                                    key={index}
                                    align='center'
                                    justify='end'
                                >

                                    <Row justify='center'>
                                        <p className={`hr-bpm${index}`}>{item.score} kcal</p>
                                    </Row>

                                    <Row justify='center'>
                                        <div className={`columns${index}`} style={{ height: getColumnHeight(energyData, item.score) }} >
                                            <div className='graphic' />
                                        </div>
                                    </Row>

                                    <Row justify='center'>
                                        <p className='hr-title'>{item.label}</p>
                                    </Row>

                                    <Row justify='center'>
                                        <div className='checkmark' style={{ visibility: index === classIndex ? "visible" : "hidden" }}>
                                            <div className='checkmarkContainer'>
                                                <img
                                                    src={require('../../../assets/images/Circulation/checkmark.png')}
                                                    className='checkmarkImage'
                                                />
                                                <p>Current Level</p>
                                            </div>
                                        </div>
                                    </Row>

                                </Col>
                            ))}

                        </div>

                    </div>

                    <Space />

                </Col>

                <Col col={{ xs: 24, lg: 12 }}>

                    <InputTable
                        className={"mini-table"}
                        columns={[
                            {
                                key: "heading",
                                label: "Measurements At Rest",
                                colSpan: 3
                            },
                        ]}
                    >

                        {measurementTable.map((item, index) => (

                            <InputTableRow key={index}>

                                <InputTableCell>
                                    <strong>{item.title}</strong>
                                </InputTableCell>

                                <InputTableCell>
                                    <strong>{item.content}</strong>
                                </InputTableCell>

                                <InputTableCell>
                                    {item.description}
                                </InputTableCell>

                            </InputTableRow>
                        ))}

                        <InputTableRow>

                            <InputTableCell colSpan={2}>
                                <strong>Measurement Duration:</strong>
                            </InputTableCell>

                            <InputTableCell>
                                {testData.manual.duration} min
                            </InputTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <InputTableCell colSpan={2}>
                                <strong>Measurement Device:</strong>
                            </InputTableCell>

                            <InputTableCell>
                                {testData.equipment.gasAnalysisUnit}
                            </InputTableCell>

                        </InputTableRow>

                    </InputTable>

                </Col>

                <Col col={{ xs: 24, lg: 12 }}>

                    <Row>

                        <RatingScaleCard
                            metric={'Metabolism'}
                            value={testData.rmr.norm?.restDif}
                            demographic={data.test.rmr.metabolismRating || data.demographic}
                            renderAfter={() => (
                                <div className={styles.diffContainer}><p><span> {getDiffRating(testData.rmr.norm?.restDif)}</span>  (Norms)</p></div>
                            )}
                            gender={gender}
                        />
                    </Row>

                    <Space />

                    <Row className='description'>

                        <>
                            <strong>Your resting metabolic rate is rated as
                                <span> {rating}</span> relative to the average person of your age and gender. When at rest and doing no activity, you need to consume
                                <span> {energyData[0]?.score} kcal </span>just to maintain your body weight. When very active, you will need to consume as much as
                                <span> {energyData[3]?.score} kcal</span> to meet your energy requirements and maintain your weight.
                            </strong>
                        </>

                    </Row>

                </Col>

                <Col col={{ xs: 24, lg: 12 }}>
                    <ReportDesc
                        label="RMR Consumption :"
                        value={testData.rmr.metabollicDescription}
                    />
                </Col>

                <Col col={{ xs: 24 }}>
                    <ReportHeading
                        text="Fuel Consumed At Rest Per Day"
                    />
                </Col>

                <Col col={{ xs: 24, lg: 12 }}>

                    <Space />

                    <div className='peak-hr'>

                        <div className='hr-chart'>

                            {fuelData.map((item: any, index: any) => (
                                <Col
                                    className={index == fuelData.length - 1 ? "lastHr" : 'today-hr'}
                                    key={index}
                                    align='center'
                                    justify='end'
                                    gutter
                                >

                                    <Row justify='center'>
                                        <p className={`hr-bpm${index}`}>{item.score} kcal</p>
                                    </Row>

                                    <Row justify='center'>
                                        <div className={`columns${index}`} style={{ height: getColumnHeight(fuelData, item.score) }} >
                                            <div className='graphic' />
                                        </div>
                                    </Row>

                                    <Row justify='center'>
                                        <p className={`hr-title${index}`}>{item.label}</p>
                                    </Row>

                                </Col>

                            ))}

                        </div>

                    </div>

                    <Space height={20} />

                </Col>

                <Col col={{ xs: 24, lg: 12 }}>

                    <InputTable
                        className={"mini-table"}
                        columns={[
                            {
                                key: "1",
                                label: ""
                            },
                            {
                                key: "2",
                                label: "g/day"
                            },
                            {
                                key: "3",
                                label: "Kcal/day"
                            },
                            {
                                key: "4",
                                label: ""
                            }
                        ]}
                    >

                        {fuelTable.map((item, index) => (

                            <InputTableRow key={index}>

                                <InputTableCell style={{ width: 200 }}>
                                    <strong>{item.title}</strong>
                                </InputTableCell>

                                <InputTableCell style={{ width: 110 }}>
                                    <strong>{item.g}</strong>
                                </InputTableCell>

                                <InputTableCell style={{ width: 110 }}>
                                    <strong>{item.kcal}</strong>
                                </InputTableCell>

                                <InputTableCell style={{ width: 200 }}>
                                    {item.description}
                                </InputTableCell>

                            </InputTableRow>
                        ))}

                    </InputTable>
                </Col>

                <Col col={{ xs: 24, lg: 12 }}>
                    <RatingScaleCard
                        metric={'Fuel Burner'}
                        value={testData.manual.rq}
                        demographic={data.test.rmr.fuelRating || data.demographic}
                        prefix="RQ"
                    />
                </Col>

                <Col col={{ xs: 24, lg: 12 }}>
                    <ReportDesc
                        label="Fuel Consumption :"
                        value={testData.rmr.fuelDescription}
                    />
                </Col>

            </Row>

        </div>

    ), [data, metricData]);

}

export default TestSessionsReportMetabolicRate;