import React, { Suspense, useCallback, useContext, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import GaitsEditTabsPanel from './GaitsEditTabsPanel';
import FieldList from '@dex/bubl-dash/src/lib/components/Fields/FieldList';
import FieldPopup from '@dex/bubl-dash/src/lib/components/Fields/FieldPopup';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import EditableContext from '../../../context/EditableContext';
import GaitsEditTabsRunDetails from '../Run/GaitsEditTabsRunDetails';
import GaitsEditTabsRunPictures from '../Run/GaitsEditTabsRunPictures';
import GaitsEditTabsRunMetric from '../Run/GaitsEditTabsRunMetric';
import GaitsEditTabsRunChanges from '../Run/GaitsEditTabsRunChanges';
import GaitsEditTabsRunSideView from '../Run/GaitsEditTabsRunSideView';
import GaitsEditTabsRunRearView from '../Run/GaitsEditTabsRunRearView';
import { get, set } from '@dex/bubl-helpers';

const GaitsEditTabsRun: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, data, settings } = props;

    return useMemo(() => (

        <GaitsEditTabsPanel
            heading={"Run"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldList
                        form={form}
                        name={`runs`}
                        label={"Runs"}
                        addButtonLabel={"Add Run"}
                        defaultValues={{
                            side: {
                                footInclination: { comment: get(settings.gait, "footInclination") },
                                legInclination: { comment: get(settings.gait, "legInclination") },
                                footStrikeAudio: { comment: get(settings.gait, "footStrikeAudio") },
                                kneeAngle: { comment: get(settings.gait, "kneeAngle") },
                                armSwingStyle: { comment: get(settings.gait, "armSwingStyle") },
                                torsoLean: { comment: get(settings.gait, "torsoLean") },
                                pelvicTilt: { comment: get(settings.gait, "pelvicTilt") },
                            },
                            rear: {
                                heelStrikeOrientation: { comment: get(settings.gait, "heelStrikeOrientation") },
                                footPronationRate: { comment: get(settings.gait, "footPronationRate") },
                                footDirection: { comment: get(settings.gait, "footDirection") },
                                legAngle: { comment: get(settings.gait, "legAngle") },
                                kneeShifting: { comment: get(settings.gait, "kneeShifting") },
                                pelvicDrop: { comment: get(settings.gait, "pelvicDrop") },
                                torsoRotation: { comment: get(settings.gait, "torsoRotation") },
                                elbowPosition: { comment: get(settings.gait, "elbowPosition") },
                                toeOffArea: { comment: get(settings.gait, "toeOffArea") },
                                heelWhip: { comment: get(settings.gait, "heelWhip") },
                                legCross: { comment: get(settings.gait, "legCross") },
                            }
                        }}
                    >

                        {(form.values.runs || []).map((run, i) => (

                            <GaitsEditTabsRunForm
                                key={i}
                                i={i}
                                run={run}
                                form={form}
                                data={data}
                            />

                        ))}

                    </FieldList>

                </Col>

            </Row>

        </GaitsEditTabsPanel>


    ), [props]);

}

const GaitsEditTabsRunForm: React.FC<any> = (props: any) => {

    const { i, run, form, data } = props;

    const prefix = `runs[${i}]`;

    const [key, setKey] = useState(0);

    const [tab, setTab] = useState("details");

    const handleCopy = useCallback((handleClose) => {

        const items = form.getValue('runs');

        handleClose();

        items.push(structuredClone(run));

        form.handleChange({ name: 'runs', value: items });

        form.setKey();

    }, [run]);

    const upKey = useCallback(() => {

        setKey(key + 1);

    }, [key]);

    const editable = useContext(EditableContext);

    return (

        <FieldPopup
            buttonLabel={"Run Analysis " + (i + 1) + " - " + get(form.values, `${prefix}.date`) + " " + get(form.values, `${prefix}.condition`)}
            heading={"Run Analysis " + (i + 1) + " - " + get(form.values, `${prefix}.date`) + " " + get(form.values, `${prefix}.condition`)}
            form={form}
            name={prefix}
            modalProps={{
                className: 'modal-top'
            }}
            actionButtons={(handleClose) => (

                <Button
                    label="Copy To New Run"
                    size="small"
                    type={"faded"}
                    onClick={handleCopy.bind(null, handleClose)}
                />

            )}
            panelProps={{
                tabs: [
                    { label: "Details", name: "details" },
                    { label: "Side View", name: "side view" },
                    { label: "Rear View", name: "rear view" },
                    { label: "Metric", name: "metric" },
                    { label: "Changes", name: "changes" },
                    { label: "Pictures", name: "pictures" },
                ].filter(Boolean),
                setTab: setTab,
                activeTab: tab
            }}
        >

            <div key={key} className={!editable ? 'form-not-editable' : ''}>

                <div className={tab === "details" ? "" : "hidden"}>

                    <GaitsEditTabsRunDetails
                        i={i}
                        form={form}
                        data={data}
                        run={run}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "side view" ? "" : "hidden"}>

                    <GaitsEditTabsRunSideView
                        i={i}
                        form={form}
                        data={data}
                        run={run}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "rear view" ? "" : "hidden"}>

                    <GaitsEditTabsRunRearView
                        i={i}
                        form={form}
                        data={data}
                        run={run}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "metric" ? "" : "hidden"}>

                    <GaitsEditTabsRunMetric
                        i={i}
                        form={form}
                        data={data}
                        run={run}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "changes" ? "" : "hidden"}>

                    <GaitsEditTabsRunChanges
                        i={i}
                        form={form}
                        data={data}
                        run={run}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "pictures" ? "" : "hidden"}>

                    <GaitsEditTabsRunPictures
                        i={i}
                        form={form}
                        data={data}
                        run={run}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

            </div>

        </FieldPopup>

    )

}

export default GaitsEditTabsRun;
