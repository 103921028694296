import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Panel, Button, MetaTable, Seperator, Space } from '@dex/bubl-dash';
import { getRandomInt } from '../../../functions/sharedGeneral';
import TrainingCard from '../../../elements/TrainingCard/TrainingCard';

const TrainingZonesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Training Zones Details"}
                    actions={
                        <Button
                            label={"Copy To New Zones"}
                            type={"faded"}
                            path={`training-zones/create?cloneId=${data.id}`}
                        />
                    }
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Demographic",
                                key: "demographic",
                                format: "titleCase"
                            },

                        ]}
                        columnB={[
                            {
                                label: "Activity",
                                key: "activity",
                            },
                        ]}
                        columnC={[

                        ]}
                    />

                    <Seperator
                        heading={"Preview"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <TrainingCard
                        data={data}
                        zone={1}
                    />

                    <Space />

                    <TrainingCard
                        data={data}
                        zone={2}
                    />

                    <Space />

                    <TrainingCard
                        data={data}
                        zone={3}
                    />

                    <Space />

                    <TrainingCard
                        data={data}
                        zone={4}
                    />

                    <Space />

                    <TrainingCard
                        data={data}
                        zone={5}
                    />

                    <Space />

                    <TrainingCard
                        data={data}
                        zone={6}
                    />


                </Panel>

            </Col>

        </Row>

    ), [data]);

}

export default TrainingZonesSingleView;
