import { fixedNum, loop, slug } from '@dex/bubl-helpers';
import React, { useEffect, useMemo } from 'react';
import { Area, AreaChart, ComposedChart, ReferenceLine, Label } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartEnergyFueling: React.FC<ChartEnergyFuelingProps> = (props) => {

    let { data, activity, type, unit, date, index, intensity, testData } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity(activity, intensity);

    const historyData = Axis.prepareHistoryData(data, slug(date), slug(index));

    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
    };

    const fatmax = fixedNum(testData?.test?.energy?.fatmaxLoad);
    const mep = fixedNum(testData?.test?.energy?.mepLoad);

    if (type === "burn") {

        unit == "Carb g" ?
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 0,
                key: "cho_" + slug(date),
                label: "Carb - " + date,
                axisLabel: "Carb - g/hr",
                props: {
                    connectNulls: true,
                }
            })
            :
            null;

        unit == "Carb kcal" ?
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 0,
                key: "eecho_" + slug(date),
                label: "Carb - " + date,
                axisLabel: "Carb - Kcal/hr",
                props: {
                    connectNulls: true,
                }
            })
            :
            null;

        unit == "Fat g" ?
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 1,
                key: "fat_" + slug(date),
                label: "Fat - " + date,
                axisLabel: "Fat - Kcal/hr",
                props: {
                    connectNulls: true,
                }
            })
            :
            null;

        unit == "Fat kcal" ?
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 1,
                key: "eefat_" + slug(date),
                label: "Fat - " + date,
                axisLabel: "Fat - Kcal/hr",
                props: {
                    connectNulls: true,
                }
            })
            :
            null;

        if (date !== index) {

            unit == "Carb g" ?
                Axis.y.push({
                    axis: true,
                    line: true,
                    pos: "left",
                    index: 0,
                    key: "cho_" + slug(index),
                    label: "Carb - " + index,
                    props: {
                        strokeDasharray: "6 4",
                        connectNulls: true,
                    }
                })
                :
                null;

            unit == "Carb kcal" ?
                Axis.y.push({
                    axis: true,
                    line: true,
                    pos: "left",
                    index: 0,
                    key: "eecho_" + slug(index),
                    label: "Carb - " + index,
                    props: {
                        connectNulls: true,
                        strokeDasharray: "6 4",
                    }
                })
                :
                null;

            unit == "Fat g" ?
                Axis.y.push({
                    axis: true,
                    line: true,
                    pos: "left",
                    index: 1,
                    key: "fat_" + slug(index),
                    label: "Fat - " + index,
                    axisLabel: "Fat - Kcal/hr",
                    props: {
                        strokeDasharray: "6 4",
                        connectNulls: true,
                    }
                })
                :
                null;

            unit == "Fat kcal" ?
                Axis.y.push({
                    axis: true,
                    line: true,
                    pos: "left",
                    index: 1,
                    key: "eefat_" + slug(index),
                    label: "Fat - " + index,
                    axisLabel: "Fat - Kcal/hr",
                    props: {
                        strokeDasharray: "6 4",
                        connectNulls: true,
                    }
                })
                :
                null;

        }

    } else if (type === "mep") {

        if (unit == "All") {
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 0,
                key: "calc_cho_" + slug(date),
                label: "% Carb - " + date,
                axisLabel: "% of Total Carb",
                props: {
                    type: "number",
                    domain: [0, 100],
                    connectNulls: true,
                    tickFormatter: (val) => val + "%",
                    suffix: "%",
                }
            });
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 1,
                key: "calc_fat_" + slug(date),
                label: "% Fat - " + date,
                axisLabel: "% of Total Fat",
                props: {
                    connectNulls: true,
                    tickFormatter: (val) => val + "%",
                    suffix: "%",
                }
            });
        } else if (unit == "Carb") {
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 0,
                key: "calc_cho_" + slug(date),
                label: "% Carb - " + date,
                axisLabel: "% of Total Carb",
                props: {
                    type: "number",
                    domain: [0, 100],
                    connectNulls: true,
                    tickFormatter: (val) => val + "%",
                    suffix: "%",
                }
            });
        } else {
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 1,
                key: "calc_fat_" + slug(date),
                label: "% Fat - " + date,
                axisLabel: "% of Total Fat",
                props: {
                    connectNulls: true,
                    tickFormatter: (val) => val + "%",
                    suffix: "%",
                }
            });
        }

        if (date !== index) {
            if (unit == "All") {
                Axis.y.push({
                    axis: false,
                    line: true,
                    pos: "left",
                    index: 0,
                    key: "calc_cho_" + slug(index),
                    label: "% Carb - " + index,
                    props: {
                        type: "number",
                        domain: [0, 100],
                        strokeDasharray: "6 4",
                        connectNulls: true,
                        suffix: "%",

                    }
                });
                Axis.y.push({
                    axis: false,
                    line: true,
                    pos: "left",
                    index: 1,
                    key: "calc_fat_" + slug(index),
                    label: "% Fat - " + index,
                    props: {
                        strokeDasharray: "6 4",
                        connectNulls: true,
                        suffix: "%",

                    }
                });
            } else if (unit == "Carb") {
                Axis.y.push({
                    axis: false,
                    line: true,
                    pos: "left",
                    index: 0,
                    key: "calc_cho_" + slug(index),
                    label: "% Carb - " + index,
                    props: {
                        type: "number",
                        domain: [0, 100],
                        strokeDasharray: "6 4",
                        connectNulls: true,
                        suffix: "%",

                    }
                });
            } else {
                Axis.y.push({
                    axis: false,
                    line: true,
                    pos: "left",
                    index: 1,
                    key: "calc_fat_" + slug(index),
                    label: "% Fat - " + index,
                    props: {
                        strokeDasharray: "6 4",
                        connectNulls: true,
                        suffix: "%",

                    }
                });
            }

        }

    };

    return (

        <Chart>

            <ComposedChart
                {...ChartProps.LineChart()}
                data={historyData}
            >

                {Axis.render()}

                {fatmax && type === "burn" &&
                    <ReferenceLine
                        x={fatmax}
                        stroke={ChartProps.indexColor(2)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={"FATMAX"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                        />
                    </ReferenceLine>
                }

                {mep && type === "mep" &&
                    <ReferenceLine
                        x={mep}
                        stroke={ChartProps.indexColor(2)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={"MEP"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                        />
                    </ReferenceLine>
                }

            </ComposedChart>

        </Chart>


    )

}

interface ChartEnergyFuelingProps {
    [key: string]: any,
}

export default ChartEnergyFueling;