import { Line, Form, Row, ButtonRow, Button, useApp, useForm, Col, FieldSelect, FieldAutocomplete, FieldNumber, FieldDate, FieldRelationship, FieldCheckbox, Seperator, FieldTextArea, Space, InputTable, InputTableCell, InputTableRow, FieldRadio, DataTableCell, FieldHidden } from '@dex/bubl-dash';
import React, { useCallback, useContext, useMemo } from 'react';
import EditableContext from '../../../context/EditableContext';
import CoachingApi from '../CoachingApi';
import { get } from '@dex/bubl-helpers';
import TestSessionsApi from '../../TestSessions/TestSessionsApi';

const CoachingGeneralForm: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const editable = useContext(EditableContext);

    const form = useForm(data);

    const update = CoachingApi.update(data.id, "general", {
        onComplete: (data) => {

            app.alert('Coaching Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, []);

    const getSession: any = TestSessionsApi.getOne(":id");

    const handleFill = useCallback((id) => {

        getSession.run({
            url: getSession.options.url.replace(":id", id),
            onComplete: (response) => {

                form.handleChange({ name: "general.weight", value: response?.bio?.weight, reset: true })
                form.handleChange({ name: "general.height", value: response?.bio?.height, reset: true })
                form.handleChange({ name: "general.bodyFat", value: response?.bio?.manualPercentageBf, reset: true })

            }
        });

    }, []);

    const trainingMode = [
        { desc: "All/Mostly Outdoors" },
        { desc: "Indoors/Outdoors Random" },
        { desc: "Indoors Weekdays/Outdoors Weekends" },
        { desc: "All/Mostly on Trainer/Treadmill" }
    ];

    return useMemo(() => (

        <>

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={update.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, sm: 5 }}>

                        <FieldAutocomplete
                            form={form}
                            label='Sport To Train For'
                            name={"general.sport"}
                            defaultValue={get(form.defaults, "general.sport")}
                            group={"sport"}
                            required
                            options={[
                                { label: "Triathlon", value: "triathlon" },
                                { label: "Duathlon", value: "duathlon" },
                                { label: "Cycling", value: "cycling" },
                                { label: "Running", value: "running" },
                                { label: "Swimming", value: "swimming" },
                                { label: "Multisport", value: "multisport" },
                                { label: "Other", value: "other" },
                            ]}
                            wrapClass={"manual-input"}
                            sort={false}
                        />

                        <FieldCheckbox
                            key={form.defaults.general?.sportList}
                            form={form}
                            name={"general.sportList"}
                            defaultValue={form.defaults.general?.sportList}
                            required
                            options={[
                                { label: "Swim", value: "swim" },
                                { label: "Bike", value: "bike" },
                                { label: "Run", value: "run" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 5 }}>

                        <FieldSelect
                            form={form}
                            name="general.level"
                            label="Level"
                            defaultValue={form.defaults.general.level}
                            options={[
                                { value: "national", label: "National" },
                                { value: "state", label: "State" },
                                { value: "junior", label: "Junior" },
                                { value: "amateur", label: "Amateur" },
                                { value: "club", label: "Club" },
                                { value: "novice", label: "Novice" },
                                { value: "age-grouper", label: "Age-Grouper" },
                                { value: "recreational", label: "Recreational" },
                                { value: "other", label: "Other" }
                            ]}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 4 }}>

                        <FieldNumber
                            form={form}
                            name="general.experience"
                            label="Years In Sport"
                            defaultValue={form.defaults.general.experience}
                            suffix=" yrs"
                            wrapClass={"manual-input"}
                        />

                    </Col>


                    <Col col={{ xs: 24, sm: 5 }}>

                        <FieldDate
                            form={form}
                            name={'general.startDate'}
                            label={'Start Date'}
                            required={true}
                            defaultValue={form.defaults.general.startDate}
                            date
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 5 }}>

                        <FieldRelationship
                            form={form}
                            name="coachId"
                            label="Coach"
                            required
                            defaultValue={form.defaults.coachId}
                            preload={true}
                            endpoint={'Fitters'}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Seperator
                            heading="Most Current Bio Data"
                            bottom={"small"}
                        />
                    </Col>

                    <Col col={{ xs: 24, sm: 4 }}>

                        <FieldNumber
                            dynamicKey
                            form={form}
                            name="general.age"
                            label="Age"
                            defaultValue={form.defaults.general.age}
                            suffix=" yrs"
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 4 }}>

                        <FieldNumber
                            dynamicKey
                            form={form}
                            name="general.weight"
                            label="Weight (kg)"
                            suffix={" kg"}
                            defaultValue={form.defaults.general.weight}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 4 }}>

                        <FieldNumber
                            dynamicKey
                            form={form}
                            name="general.height"
                            label="Height (cm)"
                            suffix={" cm"}
                            defaultValue={form.defaults.general.height}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 4 }}>

                        <FieldNumber
                            dynamicKey
                            form={form}
                            name="general.bodyFat"
                            label="%Body Fat"
                            suffix={" %"}
                            defaultValue={form.defaults.general.bodyFat}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 20, sm: 5 }}>

                        <FieldRelationship
                            form={form}
                            name="general.testId"
                            label="Get Bio Data From Previous Test"
                            defaultValue={form.defaults.general.testId}
                            preload={true}
                            endpoint={'TestSessions'}
                            wrapClass={"manual-input"}
                            where={{ athleteId: data.athleteId }}
                        />

                    </Col>

                    <Col col={{ xs: 4, sm: 3 }} align='start' justify='start'>

                        <Space height={30} />
                        {form.values.general.testId &&
                            <Button
                                label={`Fill Bio Data`}
                                onClick={handleFill.bind(null, form.values.general.testId)}
                                type={"faded"}
                                size='small'
                            />
                        }

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Seperator
                            heading="Training Information"
                            top={"small"}
                            bottom={"small"}
                        />
                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldTextArea
                            form={form}
                            name="general.performanceGoals"
                            label="General Performance Goals"
                            defaultValue={form.defaults.general.performanceGoals}
                            wrapClass={"manual-input"}
                            rows={4}
                        />

                        <FieldSelect
                            form={form}
                            name="general.preferredEvent"
                            label="Preferred Events to Participate in"
                            defaultValue={form.defaults.general.preferredEvent}
                            options={[
                                { value: "Sprint Triathlon", label: "Sprint Triathlon" },
                                { value: "Olympic Distance Tri", label: "Olympic Distance Tri" },
                                { value: "70.3 Tri", label: "70.3 Tri" },
                                { value: "IRONMAN Tri", label: "IRONMAN Tri" },
                                { value: "Duathlon - Short Course", label: "Duathlon - Short Course" },
                                { value: "Duathlon - Long Course", label: "Duathlon - Long Course" },
                                { value: "Middle Distance Running (<3000m)", label: "Middle Distance Running (<3000m)" },
                                { value: "5km Runs", label: "5km Runs" },
                                { value: "10km Runs", label: "10km Runs" },
                                { value: "Half Marathons", label: "Half Marathons" },
                                { value: "Full Marathon", label: "Full Marathon" },
                                { value: "Ultramarathon", label: "Ultramarathon" },
                                { value: "Cycling Road Races", label: "Cycling Road Races" },
                                { value: "Cycling Criteriums", label: "Cycling Criteriums" },
                                { value: "Cycling Track", label: "Cycling Track" },
                                { value: "MTB Events", label: "MTB Events" },
                                { value: "Swim Competitions - Pool", label: "Swim Competitions - Pool" },
                                { value: "Swim Open Water - Short (<2000m)", label: "Swim Open Water - Short (<2000m)" },
                                { value: "Swim Open Water - Long (>2000m)", label: "Swim Open Water - Long (>2000m)" },
                                { value: "Other..", label: "Other.." },
                                { value: "None/Don't Compete", label: "None/Don't Compete" }
                            ]}
                            isMulti
                            wrapClass={"manual-input"}
                        />

                        <FieldSelect
                            form={form}
                            label='Nutrition Diet'
                            name="general.glycogenDiet"
                            defaultValue={form.defaults.general.glycogenDiet}
                            options={[
                                { value: "High Carb", label: "High Carb" },
                                { value: "Mixed Carb-Protein", label: "Mixed Carb-Protein" },
                                { value: "Low Carb", label: "Low Carb" },
                            ]}
                            wrapClass={"manual-input"}
                        />

                        <InputTable
                            columns={[
                                {
                                    key: "bike",
                                    label: "Bike",
                                },
                                {
                                    key: "run",
                                    label: "Run",
                                },
                                {
                                    key: "training",
                                    label: "Preferred Training Module",
                                },
                            ]}
                        >

                            {trainingMode.map((training, index) => (

                                <InputTableRow key={index}>

                                    <FieldHidden
                                        form={form}
                                        name={`general.trainingMode[${index}].label`}
                                        value={training.desc}
                                    />

                                    <InputTableCell >

                                        <FieldRadio
                                            form={form}
                                            name={`general.trainingMode[${index}].bike`}
                                            defaultValue={get(form.defaults, `general.trainingMode[${index}].bike`)}
                                            options={[
                                                { label: "", value: "yes" }
                                            ]}
                                        />

                                    </InputTableCell>

                                    <InputTableCell >

                                        <FieldRadio
                                            form={form}
                                            name={`general.trainingMode[${index}].run`}
                                            defaultValue={get(form.defaults, `general.trainingMode[${index}].run`)}
                                            options={[
                                                { label: "", value: "yes" }
                                            ]}
                                        />

                                    </InputTableCell>

                                    <DataTableCell>
                                        {training.desc}
                                    </DataTableCell>

                                </InputTableRow>

                            ))}

                        </InputTable>

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldTextArea
                            form={form}
                            name="general.trainingAvailability"
                            label="Training Availability (Optimal Days and Times of the Week)"
                            defaultValue={form.defaults.general.trainingAvailability}
                            wrapClass={"manual-input"}
                            rows={4}
                        />

                        <FieldSelect
                            form={form}
                            name="general.trainingHour"
                            label="Current Training Hours"
                            defaultValue={form.defaults.general.trainingHour}
                            options={[
                                { value: "< 5 hours /week", label: "< 5 hours /week" },
                                { value: "5-10 hours/week", label: "5-10 hours/week" },
                                { value: "10-15 hours /week", label: "10-15 hours /week" },
                                { value: "15-20 hours /week", label: "15-20 hours /week" },
                                { value: ">20 hours /week", label: ">20 hours /week" },
                                { value: "None Currently", label: "None Currently" }
                            ]}
                            wrapClass={"manual-input"}
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, sm: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    name="general.equipment"
                                    label="Equipment"
                                    list
                                    defaultValue={form.defaults.general.equipment}
                                    options={[
                                        { value: "Road Bike", label: "Road Bike" },
                                        { value: "TT Bike", label: "TT Bike " },
                                        { value: "MTB", label: "MTB" },
                                        { value: "Smart Trainer", label: "Smart Trainer" },
                                        { value: "Treadmill", label: "Treadmill" },
                                        { value: "Powermeter", label: "Powermeter" },
                                        { value: "HR Monitor", label: "HR Monitor" },
                                        { value: "Kickboard", label: "Kickboard" },
                                        { value: "Pull Buoy", label: "Pull Buoy" },
                                        { value: "Paddles", label: "Paddles" },
                                    ]}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    name="general.facilities"
                                    label="Facilities"
                                    defaultValue={form.defaults.general.facilities}
                                    options={[
                                        { value: "Pool - 50m", label: "Pool - 50m" },
                                        { value: "Pool - 25m", label: "Pool - 25m" },
                                        { value: "Pool - other", label: "Pool - other" },
                                        { value: "Open Water", label: "Open Water" },
                                        { value: "Running Track", label: "Running Track" },
                                        { value: "Running Trails", label: "Running Trails" },
                                        { value: "Gym", label: "Gym" },
                                    ]}
                                    wrapClass={"manual-input"}
                                    list
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldCheckbox
                                    form={form}
                                    name="general.program"
                                    label="Program"
                                    defaultValue={form.defaults.general.program}
                                    options={[
                                        { value: "Training Peaks", label: "Training Peaks" },
                                        { value: "Garmin", label: "Garmin" },
                                        { value: "TrainerLoad", label: "TrainerLoad" },
                                        { value: "Zwift", label: "Zwift" },
                                        { value: "Wahoo", label: "Wahoo" },
                                        { value: "Perfpro", label: "Perfpro" },
                                    ]}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    name="general.otherTraining"
                                    label="Other"
                                    defaultValue={form.defaults.general.otherTraining}
                                    wrapClass={"manual-input"}
                                    rows={2}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row >

                {editable &&
                    <>
                        <Line />

                        <ButtonRow>
                            <Button
                                label={"Save"}
                                loading={update.loading}
                            />
                        </ButtonRow>
                    </>
                }

            </Form >

        </>

    ), [form.hash]);

}

export default CoachingGeneralForm;