import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldAutocomplete, FieldHidden, FieldNumber, FieldRelationship, FieldSwitch, FieldText, InputTable, InputTableCell, InputTableRow, Line, Seperator, Space, useUpdateEffect } from '@dex/bubl-dash';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { get, loop } from '@dex/bubl-helpers';

const FieldRatingScalesCreateEditForm: React.FC<any> = ( props: any ) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const columns = [
        {
            key: "label",
            label: "Rating",
            style: { width: 120 }
        },
        {
            key: "custom",
            label: "Custom Label",
            style: { width: 200 }
        },
        {
            key: "hidden",
            label: "Hide Label",
            style: { width: 120 }
        },
        {
            key: "min",
            label: "Min",
            alignMiddle: true,
            style: { width: 80 },
            number: true,
        },
        {
            key: "-",
            label: "",
            alignMiddle: true,
            style: { width: 20 },
            number: true,
        },
        {
            key: "max",
            label: "Max",
            alignMiddle: true,
            style: { width: 80 },
            number: true,
        },
        {
            key: "space",
            label: "",
        }
    ];

    useUpdateEffect( () => {

        let labels = [ "Excellent", "Very Good", "Good", "Average", "Low", "Very Low", "Poor" ];

        if ( form.values.bestScore === "highest" ) labels = labels.reverse();

        const steps = form.values.steps;

        loop( steps, ( step, index ) => {

            step.label = labels[ index ];

        } );

        form.handleChange( { name: "steps", value: steps, } );

    }, [ form.values.bestScore ] );

    const handleMaxChange = useCallback( ( index, value ) => {

        form.handleChange( { name: `steps[${ index + 1 }].min`, value: value, reset: true, silent: true } );

    }, [] );

    return useMemo( () => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Panel
                heading={"Field Rating Scale Details"}
            >
                <Row gutter={8} edge={true} className='test'>

                    <Col col={{ xs: 24, sm: 8 }}>

                        <FieldAutocomplete
                            form={form}
                            name="templateCategory"
                            label="Test Category"
                            defaultValue={form.defaults.templateCategory}
                            format={[ 'category' ]}
                            order={"name asc"}
                            group={"FieldTestCategory"}
                            preload
                            transform={"capitalize"}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 8 }}>

                        <FieldRelationship
                            form={form}
                            preload={true}
                            endpoint="fieldTemplates"
                            name="templateId"
                            label="Test"
                            required={true}
                            defaultValue={form.defaults.templateId}
                            format={[ 'name', 'units', 'category', 'subcategory' ]}
                            order={"name asc"}
                            where={{ category: form.values.templateCategory }}
                            key={form.values.templateCategory}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 8 }}>

                        <FieldAutocomplete
                            form={form}
                            label={"Sport"}
                            name={`sport`}
                            defaultValue={form.defaults.sport}
                            group={"mainSport"}
                            preload={true}
                            options={[
                                { value: "Cycling", label: "Cycling" },
                                { value: "Triathlon", label: "Triathlon" },
                                { value: "Running", label: "Running" },
                                { value: "Swimming", label: "Swimming" },
                                { value: "Football", label: "Football" },
                                { value: "Rugby", label: "Rugby" },
                                { value: "Basketball", label: "Basketball" },
                                { value: "Cricket", label: "Cricket" },
                                { value: "Gymnastics", label: "Gymnastics" },
                            ]}
                            sort={false}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldText
                            form={form}
                            name="title"
                            label="Title"
                            defaultValue={form.defaults.title}
                            required={true}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        {/* <FieldRadio
                            button
                            form={form}
                            name="level"
                            label="Level"
                            defaultValue={form.defaults.level}
                            required={true}
                            options={[
                                { value: "recreational", label: "Recreational" },
                                { value: "competitive", label: "Competitive" },
                                { value: "pro", label: "Pro" },
                            ]}
                        /> */}

                        <FieldAutocomplete
                            form={form}
                            name="level"
                            label="Level"
                            defaultValue={form.defaults.level}
                            required={true}
                            group='rating.level'
                            preload={true}
                        />
                    </Col>

                    <Col col={{ xs: 24, sm: 24 }}>

                        <FieldAutocomplete
                            form={form}
                            name="source"
                            label="Source"
                            defaultValue={form.defaults.source}
                            required={true}
                            group='rating.source'
                            preload={true}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldRadio
                            button
                            form={form}
                            name="bestScore"
                            label="Best Score is"
                            defaultValue={form.defaults.bestScore}
                            options={[
                                { value: "highest", label: "Highest" },
                                { value: "lowest", label: "Lowest" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    <Col col={{ xs: 24, sm: 8 }}>

                        <FieldNumber
                            form={form}
                            name={`mean`}
                            label='Mean'
                            defaultValue={form.defaults.mean}
                            required
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 8 }}>

                        <FieldNumber
                            form={form}
                            name={`std`}
                            label='Standard Deviation'
                            defaultValue={form.defaults.std}
                            required
                        />

                    </Col>

                </Row>

                <Space />

                <InputTable
                    columns={columns}
                >

                    {[ 0, 1, 2, 3, 4, 5, 6 ].map( ( index ) => {

                        const row = get( form.values, `steps[${ index }]` ) || {};

                        return (

                            <InputTableRow key={index}>

                                {columns.map( ( column ) => (

                                    <InputTableCell {...column}>

                                        {column.key === "label" &&
                                            <strong>
                                                {row.label}
                                                <FieldHidden
                                                    key={row.label}
                                                    form={form}
                                                    name={`steps[${ index }].label`}
                                                    value={row.label}
                                                />
                                            </strong>
                                        }

                                        {column.key === "custom" &&
                                            <FieldText
                                                key={row.customLabel}
                                                form={form}
                                                name={`steps[${ index }].customLabel`}
                                                defaultValue={row.customLabel}
                                                wrapClass={"w-240"}
                                                placeholder={row.label}
                                            />
                                        }

                                        {column.key === "hidden" &&
                                            <FieldSwitch
                                                form={form}
                                                name={`steps[${ index }].hidden`}
                                                defaultValue={row.hidden}
                                            />
                                        }

                                        {column.key === "min" &&
                                            <FieldNumber
                                                form={form}
                                                name={`steps[${ index }].min`}
                                                defaultValue={row.min}
                                                key={index > 0 ? form.hash : undefined}
                                                disabled={index > 0}
                                                hidden={index > 0}
                                            />
                                        }

                                        {column.key === "-" &&
                                            <>-</>
                                        }

                                        {column.key === "max" &&
                                            <FieldNumber
                                                form={form}
                                                name={`steps[${ index }].max`}
                                                defaultValue={row.max}
                                                onChange={handleMaxChange.bind( null, index )}
                                            />
                                        }

                                    </InputTableCell>

                                ) )}

                            </InputTableRow>

                        )
                    } )}

                </InputTable>

                <Space />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Panel>

        </Form>

    ), [ props, form.hash ] );

}

export default FieldRatingScalesCreateEditForm;
