import SinglePage from '@dex/bubl-dash/src/lib/pages/SinglePage';
import AthletesSingleView from './Components/AthletesSingleView';
import AthletesSingleEdit from './Components/AthletesSingleEdit';
import AthletesSingleTracking from './Components/AthletesSingleTracking';
import AthletesSingleHistory from './Components/AthletesSingleHistory';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "View",
        zone: settings.zone,
        path: settings.key + "/:id/view",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getButtons: settings.singleButtons,
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: AthletesSingleView,
        },
    });

    app.addRoute({
        name: settings.key + "Tracking",
        zone: settings.zone,
        path: settings.key + "/:id/tracking",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getButtons: settings.singleButtons,
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: AthletesSingleTracking,
        },
    });

    app.addRoute({
        name: settings.key + "History",
        zone: settings.zone,
        path: settings.key + "/:id/history",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getButtons: settings.singleButtons,
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: AthletesSingleHistory,
        },
    });

    app.addRoute({
        name: settings.key + "Edit",
        zone: settings.zone,
        path: settings.key + "/:id/edit",
        caps: settings.updateCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                // getButtons: settings.singleButtons,
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: AthletesSingleEdit,
        },
    });

}