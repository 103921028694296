import { clone, fixedNum, get, loop, parseNum } from "@dex/bubl-helpers";
import { initialGlycogenTable } from "./sweatTest";
import { useEffect, useMemo } from "react";
import { getClockTime, getDecimalTime, getDuration, getPaceFromKph, getSwimPace } from "./sharedTests";
import { labAnalysisRegresionCharts } from "./chartRegression";

export const getGlycogenImage = (glycogen) => {
    const thresholds = [0, 6, 13, 19, 25, 31, 38, 44, 50, 56, 63, 69, 75, 81, 88, 94, 100];
    const images = [
        [25],
        [25],
        [50],
        [75],
        [100],
        [100, 25],
        [100, 50],
        [100, 75],
        [100, 100],
        [100, 100, 25],
        [100, 100, 50],
        [100, 100, 75],
        [100, 100, 100],
        [100, 100, 100, 25],
        [100, 100, 100, 50],
        [100, 100, 100, 75],
        [100, 100, 100, 100]
    ];

    let closestNumber = 0;
    let smallestDifference = Math.abs(glycogen - thresholds[0]);

    for (let i = 1; i < thresholds.length; i++) {
        const difference = Math.abs(glycogen - thresholds[i]);
        if (difference < smallestDifference) {
            closestNumber = i;
            smallestDifference = difference;
        }
    }

    return images[closestNumber];
};

export const getGlycogenTestData = (session, test, recentTest) => {

    const unit = get(test, `displayUnit`, "kcal");
    const glycogenWholeBody = unit == "g/hr" ? get(session, "bio.glycogenWholeBody") : get(session, "bio.glycogenKcalWholeBody");
    const glycogenUpperBody = unit == "g/hr" ? get(session, "bio.glycogenUpperBody") : get(session, "bio.glycogenKcalUpperBody");
    const glycogenLowerBody = unit == "g/hr" ? get(session, "bio.glycogenLowerBody") : get(session, "bio.glycogenKcalLowerBody");

    const glycogenPart = get(test, `glycogenPart`, 'Whole');
    const glycogenAmount = (glycogenPart === "Lower") ? glycogenLowerBody : (glycogenPart === "Upper") ? glycogenUpperBody : glycogenWholeBody;

    const calculateAmount = fixedNum(glycogenAmount * (get(test, `percentStart`, 0) / 100), 2);

    const activity = get(test, `activity`);
    const activitySuffix = activity == "Cycling" ? "W" : "kph";
    const activityEstimatedSuffix = activity == "Cycling" ? "W/kg" : activity == "Running" ? "kph/km" : "min/100m";

    const power = get(test, `power`, 0);
    const time = getClockTime(1 / power, "hh:mm");

    const getEstimatedPace = getPaceFromKph(power);
    const getEstimatedSwimPace = getSwimPace("1", time);
    const getEstimatedPower = fixedNum(power / get(session, "bio.weight"), 2);

    const getEstimated = (activity) => {
        if (activity == "Cycling") {
            return getEstimatedPower
        } else if (activity == "Swimming") {
            return getEstimatedSwimPace
        } else {
            return getEstimatedPace
        }
    };

    const calculateRate = (value, percent) => {

        if (!value || !percent) return 0;

        return fixedNum(value * (percent / 100), 0)

    };

    const { carb, carbKcal, hr, mergedAvg, vo2, vo2hr, x } = useMemo(() => {

        return labAnalysisRegresionCharts(recentTest?.avgData, recentTest?.activity);

    }, [recentTest]);

    const carbUnit = unit == "g/hr" ? carb : carbKcal;

    const computeCarbBurn = fixedNum(carbUnit?.regression?.predict(parseNum(get(test, `power`))), 1) || 0;

    const usedBurnRateKey = get(test, `burnRateUsed`, "manual");
    const usedBurnRate = get(test, `${usedBurnRateKey}BurnRate`, 0);

    return {
        unit, calculateRate, getEstimated,
        glycogenWholeBody, glycogenUpperBody, glycogenLowerBody,
        glycogenPart, glycogenAmount, calculateAmount,
        activity, activitySuffix, activityEstimatedSuffix,
        getEstimatedPace, getEstimatedSwimPace, getEstimatedPower,
        carbUnit, computeCarbBurn, usedBurnRateKey, usedBurnRate
    }

};

export const getFuelingRateData = (test) => {

    const fuelingPercentMin = parseFloat(get(test, `fueling.percentMin`));
    const fuelingPercentMax = parseFloat(get(test, `fueling.percentMax`));
    const fuelingRateMin = parseFloat(get(test, `fueling.relativeMin`));
    const fuelingRateMax = parseFloat(get(test, `fueling.relativeMax`));
    const decimalTargetTime = getDecimalTime(get(test, `targetTime`));
    const recommendedRate = parseFloat(get(test, `bonk.recommendedRate`));
    const bonkMax = get(test, `chart.tableMax`);
    const bonkMin = get(test, `chart.tableMin`);
    const xAxis = (get(test, `xAxis`));

    const computeCarbNeeded = fixedNum(recommendedRate * decimalTargetTime, 0);

    const bonkMinHour = getBonkTime(bonkMin, xAxis).totalHours;
    const bonkMaxHour = getBonkTime(bonkMax, xAxis).totalHours;

    return {
        fuelingPercentMin, fuelingPercentMax,
        fuelingRateMin, fuelingRateMax,
        decimalTargetTime, recommendedRate,
        bonkMax, bonkMin, computeCarbNeeded,
        bonkMinHour, bonkMaxHour
    }
};

export function getBonkTime(arr, limitHour) {

    let bonk: any = "-";

    loop(arr, (item, index) => {

        if (limitHour <= item.hour) {
            return
        } else {

            if (item.totalHours == null) return;
            bonk = item
        }

    })

    return bonk;
};

export const checkBonkGreaterThanTarget = (targetTime, bonkHour) => {

    let isBonkHourGreater = "Yes";

    if (targetTime >= bonkHour) {

        isBonkHourGreater = "No";
    }

    return isBonkHourGreater;

};

export const getGlycogenChartData = (test) => {

    const burnRateUsed = get(test, `burnRateUsed`, "estimated");
    const burnRate = parseFloat(get(test, `${burnRateUsed}BurnRate`));
    const initialCarb = parseFloat(get(test, `amountStart`));
    const fuelingRateMin = parseFloat(get(test, `fueling.relativeMin`));
    const fuelingRateMax = parseFloat(get(test, `fueling.relativeMax`));
    const activity = get(test, `activity`);
    const targetTime = get(test, `targetTime`);
    const brainUsage = parseFloat(get(test, `brainUsage`));

    const xAxis = parseNum(get(test, `xAxis`, 10));

    const calculateGramTable = (burn, brain, initialCarb, fuelingRate) => {

        const table: any = [];

        let carbLeft = initialCarb;

        loop(initialGlycogenTable, (item, index) => {

            item.carbStart = carbLeft <= 0 ? null : carbLeft;

            item.burnRate = fixedNum((item.contribution / 100) * burn, 0);

            item.carbLeft = Math.max(0, fixedNum(item.carbStart - item.burnRate, 0));

            if (item.carbLeft > 0) item.carbLeft = item.carbLeft + fuelingRate - brain;

            item.percentCarbLeft = fixedNum((item.carbLeft / initialCarb) * 100, 0);

            carbLeft = item.carbLeft;

            const lastRow = table[index - 1];

            item.hoursTillEmpty = index ? fixedNum(lastRow.carbLeft / lastRow.burnRate, 2) : null;

            item.totalHours = (index && item.carbLeft === 0 && lastRow.carbLeft !== 0) ? lastRow.hour + item.hoursTillEmpty : null;

            table.push(clone(item));

        });

        return table;

    };

    const { chartData, tableMax, tableMin } = useMemo(() => {

        const tableMin = calculateGramTable(burnRate, brainUsage, initialCarb, fuelingRateMin);
        const tableMax = calculateGramTable(burnRate, brainUsage, initialCarb, fuelingRateMax);

        const chartData: any = [];

        let hour = 0;

        while (hour <= xAxis) {

            chartData.push({
                hour: hour,
                carbMin: tableMin[hour]?.carbStart,
                carbMax: tableMax[hour]?.carbStart,
            })

            hour++;

        }

        return { chartData, tableMax, tableMin };

    }, [burnRate, brainUsage, initialCarb, fuelingRateMin, fuelingRateMax, xAxis]);

    return {
        chartData, tableMax, tableMin,
        burnRateUsed, fuelingRateMin, fuelingRateMax,
        activity, targetTime, initialCarb
    };

};

export const glycogenEventList = [
    { label: "Cycling", value: "cycling" },
    { label: "Triathlon", value: "triathlon" },
    { label: "Triathlon-IM", value: "triathlon-IM" },
    { label: "Triathlon-70.3", value: "triathlon-70.3" },
    { label: "Triathlon-Olympic", value: "triathlon-olympic" },
    { label: "Triathlon-Sprint", value: "triathlon-sprint" },
    { label: "Running", value: "running" },
    { label: "Running-Marathon", value: "running-marathon" },
    { label: "Running-Half Marathon", value: "running-half marathon" },
    { label: "Running-10km", value: "running-10km" },
    { label: "Running-5km", value: "running-5km" },
    { label: "Running-Ultra Endurance", value: "running-ultra endurance" },
    { label: "Swimming", value: "swimming" },
    { label: "Duathlon", value: "duathlon" },
    { label: "Duathlon-Powerman", value: "duathlon-powerman" },
];

export const getTableRow = (event) => {

    if (!event) return;

    const eventsData = {
        "all": [
            { type: "Swim" },
            { type: "Bike" },
            { type: "Run" }
        ],
        "cycling": [
            { type: "Bike" }
        ],
        "triathlon": [
            { type: "Swim" },
            { type: "Bike" },
            { type: "Run" }
        ],
        "triathlon-IM": [
            { type: "Swim", distance: 3.8 },
            { type: "Bike", distance: 180 },
            { type: "Run", distance: 42 }
        ],
        "triathlon-70.3": [
            { type: "Swim", distance: 1.9 },
            { type: "Bike", distance: 90 },
            { type: "Run", distance: 21 }
        ],
        "triathlon-olympic": [
            { type: "Swim", distance: 1.5 },
            { type: "Bike", distance: 40 },
            { type: "Run", distance: 10 }
        ],
        "triathlon-sprint": [
            { type: "Swim" },
            { type: "Bike" },
            { type: "Run" }
        ],
        "running": [
            { type: "Run" }
        ],
        "running-marathon": [
            { type: "Run", distance: 42 }
        ],
        "running-half marathon": [
            { type: "Run", distance: 21 }
        ],
        "running-10km": [
            { type: "Run", distance: 10 }
        ],
        "running-5km": [
            { type: "Run", distance: 5 }
        ],
        "running-ultra endurance": [
            { type: "Run" }
        ],
        "swimming": [
            { type: "Swim" }
        ],
        "duathlon": [
            { type: "Run 1" },
            { type: "Bike" },
            { type: "Run 2" }
        ],
        "duathlon-powerman": [
            { type: "Run 1", distance: 10 },
            { type: "Bike", distance: 60 },
            { type: "Run 2", distance: 10 }
        ],
    };

    return eventsData[event] || [];
};