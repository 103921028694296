import { slug } from '@dex/bubl-helpers';
import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartBeOverTime: React.FC<ChartBeOverTimeProps> = (props) => {

    let { data, activity, date, index, intensity } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity(activity, intensity);

    const historyData = Axis.prepareHistoryData(data, slug(date), slug(index));

    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
    };

    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 0,
        key: "calc_tvfev1_" + slug(date),
        label: date,
        axisLabel: "TV/FEv1 - %",
        props: {
            domain: [0, 100],
            connectNulls: true,
        }
    });

    if (date !== index) {

        Axis.y.push({
            axis: false,
            line: true,
            pos: "left",
            index: 1,
            key: "calc_tvfev1_" + slug(index),
            label: index,
            axisLabel: "Rf/FEv1 - %",
            props: {
                strokeDasharray: "6 4",
                connectNulls: true,
            }
        });

    }

    return (

        <Chart>

            <LineChart
                {...ChartProps.LineChart()}
                data={historyData}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartBeOverTimeProps {
    [key: string]: any,
}

export default ChartBeOverTime;