import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import GaitsEditTabsPanel from './GaitsEditTabsPanel';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import { Button, FieldAutocomplete, FieldCheckbox, FieldSelect, Space, useMountEffect } from '@dex/bubl-dash';
import { get, loop, set, startCase, titleCase } from '@dex/bubl-helpers';
import { getIssueType } from '../../../functions/sharedGait';
import RatingScalesApi from '../../RatingScales/RatingScalesApi';
import { ratingLabel, stepsMinMax } from '../../../elements/RatingScale/RatingScaleElements';

const GaitsEditTabsSummary: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit, settings } = props;

    const useDefaultText = useCallback((defaultName, name: string) => {

        let text = get(settings.gait, defaultName);

        const values = form.values;

        set(values, name, text);

        form.reset(values);

    }, [props, form.hash]);

    const gender = data.athlete.gender;
    const [scales, setScales]: any = useState([]);
    const [key, setKey] = useState(0);

    const rightKeys = [
        { key: `rear.toeOffArea.rightRating`, type: "toeOffArea" },
        { key: `rear.heelStrikeOrientation.rightRating`, type: "heelStrikeOrientation" },
        { key: `rear.footPronationRate.rightRating`, type: "footPronationRate" },
        { key: `rear.footDirection.rightRating`, type: "footDirection" },
        { key: `rear.legAngle.rightRating`, type: "legAngle" },
        { key: `rear.kneeShifting.rightRating`, type: "kneeShifting" },
        { key: `rear.pelvicDrop.rightRating`, type: "pelvicDrop" },
        { key: `rear.torsoRotation.rightRating`, type: "torsoRotation" },
        { key: `rear.elbowPosition.rightRating`, type: "elbowPosition" },
        { key: `rear.heelWhip.rightRating`, type: "heelWhip" },
        { key: `rear.legCross.rightRating`, type: "legCross" },
        { key: `side.footInclination.rightRating`, type: "footInclination" },
        { key: `side.legInclination.rightRating`, type: "legInclination" },
        { key: `side.kneeAngle.rightRating`, type: "kneeAngle" },
        { key: `side.armSwingStyle.rightRating`, type: "armSwingStyle" },
    ];

    const leftKeys = [
        { key: `rear.toeOffArea.leftRating`, type: "toeOffArea" },
        { key: `rear.heelStrikeOrientation.leftRating`, type: "heelStrikeOrientation" },
        { key: `rear.footPronationRate.leftRating`, type: "footPronationRate" },
        { key: `rear.footDirection.leftRating`, type: "footDirection" },
        { key: `rear.legAngle.leftRating`, type: "legAngle" },
        { key: `rear.kneeShifting.leftRating`, type: "kneeShifting" },
        { key: `rear.pelvicDrop.leftRating`, type: "pelvicDrop" },
        { key: `rear.torsoRotation.leftRating`, type: "torsoRotation" },
        { key: `rear.elbowPosition.leftRating`, type: "elbowPosition" },
        { key: `rear.heelWhip.leftRating`, type: "heelWhip" },
        { key: `rear.legCross.leftRating`, type: "legCross" },
        { key: `side.footInclination.leftRating`, type: "footInclination" },
        { key: `side.legInclination.leftRating`, type: "legInclination" },
        { key: `side.kneeAngle.leftRating`, type: "kneeAngle" },
        { key: `side.armSwingStyle.leftRating`, type: "armSwingStyle" },
    ];

    const keys = [
        { key: `side.pelvicTilt.rating`, type: "pelvicTilt", },
        { key: `side.torsoLean.rating`, type: "torsoLean", },
        { key: `side.footStrikeAudio.rating`, type: "footStrikeAudio" },
    ];

    const correctString = (value) => {

        const splitString = startCase(value);

        const correctedString = splitString.replace(/\s+/g, ' ');

        return titleCase(correctedString)

    };

    const getIssue = (data, gender) => {

        let issues = {
            majorIssues: "",
            mildIssues: ""
        };

        const processKeys = (data, keys, side) => {

            loop(keys, (k, i) => {

                const type = k.type;
                const titlecaseType = correctString(type);
                const value = get(data, k.key);
                const issue = getIssueType(type, value);

                const ratingScale = scales.find(scale => scale.metric === titlecaseType);

                const { steps, min, max }: any = stepsMinMax(ratingScale, "all", gender);

                const rating = ratingLabel(value, steps, min, max);

                if (issue === "major") {
                    issues.majorIssues += `- ${titlecaseType}: ${rating} ${side ? ` (${side})` : ''}\n`;
                }
                if (issue === "mild") {
                    issues.mildIssues += `- ${titlecaseType}: ${rating} ${side ? ` (${side})` : ''}\n`;
                }

            });

        };

        loop(data.runs, (run, index) => {

            const runHeader = `Run Analysis ${index + 1}\n`;

            issues.majorIssues += `${index === 0 ? "" : "\n"}${runHeader}`;
            issues.mildIssues += `${index === 0 ? "" : "\n"}${runHeader}`;

            processKeys(run, rightKeys, "Right");
            processKeys(run, leftKeys, "Left");
            processKeys(run, keys, "");

        });

        return issues;
    };

    const ratingScale = RatingScalesApi.get();

    useEffect(() => {

        ratingScale.run({
            params: {
                where: {
                },
                limit: 100
            },
            onComplete: (response) => {

                setScales(response.data)

            },
        });

    }, []);

    const fillIssue = () => {

        const issue = getIssue(data, gender);

        setKey((key) => key + 1);

        if (issue) {
            form.handleChange({ reset: true, name: "summary.majorIssues", value: issue.majorIssues });
            form.handleChange({ reset: true, name: "summary.minorIssues", value: issue.mildIssues });
        }

    };

    return (

        <GaitsEditTabsPanel
            heading={"Fitter's Summary"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 20, lg: 10 }}>

                    <FieldTextArea
                        form={form}
                        label={"Major Issues"}
                        name={'summary.majorIssues'}
                        required={false}
                        defaultValue={form.defaults.summary.majorIssues}
                        key={key}
                        rows={10}
                        maxLength={2000}
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 20, lg: 10 }}>

                    <FieldTextArea
                        form={form}
                        label={"Minor Issues"}
                        name={'summary.minorIssues'}
                        required={false}
                        defaultValue={form.defaults.summary.minorIssues}
                        key={key}
                        rows={10}
                        maxLength={2000}
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 4 }}>

                    <Space />

                    <Button
                        label={'Fill Issue'}
                        onClick={fillIssue.bind(null)}
                        type={'faded'}
                    />

                </Col>

                <Col col={{ xs: 10 }}>

                    <FieldCheckbox
                        form={form}
                        label={"Recommended Shoe Types"}
                        name={'summary.shoeType'}
                        required={false}
                        defaultValue={form.defaults.summary.shoeType}
                        options={[
                            { value: "Neutral/Balance", label: "Neutral/Balance" },
                            { value: "Stability", label: "Stability (Mild Pronators/Supinators)" },
                            { value: "Motion Control", label: "Motion Control (Overpronators)" },
                            { value: "Cushioning", label: "Cushioning (Heel Strikers, Long-Distance/Heavy Runner)" },
                            { value: "Light/Carbon Support", label: "Light/Carbon Support (Racing, Forefoot Runners)" },
                            { value: "Rigid/Supportive", label: "Rigid/Strong Midsole (High-Arc, Heavy Supinators)" },
                            { value: "Trail", label: "Trail" },
                        ]}
                        list
                    />

                </Col>

                <Col col={{ xs: 10 }}>

                    <FieldAutocomplete
                        form={form}
                        label={"Suggested Shoe Brands"}
                        name={'summary.shoeBrand'}
                        defaultValue={form.defaults.summary.shoeBrand}
                        wrapClass={"manual-input"}
                        isMulti
                        group='shoeBrand'
                        preload
                        options={[]}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Gait Summary"}
                        name={'summary.comments'}
                        required={false}
                        defaultValue={form.defaults.summary.comments}
                        rows={20}
                        maxLength={10000}
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 8 }}>

                    <Space />

                    <Row edge={true}>

                        <Button
                            label={'Use Default Text'}
                            onClick={useDefaultText.bind(null, `summary`, "summary.comments")}
                            type={'faded'}
                            confirm={form.values.summary.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' } : undefined}
                        />

                    </Row>

                </Col>

            </Row>

        </GaitsEditTabsPanel>
    )

}

export default GaitsEditTabsSummary;
