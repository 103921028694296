import SinglePage from '@dex/bubl-dash/src/lib/pages/SinglePage';
import FieldSessionsSingleView from './Components/FieldSessionsSingleView';
import FieldSessionsSingleEdit from './Components/FieldSessionsSingleEdit';
import FieldSessionReport from './Report/FieldSessionReport';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "View",
        zone: settings.zone,
        path: settings.key + "/:id/view",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: FieldSessionsSingleView,
        },
    });

    app.addRoute({
        name: settings.key + "Edit",
        zone: settings.zone,
        path: settings.key + "/:id/edit/:tab",
        caps: settings.updateCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: FieldSessionsSingleEdit,
        },
    });

    app.addRoute({
        name: settings.key + "Report",
        zone: settings.zone,
        path: "field/report/:id/:view",
        caps: settings.updateCaps,
        component: SinglePage,
        public: true,
        ribbon: false,
        sidebar: false,
        componentProps: {
            getFetch: settings.reportFetch,
            getFetchParams: settings.reportFetchParams,
            header: null,
            page: FieldSessionReport,
        },
    });

    app.addRoute({
        name: settings.key + "ReportAthlete",
        zone: settings.zone,
        path: "field/report/:id/:view/:athleteId",
        caps: settings.updateCaps,
        component: SinglePage,
        public: true,
        ribbon: false,
        sidebar: false,
        componentProps: {
            getFetch: settings.reportFetch,
            getFetchParams: settings.reportFetchParams,
            header: null,
            page: FieldSessionReport,
        },
    });

}