import React, { useMemo } from 'react';
import { Heading, Line, Space } from '@dex/bubl-dash';
import ShareLink from '../../../elements/ShareLink/ShareLink';
import { base64Encode } from '@dex/bubl-helpers';

const FieldSessionsReportLinks: React.FC<any> = (props: any) => {

    const { data, isReportPage } = props;

    const path = window.location.origin;
    const id = base64Encode(data.id);

    return useMemo(() => (

        <>
            <Heading tag={3} space={false}>Manager Reports</Heading>
            <Space height={16} />

            {!isReportPage &&
                <>
                    <ShareLink
                        path={`${path}/field/report/${id}/manager`}
                        label={"Report Links"}
                    />
                    <Space height={4} />
                </>
            }

            <ShareLink
                path={`${path}/field/report/${id}/team`}
                label={"Team Report"}
            />
            <Space height={4} />

            <ShareLink
                path={`${path}/field/report/${id}/athletes`}
                label={"All Athletes Report"}
            />

            <Line />
            <Heading tag={3} space={false}>Athlete Reports</Heading>
            <Space height={16} />

            {data.athletes.map((athlete, index) => (
                <React.Fragment key={index}>
                    <ShareLink
                        path={`${path}/field/report/${id}/athlete/${athlete.id}`}
                        label={athlete.callName}
                    />
                    <Space height={4} />
                </React.Fragment>
            ))}

        </>
    ), [data]);

}

export default FieldSessionsReportLinks;
