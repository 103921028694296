import React, { useMemo, Suspense, useCallback } from 'react';
import TabBody from '../../../elements/TabBody/TabBody';
import { labTestFormattedData, labTestRollingData, labTestSumData, labTestGetSMO2Drop, labTestGetDuration, labTestFormatRowValues, labTestAverageData } from '../../../functions/labTestData';
import { clone, loop } from '@dex/bubl-helpers';
import LabTestsSprintManual from './LabTestsSprintManual';
import { getClockTime } from '../../../functions/sharedTests';
import LabTestsVo2Equipment from '../Vo2Max/LabTestsVo2Equipment';
import LabTestsSprintHr from './LabTestsSprintHr';
import LabTestsSprintMuscle from './LabTestsSprintMuscle';
import LabTestsSprintLactate from './LabTestsSprintLactate';
import LabTestsSprintPerformance from './LabTestsSprintPerformance';

const LabTestsImport = React.lazy(() => import('../Components/LabTestsImport'));

const LabTestsSprint: React.FC<any> = (props: any) => {

    const { form, data, session, tab, setKey, editable, } = props;

    const type = data.type;

    const key = props._key;

    const handleImport = useCallback((rawData, form, activity) => {

        if (!form.values.import?.rollingPeriod) {
            throw new Error("Please select a rolling average period");
        }

        let formattedData: any = labTestFormattedData(rawData, form.values.import);

        // const preData = clone(rawData[0]);

        const avgRows: any = [];
        const rollingRows: any = [];

        loop(formattedData, (row, index) => {

            if (row._stage) {

                const avg = { ...row };

                avg._is = {};

                avg._rows = index + 1;

                avg.averages = index;
                rawData[index].averages = index;

                avgRows.push(avg);
                rollingRows.push(rawData[index]);

            }

        })

        rawData = rawData.filter(Boolean);

        let avgData: any = avgRows;
        const sumAvgData = labTestSumData(avgData, form.values.import);

        const rollingData = labTestRollingData(rollingRows, form.values.import);
        const sumRollingData = labTestSumData(rollingData, form.values.import);

        const sumRawData = labTestSumData(formattedData, form.values.import);

        //apply decimal places after calculating averages and min/max sums
        formattedData = labTestFormatRowValues(formattedData);

        avgData = labTestFormatRowValues(avgRows);

        const manualData: any = [
            { manualTime: "00:00", },
            { manualTime: "01:00", },
            { manualTime: "03:00", },
            { manualTime: "05:00", },
            { manualTime: "07:00", },
            { manualTime: "10:00", }
        ];

        const smo2Drop: any = labTestGetSMO2Drop(sumAvgData);
        const duration: any = labTestGetDuration(formattedData);

        const mergedManual = manualData.map((item, index) => ({
            ...form.values.manualData[index],
            ...item
        }));

        form.handleChange({ silent: true, reset: true, name: `rawData`, value: formattedData });
        form.handleChange({ silent: true, reset: true, name: `avgData`, value: avgData });
        form.handleChange({ silent: true, reset: true, name: `sumData`, value: sumRollingData });
        form.handleChange({ silent: true, reset: true, name: `sumAvgData`, value: sumAvgData });
        form.handleChange({ silent: true, reset: true, name: `sumRawData`, value: sumRawData });
        form.handleChange({ silent: true, reset: true, name: `rollingData`, value: rollingData });
        form.handleChange({ silent: true, reset: true, name: `duration`, value: duration });
        form.handleChange({ silent: true, reset: true, name: `manualData`, value: mergedManual, reset: true });

        form.handleChange({ silent: true, reset: true, name: `hr.min`, value: sumAvgData.hr?.min });
        form.handleChange({ silent: true, reset: true, name: `hr.avg`, value: sumAvgData.hr?.avg });
        form.handleChange({ silent: true, reset: true, name: `hr.max`, value: sumRawData.hr?.max });

        form.handleChange({ silent: true, reset: true, name: `muscle.a.smo2Min`, value: sumAvgData.smo2_1?.min });
        form.handleChange({ silent: true, reset: true, name: `muscle.a.smo2Avg`, value: sumAvgData.smo2_1?.avg });
        form.handleChange({ silent: true, reset: true, name: `muscle.a.smo2Max`, value: sumAvgData.smo2_1?.max });
        form.handleChange({ silent: true, reset: true, name: `muscle.a.smo2Drop`, value: smo2Drop[1] });

        form.handleChange({ silent: true, reset: true, name: `muscle.a.thbMin`, value: sumAvgData.thb_1?.min });
        form.handleChange({ silent: true, reset: true, name: `muscle.a.thbAvg`, value: sumAvgData.thb_1?.avg });
        form.handleChange({ silent: true, reset: true, name: `muscle.a.thbMax`, value: sumAvgData.thb_1?.max });

        form.handleChange({ silent: true, reset: true, name: `muscle.b.smo2Min`, value: sumAvgData.smo2_2?.min });
        form.handleChange({ silent: true, reset: true, name: `muscle.b.smo2Avg`, value: sumAvgData.smo2_2?.avg });
        form.handleChange({ silent: true, reset: true, name: `muscle.b.smo2Max`, value: sumAvgData.smo2_2?.max });
        form.handleChange({ silent: true, reset: true, name: `muscle.b.smo2Drop`, value: smo2Drop[2] });

        form.handleChange({ silent: true, reset: true, name: `muscle.b.thbMin`, value: sumAvgData.thb_2?.min });
        form.handleChange({ silent: true, reset: true, name: `muscle.b.thbAvg`, value: sumAvgData.thb_2?.avg });
        form.handleChange({ silent: true, reset: true, name: `muscle.b.thbMax`, value: sumAvgData.thb_2?.max });

        // form.handleChange({ silent: true, reset: true, name: `lactate.measures.max`, value: activity === "Running" ? sumAvgData.speed?.max : sumAvgData.power_raw?.max });

        if (setKey) setKey();

    }, []);

    return useMemo(() => (

        <>

            <TabBody
                form={form}
                active={tab === "import"}
                fields={["import", "rawData", "avgData", "sumData"]}
            >

                <Suspense fallback={<></>}>

                    <LabTestsImport
                        form={form}
                        data={data}
                        session={session}
                        tab={tab}
                        setKey={setKey}
                        handleImport={handleImport}
                    />

                </Suspense>

            </TabBody>

            <TabBody
                form={form}
                active={tab === "manual"}
                fields={["manual", "manualData"]}
            >

                <LabTestsSprintManual
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "equipment"}
                fields={["equipment"]}
            >

                <LabTestsVo2Equipment
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "hr"}
                fields={["hr"]}
            >

                <LabTestsSprintHr
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "muscle"}
                fields={["muscle"]}
            >

                <LabTestsSprintMuscle
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "lactate"}
                fields={["lactate"]}
            >

                <LabTestsSprintLactate
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "performance"}
                fields={["performance"]}
            >

                <LabTestsSprintPerformance
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

        </>

    ), [form.hash, data, session, tab, setKey, editable]);

}

export default LabTestsSprint;
