import FieldTemplatesIndex from './FieldTemplatesIndex';
import FieldTemplatesCreate from './FieldTemplatesCreate';
import FieldTemplatesSettings from './FieldTemplatesSettings';
import FieldTemplatesSingle from './FieldTemplatesSingle';

export default function (app) {

    const settings = FieldTemplatesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }
        ],
    });

    FieldTemplatesIndex(app, settings);

    FieldTemplatesCreate(app, settings);

    FieldTemplatesSingle(app, settings);

}