import { formatNum } from '@dex/bubl-helpers';
import React from 'react';

import "./FancyValue.module.scss";

const FancyValue: React.FC<FancyValueProps> = (props) => {

    let { suffix, value } = props;

    if (typeof value === "number") value = formatNum(value);

    return (

        <div styleName='fancy'>

            <span styleName='value'>
                {value}
            </span>

            {suffix &&
                <span styleName='suffix'>
                    {" " + suffix}
                </span>
            }
        </div>

    )

}

interface FancyValueProps {
    [key: string]: any,
}

export default FancyValue;