import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Box, Button, ButtonRow, CardTabs, Modal, Panel, Space } from '@dex/bubl-dash';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';
import EditableContext from '../../../context/EditableContext';
import { date, loop } from '@dex/bubl-helpers';
import CoachingAddNewPlan from '../Plans/CoachingAddNewPlan';
import CoachingPlansSingleEdit from '../../CoachingPlans/Components/CoachingPlansSingleEdit';

const CoachingEditTabsPlans: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    const editable = useContext(EditableContext);

    const app = useApp();

    const [nav] = app.store("nav");

    const [testTab, setTestTab] = app.store("plan-tab", null);

    const handleAddPlan = useCallback((id) => {

        if (id && typeof id === "string") {

            setTestTab(id);
            app.navigate(nav, 'update', {});

        } else if (data.plans && data.plans.length) {

            setTestTab(data.plans[0].id);

        } else {

            setTestTab(null);

        }

    }, [data.id]);

    const tabs = useMemo(() => {

        const tabs: any = [];

        (data.plans || []).map((plan, index) => {

            tabs.push({
                label: `Plan ${index + 1}`,
                desc: `${date(plan.startDate, 'YYYY MMM')} - ${date(plan.endDate, 'YYYY MMM')}`,
                name: plan.id,
            })

        });

        if (editable) {
            tabs.push({
                label: "Add Plan",
                name: "addPlan"
            })
        }

        let hasActiveTab = false;

        loop(tabs, (item) => {

            if (testTab === item.name) hasActiveTab = true;

        })

        if (!hasActiveTab && tabs[0] && tabs[0].name !== "addPlan") setTestTab(tabs[0].name);

        return tabs;

    }, [data.id]);

    const [save, setSave] = useState(false);

    const handleRefresh = () => {
        app.navigate(nav, 'update', {});
    }

    return useMemo(() => (

        <>

            <Box gutter={"auto"}>

                <CoachingEditTabsPlansPanel
                    tab={testTab}
                    save={save}
                    setSave={setSave}
                    handleRefresh={handleRefresh}

                >

                    <CoachingEditTabsPlansInner
                        data={data}
                        tabs={tabs}
                        tab={testTab}
                        setTab={setTestTab}
                        save={save}
                        setSave={setSave}
                        process={process}
                        handleRefresh={handleRefresh}
                    />

                    {testTab === "addPlan" &&

                        <Modal
                            show={true}
                            onClose={handleAddPlan}
                            width={720}
                        >

                            <CoachingAddNewPlan
                                data={data}
                                onComplete={handleAddPlan}
                            />

                        </Modal>

                    }

                </CoachingEditTabsPlansPanel>

            </Box>

        </>

    ), [props, tabs, testTab, save]);

}

const CoachingEditTabsPlansPanel: React.FC<any> = (props: any) => {

    const { tab, save, setSave, handleRefresh } = props;

    return (

        <Row
            gutter={8}
            edge
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Plans"}
                    actions={
                        <>
                            {tab && tab !== "addPlan" &&
                                <ButtonRow>

                                    <Button
                                        label={"Save"}
                                        onClick={setSave.bind(null, true)}
                                        loading={save}
                                    />

                                    <Button
                                        label={"Cancel"}
                                        onClick={handleRefresh}
                                        type='faded'
                                    />

                                </ButtonRow>
                            }
                        </>
                    }
                >

                    {props.children}

                </Panel>

            </Col>

        </Row>



    )

};

const CoachingEditTabsPlansInner: React.FC<any> = (props: any) => {

    const { tab, setTab, tabs, data, save, setSave, process, handleRefresh } = props;

    return (
        <>

            <CardTabs
                activeTab={tab}
                setTab={setTab}
                tabs={tabs}
            />

            <Space />

            {(data.plans || []).map((plan, index) => {

                return (

                    <React.Fragment key={index}>

                        {tab == plan.id &&
                            <CoachingPlansSingleEdit
                                id={plan.id}
                                session={data}
                                save={save}
                                setSave={setSave}
                                editable={process ? true : false}
                                handleRefresh={handleRefresh}
                            />
                        }

                    </React.Fragment>

                )

            })}

        </>

    )

};

export default CoachingEditTabsPlans;
