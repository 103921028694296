import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';

import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldRange from '@dex/bubl-dash/src/lib/components/Fields/FieldRange';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import MobilityFront from '../../../elements/Mobility/MobilityFront';
import MobilityBack from '../../../elements/Mobility/MobilityBack';
import { calculateRatingMobility } from '../../../functions/calculateRating';
import { Button, FieldText } from '@dex/bubl-dash';
import { getMobilityComments } from '../../../functions/sharedBikeFit';
import { get, set } from '@dex/bubl-helpers';

const SessionsEditTabsMobility: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, settings } = props;

    const useDefaultText = useCallback((defaultName, name: string) => {

        let text = get(settings.fit, defaultName);

        const values = form.values;

        set(values, name, text);

        form.reset(values);

    }, [props, form.hash]);

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Mobility"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, lg: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Toe Touch"}
                                name='mobility.toeTouch'
                                required={false}
                                defaultValue={form.defaults.mobility.toeTouch}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <div className='w-240'>

                                <FieldNumber
                                    form={form}
                                    label={"Pelvic Angle"}
                                    name={"mobility.pelvicAngle"}
                                    suffix={"°"}
                                    required={false}
                                    defaultValue={form.defaults.mobility.pelvicAngle}
                                    allowNegative={true}
                                />

                            </div>

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Quads"}
                                name='mobility.quads'
                                required={false}
                                defaultValue={form.defaults.mobility.quads}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        {
                            (form.values.mobility.quads === 'Fair' || form.values.mobility.quads === 'Restricted')

                            && <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Quads Details"}
                                    name='mobility.quadsDetails'
                                    required={false}
                                    defaultValue={form.defaults.mobility.quadsDetails}
                                    options={[
                                        { value: "Left Quad Tight", label: "Left Tight" },
                                        { value: "Right Quad Tight", label: "Right Tight" },
                                    ]}
                                />

                            </Col>

                        }

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Hamstrings"}
                                name='mobility.hamstrings'
                                required={false}
                                defaultValue={form.defaults.mobility.hamstrings}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        {(form.values.mobility.hamstrings === 'Fair' || form.values.mobility.hamstrings === 'Restricted') &&

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Hamstrings Details"}
                                    name='mobility.hamstringsDetails'
                                    required={false}
                                    defaultValue={form.defaults.mobility.hamstringsDetails}
                                    options={[
                                        { value: "Left Hamstring Tight", label: "Left Tight" },
                                        { value: "Right Hamstring Tight", label: "Right Tight" },
                                    ]}
                                />

                            </Col>

                        }

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Hip Flexion (Mobility)"}
                                name='mobility.hipFlexion'
                                required={false}
                                defaultValue={form.defaults.mobility.hipFlexion}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        {(form.values.mobility.hipFlexion === 'Fair' || form.values.mobility.hipFlexion === 'Restricted') &&

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Hip Flexion Details"}
                                    name='mobility.hipFlexionDetails'
                                    required={false}
                                    defaultValue={form.defaults.mobility.hipFlexionDetails}
                                    options={[
                                        { value: "Left Hip Flexion Tight", label: "Left Tight" },
                                        { value: "Right Hip Flexion Tight", label: "Right Tight" },
                                    ]}
                                />

                            </Col>

                        }


                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Hip Flexors (Muscle)"}
                                name='mobility.hipFlexors'
                                required={false}
                                defaultValue={form.defaults.mobility.hipFlexors}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>


                        {(form.values.mobility.hipFlexors === 'Fair' || form.values.mobility.hipFlexors === 'Restricted') &&

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Hip Flexors Details"}
                                    name='mobility.hipFlexorsDetails'
                                    required={false}
                                    defaultValue={form.defaults.mobility.hipFlexorsDetails}
                                    options={[
                                        { value: "Left Hip Flexors Tight", label: "Left Tight" },
                                        { value: "Right Hip Flexors Tight", label: "Right Tight" },
                                    ]}
                                />

                            </Col>

                        }


                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Hip Internal Rotation"}
                                name='mobility.hipInternalRotation'
                                required={false}
                                defaultValue={form.defaults.mobility.hipInternalRotation}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        {
                            (form.values.mobility.hipInternalRotation === 'Fair' || form.values.mobility.hipInternalRotation === 'Restricted')

                            && <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Hip Internal Rotation Details"}
                                    name='mobility.hipInternalRotationDetails'
                                    required={false}
                                    defaultValue={form.defaults.mobility.hipInternalRotationDetails}
                                    options={[
                                        { value: "Left Hip Rotation Tight", label: "Left Tight" },
                                        { value: "Right Hip Rotation Tight", label: "Right Tight" },
                                    ]}
                                />

                            </Col>
                        }

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Trunk Rotation"}
                                name='mobility.trunkRotation'
                                required={false}
                                defaultValue={form.defaults.mobility.trunkRotation}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        {
                            (form.values.mobility.trunkRotation === 'Fair' || form.values.mobility.trunkRotation === 'Restricted')

                            && <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Trunk Rotation Details"}
                                    name='mobility.trunkRotationDetails'
                                    required={false}
                                    defaultValue={form.defaults.mobility.trunkRotationDetails}
                                    options={[
                                        { value: "Left Trunk Rotation Tight", label: "Left Tight" },
                                        { value: "Right Trunk Rotation Tight", label: "Right Tight" },
                                    ]}
                                />

                            </Col>
                        }

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Plantar Flexion"}
                                name='mobility.plantarFlexion'
                                required={false}
                                defaultValue={form.defaults.mobility.plantarFlexion}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        {
                            (form.values.mobility.plantarFlexion === 'Fair' || form.values.mobility.plantarFlexion === 'Restricted')

                            && <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Plantar Flexion Details"}
                                    name='mobility.plantarFlexionDetails'
                                    required={false}
                                    defaultValue={form.defaults.mobility.plantarFlexionDetails}
                                    options={[
                                        { value: "Left Plantar Flexion Tight", label: "Left Tight" },
                                        { value: "Right Plantar Flexion Tight", label: "Right Tight" },
                                    ]}
                                />

                            </Col>

                        }

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Dorsi Flexion"}
                                name='mobility.dorsiFlexion'
                                required={false}
                                defaultValue={form.defaults.mobility.dorsiFlexion}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        {
                            (form.values.mobility.dorsiFlexion === 'Fair' || form.values.mobility.dorsiFlexion === 'Restricted')

                            && <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Dorsi Flexion Details"}
                                    name='mobility.dorsiFlexionDetails'
                                    required={false}
                                    defaultValue={form.defaults.mobility.dorsiFlexionDetails}
                                    options={[
                                        { value: "Left Dorsi Flexion Tight", label: "Left Tight" },
                                        { value: "Right Dorsi Flexion Tight", label: "Right Tight" },
                                    ]}
                                />

                            </Col>

                        }

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Shoulders"}
                                name='mobility.shoulders'
                                required={false}
                                defaultValue={form.defaults.mobility.shoulders}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        {
                            (form.values.mobility.shoulders === 'Fair' || form.values.mobility.shoulders === 'Restricted')

                            && <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Shoulders Details"}
                                    name='mobility.shouldersDetails'
                                    required={false} shouldersDetails
                                    defaultValue={form.defaults.mobility.shouldersDetails}
                                    options={[
                                        { value: "Left Shoulder Tight", label: "Left Tight" },
                                        { value: "Right Shoulder Tight", label: "Right Tight" },
                                    ]}
                                />

                            </Col>

                        }

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"IT Band"}
                                name='mobility.itBand'
                                required={false}
                                defaultValue={form.defaults.mobility.itBand}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>


                        {
                            (form.values.mobility.itBand === 'Fair' || form.values.mobility.itBand === 'Restricted')

                            && <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"IT Band Details"}
                                    name='mobility.itBandDetails'
                                    required={false} itBandDetails
                                    defaultValue={form.defaults.mobility.itBandDetails}
                                    options={[
                                        { value: "Left IT Band Tight", label: "Left Tight" },
                                        { value: "Right IT Band Tight", label: "Right Tight" },
                                    ]}
                                />

                            </Col>

                        }


                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Upper-Mid Back"}
                                name='mobility.upperMidBack'
                                required={false}
                                defaultValue={form.defaults.mobility.upperMidBack}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        {
                            (form.values.mobility.upperMidBack === 'Fair' || form.values.mobility.upperMidBack === 'Restricted')

                            && <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Upper-Mid Back Details"}
                                    name='mobility.upperMidBackDetails'
                                    required={false} upperMidBackDetails
                                    defaultValue={form.defaults.mobility.upperMidBackDetails}
                                    options={[
                                        { value: "Left UpperMid Back Tight", label: "Left Tight" },
                                        { value: "Right UpperMid Back Tight", label: "Right Tight" },
                                    ]}
                                />

                            </Col>

                        }

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Lower Back"}
                                name='mobility.lowerBack'
                                required={false}
                                defaultValue={form.defaults.mobility.lowerBack}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, lg: 8 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldUpload
                                form={form}
                                folder={'admin-media'}
                                label={"Photo"}
                                name={`mobility.photo`}
                                required={false}
                                defaultValue={form.defaults.mobility.photo}
                                limit={1}
                            />

                            <FieldText
                                form={form}
                                label={"Caption"}
                                name={`mobility.photoCaption`}
                                required={false}
                                defaultValue={form.defaults.mobility.photoCaption}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldUpload
                                form={form}
                                folder={'admin-media'}
                                label={"Photo"}
                                name={`mobility.secondPhoto`}
                                required={false}
                                defaultValue={form.defaults.mobility.secondPhoto}
                                limit={1}
                            />

                            <FieldText
                                form={form}
                                label={"Caption"}
                                name={`mobility.secondPhotoCaption`}
                                required={false}
                                defaultValue={form.defaults.mobility.secondPhotoCaption}
                            />

                        </Col>

                    </Row>

                    <Row gutter={8} edge={true} justify={'center'} align={'center'}>


                        <Col col={{ md: 12 }}>

                            <MobilityFront
                                gender={form.values.athlete.gender}
                                values={form.values.mobility}
                            />

                        </Col>

                        <Col col={{ md: 12 }}>

                            <MobilityBack
                                gender={form.values.athlete.gender}
                                values={form.values.mobility}
                            />

                        </Col>


                    </Row>

                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldRange
                        form={form}
                        label={"Rating (Suggested: " + calculateRatingMobility(form.values.mobility) + ")"}
                        name='mobility.rating'
                        required={false}
                        defaultValue={form.defaults.mobility.rating}
                        min={0}
                        max={10}
                        minLabel={"0: Poor"}
                        maxLabel={"10: Good"}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Comments"}
                        name={'mobility.comments'}
                        required={false}
                        defaultValue={form.defaults.mobility.comments}
                        rows={4}
                        maxLength={400}
                    />

                    <Row edge={true}>

                        <Button
                            label={'Use Default Text'}
                            onClick={useDefaultText.bind(null, `mobilityComment`, "mobility.comments")}
                            type={'faded'}
                            confirm={form.values.mobility.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current comment' } : undefined}
                        />

                    </Row>

                </Col>


            </Row>

        </SessionsEditTabsPanel>

    ), [props]);

}

export default SessionsEditTabsMobility;
