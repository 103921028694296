import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsCreateEditForm from './SessionsCreateEditForm';
import DeletePanel from '@dex/bubl-dash/src/lib/components/SinglePanels/DeletePanel';
import SessionsCopyData from './SessionsCopyData';
import EditableContext from '../../../context/EditableContext';
import { Button, ButtonRow, Form, Line, Panel } from '@dex/bubl-dash';

const SessionsEditTabsInfo: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <EditableContext.Provider value={true}>

                    <Panel
                        heading={"Session Details"}
                    >

                        <Form
                            form={form}
                            onSubmit={handleSubmit}
                            loading={process?.loading}
                        >

                            <SessionsCreateEditForm
                                mode={"edit"}
                                form={form}
                                data={data}
                                handleSubmit={handleSubmit}
                                process={process}
                            />


                            <Line />

                            <ButtonRow>
                                <Button
                                    label={"Save"}
                                    loading={process?.loading}
                                />

                            </ButtonRow>

                        </Form>

                        <Space />

                    </Panel>

                </EditableContext.Provider>

                <Space />

                <SessionsCopyData data={data} />

            </Col>

            <Col
                className={'side'}
            >

                <DeletePanel
                    data={data}
                />

            </Col>

        </Row>


    ), [props]);

}

export default SessionsEditTabsInfo;
