import { formatNum } from '@dex/bubl-helpers';
import React from 'react';

import styles from "./ReportImage.module.scss";

const ReportImage: React.FC<ReportImageProps> = (props) => {

    let { path, gender, className, type, ...rest } = props;

    if (!gender) gender = "male";

    let src = path;

    if (gender) src = src.replace(":gender", gender);
    if (type) src = src.replace(":type", type);

    let classes = [styles.image];

    if (className && Array.isArray(className)) classes = [...classes, ...className];
    else if (typeof className === "string") classes.push(className);

    return (

        <>

            <img
                {...rest}
                src={src}
                className={classes.join(" ")}
            />

        </>

    )

}

interface ReportImageProps {
    [key: string]: any,
}

export default ReportImage;