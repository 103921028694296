import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'EventTargets';

const EventTargetsApi: baseApiReturn = baseApi(endpoint);

EventTargetsApi.getTargetsByEvent = (options?: options): response => {

    return apiGet({
        url: endpoint + "/getTargetsByEvent",
        params: {},
        ...options
    });

}

EventTargetsApi.getData = (options?: options): response => {

    return apiGet({
        url: endpoint + "/getData",
        params: {},
        ...options
    });

}

export default EventTargetsApi;