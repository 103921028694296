import { Col, FieldRadio, Row } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import { fixedNum, get } from '@dex/bubl-helpers';
import { calculateMinPerKm } from '../../../functions/sharedTests';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartPerformanceTrackingMaximum from '../../../elements/ChartTestSessionReport/ChartPerformanceTrackingMaximum';
import styles from "./TestSessionsReportPerformanceTrackingAerobicCapacity.module.scss";
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';

const TestSessionsReportPerformanceTrackingAerobicCapacity: React.FC<any> = (props: any) => {

    const { data, form } = props;

    let gender = data.athlete?.gender;
    const activity: string = data.test.activity;
    const intensity = data?.test?.intensity;

    const zones = data.test.lactate?.zones;
    const anaerobic = zones["4"] || {};
    const ftp = get(data.test, `lactate.measures[0]`);
    const vo2Max = get(data.test, `lactate.measures[1]`);
    const [index, setIndex] = useState(data.history[0].date);
    const anaerobicPerKg = fixedNum(vo2Max?.vo2max_speed / data.bio?.weight, 1);

    const trackingUse = get(form.values, `tracking.trackingUse`, "HR");

    let options;
    let summary;
    let description;

    if (trackingUse == "Power") {

        options = [
            { label: "Watts", name: "Watts", data: data.history },
            { label: "Watts/Kg", name: "WattsPerKg", data: data.history }
        ]
        summary = 'maxVo2Speed'
        description = "This is the maximum average power you can sustain for 3 to 5 minutes. The higher this is, the stronger you are!"

    } else if (trackingUse == "Speed") {

        options = [
            { label: "kph", name: "speed", data: data.history },
            { label: "min/km", name: "pace", data: data.history }
        ]
        summary = 'maxVo2Speed'
        description = "This is the maximum speed you can hold for 3 to 5 minutes. The higher this is, the faster you are!"

    } else if (trackingUse == "VO2") {

        options = [
            { label: "ml/kg*min", name: "relative", data: data.history },
            { label: "l/min", name: "absolute", data: data.history }
        ]
        summary = "vo2RelativeMax"
        description = "This is your maximum oxygen consumption or ceiling Aerobic fitness capacity. The higher this is, the fitter you are!"

    } else if (trackingUse == "Carb Burn" || trackingUse == "Fat Burn") {

        options = [
            { label: "g/hr", name: "gram", data: data.history },
            { label: "kcal/hr", name: "kcal", data: data.history }
        ]
        summary = "fatmax"

        if (trackingUse == "Carb Burn") {

            description = "This is the peak rate of carbohydrates you burned during testing. At near to max efforts, almost all your energy comes from carbohydrates"

        } else if (trackingUse == "Fat Burn") {

            description = "This is your peak rate of fat consumed during testing. The higher it is, the more efficient your metabolism is or the better you are at burning fat and sparing carbohydrates (ideal for health and endurance performance)."

        }

    } else if (trackingUse == "Lactate") {

        summary = "lactate"
        description = "This is your peak lactate reached during testing. Generally, when below 10 mmol/L, you either are more aerobically inclined or did not perform a max effort. If above 15 mmol/L, you are more sprint-inclined  while between 10 and 15 mmol, you are likely a mixture of both!"

    } else if (trackingUse == "HR") {

        summary = "hrMax"
        description = "This is your peak heart rate achieved during testing. It is very individual and does not reflect one’s fitness level, only how fast your heart can beat at maximum efforts"

    }

    const isPerformance = ["Power", "Speed", "VO2"];

    return useMemo(() => (

        <>

            <Row edge gutter={8}>

                <Col col={{ xs: 20 }}>

                    <PanelChart
                        key={options + trackingUse}
                        heading={trackingUse + " Max"}
                        addZero={false}
                        chartKey={form.hash}
                        types={options}
                        defaultTypes={options ? options[0] : ""}
                        data={data.history}
                        renderChart={(chartType, chartData) => (
                            <>
                                <ChartPerformanceTrackingMaximum
                                    data={chartData}
                                    activity={activity}
                                    date={data.recentDate}
                                    index={index}
                                    intensity={intensity}
                                    chart={trackingUse}
                                    type={chartType}
                                />
                            </>
                        )}
                    />

                </Col>



                <Col col={{ xs: 4 }}>

                    <FieldRadio
                        list=" vertical-list"
                        key={trackingUse}
                        form={form}
                        required={false}
                        label={"Tracking Over Time"}
                        name={"tracking.trackingUse"}
                        defaultValue={form.values?.tracking?.trackingUse || trackingUse}
                        options={[
                            activity === "Cycling" ? { label: "Power", value: "Power" } : null,
                            activity === "Running" ? { label: "Speed", value: "Speed" } : null,
                            { label: "VO2", value: "VO2" },
                            { label: "HR", value: "HR" },
                            { label: "Lactate", value: "Lactate" },
                            { label: "Carb Burn", value: "Carb Burn" },
                            { label: "Fat Burn", value: "Fat Burn" },
                        ].filter(Boolean)}
                    />

                </Col>

                <Col col={{ xs: 20 }}>

                    <ReportDesc
                        label={description}
                        className={["no-border", "h1"]}
                    />

                </Col>

                <Col col={{ xs: 20 }}>

                    {(trackingUse == "Power" || trackingUse == "Speed" || trackingUse == "VO2") &&

                        <ReportHeading
                            text={"Current Rating"}
                        />

                    }

                    {(trackingUse == "Power" || trackingUse == "Speed") &&

                        <>

                            {activity == "Cycling" ?

                                <RatingScaleCard
                                    metric={"Performance - Cycling - Anaerobic"}
                                    value={vo2Max?.vo2max_speed}
                                    form={form}
                                    demographic={data.test.performance.powerAnaerobicScale || data.demographic}
                                    split={{
                                        metric: "Performance - Cycling - Anaerobic / Kg",
                                        value: anaerobicPerKg,
                                    }}
                                    gender={gender}
                                />
                                :
                                <RatingScaleCard
                                    metric={"Performance - Running - Anaerobic"}
                                    value={anaerobic.powerUpper}
                                    form={form}
                                    demographic={data.test.performance.powerThresholdScale || data.demographic}
                                    split={{
                                        metric: "Performance - Running - Threshold",
                                        value: ftp?.ftp_speed,
                                        altUnit: {
                                            value: calculateMinPerKm(ftp?.ftp_speed),
                                            suffix: "min/km",
                                        }
                                    }}
                                    altUnit={{
                                        value: calculateMinPerKm(anaerobic.powerUpper),
                                        suffix: "min/km",
                                        sep: " / "
                                    }}
                                    gender={gender}
                                />

                            }

                        </>

                    }

                    {trackingUse == "VO2" &&

                        <RatingScaleCard
                            metric={'VO2 Max'}
                            value={get(data.test, 'vo2.' + get(data.test, "vo2.zoneUse", "relativeMax"))}
                            form={form}
                            demographic={data.test.vo2.vo2MaxScale || data.demographic}
                            gender={gender}
                        />

                    }

                </Col>

                <Col col={{ xs: 20 }}>

                    <ReportChartSummary
                        label={""}
                        valueKey={summary}
                        indexKey={"date"}
                        data={data.history}
                        type={isPerformance.includes(trackingUse) ? "performance" : "size"}
                    />

                </Col>



            </Row>

        </>

    ), [form.hash, trackingUse, options]);

}

export default TestSessionsReportPerformanceTrackingAerobicCapacity;