import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldList, FieldPopup } from '@dex/bubl-dash';
import EditableContext from '../../../context/EditableContext';
import { workoutLabel } from '../../../functions/sharedTestData';
import LabTestsSweatWorkoutGeneral from './LabTestsSweatWorkoutGeneral';
import LabTestsSweatWorkoutBody from './LabTestsSweatWorkoutBody';
import LabTestsSweatWorkoutSodium from './LabTestsSweatWorkoutSodium';
import LabTestsSweatWorkoutInfo from './LabTestsSweatWorkoutInfo';

const LabTestsSweatWorkouts: React.FC<any> = (props: any) => {

    const { form, data, session } = props;

    return useMemo(() => (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldList
                        form={form}
                        name={`workouts`}
                        label={"Workouts"}
                        addButtonLabel={"Add Workout"}
                        defaultValues={{ date: session.date }}
                    >

                        {(form.values.workouts || []).map((workout, i) => (

                            <LabTestsSweatWorkoutForm
                                key={i}
                                i={i}
                                workout={workout}
                                form={form}
                                data={data}
                                session={session}
                            />

                        ))}

                    </FieldList>

                </Col>

            </Row>

        </>

    ), [form.hash, session, data]);

}

const LabTestsSweatWorkoutForm: React.FC<any> = (props: any) => {

    const { i, workout, form, data, session } = props;

    const prefix = `workouts[${i}]`;

    const [key, setKey] = useState(0);

    const [tab, setTab] = useState("general");

    const upKey = useCallback(() => {

        setKey(key + 1);

    }, [key]);

    const editable = useContext(EditableContext);

    const [recentTest, setRecentTest] = useState<any>(null);

    return (

        <FieldPopup
            buttonLabel={"Workout " + (i + 1) + " - " + workoutLabel(workout)}
            heading={"Workout " + (i + 1) + " - " + workoutLabel(workout)}
            form={form}
            name={prefix}
            modalProps={{
                className: 'modal-top'
            }}
            panelProps={{
                tabs: [
                    { label: "General", name: "general" },
                    { label: "Workout Info", name: "info" },
                    { label: "Body Input/Output", name: "body" },
                    { label: "Sodium Measures", name: "sodium" },
                ].filter(Boolean),
                setTab: setTab,
                activeTab: tab
            }}
        >

            <div key={key} className={!editable ? 'form-not-editable' : ''}>

                <div className={tab === "general" ? "" : "hidden"}>

                    <LabTestsSweatWorkoutGeneral
                        i={i}
                        session={session}
                        form={form}
                        data={data}
                        workout={workout}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "info" ? "" : "hidden"}>

                    <LabTestsSweatWorkoutInfo
                        i={i}
                        form={form}
                        data={data}
                        session={session}
                        workout={workout}
                        prefix={prefix}
                        setKey={upKey}
                        recentTest={recentTest}
                        setRecentTest={setRecentTest}
                    />

                </div>

                <div className={tab === "body" ? "" : "hidden"}>

                    <LabTestsSweatWorkoutBody
                        i={i}
                        form={form}
                        data={data}
                        session={session}
                        workout={workout}
                        prefix={prefix}
                        setKey={upKey}
                        recentTest={recentTest}
                        setRecentTest={setRecentTest}
                    />

                </div>

                <div className={tab === "sodium" ? "" : "hidden"}>

                    <LabTestsSweatWorkoutSodium
                        i={i}
                        form={form}
                        data={data}
                        session={session}
                        workout={workout}
                        prefix={prefix}
                        setKey={upKey}
                        recentTest={recentTest}
                        setRecentTest={setRecentTest}
                    />

                </div>

            </div>

        </FieldPopup>

    )

}

export default LabTestsSweatWorkouts;
