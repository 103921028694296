import { Col, DataTableCell, FieldTextArea, InputTable, InputTableRow, Row, Space } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import { fixedNum, get, loop } from '@dex/bubl-helpers';
import ChartProps from '../../../elements/Chart/Chart.Props';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartSprintLactate from '../../../elements/ChartLabTests/ChartSprintLactate';
import ChartMuscle from '../../../elements/ChartLabTests/ChartMuscle';
import ChartSprintMuscleLactate from '../../../elements/ChartTestSessionReport/ChartSprintMuscleLactate';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ChartSprintMuscleOxygen from '../../../elements/ChartTestSessionReport/ChartSprintMuscleOxygen';

const TestsReportSprintMuscleFitness: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const activity = data.test.activity;

    const gender = data.athlete.gender || "male"

    const zoneUse = data.test.muscle?.zoneUse || "a";

    const thbDrop = fixedNum(get(data.test, `muscle.${zoneUse}.thbMax`) - get(data.test, `muscle.${zoneUse}.thbMin`), 2) || " ";

    return useMemo(() => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 24, md: 24 }}>

                    <ReportHeading
                        text="Lactate Profile"
                    />

                    <PanelChart
                        heading={"Lactate"}
                        data={form.values.manualData}
                        addZero={false}
                        renderChart={(chartType, chartData) => (

                            <>
                                <ChartSprintMuscleLactate
                                    data={chartData}
                                    activity={activity}
                                    intensity={data.intensity}
                                    xAxis={{
                                        interval: ChartProps.interval(activity, chartType),
                                    }}
                                    peak={form.values.manual?.timePeakLactate}
                                />
                            </>

                        )}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label={"Peak Lactate and VLamax"}
                        value={data.test.lactate.sprintLactateDescription}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <InputTable
                        columns={[
                            { colSpan: 2, label: "Lactate Measures" },
                        ]}
                    >
                        <InputTableRow>

                            <DataTableCell>
                                <strong>Peak Lactate</strong>
                            </DataTableCell>

                            <DataTableCell>

                                <strong>{form.values?.manual?.peakLactate} mmol/L</strong>

                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Vlamax</strong>
                            </DataTableCell>

                            <DataTableCell>

                                <strong>{form.values?.manual?.vlaMax} mmol/L/sec</strong>

                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Time To Peak Lac</strong>
                            </DataTableCell>

                            <DataTableCell>

                                <strong>{form.values?.manual.timePeakLactate} mm:ss</strong>

                            </DataTableCell>

                        </InputTableRow>

                    </InputTable>

                    <Space />

                    <RatingScaleCard
                        form={form}
                        metric={'Vlamax'}
                        value={data.test?.manual?.vlaMax}
                        demographic={get(data.test, 'lactate.lactateRatingScale', data.demographic)}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <ReportHeading
                        text="Muscle Oxygen Profile"
                    />

                    <PanelChart
                        heading={"Muscle Oxygen & THb"}
                        types={[
                            {
                                name: "avg",
                                label: "Sprint Only",
                                data: data.test.avgData
                            },
                            {
                                name: "rolling",
                                label: "Average 5 Sec",
                                data: data.test.rollingData
                            },
                            {
                                name: "raw",
                                label: "Raw",
                                data: data.test.rawData
                            }
                        ]}
                        addZero={false}
                        renderChart={(chartType, chartData) => (

                            <>
                                <ChartSprintMuscleOxygen
                                    data={chartData}
                                    activity={activity}
                                    keyIndex={zoneUse == "a" ? 1 : 2}
                                    intensity={data.intensity}
                                    xAxis={{
                                        interval: ChartProps.interval(activity, chartType),
                                    }}
                                />
                            </>

                        )}
                    />

                </Col>


                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label={"Muscle Oxygen (SMO2)"}
                        value={data.test.muscle.sprintSmo2Description}
                    />

                    <Space />

                    <ReportDesc
                        label={"THb or Total Hemoglobin"}
                        value={data.test.muscle.sprintTbhDescription}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <InputTable
                        columns={[{ colSpan: 3, label: "Muscle Oxygen Measures", alignMiddle: true },
                        ]}
                    >
                        <InputTableRow>

                            <DataTableCell />

                            <DataTableCell>
                                <strong>SMO2 - %</strong>
                            </DataTableCell>

                            <DataTableCell>
                                <strong> Thb -mg/dl </strong>
                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Min</strong>
                            </DataTableCell>

                            <DataTableCell>
                                <strong>{get(data.test, `muscle.${zoneUse}.smo2Min`)}</strong>
                            </DataTableCell>

                            <DataTableCell>
                                <strong>{get(data.test, `muscle.${zoneUse}.thbMin`)}</strong>
                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Max</strong>
                            </DataTableCell>

                            <DataTableCell>
                                <strong>{get(data.test, `muscle.${zoneUse}.smo2Max`)}</strong>
                            </DataTableCell>

                            <DataTableCell>
                                <strong>{get(data.test, `muscle.${zoneUse}.thbMax`)}</strong>
                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Drop</strong>
                            </DataTableCell>

                            <DataTableCell>
                                <strong>{get(data.test, `muscle.${zoneUse}.smo2Drop`)}</strong>
                            </DataTableCell>

                            <DataTableCell>
                                <strong>{thbDrop}</strong>
                            </DataTableCell>

                        </InputTableRow>

                    </InputTable>

                    <Space />

                    <RatingScaleCard
                        form={form}
                        metric={'SMO2 Rating'}
                        value={get(data.test, `muscle.${zoneUse}.smo2Min`)}
                        demographic={get(data.test, 'muscle.smo2RatingScale', data.demographic)}
                        gender={gender}
                    />

                </Col>

            </Row>

        </>

    ), [data])
}

export default TestsReportSprintMuscleFitness;