import { Box, Col, Heading, Line, Row, Space, WhiteSpace, useForm } from '@dex/bubl-dash';
import React, { useEffect, useMemo, useState } from 'react';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import FancyValue from '../../../elements/FancyValue/FancyValue';
import styles from "./CoachingReportTrainingWeek.module.scss";
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import ChartCoachingWeeklyIntensity from '../../../elements/ChartCoaching/ChartCoachingWeeklyIntensity';
import ChartCoachingWeeklyVolume from '../../../elements/ChartCoaching/ChartCoachingWeeklyVolume';
import { calculateTarget, getIcon } from '../../../functions/sharedCoaching';
import ReportCheck from '../../../elements/ReportCheck/ReportCheck';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import { get, hashCode, unslug, upperCase } from '@dex/bubl-helpers';
import CoachingApi from '../CoachingApi';
import { PeriodFilter } from './CoachingReportTracking';
import RatingScaleTable from '../../../elements/RatingScale/RatingScaleTable';

const CoachingReportTrainingWeek: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const weekData = data?.week;
    const volumeTotal = weekData.volume?.totalEndurance;
    const intensityTotal = weekData.intensity?.totalEndurance;

    const activity = "cycling";
    const filter = useForm({ period: "last-3-months" });

    const [trackingData, setTrackingData]: any = useState([]);
    const fetch = CoachingApi.getReportData(data.id);

    useEffect(() => {

        fetch.run({
            params: { filters: filter.values, type: "training-over-time" },
            onComplete: (response: any) => {
                setTrackingData(response);

            }

        })

    }, [hashCode(filter.values.to), hashCode(filter.values.from)]);

    const weekEndDate = get(weekData, 'weekEndDate');
    const currentWeek = trackingData?.weeks?.find(item => item.weekEndDate === weekEndDate);

    const [VolumeChartType, setVolumeChartType] = useState("hrs");

    const volumeValue = VolumeChartType == "hrs" ? {
        value: volumeTotal?.timeActual,
        target: volumeTotal?.timeTarget,
        met: calculateTarget(volumeTotal?.timeTarget, volumeTotal?.timeActual)
    } : {
        value: volumeTotal?.distanceActual,
        target: volumeTotal?.distanceTarget,
        met: calculateTarget(volumeTotal?.distanceTarget, volumeTotal?.distanceActual, "")
    }

    const intensityValue = {
        value: intensityTotal?.time,
        target: intensityTotal?.target,
        met: calculateTarget(intensityTotal?.target, intensityTotal?.time)
    }

    return useMemo(() => (

        <Row edge gutter={"auto"}>

            <Col col={{ xs: 24 }}>

                <PeriodFilter
                    data={data}
                    filter={filter}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Volume"
                />

            </Col>

            <Col col={{ xs: 16 }}>

                <PanelChart
                    heading={"Volume This Week"}
                    types={[
                        {
                            name: "hrs",
                            label: "Hours",
                            data: weekData.volume?.results
                        },
                        {
                            name: "km",
                            label: "KM",
                            data: weekData.volume?.results
                        }
                    ]}
                    renderChart={(chartType, chartData) => (
                        <>
                            {weekData.volume?.results &&
                                <ChartCoachingWeeklyVolume
                                    data={weekData.volume?.results}
                                    type={chartType}
                                    setVolumeChartType={setVolumeChartType(chartType)}
                                />
                            }
                        </>
                    )}
                />

            </Col>

            <Col col={{ xs: 8 }}>
                <SideTable
                    period={filter.values.period}
                    data={weekData.volume?.totalEndurance}
                    type="volume"
                    values={volumeValue}
                    volumeChartType={VolumeChartType}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Intensity"
                />

            </Col>

            <Col col={{ xs: 16 }}>

                <PanelChart
                    heading={"Intensity This Week"}
                    types={[
                        {
                            name: "hrs",
                            label: "Hours",
                            data: weekData.intensity?.results
                        },
                        {
                            name: "%",
                            label: "%",
                            data: weekData.intensity?.results
                        }
                    ]}
                    addZero={false}
                    renderChart={(chartType, chartData) => (
                        <>
                            {weekData.intensity?.results &&
                                <ChartCoachingWeeklyIntensity
                                    data={weekData.intensity?.results}
                                    type={chartType}
                                    activity={activity}
                                    intensity={data.intensity}
                                />
                            }
                        </>
                    )}
                />

            </Col>

            <Col col={{ xs: 8 }}>
                <SideTable
                    period={filter.values.period}
                    data={weekData.intensity?.totalEndurance}
                    type="intensity"
                    values={intensityValue}
                />
            </Col>

            <Col col={{ xs: 24 }}>
                <CoachingReportHeading
                    text="Conditioning Rating This Week"
                />
            </Col>

            <Col col={{ xs: 12 }}>

                <RatingScaleCard
                    renderHeading={(title) => <>
                        <img
                            src={require("../../../assets/images/Coaching/stimulusFitness.png")}
                            className={styles.ratingIcon}
                        />
                        {title}:
                    </>}
                    metric={'Fitness Level'}
                    value={currentWeek?.fitnessStimulus}
                    gender={data.athlete.gender}
                // name="FitnessLevel"
                // demographic={get(data, 'FitnessLevel')}
                />

                <Space />

                <RatingScaleCard
                    renderHeading={(title) => <>
                        <img
                            src={require("../../../assets/images/Coaching/bodyStress.png")}
                            className={styles.ratingIcon}
                        />
                        {title}:
                    </>}
                    metric={'Body Stress'}
                    value={currentWeek?.bodyStress}
                    gender={data?.athlete?.gender}
                // name="bodyStress"
                // demographic={get(data, 'bodyStress')}
                />

            </Col>

            <Col col={{ xs: 12 }}>

                <RatingScaleTable
                    title={"Training Stimulus"}
                    rows={[
                        {
                            metric: `Training Stimulus`,
                            gender: data?.athlete?.gender,
                            renderHeading: () => <>
                                <img
                                    src={require("../../../assets/images/Coaching/stimulusSwim.png")}
                                    className={styles.ratingIcon}
                                />
                                Swim</>,
                            value: currentWeek?.swimStimulusAverage
                        },
                        {
                            metric: `Training Stimulus`,
                            gender: data?.athlete?.gender,
                            renderHeading: () => <>
                                <img
                                    src={require("../../../assets/images/Coaching/stimulusBike.png")}
                                    className={styles.ratingIcon}
                                />
                                Bike</>,
                            value: currentWeek?.bikeStimulusAverage
                        },
                        {
                            metric: `Training Stimulus`,
                            gender: data?.athlete?.gender,
                            renderHeading: () => <>
                                <img
                                    src={require("../../../assets/images/Coaching/stimulusRun.png")}
                                    className={styles.ratingIcon}
                                />
                                Run</>,
                            value: currentWeek?.runStimulusAverage
                        },
                    ]}
                    show={true}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Highlights This Week"
                />

                <Highlights
                    highlights={weekData?.highlights || {}}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Training Distruption"
                />

                <Row edge gutter={4} align='center'>

                    {["Sick/Injured", "Travel", "Work", "None"].map((item, index) => (

                        <React.Fragment key={index}>

                            <Col>
                                <ReportCheck checked={weekData.comments?.trainingDistruption?.includes(item)} />
                            </Col>

                            <Col>
                                {item}
                                <WhiteSpace /><WhiteSpace /><WhiteSpace /><WhiteSpace />
                                <WhiteSpace /><WhiteSpace /><WhiteSpace /><WhiteSpace />
                            </Col>

                        </React.Fragment>

                    ))}

                </Row>

                <Space />

            </Col>

        </Row>

    ), [data, currentWeek, trackingData, filter.hash]);

}

const SideTable: React.FC<any> = (props: any) => {

    const { data, values, type, period, volumeChartType } = props;

    const target = getIcon(values.target, values.value, values.met);

    let remark;

    if (target?.text) {
        if (type == "intensity") {
            remark = <p className={styles.remarks}>Your training intensity this week was <span className={[styles[`target${target?.text}`]].join(" ")}>{upperCase(target?.text)}</span>  your average for the <span className={styles.target}>{unslug(period)}.</span></p>
        } else {
            remark = <p className={styles.remarks}>Your total training volume this week <span className={[styles[`target${target?.text}`]].join(" ")}>{upperCase(target?.text)}</span> your set Target for the <span className={styles.target}> Week.</span></p>
        }
    }

    return (
        <Row edge gutter={"auto"} justify='center'>

            <Col col={{ xs: 24 }} align='center' justify='center'>

                <div className={styles.text}>
                    <strong>Total:</strong>
                </div>

                {volumeChartType == "km" ?
                    <FancyValue
                        value={values.value}
                        suffix={"KM"}
                    />
                    :
                    <FancyValue
                        value={values.value}
                        suffix={"hh:mm"}
                    />
                }

            </Col>

            <Col col={{ xs: 24 }} align='center'>

                <div className={styles.arrow}>
                    {target?.icon}
                </div>

                <Heading space="none" className={"text-center"}> {target?.text} {type !== "intensity" ? "Target:" : "Average:"}</Heading>

                <p className={styles.time}>(  {values.target} <span> {volumeChartType == "km" ? "km" : "hh:mm"}</span> )</p>

            </Col>

            {remark &&
                <Col col={{ xs: 24 }} align='center'>
                    <p className={styles.blankTarget}>Remarks:</p>
                    {remark}
                </Col>
            }

        </Row>

    )
};

const Highlights: React.FC<any> = (props: any) => {

    const { highlights } = props;

    const groups = [
        {
            key: "swim",
            title: "Swim",
            icon: require("../../../assets/images/Coaching/stimulusSwim.png"),
        },
        {
            key: "bike",
            title: "Bike",
            icon: require("../../../assets/images/Coaching/stimulusBike.png"),
        },
        {
            key: "run",
            title: "Run",
            icon: require("../../../assets/images/Coaching/stimulusRun.png"),
        },
        {
            key: "total",
            title: "Totals",
            icon: require("../../../assets/images/Coaching/stimulusTotal.png"),
        },
        {
            key: "hr",
            title: "HR Zone",
            icon: require("../../../assets/images/Coaching/stimulusFitness.png"),
        }
    ];

    const bestIcon = <img
        src={require("../../../assets/images/Coaching/medalBest.png")}
        style={{ width: 60, height: 60 + "px", objectFit: "scale-down" }}
    />

    const top5Icon = <img
        src={require("../../../assets/images/Coaching/medalTop.png")}
        style={{ width: 60, height: 60 + "px", objectFit: "scale-down" }}
    />

    return (

        <Row edge gutter={"auto"}>

            {groups.map((group, index) => (
                <React.Fragment key={index}>
                    {highlights[group.key] && highlights[group.key].length > 0 &&
                        <Col col={{ xs: 12 }}>

                            <Box className='highlights'>

                                <Row gutter={"small"} align='center'>
                                    <Col col={{ xs: 16 }} align='start'>
                                        <Heading space={false}>{group.title}</Heading>
                                    </Col>
                                    <Col col={{ xs: 8 }} align='end'>
                                        <img
                                            src={group.icon}
                                            style={{ height: 40 + "px" }}
                                        />
                                    </Col>
                                </Row>

                                <Line top={false} />

                                <table>
                                    {highlights[group.key].map((item, index) => (
                                        <tr>
                                            <td>{item.icon == "best" ? bestIcon : top5Icon} </td> <td style={{ verticalAlign: "middle" }} className={styles.highlightText}>{item.text}</td>
                                        </tr>
                                    ))}
                                </table>

                            </Box>

                        </Col>
                    }
                </React.Fragment>
            ))}

        </Row>

    )
};

export default CoachingReportTrainingWeek;