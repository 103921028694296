export const sumData = {
    displayAs: "scale",
    suffix: '',
    steps: [
        {
            "label": "Very Poor",
            "hidden": null,
            "male": {
                "untrained": {
                    "min": "0",
                    "max": "10"
                },
            }
        },
        {
            "label": "Compromised",
            "hidden": true,
            "male": {
                "untrained": {
                    "min": "10",
                    "max": "15"
                },
            }
        },
        {
            "label": "Satisfactory",
            "hidden": true,
            "male": {
                "untrained": {
                    "min": "15",
                    "max": "20"
                },
            }
        },
        {
            "label": "Good",
            "hidden": null,
            "male": {
                "untrained": {
                    "min": "20",
                    "max": "25"
                },
            }
        },
    ]
}

export const normalData = {
    displayAs: "scale",
    suffix: " ",
    steps: [
        {
            "label": "Low",
            "hidden": null,
            "male": {
                "untrained": {
                    "min": "1",
                    "max": "2"
                },
            }
        },
        {
            "label": "Fair",
            "hidden": null,
            "male": {
                "untrained": {
                    "min": "3",
                    "max": "3"
                },
            }
        },
        {
            "label": "Good",
            "hidden": null,
            "male": {
                "untrained": {
                    "min": "4",
                    "max": "5"
                },
            }
        },
    ]
}