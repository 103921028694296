import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import { FieldTextArea, Seperator } from '@dex/bubl-dash';

const SettingsFitSize: React.FC<any> = (props: any) => {

    const { form } = props;

    return useMemo(() => (

        <Row gutter={8} edge={true}>


            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Injury"}
                    bottom={"auto"}
                />
            </Col>


            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Injury Comment"}
                    name={"injuryComment"}
                    defaultValue={form.defaults.injuryComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Posture"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Posture Comment"}
                    name={"postureComment"}
                    defaultValue={form.defaults.postureComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Mobility"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Mobility Comment"}
                    name={"mobilityComment"}
                    defaultValue={form.defaults.mobilityComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Symmetry"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Symmetry Comment"}
                    name={"symmetryComment"}
                    defaultValue={form.defaults.symmetryComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Stability"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Stability Comment"}
                    name={"stabilityComment"}
                    defaultValue={form.defaults.stabilityComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Foot"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Foot Comment"}
                    name={"footComment"}
                    defaultValue={form.defaults.footComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Cleat"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Cleat Comment"}
                    name={"cleatComment"}
                    defaultValue={form.defaults.cleatComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Bike"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Adjustments Comment"}
                    name={"adjustmentComment"}
                    defaultValue={form.defaults.adjustmentComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Pressure Comment"}
                    name={"pressureComment"}
                    defaultValue={form.defaults.pressureComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Suitability Comment"}
                    name={"suitabilityComment"}
                    defaultValue={form.defaults.suitabilityComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Summary"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Default Text"}
                    name={"defaultText"}
                    defaultValue={form.defaults.defaultText}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Sitting Too High Text"}
                    name={"sittingTooHigh"}
                    defaultValue={form.defaults.sittingTooHigh}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Sitting Too Low Text"}
                    name={"sittingTooLow"}
                    defaultValue={form.defaults.sittingTooLow}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Bike Sizing Text"}
                    name={"bikeSizing"}
                    defaultValue={form.defaults.bikeSizing}
                    rows={5}
                />

            </Col>

        </Row>

    ), [form.hash]);

}

export default SettingsFitSize;
