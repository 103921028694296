import React, { useContext, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import EditableContext from '../../../context/EditableContext';
import { useApp } from '@dex/bubl-dash';

const GaitsEditTabsPanel: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, } = props;

    const editable = useContext(EditableContext);

    const app = useApp();

    const [nav] = app.store("nav");

    const handleRefresh = () => {
        app.navigate(nav, 'update', {});
    }

    return useMemo(() => (

        <>

            {!editable &&
                <>
                    {props.children}
                </>
            }

            {editable &&
                <Row
                    gutter={'auto'}
                >

                    <Col
                        col={{ xs: 24 }}
                    >

                        <Panel
                            heading={props.heading}
                            actions={
                                <Button
                                    label={"Save"}
                                    onClick={form.triggerSubmit}
                                />
                            }
                        >

                            <Form
                                form={form}
                                onSubmit={handleSubmit}
                                loading={process.loading}
                            >

                                {props.children}

                                <Line />

                                <ButtonRow>

                                    <Button
                                        label={"Save"}
                                        loading={process.loading}
                                    />

                                    <Button
                                        label={"Cancel"}
                                        type='faded'
                                        onClick={handleRefresh.bind(null)}
                                        htmlType="button"
                                    />

                                </ButtonRow>

                            </Form>

                        </Panel>

                    </Col>

                </Row>
            }

        </>

    ), [props, form.hash]);

}

export default GaitsEditTabsPanel;
