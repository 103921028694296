import React, { useCallback, useMemo, useState } from 'react';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import InputTable, { InputTableRow, InputTableCell } from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import { FieldText, useMountEffect } from '@dex/bubl-dash';
import { fixedNum, isEmpty, parseNum } from '@dex/bubl-helpers';

const SessionsEditTabsBikeLeomo: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;

    const [loading, setLoading] = useState(false);

    const columns = [
        {
            key: "prop",
            label: "Property / Description",
            style: { width: 120 }
        },
        {
            key: "pre",
            label: "Pre",
            style: { width: 120 }
        },
        {
            key: "prost",
            label: "Post",
            style: { width: 120 }
        },
        {
            key: "difference",
            label: "Difference",
            style: { width: 120 }
        },
        {
            key: "change",
            label: "Change",
            style: { width: 120 }
        },
        {
            key: "ideal",
            label: "Ideal",
            style: { width: 120 }
        },
    ]

    const getDifference = useCallback((pre, post) => {

        if (isEmpty(pre) || isEmpty(post)) return;

        const difference = fixedNum(pre - post, 1);

        return difference;

    }, [])

    const getChanges = useCallback((value, name) => {

        let change;

        if (name == "leomoTorsoAngleChange" || name == "leomoHipAngleChange") {
            if (value >= 1) {
                change = "Lowered"
            } else if (value <= -1) {
                change = "Raised"
            } else if (value <= 1 && value >= -1) {
                change = "Unchanged"
            } else {
                change = "Not Assessed"
            }
        } else if (name == "leomoHipRotationChange" || name == "leomoHipRockingChange") {
            if (value >= 1) {
                change = "More Stable"
            } else if (value <= -1) {
                change = "Less Stable"
            } else if (value <= 1 && value >= -1) {
                change = "Unchanged"
            } else {
                change = "Not Assessed"
            }
        } else {
            if (value >= 1) {
                change = "Reduced"
            } else if (value <= -1) {
                change = "Increased"
            } else if (value <= 1 && value >= -1) {
                change = "Unchanged"
            } else {
                change = "Not Assessed"
            }
        }

        return change;

    }, [])

    useMountEffect(() => {

        if (!bike.leomoTorsoAngleDifference) form.handleChange({ name: `${prefix}.leomoTorsoAngleDifference`, value: getDifference(bike.leomoTorsoAnglePre, bike.leomoTorsoAnglePost), reset: true });
        if (!bike.leomoTorsoAngleChange) form.handleChange({ name: `${prefix}.leomoTorsoAngleChange`, value: getChanges(getDifference(bike.leomoTorsoAnglePre, bike.leomoTorsoAnglePost), "leomoTorsoAngleChange"), reset: true });
        if (!bike.leomoHipAngleDifference) form.handleChange({ name: `${prefix}.leomoHipAngleDifference`, value: getDifference(bike.leomoHipAnglePre, bike.leomoHipAnglePost), reset: true });
        if (!bike.leomoHipAngleChange) form.handleChange({ name: `${prefix}.leomoHipAngleChange`, value: getChanges(getDifference(bike.leomoHipAnglePre, bike.leomoHipAnglePost), "leomoHipAngleChange"), reset: true });
        if (!bike.leomoHipRotationDifference) form.handleChange({ name: `${prefix}.leomoHipRotationDifference`, value: getDifference(bike.leomoHipRotationPre, bike.leomoHipRotationPost), reset: true });
        if (!bike.leomoHipRotationChange) form.handleChange({ name: `${prefix}.leomoHipRotationChange`, value: getChanges(getDifference(bike.leomoHipRotationPre, bike.leomoHipRotationPost), "leomoHipRotationChange"), reset: true });
        if (!bike.leomoHipRockingDifference) form.handleChange({ name: `${prefix}.leomoHipRockingDifference`, value: getDifference(bike.leomoHipRockingPre, bike.leomoHipRockingPost), reset: true });
        if (!bike.leomoHipRockingChange) form.handleChange({ name: `${prefix}.leomoHipRockingChange`, value: getChanges(getDifference(bike.leomoHipRockingPre, bike.leomoHipRockingPost), "leomoHipRockingChange"), reset: true });
        if (!bike.leomoFeetDdsLeftDifference) form.handleChange({ name: `${prefix}.leomoFeetDdsLeftDifference`, value: getDifference(bike.leomoFeetDdsLeftPre, bike.leomoFeetDdsLeftPost), reset: true });
        if (!bike.leomoFeetDdsLeftChange) form.handleChange({ name: `${prefix}.leomoFeetDdsLeftChange`, value: getChanges(getDifference(bike.leomoFeetDdsLeftPre, bike.leomoFeetDdsLeftPost), "leomoFeetDdsLeftChange"), reset: true });
        if (!bike.leomoFeetDdsRightDifference) form.handleChange({ name: `${prefix}.leomoFeetDdsRightDifference`, value: getDifference(bike.leomoFeetDdsRightPre, bike.leomoFeetDdsRightPost), reset: true });
        if (!bike.leomoFeetDdsRightChange) form.handleChange({ name: `${prefix}.leomoFeetDdsRightChange`, value: getChanges(getDifference(bike.leomoFeetDdsRightPre, bike.leomoFeetDdsRightPost), "leomoFeetDdsRightChange"), reset: true });

        if (!bike.leomoTorsoAngleIdeal) form.handleChange({ name: `${prefix}.leomoTorsoAngleIdeal`, value: "Individual", reset: true });
        if (!bike.leomoHipAngleIdeal) form.handleChange({ name: `${prefix}.leomoHipAngleIdeal`, value: "Individual; <30 deg is Aggressive", reset: true });
        if (!bike.leomoHipRotationIdeal) form.handleChange({ name: `${prefix}.leomoHipRotationIdeal`, value: "<8 deg good; <5 deg Ideal", reset: true });
        if (!bike.leomoFeetDdsLeftIdeal) form.handleChange({ name: `${prefix}.leomoFeetDdsLeftIdeal`, value: "<3 deg/sec Fair; O deg/sec Ideal", reset: true });

    });

    return useMemo(() => (

        <InputTable
            columns={columns}
        >

            <InputTableRow>

                <InputTableCell style={{ width: 550, paddingLeft: 12 }}>
                    Torso Angle:
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoTorsoAnglePre`}
                        defaultValue={bike.leomoTorsoAnglePre}
                        suffix={"°"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoTorsoAnglePost`}
                        defaultValue={bike.leomoTorsoAnglePost}
                        suffix={"°"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoTorsoAngleDifference`}
                        defaultValue={getDifference(bike.leomoTorsoAnglePre, bike.leomoTorsoAnglePost)}
                        allowNegative={true}
                        suffix={"°"}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldText
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoTorsoAngleChange`}
                        defaultValue={getChanges(getDifference(bike.leomoTorsoAnglePre, bike.leomoTorsoAnglePost), "leomoTorsoAngleChange")}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 440 }}>
                    <FieldText
                        form={form}
                        name={`${prefix}.leomoTorsoAngleIdeal`}
                        defaultValue={bike.leomoTorsoAngleIdeal}
                    />
                </InputTableCell>

            </InputTableRow>

            <InputTableRow>

                <InputTableCell style={{ width: 550, paddingLeft: 12 }}>
                    Hip Angle:
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipAnglePre`}
                        defaultValue={bike.leomoHipAnglePre}
                        suffix={"°"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipAnglePost`}
                        defaultValue={bike.leomoHipAnglePost}
                        suffix={"°"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipAngleDifference`}
                        defaultValue={getDifference(bike.leomoHipAnglePre, bike.leomoHipAnglePost)}
                        allowNegative={true}
                        suffix={"°"}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldText
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipAngleChange`}
                        defaultValue={getChanges(getDifference(bike.leomoHipAnglePre, bike.leomoHipAnglePost), "leomoHipAngleChange")}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldText
                        form={form}
                        name={`${prefix}.leomoHipAngleIdeal`}
                        defaultValue={bike.leomoHipAngleIdeal}
                    />
                </InputTableCell>

            </InputTableRow>

            <InputTableRow>

                <InputTableCell style={{ width: 550, paddingLeft: 12 }}>
                    Hip Rotation:
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipRotationPre`}
                        defaultValue={bike.leomoHipRotationPre}
                        suffix={"°"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipRotationPost`}
                        defaultValue={bike.leomoHipRotationPost}
                        suffix={"°"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipRotationDifference`}
                        defaultValue={getDifference(bike.leomoHipRotationPre, bike.leomoHipRotationPost)}
                        allowNegative={true}
                        suffix={"°"}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldText
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipRotationChange`}
                        defaultValue={getChanges(getDifference(bike.leomoHipRotationPre, bike.leomoHipRotationPost), "leomoHipRotationChange")}
                    />
                </InputTableCell>

                <InputTableCell rowSpan={2} style={{ width: 220 }}>
                    <FieldText
                        form={form}
                        name={`${prefix}.leomoHipRotationIdeal`}
                        defaultValue={bike.leomoHipRotationIdeal}
                    />
                </InputTableCell>

            </InputTableRow>

            <InputTableRow>

                <InputTableCell style={{ width: 550, paddingLeft: 12 }}>
                    Hip Rocking:
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipRockingPre`}
                        defaultValue={bike.leomoHipRockingPre}
                        suffix={"°"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipRockingPost`}
                        defaultValue={bike.leomoHipRockingPost}
                        suffix={"°"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipRockingDifference`}
                        defaultValue={getDifference(bike.leomoHipRockingPre, bike.leomoHipRockingPost)}
                        allowNegative={true}
                        suffix={"°"}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldText
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoHipRockingChange`}
                        defaultValue={getChanges(getDifference(bike.leomoHipRockingPre, bike.leomoHipRockingPost), "leomoHipRockingChange")}
                    />
                </InputTableCell>


            </InputTableRow>

            <InputTableRow>

                <InputTableCell style={{ width: 550, paddingLeft: 12 }}>
                    FEET DDS Left:
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoFeetDdsLeftPre`}
                        defaultValue={bike.leomoFeetDdsLeftPre}
                        suffix={"°/sec"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoFeetDdsLeftPost`}
                        defaultValue={bike.leomoFeetDdsLeftPost}
                        suffix={"°/sec"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoFeetDdsLeftDifference`}
                        defaultValue={getDifference(bike.leomoFeetDdsLeftPre, bike.leomoFeetDdsLeftPost)}
                        allowNegative={true}
                        suffix={"°/sec"}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldText
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoFeetDdsLeftChange`}
                        defaultValue={getChanges(getDifference(bike.leomoFeetDdsLeftPre, bike.leomoFeetDdsLeftPost), "leomoFeetDdsLeftChange")}
                    />
                </InputTableCell>

                <InputTableCell rowSpan={2} style={{ width: 220 }}>
                    <FieldText
                        form={form}
                        name={`${prefix}.leomoFeetDdsLeftIdeal`}
                        defaultValue={bike.leomoFeetDdsLeftIdeal}
                    />
                </InputTableCell>

            </InputTableRow>

            <InputTableRow>

                <InputTableCell style={{ width: 550, paddingLeft: 12 }}>
                    FEET DDS Right:
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoFeetDdsRightPre`}
                        defaultValue={bike.leomoFeetDdsRightPre}
                        suffix={"°/sec"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoFeetDdsRightPost`}
                        defaultValue={bike.leomoFeetDdsRightPost}
                        suffix={"°/sec"}
                        allowNegative={true}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldNumber
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoFeetDdsRightDifference`}
                        defaultValue={getDifference(bike.leomoFeetDdsRightPre, bike.leomoFeetDdsRightPost)}
                        allowNegative={true}
                        suffix={"°/sec"}
                    />
                </InputTableCell>

                <InputTableCell style={{ width: 220 }}>
                    <FieldText
                        hidden
                        disabled
                        dynamicKey
                        form={form}
                        wrapClass={"w-200"}
                        name={`${prefix}.leomoFeetDdsRightChange`}
                        defaultValue={getChanges(getDifference(bike.leomoFeetDdsRightPre, bike.leomoFeetDdsRightPost), "leomoFeetDdsRightChange")}
                    />
                </InputTableCell>

            </InputTableRow>

        </InputTable>


    ), [props, loading, form.hash]);

}

export default SessionsEditTabsBikeLeomo;
