import { Button, Col, DataTableCell, FieldCheckbox, FieldHidden, FieldNumber, FieldRadio, FieldSelect, FieldText, FieldTextTime, InputTable, InputTableCell, InputTableRow, Row, Seperator, Space, useMountEffect, useUpdateEffect } from '@dex/bubl-dash';
import { clone, date, dateDay, fixedNum, get, hashCode, loop, parseNum, set, slug } from '@dex/bubl-helpers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { labAnalysisRegresionCharts } from '../../../functions/chartRegression';
import { getClockTime } from '../../../functions/sharedTests';
import LabTestsApi from '../LabTestsApi';
import GlycogenFueling from '../../../elements/GlycogenFueling/GlycogenFueling';
import { checkBonkGreaterThanTarget, getBonkTime, getFuelingRateData, getGlycogenChartData, getGlycogenTestData } from '../../../functions/glycogenTest';
import ChartGlycogen from '../../../elements/ChartLabTests/ChartGlycogen';
import PanelChart from '../../../elements/PanelChart/PanelChart';

const LabTestsGlycogenManualLoad: React.FC<any> = (props: any) => {

    const { i, form, data, prefix, glycogen, session, recentTest, setRecentTest } = props;

    const test = get(form.values, `${prefix}`);

    const {
        unit, calculateRate, getEstimated,
        glycogenWholeBody, glycogenUpperBody, glycogenLowerBody,
        glycogenPart, glycogenAmount, calculateAmount,
        activity, activitySuffix, activityEstimatedSuffix,
        getEstimatedPace, getEstimatedSwimPace, getEstimatedPower,
        carbUnit, computeCarbBurn, usedBurnRateKey, usedBurnRate
    } = getGlycogenTestData(session, test, recentTest);

    const [options, setOptions] = useState<any>([]);

    const recentLabTests = LabTestsApi.get();

    const [key, setKey] = useState(-1);

    useMountEffect(() => {

        recentLabTests.run({
            params: {
                where: {
                    // date: { lte: dateDay(glycogen.date) },
                    athleteId: session.athleteId
                },
                order: ["date DESC"]
            },
            onComplete: (response) => {

                const options: any = [];

                loop(response.data, (item: any) => {

                    if (!["race-ready", "vo2max", "met"].includes(slug(item.type))) return;

                    options.push({
                        label: [date(item.date), item.type, item.activity, item.intensity].filter(Boolean).join(", "),
                        value: item.id,
                    });

                });

                setOptions(options);

            },
        });

    });

    useEffect(() => {

        let labTest: any = null;

        loop(recentLabTests?.data?.data, (item: any) => {

            if (item.id !== glycogen?.labTestId) return;

            labTest = item

            setKey((key) => key + 1);

        });

        setRecentTest(labTest);

    }, [glycogen.labTestId, recentLabTests.loading]);

    useUpdateEffect(() => {

        form.handleChange({ name: `${prefix}.bodyWeight`, value: fixedNum(get(session, "bio.weight", 0)) })
        form.handleChange({ name: `${prefix}.amountStart`, value: calculateAmount })

    }, [calculateAmount]);

    useUpdateEffect(() => {

        //do not update on mount / first render
        if (key <= 0) return;

        if (recentTest?.type == "Race Ready") {

            const powerArray = get(recentTest, "result.sum.ftp");
            const carbBurnArray = unit == "g/hr" ? get(recentTest, "result.sum.carbBurnG") : get(recentTest, "result.sum.carbBurnKcal");

            let avgPower = 0;
            let avgCarbBurn = 0;

            const loopArray = (array, average) => {

                loop(array, (item, index) => {
                    average += item
                })
                return average

            }

            form.handleChange({ name: `${prefix}.power`, value: loopArray(powerArray, avgPower), reset: true, silent: true })
            form.handleChange({ name: `${prefix}.manualBurnRate`, value: loopArray(carbBurnArray, avgCarbBurn), reset: true, silent: true })

        } else {

            form.handleChange({ name: `${prefix}.power`, value: get(recentTest, "sumAvgData.power_raw.avg"), reset: true, silent: true })
            form.handleChange({ name: `${prefix}.manualBurnRate`, value: unit == "g/hr" ? get(recentTest, "sumAvgData.cho.avg") : get(recentTest, "sumAvgData.eecho.avg"), reset: true, silent: true })

        }

    }, [recentTest, unit]);

    useUpdateEffect(() => {

        loop(get(form.values, `${prefix}.activities`), (item, index) => {

            const activity = get(form.values, `${prefix}.activity`);
            let choosenActivity: string = "Run"
            let totalTime = 0;

            if (activity == "Running") {
                choosenActivity = "Run"
            } else if (activity == "Swimming") {
                choosenActivity = "Swim"
            } else if (activity == "Cycling") {
                choosenActivity = "Bike"
            };

            if (item.activity === choosenActivity) {
                return form.handleChange({ reset: true, name: `${prefix}.targetTime`, value: item.time })
            }

        })

    }, [get(form.values, `${prefix}.activity`)]);

    return useMemo(() => (

        <>

            {!recentLabTests.loading &&

                <>

                    <Row edge gutter={8}>

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldNumber
                                form={form}
                                name={`${prefix}.bodyWeight`}
                                defaultValue={fixedNum(get(session, "bio.weight", 0))}
                                label='Body Weight'
                                suffix={' kg'}
                                wrapClass={"w-140"}
                                disabled
                                hidden
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldNumber
                                form={form}
                                name={`${prefix}.brainUsage`}
                                defaultValue={get(form.values, `${prefix}.brainUsage`)}
                                label='Brain Usage'
                                wrapClass={"w-140 manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldRadio
                                dynamicKey
                                button
                                form={form}
                                label={"Glycogen Load"}
                                name={`${prefix}.displayUnit`}
                                defaultValue={get(form.defaults, `${prefix}.displayUnit`)}
                                singular={true}
                                options={[
                                    { label: "Kcal/hr", value: "kcal/hr" },
                                    { label: "g/hr", value: "g/hr" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button
                                form={form}
                                name={`${prefix}.glycogenPart`}
                                label="Glycogen Load Used"
                                defaultValue={get(form.values, `${prefix}.glycogenPart`)}
                                options={[
                                    { value: "Whole", label: `Whole Body ${glycogenWholeBody} ${unit == "g/hr" ? " g" : " kcal"}` },
                                    { value: "Upper", label: `Upper Body ${glycogenUpperBody} ${unit == "g/hr" ? " g" : " kcal"}` },
                                    { value: "Lower", label: `Lower Body ${glycogenLowerBody} ${unit == "g/hr" ? " g" : " kcal"}` },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <Row gutter={"auto"} edge>

                                <Col >
                                    <FieldNumber
                                        form={form}
                                        name={`${prefix}.percentStart`}
                                        defaultValue={get(form.values, `${prefix}.percentStart`)}
                                        label='Start Load %'
                                        suffix=" %"
                                        wrapClass={"manual-input w-140"}
                                    />
                                </Col>

                                <Col >
                                    <FieldNumber
                                        form={form}
                                        name={`${prefix}.amountStart`}
                                        defaultValue={calculateAmount}
                                        label='Start Load Amount'
                                        suffix={unit == "g/hr" ? " g" : " kcal"}
                                        key={calculateAmount}
                                        wrapClass={"w-140"}
                                        disabled
                                        hidden
                                    />
                                </Col>

                            </Row>

                        </Col>

                    </Row>

                    <Seperator heading="Glycogen Profile" top={"auto"} bottom={"auto"} />

                    <Row edge gutter={8}>

                        <Col col={{ xs: 8 }} >

                            <FieldSelect
                                key={options}
                                form={form}
                                label="Lab Test Data"
                                name={`${prefix}.labTestId`}
                                defaultValue={get(form.values, `${prefix}.labTestId`)}
                                options={options}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 4 }}>

                            <FieldTextTime
                                dynamicKey
                                form={form}
                                label="Target Time"
                                name={`${prefix}.targetTime`}
                                defaultValue={get(form.defaults, `${prefix}.targetTime`)}
                                wrapClass={"w-120 manual-input"}
                            />

                        </Col >

                        <Col col={{ xs: 4 }}>

                            <FieldNumber
                                form={form}
                                label="X-Axis Limit"
                                name={`${prefix}.xAxis`}
                                defaultValue={get(form.values, `${prefix}.xAxis`)}
                                wrapClass={"w-120 manual-input"}
                                suffix=" hrs"
                            />

                        </Col >

                        <Col col={{ xs: 12 }}>

                            <InputTable
                                columns={[
                                    { label: activity == "Cycling" ? "Power" : "Speed", colSpan: 2, alignMiddle: true },
                                    { label: "Carb Burn Rate", colSpan: 2, alignMiddle: true },
                                ]}
                            >

                                <InputTableRow>

                                    <InputTableCell alignMiddle>{activitySuffix}</InputTableCell>
                                    <InputTableCell alignMiddle>{activityEstimatedSuffix}</InputTableCell>
                                    <InputTableCell alignMiddle>Manual</InputTableCell>
                                    <InputTableCell alignMiddle>Estimated</InputTableCell>

                                </InputTableRow>

                                <InputTableRow>

                                    <InputTableCell>

                                        <FieldNumber
                                            key={get(form.defaults, `${prefix}.power`)}
                                            form={form}
                                            name={`${prefix}.power`}
                                            defaultValue={get(form.defaults, `${prefix}.power`)}
                                            suffix={` ${activitySuffix}`}
                                            wrapClass={"manual-input text-center"}
                                            defer={500}
                                        />

                                    </InputTableCell>

                                    <InputTableCell>

                                        <FieldText
                                            form={form}
                                            name={`${prefix}.relativePower`}
                                            defaultValue={(getEstimated(activity) || "0") + " " + activityEstimatedSuffix}
                                            disabled
                                            hidden
                                            key={getEstimated(activity)}
                                            wrapClass={"text-center"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell>

                                        <FieldNumber
                                            key={get(form.defaults, `${prefix}.manualBurnRate`) + unit}
                                            form={form}
                                            name={`${prefix}.manualBurnRate`}
                                            defaultValue={get(form.defaults, `${prefix}.manualBurnRate`)}
                                            suffix={unit == "g/hr" ? " g/hr" : " kcal/hr"}
                                            wrapClass={"manual-input text-center"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell>

                                        <FieldNumber
                                            form={form}
                                            name={`${prefix}.estimatedBurnRate`}
                                            defaultValue={computeCarbBurn}
                                            key={computeCarbBurn || unit}
                                            wrapClass={"text-center"}
                                            suffix={unit == "g/hr" ? " g/hr" : " kcal/hr"}
                                            disabled
                                            hidden
                                        />

                                    </InputTableCell>

                                </InputTableRow>

                                <InputTableRow>

                                    <InputTableCell />

                                    <InputTableCell >
                                        <p style={{ fontWeight: 600, fontSize: 14 }}>Used For Chart:</p>
                                    </InputTableCell>

                                    <InputTableCell>

                                        <FieldCheckbox
                                            dynamicKey
                                            form={form}
                                            name={`${prefix}.burnRateUsed`}
                                            defaultValue={get(form.defaults, `${prefix}.burnRateUsed`)}
                                            singular={true}
                                            options={[
                                                { label: "", value: "manual" }
                                            ]}
                                        />

                                    </InputTableCell>

                                    <InputTableCell>

                                        <FieldCheckbox
                                            dynamicKey
                                            form={form}
                                            name={`${prefix}.burnRateUsed`}
                                            defaultValue={get(form.defaults, `${prefix}.burnRateUsed`)}
                                            singular={true}
                                            options={[
                                                { label: "", value: "estimated" }
                                            ]}
                                        />

                                    </InputTableCell>

                                </InputTableRow>

                            </InputTable>

                        </Col>

                        <Col col={{ xs: 12 }}>

                            <InputTable
                                columns={[
                                    { label: "Fueling", colSpan: 3, alignMiddle: true },
                                ]}
                            >

                                <InputTableRow>

                                    <InputTableCell />
                                    <InputTableCell alignMiddle>%</InputTableCell>
                                    <InputTableCell alignMiddle>Rate</InputTableCell>

                                </InputTableRow>

                                <InputTableRow>

                                    <InputTableCell alignMiddle>
                                        <strong>Min</strong>
                                    </InputTableCell>

                                    <InputTableCell alignMiddle>

                                        <FieldNumber
                                            form={form}
                                            name={`${prefix}.fueling.percentMin`}
                                            defaultValue={get(form.values, `${prefix}.fueling.percentMin`)}
                                            wrapClass={"manual-input w-120 field-center"}
                                            suffix=" %"
                                        />

                                    </InputTableCell>

                                    <InputTableCell alignMiddle>

                                        <FieldNumber
                                            form={form}
                                            name={`${prefix}.fueling.relativeMin`}
                                            defaultValue={calculateRate(usedBurnRate, get(form.values, `${prefix}.fueling.percentMin`))}
                                            wrapClass={"w-120 field-center"}
                                            suffix={unit == "g/hr" ? " g/hr" : " kcal/hr"}
                                            key={calculateRate(usedBurnRate, get(form.values, `${prefix}.fueling.percentMin`))}
                                            disabled
                                            hidden
                                        />

                                    </InputTableCell>

                                </InputTableRow>

                                <InputTableRow>

                                    <InputTableCell alignMiddle>
                                        <strong>Max</strong>
                                    </InputTableCell>

                                    <InputTableCell alignMiddle>

                                        <FieldNumber
                                            form={form}
                                            name={`${prefix}.fueling.percentMax`}
                                            defaultValue={get(form.values, `${prefix}.fueling.percentMax`)}
                                            wrapClass={"manual-input w-120 field-center"}
                                            suffix=" %"
                                        />

                                    </InputTableCell>

                                    <InputTableCell alignMiddle>

                                        <FieldNumber
                                            form={form}
                                            name={`${prefix}.fueling.relativeMax`}
                                            defaultValue={calculateRate(usedBurnRate, get(form.values, `${prefix}.fueling.percentMax`))}
                                            wrapClass={"w-120 field-center"}
                                            suffix={unit == "g/hr" ? " g/hr" : " kcal/hr"}
                                            key={calculateRate(usedBurnRate, get(form.values, `${prefix}.fueling.percentMax`))}
                                            disabled
                                            hidden
                                        />

                                    </InputTableCell>

                                </InputTableRow>

                            </InputTable>

                        </Col>

                    </Row >

                    <Seperator heading={"Energy Depletion During Activity"} top={"auto"} bottom={"auto"} />

                    <LabTestsGlycogenChart
                        form={form}
                        prefix={prefix}
                        unit={unit}
                    />

                    <Seperator heading={"Fueling Rate"} top={"auto"} bottom={"auto"} />

                    <LabTestsGlycogenFuelingRate
                        form={form}
                        prefix={prefix}
                        unit={unit}
                    />

                </>

            }

        </>

    ), [form.hash, recentLabTests.loading, data, session, options, computeCarbBurn, key]);

}

const LabTestsGlycogenChart: React.FC<any> = (props: any) => {

    const { form, prefix, unit } = props;

    const test = get(form.values, `${prefix}`);

    const {
        chartData, tableMax, tableMin,
        burnRateUsed, fuelingRateMin, fuelingRateMax,
        activity, targetTime
    } = getGlycogenChartData(test);

    useEffect(() => {
        form.handleChange({ value: chartData, name: `${prefix}.chart.chartData` })
        form.handleChange({ value: tableMin, name: `${prefix}.chart.tableMin` })
        form.handleChange({ value: tableMax, name: `${prefix}.chart.tableMax` })
    }, [chartData, tableMax, tableMin]);

    return (
        <>
            <Space />

            <PanelChart
                key={hashCode(chartData)}
                heading={activity}
                addZero={false}
                data={chartData}
                chartKey={form.hash}
                renderChart={(chartType, chartData) => (
                    <>
                        <ChartGlycogen
                            data={chartData}
                            activity={activity}
                            targetTime={targetTime}
                            fuelingRateMax={fuelingRateMax}
                            fuelingRateMin={fuelingRateMin}
                            unit={unit}
                        />
                    </>

                )}
            />
        </>
    )
}

const LabTestsGlycogenFuelingRate: React.FC<any> = (props: any) => {

    const { form, prefix, unit } = props;

    const {
        fuelingRateMin, fuelingRateMax,
        decimalTargetTime, recommendedRate,
        bonkMax, bonkMin, computeCarbNeeded,
        bonkMinHour, bonkMaxHour
    } = getFuelingRateData(get(form.values, `${prefix}`));

    useEffect(() => {
        form.handleChange({ name: `${prefix}.bonk.sufficientMin`, value: checkBonkGreaterThanTarget(decimalTargetTime, bonkMinHour) })
        form.handleChange({ name: `${prefix}.bonk.sufficientMax`, value: checkBonkGreaterThanTarget(decimalTargetTime, bonkMaxHour) })
    }, [decimalTargetTime, bonkMaxHour, bonkMinHour]);

    const xAxis = get(form.values, `${prefix}.xAxis`);
    const minBonkTime = getBonkTime(bonkMin, xAxis);
    const maxBonkTime = getBonkTime(bonkMax, xAxis);

    return (

        <Row edge gutter={"auto"}>

            <Col col={{ xs: 24, md: 12 }}>

                <InputTable
                    columns={[
                        { label: "Fueling Rate" },
                        { label: "Time To Bonk - hh:mm" },
                        { label: "Fuel Rate Sufficient?" },
                    ]}
                >

                    <InputTableRow>

                        <DataTableCell>
                            <strong>{fuelingRateMin} {unit}</strong>
                        </DataTableCell>

                        <DataTableCell>

                            {minBonkTime.totalHours ? getClockTime(minBonkTime.totalHours) : "> " + xAxis + " hrs"}

                            <FieldHidden
                                form={form}
                                name={`${prefix}.bonk.timeMin`}
                                value={minBonkTime.totalHours}
                            />

                        </DataTableCell>

                        <InputTableCell>

                            <FieldText
                                form={form}
                                name={`${prefix}.bonk.sufficientMin`}
                                defaultValue={checkBonkGreaterThanTarget(decimalTargetTime, bonkMinHour)}
                                key={checkBonkGreaterThanTarget(decimalTargetTime, bonkMinHour)}
                                disabled
                                hidden
                            />

                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <DataTableCell>
                            <strong>{fuelingRateMax} {unit}</strong>
                        </DataTableCell>

                        <DataTableCell>

                            {maxBonkTime.totalHours ? getClockTime(maxBonkTime.totalHours) : "> " + xAxis + " hrs"}

                            <FieldHidden
                                form={form}
                                name={`${prefix}.bonk.timeMax`}
                                value={maxBonkTime.totalHours}
                            />

                        </DataTableCell>

                        <InputTableCell>

                            <FieldText
                                form={form}
                                name={`${prefix}.bonk.sufficientMax`}
                                defaultValue={checkBonkGreaterThanTarget(decimalTargetTime, bonkMaxHour)}
                                key={checkBonkGreaterThanTarget(decimalTargetTime, bonkMaxHour)}
                                disabled
                                hidden
                            />

                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <DataTableCell colSpan="2">
                            Reconmended Fueling Rate:
                        </DataTableCell>

                        <InputTableCell>

                            <FieldNumber
                                form={form}
                                name={`${prefix}.bonk.recommendedRate`}
                                defaultValue={get(form.values, `${prefix}.bonk.recommendedRate`, 0)}
                                wrapClass={"manual-input"}
                                suffix={` ${unit}`}
                            />

                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <DataTableCell colSpan="2">
                            Total Carbs Needed For Activity:
                        </DataTableCell>

                        <InputTableCell>

                            <FieldNumber
                                form={form}
                                name={`${prefix}.bonk.totalCarb`}
                                defaultValue={computeCarbNeeded}
                                key={computeCarbNeeded}
                                suffix={unit == "g/hr" ? " g" : " kcal"}
                                disabled
                                hidden
                            />

                        </InputTableCell>

                    </InputTableRow>

                </InputTable>

            </Col>

            <Col col={{ xs: 24, md: 12 }}>
                <GlycogenFueling
                    unit={unit}
                    recommendedRate={recommendedRate}
                />
            </Col>

        </Row>
    )
}

export default LabTestsGlycogenManualLoad;
