import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import { Button, ButtonRow, Seperator } from '@dex/bubl-dash';
import EditableContext from '../../../context/EditableContext';
import FieldSessionssGeneralForm from './FieldSessionsGeneralForm';
import FieldSessionsSingleAthletes from './FieldSessionsSingleAthletes';

const FieldSessionsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>
            <Row
                gutter={'auto'}
            >

                <Col
                    // className={'main'}
                    col={{ xs: 24 }}
                >

                    <Panel
                        heading={"Field Test Sessions Details"}
                        actions={() => (
                            <ButtonRow>
                                <Button
                                    label="Copy To New"
                                    type="faded"
                                    size="small"
                                    path={`/field/field-sessions/create?cloneId=${data.id}`}
                                />

                                <Button
                                    label="Edit Field Session"
                                    type="faded"
                                    size="small"
                                    path={`/field/field-sessions/${data.id}/edit/info`}
                                />
                            </ButtonRow>
                        )}
                    >

                        <EditableContext.Provider value={true}>

                            <FieldSessionssGeneralForm
                                data={data}
                            />

                        </EditableContext.Provider>

                        <FieldSessionsSingleAthletes
                            data={data}
                        />

                    </Panel>

                </Col>

            </Row>
        </>
    ), [data]);

}

export default FieldSessionsSingleView;
