import React, { Suspense, useCallback, useContext, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldList from '@dex/bubl-dash/src/lib/components/Fields/FieldList';
import FieldPopup from '@dex/bubl-dash/src/lib/components/Fields/FieldPopup';
import SessionsEditTabsSizingDetails from '../Sizing/SessionsEditTabsSizingDetails';
import SessionsEditTabsSizingPosture from '../Sizing/SessionsEditTabsSizingPosture';
import SessionsEditTabsSizingSetup from '../Sizing/SessionsEditTabsSizingSetup';
import SessionsEditTabsSizingPictures from '../Sizing/SessionsEditTabsSizingPictures';
import SessionsEditTabsSizingSummary from '../Sizing/SessionsEditTabsSizingSummary';
import SessionsEditTabsSizingFinal from '../Sizing/SessionsEditTabsSizingFinal';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import SessionsEditTabsSizingIndex from '../Sizing/SessionsEditTabsSizingIndex';
import { bikeLabel } from '../../../functions/sharedBikeFit';
import EditableContext from '../../../context/EditableContext';

const SessionsEditTabsSizingRetul = React.lazy( () => import( '../Sizing/SessionsEditTabsSizingRetul' ) );
const SessionsEditTabsSizingCompare = React.lazy( () => import( '../Sizing/SessionsEditTabsSizingCompare' ) );

const SessionsEditTabsSizing: React.FC<any> = ( props: any ) => {

    const { form, process, handleSubmit, data } = props;

    return useMemo( () => (

        <SessionsEditTabsPanel
            heading={"Bike Sizing"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldList
                        form={form}
                        name={`bikes`}
                        label={"Bikes"}
                        addButtonLabel={"Add Bike"}
                        defaultValues={{}}
                    >

                        {( form.values.bikes || [] ).map( ( bike, i ) => (

                            <SessionsEditTabsSizingForm
                                key={i}
                                i={i}
                                bike={bike}
                                form={form}
                                data={data}
                            />

                        ) )}

                    </FieldList>

                </Col>

            </Row>

        </SessionsEditTabsPanel>


    ), [ props ] );

}

const SessionsEditTabsSizingForm: React.FC<any> = ( props: any ) => {

    const { i, bike, form, data } = props;

    const prefix = `bikes[${ i }]`;

    const [ key, setKey ] = useState( 0 );

    const [ tab, setTab ] = useState( "details" );

    const handleCopy = useCallback( ( handleClose ) => {

        const items = form.getValue( 'bikes' );

        handleClose();

        items.push( structuredClone( bike ) );

        form.handleChange( { name: 'bikes', value: items } );

        form.setKey();

    }, [ bike ] );

    const upKey = useCallback( () => {

        setKey( key + 1 );

    }, [ key ] );

    const editable = useContext( EditableContext );

    return (

        <FieldPopup
            buttonLabel={"Bike " + ( i + 1 ) + " - " + bikeLabel( bike )}
            heading={"Bike " + ( i + 1 ) + " - " + bikeLabel( bike )}
            form={form}
            name={prefix}
            modalProps={{
                className: 'modal-top'
            }}
            actionButtons={( handleClose ) => (

                <Button
                    label="Copy To New Bike"
                    size="small"
                    type={"faded"}
                    onClick={handleCopy.bind( null, handleClose )}
                />

            )}
            panelProps={{
                tabs: [
                    { label: "Details", name: "details" },
                    bike.type && !bike.isRetul ? { label: "Sizing", name: "setup" } : null,
                    bike.type && bike.isRetul ? { label: "Posture", name: "posture" } : null,
                    bike.type && bike.isRetul ? { label: "RETUL - Posture", name: "retul" } : null,
                    bike.type && bike.isRetul ? { label: "RETUL - Bike", name: "final" } : null,
                    bike.type && bike.isRetul ? { label: "Pictures", name: "pictures" } : null,
                    bike.type && bike.isRetul ? { label: "Compare", name: "compare" } : null,
                    bike.type && bike.isRetul ? { label: "Index", name: "index" } : null,
                    bike.type && !bike.isRetul ? { label: "Comments", name: "summary" } : null,
                ].filter( Boolean ),
                setTab: setTab,
                activeTab: tab
            }}
        >

            <div key={key} className={!editable ? 'form-not-editable' : ''}>

                <div className={tab === "details" ? "" : "hidden"}>

                    <SessionsEditTabsSizingDetails
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "setup" ? "" : "hidden"}>

                    <SessionsEditTabsSizingSetup
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "posture" ? "" : "hidden"}>

                    <SessionsEditTabsSizingPosture
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "retul" ? "" : "hidden"}>

                    <Suspense fallback={<></>}>

                        <SessionsEditTabsSizingRetul
                            i={i}
                            form={form}
                            data={data}
                            bike={bike}
                            prefix={prefix}
                            setKey={upKey}
                        />

                    </Suspense>

                </div>

                <div className={tab === "final" ? "" : "hidden"}>

                    <SessionsEditTabsSizingFinal
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "pictures" ? "" : "hidden"}>

                    <SessionsEditTabsSizingPictures
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "compare" ? "" : "hidden"}>

                    <Suspense fallback={<></>}>

                        <SessionsEditTabsSizingCompare
                            i={i}
                            form={form}
                            data={data}
                            bike={bike}
                            prefix={prefix}
                            setKey={upKey}
                        />

                    </Suspense>

                </div>

                <div className={tab === "index" ? "" : "hidden"}>

                    <SessionsEditTabsSizingIndex
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "summary" ? "" : "hidden"}>

                    <SessionsEditTabsSizingSummary
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

            </div>

        </FieldPopup>

    )

}

export default SessionsEditTabsSizing;
