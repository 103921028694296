import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldHidden, FieldNumber, FieldTextTime, InputTable, InputTableCell, InputTableRow, Space, useMountEffect, useUpdateEffect } from '@dex/bubl-dash';
import { fixedNum, get, hashCode, loop, parseNum } from '@dex/bubl-helpers';
import { labTestMergeData } from '../../../functions/labTestData';
import * as _ from 'lodash';

const LabTestsSprintManual: React.FC<any> = (props: any) => {

    const { form, data, session } = props;

    const activity = form.values.activity;

    // useEffect(() => {

    //     const manualData = form.values.manualData || [];
    //     const avgData = form.values.avgData || [];

    //     const data = labTestMergeData(avgData, manualData);

    //     form.handleChange({ silent: true, name: `avgData`, value: data });

    // }, [JSON.stringify(form.values.manualData)])

    const stages: any = [];

    const noOfStages = 11;

    let n = 1;

    while (n <= noOfStages) {

        stages.push(n)

        n++;

    }

    let columns: any = [
        {
            key: "stage",
            label: "Stage",
            desc: "[#]"
        },
        {
            key: "speed",
            label: activity == "Running" ? "Speed" : "Power",
        },
        {
            key: "timeStage",
            label: "Time",
            desc: "[mm:ss]"
        },
        {
            key: "lactate",
            label: "Lactate",
            desc: " [mmol/L]"
        },
        {
            key: "manual.rpe",
            label: "RPE",
            desc: "[#]"
        },
    ];

    if (activity === 'Cycling') {

        columns = [
            ...columns,
            {
                key: "empty",
                label: "",
            },
        ];

    }

    if (activity === 'Running') {

        columns = [
            ...columns,
            // {
            //     key: "kph",
            //     label: "KPH",
            // },
            // {
            //     key: "gradePercentage",
            //     label: "Grade %",
            // },
            {
                key: "timeRev",
                label: "Time Rev (sec)",
            },
            {
                key: "timeStrides",
                label: "Time Strides (sec)",
            },
            {
                key: "strideFrequency",
                label: "Stride Frequency (per min)",
            },
            {
                key: "treadmillSpeed",
                label: "Treadmill Speed (kph)",
            },
        ]

    } else if (activity === 'Cycling') {

        columns = [
            ...columns,
        ]

    }

    const stageInput = (key: string, index, args?: any) => {

        let hidden = false;
        let defaultValue = get(form.defaults, `manualData[${index}].${key}`, null);

        if (key === "empty") {

            return (
                <InputTableCell style={{ width: 200 }}>
                </InputTableCell>
            );

        }

        if (key === "strideFrequency" || key === "treadmillSpeed") {

            hidden = true;
            defaultValue = "";

            const rev = parseNum(form.values.manual.revolutions || 0);
            const belt = parseNum(form.values.manual.beltLength || 0);

            let timeRev = parseNum(get(form.values, `manualData[${index}].timeRev`, 0));
            let timeStrides = parseNum(get(form.values, `manualData[${index}].timeStrides`, 0));

            if (key === "strideFrequency") {

                if (rev && timeStrides)
                    defaultValue = fixedNum((rev * 60) / timeStrides, 0);

            } else if (key === "treadmillSpeed") {

                if (belt && timeRev)
                    defaultValue = fixedNum(((rev * belt) / timeRev) * 3.6, 1);

            }

            if (isNaN(defaultValue) || !isFinite(defaultValue)) defaultValue = "";

        }

        return (

            <InputTableCell style={{ width: 100 }}>

                {hidden &&
                    <>
                        <FieldHidden
                            form={form}
                            name={`manualData[${index}].${key}`}
                            defaultValue={defaultValue}
                        />
                        <span>&nbsp;</span> {defaultValue}
                    </>
                }

                {!hidden &&
                    <FieldNumber
                        form={form}
                        name={`manualData[${index}].${key}`}
                        defaultValue={defaultValue}
                        allowNegative={true}
                        wrapClass={"manual-input"}
                        {...args}
                        defer={500}
                    />
                }

            </InputTableCell>

        );

    };

    const calculateVlamax = (max, pre, sprint) => {

        return fixedNum((max - pre) / sprint, 2)

    };

    const getPeakLactate = (manualData) => {

        let max = fixedNum(manualData[0]?.lactate || 0);
        let maxIndex = 0;

        manualData.map((item, index) => {

            let num = fixedNum(item?.lactate);

            if (num > max) {
                max = num;
                maxIndex = index
            }
        });

        return { max, maxIndex };

    };

    useEffect(() => {

        const manualData = form.values.manualData;
        const rollingData = form.values.rollingData;

        const { max, maxIndex } = getPeakLactate(manualData);
        const sprintTime = rollingData[rollingData.length - 1]?.time;

        form.handleChange({ reset: true, name: `manual.peakLactate`, value: max });

        form.handleChange({ reset: true, name: `manual.timePeakLactate`, value: manualData[maxIndex]?.manualTime });

        form.handleChange({ reset: true, name: `manual.sprintTime`, value: sprintTime });

        form.handleChange({ reset: true, name: `manual.vlaMax`, value: calculateVlamax(max, manualData[0]?.lactate, sprintTime) });

    }, [hashCode(form.values.manualData), props]);

    return useMemo(() => (

        <>

            {activity === 'Running' &&
                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldNumber
                            form={form}
                            label={"Revolutions (#)"}
                            name={"manual.revolutions"}
                            defaultValue={form.defaults?.manual?.revolutions}
                            allowNegative
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldNumber
                            form={form}
                            label={"Belt Length (m)"}
                            name={"manual.beltLength"}
                            suffix={" M"}
                            defaultValue={form.defaults?.manual?.beltLength}
                            allowNegative
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Space height={8} />
                    </Col>

                </Row>
            }

            <InputTable columns={columns}>

                {stages.map((stage, index) => {

                    return (

                        <InputTableRow key={index}>

                            <InputTableCell style={{ width: 10 }}>
                                {index + 1}
                            </InputTableCell>

                            <InputTableCell style={{ width: 10 }}>
                                {index == 0 ? "Pre-Lactate" : `Post-Lactate ${index}`}
                            </InputTableCell>

                            <InputTableCell style={{ width: 100 }}>

                                <FieldTextTime
                                    form={form}
                                    name={`manualData[${index}].manualTime`}
                                    defaultValue={get(form.defaults, `manualData[${index}].manualTime`)}
                                    placeholder={"mm:ss"}
                                    wrapClass={"manual-input"}
                                />

                            </InputTableCell>

                            {stageInput("lactate", index)}

                            <InputTableCell style={{ width: 100 }}>

                                {index == 0 &&

                                    <FieldNumber
                                        form={form}
                                        name={`manualData[${index}].rpe`}
                                        defaultValue={get(form.defaults, `manualData[${index}].rpe`)}
                                        allowNegative={true}
                                        wrapClass={"manual-input"}
                                        defer={500}
                                    />

                                }

                            </InputTableCell>

                            {activity === 'Cycling' &&

                                <>
                                    {stageInput("empty", index)}
                                </>

                            }

                            {activity === 'Running' &&

                                <>

                                    {/* {stageInput("kph", index)} */}

                                    {/* {stageInput("gradePercentage", index)} */}

                                    {stageInput("timeRev", index)}

                                    {stageInput("timeStrides", index)}

                                    {stageInput("strideFrequency", index)}

                                    {stageInput("treadmillSpeed", index)}

                                </>

                            }

                        </InputTableRow>

                    )

                })

                }

            </InputTable>

            <Col col={{ xs: 12 }}>

                <FieldNumber
                    dynamicKey
                    form={form}
                    name={`manual.peakLactate`}
                    defaultValue={form.defaults.manual?.peakLactate}
                    label='Peak Lactate'
                    allowNegative={true}
                    suffix=" mmol/L"
                    wrapClass={"inline-label w-500"}
                />

                <FieldNumber
                    dynamicKey
                    form={form}
                    name={`manual.sprintTime`}
                    defaultValue={form.defaults.manual?.sprintTime}
                    label='Total Sprint Time'
                    suffix=" s"
                    wrapClass={"inline-label w-500"}
                />

                <FieldNumber
                    dynamicKey
                    form={form}
                    name={`manual.vlaMax`}
                    defaultValue={form.defaults.manual?.vlaMax}
                    label='VlaMAX'
                    allowNegative={true}
                    suffix=" mmol/L/sec"
                    wrapClass={"inline-label w-500"}
                />

                <FieldTextTime
                    dynamicKey
                    form={form}
                    name={`manual.timePeakLactate`}
                    defaultValue={form.defaults.manual?.timePeakLactate}
                    label='Time At Peak Lactate'
                    wrapClass={"inline-label w-500"}
                    placeholder="mm:ss"
                />

            </Col>
        </>

    ), [activity, stages, form.hash]);

}

export default LabTestsSprintManual;
