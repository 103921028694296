import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldRelationship, FieldDate, FieldAutocomplete, FieldSelect, FieldText, FieldCheckbox } from '@dex/bubl-dash';


const FieldSessionsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Field Test Sessions Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldRelationship
                            form={form}
                            preload={true}
                            endpoint="teams"
                            name="teamId"
                            label="Team"
                            required={true}
                            defaultValue={form.defaults.teamId}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldDate
                            form={form}
                            name={'sessionDate'}
                            label={'Session Date'}
                            required={true}
                            defaultValue={form.defaults.sessionDate}
                            date
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldText
                            form={form}
                            name="period"
                            label="Test Period"
                            defaultValue={form.defaults.period}
                            required={true}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldRelationship
                            form={form}
                            name="fitterIds"
                            label="Appraisers"
                            required={true}
                            defaultValue={form.defaults.fitterIds}
                            preload={true}
                            endpoint={'Fitters'}
                            isMulti={true}
                        />

                    </Col>

                </Row>

                <Line />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldAutocomplete
                            form={form}
                            label={"Main Sport"}
                            name='mainSport'
                            defaultValue={form.defaults.mainSport}
                            group={"mainSport"}
                            options={[
                                { value: "Cycling", label: "Cycling" },
                                { value: "Triathlon", label: "Triathlon" },
                                { value: "Running", label: "Running" },
                                { value: "Swimming", label: "Swimming" },
                                { value: "Football", label: "Football" },
                                { value: "Rugby", label: "Rugby" },
                                { value: "Basketball", label: "Basketball" },
                                { value: "Cricket", label: "Cricket" },
                                { value: "Gymnastics", label: "Gymnastics" },
                            ]}
                            sort={false}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldAutocomplete
                            key={form.values.mainSport}
                            form={form}
                            name="discipline"
                            label="Discipline"
                            defaultValue={form.defaults.discipline}
                            group={"discipline." + form.values.mainSport}
                            preload={true}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldSelect
                            form={form}
                            name="level"
                            label="Level"
                            defaultValue={form.defaults.level}
                            options={[
                                { value: "national", label: "National" },
                                { value: "state", label: "State" },
                                { value: "junior", label: "Junior" },
                                { value: "amateur", label: "Amateur" },
                                { value: "club", label: "Club" },
                                { value: "novice", label: "Novice" },
                                { value: "age-grouper", label: "Age-Grouper" },
                                { value: "recreational", label: "Recreational" },
                                { value: "other", label: "Other" }
                            ]}
                        />

                    </Col>

                </Row>

                <Line />

                {mode === "create" &&
                    <>

                        <FieldRelationship
                            form={form}
                            name="id"
                            label={`Copy Test Selection & Report Format From Past Session`}
                            defaultValue={form.defaults.id}
                            preload={true}
                            isMulti={false}
                            endpoint="FieldSessions"
                        />

                        <Line />

                    </>
                }


                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default FieldSessionsCreateEditForm;
