import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';

const SessionsEditTabsBikeSummary: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ md: 8, xs: 24 }}>

                <FieldSelect
                    form={form}
                    label={"Frame Suitability"}
                    name={`${prefix}.frameSuitability`}
                    required={false}
                    defaultValue={bike.frameSuitability}
                    options={[
                        { value: "Adequate", label: "Adequate" },
                        { value: "Overall Too Large", label: "Overall Too Large" },
                        { value: "Overall Too Small", label: "Overall Too Small" },
                        { value: "Stack Too Low", label: "Stack Too Low" },
                        { value: "Stack Too High", label: "Stack Too High" },
                        { value: "Reach Too Short", label: "Reach Too Short" },
                        { value: "Reach Too Long", label: "Reach Too Long" },
                        { value: "Not Assessed", label: "Not Assessed" }
                    ]}
                />

            </Col>

            <Col col={{ md: 8, xs: 24 }}>

                <FieldSelect
                    form={form}
                    label={"Crank Suitability"}
                    name={`${prefix}.crankSuitability`}
                    required={false}
                    defaultValue={bike.crankSuitability}
                    options={[
                        { value: "Adequate", label: "Adequate" },
                        { value: "Long For You", label: "Long For You" },
                        { value: "Short For You", label: "Short For You" },
                        { value: "Not Assessed", label: "Not Assessed" }
                    ]}
                />

            </Col>

            <Col col={{ md: 8, xs: 24 }}>

                <FieldSelect
                    form={form}
                    label={"Bar Suitability"}
                    name={`${prefix}.barSuitability`}
                    required={false}
                    defaultValue={bike.barSuitability}
                    options={[
                        { value: "Adequate", label: "Adequate" },
                        { value: "Wide", label: "Wide" },
                        { value: "Narrow", label: "Narrow" },
                        { value: "Aeropads at Hip Width", label: "Aeropads at Hip Width" },
                        { value: "Aeropads Wide", label: "Aeropads Wide" },
                        { value: "Aeropads Narrow", label: "Aeropads Narrow" },
                        { value: "Not Assessed", label: "Not Assessed" }
                    ]}
                />

            </Col>

            <Col col={{ xs: 24 }}>
                <Line />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Comments"}
                    name={`${prefix}.comments`}
                    defaultValue={bike.comments}
                    rows={4}
                    maxLength={640}
                />
            </Col>

        </Row>

    ), [props]);

}

export default SessionsEditTabsBikeSummary;
