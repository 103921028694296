import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../../Chart/Chart';
import ChartProps from '../../Chart/Chart.Props';
import * as _ from 'lodash';
import ChartAxis from '../../Chart/ChartAxis';

import styles from "./PerformanceChart.module.scss";
import { fixedNum } from '@dex/bubl-helpers';
import { nearestMaxTen } from '../../../functions/sharedChart';

const PerformanceChart: React.FC<PerformanceChartProps> = (props) => {

    const { data, units, isTeam, athleteCount, options, decimalPlace } = props;

    const Axis = new ChartAxis();

    // @ts-ignore
    Axis.setXByActivity("history");

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    const label = isTeam ? `Team Score` : `Your Score`;

    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 0,
        key: "score",
        label: `${label}`,
        axisLabel: units ? `${label} - ${units}` : `${label}`,
        props: {
            dot: { strokeWidth: 6, fill: ChartProps.indexColor(0) },
            tickFormatter: (val) => { return fixedNum(val, decimalPlace) }
            // domain: ["dataMin", "dataMax"],
        }
    });

    if (!isTeam) {

        Axis.y.push({
            axis: true,
            circle: true,
            pos: "right",
            index: 2,
            key: "ranking",
            label: "Ranking",
            props: {
                dot: { strokeWidth: 6, fill: ChartProps.indexColor(2) },
                domain: [0, nearestMaxTen(athleteCount)],
            }
        });

    }

    return (

        <>
            <Chart>

                <LineChart
                    {...ChartProps.LineChart()}
                    data={data}
                >

                    {Axis.render()}

                </LineChart>

            </Chart>
        </>

    )

}

interface PerformanceChartProps {
    [key: string]: any,
}

export default PerformanceChart;