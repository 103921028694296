import React, { useCallback, useState } from 'react';
import { Box, Button, ButtonRow, Col, Form, Line, Row, Seperator, Space, useUpdateEffect } from '@dex/bubl-dash';
import TabBody from '../../../elements/TabBody/TabBody';
import CoachingTrainingsWeekVolTab from './CoachingTrainingsWeekVolTab';
import CoachingTrainingsWeekRPETab from './CoachingTrainingsWeekRPETab';
import CoachingTrainingsWeekCommentsTab from './CoachingTrainingsWeekCommentsTab';
import CoachingTrainingGenericForm from './CoachingTrainingGenericForm';
import { getWeek } from '../../../functions/sharedCoaching';
import CoachingTrainingsBestProvider from './CoachingTrainingsBestProvider';
import CoachingTrainingsWeekHighlightsTab from './CoachingTrainingsWeekHighlightsTab';
import CoachingTrainingsWeekTrainingZoneTab from './CoachingTrainingsWeekTrainingZoneTab';

const CoachingTrainingsWeekForm: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit, mode, del, handleClose, app } = props;

    const [tab, setTab] = useState("volume");

    const tabs = [
        { label: "Vol/Int", name: "volume" },
        { label: "RPE", name: "rpe" },
        { label: "Highlights", name: "highlights" },
        { label: "Comments", name: "comment" },
        { label: "Training Zone", name: "trainingZone" },
    ]

    const { week, block, weeks } = getWeek(form, data);

    useUpdateEffect(() => {

        form.handleChange({ name: "week.phase", value: block.phase, reset: true })
        form.handleChange({ name: "week.block", value: block.block, reset: true })
        form.handleChange({ name: "week.blockNum", value: block.blockNum, reset: true })
        form.handleChange({ name: "week.week", value: week.week, reset: true })
        form.handleChange({ name: "week.startDate", value: week.startDate, reset: true })
        form.handleChange({ name: "week.endDate", value: week.endDate, reset: true })
        form.handleChange({ name: "week.eventId", value: week.event, reset: true })

    }, [week, block])

    return (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <CoachingTrainingGenericForm
                form={form}
                data={data}
                mode={mode}
                week={week}
                block={block}
                weeks={weeks}
            />

            {week && week.week &&

                <>

                    <CoachingTrainingsBestProvider
                        type={"Weeks"}
                        data={data}
                        form={form}
                        week={week}
                        endDate={week?.endDate}
                        values={form.values}
                    >

                        <Seperator
                            heading={
                                <Row edge >
                                    <Col col={{ xs: 16 }} >
                                        {"Week " + week.calendar + " Data"}
                                    </Col>

                                    <Col col={{ xs: 8 }} align='end'>
                                        <ButtonRow>
                                            <Button
                                                label={"Save"}
                                                loading={process.loading}
                                                onClick={form.triggerSubmit}
                                                htmlType={"button"}
                                                size="regular"
                                            />

                                            <Button
                                                label={"Close"}
                                                onClick={() => handleClose()}
                                                type='light'
                                                size="regular"
                                            />
                                        </ButtonRow>
                                    </Col >
                                </Row>
                            }
                            tabs={tabs}
                            activeTab={tab}
                            setTab={setTab}
                        />

                        <Space />

                        <Box gutter={8} edge>

                            <TabBody
                                form={form}
                                active={tab === "volume"}
                                fields={["volume", "intensity", "other"]}
                            >

                                <CoachingTrainingsWeekVolTab
                                    data={data}
                                    form={form}
                                    week={week}
                                    weeks={weeks}
                                    block={block}
                                    mode={mode}
                                />

                            </TabBody>

                            <TabBody
                                form={form}
                                active={tab === "rpe"}
                                fields={["rpe"]}
                            >

                                <CoachingTrainingsWeekRPETab
                                    data={data}
                                    form={form}
                                    week={week}
                                    weeks={weeks}
                                    block={block}
                                    mode={mode}
                                />

                            </TabBody>

                            <TabBody
                                form={form}
                                active={tab === "trainingZone"}
                                fields={["trainingZone"]}
                            >

                                <CoachingTrainingsWeekTrainingZoneTab
                                    data={data}
                                    form={form}
                                    week={week}
                                    weeks={weeks}
                                    block={block}
                                    mode={mode}
                                />

                            </TabBody>

                            <TabBody
                                form={form}
                                active={tab === "highlights"}
                                fields={["highlights"]}
                            >

                                <CoachingTrainingsWeekHighlightsTab
                                    data={data}
                                    form={form}
                                    week={week}
                                    weeks={weeks}
                                    block={block}
                                    mode={mode}
                                />

                            </TabBody>

                            <TabBody
                                form={form}
                                active={tab === "comment"}
                                fields={["comments"]}
                            >

                                <CoachingTrainingsWeekCommentsTab
                                    data={data}
                                    form={form}
                                    week={week}
                                    weeks={weeks}
                                    block={block}
                                    mode={mode}
                                />

                            </TabBody>

                        </Box>

                    </CoachingTrainingsBestProvider>

                    <Line />

                    <Row edge gutter={8}>

                        <Col col={{ xs: 21 }}>

                            <ButtonRow>

                                <Button
                                    label={"Save"}
                                    loading={process.loading}
                                    onClick={form.triggerSubmit}
                                    htmlType={"button"}
                                />


                                <Button
                                    label={"Close"}
                                    onClick={() => handleClose()}
                                    type='faded'
                                />
                            </ButtonRow>

                        </Col>

                        {mode == "edit" &&
                            <Col col={{ xs: 3 }} align='end'>
                                <Button
                                    confirm={{
                                        title: `Delete ${form.values.id}`,
                                        message: "Are your sure you want to perform this action?"
                                    }}
                                    onClick={del.run}
                                    label="Delete"
                                    type='faded'
                                />
                            </Col>
                        }

                    </Row>

                </>

            }

        </Form>

    )
}

export default CoachingTrainingsWeekForm
