import React, { useEffect, useMemo } from 'react';
import { Row, Col, } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, DataTableCell, FieldHidden, FieldNumber, FieldText, InputTable, InputTableCell, InputTableRow, Space, useMountEffect, useUpdateEffect } from '@dex/bubl-dash';
import { fixedNum, get, hashCode, loop, parseNum } from '@dex/bubl-helpers';

const CoachingTrainingsWeekRPETab: React.FC<any> = ( props: any ) => {

    const { form, data, week, weeks, block } = props;

    return (

        <>

            <Row
                gutter={8}
                edge
            >

                <Col col={{ xs: 12, lg: 12 }}>

                    <WorkoutTable
                        data={data}
                        form={form}
                    />

                </Col>

                <Col col={{ xs: 12, lg: 12 }}>

                    <CalculatedTable
                        data={data}
                        form={form}
                    />

                </Col>

            </Row>

        </>

    )
}

const WorkoutTable: React.FC<any> = ( props: any ) => {

    const { data, form } = props;

    const column = [
        {
            key: "workout",
            style: { width: "80px" }
        },
        {
            label: "Descriptions",
            key: "specific",
            style: { width: "100px" }
        },
        {
            key: "RPE",
            label: "RPE",
            style: { width: "40px" },
            alignMiddle: true,
        },
        {
            key: "felt",
            label: "FELT",
            style: { width: "40px" },
            alignMiddle: true,
        }
    ];

    const workouts = form.values.rpe?.workouts;
    const workoutsCompleted = form.values.rpe?.workoutsCompleted;

    useEffect( () => {
        let value = Array.from( { length: 10 }, () => ( {} ) );

        if ( workoutsCompleted ) {
            value = Array.from( { length: workoutsCompleted }, ( _, index ) => ( {
                ...( ( workouts && workouts[ index ] ) || {} )
            } ) );
        };

        form.handleChange( { reset: true, name: "rpe.workouts", value: value } );

    }, [ workoutsCompleted ] );

    const addWorkout = () => {

        workouts.push( {} );
        form.setKey();

    };

    return (
        <>
            <InputTable
                columns={column}
            >

                {form.values.rpe?.workouts?.map( ( item, index ) => (
                    <InputTableRow key={index}>

                        <DataTableCell>
                            <strong>{`Workout ${ ( index + 1 ) }`}</strong>
                            <FieldHidden
                                form={form}
                                name={`rpe.workouts[${ index }].title`}
                                value={`Workout ${ ( index + 1 ) }`}
                            />
                        </DataTableCell>

                        <InputTableCell>

                            <FieldText
                                form={form}
                                name={`rpe.workouts[${ index }].specific`}
                                defaultValue={get( form.values, `rpe.workouts[${ index }].specific` )}
                                wrapClass={"manual-input"}
                                disabled={index >= form.values?.rpe?.workoutsCompleted}
                            />

                        </InputTableCell>

                        <InputTableCell align={"center"}>

                            <FieldNumber
                                form={form}
                                name={`rpe.workouts[${ index }].rpe`}
                                defaultValue={get( form.values, `rpe.workouts[${ index }].rpe` )}
                                wrapClass={"manual-input w-60"}
                                disabled={index >= form.values?.rpe?.workoutsCompleted}
                                max={10}
                            />

                        </InputTableCell>

                        <InputTableCell align={"center"}>

                            <FieldNumber
                                form={form}
                                name={`rpe.workouts[${ index }].felt`}
                                defaultValue={get( form.values, `rpe.workouts[${ index }].felt` )}
                                wrapClass={"manual-input w-60"}
                                disabled={index >= form.values?.rpe?.workoutsCompleted}
                                max={10}
                            />

                        </InputTableCell>

                    </InputTableRow>

                ) )}

            </InputTable>

            {/* <Space />

            <Button
                label="Add Workout"
                type={"light"}
                size={"small"}
                onClick={() => { addWorkout() }}

            /> */}
        </>

    )
}

const CalculatedTable: React.FC<any> = ( props: any ) => {

    const { data, form } = props;

    const column = [
        {
            key: "workout",
            style: { width: "80px" }
        },
        {
            key: "RPE",
            label: "RPE",
            style: { width: "80px" }
        },
        {
            key: "felt",
            label: "FELT",
            style: { width: "80px" }
        },
        {
            key: "felt",
            label: "% Felt",
            style: { width: "80px" }
        }
    ];

    const rows = [
        { title: `# of Workouts Rated` },
        { title: `Rating Avg` },
        { title: `% Rated` },
        { title: `# of Missing` },
        { title: `# of Workouts FELT STRONG` },
        { title: `# of Workouts FELT WEAK` },
    ];

    useUpdateEffect( () => {

        const workouts = form.values.rpe?.workouts;
        const ratings = get( form.values, `rpe.ratings` );
        const totalWorkout = get( form.values, `rpe.workoutsCompleted` );

        let ratedRpe = 0;
        let rpe = 0;
        let ratedFelt = 0;
        let felt = 0;
        let feltGood = 0;
        let feltWeak = 0;

        loop( workouts, ( workout, index ) => {

            if ( workout.rpe ) {
                ratedRpe += 1
                rpe += parseNum( workout.rpe )
            }

            if ( workout.felt ) {

                ratedFelt += 1
                felt += parseNum( workout.felt )

                if ( workout.felt >= 4 ) {
                    feltWeak += 1
                } else if ( workout.felt <= 3 ) {
                    feltGood += 1
                }
            }

        } )

        const percentRatedRpe = fixedNum( ( ratedRpe / ( totalWorkout ) ) * 100, 0 )
        const percentRatedFelt = fixedNum( ( ratedFelt / ( totalWorkout ) ) * 100, 0 )

        const avgRpe = fixedNum( rpe / ratedRpe, 1 );
        const avgFelt = fixedNum( felt / ratedFelt, 1 );

        const missingRpe = totalWorkout - ratedRpe;
        const missingFelt = totalWorkout - ratedFelt;


        const percentFeltGood = fixedNum( ( feltGood / totalWorkout ) * 100, 0 );
        const percentFeltWeak = fixedNum( ( feltWeak / totalWorkout ) * 100, 0 );

        const percentFeltMissing = fixedNum( 100 - ( percentFeltGood + percentFeltWeak ), 0 );

        loop( ratings, ( rating, i ) => {

            if ( rating.title == "# of Workouts Rated" ) {
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].rpe`, value: ratedRpe } )
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].felt`, value: ratedFelt } )
            } else if ( rating.title == "% Rated" ) {
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].rpe`, value: percentRatedRpe } )
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].felt`, value: percentRatedFelt } )
            } else if ( rating.title == 'Rating Avg' ) {
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].rpe`, value: avgRpe } )
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].felt`, value: avgFelt } )
            } else if ( rating.title == "# of Missing" ) {
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].rpe`, value: missingRpe } )
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].felt`, value: missingFelt } )
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].percent`, value: percentFeltMissing } )
            } else if ( rating.title == '# of Workouts FELT STRONG' ) {
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].felt`, value: feltGood } )
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].percent`, value: percentFeltGood } )
            } else if ( rating.title == '# of Workouts FELT WEAK' ) {
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].felt`, value: feltWeak } )
                form.handleChange( { reset: true, name: `rpe.ratings[${ i }].percent`, value: percentFeltWeak } )
            }
        } )

    }, [ hashCode( form.values.rpe?.workouts ), get( form.values, `rpe.workoutsCompleted` ) ] )

    return (
        <>
            <FieldNumber
                allowNegative
                dynamicKey
                form={form}
                name={`rpe.workoutsCompleted`}
                defaultValue={get( form.values, `rpe.workoutsCompleted` )}
                label='Workouts Completed'
                disabled
            />

            <InputTable
                columns={column}
            >

                {rows.map( ( item, index ) => (
                    <InputTableRow key={index}>

                        <DataTableCell>
                            <strong>{item.title}</strong>
                            <FieldHidden
                                form={form}
                                name={`rpe.ratings[${ index }].title`}
                                value={item.title}
                            />
                        </DataTableCell>

                        <InputTableCell>
                            {item.title != "# of Workouts FELT STRONG" && item.title != "# of Workouts FELT WEAK" &&

                                <FieldNumber
                                    dynamicKey
                                    form={form}
                                    name={`rpe.ratings[${ index }].rpe`}
                                    defaultValue={get( form.values, `rpe.ratings[${ index }].rpe` )}
                                    disabled
                                    suffix={item.title == "% Rated" && " %"}
                                />
                            }
                        </InputTableCell>

                        <InputTableCell>

                            <FieldNumber
                                dynamicKey
                                form={form}
                                name={`rpe.ratings[${ index }].felt`}
                                defaultValue={get( form.values, `rpe.ratings[${ index }].felt` )}
                                suffix={item.title == "% Rated" && " %"}
                                disabled
                            />

                        </InputTableCell>

                        <InputTableCell>
                            {( item.title == "# of Missing" || item.title == "# of Workouts FELT STRONG" || item.title == "# of Workouts FELT WEAK" ) &&

                                <FieldNumber
                                    dynamicKey
                                    form={form}
                                    name={`rpe.ratings[${ index }].percent`}
                                    defaultValue={get( form.values, `rpe.ratings[${ index }].percent` )}
                                    suffix=" %"
                                    disabled
                                // hidden
                                />

                            }

                        </InputTableCell>

                    </InputTableRow>

                ) )}

            </InputTable>

        </>

    )
}

export default CoachingTrainingsWeekRPETab
