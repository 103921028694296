import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Space, useApp } from '@dex/bubl-dash';
import { parseNum } from '@dex/bubl-helpers';
import TestSessionsReportMenu from './TestSessionsReportMenu';
import TestSessionsReportPage from './TestSessionsReportPage';
import styles from "./TestSessionsReport.module.scss";
import TestSessionsReportPerformanceTrackingAerobicCapacity from './TestSessionsReportPerformanceTrackingAerobicCapacity';
import TestSessionsReportPerformanceTrackingThreshold from './TestSessionsReportPerformanceTrackingThreshold';
import TestSessionsReportPerformanceTrackingMetabolic from './TestSessionsReportPerformanceTrackingMetabolic';
import TestSessionsReportPerformanceTrackingEconomy from './TestSessionsReportPerformanceTrackingEconomy';
import TestSessionsReportSweatTestComment from './TestSessionsReportSweatTestComment';
import TestSessionsReportPerformanceTrackingZones from './TestSessionsReportPerformanceTrackingZones';
import TestSessionsReportPerformanceTrackingSprint from './TestSessionsReportPerformanceTrackingSprint';
import TestSessionsReportPerformanceTrackingCover from './TestSessionsReportPerformanceTrackingCover';

const TestSessionsReportPerformanceTracking: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const [activePage, setActivePage] = useState(0);

    const observer: any = useRef();

    const pages: any = [
        {
            key: "cover",
            label: "Cover",
            heading: false,
            el: TestSessionsReportPerformanceTrackingCover,
            ref: useRef()
        },
        {
            key: "maximum-aerobic-capacity",
            label: "Aerobic Capacity",
            heading: "Maximum Aerobic Capacity",
            el: TestSessionsReportPerformanceTrackingAerobicCapacity,
            ref: useRef()
        },
        {
            key: "threshold",
            label: "Threshold",
            heading: "Threshold",
            el: TestSessionsReportPerformanceTrackingThreshold,
            ref: useRef()
        },
        {
            key: "sprint",
            label: "Sprint",
            heading: "Sprint",
            el: TestSessionsReportPerformanceTrackingSprint,
            ref: useRef()
        },
        {
            key: "metabolic-fficiency",
            label: "Metabolic Efficiency",
            heading: "Metabolic Efficiency",
            el: TestSessionsReportPerformanceTrackingMetabolic,
            ref: useRef()
        },
        {
            key: "economy",
            label: "Economy",
            heading: "Economy",
            el: TestSessionsReportPerformanceTrackingEconomy,
            ref: useRef()
        },
        {
            key: "zones",
            label: "Zones",
            heading: "Zones",
            el: TestSessionsReportPerformanceTrackingZones,
            ref: useRef()
        },
        {
            key: "comment",
            label: "Appraiser's Comments",
            heading: "Appraiser's Comments",
            icon: require("../../../assets/images/LabReport/icon-summary.png"),
            el: TestSessionsReportSweatTestComment,
            ref: useRef()
        },
    ].filter((page) => {

        if ((!data.reportPages || !data.reportPages.length) && (data.reportType == "sprint-tracking-running" || data.reportType == "sprint-tracking-cycling")) {
            return ["cover", "athlete-profile", "sprint", "comment"].includes(page.key);
        }

        if ((!data.reportPages || !data.reportPages.length) && (data.reportType == "performance-tracking-running" || data.reportType == "performance-tracking-cycling")) {
            return ["cover", "athlete-profile", "maximum-aerobic-capacity", "threshold", "metabolic-fficiency", "economy", "zones", "comment"].includes(page.key);
        }

        // if (!data.reportPages || !data.reportPages.length) return true;
        return data.reportPages.includes(page.key) ? true : false;

    });

    useEffect(() => {

        const callback = (entries, observer) => {

            let inViewTarget: any = null;
            let inViewRatio = 0;

            entries.forEach((entry, index) => {

                if (entry.isIntersecting && entry.intersectionRatio > inViewRatio) {

                    inViewRatio = entry.intersectionRatio;
                    inViewTarget = entry.target;

                }

            })

            if (inViewTarget) setActivePage(parseNum(inViewTarget.id));

        };

        const observer = new IntersectionObserver(callback, {
            threshold: .4,
        });

        pages.map((page) => {

            observer.observe(page.ref.current);

        });

        () => {

            pages.map((page) => {

                observer.unobserve(page.ref.current);

            });

            observer.disconnect();

        }

    }, []);

    const handleScrollTo = useCallback((ref, event) => {

        if (event && event.preventDefault) event.preventDefault();

        ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

    }, []);

    const app = useApp();

    const [settings] = app.store("appSettings");

    return useMemo(() => (

        <>

            <TestSessionsReportMenu
                data={data}
                activePage={activePage}
                pages={pages}
                handleScrollTo={handleScrollTo}
                form={form}
            />

            {pages.map((page, index) => (
                <React.Fragment key={index}>

                    {index >= 0 &&
                        <Space />
                    }

                    <TestSessionsReportPage
                        heading={page.heading}
                        icon={page.icon}
                        name={data.athlete.fullName}
                        index={index}
                        observer={observer}
                        forwardedRef={page.ref}
                        setActivePage={setActivePage}
                        form={form}
                    >

                        {page.el &&
                            <page.el
                                data={data}
                                form={form}
                                description={settings?.performanceTracking}
                            />
                        }

                    </TestSessionsReportPage>


                </React.Fragment>
            ))}

        </>

    ), [data, activePage, form.hash]);

}

export default TestSessionsReportPerformanceTracking;