import React from 'react';

import styles from "./FieldResultsHeader.module.scss";
import { titleCase } from '@dex/bubl-helpers';

const FieldResultsHeader: React.FC<FieldResultsHeaderProps> = (props) => {

    const { heading, team, date, sport, age, position } = props;

    return (

        <div className={styles.heading}>

            <div className={styles.left}>

                <h1>{titleCase(heading)}</h1>

                {(sport || position || age) &&

                    <div className={styles.details}>

                        {sport && <h3>{titleCase(sport)}</h3>}
                        {position && <h3>{titleCase(position)}</h3>}
                        {age && <h3>{age} yrs</h3>}

                    </div>

                }

            </div>

            {(team || date) &&
                <div className={styles.right}>

                    {team && <h2>{titleCase(team)}</h2>}
                    {date && <h3>{date}</h3>}

                </div>
            }

        </div>

    )

}

interface FieldResultsHeaderProps {
    [key: string]: any,
}

export default FieldResultsHeader;