import { Col, DataTableCell, InputTable, InputTableRow, Row } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import { loop } from '@dex/bubl-helpers';
import ChartProps from '../../../elements/Chart/Chart.Props';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartSprintHr from '../../../elements/ChartLabTests/ChartSprintHr';

const TestSessionsReportSprintHeartRate: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const activity = data.test.activity;

    return useMemo(() => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 24, md: 24 }}>

                    <PanelChart
                        heading={"HR During The Sprint"}
                        types={[
                            {
                                name: "avg",
                                label: "Sprint Only",
                                data: data.test.avgData
                            },
                            {
                                name: "rolling",
                                label: "Average 5 Sec",
                                data: data.test.rollingData
                            },
                            {
                                name: "raw",
                                label: "Raw",
                                data: data.test.rawData
                            }
                        ]}
                        addZero={false}
                        renderChart={(chartType, chartData) => (

                            <>
                                <ChartSprintHr
                                    data={chartData}
                                    activity={activity}
                                    intensity={data.intensity}
                                    xAxis={{
                                        interval: ChartProps.interval(activity, chartType),
                                    }}
                                />
                            </>

                        )}
                    />

                </Col>


                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Sprint Heart Rate (HR)"
                        value={data.test.hr?.sprintHrDescription}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <InputTable
                        columns={[
                            { colSpan: 2, label: "Heart Rate Measure", alignMiddle: true },
                        ]}
                    >
                        <InputTableRow>

                            <DataTableCell>
                                <strong>Peak</strong>
                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{data.test?.hr?.max} bpm</strong>

                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Min</strong>
                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{data.test?.hr?.min} bpm</strong>

                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Avg</strong>
                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{data.test?.hr?.avg} bpm</strong>

                            </DataTableCell>

                        </InputTableRow>

                    </InputTable>

                </Col>

            </Row>

        </>

    ), [data])
}

export default TestSessionsReportSprintHeartRate;