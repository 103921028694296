export const summaryData = {
    displayAs: "scale",
    suffix: " ",
    steps: [
        {
            "label": "Poor",
            "hidden": null,
            "male": {
                "untrained": {
                    "min": "0",
                    "max": "1"
                },
            }
        },
        {
            "label": "Fair",
            "hidden": null,
            "male": {
                "untrained": {
                    "min": "2",
                    "max": "3"
                },
            }
        },
        {
            "label": "Excellent",
            "hidden": null,
            "male": {
                "untrained": {
                    "min": "4",
                    "max": "5"
                },
            }
        },
    ]
}