import React from 'react';
import SVG from 'react-inlinesvg';
import InjuryFrontSvg from './InjuryFront.svg';
import InjuryFrontRunSvg from './InjuryFrontRun.svg';

import './InjuryFront.module.scss';
import { hash } from '@dex/bubl-helpers';
import { injuryValuesToClasses } from '../../functions/sharedBikeFit';
import { fillImage } from '../../functions/sharedGeneral';

const InjuryFront: React.FC<any> = (props: any) => {

    const { checked, activity } = props;

    let { injuryFrontClasses } = injuryValuesToClasses(checked);

    return (

        <div
            key={hash([injuryFrontClasses])}
            styleName={'wrap'}
        >
            <SVG
                src={activity == "run" ? InjuryFrontRunSvg : InjuryFrontSvg}
                preProcessor={(code) => {
                    code = fillImage(code, injuryFrontClasses, "#3D5AFE")
                    return code;
                }}
            />

        </div>

    )

}

export default InjuryFront;
