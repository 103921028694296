import { fixedNum, merge, slug } from '@dex/bubl-helpers';
import { fancyHourFormatNoZero, fancyTimeFormatNoZero } from '../../functions/sharedChart';

const ChartProps = {
    LineChart: (props = {}) => {

        const args: any = {
            height: 300,
            margin: {
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
            }
        };

        return merge(args, props);

    },
    ScatterChart: (props = {}) => {

        const args: any = {
            height: 300,
            margin: {
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
            }
        };

        return merge(args, props);

    },
    BarChart: (props = {}) => {

        const args: any = {
            height: 300,
            margin: {
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
            },
            background: false,
        };

        return merge(args, props);

    },
    CartesianGrid: (props = {}) => {

        const args: any = {
            stroke: "#fff",
            strokeOpacity: .2
        };

        return merge(args, props);

    },
    XAxis: (index = 0, label = "X Axis", props: any = {}) => {

        const args: any = {
            height: props.angle === -45 ? 65 : 30,
            label: {
                value: label,
                dy: 15,
                fill: ChartProps.indexColor(index),
                fontWeight: 700,
                position: 'insideBottom',
                style: {
                    fontSize: '1.28rem',
                    transform: 'translateY(15px)',
                }
            },
            tick: { fontWeight: 600, fill: '#FFF', fontSize: 50, },
            tickLine: false,
            interval: 7,
        };

        return merge(args, props);

    },
    YAxis: (index = 0, label = "Y Axis", pos = "left", props: any = {}) => {

        const args: any = {
            yAxisId: pos + "-axis",
            orientation: pos,
            label: {
                value: label,
                angle: -90,
                dy: 10,
                dx: -20,
                fill: ChartProps.indexColor(index),
                fontWeight: 700,
                width: 260,
                height: 50,
                style: {
                    fontSize: '1.28rem',
                }
            },
            tick: { fontWeight: 600, fill: '#FFF' },
            tickLine: false
        };

        if (pos === "right") {
            args.label.angle = 270;
            args.label.dy = 10;
            args.label.dx = 20;
        }

        return merge(args, props);

    },
    Line: (index = 0, pos = "left", props = {}) => {

        const args: any = {
            yAxisId: pos + "-axis",
            type: "monotone",
            stroke: ChartProps.indexColor(index),
            strokeWidth: 2,
            fillOpacity: 1,
            strokeLinecap: "round",
            activeDot: { strokeWidth: 2 },
            dot: { strokeWidth: 6, fill: ChartProps.indexColor(index) },
        };

        const merged: any = merge(args, props);

        if (merged.label) {
            merged.label = ChartProps.Label(merged.label, merged);
            merged.label.fill = ChartProps.indexColor(6)
        }

        return merged;

    },
    Circle: (index = 0, pos = "left", props = {}) => {

        const args: any = {
            yAxisId: pos + "-axis",
            type: "monotone",
            stroke: ChartProps.indexColor(index),
            strokeWidth: 0,
            fillOpacity: 1,
            strokeLinecap: "round",
            // activeDot: { strokeWidth: 2, fill: ChartProps.indexColor(index) },
            legendType: "circle",
            dot: { strokeWidth: 6, fill: ChartProps.indexColor(index) }
        };

        const merged: any = merge(args, props);

        if (merged.label) merged.label = ChartProps.Label(merged.label, merged);

        return merged;

    },
    Area: (index = 0, pos = "left", props = {}) => {

        const args: any = {
            yAxisId: pos + "-axis",
            fill: ChartProps.indexColor(index),
            opacity: .15,
            legendType: "none",
            activeDot: false,
        };

        const merged: any = merge(args, props);

        if (merged.label) merged.label = ChartProps.Label(merged.label, merged);

        return merged;

    },
    Bar: (index = 0, pos = "left", props = {}) => {

        const args: any = {
            yAxisId: pos + "-axis",
            fill: ChartProps.indexColor(index),
            type: "monotone",
            fillOpacity: 1,
            background: false,
            barSize: 40,
        };

        const merged: any = merge(args, props);

        if (merged.label) merged.label = ChartProps.Label(merged.label, merged);

        return merged;

    },
    Label: (props = {}, parent, data: any = {}) => {

        const args: any = {
            position: 'top',
            fill: ChartProps.indexColor(data.index),
            fontWeight: 600,
            formatter: null,
            offset: 8
        };

        if (parent?.tickFormatter && parent?.tickFormatter.isFancyTime) args.formatter = fancyTimeFormatNoZero;
        if (parent?.tickFormatter && parent?.tickFormatter.isFancyHour) args.formatter = fancyHourFormatNoZero;

        if (data.props?.suffix) args.formatter = (val) => val + data.props?.suffix;

        if (data.props?.labelPosition) args.position = data.props?.labelPosition;

        return merge(args, props);

    },
    _: (props = {}) => {

        const args: any = {};

        return merge(args, props);

    },

    indexColor: (index = 0) => {

        const colors = ["#5DC828", "#4ABBFF", "#FECF00", "#6cdcc7", "#E3C1FF", "#F29A91", "#FFFFFF", "#000000", "#b1daf7"];

        return colors[index] || colors[0];

    },

    activityColor: (activity = "") => {

        const colors = {
            run: ChartProps.indexColor(4),
            bike: ChartProps.indexColor(0),
            swim: ChartProps.indexColor(1),
            hr: ChartProps.indexColor(5),
            other: ChartProps.indexColor(7),
            gym: ChartProps.indexColor(2)
        };

        return colors[activity.toLowerCase()] || "#ffffff";

    },

    metricColor: (metric = "") => {

        const colors = {
            "Hours > Zone 2": ChartProps.indexColor(5),
            "Training Stimulus": ChartProps.indexColor(4),
            "Avg Pace": ChartProps.indexColor(0),
            "Best Pace": ChartProps.indexColor(0),
            "Avg Time": ChartProps.indexColor(4),
            "Best Time": ChartProps.indexColor(4),
            "Avg HR": ChartProps.indexColor(5),
            "Peak HR": ChartProps.indexColor(5),
            "HR Hours > Zone 2": ChartProps.indexColor(5),
            "W Hours > Zone 2": ChartProps.indexColor(0),
            "Avg W": ChartProps.indexColor(0),
            "Norm W": ChartProps.indexColor(0),
            "Hrs Zone 2+": ChartProps.indexColor(2),
            "Avg KPH": ChartProps.indexColor(4),
            "Best W": ChartProps.indexColor(0),
            "Best KPH": ChartProps.indexColor(4)
        };

        return colors[metric] || "#ffffff";

    },

    trackingColor: (metric = "") => {

        const colors = {
            "Hours Above Zone 2": ChartProps.indexColor(5),
            "Training Stimulus": ChartProps.indexColor(4),
            "TSS Score": ChartProps.indexColor(2),
            "All": ChartProps.indexColor(6),
            "Swim": ChartProps.indexColor(1),
            "Bike": ChartProps.indexColor(0),
            "Run": ChartProps.indexColor(4),
            "Brick": ChartProps.indexColor(3),
            "Gym": ChartProps.indexColor(2),
            "Other": ChartProps.indexColor(8),
            "Event/Test": ChartProps.indexColor(5),
            "Avg RPE": ChartProps.indexColor(2),
            "Avg Felt": ChartProps.indexColor(3),
            "Fitness": ChartProps.indexColor(1),
            "Form": ChartProps.indexColor(2),
            "Fatigue": ChartProps.indexColor(4),
            "Body Stress": ChartProps.indexColor(5),
            "% Felt Strong": ChartProps.indexColor(1),
            "% Felt Weak": ChartProps.indexColor(0),
            "Fluid Loss": ChartProps.indexColor(2),
            "Fluid Intake": ChartProps.indexColor(0),
            "Carb Intake": ChartProps.indexColor(4),
        };

        return colors[metric] || "#ffffff";

    },

    interval: (activity, dataType, chartType = "") => {

        let interval = 0;

        activity = slug(activity);

        if (chartType === "time") {

            //times
            interval = (dataType === "avg") ? 2 : 7;

        } else {

            //speed
            if (activity === "running") interval = (dataType === "avg") ? 0 : 5;

            //power
            if (activity === "cycling") interval = (dataType === "avg") ? 0 : 7;

        }

        return interval;

    },

    formula: (reg) => {

        if (reg.regression.degree) {

            let b3: any = fixedNum(reg.regression.coefficients[3], 4);
            let b2: any = fixedNum(reg.regression.coefficients[2], 4);
            let b1: any = fixedNum(reg.regression.coefficients[1], 4);
            let a: any = fixedNum(reg.regression.coefficients[0], 4);

            // b3 = (b3 > 0) ? "+ " + b3 : (b3 + "").replace("-", "- ");
            b2 = (b2 > 0) ? "+ " + b2 : (b2 + "").replace("-", "- ");
            b1 = (b1 > 0) ? "+ " + b1 : (b1 + "").replace("-", "- ");
            a = (a > 0) ? "+ " + a : (a + "").replace("-", "- ");

            return `Y = ${b3} ${b2} ${b1} ${a}`;

        } else {

            let a = fixedNum(reg.regression.slope, 4);
            let b = fixedNum(reg.regression.intercept, 4);

            return `Y = ${a} + ${b}`;

        }


    }

};

export default ChartProps;