import React, { useCallback, useMemo, useState } from 'react';
import { useApp, useForm } from '@dex/bubl-dash';
import CoachingTrainingEnduranceApi from '../../CoachingTrainingEndurance/CoachingTrainingEnduranceApi';
import CoachingTrainingEnduranceForm from './CoachingTrainingEnduranceForm';

const CoachingTrainingsEditEndurance: React.FC<any> = (props: any) => {

    const { data, handleClose, week } = props;

    const app = useApp()

    const form = useForm(week);

    const process: any = CoachingTrainingEnduranceApi.update(week.id, "update", {});

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            onComplete: (data) => {

                app.alert('Endurance Workout Saved', 'success');

                // handleClose();
            }

        });

    }, [props]);

    const del: any = CoachingTrainingEnduranceApi.delete(week.id, {
        onComplete: (data) => {

            app.alert('Endurance Workout Deleted', 'success');

            handleClose();

        }
    });

    return useMemo(() => (

        <>
            <CoachingTrainingEnduranceForm
                form={form}
                data={data}
                process={process}
                handleSubmit={handleSubmit}
                mode="edit"
                del={del}
                handleClose={handleClose}
            />
        </>

    ), [data, process, form?.hash, week.id])
}

export default CoachingTrainingsEditEndurance
