import { MetaTable } from '@dex/bubl-dash';
import React from 'react';

import styles from "./ReportMetaTable.module.scss";

const ReportMetaTable: React.FC<ReportMetaTableProps> = (props) => {

    let { data, className, ...rest } = props;

    let classes = [styles.table];

    if (className && Array.isArray(className)) classes = [...classes, ...className];
    else if (typeof className === "string") classes.push(className);

    return (

        <div
            className={classes.join(" ")}
        >

            <MetaTable
                data={data}
                {...rest}
            />

        </div>

    )

}

interface ReportMetaTableProps {
    [key: string]: any,
}

export default ReportMetaTable;