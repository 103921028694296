import React from 'react';

import styles from "./FieldResultsAthleteFocus.module.scss";
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Col, Panel, Row } from '@dex/bubl-dash';
import { slug } from '@dex/bubl-helpers';

const FieldResultsAthleteFocus: React.FC<FieldResultsAthleteFocusProps> = (props) => {

    const { attributes } = props;

    return (

        <Panel heading={"Athlete Focus"} className={styles.panel}>

            <Row gutter={"small"} edge={true}>

                {attributes.map((item, index) => (
                    <Col col={{ xs: 24, sm: 12, lg: 8, xl: 6 }}>

                        <FieldResultsAthleteFocusPanel
                            label={item.label}
                            category={item.category}
                            names={item.names}
                        />

                    </Col>
                ))}

            </Row>

        </Panel>
    )

}

const FieldResultsAthleteFocusPanel: React.FC<FieldResultsAthleteFocusProps> = (props) => {

    const { label, category, names } = props;

    return (

        <div className={[styles.container, `cat-color-${slug(category)}`].join(" ")}>

            <div className={styles.label}>
                <div className={styles.dot} />
                <p>{label}</p>
            </div>

            <Space height={8} />

            {names.map((name, index) => (
                <p>{name}</p>
            ))}

        </div>

    )

}

interface FieldResultsAthleteFocusProps {
    [key: string]: any,
}

export default FieldResultsAthleteFocus;