import React, { useMemo, useCallback, useState, useEffect, Suspense } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Button, ButtonRow, Col, FieldHidden, Line, Row, Seperator, Space } from '@dex/bubl-dash';
import TabBody from '../../../elements/TabBody/TabBody';
import FieldTestsApi from '../FieldTestsApi';
import FieldTestsDetail from './FieldTestsDetail';
import FieldTestsScores from './FieldTestsScores';

import { date } from '@dex/bubl-helpers';
import FieldSessionsApi from '../../FieldSessions/FieldSessionsApi';
import FieldTestsConditions from './FieldTestsConditions';

const FieldTestsTests = React.lazy(() => import('./FieldTestsTests'));
const FieldTestsImport = React.lazy(() => import('./FieldTestsImport'));

const FieldFieldFieldTestsSingleEdit: React.FC<any> = (props: any) => {

    const { id, session, save, setSave } = props;

    const labTest = FieldTestsApi.getOne(id);

    useEffect(() => {

        labTest.run();

    }, [id]);

    return useMemo(() => (

        <>

            {labTest.data &&
                <FieldFieldFieldTestsSingleEditForm
                    {...props}
                    data={labTest.data}
                />
            }

        </>

    ), [labTest.loading, save, setSave]);

}

const FieldFieldFieldTestsSingleEditForm: React.FC<any> = (props: any) => {

    const { data, session, save, setSave, editable } = props;

    const app = useApp();

    const [nav] = app.store("nav");

    const [tab, setTab] = app.store("field-test-subtab", "details");

    const [testTab, setTestTab] = app.store("field-test-tab", null);

    const [key, setKey] = useState(0);

    const form = useForm(data);

    const trash = FieldSessionsApi.update(session.id, "removeFieldTest", {
        onComplete: (data) => {

            setSave(false);

            setTestTab(null);

            app.alert('Field Test Removed', 'success');

            app.navigate(nav, 'update', {});

        }
    });

    const update = FieldTestsApi.update(data.id, "update", {
        onComplete: (data) => {

            setSave(false);

            app.alert('Field Test Saved', 'success');

            // refresh page
            // app.navigate(nav, 'update', {});

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({
            data: values,
        });

    }, [props]);

    const increaseKey = useCallback(() => {

        setKey((key) => key + 1);

    }, []);

    useEffect(() => {

        if (save) form.triggerSubmit();

    }, [save]);

    const handleTrash = useCallback(() => {

        trash.run({
            data: { id: data.id }
        });

    }, []);

    const heading = useMemo(() => {

        return [date(data.date), data.timeOfDay].filter(Boolean).join(", ");

    }, [data]);

    return useMemo(() => (

        <>

            <Seperator
                heading={heading}
                activeTab={tab || "-"}
                setTab={(x) => { setTab(x) }}
                tabs={[
                    { name: "details", label: "Details" },
                    { name: "tests", label: "Tests" },
                    { name: "import", label: "Import" },
                    { name: "conditions", label: "Conditions" },
                    { name: "scores", label: "Scores" },
                ]}
            />

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={update.loading}
            >

                <FieldHidden
                    name={'import.data'}
                    value={form.values.import?.data}
                    form={form}
                />

                <Space />

                <TabBody
                    form={form}
                    active={tab === "details"}
                    fields={["date", "timeOfDay", "fitterIds", "details"]}
                >

                    <FieldTestsDetail
                        form={form}
                        data={data}
                        session={session}
                        tab={tab}
                        setKey={increaseKey}
                    />

                </TabBody>

                <TabBody
                    form={form}
                    active={tab === "tests"}
                    fields={["tests"]}
                >

                    <Suspense fallback={<></>}>

                        <FieldTestsTests
                            form={form}
                            data={data}
                            session={session}
                            tab={tab}
                            setKey={increaseKey}
                        />

                    </Suspense>

                </TabBody>

                <TabBody
                    form={form}
                    active={tab === "import"}
                    fields={["import"]}
                >

                    <Suspense fallback={<></>}>

                        <FieldTestsImport
                            key={key}
                            form={form}
                            data={data}
                            session={session}
                            tab={tab}
                        />

                    </Suspense>

                </TabBody>

                <TabBody
                    form={form}
                    active={tab === "scores"}
                    fields={["scores"]}
                >

                    <FieldTestsScores
                        key={key}
                        form={form}
                        data={data}
                        session={session}
                        tab={tab}
                    />

                </TabBody>

                <TabBody
                    form={form}
                    active={tab === "conditions"}
                    fields={["conditions"]}
                >

                    <FieldTestsConditions
                        key={key}
                        form={form}
                        data={data}
                        session={session}
                        tab={tab}
                    />

                </TabBody>

                {editable &&
                    <>
                        <Line />

                        <Row>

                            <Col col={{ xs: 24, sm: 16 }}>

                                <ButtonRow>
                                    <Button
                                        label={"Save"}
                                        // onPress={form.handleSubmit}
                                        loading={update.loading}
                                    />
                                    <Button
                                        label={"Reset"}
                                        type={"faded"}
                                        onClick={form.reset}
                                    />
                                </ButtonRow>

                            </Col>
                            <Col col={{ xs: 24, sm: 8 }} align={"end"}>

                                <ButtonRow>
                                    <Button
                                        label={"Remove Field Test"}
                                        type={"light"}
                                        onClick={handleTrash}
                                        loading={trash.loading}
                                        confirm={{
                                            title: "Are you sure you want continue?",
                                            message: "This data cannot be recovered once removed.",
                                        }}
                                    />
                                </ButtonRow>

                            </Col>

                        </Row>
                    </>
                }

            </Form >

        </>

    ), [data, form.hash, update.loading, tab, key, trash.loading]);

}

export default FieldFieldFieldTestsSingleEdit;
