import React, { useCallback, useMemo } from 'react';
import { useApp, useForm } from '@dex/bubl-dash';
import CoachingTrainingEnduranceApi from '../../CoachingTrainingEndurance/CoachingTrainingEnduranceApi';
import CoachingTrainingEnduranceForm from './CoachingTrainingEnduranceForm';

const CoachingTrainingsAddEndurance: React.FC<any> = (props: any) => {

    const { data, handleClose } = props;

    const form = useForm({});

    const app = useApp()

    const process = CoachingTrainingEnduranceApi.create();

    const handleSubmit = useCallback((values) => {

        values.coachingId = data.id;

        process.run({
            data: values,
            onComplete: (data) => {

                app.alert('Endurance Workout Saved', 'success');

                form.reset();

                handleClose();

            }
        });

    }, [props]);

    return useMemo(() => (

        <>
            <CoachingTrainingEnduranceForm
                form={form}
                data={data}
                process={process}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
            />

        </>

    ), [form.hash, data, process])
}

export default CoachingTrainingsAddEndurance
