import React, { useMemo } from 'react';
import styles from "./CoachingTrainingZoneTable.module.scss";
import { Col, FieldRadio, Heading, Row, Space, WhiteSpace } from '@dex/bubl-dash';
import CoachingReportHeading from '../CoachingReportHeading/CoachingReportHeading';
import { getBg } from '../../functions/sharedZones';
import { getSpeed, getKphFromPace } from '../../functions/sharedTests';

const CoachingTrainingZoneTable: React.FC<CoachingTrainingZoneTableProps> = (props) => {

    const { form, zone } = props

    const zonesColumn = [

        {
            label: '',
            key: 'icon',
            col: 2
        },
        {
            label: '',
            key: 'zone',
            col: 4,
            className: "table-bdr-right-gray",
        },
        !zone?.hide?.includes("swim") ? {
            label: 'Swim',
            key: 'swim',
            desc: <>
                <FieldRadio
                    form={form}
                    name="trainingZone.swim"
                    defaultValue={form.values.trainingZone?.swim}
                    required
                    options={[
                        { label: 'Pace', value: 'pace' },
                        { label: 'Speed', value: 'speed' },
                    ]}
                />
            </>,
            className: "table-bdr-right-gray",
            col: 5
        } : null,
        !zone?.hide?.includes("bike") ? {
            label: 'Bike',
            key: 'bike',
            desc: <>&nbsp;</>,
            className: "table-bdr-right-gray",
            col: 4
        } : null,
        !zone?.hide?.includes("run") ? {
            label: 'Run',
            key: 'run',
            desc: <>
                <FieldRadio
                    form={form}
                    name="trainingZone.run"
                    defaultValue={form.values.trainingZone?.run}
                    required
                    options={[
                        { label: 'Pace', value: 'pace' },
                        { label: 'Speed', value: 'speed' },
                    ]}
                />
            </>,
            className: "table-bdr-right-gray",
            col: 5
        } : null,
        !zone?.hide?.includes("hr") ? {
            label: 'HR',
            key: 'hr',
            desc: <>&nbsp;</>,
            col: 4,
        } : null,

    ].filter(Boolean);

    return (

        <Col col={{ xs: 24 }}>

            <CoachingReportHeading
                text="Your Zones"
            />

            <Row edge gutter={8}>

                {zonesColumn.map((zoneCol, i) => (

                    <Col col={{ xs: zoneCol?.col }} key={i} align='center' style={{ textAlign: "center" }} className={zoneCol?.className}>

                        <strong >
                            {zoneCol?.label}
                        </strong>

                        <br />

                        {zoneCol?.desc}

                    </Col>

                ))}

            </Row>

            {zone?.zones?.map((row, index) => (

                <React.Fragment key={index}>
                    {index !== 0 &&
                        <Space />
                    }

                    <Row className={""} edge gutter={8}>

                        <Col col={{ xs: 2 }} justify='center'>

                            {index !== undefined && index < 6 &&
                                <img
                                    src={require(`../../assets/images/TrainingCard/Zone ${(index + 1)}.png`)}
                                    style={{ width: "50px" }}
                                />
                            }

                        </Col>

                        <Col col={{ xs: 4 }} className={`table-bdr-right-gray ${getBg(index)} `} justify='center' align='center'>{row?.name} {row?.effort}</Col>

                        {!zone?.hide?.includes("swim") &&
                            <>
                                {form.values?.trainingZone?.swim === "pace" ?

                                    <Col col={{ xs: 5 }} className={`table-bdr-right-gray ${getBg(index)} `} justify='center' align='center'>

                                        {row.swimTo && !row.swimFrom && index !== zone?.zones.length - 1 &&
                                            <>
                                                {'> '} {row.swimTo}
                                            </>
                                        }

                                        {row.swimTo && row.swimFrom &&
                                            <>
                                                {row.swimFrom} to {row.swimTo}
                                            </>
                                        }

                                        {row.swimTo && !row.swimFrom && index == zone?.zones.length - 1 &&
                                            <>
                                                {'< '} {row.swimTo}
                                            </>
                                        }

                                        <WhiteSpace />mm:ss

                                    </Col>

                                    :

                                    <Col col={{ xs: 5 }} className={`table-bdr-right-gray ${getBg(index)} `} justify='center' align='center'>

                                        {row.swimTo && !row.swimFrom && index !== zone?.zones.length - 1 &&
                                            <>
                                                {'< '} {getKphFromPace(row.swimTo)}
                                            </>
                                        }

                                        {row.swimTo && row.swimFrom &&
                                            <>
                                                {getKphFromPace(row.swimFrom)} to {getKphFromPace(row.swimTo)}
                                            </>
                                        }

                                        {row.swimTo && !row.swimFrom && index == zone?.zones.length - 1 &&
                                            <>
                                                {'> '} {getKphFromPace(row.swimTo)}
                                            </>
                                        }

                                        <WhiteSpace />kph

                                    </Col>

                                }
                            </>
                        }

                        {!zone?.hide?.includes("bike") &&
                            <Col col={{ xs: 4 }} className={`table-bdr-right-gray ${getBg(index)} `} justify='center' align='center'>

                                {row.bikeTo && !row.bikeFrom &&
                                    <>
                                        {'< '} {row.bikeTo}
                                    </>
                                }

                                {row.bikeTo && row.bikeFrom &&
                                    <>
                                        {row.bikeFrom} to {row.bikeTo}
                                    </>
                                }

                                {!row.bikeTo && row.bikeFrom &&
                                    <>
                                        {'> '} {row.bikeFrom}
                                    </>
                                }

                                <WhiteSpace />w

                            </Col>
                        }

                        {!zone?.hide?.includes("run") &&
                            <>
                                {form.values?.trainingZone?.run === "pace" ?

                                    <Col col={{ xs: 5 }} className={`table-bdr-right-gray ${getBg(index)} `} justify='center' align='center'>

                                        {!row.runToPace && row.runFromPace &&
                                            <>
                                                {'> '} {row.runFromPace}
                                            </>
                                        }

                                        {row.runToPace && row.runFromPace &&
                                            <>
                                                {row.runFromPace} to {row.runToPace}
                                            </>
                                        }

                                        {row.runToPace && !row.runFromPace &&
                                            <>
                                                {'< '} {row.runToPace}
                                            </>
                                        }

                                        <WhiteSpace />mm:ss

                                    </Col>
                                    :
                                    <Col col={{ xs: 5 }} className={`table-bdr-right-gray ${getBg(index)} `} justify='center' align='center'>

                                        {row.runToSpeed && !row.runFromSpeed &&
                                            <>
                                                {'< '} {row.runToSpeed}
                                            </>
                                        }

                                        {row.runToSpeed && row.runFromSpeed &&
                                            <>
                                                {row.runFromSpeed} to {row.runToSpeed}
                                            </>
                                        }

                                        {!row.runToSpeed && row.runFromSpeed &&
                                            <>
                                                {'> '} {row.runFromSpeed}
                                            </>
                                        }

                                        <WhiteSpace />kph

                                    </Col>

                                }
                            </>
                        }

                        {!zone?.hide?.includes("hr") &&
                            <Col col={{ xs: 4 }} justify='center' align='center' className={getBg(index)}>

                                {row.hrTo && !row.hrFrom &&
                                    <>
                                        {'< '} {row.hrTo}
                                    </>
                                }

                                {row.hrTo && row.hrFrom &&
                                    <>
                                        {row.hrFrom} to {row.hrTo}
                                    </>
                                }

                                {!row.hrTo && row.hrFrom &&
                                    <>
                                        {'> '} {row.hrFrom}
                                    </>
                                }

                                <WhiteSpace />

                            </Col>
                        }

                    </Row>

                </React.Fragment>

            ))
            }

        </Col >

    )

}

export const CoachingTrainingZoneMiniTable: React.FC<CoachingTrainingZoneTableProps> = (props) => {

    const { zone, data } = props

    const rows = useMemo(() => ([
        data?.general?.sportList?.includes("swim") ? {
            activity: "Swim",
            key: "swim",
            threshold: <strong>{`${zone?.swimFTP || ""} mm:ss / ${getKphFromPace(zone?.swimFTP) || ""} kph`}</strong>,//get speed
            maximum: <strong>{`${zone?.swimMax || ""} mm:ss / ${getKphFromPace(zone?.swimMax) || ""} kph`}</strong>,//get speed
            thresholdDesc: "Critical Pace (~1500-2000m)",
            maximumDesc: "Best 200m",
            className: "table-bg-darkerBlue",
        } : null,
        data?.general?.sportList?.includes("bike") ? {
            activity: "Bike",
            key: "bike",
            threshold: <strong>{`${zone?.bikeFTP || ""} w`}</strong>,
            maximum: <strong>{`${zone?.bikeMax || ""} w`}</strong>,
            thresholdDesc: "Best ~60min (FTP)",
            maximumDesc: "Best 3-5min",
            className: "table-bg-darkerGreen",
        } : null,
        data?.general?.sportList?.includes("run") ? {
            activity: "Run",
            key: "run",
            threshold: <strong>{`${zone?.runFTP || ""} mm:ss / ${zone?.runSpeedFTP || ""} kph`}</strong>,
            maximum: <strong>{`${zone?.runMax || ""}  mm:ss /  ${getKphFromPace(zone?.runMax) || ""} kph`}</strong>, //getspeed
            thresholdDesc: "Best 8-12km",
            maximumDesc: "Best 1500-3000m",
            className: "table-bg-darkerPurple",
        } : null,
        {
            activity: "HR",
            key: "hr",
            threshold: <strong>{`${zone?.hrFTP || ""} bpm`}</strong>,
            maximum: <strong>{`${zone?.hrMax || ""} bpm`}</strong>,
            thresholdDesc: "Peak ~60min",
            maximumDesc: "Peak 1min",
            className: "table-bg-darkerRed",
            background: "red",
        },
        {
            activity: "VO2",
            key: "vo2",
            threshold: <strong>{`${zone?.vo2FTP || ""}  ml/kg*min`}</strong>,
            maximum: <strong>{`${zone?.vo2Max || ""}  ml/kg*min`}</strong>,
            thresholdDesc: "Peak ~60min",
            maximumDesc: "Peak 1min",
            className: "table-bg-darkerYellow",
        },

    ].filter(Boolean)), [zone]);

    return (

        <Col col={{ xs: 16 }}>

            <Row gutter={8} style={{ textAlign: "center" }}>
                <Col col={{ xs: 4 }}></Col>
                <Col col={{ xs: 10 }} className='table-bdr-right-gray' align='center'><Heading space={4}>Threshold</Heading></Col>
                <Col col={{ xs: 10 }} align='center'><Heading space={4}>Maximum</Heading></Col>
            </Row>

            {rows.map((row, index) => (

                <React.Fragment key={index}>

                    <Row
                        className={row?.className + " " + styles.measuresRow}
                        gutter={8}
                    >

                        <Col col={{ xs: 4 }} align='center' justify='center'><Heading size={4} space={false}>{row?.activity}</Heading></Col>
                        <Col col={{ xs: 10 }} className='value text-center table-bdr-right-gray' justify='center'>{row?.threshold}<small>{row?.thresholdDesc}</small></Col>
                        <Col col={{ xs: 10 }} className='value text-center' justify='center'>{row?.maximum}<small>{row?.maximumDesc}</small></Col>

                    </Row>

                    <Space height={4} />

                </React.Fragment>

            ))
            }

        </Col >

    )

}

interface CoachingTrainingZoneTableProps {
    [key: string]: any,
}

export default CoachingTrainingZoneTable;