import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import GaitsApi from '../GaitsApi';
import EditableContext from '../../../context/EditableContext';
import { get, set } from '@dex/bubl-helpers';
import GaitsCreateEditForm from './GaitsCreateEditForm';
import { Box } from '@dex/bubl-dash';
import GaitsEditTabsBio from './GaitsEditTabsBio';
import GaitsEditTabsInjury from './GaitsEditTabsInjury';
import GaitsEditTabsPosture from './GaitsEditTabsPosture';
import GaitsEditTabsMobility from './GaitsEditTabsMobility';
import GaitsEditTabsSymmetry from './GaitsEditTabsSymmetry';
import GaitsEditTabsStability from './GaitsEditTabsStability';
import GaitsEditTabsFoot from './GaitsEditTabsFoot';
import GaitsEditTabsShoes from './GaitsEditTabsShoes';
import GaitsEditTabsSummary from './GaitsEditSummary';
import GaitsEditTabsExercises from './GaitsEditTabsExercises';
import GaitsEditTabsRun from './GaitsEditTabsRun';
import GaitsEditTabsInfo from './GaitsEditTabsInfo';
import GaitsEditTabsReport from './GaitsEditTabsReport';

const GaitsSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const tab = get(props, 'routeParams.tab');

    const form = useForm(data);

    const [nav] = app.store("nav");

    let action = tab;

    if (tab === "info" || !tab) action = "update"

    const update = GaitsApi.update(data.id, action, {
        onComplete: (data) => {

            app.alert('Gaits Saved', 'success');

            app.navigate(nav, 'update', {});
        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    const [settings] = app.store("appSettings");

    let TabElement: any = React.Fragment;
    if (tab === "info" || tab === "general" || !tab) TabElement = GaitsEditTabsInfo;
    if (tab === "bio") TabElement = GaitsEditTabsBio;
    else if (tab === "injury") TabElement = GaitsEditTabsInjury;
    else if (tab === "posture") TabElement = GaitsEditTabsPosture;
    else if (tab === "mobility") TabElement = GaitsEditTabsMobility;
    else if (tab === "symmetry") TabElement = GaitsEditTabsSymmetry;
    else if (tab === "stability") TabElement = GaitsEditTabsStability;
    else if (tab === "foot") TabElement = GaitsEditTabsFoot;
    else if (tab === "shoes") TabElement = GaitsEditTabsShoes;
    else if (tab === "run") TabElement = GaitsEditTabsRun;
    else if (tab === "exercises") TabElement = GaitsEditTabsExercises;
    else if (tab === "summary") TabElement = GaitsEditTabsSummary;
    else if (tab === "report") TabElement = GaitsEditTabsReport;

    return useMemo(() => (

        <>
            <EditableContext.Provider value={true}>

                <Box gutter={tab === "info" ? "auto" : ""}>

                    {data && TabElement &&

                        <TabElement
                            form={form}
                            data={data}
                            handleSubmit={handleSubmit}
                            process={update}
                            settings={settings}
                        />

                    }

                </Box>

            </EditableContext.Provider>

        </>

    ), [data, form.hash, update]);

}

export default GaitsSingleEdit;
