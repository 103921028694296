import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const FieldTemplatesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Field Test Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Test/Measure Name",
                                key: "name",
                            },
                            {
                                label: "Units",
                                key: "units",
                            },
                            {
                                label: "Description",
                                key: "description",
                                format: "nl2br"
                            },

                        ]}
                        columnB={[
                            {
                                label: "Test Category",
                                key: "category",
                                format: "titleCase"
                            },
                            {
                                label: "Test Subcategory",
                                key: "subcategory",
                                format: "titleCase"
                            },
                            {
                                label: "Best Score is",
                                key: "bestScore",
                                format: "titleCase"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Decimal Places",
                                key: "decimalPlace",
                                format: "number"
                            },
                            {
                                label: "Photo",
                                key: "photo",
                                format: "image",
                                link: true,
                                hideEmpty: true,
                                filters: {
                                    width: 100,
                                    height: 100
                                }
                            },
                        ]}
                    />

                </Panel>

            </Col>

        </Row>

    ), [data]);

}

export default FieldTemplatesSingleView;
