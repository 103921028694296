import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { DataTable, DataTableCell, DataTableRow, FieldCheckbox, FieldHidden, FieldNumber, FieldPopup, FieldRadio, FieldSelect, FieldTextArea, InputTable, InputTableCell, InputTableRow, Space } from '@dex/bubl-dash';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import { fixedNum, get, loop } from '@dex/bubl-helpers';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartSprintPerformance from '../../../elements/ChartLabTests/ChartSprintPerformance';

const LabTestsSprintPerformance: React.FC<any> = (props: any) => {

    const { form, data, session, tab } = props;

    const activity = data.activity;

    const [chartUse, setChartUse] = useState("avg");

    const checkArrayValue = (value, array, item) => {
        let selectedObject;

        for (const row of array) {
            if (row[item] === value) {
                selectedObject = row;
                break;
            }
        }

        return selectedObject?.time;
    };


    const performanceUse = form.values?.performance?.performanceUse || "power_raw";

    let powerPeak;
    let rpmPeak;

    if (activity == "Cycling") {

        if (chartUse == "raw") {

            powerPeak = checkArrayValue(get(form.values, `sumRawData.${performanceUse}.max`), form.values?.rawData, performanceUse)
            rpmPeak = checkArrayValue(form.values?.sumRawData?.rpm?.max, form.values?.rawData, "rpm")

        } else if (chartUse == "rolling") {

            powerPeak = checkArrayValue(get(form.values, `sumData.${performanceUse}.max`), form.values?.rollingData, performanceUse)
            rpmPeak = checkArrayValue(form.values?.sumData?.rpm?.max, form.values?.rollingData, "rpm")

        } else {

            powerPeak = checkArrayValue(get(form.values, `sumAvgData.${performanceUse}.max`), form.values?.avgData, performanceUse)
            rpmPeak = checkArrayValue(form.values?.sumAvgData?.rpm?.max, form.values?.avgData, "rpm")

        }

    } else {

        if (chartUse == "raw") {

            powerPeak = checkArrayValue(form.values?.sumRawData?.speed?.max, form.values?.rawData, "speed")
            rpmPeak = checkArrayValue(form.values?.sumRawData?.pace?.max, form.values?.rawData, "pace")

        } else if (chartUse == "rolling") {

            powerPeak = checkArrayValue(form.values?.sumData?.speed?.max, form.values?.rollingData, "speed")
            rpmPeak = checkArrayValue(form.values?.sumData?.pace?.max, form.values?.rollingData, "pace")

        } else {

            powerPeak = checkArrayValue(form.values?.sumAvgData?.speed?.max, form.values?.avgData, "speed")
            rpmPeak = checkArrayValue(form.values?.sumAvgData?.pace?.max, form.values?.avgData, "pace")
        }
    };

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24, md: 24 }}>

                <PanelChart
                    heading={"Performance"}
                    types={[
                        {
                            name: "avg",
                            label: "Sprint Only",
                            data: form.values.avgData
                        },
                        {
                            name: "rolling",
                            label: "Average 5 Sec",
                            data: form.values.rollingData
                        },
                        {
                            name: "raw",
                            label: "Raw",
                            data: form.values.rawData
                        }
                    ]}
                    onTypeChange={setChartUse}
                    addZero={false}
                    renderChart={(chartType, chartData) => (

                        <>
                            <ChartSprintPerformance
                                data={chartData}
                                activity={activity}
                                intensity={data.intensity}
                                xAxis={{
                                    interval: ChartProps.interval(activity, chartType),
                                }}
                                type={chartType}
                                peak={powerPeak}
                                rpm={rpmPeak}
                                performance={performanceUse}
                            />
                        </>

                    )}
                />

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <FieldRadio
                    form={form}
                    required={false}
                    label={"Power"}
                    name={"performance.performanceUse"}
                    defaultValue={performanceUse}
                    options={[
                        { label: "Bike", value: "power_raw" },
                        { label: "Trainer", value: "power_target" },
                    ].filter(Boolean)}
                />

                {activity == "Cycling" ?

                    <InputTable
                        columns={[
                            {},
                            { colSpan: 2, label: "Power", alignMiddle: true },
                            { label: "RPM", alignMiddle: true },
                        ]}
                    >
                        <InputTableRow>

                            <DataTableCell>
                                <strong>Peak - 1 Sec</strong>
                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{get(form.values, `sumRawData.${performanceUse}.max`)} W</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{fixedNum(get(form.values, `sumRawData.${performanceUse}.max`) / session?.bio?.weight, 1)} W/kg</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{form.values?.sumRawData?.rpm?.max}</strong>

                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Peak - 5 Sec</strong>
                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{get(form.values, `sumData.${performanceUse}.max`)} W</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{fixedNum(get(form.values, `sumData.${performanceUse}.max`) / session?.bio?.weight, 1)} W/kg</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{form.values?.sumData?.rpm?.max}</strong>

                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Avg - Full Sprint</strong>
                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{get(form.values, `sumRawData.${performanceUse}.avg`)} W</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{fixedNum(get(form.values, `sumRawData.${performanceUse}.avg`) / session?.bio?.weight, 1)} W/kg</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{form.values?.sumRawData?.rpm?.avg}</strong>

                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Time To Peak</strong>
                            </DataTableCell>

                            <DataTableCell alignMiddle colSpan={2}>

                                <strong>{powerPeak} sec</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{rpmPeak} sec</strong>

                            </DataTableCell>

                        </InputTableRow>

                    </InputTable>
                    :
                    <InputTable
                        columns={[
                            {},
                            { colSpan: 2, label: "Speed", alignMiddle: true },
                            { label: "Pace", alignMiddle: true },
                        ]}
                    >
                        <InputTableRow>

                            <DataTableCell>
                                <strong>Peak - 1 Sec</strong>
                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{form.values?.sumRawData?.speed?.max} kph</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{fixedNum(form.values?.sumRawData?.speed?.max / session?.bio?.weight, 1)} kph/kg</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{form.values?.sumRawData?.pace?.max}</strong>

                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Peak - 5 Sec</strong>
                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{form.values?.sumData?.speed?.max} kph</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{fixedNum(form.values?.sumData?.speed?.max / session?.bio?.weight, 1)} kph/kg</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{form.values?.sumData?.pace?.max}</strong>

                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Avg - Full Sprint</strong>
                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{form.values?.sumRawData?.speed?.avg} W</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{fixedNum(form.values?.sumRawData?.speed?.avg / session?.bio?.weight, 1)} W/kg</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{form.values?.sumRawData?.pace?.avg}</strong>

                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                <strong>Time To Peak</strong>
                            </DataTableCell>

                            <DataTableCell alignMiddle colSpan={2}>

                                <strong>{powerPeak} sec</strong>

                            </DataTableCell>

                            <DataTableCell alignMiddle>

                                <strong>{rpmPeak} sec</strong>

                            </DataTableCell>

                        </InputTableRow>

                    </InputTable>
                }

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                {activity == "Cycling" ?
                    <RatingScaleCard
                        form={form}
                        metric={'Sprint Power 5 sec'}
                        value={get(form.values, `sumData.${performanceUse}.max`)}
                        name="performance.fiveSecondRatingScale"
                        demographic={get(form.values, 'performance.fiveSecondRatingScale', session.demographic)}
                        split={{
                            metric: 'Sprint Power/kg 5 sec',
                            value: fixedNum(get(form.values, `sumData.${performanceUse}.max`) / session?.bio?.weight, 1),
                        }}
                    />
                    :
                    <RatingScaleCard
                        form={form}
                        metric={'Sprint Speed 5 sec'}
                        value={form.values?.sumData?.speed?.max}
                        name="performance.fiveSecondRatingScale"
                        demographic={get(form.values, 'performance.fiveSecondRatingScale', session.demographic)}
                        split={{
                            metric: 'Sprint Pace 5 sec',
                            value: form.values?.sumData?.pace?.max,
                        }}
                    />
                }

                <Space />

                {activity == "Cycling" ?
                    <RatingScaleCard
                        form={form}
                        metric={'Sprint Power 20 sec'}
                        value={get(form.values, `sumAvgData.${performanceUse}.avg`)}
                        name="performance.fiveSecondRatingScale"
                        demographic={get(form.values, 'performance.fiveSecondRatingScale', session.demographic)}
                        split={{
                            metric: 'Sprint Power/kg 20 sec',
                            value: fixedNum(get(form.values, `sumAvgData.${performanceUse}.avg`) / session?.bio?.weight, 1),
                        }}
                    />
                    :
                    <RatingScaleCard
                        form={form}
                        metric={'Sprint Speed 20 sec'}
                        value={form.values?.sumAvgData?.speed?.avg}
                        name="performance.twentySecondRatingScale"
                        demographic={get(form.values, 'performance.twentySecondRatingScale', session.demographic)}
                        split={{
                            metric: 'Sprint Pace 20 sec',
                            value: form.values?.sumAvgData?.pace?.avg,
                        }}
                    />
                }

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <FieldTextArea
                    form={form}
                    label={"Performance Description"}
                    name={"performance.lactateDescription"}
                    defaultValue={form.defaults?.performance?.performanceDescription}
                    rows={3}
                />

            </Col>

        </Row>

    ), [form.hash, rpmPeak, powerPeak, performanceUse]);

}


export default LabTestsSprintPerformance;
