import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, FieldList, FieldPopup } from '@dex/bubl-dash';
import EditableContext from '../../../context/EditableContext';
import LabTestsGlycogenManualEvent from './LabTestsGlycogenManualEvent';
import LabTestsGlycogenManualLoad from './LabTestsGlycogenManualLoad';
import LabTestsGlycogenManualSummary from './LabTestsGlycogenManualSummary';

const LabTestsGlycogenManual: React.FC<any> = (props: any) => {

    const { form, data, session } = props;

    return useMemo(() => (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldList
                        form={form}
                        name={`glycogens`}
                        label={"Glycogen Profile"}
                        defaultValues={{ brainUsage: 6 }}
                        addButtonLabel={"Add Glycogen Profile"}
                    >

                        {(form.values.glycogens || []).map((glycogen, i) => (

                            <LabTestsGlycogenManualForm
                                key={i}
                                i={i}
                                glycogen={glycogen}
                                form={form}
                                data={data}
                                session={session}
                            />

                        ))}

                    </FieldList>

                </Col>

            </Row>

        </>

    ), [form.hash, session, data]);

}

const LabTestsGlycogenManualForm: React.FC<any> = (props: any) => {

    const { i, glycogen, form, data, session } = props;

    const prefix = `glycogens[${i}]`;

    const [key, setKey] = useState(0);

    const [tab, setTab] = useState("events");

    const upKey = useCallback(() => {

        setKey(key + 1);

    }, [key]);

    const editable = useContext(EditableContext);

    const [recentTest, setRecentTest] = useState<any>(null);

    const handleCopy = useCallback((handleClose) => {

        const items = form.getValue('glycogens');

        handleClose();

        items.push(structuredClone(glycogen));

        form.handleChange({ name: 'glycogens', value: items });

        form.setKey();

    }, [glycogen]);

    return (

        <FieldPopup
            buttonLabel={"Glycogen " + (i + 1) + " - " + glycogen?.activity}
            heading={"Glycogen " + (i + 1) + " - " + glycogen?.activity}
            form={form}
            name={prefix}
            modalProps={{
                className: 'modal-top'
            }}
            actionButtons={(handleClose) => (

                <Button
                    label="Copy To New Test"
                    size="small"
                    type={"faded"}
                    onClick={handleCopy.bind(null, handleClose)}
                />

            )}
            panelProps={{
                tabs: [
                    { label: "Target Event", name: "events" },
                    { label: "Glycogen Load", name: "load" },
                    { label: "Summary", name: "summary" },
                ].filter(Boolean),
                setTab: setTab,
                activeTab: tab
            }}
        >

            <div key={key} className={!editable ? 'form-not-editable' : ''}>

                <div className={tab === "events" ? "" : "hidden"}>

                    <LabTestsGlycogenManualEvent
                        i={i}
                        session={session}
                        form={form}
                        data={data}
                        item={glycogen}
                        prefix={prefix}
                        setKey={upKey}
                        setRecentTest={setRecentTest}
                        recentTest={recentTest}
                    />

                </div>

                <div className={tab === "load" ? "" : "hidden"}>

                    <LabTestsGlycogenManualLoad
                        i={i}
                        session={session}
                        form={form}
                        data={data}
                        glycogen={glycogen}
                        prefix={prefix}
                        setKey={upKey}
                        setRecentTest={setRecentTest}
                        recentTest={recentTest}
                    />

                </div>

                <div className={tab == "summary" ? "" : "hidden"}>

                    <LabTestsGlycogenManualSummary
                        i={i}
                        session={session}
                        form={form}
                        data={data}
                        glycogen={glycogen}
                        prefix={prefix}
                        setKey={upKey}
                        setRecentTest={setRecentTest}
                        recentTest={recentTest}
                    />

                </div>

            </div>

        </FieldPopup>

    )

}

export default LabTestsGlycogenManual;
