import { Col, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import styles from "./TestSessionsReportAthlete.module.scss";
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartBreathingRest from '../../../elements/ChartTestSessionReport/ChartBreathingRest';
import ChartBloodGlucose from '../../../elements/ChartTestSessionReport/ChartBloodGlucose';
import ChartRestingHr from '../../../elements/ChartTestSessionReport/ChartRestingHr';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ChartRestingBp from '../../../elements/ChartTestSessionReport/ChartRestingBp';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import SimpleRatingTable from '../../../elements/RatingScale/SimpleRatingTable';
import { carbIntakeLabel } from '../../../functions/sharedTests';

const TestSessionsReportAthlete: React.FC<any> = ( props: any ) => {

    const { data, form } = props;

    let gender = data.athlete?.gender;
    return useMemo( () => (

        <>

            <Row gutter={20} edge>

                <Col col={{ xs: 12 }}>

                    <ReportHeading
                        text={data.athlete.fullName}
                    />

                    <Row gutter={16} edge>

                        <Col col={{ xs: 9 }}>

                            <ReportMetaTable
                                data={data}
                                columnSize={{ xs: 24 }}
                                columnA={[
                                    {
                                        key: 'athlete.gender',
                                        label: 'Gender',
                                        format: "titleCase"
                                    },
                                    {
                                        key: 'athlete.age',
                                        label: 'Age',
                                        format: "number",
                                        suffix: " yrs",
                                    },
                                    {
                                        key: 'athlete.mainSport',
                                        label: 'Main Sport',
                                    },
                                    {
                                        key: 'athlete.level',
                                        label: 'Level',
                                        format: "titleCase"
                                    },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 15 }}>

                            <ReportMetaTable
                                data={data}
                                columnSize={{ xs: 24 }}
                                columnA={[
                                    {
                                        key: 'general.reasonForTest',
                                        label: 'Reason For Test',
                                        format: "nl2br"
                                    },
                                    {
                                        key: 'general.performanceGoals',
                                        label: 'Performance Goals',
                                        format: "nl2br"
                                    },
                                    {
                                        key: 'general.currentTrainingHours',
                                        label: 'Trainig Hours',
                                    },
                                    {
                                        key: 'general.exercise24Hrs',
                                        label: 'Exercise 24hrs',
                                    },
                                ]}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 12 }}>

                    <ReportHeading
                        text="Test Tools"
                    />

                    <ReportMetaTable
                        data={data}
                        columnSize={{ xs: 12 }}
                        columnA={[
                            {
                                key: 'test.equipment.gasAnalysisUnit',
                                label: 'Gas Analysis Unit',
                            },
                            {
                                key: 'test.equipment.spirometer',
                                label: 'Flow Meter',
                            },
                            data.reportType !== "rmr" ? {
                                key: 'test.equipment.lactateAnalyzer',
                                label: 'Lactate Analyzer',
                            } : null,
                            data.reportType !== "rmr" ? {
                                key: 'test.equipment.glucoseAnalyzer',
                                label: 'Glucose Analyzer',
                            } : null,
                            data.reportType !== "rmr" ? {
                                key: 'test.equipment.fatCaliper',
                                label: 'Body Fat',
                            } : null,
                        ].filter( Boolean )}
                        columnB={[
                            data.reportType !== "rmr" ? {
                                key: 'test.equipment.testApparatus',
                                label: 'Test Apparatus',
                            } : null,
                            data.reportType !== "rmr" ? {
                                key: 'test.equipment.moxyLocationA',
                                label: 'Moxy Sensor Location',
                                render: ( ( value, row ) => {
                                    const equipment = row.test.equipment;
                                    const locationA = equipment?.moxyLocationA;
                                    const locationB = equipment?.moxyLocationB;
                                    if ( !locationA && !locationB ) {
                                        return <>{'-'}</>;
                                    }
                                    return (
                                        <>{locationA}{locationA && locationB ? ', ' : ''}{locationB}</>
                                    )
                                } )
                            } : null,
                            data.reportType !== "rmr" ? {
                                key: data.test.activity == "Running" ? 'test.equipment.shoeWeight' : 'test.equipment.saddleHeight',
                                label: data.test.activity == "Running" ? 'Shoe Weight' : 'Saddle Height',
                                render: ( ( value ) => {
                                    return value && `${ value } ${ data.test.activity == "Cycling" ? " mm" : "" }`
                                } )
                            } : null,
                            data.reportType !== "rmr" ? {
                                key: data.test.activity == "Running" ? 'test.equipment.runningShoes' : 'test.equipment.bikeUsed',
                                label: data.test.activity == "Running" ? 'Shoe Used' : 'Bike Used',
                            } : null,
                            data.reportType == "rmr" ? {
                                key: 'test.equipment.fatCaliper',
                                label: 'Body Fat',
                            } : null,
                            {
                                key: 'test.equipment.hrMonitor',
                                label: 'HR Monitor',
                            },

                        ].filter( Boolean )}
                    />

                </Col>

                <Col col={{ xs: 24 }}>
                    <Space height={"small"} />
                </Col>

                <Col col={{ xs: 6 }}>

                    <ReportHeading
                        text="Health Measures"
                    />

                    <ReportMetaTable
                        data={data}
                        columnSize={{ xs: 24 }}
                        columnA={[
                            {
                                key: 'health.parq',
                                label: 'Par-Q',
                            },
                            {
                                key: 'health.medication',
                                label: 'Medication',
                                format: "nl2br"
                            },
                            {
                                key: 'health.healthIssusesInjuries',
                                label: 'Health Issues',
                                format: "nl2br"
                            },
                            {
                                key: 'general.fitnessLevel',
                                label: 'Fitness Level',
                            },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 6 }}>

                    <ReportHeading
                        text="Nutrition Info"
                    />

                    <ReportMetaTable
                        data={data}
                        columnSize={{ xs: 24 }}
                        columnA={[
                            {
                                key: 'general.timeOfLastMeal',
                                label: 'Time of Last Meal',
                            },
                            {
                                key: 'general.foodToday',
                                label: 'Food Today',
                            },
                            {
                                key: 'general.nutritionDiet',
                                label: 'Nutrition Diet',
                            },
                            {
                                key: 'bio.glycogenDiet',
                                label: 'Carb Intake per Day',
                                render: ( value ) => {
                                    return carbIntakeLabel( value )
                                }
                            },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 12 }}>

                    <SimpleRatingTable
                        title={"Wellness Rating"}
                        rows={[
                            {
                                metric: 'Fatigue',
                                value: data.health.fatigue,
                            },
                            {
                                metric: 'Soreness',
                                value: data.health.soreness,
                            },
                            {
                                metric: 'Sleep',
                                value: data.health.sleep,
                            },
                            {
                                metric: 'Mood',
                                value: data.health.mood,
                            },
                            {
                                metric: 'Stress',
                                value: data.health.stress,
                            },
                        ]}
                        footer={{
                            metric: 'Overall Score',
                            value: data.health.wellnessTotal,
                            rating: data.health.wellnessRating
                        }}
                        form={form}
                    />

                </Col>

                <Col col={{ xs: 12 }}>

                    <PanelChart
                        heading={"Breathing at Rest"}
                        maxCols={3}
                        data={data.history}
                        renderChart={( chartType, chartData ) => (
                            <ChartBreathingRest
                                data={chartData}
                            />
                        )}
                    />

                    <RatingScaleCard
                        metric={"Breathing At Rest - Capacity"}
                        value={data.health.restingFvc}
                        form={form}
                        demographic={data.health.demographic || data.demographic}
                        split={{
                            metric: "Breathing At Rest - Strength",
                            value: data.health.fev1FvcPercentage,
                            altUnit: {
                                value: data.health.restingFev1,
                                suffix: "L",
                                position: "before",
                                decimalPlace: 1
                            }
                        }}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 12 }}>

                    <PanelChart
                        heading={"Resting Blood Pressure (BP)"}
                        maxCols={3}
                        data={data.history}
                        renderChart={( chartType, chartData ) => (
                            <ChartRestingBp
                                data={chartData}
                            />
                        )}
                    />

                    <RatingScaleCard
                        metric={"Resting Blood Pressure - Systolic"}
                        value={data.health.restingBpSystolic}
                        form={form}
                        demographic={data.health.demographic || data.demographic}
                        split={{
                            metric: "Resting Blood Pressure - Diastolic",
                            value: data.health.restingBpDiastolic,
                        }}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 12 }}>

                    <PanelChart
                        heading={"Pre-Test Blood Glucose (BG)"}
                        data={data.history}
                        renderChart={( chartType, chartData ) => (
                            <ChartBloodGlucose
                                data={chartData}
                            />
                        )}
                    />

                    <RatingScaleCard
                        metric={"Blood Glucose"}
                        value={data.health.restingBloodGlucose}
                        form={form}
                        demographic={data.health.demographic || data.demographic}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 12 }}>

                    <PanelChart
                        heading={"Resting Heart Rate (HR)"}
                        maxCols={3}
                        data={data.history}
                        renderChart={( chartType, chartData ) => (
                            <ChartRestingHr
                                data={chartData}
                            />
                        )}
                    />

                    <RatingScaleCard
                        metric={"Resting Heart Rate"}
                        value={data.health.restingHr}
                        form={form}
                        demographic={data.health.demographic || data.demographic}
                        gender={gender}
                    />

                </Col>

            </Row>

        </>

    ), [ data ] );

}

export default TestSessionsReportAthlete;