import { Row, Col, FieldNumber, Seperator, useUpdateEffect } from '@dex/bubl-dash';
import { fixedNum, get } from '@dex/bubl-helpers';
import React, { useMemo } from 'react';
import { getAverage } from '../../../functions/sharedGeneral';

const LabTestsSweatWorkoutSodium: React.FC<any> = (props: any) => {

    const { form, data, prefix, workout, session } = props;

    const key = props._key;

    const chest = get(form.values, `${prefix}.sodiumChest`);
    const back = get(form.values, `${prefix}.sodiumBack`);
    const forearm = get(form.values, `${prefix}.sodiumForearm`);
    const drip = get(form.values, `${prefix}.sodiumDrip`);
    const sweatLossRate = get(form.values, `${prefix}.sweatLossRate`)

    const getSodiumAverage = getAverage([parseFloat(chest), parseFloat(back), parseFloat(forearm), parseFloat(drip)], 0);
    const getSodiumLossRate = fixedNum(getSodiumAverage / 1000 * sweatLossRate, 0);

    useUpdateEffect(() => {
        form.handleChange({ silent: true, name: `${prefix}.totalSodiumLoss`, value: getSodiumAverage });
        form.handleChange({ silent: true, name: `${prefix}.sodiumLossRate`, value: getSodiumLossRate });
    }, [getSodiumAverage, getSodiumLossRate]);

    return useMemo(() => (

        <>

            <Row gutter={8} edge>

                <Col col={{ xs: 24, md: 6 }}>
                    <FieldNumber
                        form={form}
                        name={`${prefix}.sodiumChest`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.sodiumChest}
                        suffix=" mg/L"
                        label='Chest'
                    />
                </Col>

                <Col col={{ xs: 24, md: 6 }}>
                    <FieldNumber
                        form={form}
                        name={`${prefix}.sodiumBack`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.sodiumBack}
                        suffix=" mg/L"
                        label='Back'
                    />
                </Col>

                <Col col={{ xs: 24, md: 6 }}>
                    <FieldNumber
                        form={form}
                        name={`${prefix}.sodiumForearm`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.sodiumForearm}
                        suffix=" mg/L"
                        label='Forearm'
                    />
                </Col>

                <Col col={{ xs: 24, md: 6 }}>
                    <FieldNumber
                        form={form}
                        name={`${prefix}.sodiumDrip`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.sodiumDrip}
                        suffix=" mg/L"
                        label='Drip'
                    />
                </Col>

            </Row>

            <Seperator
                heading={"Calculated"}
                top={"auto"}
                bottom={"auto"}
            />

            <Row gutter={8} edge>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name={`${prefix}.totalSodiumLoss`}
                        defaultValue={getSodiumAverage}
                        key={getSodiumAverage}
                        suffix=" mg/L"
                        label="Average"
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name={`${prefix}.sodiumLossRate`}
                        defaultValue={getSodiumLossRate}
                        key={getSodiumLossRate}
                        suffix=" mg/hr"
                        label='Sodium Loss Rate'
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, data, session]);

}

export default LabTestsSweatWorkoutSodium;
