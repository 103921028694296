import { DataTableCell, InputTableRow, FieldTextTime, InputTableCell, InputTable, Link, Modal, Panel, FieldHidden, Button, Form, FieldCheckbox, FieldNumber, FieldRelationship, useMountEffect, ButtonRow, Row, Col } from '@dex/bubl-dash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { clone, formatNum, get, hashCode, loop } from '@dex/bubl-helpers';
import { calculateRating, calculateTarget, getIcon } from '../../../functions/sharedCoaching';
import CoachingTrainingsEditWeek from './CoachingTrainingsEditWeek';
import CoachingTrainingWeeksApi from '../../CoachingTrainingWeeks/CoachingTrainingWeeksApi';
import { getClockTime } from '../../../functions/sharedTests';

const CoachingTrainingsWeekTable: React.FC<any> = (props: any) => {

    const { form, data, weeks, averages, best, handleClose, handleSubmit, saving, fullscreenHandle, filters } = props;

    const targetWeek = CoachingTrainingWeeksApi.getOne(":id");

    useEffect(() => {

        const training = clone(get(data, 'training', {}));

        if (!form.values.weekEnding) {

            form.handleChange({ reset: true, name: "training", value: training });
            return;

        }

        training.targets = {};

        targetWeek.run({
            url: targetWeek.options.url?.replace(":id", form.values.weekEnding),
            onComplete: (response) => {

                if (response) {

                    training.targets.volumeTotalTime = response.volume?.total?.timeTarget;
                    training.targets.volumeSwimTime = response.volume?.results.swim?.timeTarget;
                    training.targets.volumeBikeTime = response.volume?.results.bike?.timeTarget;
                    training.targets.volumeRunTime = response.volume?.results.run?.timeTarget;
                    training.targets.volumeGymTime = response.volume?.results.gym?.timeTarget;

                    training.targets.volumeTotalDistance = response.volume?.total?.distanceTarget;
                    training.targets.volumeSwimDistance = response.volume?.results.swim?.distanceTarget;
                    training.targets.volumeBikeDistance = response.volume?.results.bike?.distanceTarget;
                    training.targets.volumeRunDistance = response.volume?.results.run?.distanceTarget;

                    training.targets.intensityTotalTime = response.intensity?.totalEndurance?.target;
                    training.targets.intensitySwimTime = response.intensity?.results?.swim?.target;
                    training.targets.intensityBikeTime = response.intensity?.results?.bike?.target;
                    training.targets.intensityRunTime = response.intensity?.results?.run?.target;
                    training.targets.intensityHRTime = response.intensity?.results?.hr?.target;

                }

                form.handleChange({ reset: true, name: "training", value: training });

            }
        });

    }, [form.values.weekEnding]);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const handlePrevClick = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const handleNextClick = () => {
        setCurrentPage((prevPage) => {
            const maxPage = Math.ceil(weeks?.length / itemsPerPage) - 1;
            return Math.min(prevPage + 1, maxPage);
        });
    };

    const startIndex = currentPage * itemsPerPage;

    const displayedWeeks = weeks?.slice(startIndex, startIndex + itemsPerPage);

    return useMemo(() => (

        <InputTable className={"mini-table"} style={{ overflow: "auto" }}>

            <InputTableRow className={'table-row-short'}>

                <DataTableCell
                    className={"table-bdr-none"}
                    colSpan={7}
                    rowSpan={1}
                    alignEnd
                >

                    <>

                        <Form
                            form={form}
                            onSubmit={handleSubmit}
                            autoDiscardChanges={true}
                        >

                            <FieldHidden
                                key={hashCode(filters.values)}
                                form={form}
                                name="filters"
                                defaultValue={filters.values}
                            />

                            <Button
                                label="Save As Current Targets"
                                onClick={form.triggerSubmit}
                                loading={saving}
                                size={"small"}
                            />

                        </Form>

                    </>

                </DataTableCell>

                <DataTableCell>
                    Use Average
                </DataTableCell>

                <DataTableCell />

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.volumeTotalTime}
                        form={form}
                        name='training.useAverage.volumeTotalTime'
                        defaultValue={form.values.training?.useAverage?.volumeTotalTime}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.volumeSwimTime}
                        form={form}
                        name='training.useAverage.volumeSwimTime'
                        defaultValue={form.values.training?.useAverage?.volumeSwimTime}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.volumeBikeTime}
                        form={form}
                        name='training.useAverage.volumeBikeTime'
                        defaultValue={form.values.training?.useAverage?.volumeBikeTime}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.volumeRunTime}
                        form={form}
                        name='training.useAverage.volumeRunTime'
                        defaultValue={form.values.training?.useAverage?.volumeRunTime}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.volumeGymTime}
                        form={form}
                        name='training.useAverage.volumeGymTime'
                        defaultValue={form.values.training?.useAverage?.volumeGymTime}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.volumeSwimDistance}
                        form={form}
                        name='training.useAverage.volumeSwimDistance'
                        defaultValue={form.values.training?.useAverage?.volumeSwimDistance}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.volumeBikeDistance}
                        form={form}
                        name='training.useAverage.volumeBikeDistance'
                        defaultValue={form.values.training?.useAverage?.volumeBikeDistance}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.volumeRunDistance}
                        form={form}
                        name='training.useAverage.volumeRunDistance'
                        defaultValue={form.values.training?.useAverage?.volumeRunDistance}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell />

                <InputTableCell />

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.intensityTotalTime}
                        form={form}
                        name='training.useAverage.intensityTotalTime'
                        defaultValue={form.values.training?.useAverage?.intensityTotalTime}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.intensitySwimTime}
                        form={form}
                        name='training.useAverage.intensitySwimTime'
                        defaultValue={form.values.training?.useAverage?.intensitySwimTime}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.intensityBikeTime}
                        form={form}
                        name='training.useAverage.intensityBikeTime'
                        defaultValue={form.values.training?.useAverage?.intensityBikeTime}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.intensityRunTime}
                        form={form}
                        name='training.useAverage.intensityRunTime'
                        defaultValue={form.values.training?.useAverage?.intensityRunTime}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldCheckbox
                        key={form.values.training?.useAverage?.intensityHRTime}
                        form={form}
                        name='training.useAverage.intensityHRTime'
                        defaultValue={form.values.training?.useAverage?.intensityHRTime}
                        options={[
                            { label: "", value: "average" }
                        ]}
                        singular
                    />
                </InputTableCell>

                <InputTableCell colSpan={10} />

            </InputTableRow>

            <InputTableRow>

                <DataTableCell className="table-bdr-top-none table-bdr-btm-none" colSpan={3} />

                <DataTableCell className="table-bdr-top-none table-bdr-btm-none table-bdr-right-gray" colSpan={4}>

                    <FieldRelationship
                        key={form.values.weekEnding}
                        form={form}
                        name='weekEnding'
                        defaultValue={form.values.weekEnding}
                        placeholder="Current Targets"
                        endpoint={'CoachingTrainingWeeks'}
                        where={{ coachingId: data.id }}
                        order={'weekEndDate DESC'}
                        limit={100}
                        preload={true}
                    />

                </DataTableCell>

                <DataTableCell className="table-bg-gray">
                    <strong>
                        Targets
                    </strong>
                </DataTableCell>

                <InputTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldTextTime
                        key={form.values.training?.targets?.volumeTotalTime}
                        form={form}
                        name="training.targets.volumeTotalTime"
                        defaultValue={form.values.training?.targets?.volumeTotalTime}
                        wrapClass={form.values.training?.useAverage?.volumeTotalTime ? "" : "manual-input"}
                        defer={1000}
                    />
                </InputTableCell>

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldTextTime
                        key={form.values.training?.targets?.volumeSwimTime}
                        form={form}
                        name="training.targets.volumeSwimTime"
                        defaultValue={form.values.training?.targets?.volumeSwimTime}
                        wrapClass={form.values.training?.useAverage?.volumeSwimTime ? "" : "manual-input"}
                        defer={1000}
                    />
                </InputTableCell>

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldTextTime
                        key={form.values.training?.targets?.volumeBikeTime}
                        form={form}
                        name="training.targets.volumeBikeTime"
                        defaultValue={form.values.training?.targets?.volumeBikeTime}
                        wrapClass={form.values.training?.useAverage?.volumeBikeTime ? "" : "manual-input"}
                        defer={1000}
                    />
                </InputTableCell>

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldTextTime
                        key={form.values.training?.targets?.volumeRunTime}
                        form={form}
                        name="training.targets.volumeRunTime"
                        defaultValue={form.values.training?.targets?.volumeRunTime}
                        wrapClass={form.values.training?.useAverage?.volumeRunTime ? "" : "manual-input"}
                        defer={1000}
                    />
                </InputTableCell>

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldTextTime
                        key={form.values.training?.targets?.volumeGymTime}
                        form={form}
                        name="training.targets.volumeGymTime"
                        defaultValue={form.values.training?.targets?.volumeGymTime}
                        wrapClass={form.values.training?.useAverage?.volumeGymTime ? "" : "manual-input"}
                        defer={1000}
                    />
                </InputTableCell>

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldNumber
                        key={form.values.training?.targets?.volumeSwimDistance}
                        form={form}
                        name="training.targets.volumeSwimDistance"
                        defaultValue={form.values.training?.targets?.volumeSwimDistance}
                        wrapClass={form.values.training?.useAverage?.volumeSwimDistance ? "" : "manual-input"}
                        suffix=" m"
                        defer={1000}
                    />
                </InputTableCell>

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldNumber
                        key={form.values.training?.targets?.volumeBikeDistance}
                        form={form}
                        name="training.targets.volumeBikeDistance"
                        defaultValue={form.values.training?.targets?.volumeBikeDistance}
                        wrapClass={form.values.training?.useAverage?.volumeBikeDistance ? "" : "manual-input"}
                        suffix=" km"
                        defer={1000}
                    />
                </InputTableCell>

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldNumber
                        key={form.values.training?.targets?.volumeRunDistance}
                        form={form}
                        name="training.targets.volumeRunDistance"
                        defaultValue={form.values.training?.targets?.volumeRunDistance}
                        wrapClass={form.values.training?.useAverage?.volumeRunDistance ? "" : "manual-input"}
                        suffix=" km"
                        defer={1000}
                    />
                </InputTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldTextTime
                        key={form.values.training?.targets?.intensityTotalTime}
                        form={form}
                        name="training.targets.intensityTotalTime"
                        defaultValue={form.values.training?.targets?.intensityTotalTime}
                        wrapClass={form.values.training?.useAverage?.intensityTotalTime ? "" : "manual-input"}
                        defer={1000}
                    />
                </InputTableCell>

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldTextTime
                        key={form.values.training?.targets?.intensitySwimTime}
                        form={form}
                        name="training.targets.intensitySwimTime"
                        defaultValue={form.values.training?.targets?.intensitySwimTime}
                        wrapClass={form.values.training?.useAverage?.intensitySwimTime ? "" : "manual-input"}
                        defer={1000}
                    />
                </InputTableCell>

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldTextTime
                        key={form.values.training?.targets?.intensityBikeTime}
                        form={form}
                        name="training.targets.intensityBikeTime"
                        defaultValue={form.values.training?.targets?.intensityBikeTime}
                        wrapClass={form.values.training?.useAverage?.intensityBikeTime ? "" : "manual-input"}
                        defer={1000}
                    />
                </InputTableCell>

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldTextTime
                        key={form.values.training?.targets?.intensityRunTime}
                        form={form}
                        name="training.targets.intensityRunTime"
                        defaultValue={form.values.training?.targets?.intensityRunTime}
                        wrapClass={form.values.training?.useAverage?.intensityRunTime ? "" : "manual-input"}
                        defer={1000}
                    />
                </InputTableCell>

                <InputTableCell className="table-bdr-left-gray" style={{ minWidth: "100px" }}>
                    <FieldTextTime
                        key={form.values.training?.targets?.intensityHRTime}
                        form={form}
                        name="training.targets.intensityHRTime"
                        defaultValue={form.values.training?.targets?.intensityHRTime}
                        wrapClass={form.values.training?.useAverage?.intensityHRTime ? "" : "manual-input"}
                        defer={1000}
                    />
                </InputTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    0.9
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    1.1
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    0.9
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    1.1
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    0.9
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    1.1
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    0.9
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    1.1
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    1.1
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    1.2
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    0.8
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    0.9
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

            </InputTableRow>

            <InputTableRow>

                <DataTableCell className="table-bdr-top-none table-bdr-btm-none" />

                <DataTableCell className="table-bdr-top-none table-bdr-btm-none table-bdr-right-gray" colSpan={6} >

                    <Row edge gutter={8} justify='end'>

                        {!fullscreenHandle.active &&
                            <Col>
                                <Button
                                    onClick={handlePrevClick}
                                    disabled={currentPage === 0}
                                    label="Previous Page"
                                    size="small"
                                    type={currentPage === 0 ? "disabled" : 'faded'}
                                />
                            </Col>
                        }

                        {!fullscreenHandle.active &&
                            <Col>
                                <Button
                                    onClick={handleNextClick}
                                    disabled={currentPage === Math.ceil(weeks?.length / itemsPerPage) - 1}
                                    label="Next Page"
                                    size="small"
                                    type={currentPage === Math.ceil(weeks?.length / itemsPerPage) - 1 ? "disabled" : 'faded'}
                                />
                            </Col>
                        }

                        {!fullscreenHandle.active ?
                            <Col>
                                <Button
                                    onClick={fullscreenHandle.enter}
                                    label="Enter Fullscreen"
                                    size="small"
                                    type={'faded'}
                                />
                            </Col>
                            :
                            <Col>
                                <Button
                                    onClick={fullscreenHandle.exit}
                                    label="Exit Fullscreen"
                                    size="small"
                                    type={'faded'}
                                />
                            </Col>
                        }

                    </Row>

                </DataTableCell>

                <DataTableCell className="table-bg-gray" >
                    <strong>Max/Best</strong>
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"}>
                    {best?.volumeTotalTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.volumeSwimTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.volumeBikeTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.volumeRunTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.volumeGymTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {formatNum(best?.volumeSwimdistance)}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.volumeBikedistance}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.volumeRundistance}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.workoutsAll}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.intensityTotalTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.intensitySwimTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.intensityBikeTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.intensityRunTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.intensityTotalHrTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.tssScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.fitnessScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.fatigueScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.formScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.hrPeakBikeTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.hrPeakRunTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.avgRpe}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.avgFelt}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.elevationScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.workScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.swimStimulusVolume}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.swimStimulusIntensity}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.swimStimulusAverage}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.bikeStimulusVolume}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.bikeStimulusIntensity}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.bikeStimulusAverage}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.runStimulusVolume}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.runStimulusIntensity}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.runStimulusAverage}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.totalStimulusVolume}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.totalStimulusIntensity}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.totalStimulusAverage}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.fitnessStimulus}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.bodyStressHr}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.totalStimulusAverage}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.bodyStressRpe}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.bodyStressFelt}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {best?.bodyStress}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

            </InputTableRow>

            <InputTableRow>

                <DataTableCell className="table-bdr-top-none table-bdr-btm-none table-bdr-right-gray" colSpan={7} />

                <DataTableCell className="table-bg-gray" >
                    <strong>
                        Average
                    </strong>
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray  " + (form.values.training?.useAverage?.volumeTotalTime ? "table-highlight" : "")}>
                    {averages?.volumeTotalTime}
                    <FieldHidden
                        form={form}
                        name="weekData.volumeTotalTime"
                        value={averages?.volumeTotalTime}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.volumeSwimTime ? "table-highlight" : "")} >
                    {averages?.volumeSwimTime}
                    <FieldHidden
                        form={form}
                        name="weekData.volumeSwimTime"
                        value={averages?.volumeSwimTime}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.volumeBikeTime ? "table-highlight" : "")} >
                    {averages?.volumeBikeTime}
                    <FieldHidden
                        form={form}
                        name="weekData.volumeBikeTime"
                        value={averages?.volumeBikeTime}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.volumeRunTime ? "table-highlight" : "")} >
                    {averages?.volumeRunTime}
                    <FieldHidden
                        form={form}
                        name="weekData.volumeRunTime"
                        value={averages?.volumeRunTime}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.volumeGymTime ? "table-highlight" : "")} >
                    {averages?.volumeGymTime}
                    <FieldHidden
                        form={form}
                        name="weekData.volumeGymTime"
                        value={averages?.volumeGymTime}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.volumeSwimDistance ? "table-highlight" : "")} >
                    {formatNum(averages?.volumeSwimdistance)}
                    <FieldHidden
                        form={form}
                        name="weekData.volumeSwimDistance"
                        value={averages?.volumeSwimdistance}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.volumeBikeDistance ? "table-highlight" : "")} >
                    {averages?.volumeBikedistance}
                    <FieldHidden
                        form={form}
                        name="weekData.volumeBikeDistance"
                        value={averages?.volumeBikedistance}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.volumeRunDistance ? "table-highlight" : "")} >
                    {averages?.volumeRundistance}
                    <FieldHidden
                        form={form}
                        name="weekData.volumeRunDistance"
                        value={averages?.volumeRundistance}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.workoutsAll}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.intensityTotalTime ? "table-highlight" : "")} >
                    {averages?.intensityTotalTime}
                    <FieldHidden
                        form={form}
                        name="weekData.intensityTotalTime"
                        value={averages?.intensityTotalTime}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.intensitySwimTime ? "table-highlight" : "")} >
                    {averages?.intensitySwimTime}
                    <FieldHidden
                        form={form}
                        name="weekData.intensitySwimTime"
                        value={averages?.intensitySwimTime}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.intensityBikeTime ? "table-highlight" : "")} >
                    {averages?.intensityBikeTime}
                    <FieldHidden
                        form={form}
                        name="weekData.intensityBikeTime"
                        value={averages?.intensityBikeTime}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.intensityRunTime ? "table-highlight" : "")} >
                    {averages?.intensityRunTime}
                    <FieldHidden
                        form={form}
                        name="weekData.intensityRunTime"
                        value={averages?.intensityRunTime}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray " + (form.values.training?.useAverage?.intensityHRTime ? "table-highlight" : "")} >
                    {averages?.intensityTotalHrTime}
                    <FieldHidden
                        form={form}
                        name="weekData.intensityHRTime"
                        value={averages?.intensityTotalHrTime}
                    />
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.tssScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.fitnessScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.fatigueScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.formScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.hrPeakBikeTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.hrPeakRunTime}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.avgRpe}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.avgFelt}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.elevationScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.workScore}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.swimStimulusVolume}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.swimStimulusIntensity}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.swimStimulusAverage}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.bikeStimulusVolume}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.bikeStimulusIntensity}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.bikeStimulusAverage}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.runStimulusVolume}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.runStimulusIntensity}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.runStimulusAverage}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.totalStimulusVolume}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.totalStimulusIntensity}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.totalStimulusAverage}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.fitnessStimulus}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.bodyStressHr}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.totalStimulusAverage}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.bodyStressRpe}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.bodyStressFelt}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} >
                    {averages?.bodyStress}
                </DataTableCell>

                <DataTableCell className={"table-bdr-left-gray table-bg-gray"} />

            </InputTableRow>

            <InputTableRow >

                <DataTableCell />

                <DataTableCell />

                <DataTableCell />

                <DataTableCell />

                <DataTableCell />

                <DataTableCell />

                <DataTableCell />

                <DataTableCell />

                <DataTableCell className="table-bdr-left-gray">
                    <strong style={{ whiteSpace: "pre-wrap" }}>Volume Target</strong>
                </DataTableCell>

                <DataTableCell colSpan={5} className="table-bdr-left-gray">
                    <strong>Actual Time [hh:mm]</strong>
                </DataTableCell>

                <DataTableCell colSpan={3} className="table-bdr-left-gray">
                    <strong>Actual Distance [km]</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>Total</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray" >
                    <strong style={{ whiteSpace: "pre-wrap" }}>Intensity Target</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray" />

                <DataTableCell colSpan={4} className="table-bdr-left-gray">
                    <strong style={{ whiteSpace: "pre-wrap" }}>Intensity [hh:mm]</strong>
                </DataTableCell>

                <DataTableCell colSpan={4} className="table-bdr-left-gray">
                    <strong style={{ whiteSpace: "pre-wrap" }}>Training Load</strong>
                </DataTableCell>

                <DataTableCell colSpan={2} className="table-bdr-left-gray">
                    <strong style={{ whiteSpace: "pre-wrap" }}>Peak Hr [bpm]</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong style={{ whiteSpace: "pre-wrap" }}>RPE</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong style={{ whiteSpace: "pre-wrap" }}>Felt</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong style={{ whiteSpace: "pre-wrap" }}> Elevation Gain</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong style={{ whiteSpace: "pre-wrap" }}> Work</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray" colSpan={4}>
                    <strong >Training Stimulus - Swim</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray" colSpan={4}>
                    <strong >Training Stimulus - Bike</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray" colSpan={4}>
                    <strong >Training Stimulus - Run</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray" colSpan={4}>
                    <strong >Training Stimulus - Total</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray" colSpan={2}>
                    <strong >Fitness</strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray" colSpan={6}>
                    <strong >Body Stress</strong>
                </DataTableCell>

            </InputTableRow>

            <InputTableRow className={"table-bg-gray "} >

                <DataTableCell />

                <DataTableCell>
                    <strong>
                        Sport
                    </strong>
                </DataTableCell>

                <DataTableCell>
                    <strong>
                        Calendar #
                    </strong>
                </DataTableCell>

                <DataTableCell>
                    <strong>
                        Phase
                    </strong>
                </DataTableCell>

                <DataTableCell>
                    <strong>
                        Block
                    </strong>
                </DataTableCell>

                <DataTableCell>
                    <strong>
                        Block #
                    </strong>
                </DataTableCell>

                <DataTableCell>
                    <strong>
                        Week #
                    </strong>
                </DataTableCell>

                <DataTableCell>
                    <strong>
                        End Date
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Met
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Total
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Swim
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Bike
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Run
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Gym
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Swim [m]
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Bike
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Run
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Workouts
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Met
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Total
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Swim
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Bike
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Run
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        HR
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        TSS
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Fitness
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Fatigue
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Form
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Bike
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Run
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Avg
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Avg
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        m
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        kj
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Vol
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Int
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Avg
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Result
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Vol
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Int
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Avg
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Result
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Vol
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Int
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Avg
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Result
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Vol
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Int
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Avg
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Result
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Fitness
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Avg
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        HR
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong style={{ whiteSpace: "pre-wrap" }}>
                        Total Stimulus
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        RPE
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Felt
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Avg
                    </strong>
                </DataTableCell>

                <DataTableCell className="table-bdr-left-gray">
                    <strong>
                        Result
                    </strong>
                </DataTableCell>

            </InputTableRow>

            {weeks && weeks.length > 0 &&
                <FilteredWeekRow
                    data={data}
                    form={form}
                    weeks={fullscreenHandle.active ? weeks : displayedWeeks}
                    averages={averages}
                    best={best}
                    handleClose={handleClose}
                    fullscreenHandle={fullscreenHandle}
                />
            }

        </InputTable >

    ), [form.hash, data, weeks, saving, currentPage, fullscreenHandle.active])
}

const FilteredWeekRow: React.FC<any> = (props: any) => {

    const { form, data, weeks, averages, best, handleClose, fullscreenHandle } = props;

    const getEvent = (id) => {

        let name;

        loop(form.values.events, (event) => {
            if (event.id == id) name = event.type
        })

        return name;

    }

    const handleRefresh = useCallback(() => {

        setEditWeekId(null);

        handleClose();

        // form.triggerSubmit();

    }, []);

    const [editWeekId, setEditWeekId] = useState<any>(null);

    const handleOpen = (id) => {

        fullscreenHandle.exit();

        setEditWeekId(id);

    };

    return (
        <>

            {editWeekId && editWeekId &&
                <Modal
                    show={true}
                    onClose={handleRefresh}
                    width={1920}
                >
                    <Panel heading={"Edit Week"}>
                        <CoachingTrainingsEditWeek
                            data={data}
                            weekId={editWeekId}
                            handleClose={handleRefresh}
                        />
                    </Panel>
                </Modal>
            }

            {weeks.map((w, i) => (

                <InputTableRow key={i} className={`table-bg-zone-${i % 2 === 0 ? 3 : ""}`} >

                    <DataTableCell>
                        <Link onClick={handleOpen.bind(null, w.id)}>
                            Edit Week
                        </Link>
                    </DataTableCell>

                    <DataTableCell>
                        {getEvent(w.week.eventId)}
                    </DataTableCell>

                    <DataTableCell>
                        {w.weekNo}
                    </DataTableCell>

                    <DataTableCell>
                        {w.week.phase}
                    </DataTableCell>

                    <DataTableCell>
                        {w.week.block}
                    </DataTableCell>

                    <DataTableCell>
                        {w.week.blockNum}
                    </DataTableCell>

                    <DataTableCell>
                        {w.week.week}
                    </DataTableCell>

                    <DataTableCell>
                        {w.week.endDate}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={"table-bdr-left-gray"}>
                        {getIcon(getClockTime(w?.volumeTotalTimeTarget / 60), getClockTime(w?.volumeTotalTime / 60), calculateTarget(getClockTime(w?.volumeTotalTimeTarget / 60), getClockTime(w?.volumeTotalTime / 60)))?.icon}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={getClockTime(w?.volumeTotalTime / 60) == best?.volumeTotalTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {getClockTime(w?.volumeTotalTime / 60)}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={getClockTime(w?.volumeSwimTime / 60) == best?.volumeSwimTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {getClockTime(w?.volumeSwimTime / 60)}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={getClockTime(w?.volumeBikeTime / 60) == best?.volumeBikeTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {getClockTime(w?.volumeBikeTime / 60)}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={getClockTime(w?.volumeRunTime / 60) == best?.volumeRunTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {getClockTime(w?.volumeRunTime / 60)}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.volumeGymTime == best?.volumeGymTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {getClockTime(w?.volumeGymTime / 60)}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.volumeSwimdistance == best?.volumeSwimdistance ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {formatNum(w?.volumeSwimdistance)}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.volumeBikedistance == best?.volumeBikedistance ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.volumeBikedistance || 0}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.volumeRundistance == best?.volumeRundistance ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.volumeRundistance || 0}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.workoutsAll == best?.workoutsAll ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.workoutsAll}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={"table-bdr-left-gray"}>
                        {getIcon(getClockTime(w?.intensityTotalTimeTarget / 60), getClockTime(w?.intensityTotalTime / 60), calculateTarget(getClockTime(w?.intensityTotalTimeTarget / 60), getClockTime(w?.intensityTotalTime / 60)), "intensity")?.icon}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={getClockTime(w?.intensityTotalTime / 60) == best?.intensityTotalTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {getClockTime(w?.intensityTotalTime / 60)}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={getClockTime(w?.intensitySwimTime / 60) == best?.intensitySwimTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {getClockTime(w?.intensitySwimTime / 60)}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={getClockTime(w?.intensityBikeTime / 60) == best?.intensityBikeTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {getClockTime(w?.intensityBikeTime / 60)}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={getClockTime(w?.intensityRunTime / 60) == best?.intensityRunTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {getClockTime(w?.intensityRunTime / 60)}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={getClockTime(w?.intensityTotalHrTime / 60) == best?.intensityTotalHrTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {getClockTime(w?.intensityTotalHrTime / 60)}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.tssScore == best?.tssScore ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.tssScore || 0}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.fitnessScore == best?.fitnessScore ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.fitnessScore || 0}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.fatigueScore == best?.fatigueScore ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.fatigueScore || 0}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.formScore == best?.formScore ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.formScore || 0}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.hrPeakBikeTime == best?.hrPeakBikeTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.hrPeakBikeTime || 0}
                    </DataTableCell >

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.hrPeakBikeTime == best?.hrPeakBikeTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.hrPeakRunTime || 0}
                    </DataTableCell >

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.avgRpe == best?.avgRpe ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.avgRpe || 0}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.avgFelt == best?.avgFelt ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.avgFelt || 0}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.elevationScore == best?.elevationScore ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.elevationScore || 0}
                    </DataTableCell >

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.workScore == best?.workScore ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.workScore || 0}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.swimStimulusVolume == best?.swimStimulusVolume ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.swimStimulusVolume}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.swimStimulusIntensity == best?.swimStimulusIntensity ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.swimStimulusIntensity}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.swimStimulusAverage == best?.swimStimulusAverage ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.swimStimulusAverage}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className="table-bdr-left-gray">
                        {calculateRating(w?.swimStimulusAverage, "stimulus")}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.bikeStimulusVolume == best?.bikeStimulusVolume ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.bikeStimulusVolume}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.bikeStimulusIntensity == best?.bikeStimulusIntensity ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.bikeStimulusIntensity}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.bikeStimulusAverage == best?.bikeStimulusAverage ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.bikeStimulusAverage}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className="table-bdr-left-gray">
                        {calculateRating(w?.bikeStimulusAverage, "stimulus")}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.runStimulusVolume == best?.runStimulusVolume ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.runStimulusVolume}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.runStimulusIntensity == best?.runStimulusIntensity ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.runStimulusIntensity}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.runStimulusAverage == best?.runStimulusAverage ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.runStimulusAverage}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className="table-bdr-left-gray">
                        {calculateRating(w?.runStimulusAverage, "stimulus")}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.totalStimulusVolume == best?.totalStimulusVolume ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.totalStimulusVolume}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.totalStimulusIntensity == best?.totalStimulusIntensity ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.totalStimulusIntensity}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.totalStimulusAverage == best?.totalStimulusAverage ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.totalStimulusAverage}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className="table-bdr-left-gray" >
                        {calculateRating(w?.totalStimulusAverage, "stimulus")}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.fitnessStimulus == best?.fitnessStimulus ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.fitnessStimulus}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className="table-bdr-left-gray" >
                        {calculateRating(w?.fitnessStimulus, "hr")}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.bodyStressHr == best?.bodyStressHr ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.bodyStressHr}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.totalStimulusAverage == best?.totalStimulusAverage ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.totalStimulusAverage}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.bodyStressRpe == best?.bodyStressRpe ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.bodyStressRpe || 0}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.bodyStressFelt == best?.bodyStressFelt ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.bodyStressFelt || 0}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className={w?.bodyStress == best?.bodyStress ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"} >
                        {w?.bodyStress}
                    </DataTableCell>

                    <DataTableCell style={{ minWidth: "90px" }} className="table-bdr-left-gray">
                        {calculateRating(w?.bodyStress, "body stress")}
                    </DataTableCell>

                </InputTableRow >

            ))}
        </>

    )
}

export default CoachingTrainingsWeekTable