import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import RatingScalesApi from './RatingScalesApi';
import RatingScaleCreateNew from './Components/RatingScaleCreateNew';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New Rating Scale",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: RatingScaleCreateNew,
                getCreate: RatingScalesApi.create,
                getClone: RatingScalesApi.getOne,
                defaults: { steps: [], bestScore: "highest", decimalPlaces: 1, },
                successMessage: "Rating Scale Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey,
                rowProps: { gutter: "auto", },
                colProps: { className: "", col: { xs: 24 } }
            }
        },
    });

}