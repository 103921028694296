import React, { useMemo } from 'react';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import { date, loop } from '@dex/bubl-helpers';
import { Space } from '@dex/bubl-dash';

const TestSessionsReportSweatTestComment: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const archive = data.test.comment?.archive || [];
    const filterArchive = archive.filter((item) => item.show == true);

    return useMemo(() => (
        <>

            {data?.summary?.comments &&
                <ReportDesc
                    label=""
                    value={data?.summary.comments || ""}
                />
            }

            {data.test?.comment?.summary &&
                <ReportDesc
                    label=""
                    value={data.test?.comment?.summary || ""}
                />
            }

            {filterArchive.map((item, index) => (

                <>
                    <Space />

                    {date(item.date)}

                    <ReportDesc
                        label=""
                        value={item.summary || ""}
                    />

                </>

            ))}
        </>
    ), [])
}

export default TestSessionsReportSweatTestComment;