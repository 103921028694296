import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import RatingCreator from '../../../elements/RatingCreator/RatingCreator';
import FieldRatingScalesCreateEditForm from './FieldRatingScalesCreateEditForm';

const FieldRatingScaleCreateNew: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    return useMemo(() => (

        <Row gutter={'auto'} edge={true}>

            <Col col={{ xs: 24, lg: 16 }}>

                <FieldRatingScalesCreateEditForm
                    form={form}
                    handleSubmit={handleSubmit}
                    process={process}
                />

            </Col>

            <Col col={{ xs: 24, lg: 8 }}>

                <RatingCreator
                    mainForm={form}
                    gender={false}
                    level={false}
                    displayAs={"scale"}
                />

            </Col>

        </Row>

    ), [props, form.hash]);

}

export default FieldRatingScaleCreateNew;
