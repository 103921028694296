import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, CardTabs, Modal, Panel, Space } from '@dex/bubl-dash';
import LabTestsAddNew from '../LabTests/LabTestsAddNew';
import LabTestsSingleEdit from '../../LabTests/Components/LabTestsSingleEdit';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';
import EditableContext from '../../../context/EditableContext';
import { loop } from '@dex/bubl-helpers';

const TestSessionsEditTabsTests: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    const editable = useContext(EditableContext);

    const app = useApp();

    const [nav] = app.store("nav");

    const [testTab, setTestTab] = app.store("lab-test-tab", null);

    const handleAddTest = useCallback((id) => {

        if (id && typeof id === "string") {

            setTestTab(id);
            app.navigate(nav, 'update', {});

        } else if (data.labTests && data.labTests.length) {

            setTestTab(data.labTests[0].id);

        } else {

            setTestTab(null);

        }

    }, [data.labTestIds]);

    const tabs = useMemo(() => {

        const tabs: any = [];

        data.labTests.map((labTest, index) => {

            tabs.push({
                label: [labTest.type].filter(Boolean).join(", "),
                desc: [labTest.activity, labTest.intensity].filter(Boolean).join(", "),  //, ${date(labTest.date)}
                name: labTest.id,
            })

        });

        if (editable) {
            tabs.push({
                label: "Add Lab Test",
                name: "addTest"
            })
        }

        let hasActiveTab = false;

        loop(tabs, (item) => {

            if (testTab === item.name) hasActiveTab = true;

        })

        if (!hasActiveTab && tabs[0] && tabs[0].name !== "addTest") setTestTab(tabs[0].name);

        return tabs;

    }, [data.labTestIds]);

    const [save, setSave] = useState(false);

    return useMemo(() => (

        <>

            {!editable &&
                <TestSessionsEditTabsTestsInner
                    data={data}
                    tabs={tabs}
                    tab={testTab}
                    setTab={setTestTab}
                    save={save}
                    setSave={setSave}
                    process={process}
                />
            }

            {editable &&
                <TestSessionsEditTabsTestsPanel
                    tab={testTab}
                    save={save}
                    setSave={setSave}
                >

                    <TestSessionsEditTabsTestsInner
                        data={data}
                        tabs={tabs}
                        tab={testTab}
                        setTab={setTestTab}
                        save={save}
                        setSave={setSave}
                        process={process}
                    />

                    {testTab === "addTest" &&

                        <Modal
                            show={true}
                            onClose={handleAddTest}
                            width={720}
                        >

                            <LabTestsAddNew
                                data={data}
                                onComplete={handleAddTest}
                            />

                        </Modal>

                    }

                </TestSessionsEditTabsTestsPanel>

            }

        </>

    ), [props, tabs, testTab, save]);

}



const TestSessionsEditTabsTestsPanel: React.FC<any> = (props: any) => {

    const { tab, save, setSave } = props;

    return (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Lab Tests"}
                    actions={
                        <>
                            {tab && tab !== "addTest" &&
                                <Button
                                    label={"Save"}
                                    onClick={setSave.bind(null, true)}
                                    loading={save}
                                />
                            }
                        </>
                    }
                >

                    {props.children}

                </Panel>

            </Col>

        </Row>



    )

};

const TestSessionsEditTabsTestsInner: React.FC<any> = (props: any) => {

    const { tab, setTab, tabs, data, save, setSave, process } = props;

    return (
        <>

            <CardTabs
                activeTab={tab}
                setTab={setTab}
                tabs={tabs}
            />

            <Space />

            {data.labTests.map((labTest, index) => {

                return (

                    <React.Fragment key={index}>

                        {tab == labTest.id &&
                            <LabTestsSingleEdit
                                id={labTest.id}
                                session={data}
                                save={save}
                                setSave={setSave}
                                editable={process ? true : false}
                            />
                        }

                    </React.Fragment>

                )

            })}

        </>

    )

};

export default TestSessionsEditTabsTests;
