import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import RatingScalesApi from '../RatingScalesApi';
import RatingScalesCreateEditForm from './RatingScalesCreateEditForm';
import RatingCreator from '../../../elements/RatingCreator/RatingCreator';

const RatingScalesSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const form = useForm(data);

    const update = RatingScalesApi.update(data.id, "update", {
        onComplete: (data) => {

            app.alert('Rating Scale Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24, lg: 17 }}
            >

                <RatingScalesCreateEditForm
                    mode={"edit"}
                    form={form}
                    data={data}
                    handleSubmit={handleSubmit}
                    process={update}
                />

            </Col>

            <Col
                col={{ xs: 24, lg: 7 }}
            >

                <RatingCreator
                    mainForm={form}
                    gender={true}
                    level={true}
                    displayAs={form.values.displayAs}
                />

            </Col>

        </Row>

    ), [data, form.hash, update]);

}

export default RatingScalesSingleEdit;
