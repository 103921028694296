import { Col, Line, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import styles from "./GaitReportPosture.module.scss";
import { ColoredHeader } from './GaitReportPage';
import GaitReportComment from '../../../elements/GaitReportComment/GaitReportComment';

const GaitReportPosture: React.FC<any> = ( props: any ) => {

    const { data, form } = props;

    let gender = data.athlete?.gender;

    return useMemo( () => (

        <Row gutter={20} edge>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Standing Posture"
                />

            </Col>

            <Col col={{ xs: 24 }}>
                <p className='sub-heading-title'>Postural Analysis</p>
            </Col>

            <Col col={{ xs: 6 }}>

                <div className={styles.imageWrapper}>

                    <img
                        alt="posture"
                        src={require( `../../../assets/images/${ gender == "male" ? "PostureMale" : "PostureFemale" }.png` )}
                        className={styles.image}
                    />

                </div>

            </Col>

            <Col col={{ xs: 12 }}>

                <ReportMetaTable
                    data={data}
                    columnSize={{ xs: 12 }}
                    columnA={[
                        {
                            key: 'posture.pedalStance',
                            label: 'Pedal Stance'
                        }
                    ]}
                    columnB={[
                        {
                            key: 'posture.thighGap',
                            label: 'Thigh Gap'
                        }
                    ]}
                />

                {data.posture.analysis &&
                    <Row edge gutter={2}>

                        <Col col={{ xs: 24 }}>
                            <p className={styles.tableHeading}>Analysis:</p>
                        </Col>

                        {data.posture.analysis.map( ( analysis, i ) => (
                            <Col col={{ xs: 12 }} key={i} >
                                <small style={{ marginLeft: i % 2 ? "8px" : "" }}>
                                    {analysis}
                                </small>
                            </Col>
                        ) )}

                    </Row>
                }

            </Col>

            <Col col={{ xs: 6 }}>

                <GaitReportComment
                    label={`Comments`}
                    value={data.posture.comments}
                />

            </Col>

            <FootSection
                data={data}
            />

        </Row >

    ), [ data ] );

}

const FootSection: React.FC<any> = ( props: any ) => {

    const { data } = props;

    return (
        <>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Foot"
                />

            </Col>

            <Col col={{ xs: 6 }}>

                <p className='sub-heading-title'>Left Foot</p>

                <ReportMetaTable
                    data={data}
                    columnSize={{ xs: 24 }}
                    columnA={[
                        {
                            key: 'foot.widthLeft',
                            label: 'Width',
                            render: ( value ) => `${ value ? value : "-" } ( ${ data.foot.widthMeasureLeft ? data.foot.widthMeasureLeft : "-" } mm)`
                        },
                        {
                            key: 'foot.footLengthLeft',
                            label: 'Length',
                            format: "number",
                            suffix: " mm"
                        },
                        {
                            key: 'foot.footWalkLeft',
                            label: 'Walking Posture',
                        },
                        {
                            key: 'foot.measuredShoeLeft',
                            label: 'Measured Shoe Size',
                            format: "number",
                            suffix: " EUR"
                        }
                    ]}
                />

            </Col>

            <Col col={{ xs: 6 }}>
                <FootSectionImageDetails
                    data={data}
                    image={require( `../../../assets/images/Foot/Foot${ data.foot.leftArchImg }.png` )}
                    details={[
                        {
                            label: "Arch Height & Length",
                            key: "foot.archHeightLeft",
                            render: ( value ) => `${ value ? value : "-" } ${ data.foot.archLengthLeft ? data.foot.archLengthLeft : "-" }`
                        },
                        {
                            label: "Arch Flexibility",
                            key: "foot.archFlexibilityLeft",
                        }
                    ]}
                />
            </Col>

            <Col col={{ xs: 6 }}>
                <FootSectionImageDetails
                    data={data}
                    image={require( `../../../assets/images/Foot/Foot${ data.foot.leftForeFootImg }.png` )}
                    details={[
                        {
                            label: "Fore Foot",
                            key: "foot.forefootLeft",
                        }
                    ]}
                />
            </Col>

            <Col col={{ xs: 6 }}>
                <FootSectionImageDetails
                    data={data}
                    image={require( `../../../assets/images/Foot/Foot${ data.foot.leftHeel }.png` )}
                    details={[
                        {
                            label: "Heel (Loaded)",
                            key: "foot.heelLeft",
                        },
                        {
                            label: "Heel (Unloaded)",
                            key: "foot.footInversionLeft",
                        }
                    ]}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <Line top={null} bottom={null} />

            </Col>

            <Col col={{ xs: 6 }}>

                <p className='sub-heading-title'>Right Foot</p>

                <ReportMetaTable
                    data={data}
                    columnSize={{ xs: 24 }}
                    columnA={[
                        {
                            key: 'foot.widthRight',
                            label: 'Width',
                            render: ( value ) => `${ value ? value : "-" } ( ${ data.foot.widthMeasureRight ? data.foot.widthMeasureRight : "-" } mm)`
                        },
                        {
                            key: 'foot.footLengthRight',
                            label: 'Length',
                            format: "number",
                            suffix: " mm"
                        },
                        {
                            key: 'foot.footWalkRight',
                            label: 'Walking Posture',
                        },
                        {
                            key: 'foot.measuredShoeRight',
                            label: 'Measured Shoe Size',
                            format: "number",
                            suffix: " EUR"
                        }
                    ]}
                />

            </Col>

            <Col col={{ xs: 6 }}>
                <FootSectionImageDetails
                    data={data}
                    image={require( `../../../assets/images/Foot/Foot${ data.foot.rightArchImg }.png` )}
                    details={[
                        {
                            label: "Arch Height & Length",
                            key: "foot.archHeightRight",
                            render: ( value ) => `${ value ? value : "-" } ${ data.foot.archLengthRight ? data.foot.archLengthRight : "-" }`
                        },
                        {
                            label: "Arch Flexibility",
                            key: "foot.archFlexibilityRight",
                        }
                    ]}
                />
            </Col>

            <Col col={{ xs: 6 }}>
                <FootSectionImageDetails
                    data={data}
                    image={require( `../../../assets/images/Foot/Foot${ data.foot.rightForeFootImg }.png` )}
                    details={[
                        {
                            label: "Fore Foot",
                            key: "foot.forefootRight",
                        }
                    ]}
                />
            </Col>

            <Col col={{ xs: 6 }}>
                <FootSectionImageDetails
                    data={data}
                    image={require( `../../../assets/images/Foot/Foot${ data.foot.rightHeel }.png` )}
                    details={[
                        {
                            label: "Heel (Loaded)",
                            key: "foot.heelRight",
                        },
                        {
                            label: "Heel (Unloaded)",
                            key: "foot.footInversionRight",
                        }
                    ]}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <Line top={null} bottom={null} />

            </Col>

            <Col col={{ xs: 6 }}>

                <ReportMetaTable
                    data={data}
                    columnSize={{ xs: 24 }}
                    columnA={[
                        {
                            key: 'foot.footLengthDiff',
                            label: 'Foot Length Difference',
                        },
                        {
                            key: 'foot.archLengthDiff',
                            label: 'Arc Length Difference',
                        },
                        {
                            key: 'foot.widthDiff',
                            label: 'Foot Width Difference',
                        },
                    ]}
                />

            </Col>

            <Col col={{ xs: 18 }}>

                <GaitReportComment
                    label={`Comment`}
                    value={data.foot.comments}
                />

            </Col>

        </>
    )
}

const FootSectionImageDetails: React.FC<any> = ( props: any ) => {

    const { data, details, image } = props;

    return (
        <>
            <div className={styles.imageContainer}>

                <img
                    src={image}
                />

            </div>

            <ReportMetaTable
                data={data}
                columnSize={{ xs: 24 }}
                columnA={details}
            />

        </>
    )
}

export default GaitReportPosture;