import { Col, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import { get } from '@dex/bubl-helpers';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import * as _ from "lodash";
import SweatTestAverageChart from '../../../elements/SweatTestAverageChart/SweatTestAverageChart';
import { changeDemographic } from '../../../functions/sharedTests';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportSweatTestFluid: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const fluidData = data.test.fluid || [];
    const gender = data.athlete.gender;

    const fluidArray = [
        {},
        ...fluidData.avgFluid,
        fluidData.avgFluid.length > 1 ?
            { avg: fluidData.fluidLossRating, type: "Average" }
            :
            null
    ].filter(Boolean);

    const sodiumArray = [
        {},
        ...fluidData.avgSodium,
        fluidData.avgSodium.length > 1 ?
            { avg: fluidData.sodiumLossRating, type: "Average" }
            :
            null
    ].filter(Boolean);

    return useMemo(() => (

        <>

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 24 }}>

                    <ReportHeading
                        text={data.athlete.fullName}
                    />

                    <ReportMetaTable
                        data={data}
                        columnSize={{ xs: 24, sm: 8 }}
                        columnA={[
                            {
                                key: 'athlete.gender',
                                label: 'Gender',
                                format: "titleCase"
                            },
                            {
                                key: 'athlete.age',
                                label: 'Age',
                                format: "number",
                                suffix: " yrs",
                            },
                            {
                                key: 'athlete.mainSport',
                                label: 'Main Sport',
                                format: "titleCase"
                            },
                        ]}
                        columnB={[
                            {
                                key: 'demographic',
                                label: 'Level',
                                format: "titleCase",
                                render: (item) => {
                                    return <>{changeDemographic(item)}</>
                                }
                            },
                            {
                                key: 'test.rehydration.avgWeight',
                                label: 'Weight',
                                format: "number",
                                suffix: " kg",
                            },
                            {
                                key: 'test.rehydration.avgHeight',
                                label: 'Height',
                                format: "number",
                                suffix: " cm",
                            },
                        ]}
                        columnC={[
                            {
                                key: 'test.currentMax.currentHrMax',
                                label: 'Hrmax',
                                format: "number",
                                suffix: " bpm",
                            },
                            {
                                key: 'test.currentMax.currentVo2Max',
                                label: 'Vo2max',
                                format: "number",
                                suffix: "  ml/kg*min",
                            },
                            {
                                key: 'test.rehydration.sweatMeter',
                                label: 'Sweat Meter',
                                format: "titleCase"
                            },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 24 }}>
                    <ReportHeading
                        text="Measurement Results"
                    />
                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <SweatTestAverageChart
                        data={fluidArray}
                        suffix="ml/hr"
                        title="Your Fluid Loss Rate"
                        img={true}
                    />


                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label='Your Fluid Loss'
                        value={fluidData.fluidDescription}
                    />

                    <Space />

                    <RatingScaleCard
                        metric="Fluid Loss Rating"
                        value={get(fluidData, "fluidLossRating")}
                        demographic={fluidData.fluidLossRatingScale || data.demographic}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>


                    <SweatTestAverageChart
                        data={sodiumArray}
                        suffix="mg/hr"
                        title="Your Sodium Loss Rate"
                        img={true}
                    />


                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label='Your Sodium Loss'
                        value={fluidData.sodiumDescription}
                    />

                    <Space />

                    <RatingScaleCard
                        metric="Sodium Loss Rating"
                        value={get(fluidData, 'sodiumLossRating')}
                        demographic={fluidData.sodiumLossRatingScale || data.demographic}
                        gender={gender}
                    />

                </Col>

            </Row>

        </>

    ), [])
}

export default TestSessionsReportSweatTestFluid;