import React, { useContext, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldList from '@dex/bubl-dash/src/lib/components/Fields/FieldList';
import FieldPopup from '@dex/bubl-dash/src/lib/components/Fields/FieldPopup';
import GaitsEditTabsPanel from './GaitsEditTabsPanel';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import EditableContext from '../../../context/EditableContext';
import { FieldRange, FieldRepeater, Heading, Space, WhiteSpace } from '@dex/bubl-dash';
import style from './Gaits.module.scss';
import FieldRangeLabels from '../../../elements/FieldRangeLabels/FieldRangeLabels';
import { get, slug } from '@dex/bubl-helpers';
import ShoeWearPattern from '../../../elements/ShoeWearPattern/ShoeWearPattern';
import InsoleWearPattern from '../../../elements/InsoleWearPattern/InsoleWearPattern';
import styles from './Gaits.module.scss';

const GaitsEditTabsShoes: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, data, settings } = props;

    return useMemo(() => (

        <GaitsEditTabsPanel
            heading={"Shoes"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldList
                        form={form}
                        name={`shoes`}
                        label={"Shoes"}
                        addButtonLabel={"Add Shoe"}
                        defaultValues={{ comments: get(settings.gait, "shoeComment") }}
                    >

                        {(form.values.shoes || []).map((shoe, i) => (

                            <GaitsEditTabsShoesForm
                                key={i}
                                i={i}
                                shoe={shoe}
                                form={form}
                                data={data}
                            />

                        ))}

                    </FieldList>

                </Col>

            </Row>

        </GaitsEditTabsPanel>


    ), [props]);

}

const GaitsEditTabsShoesForm: React.FC<any> = (props: any) => {

    const { i, shoe, form, data } = props;

    const prefix = `shoes[${i}]]`;

    const [tab, setTab] = useState("details");

    const editable = useContext(EditableContext);

    return (

        <FieldPopup
            buttonLabel={"Shoes " + (i + 1) + (shoe.shoeBrand ? ` - ${shoe.shoeBrand}` : "") + (shoe.shoeSize ? ` Size ${shoe.shoeSize}` : "")}
            heading={"Shoes " + (i + 1) + (shoe.shoeBrand ? ` - ${shoe.shoeBrand}` : "") + (shoe.shoeSize ? ` Size ${shoe.shoeSize}` : "")}
            form={form}
            name={prefix}
            modalProps={{
                className: 'modal-top'
            }}
            panelProps={{
                tabs: [
                    { label: "Details", name: "details" },
                    { label: "Wear Pattern", name: "pattern" },
                    { label: "Pictures", name: "photos" }
                ],
                setTab: setTab,
                activeTab: tab
            }}
        >

            <div className={!editable ? 'form-not-editable' : ''}>

                <div className={tab === "details" ? "" : "hidden"}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 12, md: 7 }}>

                            <FieldText
                                form={form}
                                label={"Shoe Brand"}
                                name={`${prefix}.shoeBrand`}
                                defaultValue={shoe.shoeBrand}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 6, md: 3 }}>

                            <FieldNumber
                                form={form}
                                label={"Shoe Size"}
                                name={`${prefix}.shoeSize`}
                                defaultValue={shoe.shoeSize}
                                suffix={" EUR"}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 6, md: 4 }}>

                            <FieldNumber
                                form={form}
                                label={"Measured Shoe Size (Left)"}
                                name={`${prefix}.footLengthLeft`}
                                defaultValue={data.foot.measuredShoeLeft}
                                suffix={" EUR"}
                                disabled={true}
                            />

                        </Col>

                        <Col col={{ xs: 6, md: 4 }}>

                            <FieldNumber
                                form={form}
                                label={"Measured Shoe Size (Right)"}
                                name={`${prefix}.footLengthRight`}
                                defaultValue={data.foot.measuredShoeRight}
                                suffix={" EUR"}
                                disabled={true}
                            />

                        </Col>

                        <Col col={{ xs: 6, md: 3 }}>

                            <FieldNumber
                                form={form}
                                label={"Shoe Weight"}
                                name={`${prefix}.shoeWeight`}
                                defaultValue={shoe.shoeWeight}
                                suffix={" g"}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 6, md: 3 }}>

                            <FieldText
                                form={form}
                                label={"Shoe Age"}
                                name={`${prefix}.shoeAge`}
                                defaultValue={shoe.shoeAge}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <Line />

                        </Col>

                        <Col col={{ xs: 24, md: 7 }}>

                            <FieldSelect
                                form={form}
                                label={"Insoles"}
                                name={`${prefix}.insoles`}
                                defaultValue={shoe.insoles}
                                allowCustomOption={true}
                                options={[
                                    { value: "Shoe Brand", label: "Shoe Brand" },
                                    { value: "G8 - Level 1", label: "G8 - Level 1" },
                                    { value: "G8 - Level 2", label: "G8 - Level 2" },
                                    { value: "G8 - Level 3", label: "G8 - Level 3" },
                                    { value: "G8 - Level 4", label: "G8 - Level 4" },
                                    { value: "G8 - Level 5", label: "G8 - Level 5" },
                                    { value: "Push GebioMized - Low Arch", label: "Push GebioMized - Low Arch" },
                                    { value: "Push GebioMized - Med Arch", label: "Push GebioMized - Med Arch" },
                                    { value: "Push GebioMized - High Arch", label: "Push GebioMized - High Arch" },
                                    { value: "Specialized - Low Arch", label: "Specialized - Low Arch" },
                                    { value: "Specialized - Med Arch", label: "Specialized - Med Arch" },
                                    { value: "Specialized - High Arch", label: "Specialized - High Arch" },
                                    { value: "Solestar - Low Arch", label: "Solestar - Low Arch" },
                                    { value: "Solestar - Med Arch", label: "Solestar - Med Arch" },
                                    { value: "Solestar - High Arch", label: "Solestar - High Arch" }
                                ]}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>
                            <Line />
                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Shoe Comments"}
                                name={`${prefix}.comments`}
                                defaultValue={shoe.comments}
                                rows={4}
                                maxLength={500}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                    </Row>

                </div>

                <div className={tab === "pattern" ? "" : "hidden"}>

                    <WearPatternTab
                        shoe={shoe}
                        form={form}
                        prefix={prefix}
                    />

                </div>

                <div className={tab === "photos" ? "" : "hidden"}>

                    <PhotoTab
                        shoe={shoe}
                        form={form}
                        prefix={prefix}
                    />

                </div>

            </div>

        </FieldPopup>

    )

}

const WearPatternTab: React.FC<any> = (props: any) => {

    const { shoe, form, prefix } = props;

    const getFootType = (shoe: number): string => {

        if (shoe < -3.5) {
            return 'Severe Pronation';
        } else if (shoe >= -3.5 && shoe < -1.6) {
            return 'Mild Pronation';
        } else if (shoe >= -1.5 && shoe <= 1.5) {
            return 'Normal';
        } else if (shoe > 1.5 && shoe <= 3.5) {
            return 'Mild Supination';
        } else if (shoe > 3.5) {
            return 'Severe Supination';
        } else {
            return '';
        }

    };

    return (
        <Row gutter={24} edge={true}>

            <Col col={{ xs: 12 }} align='center' className='text-center'>

                <Heading>Shoe Wear Pattern</Heading>

                <Space />

                <ShoeWearPattern
                    prefix={prefix}
                    shoe={shoe}
                    form={form}
                />

                <Space />

                <WearPatternLegend />

                <Space />

            </Col>

            <Col col={{ xs: 12 }} align='center' className='text-center'>

                <Heading>Insole Wear Pattern</Heading>

                <Space />

                <InsoleWearPattern
                    prefix={prefix}
                    shoe={shoe}
                    form={form}
                />

                <Space />

                <WearPatternLegend />

                <Space />

            </Col>

            <Col col={{ xs: 24 }}>
                <Line top={"small"} bottom={false} />
            </Col>

            <Col col={{ xs: 12 }}>

                <Heading space={false}><WhiteSpace />Left: <span className={style.shoeRating + " " + style[slug(getFootType(shoe?.leftFootwearRating))]}>{getFootType(shoe?.leftFootwearRating)}</span></Heading>

                <FieldRange
                    form={form}
                    label={[<>Overpronate<br />++</>, <>Normal</>, <>Oversupinate<br />++</>]}
                    labelElement={FieldRangeLabels}
                    required={false}
                    name={`${prefix}.leftFootwearRating`}
                    defaultValue={shoe.leftFootwearRating}
                    min={-5}
                    max={5}
                    step={0.5}
                    marks={{ "-5": "-5", "0": shoe?.leftFootwearRating || 0, "5": "5" }}
                />

            </Col>

            <Col col={{ xs: 12 }}>

                <Heading space={false}><WhiteSpace />Right: <span className={style.shoeRating + " " + style[slug(getFootType(shoe?.rightFootwearRating))]}>{getFootType(shoe?.rightFootwearRating)}</span></Heading>

                <FieldRange
                    form={form}
                    label={[<>Overpronate<br />++</>, <>Normal</>, <>Oversupinate<br />++</>]}
                    labelElement={FieldRangeLabels}
                    required={false}
                    name={`${prefix}.rightFootwearRating`}
                    defaultValue={shoe.rightFootwearRating}
                    min={-5}
                    max={5}
                    step={0.5}
                    marks={{ "-5": "-5", "0": shoe?.rightFootwearRating || 0, "5": "5" }}
                />

            </Col>

        </Row >
    )
}

export const WearPatternLegend: React.FC<any> = (props: any) => {

    return (
        <Row edge gutter={8}>
            <Col col={{ xs: 8 }} >
                <div className={styles.containerLegend}>
                    <div className={styles.boxNone} />
                    <p className={styles.textNone} >None</p>
                </div>
            </Col>
            <Col col={{ xs: 8 }}>
                <div className={styles.containerLegend}>
                    <div className={styles.boxMild} />
                    <p className={styles.textMild} >Mild</p>
                </div>
            </Col>
            <Col col={{ xs: 8 }}>
                <div className={styles.containerLegend}>
                    <div className={styles.boxSevere} />
                    <p className={styles.textSevere} >Severe</p>
                </div>
            </Col>
        </Row >
    )

}

const PhotoTab: React.FC<any> = (props: any) => {

    const { form, shoe, prefix } = props;

    return (
        <FieldRepeater
            form={form}
            name={`${prefix}.pictures`}
            addButtonLabel={"Add Picture"}
        >

            {(shoe.pictures || []).map((row, n) => (

                <React.Fragment key={form.key + "-" + n}>

                    <Row gutter={16} edge={true} className='row-top-edge'>

                        <Col col={{ xs: 12 }}>

                            <FieldUpload
                                form={form}
                                name={`${prefix}.pictures[${n}].image`}
                                folder='admin-media'
                                label='Photo'
                                required={true}
                                defaultValue={row.image}
                                multiple={false}
                                accept='image/*'
                            />

                        </Col>

                        <Col col={{ xs: 12 }}>

                            <FieldTextArea
                                form={form}
                                name={`${prefix}.pictures[${n}].caption`}
                                label={"Caption"}
                                required={true}
                                defaultValue={row.caption}
                                rows={4}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                    </Row>

                </React.Fragment>
            ))}

        </FieldRepeater>
    )
}

export default GaitsEditTabsShoes;
