import { Col, FieldRadio, Row } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';
import { fixedNum, get, loop } from '@dex/bubl-helpers';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import ChartPerformanceTrackingSprint from '../../../elements/ChartTestSessionReport/ChartPerformanceTrackingSprint';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';

const TestSessionsReportPerformanceTrackingSprint: React.FC<any> = (props: any) => {

    const { data, form, description } = props;

    let gender = data.athlete?.gender;
    const activity: string = data.test.activity;
    const intensity = data?.test?.intensity;

    const [index, setIndex] = useState(data.history[0].date);

    const trackingUse = get(form.values, `sprint.trackingUse`, "HR Peak");

    let options;
    let summary;

    if (trackingUse == "Power - 20s") {

        options = [
            { label: "Watts", name: "Watts", data: data.history },
            { label: "Watts/Kg", name: "WattsPerKg", data: data.history }
        ]
        summary = 'peakPower20'

    } else if (trackingUse == "Power - 5s") {

        options = [
            { label: "Watts", name: "Watts", data: data.history },
            { label: "Watts/Kg", name: "WattsPerKg", data: data.history }
        ]
        summary = 'peakPower5'

    } else if (trackingUse == "Speed - 20s") {

        options = [
            { label: "kph", name: "speed", data: data.history },
            { label: "min/km", name: "pace", data: data.history }
        ]
        summary = 'peakSpeed20'

    } else if (trackingUse == "Speed - 5s") {

        options = [
            { label: "kph", name: "speed", data: data.history },
            { label: "min/km", name: "pace", data: data.history }
        ]
        summary = 'peakSpeed5'

    } else if (trackingUse == "Rpm - 5s") {

        summary = "peakRpm5"

    } else if (trackingUse == "Rpm - 20s") {

        summary = "peakRpm20"

    } else if (trackingUse == "Peak Lactate") {
        options = [
            { label: "mmol/L", name: "mmol/L", data: data.history },
            { label: "mmol/L/sec", name: "mmol/L/sec", data: data.history }
        ]
        summary = "peakLactate"

    } else if (trackingUse == "HR Peak") {

        // options = [
        //     { label: "bpm", name: "bpm", data: data.history },
        //     { label: "%Hrmax", name: "%Hrmax", data: data.history }
        // ]
        summary = "hrMax"

    }

    return useMemo(() => (

        <>

            <Row edge gutter={8}>

                <Col col={{ xs: 20 }}>

                    <PanelChart
                        key={options + trackingUse}
                        heading={trackingUse + " Sprint"}
                        addZero={false}
                        chartKey={form.hash}
                        types={options}
                        defaultTypes={options ? options[0] : ""}
                        data={data.history}
                        renderChart={(chartType, chartData) => (
                            <>
                                <ChartPerformanceTrackingSprint
                                    data={chartData}
                                    activity={activity}
                                    date={data.recentDate}
                                    index={index}
                                    intensity={intensity}
                                    chart={trackingUse}
                                    type={chartType}
                                />
                            </>
                        )}
                    />

                </Col>

                <Col col={{ xs: 4 }}>

                    <FieldRadio
                        list=" vertical-list"
                        key={trackingUse}
                        form={form}
                        required={false}
                        label={"Tracking Over Time"}
                        name={"sprint.trackingUse"}
                        defaultValue={form.values?.sprint?.trackingUse || trackingUse}
                        options={[
                            activity == "Cycling" ? { label: "Power - 20s", value: "Power - 20s" } : null,
                            activity == "Cycling" ? { label: "Power - 5s", value: "Power - 5s" } : null,
                            activity == "Running" ? { label: "Speed - 20s", value: "Speed - 20s" } : null,
                            activity == "Running" ? { label: "Speed - 5s", value: "Speed - 5s" } : null,
                            activity == "Cycling" ? { label: "Rpm - 20s", value: "Rpm - 20s" } : null,
                            activity == "Cycling" ? { label: "Rpm - 5s", value: "Rpm - 5s" } : null,
                            { label: "Peak Lactate", value: "Peak Lactate" },
                            { label: "HR Peak", value: "HR Peak" },
                        ].filter(Boolean)}
                    />

                </Col>

                <Col col={{ xs: 20 }}>

                    <ReportDesc
                        label={description?.sprintDescription}
                        className={["no-border", "h1"]}
                    />

                </Col>

                <Col col={{ xs: 20 }}>

                    {(trackingUse == "Peak Lactate" || trackingUse == "Power - 20s" || trackingUse == "VO2") &&

                        <ReportHeading
                            text={"Current Rating"}
                        />

                    }

                    {trackingUse == "Power - 20s" &&
                        <RatingScaleCard
                            form={form}
                            metric={'Sprint Power 20 sec'}
                            value={form.values?.sumAvgData?.power_raw?.max}
                            name="performance.fiveSecondRatingScale"
                            demographic={get(form.values, 'performance.fiveSecondRatingScale', data.demographic)}
                            split={{
                                metric: 'Sprint Power/kg 20 sec',
                                value: fixedNum(form.values?.sumAvgData?.power_raw?.max / data?.bio?.weight, 1),
                            }}
                        />
                    }

                    {trackingUse == "Power - 5s" &&
                        <RatingScaleCard
                            form={form}
                            metric={'Sprint Power 5 sec'}
                            value={form.values?.sumData?.power_raw?.max}
                            name="performance.fiveSecondRatingScale"
                            demographic={get(form.values, 'performance.fiveSecondRatingScale', data.demographic)}
                            split={{
                                metric: 'Sprint Power/kg 5 sec',
                                value: fixedNum(form.values?.sumData?.power_raw?.max / data?.bio?.weight, 1),
                            }}
                        />
                    }

                    {trackingUse == "Speed - 20s" &&
                        <RatingScaleCard
                            form={form}
                            metric={'Sprint Speed 20 sec'}
                            value={form.values?.sumAvgData?.speed?.max}
                            name="performance.fiveSecondRatingScale"
                            demographic={get(form.values, 'performance.fiveSecondRatingScale', data.demographic)}
                            split={{
                                metric: 'Sprint Pace 20 sec',
                                value: form.values?.sumAvgData?.pace?.max,
                            }}
                        />
                    }

                    {trackingUse == "Speed - 5s" &&
                        <RatingScaleCard
                            form={form}
                            metric={'Sprint Speed 5 sec'}
                            value={form.values?.sumData?.speed?.max}
                            name="performance.fiveSecondRatingScale"
                            demographic={get(form.values, 'performance.fiveSecondRatingScale', data.demographic)}
                            split={{
                                metric: 'Sprint Pace 5 sec',
                                value: form.values?.sumData?.pace?.max,
                            }}
                        />
                    }

                    {trackingUse == "Peak Lactate" &&

                        <RatingScaleCard
                            metric={'Vlamax'}
                            value={get(data.test, 'manual.vlaMax')}
                            form={form}
                            demographic={data.test.vo2.lactateRatingScale || data.demographic}
                            gender={gender}
                        />

                    }

                </Col>

                <Col col={{ xs: 20 }}>

                    <ReportChartSummary
                        label={""}
                        valueKey={summary}
                        indexKey={"date"}
                        data={data.history}
                        type={"performance"}
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, trackingUse, options]);

}

export default TestSessionsReportPerformanceTrackingSprint;