import { Row, Col, FieldDate, FieldText, FieldAutocomplete, FieldSelect, FieldTextTime } from '@dex/bubl-dash';
import React, { useMemo } from 'react';

const LabTestsSweatWorkoutGeneral: React.FC<any> = (props: any) => {

    const { form, data, prefix, workout, session } = props;

    return useMemo(() => (

        <>

            <Row gutter={8} edge>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldDate
                        button={true}
                        form={form}
                        label={"Workout Date"}
                        name={`${prefix}.date`}
                        defaultValue={workout.date}
                        required={true}
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        name={`${prefix}.time`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.time}
                        label='Start Time'
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldAutocomplete
                        form={form}
                        label={"Location"}
                        name={`${prefix}.location`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.location}
                        group={"workout.location"}
                        options={[
                            { value: "VO2max Studio", label: "VO2max Studio" },
                            { value: "Indoor Workout", label: "Indoor Workout" },
                            { value: "Outdoor Workout", label: "Outdoor Workout" },
                        ]}
                        preload={true}
                        sort={false}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldAutocomplete
                        form={form}
                        label={"Activity"}
                        name={`${prefix}.activity`}
                        wrapClass={"manual-input"}
                        defaultValue={workout?.activity}
                        group={"workout.activity"}
                        required
                        options={[
                            { value: "Cycling", label: "Cycling" },
                            { value: "Running", label: "Running" },
                            { value: "All", label: "All" },
                        ]}
                        preload={true}
                        sort={false}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldSelect
                        form={form}
                        label='Weather'
                        name={`${prefix}.weather`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.weather}
                        options={[
                            { value: "sunny", label: "Sunny" },
                            { value: "partly sunny", label: "Partly Sunny" },
                            { value: "cloudy", label: "Cloudy" },
                            { value: "drizzle", label: "Drizzle" },
                            { value: "rain", label: "Rain" },
                            { value: "heavy rain", label: "Heavy Rain" },
                            { value: "snowing", label: "Snowing" },
                            { value: "not applicable", label: "Not Applicable" },
                            { value: "uncertain", label: "Uncertain" },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldSelect
                        form={form}
                        label='Air Temperature'
                        name={`${prefix}.temperature`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.temperature}
                        options={[
                            { label: "Very Hot (+30 deg)", value: "Very Hot (+30 deg)" },
                            { label: "Hot (26-30 deg)", value: "Hot (26-30 deg)" },
                            { label: "Warm (20-25 deg)", value: "warm (20-25 deg)" },
                            { label: "Cool (15-19 deg)", value: "Cool (15-19 deg)" },
                            { label: "Chilly (10-14 deg)", value: "Chilly (10-14 deg)" },
                            { label: "Cold (5-9 deg)", value: "Cold (5-9 deg)" },
                            { label: "Very Cold (0-4 deg)", value: "Very Cold (0-4 deg)" },
                            { label: "Freezing (O to -10 deg)", value: "Freezing (O to -10 deg)" },
                            { label: "Artic (<-10 deg)", value: "Artic (<-10 deg)" },
                            { label: "Uncertain", value: "Uncertain" }
                        ]}
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, data, session]);

}

export default LabTestsSweatWorkoutGeneral;
