import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Col, FieldRadio, FieldSelect, Loading, Modal, Panel, Row, Space } from '@dex/bubl-dash';
import FieldResultsAthlete from '../Reports/FieldResultsAthlete';
import FieldResultsTeam from '../Reports/FieldResultsTeam';
import FieldSessionsApi from '../FieldSessionsApi';
import FieldSessionsReportLinks from './FieldSessionsReportLinks';

const FieldSessionsEditTabsReports: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    const [share, setShare] = useState(false);

    const report = FieldSessionsApi.format(data.id, "getReport");

    useEffect(() => {

        form.handleChange({ name: "athleteId", value: data.athletes[0].id, reset: true });

    }, []);

    useEffect(() => {

        if (!form.values.view) return;

        if (form.values.view === "athlete" && !form.values.athleteId) return;

        report.reset();
        report.cancel();

        report.run({
            params: {
                view: form.values.view,
                history: true,
                athleteId: form.values.view === "athlete" ? form.values.athleteId : null,
            }
        });

    }, [form.values.view, form.values.athleteId]);

    const athletes = useMemo(() => {

        return data.athletes.map(athlete => {
            return { value: athlete.id, label: athlete.callName }
        })

    }, [data.athletes]);

    return useMemo(() => (

        <Box gutter={"auto"}>

            {share &&
                <Modal
                    show={share}
                    onClose={setShare.bind(null, false)}
                    width={720}
                >

                    <Panel
                        heading={"Share Field Report"}
                        actions={
                            <>
                                <Button
                                    onClick={setShare.bind(null, false)}
                                    label={"Close"}
                                    type='faded'
                                />
                            </>
                        }
                    >

                        <FieldSessionsReportLinks
                            data={data}
                        />

                    </Panel>

                </Modal>
            }

            <Panel
                heading={"Reports"}
                actions={
                    <>
                        <Button
                            onClick={setShare.bind(null, true)}
                            label={"Share Report"}
                        />
                    </>
                }
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, sm: 12, md: 8 }}>

                        <FieldRadio
                            button
                            form={form}
                            name={"view"}
                            label={'View By'}
                            defaultValue={form.defaults.view}
                            options={[
                                { label: 'By Athlete', value: 'athlete' },
                                { label: 'By Team', value: 'team' },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, md: 8 }}>

                        <FieldSelect
                            wrapClass={form.values.view === 'athlete' ? "" : "hidden"}
                            button
                            form={form}
                            name={"athleteId"}
                            label={'Athlete'}
                            defaultValue={form.defaults.athleteId}
                            options={athletes}
                        />

                    </Col>

                </Row>

            </Panel>

            <Space />

            {report.loading &&
                <>
                    <Space />
                    <Loading />
                    <Space />
                </>
            }

            {(!report.loading && report.data && report.data.athlete && form.values.view === 'athlete' && report.data.view === "athlete") &&

                <FieldResultsAthlete
                    report={report.data}
                    form={form}
                />

            }

            {(!report.loading && report.data && form.values.view === 'team' && report.data.view === "team") &&

                <FieldResultsTeam
                    report={report.data}
                    form={form}
                />

            }


        </Box>

    ), [props, report.data, report.loading, share]);

}

export default FieldSessionsEditTabsReports;
