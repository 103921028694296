import React, { useMemo } from 'react';
import { Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, FieldDate, FieldRelationship, FieldSelect, FieldText, FieldTextArea, Line, Space } from '@dex/bubl-dash';

const GaitsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Gaits Session Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24, lg: 8 }}>

                        <FieldSelect
                            button={true}
                            form={form}
                            label={"Fit Type"}
                            name='fitType'
                            required={true}
                            isMulti={false}
                            defaultValue={form.defaults.fitType}
                            options={[
                                { value: "Gait Analysis", label: "Gait Analysis" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldRelationship
                            form={form}
                            label='Athlete'
                            name='athleteId'
                            required={true}
                            preload={true}
                            endpoint={'Athletes'}
                            defaultValue={form.defaults.athleteId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldDate
                            form={form}
                            label={"Session Date"}
                            name={`sessionDate`}
                            required={true}
                            defaultValue={form.defaults.sessionDate}
                            date={true}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>


                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Runner Level"}
                            name='runnerLevel'
                            defaultValue={form.defaults.runnerLevel}
                            options={[
                                { value: "Novice", label: "Novice" },
                                { value: "Intermediate", label: "Intermediate" },
                                { value: "Experienced", label: "Experienced" },
                                { value: "Elite", label: "Elite" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Running Mileage per Week"}
                            name='runningMileagePerWeek'
                            defaultValue={form.defaults.runningMileagePerWeek}
                            options={[
                                { value: "None Currently", label: "None Currently" },
                                { value: "Very-Low (<10KM)", label: "Very Low (<10KM)" },
                                { value: "Low (10-30KM)", label: "Low (10-30KM)" },
                                { value: "Moderate (30-50KM)", label: "Moderate (30-50KM)" },
                                { value: "Moderately-High (50-70KM)", label: "Moderately-High (50-70KM)" },
                                { value: "High (70-90KM)", label: "High (70-90KM)" },
                                { value: "Very-High (90-110KM)", label: "Very High (90-110KM)" },
                                { value: "Elite (>110KM)", label: "Elite (>110KM)" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Competes?"}
                            name='competes'
                            defaultValue={form.defaults.competes}
                            options={[
                                { value: "Occasionally (2-5 events per year)", label: "Occasionally (2-5 events per year)" },
                                { value: "Frequently (5-10 events per year)", label: "Frequently (5-10 events per year)" },
                                { value: "Often ( 1 or more a month)", label: "Often ( 1 or more a month)" },
                                { value: "Rarely (1 or less/year)", label: "Rarely (1 or less/year)" },
                                { value: "Not Currently", label: "Not Currently" },
                                { value: "No, Never", label: "No, Never" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            name="yearsRunning"
                            label="Years Running"
                            suffix={" Years"}
                            defaultValue={form.defaults.yearsRunning}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 8 }}>

                        <FieldRelationship
                            form={form}
                            name="appraiserId"
                            label="Appraiser"
                            required={true}
                            defaultValue={form.defaults.appraiserId}
                            preload={true}
                            endpoint={'Fitters'}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 8 }}>

                        <FieldRelationship
                            form={form}
                            name="assistantAppraiserId"
                            label="Assistant Aprraiser"
                            required={false}
                            defaultValue={form.defaults.assistantAppraiserId}
                            preload={true}
                            endpoint={'Fitters'}
                            isMulti={true}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    <Col col={{ xs: 24, sm: 24 }}>

                        <FieldTextArea
                            form={form}
                            name="reasonForAnalysis"
                            label="Reason For Analysis"
                            required={false}
                            defaultValue={form.defaults.reasonForAnalysis}
                            maxLength={400}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 24 }}>

                        <FieldText
                            form={form}
                            name="performanceGoals"
                            label="Performance Goals"
                            required={false}
                            defaultValue={form.defaults.performanceGoals}
                            maxLength={150}
                        />

                    </Col>

                </Row>

                <Line />

                <Space />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default GaitsCreateEditForm;
