import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { fixedNum, loop } from '@dex/bubl-helpers';
import { useUpdateEffect } from '@dex/bubl-dash';
import { fancyTimeFormat } from '../../functions/sharedChart';

const ChartRaceReady: React.FC<ChartRaceReadyProps> = (props) => {

    let { data, efforts, activity, intensity, type, unit, setActiveTab } = props;

    if (setActiveTab) setActiveTab(unit);

    const Axis = new ChartAxis();

    Axis.setXByActivity("time", null);

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };


    loop(efforts, (index) => {

        const label = `Effort ${index}`;

        let indexPosition = index;

        if (index == 1) {
            indexPosition = 0
        } else if (index == 2) {
            indexPosition = 1
        } else {
            indexPosition
        };

        if (type == "HR") {

            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: indexPosition,
                key: `hr_` + index,
                label: label,
                axisLabel: 'HR - bpm',
                props: {
                    domain: [60, 180],
                }

            });

        } else if (type == "Power") {

            let key = unit === "w/kg" ? "calc_power_raw_kg" : "power_raw";

            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: indexPosition,
                key: key + "_" + index,
                label: label,
                axisLabel: 'Power - ' + unit,
                props: {
                    domain: unit === "w/kg" ? [0, 6] : [50, 250],
                }

            });

        } else if (type == "Speed") {

            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: indexPosition,
                key: unit == "speed" ? "speed" + "_" + index : "calc_pace" + "_" + index,
                label: label,
                axisLabel: unit == "speed" ? 'Speed - kph' : "Pace - min/km",
                props: {
                    domain: unit == "speed" ? [4, 16] : [120, 600],
                    tickFormatter: unit == "speed" ? null : fancyTimeFormat
                }
            });

        } else if (type == "SMO2") {

            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: indexPosition,
                key: unit + `_` + index,
                label: label,
                axisLabel: 'SMO2 - %',
                props: {
                    domain: [0, 100],
                }
            });

        } else if (type == "VO2") {

            let key = unit === "vo2relative" ? "ml/kg*min" : "L/min";

            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: indexPosition,
                key: unit + `_` + index,
                label: label,
                axisLabel: 'VO2 - ' + key,
                props: {
                    domain: unit === "vo2relative" ? [20, 60] : [0, 6],
                }
            });

        } else if (type == "Energy") {

            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: indexPosition,
                key: unit == "g/hr" ? `cho_` + index : `eecho_` + index,
                label: label,
                axisLabel: 'Energy - ' + unit,
                props: {
                    domain: unit == "g/hr" ? [0, 200] : [0, 800],
                }
            });

        } else if (type == "RPE") {

            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: indexPosition,
                key: `rpe_` + index,
                label: label,
                axisLabel: 'RPE',
                props: {
                    domain: [0, 10],
                    ticks: [0, 2, 4, 6, 8, 10]
                }
            });

        } else if (type == "Lactate") {

            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: indexPosition,
                key: unit == "mmol/L/min" ? `vla_` + index : 'lactate_' + index,
                label: label,
                axisLabel: 'Lactate - ' + unit,
                props: {
                    domain: unit == "mmol/L/min" ? [0, dataMax => (fixedNum(dataMax * 1.2, 2))] : [0, 6],
                }
            });

        } else if (type == "Breathing") {

            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: indexPosition,
                key: unit == "brpm" ? `rf_` + index : `tvl_` + index,
                label: label,
                axisLabel: 'Breathing - ' + unit,
                props: {
                    domain: unit == "brpm" ? [0, 60] : [0, 6],
                }
            });

        } else if (type == "Stride Rate" || type == "Stride") {

            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: indexPosition,
                key: unit == "per min" ? `strideFrequency_` + index : `strideLength_` + index,
                label: `Effort ${index}`,
                axisLabel: 'Stride - ' + unit,
                props: {
                    domain: unit == "per min" ? [60, 100] : [1.50, 3.5],
                }
            });

        }

    });

    return (

        <Chart
            key={type + unit}
        >

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartRaceReadyProps {
    [key: string]: any,
}

export default ChartRaceReady;