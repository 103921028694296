import SinglePage from '@dex/bubl-dash/src/lib/pages/SinglePage';
import SessionsSingleView from './Components/SessionsSingleView';
import SessionsSingleEdit from './Components/SessionsSingleEdit';

export default function ( app, settings ) {

    app.addRoute( {
        name: settings.key + "View",
        zone: settings.zone,
        path: settings.key + "/:id/view",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getButtons: settings.singleButtons,
                getTabs: settings.singleTabs,
            },
            page: SessionsSingleView,
        },
    } );

    app.addRoute( {
        name: settings.key + "Edit",
        zone: settings.zone,
        path: settings.key + "/:id/edit/:tab",
        caps: settings.updateCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getButtons: settings.singleButtons,
                getTabs: settings.singleTabs
            },
            page: SessionsSingleEdit,
        },
    } );

}