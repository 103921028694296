import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import QuestionnairesFillView from './QuestionnairesFillView';
import QuestionnairesReview from './QuestionnairesReview';
import QuestionnairesPending from './QuestionnairesPending';

const QuestionnairesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Questionnaires Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Athlete",
                                key: "athlete",
                                format: "ModelLink",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Type of Service",
                                key: "typeOfService",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                        ]}
                    />

                    {data.status !== 'pending' &&
                        <QuestionnairesFillView data={data} />
                    }

                </Panel>

                {data.status === 'filled' &&
                    <>
                        <Space />
                        <QuestionnairesReview data={data} />
                    </>
                }

                {/* {data.status === 'pending' &&
                    <>
                        <Space />
                        <QuestionnairesPending data={data} />
                    </>
                } */}

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default QuestionnairesSingleView;
