import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { DataTable, DataTableCell, DataTableRow, FieldCheckbox, FieldHidden, FieldNumber, FieldPopup, FieldSelect, FieldTextArea, InputTable, InputTableCell, InputTableRow, Space } from '@dex/bubl-dash';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartHr from '../../../elements/ChartLabTests/ChartHr';
import { get } from '@dex/bubl-helpers';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartSprintHr from '../../../elements/ChartLabTests/ChartSprintHr';

const LabTestsSprintHr: React.FC<any> = (props: any) => {

    const { form, data, session, tab } = props;

    const activity = data.activity;

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24, md: 24 }}>

                <PanelChart
                    heading={"HR During The Sprint"}
                    types={[
                        {
                            name: "avg",
                            label: "Sprint Only",
                            data: form.values.avgData
                        },
                        {
                            name: "rolling",
                            label: "Average 5 Sec",
                            data: form.values.rollingData
                        },
                        {
                            name: "raw",
                            label: "Raw",
                            data: form.values.rawData
                        }
                    ]}
                    data={form.values.rawData}
                    addZero={false}
                    renderChart={(chartType, chartData) => (

                        <>
                            <ChartSprintHr
                                data={chartData}
                                activity={activity}
                                intensity={data.intensity}
                                xAxis={{
                                    interval: ChartProps.interval(activity, chartType),
                                }}
                            />
                        </>

                    )}
                />

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <InputTable
                    columns={[
                        { colSpan: 2, label: "Heart Rate Measures" },
                    ]}
                >
                    <InputTableRow>

                        <DataTableCell>
                            <strong>Peak</strong>
                        </DataTableCell>

                        <DataTableCell>

                            <strong>{form.values?.hr?.max} bpm</strong>

                            <FieldHidden
                                form={form}
                                name={`hr.max`}
                                value={form.values.hr.max}
                            />

                        </DataTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <DataTableCell>
                            <strong>Min</strong>
                        </DataTableCell>

                        <DataTableCell>

                            <strong>{form.values?.hr?.min} bpm</strong>

                            <FieldHidden
                                form={form}
                                name={`hr.min`}
                                value={form.values.hr.min}
                            />

                        </DataTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <DataTableCell>
                            <strong>Avg</strong>
                        </DataTableCell>

                        <DataTableCell>

                            <strong>{form.values?.hr?.avg} bpm</strong>

                            <FieldHidden
                                form={form}
                                name={`hr.avg`}
                                value={form.values.hr.avg}
                            />

                        </DataTableCell>

                    </InputTableRow>

                </InputTable>

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <FieldTextArea
                    form={form}
                    label={"HR Description"}
                    name={"hr.sprintHrDescription"}
                    defaultValue={form.defaults?.hr?.sprintHrDescription}
                    rows={3}
                />

            </Col>

        </Row>

    ), [props]);

}


export default LabTestsSprintHr;
