import { isEmpty, loop, parseNum, titleCase } from "@dex/bubl-helpers"

export const getArmProportion = (ratio) => {

    if (ratio === 0) {

        return ""

    } else if (ratio <= 97.5) {

        return "Very Short Arms"

    } else if (ratio <= 99) {

        return "Short-ish Arms"

    } else if (ratio <= 103) {

        return "Proportional"

    } else if (ratio <= 104) {

        return "Long-ish Arms"

    } else if (ratio > 104) {

        return "Very Long Arms"

    }

}

export const getLegProportion = (ratio) => {

    if (ratio === 0) {

        return ""

    } else if (ratio < 44.5) {

        return "Very Short Legs"

    } else if (ratio < 46) {

        return "Short-ish Legs"

    } else if (ratio < 48) {

        return "Proportional"

    } else if (ratio < 49.5) {

        return "Long-ish Legs"

    } else if (ratio >= 49.5) {

        return "Very Long Legs"

    }

}

export const getThighShinProportion = (ratio) => {

    if (!ratio) return ""

    if (ratio === 0) {

        return ""

    } else if (ratio < 86) {

        return "Very Long Thighs"

    } else if (ratio < 89.5) {

        return "Long-ish Thighs"

    } else if (ratio < 90.5) {

        return "Proportional"

    } else if (ratio < 94.5) {

        return "Long-ish Shins"

    } else if (ratio >= 94.5) {

        return "Very Long Shins"

    } else {
        return ""
    }

}

export const getArmRatio = (armSpan = 0, height = 0) => {
    return parseNum((armSpan / height * 100).toFixed(1))
}

export const getLegRatio = (inseam = 0, height = 0) => {
    return parseNum((inseam / height * 100).toFixed(1))
}

export const getThighShinRatio = (shinLength = 0, thighLength = 0) => {

    if (isEmpty(shinLength) || isEmpty(thighLength)) return null;

    return parseNum((shinLength / thighLength * 100).toFixed(1))
}

export const getSpacerTotal = (stemSpacers = 0, stemCap = 0) => {

    if (isEmpty(stemSpacers) && isEmpty(stemCap)) return null;

    return parseNum(stemSpacers) + parseNum(stemCap);

}

export const mobilityValuesToClasses = (values) => {

    let fair: any = []
    let restricted: any = []

    loop(values, (value, key) => {

        if (typeof (value) !== 'string') return;

        if (value !== 'Fair' && value !== 'Restricted') return;

        if (key === 'lowerBack' && value === 'Fair') fair.push('LowerBack')
        if (key === 'lowerBack' && value === 'Restricted') restricted.push('LowerBack')

        const details = values[key + "Details"];

        if (!details) return;

        details.map(detail => {

            detail = detail.replace("Tight", "");

            if (value === 'Fair') fair.push(detail)
            else if (value === 'Restricted') restricted.push(detail)

        })

    });

    return { fair, restricted };

}

export const injuryValuesToClasses = (values) => {

    let injuryFrontClasses: any = [];
    let injuryBackClasses: any = [];

    loop(values, (values, key) => {

        if (!values || !Array.isArray(values)) return;

        values.map(value => {
            if (key === 'front' || key === 'frontLeft' || key === 'frontRight') {
                injuryFrontClasses.push(titleCase(key) + "_" + value.replace(/ /g, "_"));
            } else if (key === 'back' || key === 'backRight' || key === 'backLeft') {
                injuryBackClasses.push(titleCase(key) + "_" + value.replace(/ /g, "_"));
            }
        });

    })

    return { injuryFrontClasses, injuryBackClasses };
}

export const getFootLengthDifference = (left, right) => {

    const leftFootLength = parseFloat(left);

    const rightFootLength = parseFloat(right);

    if (Math.abs(leftFootLength - rightFootLength) <= 2) {
        return "Even"
    } else if (leftFootLength > rightFootLength) {

        return "Right Shorter"

    } else if (leftFootLength < rightFootLength) {

        return "Left Shorter"
    }

}

export const getArchLengthDifference = (left, right) => {

    const leftArchLength = parseFloat(left);

    const rightArchLength = parseFloat(right);

    if (leftArchLength === rightArchLength) {

        return "Even"

    } else if (leftArchLength > rightArchLength) {

        return "Right Shorter"

    } else if (leftArchLength < rightArchLength) {

        return "Left Shorter"
    }

}

export const getFootPhoto = (key, side, value) => {

    side = titleCase(side);
    value = titleCase(value);

    return key + side + value;
}

export const getHipPhoto = (hipLength, legLength) => {

    hipLength = titleCase(hipLength).replace(/\s/g, "").replace("Uncertain", "Even");
    legLength = titleCase(legLength).replace(/\s/g, "").replace("Uncertain", "Even");

    return hipLength + "Leg" + legLength + '.png';
}

export const bikeLabel = (bike) => {

    let label = [bike.brand, bike.model, bike.year];

    if ((bike.type === "Road" && bike.handlebarType === "Aerobars") || bike.type === "RoadAero") {
        label.push("- " + bike.handlebarType);
    }

    return label.filter(Boolean).join(" ");

}

export const getWidthDifference = (left, right) => {

    left = parseFloat(left);
    right = parseFloat(right);

    if (left - right > 2) {
        return "Left Wider";
    } else if (right - left > 2) {
        return "Right Wider";
    } else if (Math.abs(left - right) <= 2) {
        return "Even";
    } else {
        return "";
    }

}

export const getSummaryText = (summaryType) => {

    let text = '';

    if (summaryType === 'default') {

        text = `Your original setup on your bike required some adjustments. Initially, you were ____

To improve your comfort and position on the bike, we ____

You felt the changes to your setup made riding more comfortable. Try your new set-up for a while and see how your body responds to it. It may take several rides for your body to adapt and for you to feel the benefits of the changes made to your setup.

Other things to consider:
- think about switching to grey (moderate float ) cleats in the future.
- try do the stretching exercises I showed you ____. They are posted at the end of this report.

The rest of the report details the following:
- Results of your functional assessment
- Saddle pressure results.
- Your final riding posture on your bike.
- Photos of you pre and post fitting.

It was a pleasure meeting you and all the best with your cycling adventures. Let us know how the new fit feels after several rides.

Power to the Pedals!
Patrick and the VO2max Team`;

    } else if (summaryType === 'sitting-too-high') {

        text = `Your original bike setup required some adjustments. Initially, you were sitting too high on the bike with your saddle tilted down at the nose a bit too much. By sitting too high, there is an increase in pressure on the sit bones and perineum making long rides potentially more uncomfortable. Your legs and feet may also be overextended reducing their ability to generate force consistently to the pedals. With your saddle nose tilted down too much, your body will be driven constantly forwards on the bike putting more pressure on your hands, arms, and shoulders (to hold you in place). This may explain the discomfort you have been getting.

To improve your comfort and position on the bike, we lowered your seat post and levelled your saddle better.

You felt the changes to your setup made riding more comfortable. Try your new set-up for a while and see how your body responds to it. It may take several rides for your body to adapt and for you to feel the benefits of the changes made to your setup.

Other things to consider:
-
- try do the exercises posted at the end of this report. These will help improve your mobility and
stability on and off the bike.

The rest of the report details the following:
- Results of your functional assessment.
- Saddle pressure results.
- Your final riding posture on your bike.
- Photos of you pre and post fitting.

It was a pleasure meeting you again and all the best with your cycling adventures. Let us know how
the new fit feels after several rides.

Power to the Pedals!
Patrick and the VO2max Team`;

    } else if (summaryType === 'sitting-too-low') {

        text = `Your original bike setup required some adjustments. Initially, you were sitting too low on your bike with your saddle tilted down at the nose a bit too much. By sitting too low, your knees and lower back will be over-flexed, running the risk of developing knee and/or lower back pain as a result. You also reduce the cycling muscle’s ability to generate force consistently to the pedals. With your saddle nose tilted down too much, your body will be driven constantly forwards on the bike putting more pressure on your hands, arms, and shoulders (to hold you in place). This may explain the discomfort you have been getting.

To improve your comfort and position on the bike, we raised your seat post and levelled your saddle better.

You felt the changes to your setup made riding more comfortable. Try your new set-up for a while and see how your body responds to it. It may take several rides for your body to adapt and for you to feel the benefits of the changes made to your setup.

Other things to consider:
-
- try do the exercises posted at the end of this report. These will help improve your mobility and
stability on and off the bike.

The rest of the report details the following:
- Results of your functional assessment.
- Saddle pressure results.
- Your final riding posture on your bike.
- Photos of you pre and post fitting.

It was a pleasure meeting you again and all the best with your cycling adventures. Let us know how
the new fit feels after several rides.

Power to the Pedals!
Patrick and the VO2max Team`;

    } else if (summaryType === 'bike-sizing') {

        text = `Your bike profiles as determined during your sizing session with us are given in this report. They include the frame’s dimensions and positional measurements as well as a n overlay of the bikes so you can easily compare and see the differences between bikes. Note the following:

Which of these profiles you choose to go with will depend on how comfortable or aggressive you wish to ride in addition to how you prefer the setup on your bike to look like (e.g., number of spacers below the stem, amount of seat post exposed, length of the stem).

That's It! Good luck with your new bike. Any questions just feel free to ask us. Once you get your new bike and have done a few rides, you can always return for a fit on the bike. We can take a look at your riding posture and style to see if there are further adjustments needed to improve your comfort and performance on the bike.

It was a pleasure meeting you and all the best with your cycling adventures.

Power to the Pedals!
Patrick and the VO2max Team`;

    } else if (summaryType === 'test-session-summary') {
        text = `You should...

It was a pleasure meeting you and all the best with your cycling adventures.

Power to the Pedals!
Patrick and the VO2max Team`;
    }

    return text;
}

export const getMobilityComments = (type) => {

    let text = "";

    if (type === 'default') {
        text = 'Areas shaded red or yellow require improvement in your flexibility and/or mobility. See exercises at end of report.';
    }

    return text;

}

export const getStabilityComments = (type) => {

    let text = "";

    if (type === 'default') {
        text = '- Your core and hip stability can use improvement. See exercises at the end of this report to help you with this.';
    }

    return text;

}

export const getSymmetryComments = (type) => {

    let text = "";

    if (type === 'default') {
        text = 'On standing your left/right hip was lower by mm. When lying down, your left/right hip was lower while your left/right leg appeared shorter. Your hip asymmetry was partly/fully corrected and your legs then looked even after you performed the isometric exercises and/or had the magnets applied. The hip asymmetry was due to a functional (not structural) difference. On the bike, your left/right hip was shifting downwards/forwards when cycling.';
    }

    return text;

}