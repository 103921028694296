import { Col, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import styles from "./GaitReportRunner.module.scss";
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import { ColoredHeader } from './GaitReportPage';
import GaitReportComment from '../../../elements/GaitReportComment/GaitReportComment';
import InjuryFront from '../../../elements/InjuryFront/InjuryFront';
import InjuryBack from '../../../elements/InjuryBack/InjuryBack';
import ReportLabelValue from '../../../elements/ReportLabelValue/ReportLabelValue';

const GaitReportRunner: React.FC<any> = (props: any) => {

    const { data, form } = props;

    let gender = data.athlete?.gender;

    return useMemo(() => (

        <Row gutter={20} edge>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Athlete Information"
                />

            </Col>

            <Col col={{ xs: 16 }}>

                <ReportHeading
                    text={"Information"}
                />


                <ReportMetaTable
                    data={data}
                    columnSize={{ xs: 8 }}
                    columnA={[
                        {
                            key: 'athlete.fullName',
                            label: 'Athlete',
                            format: "titleCase"
                        },
                        {
                            key: 'athlete.mainSport',
                            label: 'Main Sport',
                        },
                        {
                            key: 'yearsRunning',
                            label: 'Years Running',
                            format: "number",
                            suffix: " yrs",
                        },
                    ]}
                    columnB={[
                        {
                            key: 'athlete.gender',
                            label: 'Gender',
                            format: "titleCase"
                        },
                        {
                            key: 'athlete.otherSports',
                            label: 'Other Sport',
                        },
                        {
                            key: 'competes',
                            label: 'Competes?',
                        },
                    ]}
                    columnC={[
                        {
                            key: 'athlete.age',
                            label: 'Age',
                            format: "number",
                            suffix: " yrs",
                        },
                        {
                            key: 'athlete.level',
                            label: 'Running Level',
                        },
                        {
                            key: 'athlete.referredBy',
                            label: 'Referred By',
                        },
                    ]}
                />

            </Col>

            <Col col={{ xs: 8 }}>

                <div className={styles.right}>

                    <div className={styles.chartContainer}>

                        <ReportHeading
                            text={"Weekly Running Mileage"}
                        />

                        <div className={styles.barChart}>
                            <table>
                                <tr>
                                    <td>Elite</td>

                                    <td>{'>'}110 km</td>
                                </tr>
                                <tr>
                                    <td>Very High</td>

                                    <td>90-110 km</td>
                                </tr>
                                <tr>
                                    <td>High</td>

                                    <td>70-90 km</td>
                                </tr>
                                <tr>
                                    <td>Moderately High</td>

                                    <td>50-70 km</td>
                                </tr>
                                <tr>
                                    <td>Moderate</td>

                                    <td>30-50 km</td>
                                </tr>
                                <tr>
                                    <td>Low</td>

                                    <td>10-30 km</td>
                                </tr>
                                <tr>
                                    <td>Very Low</td>

                                    <td> &lt;10 km </td>
                                </tr>
                            </table>

                            <label
                                className={[styles.mileageBar, styles[`${data.weeklyMileage}`]].join("")}
                            />

                        </div>

                        <div className={styles.label}>
                            You
                        </div>

                    </div>

                </div>

            </Col >

            <Col col={{ xs: 16 }}>
                <GaitReportComment
                    label="Reason For Analysis"
                    value={data.reasonForAnalysis}
                />

                <Space height={2} />

                <GaitReportComment
                    label="Performance Goals"
                    value={data.performanceGoals}
                />

                <Space height={2} />

                <GaitReportComment
                    label="Injuries & Discomfort"
                    value={data.injury.comments}
                />

            </Col>

            <Col col={{ xs: 8 }}>

                <Row edge gutter={8}>

                    <Col col={{ xs: 12 }}>

                        <InjuryFront
                            activity="run"
                            checked={data.injury}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <InjuryBack
                            activity="run"
                            checked={data.injury}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <div className={styles.injuryPainArea}>
                            <div className={styles.circle} />
                            <p className={styles.subHeadingTitle}>
                                AREA OF DISCOMFORT/PAIN
                            </p>
                        </div>

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Measurements"
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <MeasurementSection
                    data={data}
                    gender={gender}
                />

            </Col>

        </Row >

    ), [data]);

}

const MeasurementSection: React.FC<any> = (props: any) => {

    const { data, gender } = props;

    return (
        <div className={styles.sectionBio}>

            <div className={styles.measurements}>

                <div className={styles.imagewrapper}>

                    <div className={styles.image1}>
                        <img src={require(`../../../assets/images/GaitReport/${gender == "male" ? "BioStandingMale" : "BioStandingFemale"}.png`)} alt="BioStanding" />
                    </div>

                    <div className={styles.image2}>
                        <img src={require(`../../../assets/images/GaitReport/${gender == "male" ? "BioArmOutMale" : "BioArmOutFemale"}.png`)} alt="BioArmOut" />
                    </div>

                    <div className={styles.image4}>
                        <img src={require(`../../../assets/images/GaitReport/${gender == "male" ? "BioWingSpanMale" : "BioWingSpanFemale"}.png`)} alt="BioArmOutMale" />
                    </div>

                    <div className={styles.image3}>
                        <img src={require(`../../../assets/images/GaitReport/${gender == "male" ? "BioSeatedMale" : "BioSeatedFemale"}.png`)} alt="BioSeatedMale" />
                    </div>

                    <div className={styles.height}>
                        <ReportLabelValue
                            label={"Height"}
                            value={data.bio.height}
                            suffix={"CM"}
                            className={"height"}

                        />
                    </div>

                    <div className={styles.weight}>
                        <ReportLabelValue
                            label={"Weight"}
                            value={data.bio.weight}
                            suffix={"CM"}
                            className={"weight"}
                        />
                    </div>

                    <div className={styles.inseam}>
                        <ReportLabelValue
                            label={"Inseam"}
                            value={data.bio.inseam}
                            suffix={"CM"}
                            className={"inseam"}
                        />
                    </div>

                    <div className={styles.shoulderWidth}>
                        <ReportLabelValue
                            label={"Shoulder Width"}
                            value={data.bio.shoulderWidth}
                            suffix={"CM"}
                            className={"shoulderWidth"}
                        />
                    </div>

                    <div className={styles.armLength}>
                        <ReportLabelValue
                            label={"Arm Length"}
                            value={data.bio.armLength}
                            suffix={"CM"}
                            className={"armLength"}
                        />
                    </div>

                    <div className={styles.floorToShoulder}>
                        <ReportLabelValue
                            label={"Floor to Shoulder"}
                            value={data.bio.shoulderHeight}
                            suffix={"CM"}
                            className={"floorToShoulder"}
                        />
                    </div>

                    <div className={styles.armSpan}>
                        <ReportLabelValue
                            label={"Armspan"}
                            value={data.bio.armSpan}
                            suffix={"CM"}
                            className={"armSpan"}
                        />
                    </div>

                    <div className={styles.torsoLength}>
                        <ReportLabelValue
                            label={"Torso Length"}
                            value={data.bio.torsoLength}
                            suffix={"CM"}
                            className={"torsoLength"}
                        />
                    </div>

                    <div className={styles.femurLength}>
                        <ReportLabelValue
                            label={"Femur Length"}
                            value={data.bio.thighLength}
                            suffix={"CM"}
                            className={"femurLength"}
                        />
                    </div>

                    <div className={styles.lowerLegLength}>
                        <ReportLabelValue
                            label={"Lower Leg Length"}
                            value={data.bio.shinLength}
                            suffix={"CM"}
                            className={"lowerLegLength"}
                        />
                    </div>

                </div>

            </div>

            <div className={styles.sectionSubHeading}>

                <div className={styles.subHeadingTitle}>
                    YOUR BODY PROPORTIONS
                </div>
                <div className={styles.subHeadingSubTitle}>
                    (Relative to your height)
                </div>

            </div>

            <div className={styles.bodyProportions}>
                <ReportMetaTable
                    data={data}
                    columnSize={{ xs: 8 }}
                    columnA={[
                        {
                            key: 'bio.armProportion',
                            label: 'Arm Proportion',
                        },
                    ]}
                    columnB={[
                        {
                            key: 'bio.legProportion',
                            label: 'Leg Proportion',
                        },
                    ]}
                    columnC={[
                        {
                            key: 'bio.thighShinProportion',
                            label: 'Thigh-Shin Proportion',
                        },
                    ]}
                />

            </div>

        </div>
    )
};

export default GaitReportRunner;