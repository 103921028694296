import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, DataTable, DataTableCell, FieldHidden, FieldRadio, FieldRange, FieldRepeater, FieldSelect, FieldSwitch, FieldTextArea, FieldUpload, Heading, InputTableCell, InputTableRow, Line, Seperator, Space, useMountEffect } from '@dex/bubl-dash';
import { get, isEmpty } from '@dex/bubl-helpers';
import FieldRangeLabels from '../../../elements/FieldRangeLabels/FieldRangeLabels';
import { gaitIssueOptions, getIssueType, getIssueTypeLabel } from '../../../functions/sharedGait';
import styles from "./GaitsEditTabs.module.scss";
import { ColoredHeader } from '../Report/GaitReportPage';

const GaitsEditTabsRunSideView: React.FC<any> = (props: any) => {

    const { form, prefix, run, data } = props;

    return useMemo(() => (

        <>

            <ColoredHeader heading="Foot Strike (Initial)" />

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Foot Inclination"
                        image="FootInclination"
                        desc="(Optimal: Minimal)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        name={`${prefix}.side.footInclination.leftRating`}
                        defaultValue={get(run, `side.footInclination.leftRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `side.footInclination.leftRating`), 5: "5" }}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        required={false}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="footInclination"
                        keyName={"side.footInclination.issueLeft"}
                        value={get(run, `side.footInclination.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        required={false}
                        name={`${prefix}.side.footInclination.rightRating`}
                        defaultValue={get(run, `side.footInclination.rightRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `side.footInclination.rightRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="footInclination"
                        keyName={"side.footInclination.issueRight"}
                        value={get(run, `side.footInclination.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.side.footInclination.comment`}
                        defaultValue={get(run, `side.footInclination.comment`)}
                        label='Comments'
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>


            </Row>

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Leg Inclination"
                        image="LegInclination"
                        desc="(Optimal : 5 Deg Or Less)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        name={`${prefix}.side.legInclination.leftRating`}
                        defaultValue={get(run, `side.legInclination.leftRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `side.legInclination.leftRating`), 5: "5" }}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        required={false}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="legInclination"
                        keyName={"side.legInclination.issueLeft"}
                        value={get(run, `side.legInclination.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        required={false}
                        name={`${prefix}.side.legInclination.rightRating`}
                        defaultValue={get(run, `side.legInclination.rightRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `side.legInclination.rightRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="legInclination"
                        keyName={"side.legInclination.issueRight"}
                        value={get(run, `side.legInclination.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.side.legInclination.comment`}
                        defaultValue={get(run, `side.legInclination.comment`)}
                        label='Comments'
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Foot Strike Audio"
                        image="FootStrikeAudio"
                        desc="(Optimal : None To Mild)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <FieldRange
                        form={form}
                        label={[<>Silent</>, <>Moderate</>, <>Very Loud</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.side.footStrikeAudio.rating`}
                        defaultValue={get(run, `side.footStrikeAudio.rating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `side.footStrikeAudio.rating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="footStrikeAudio"
                        keyName={"side.footStrikeAudio.issue"}
                        value={get(run, `side.footStrikeAudio.rating`)}
                        label="Issue ?"
                    />

                </Col>

                <Col col={{ xs: 8 }} />

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.side.footStrikeAudio.comment`}
                        defaultValue={get(run, `side.footStrikeAudio.comment`)}
                        label='Comments'
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Space />

            <ColoredHeader heading="Mid-Stance" />

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Knee Angle"
                        image="KneeAngle"
                        desc="(Optimal : 135 - 145 Deg)"
                    />
                }
                description={""}
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Too<br /> Flexed</>, <>Optimal</>, <>Too<br /> Straight</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.side.kneeAngle.leftRating`}
                        defaultValue={get(run, `side.kneeAngle.leftRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", 0: get(run, `side.kneeAngle.leftRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="kneeAngle"
                        keyName={"side.kneeAngle.issueLeft"}
                        value={get(run, `side.kneeAngle.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Too<br /> Flexed</>, <>Optimal</>, <>Too<br /> Straight</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.side.kneeAngle.rightRating`}
                        defaultValue={get(run, `side.kneeAngle.rightRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", 0: get(run, `side.kneeAngle.rightRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="kneeAngle"
                        keyName={"side.kneeAngle.issueRight"}
                        value={get(run, `side.kneeAngle.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.side.kneeAngle.comment`}
                        defaultValue={get(run, `side.kneeAngle.comment`)}
                        wrapClass={"manual-input"}
                        rows={6}
                    />

                </Col>

            </Row>

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Arm Swing Style"
                        image="ArmSwingStyle"
                        desc="(Optimal : Moderate Amount With Arms Bent ~70-90 Deg)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.side.armSwingStyle.leftRating`}
                        defaultValue={get(run, `side.armSwingStyle.leftRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `side.armSwingStyle.leftRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="armSwingStyle"
                        keyName={"side.armSwingStyle.issueLeft"}
                        value={get(run, `side.armSwingStyle.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.side.armSwingStyle.rightRating`}
                        defaultValue={get(run, `side.armSwingStyle.rightRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `side.armSwingStyle.rightRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="armSwingStyle"
                        keyName={"side.armSwingStyle.issueRight"}
                        value={get(run, `side.armSwingStyle.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.side.armSwingStyle.comment`}
                        defaultValue={get(run, `side.armSwingStyle.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Torso Lean"
                        image="TorsoLean"
                        desc="(Optimal : ~7 Deg Forwards)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <FieldRange
                        form={form}
                        label={[<>Rearwards<br /> ++</>, <>Neutral</>, <>Forwards<br /> ++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.side.torsoLean.rating`}
                        defaultValue={get(run, `side.torsoLean.rating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", 0: get(run, `side.torsoLean.rating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="torsoLean"
                        keyName={"side.torsoLean.issue"}
                        value={get(run, `side.torsoLean.rating`)}
                        label="Issue ?"
                    />

                </Col>

                <Col col={{ xs: 8 }} />

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.side.torsoLean.comment`}
                        defaultValue={get(run, `side.torsoLean.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Pelvic Tilt"
                        image="PelvicTilt"
                        desc="(Optimal : Slightly Forwards)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <FieldRange
                        form={form}
                        label={[<>Rearwards<br /> ++</>, <>Neutral</>, <>Forwards<br /> ++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.side.pelvicTilt.rating`}
                        defaultValue={get(run, `side.pelvicTilt.rating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", 0: get(run, `side.pelvicTilt.rating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="pelvicTilt"
                        keyName="side.pelvicTilt.issue"
                        value={get(run, `side.pelvicTilt.rating`)}
                        label="Issue ?"
                    />

                </Col>

                <Col col={{ xs: 8 }} />

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.side.pelvicTilt.comment`}
                        defaultValue={get(run, `side.pelvicTilt.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, run]);

}

export const GaitsEditTabsRunSideViewIssue: React.FC<any> = (props: any) => {

    const { form, prefix, run, type, value, keyName } = props;

    const label = getIssueTypeLabel(getIssueType(type, value));
    const issue = getIssueType(type, value);

    return (

        <>
            {!isEmpty(value) &&
                <>

                    <FieldHidden
                        form={form}
                        name={`${prefix}.${keyName}`}
                        defaultValue={"yes"}
                    />
                    <Button
                        label={label}
                        onClick={() => { }}
                        className={`issue-${issue}`}
                        size='small'
                        disabled
                    />
                </>
            }

        </>
    )

}

export const HeadingTitleImage: React.FC<any> = (props: any) => {

    const { title, image, desc } = props;

    return (
        <Row edge gutter={8}>

            <Col col={{ xs: 20 }}>
                {title}
                {desc &&
                    <p>{desc}</p>
                }
            </Col>

            {image &&
                <Col col={{ xs: 4 }}>
                    <div className={styles.headingImage}>
                        <img
                            src={require(`../../../assets/images/Gait/icon-${image}.png`)}
                        />
                    </div>
                </Col>
            }

        </Row>
    )

}

export default GaitsEditTabsRunSideView;
