import React, { useMemo, Suspense, useCallback } from 'react';
import TabBody from '../../../elements/TabBody/TabBody';
import { labTestFormatRowValues, labTestFormattedData, labTestGetDuration, labTestGetEffortAverages, labTestGetEffortData } from '../../../functions/labTestData';
import LabTestsRaceReadyEquipment from './LabTestsRaceReadyEquipment';
import LabTestsRaceReadyManual from './LabTestsRaceReadyManual';
import { useApp } from '@dex/bubl-dash';
import { loop } from '@dex/bubl-helpers';
import LabTestsRaceReadyResult from './LabTestsRaceReadyResult';
import LabTestsRaceReadyRating from './LabTestsRaceReadyRating';
import LabTestsRaceReadyComment from './LabTestsRaceReadyComment';

const LabTestsImport = React.lazy(() => import('../Components/LabTestsImport'));

const LabTestsRaceReady: React.FC<any> = (props: any) => {

    const { form, data, session, tab, setKey, editable, } = props;

    const key = props._key;

    const app = useApp();

    const handleImport = useCallback((rawData, form, activity) => {

        let formattedData: any = labTestFormattedData(rawData, form.values.import);

        const effortData = labTestGetEffortData(formattedData);
        const effortAvg = labTestGetEffortAverages(effortData);

        //apply decimal places after calculating averages and min/max sums
        formattedData = labTestFormatRowValues(formattedData);

        loop(effortData, (effort, index) => {

            effortData[index] = labTestFormatRowValues(effort);

        });

        const duration: any = labTestGetDuration(formattedData);

        form.handleChange({ silent: true, reset: true, name: `rawData`, value: formattedData });
        form.handleChange({ silent: true, reset: true, name: `effortData`, value: effortData });
        form.handleChange({ silent: true, reset: true, name: `avgData`, value: effortAvg });
        form.handleChange({ silent: true, reset: true, name: `duration`, value: duration });

        if (setKey) setKey();

    }, []);

    return useMemo(() => (

        <>

            <TabBody
                form={form}
                active={tab === "import"}
                fields={["import", "rawData", "avgData", "effortData"]}
            >

                <Suspense fallback={<></>}>

                    <LabTestsImport
                        form={form}
                        data={data}
                        session={session}
                        tab={tab}
                        setKey={setKey}
                        handleImport={handleImport}
                        manualOnly={true}
                    />

                </Suspense>

            </TabBody>

            <TabBody
                form={form}
                active={tab === "manual"}
                fields={["manual", "manualData"]}
            >

                <LabTestsRaceReadyManual
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "equipment"}
                fields={["equipment"]}
            >

                <LabTestsRaceReadyEquipment
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "result"}
                fields={["result"]}
            >

                <LabTestsRaceReadyResult
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "rating"}
                fields={["rating"]}
            >

                <LabTestsRaceReadyRating
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "comments"}
                fields={["comment"]}
            >

                <LabTestsRaceReadyComment
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

        </>

    ), [form.hash, data, session, tab, setKey, editable]);

}

export default LabTestsRaceReady;
