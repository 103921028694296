import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldNumber, FieldRadio, FieldText, FieldTextArea, InputTable, InputTableCell, InputTableRow, Line } from '@dex/bubl-dash';
import TestSessionsEditTabsPanel from './TestSessionsEditTabsPanel';
import { fixedNum, get } from '@dex/bubl-helpers';
import { getSum } from '../../../functions/sharedGeneral';
import { getFevFvcRatioPercentage, getWellnessRating } from '../../../functions/sharedTests';

const TestSessionsEditTabsHealth: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    return useMemo(() => {

        const columns = [
            {
                key: "props",
                label: "Wellness",
                style: { width: 120 }
            },
            {
                key: "score",
                label: "Score",
                style: { width: 120 }
            },
        ]

        const rows = [
            {
                key: "fatigue",
                label: "Fatigue",
            },
            {
                key: "soreness",
                label: "Soreness",
            },
            {
                key: "sleep",
                label: "Sleep",
            },
            {
                key: "mood",
                label: "Mood",
            },
            {
                key: "stress",
                label: "Stress",
            },
        ]

        let values = form.values.health || {};

        let fatiguetoStressScore = [
            values?.fatigue,
            values?.soreness,
            values?.sleep,
            values?.mood,
            values?.stress,
        ]

        const wellnessTotal = getSum(fatiguetoStressScore);
        const wellnessRating = getWellnessRating(wellnessTotal);

        const fev1FvcPercentage = getFevFvcRatioPercentage(values?.restingFev1, values?.restingFvc)

        return (
            <TestSessionsEditTabsPanel
                heading={"Health"}
                {...props}
            >

                <Row gutter={20} edge={true}>

                    <Col col={{ xs: 24, lg: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, sm: 24, md: 24 }}>

                                <FieldRadio
                                    button={true}
                                    form={form}
                                    label={"PARQ"}
                                    name="health.parq"
                                    defaultValue={form.defaults.health?.parq}
                                    required={false}
                                    options={[
                                        { value: "Cleared", label: "Cleared" },
                                        { value: "Not Cleared", label: "Not Cleared" },
                                        { value: "Conditional Clearance", label: "Conditional Clearance" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    name="health.healthIssusesInjuries"
                                    label="Health Issues/Injuries"
                                    row={4}
                                    defaultValue={form.defaults.health?.healthIssusesInjuries}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    name="health.medication"
                                    label="Medication"
                                    row={4}
                                    defaultValue={form.defaults.health?.medication}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>
                                <Line />
                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="health.restingHr"
                                    label="Resting HR"
                                    defaultValue={form.defaults.health?.restingHr}
                                    suffix={" BPM"}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Max HR(Training)"}
                                    name={`health.maxHR`}
                                    defaultValue={form.defaults.health.maxHR}
                                    suffix=" BPM"
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 16 }}>

                                <FieldRadio
                                    button
                                    form={form}
                                    name="health.demographic"
                                    label="General Demographic"
                                    defaultValue={form.defaults.health?.demographic}
                                    options={[
                                        { label: "Recreational", value: "untrained" },
                                        { label: "Competitive", value: "trained" },
                                        { label: "Pro", value: "elite" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>
                                <Line />
                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="health.restingBpSystolic"
                                    label="Resting BP Systolic"
                                    defaultValue={form.defaults.health?.restingBpSystolic}
                                    suffix={" mmHg"}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="health.restingBpDiastolic"
                                    label="Resting BP Diastolic"
                                    defaultValue={form.defaults.health?.restingBpDiastolic}
                                    suffix={" mmHg"}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="health.restingBloodGlucose"
                                    label="Resting Blood Glucose"
                                    defaultValue={form.defaults.health?.restingBloodGlucose}
                                    suffix={" mmol/L"}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>
                                <Line />
                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="health.restingFev1"
                                    label="Resting FEV1"
                                    defaultValue={form.defaults.health?.restingFev1 || 1}
                                    suffix={" L"}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="health.restingFvc"
                                    label="Resting FVC"
                                    defaultValue={form.defaults.health?.restingFvc}
                                    suffix={" L"}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    disabled
                                    hidden
                                    name="health.fev1FvcPercentage"
                                    label="FEV1/FVC Ratio"
                                    suffix={" %"}
                                    key={fev1FvcPercentage}
                                    defaultValue={fixedNum(fev1FvcPercentage, 0)}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, lg: 12 }} >

                        <InputTable
                            columns={columns}
                        >

                            {rows.map((row, index) => {

                                const value = get(form.defaults, `health.${row.key}`);

                                return (

                                    <React.Fragment key={index}>

                                        <InputTableRow>

                                            <InputTableCell style={{ paddingLeft: 12 }}>
                                                <><strong>{row.label}</strong></>
                                            </InputTableCell>

                                            <InputTableCell style={{ width: '300px' }}>

                                                <FieldRadio
                                                    button={true}
                                                    form={form}
                                                    name={`health.${row.key}`}
                                                    defaultValue={value}
                                                    required={false}
                                                    options={[
                                                        { value: "1", label: "1" },
                                                        { value: "2", label: "2" },
                                                        { value: "3", label: "3" },
                                                        { value: "4", label: "4" },
                                                        { value: "5", label: "5" },
                                                    ]}
                                                />

                                            </InputTableCell>

                                        </InputTableRow>

                                    </React.Fragment>
                                )

                            })}

                            <InputTableRow>

                                <InputTableCell style={{ paddingLeft: 12 }}>
                                    <>Total</>
                                </InputTableCell>

                                <InputTableCell style={{ width: 200, paddingRight: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        disabled
                                        hidden
                                        name="health.wellnessTotal"
                                        key={wellnessTotal}
                                        defaultValue={wellnessTotal}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell style={{ paddingLeft: 12 }}>
                                    <>Wellness Rating</>
                                </InputTableCell>

                                <InputTableCell style={{ width: 200, paddingRight: 12 }}>

                                    <FieldText
                                        form={form}
                                        name="health.wellnessRating"
                                        disabled
                                        hidden
                                        key={wellnessRating}
                                        defaultValue={wellnessRating}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                        </InputTable>

                    </Col>

                </Row>


            </TestSessionsEditTabsPanel>

        )

    }, [props]);

}

export default TestSessionsEditTabsHealth;
