import { Col, Row, Space } from '@dex/bubl-dash';
import React from 'react';
import CoachingReportHeading from '../CoachingReportHeading/CoachingReportHeading';
import style from "./CoachingReportWeeklyAverage.module.scss";
import { WeeklyAveragePercentage } from '../../views/Coaching/Report/CoachingReportTracking';

const CoachingReportWeeklyAverage: React.FC<CoachingReportWeeklyAverageProps> = (props) => {

    let { desc, metTarget, belowTarget, aboveTarget, percentageNote, classes, columnA, columnB, columnANote, columnBNote, columnBNotePlus } = props;

    classes = props.classes || "container";

    return (

        <Row edge gutter={8}>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Weekly Average"
                    desc={desc ? `- ${desc}` : null}
                />

            </Col>

            {columnA &&

                <Col col={{ xs: 12 }}>

                    <Row gutter={4} edge>

                        {columnA.map((row, i) => (

                            <React.Fragment key={i}>

                                <Col col={{ xs: 10 }} align='end'>
                                    <p>
                                        <strong className={style.label}>{row.label ? `${row.label} :` : <>&nbsp;</>}</strong>
                                    </p>
                                </Col>

                                <Col col={{ xs: 14 }}>
                                    <p>
                                        <span><strong>{row.value}</strong> {row.suffix}</span>
                                    </p>
                                </Col>


                            </React.Fragment>

                        ))}

                        <Col col={{ xs: 24 }}>
                            <strong>{columnANote}</strong>
                        </Col>

                    </Row>

                </Col>

            }

            {columnB &&

                <Col col={{ xs: 12 }}>

                    <Row gutter={4} edge>

                        {columnB.map((row, i) => (

                            <React.Fragment key={i}>

                                <Col col={{ xs: 10 }} align='end'>
                                    <p>
                                        <strong className={style.label}>{row.label ? `${row.label} :` : <>&nbsp;</>}</strong>
                                    </p>
                                </Col>

                                <Col col={{ xs: 14 }}>
                                    <p>
                                        <span><strong>{row.value}</strong> {row.suffix}</span>
                                    </p>
                                </Col>


                            </React.Fragment>

                        ))}

                        <Col col={{ xs: 24 }}>
                            <strong>{columnBNote}</strong>
                            {columnBNotePlus && <> <br /> <strong>{columnBNotePlus}</strong> </>}
                        </Col>

                    </Row>

                </Col>

            }

            {(metTarget || belowTarget || aboveTarget) &&

                <Col col={{ xs: classes ? 12 : 24 }}>

                    <WeeklyAveragePercentage
                        metTarget={metTarget}
                        aboveTarget={belowTarget}
                        belowTarget={aboveTarget}
                    />

                    {percentageNote && <> <br /> <strong>{percentageNote}</strong> </>}

                </Col>
            }

        </Row>
    )

}

interface CoachingReportWeeklyAverageProps {
    [key: string]: any,
}

export default CoachingReportWeeklyAverage;