import SinglePage from '@dex/bubl-dash/src/lib/pages/SinglePage';
import QuestionnairesSingleView from './Components/QuestionnairesSingleView';
import QuestionnairesSingleEdit from './Components/QuestionnairesSingleEdit';
import QuestionnairesSingleFill from './Components/QuestionnairesSingleFill';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "View",
        zone: settings.zone,
        path: settings.key + "/:id/view",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: QuestionnairesSingleView,
        },
    });

    app.addRoute({
        name: settings.key + "Edit",
        zone: settings.zone,
        path: settings.key + "/:id/edit",
        caps: settings.updateCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: QuestionnairesSingleEdit,
        },
    });

    app.addRoute({
        name: settings.key + "Fill",
        zone: settings.zone,
        path: settings.key + "/:id/fill",
        caps: settings.updateCaps,
        component: SinglePage,
        public: true,
        ribbon: false,
        sidebar: false,
        componentProps: {
            getFetch: settings.singleFetchFill,
            getFetchParams: settings.singleFetchParams,
            page: QuestionnairesSingleFill,
        },
    });

}