import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import InjuryFront from '../../../elements/InjuryFront/InjuryFront';
import InjuryBack from '../../../elements/InjuryBack/InjuryBack';

const SessionsEditTabsInjury: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Injury"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Front Left"}
                                name='injury.frontLeft'
                                required={false}
                                defaultValue={form.defaults.injury.frontLeft}
                                options={[
                                    { value: "Shoulder", label: "Shoulder" },
                                    { value: "Biceps", label: "Biceps" },
                                    { value: "Forearm", label: "Forearm" },
                                    { value: "Hand", label: "Hand" },
                                    { value: "Hip", label: "Hip" },
                                    { value: "Lateral Thigh", label: "Lateral Thigh" },
                                    { value: "Quads", label: "Quads" },
                                    { value: "Knee", label: "Knee" },
                                    { value: "Shin", label: "Shin" },
                                    { value: "Foot", label: "Foot" },
                                    { value: "Groin", label: "Groin" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Front Right"}
                                name='injury.frontRight'
                                required={false}
                                defaultValue={form.defaults.injury.frontRight}
                                options={[
                                    { value: "Shoulder", label: "Shoulder" },
                                    { value: "Biceps", label: "Biceps" },
                                    { value: "Forearm", label: "Forearm" },
                                    { value: "Hand", label: "Hand" },
                                    { value: "Hip", label: "Hip" },
                                    { value: "Lateral Thigh", label: "Lateral Thigh" },
                                    { value: "Quads", label: "Quads" },
                                    { value: "Knee", label: "Knee" },
                                    { value: "Shin", label: "Shin" },
                                    { value: "Foot", label: "Foot" },
                                    { value: "Groin", label: "Groin" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Back & Neck"}
                                name='injury.back'
                                required={false}
                                defaultValue={form.defaults.injury.back}
                                options={[
                                    { value: "Lower Back", label: "Lower Back" },
                                    { value: "Mid Back", label: "Mid Back" },
                                    { value: "Upper Back", label: "Upper Back" },
                                    { value: "Neck", label: "Neck" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Rear Left"}
                                name='injury.backLeft'
                                required={false}
                                defaultValue={form.defaults.injury.backLeft}
                                options={[
                                    { value: "Scapular", label: "Scapular" },
                                    { value: "Buttocks", label: "Buttocks" },
                                    { value: "Hamstring", label: "Hamstring" },
                                    { value: "Calf", label: "Calf" },
                                    { value: "Sole", label: "Sole" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Rear Right"}
                                name='injury.backRight'
                                required={false}
                                defaultValue={form.defaults.injury.backRight}
                                options={[
                                    { value: "Scapular", label: "Scapular" },
                                    { value: "Buttocks", label: "Buttocks" },
                                    { value: "Hamstring", label: "Hamstring" },
                                    { value: "Calf", label: "Calf" },
                                    { value: "Sole", label: "Sole" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={null} bottom={null} />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Comments"}
                                name={'injury.comments'}
                                required={false}
                                defaultValue={form.defaults.injury.comments}
                                rows={4}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <InjuryFront
                        checked={form.values.injury || {}}
                    />

                    <InjuryBack
                        checked={form.values.injury || {}}
                    />

                </Col>

            </Row>

        </SessionsEditTabsPanel>

    ), [props]);

}

export default SessionsEditTabsInjury;
