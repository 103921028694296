import { Button, ButtonRow, Col, FieldDate, FieldRadio, FieldSelect, FieldSwitch, FieldText, Form, Line, Panel, Row, useApp, useForm } from '@dex/bubl-dash';
import moment from 'moment';
import React, { useCallback } from 'react';
import FieldSessionsApi from '../FieldSessionsApi';

const FieldTestsAddNew: React.FC<any> = (props: any) => {

    const { data, onComplete } = props;

    const app = useApp();

    const form = useForm({ date: moment().toLocaleString() });

    const add = FieldSessionsApi.update(data.id, "addFieldTest", {

        onComplete: (data) => {

            app.alert('Field Test Added', 'success');

            onComplete(data.id);

        }

    });

    const handleSubmit = useCallback((values) => {

        add.run({ data: values });

    }, [props]);

    return (

        <Panel
            heading={"Add Field Test"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={add.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, lg: 12 }}>

                        <FieldDate
                            form={form}
                            label={"Test Date"}
                            name={`date`}
                            required={true}
                            defaultValue={form.defaults.date}
                            date={true}
                        />

                    </Col>

                    <Col col={{ xs: 24, lg: 12 }}>

                        <FieldText
                            form={form}
                            label={"Time of Day"}
                            name={`timeOfDay`}
                            required={false}
                            defaultValue={form.defaults.timeOfDay}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={add.loading}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    )
}



export default FieldTestsAddNew;