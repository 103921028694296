import React, { useMemo, useState } from 'react';
import TabBody from '../../../elements/TabBody/TabBody';
import LabTestsSweatWorkouts from './LabTestsSweatWorkouts';
import LabTestsSweatFluid from './LabTestsSweatFluid';
import LabTestsSweatRehydration from './LabTestsSweatRehydration';
import LabTestsSweatDehydration from './LabTestsSweatDehydration';
import LabTestsSweatComment from './LabTestsSweatComment';
import LabTestsSweatWorkoutCurrentMax from './LabTestsSweatWorkoutCurrentMax';

const LabTestsSweat: React.FC<any> = (props: any) => {

    const { form, data, session, tab, setKey, editable } = props;

    const key = props._key;

    return useMemo(() => (

        <>

            <TabBody
                form={form}
                active={tab === "workouts"}
                fields={["workouts"]}
            >

                <LabTestsSweatWorkouts
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "fluid"}
                fields={["fluid"]}
            >

                <LabTestsSweatFluid
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "currentMax"}
                fields={["currentMax"]}
            >

                <LabTestsSweatWorkoutCurrentMax
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "rehydration"}
                fields={["rehydration"]}
            >

                <LabTestsSweatRehydration
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "dehydration"}
                fields={["dehydration"]}
            >

                <LabTestsSweatDehydration
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

            <TabBody
                form={form}
                active={tab === "comments"}
                fields={["comment"]}
            >

                <LabTestsSweatComment
                    key={key}
                    form={form}
                    data={data}
                    session={session}
                    tab={tab}
                />

            </TabBody>

        </>

    ), [form.hash, data, session, tab, setKey, editable]);

}

export default LabTestsSweat;
