import { Col, Row, Space } from '@dex/bubl-dash';
import React from 'react';
import CoachingReportHeading from '../CoachingReportHeading/CoachingReportHeading';
import style from "./CoachingReportAverageTable.module.scss";

const CoachingReportAverageTable: React.FC<CoachingReportAverageTableProps> = (props) => {

    let { title, desc, avgA, avgATitle, avgB, avgBTitle } = props;

    return (

        <Row edge gutter={1} justify='center'>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text={title}
                    desc={desc && `- ${desc}`}
                />

            </Col>

            <Col col={{ xs: 16 }}>

                <Row edge gutter={8}>

                    {avgATitle &&
                        <>
                            <Col col={{ xs: 6 }} />

                            <Col col={{ xs: 18 }}>

                                <p className={style.value}>
                                    <strong>{avgATitle}</strong>
                                </p>


                            </Col>
                        </>
                    }

                    {avgA && avgA.map((item: any, index: number) => (

                        <React.Fragment key={index}>

                            <Col col={{ xs: 6 }} align='end'>

                                {item.title &&
                                    <p>
                                        <strong className={style.title}>{item.title}:</strong>
                                    </p>
                                }

                            </Col>

                            <Col col={{ xs: 18 }}>

                                <p className={style.value}>
                                    <strong>{item.valueA}</strong> /  <strong>{item.valueB}</strong>
                                    <span className={style.suffix}> {item.suffix}</span>
                                </p>

                            </Col>

                        </React.Fragment>

                    ))}

                    {avgBTitle &&
                        <>
                            <Col col={{ xs: 6 }} />

                            <Col col={{ xs: 18 }}>

                                <p className={style.value}>
                                    <strong>{avgBTitle}</strong>
                                </p>


                            </Col>
                        </>
                    }

                    {avgB && avgB.map((item: any, index: number) => (

                        <React.Fragment key={index}>

                            <Col col={{ xs: 6 }} align='end'>

                                {item.title &&
                                    <p>
                                        <strong className={style.title}>{item.title}:</strong>
                                    </p>
                                }

                            </Col>

                            <Col col={{ xs: 18 }}>

                                <p className={style.value}>
                                    <strong>{item.valueA}</strong> /  <strong>{item.valueB}</strong>
                                    <span className={style.suffix}> {item.suffix}</span>
                                </p>

                            </Col>

                        </React.Fragment>

                    ))}

                </Row>

            </Col>

        </Row>
    )

}

interface CoachingReportAverageTableProps {
    [key: string]: any,
}

export default CoachingReportAverageTable;