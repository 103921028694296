import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import EventTargetsApi from './EventTargetsApi';
import EventTargetsCreateNew from './Components/EventTargetsCreateNew';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New Event Target",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: EventTargetsCreateNew,
                getCreate: EventTargetsApi.create,
                getClone: EventTargetsApi.getOne,
                defaults: { steps: [], bestScore: "highest", decimalPlaces: 1, },
                successMessage: "Event Target Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey,
                rowProps: { gutter: "auto", },
                colProps: { className: "", col: { xs: 24 } }
            }
        },
    });

}