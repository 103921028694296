import React from 'react';
import { LineChart, ReferenceLine, Label } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { getDecimalTime } from '../../functions/sharedTests';
import { fancyTimeFormat } from '../../functions/sharedChart';

const ChartSprintPerformance: React.FC<ChartSprintPerformanceProps> = (props) => {

    let { data, activity, intensity, type, peak, rpm, performance } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity("time", intensity);

    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
        interval: 0,
        tickFormatter: undefined
    };

    Axis.x.label = "Sprint Time - Sec";

    if (activity == "Cycling") {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: performance,
            label: "Power",
            axisLabel: "Power - w",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 1,
            key: "rpm",
            label: "RPM",
            axisLabel: "RPM",
            props: {
                domain: [70, 150]
            }
        });
    } else {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "speed",
            label: "Speed",
            axisLabel: "Speed - kph",
        });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 1,
            key: "calc_pace",
            label: "Pace",
            axisLabel: "Pace",
            props: {
                tickFormatter: fancyTimeFormat
            }
        });
    }

    return (

        <Chart>

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >

                {peak &&
                    <ReferenceLine
                        x={peak}
                        stroke={ChartProps.indexColor(2)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={activity == "Cycling" ? "Peak Power" : "Peak Speed"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                        />
                    </ReferenceLine>
                }

                {rpm &&
                    <ReferenceLine
                        x={rpm}
                        stroke={ChartProps.indexColor(2)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={activity == "Cycling" ? "Peak RPM" : "Peak Pace"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                        />
                    </ReferenceLine>
                }

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartSprintPerformanceProps {
    [key: string]: any,
}

export default ChartSprintPerformance;