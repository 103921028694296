import React, { useContext, useEffect } from 'react';
import CoachingApi from '../CoachingApi';
import BestContext from '../../../context/BestContext';
import { slug, get, parseNum } from '@dex/bubl-helpers';
import { getDecimalTime } from '../../../functions/sharedTests';

const CoachingTrainingsBestProvider: React.FC<any> = (props: any) => {

    const { type, form, week, activity, endDate, values, data } = props;

    const best = CoachingApi.format(data.id, 'getBest');

    useEffect(() => {

        best.run({
            params: { type: type, endDate: endDate, activity: activity },
        });

    }, [endDate, activity]);

    return (

        <>
            <BestContext.Provider value={{ values: values, week: week, type: type, best: best.data }}>

                {props.children}

            </BestContext.Provider>

        </>

    );

}

const CoachingTrainingsIsBest: React.FC<any> = (props: any) => {

    let { name, size } = props;

    const { best, week, type, values } = useContext(BestContext);

    if (!best || !values) return <></>;

    let value = get(values, name);
    let key = slug(name.replace(/\./g, '_'));

    let liveValue = get(best, 'live.' + key);
    // let pastValue = get(best, 'past.' + key);

    if (!value) return <></>;
    if (!liveValue) return <></>;

    if (typeof value === "string" && value.indexOf(':') > 0) value = getDecimalTime(value);
    else if (typeof value === "string") value = parseNum(value);

    let isBest, isTop5;

    if (name == "avgPace") {
        isBest = value <= liveValue;
        isTop5 = value <= (liveValue * 1.05);
    } else {
        isBest = value >= liveValue;
        isTop5 = value >= (liveValue * 0.95);
    }

    return (
        <>
            {isBest &&
                <CoachingTrainingsIconBest size={size || 50} />
            }
            {!isBest && isTop5 &&
                <CoachingTrainingsIconTop5 size={size || 50} />
            }
        </>
    )
};

const CoachingTrainingsIconBest: React.FC<any> = (props: any) => {

    const size = props.size || 50;

    return (
        <img
            src={require("../../../assets/images/Coaching/medalBest.png")}
            style={{ width: size * 1.6, height: size + "px", objectFit: "scale-down" }}
        />
    )

}
const CoachingTrainingsIconTop5: React.FC<any> = (props: any) => {

    const size = props.size || 50;

    return (
        <img
            src={require("../../../assets/images/Coaching/medalTop.png")}
            style={{ width: size * 1.6 + "px", height: size + "px", objectFit: "scale-down" }}
        />
    )

}


export default CoachingTrainingsBestProvider

export { CoachingTrainingsIsBest, CoachingTrainingsIconBest, CoachingTrainingsIconTop5 };