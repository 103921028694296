import { slug } from '@dex/bubl-helpers';
import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { nearestMaxTen } from '../../functions/sharedChart';

const ChartVo2Max: React.FC<ChartVo2MaxProps> = (props) => {

    let { data, activity, index, date, intensity } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity(activity, intensity);

    const historyData = Axis.prepareHistoryData(data, slug(date), slug(index));

    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
    };

    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 0,
        key: "vo2relative_" + slug(date),
        label: date,
        axisLabel: "VO2 - ml/kg²min",
        props: {
            connectNulls: true,
            domain: [10, nearestMaxTen]
        }
    });

    if (date !== index) {

        Axis.y.push({
            axis: false,
            line: true,
            pos: "left",
            index: 1,
            key: "vo2relative_" + slug(index),
            label: index,
            props: {
                strokeDasharray: "6 4",
                connectNulls: true,
                labelPosition: "bottom"
            }
        });

    }

    return (

        <Chart>

            <LineChart
                {...ChartProps.LineChart()}
                data={historyData}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartVo2MaxProps {
    [key: string]: any,
}

export default ChartVo2Max;