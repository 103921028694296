import React, { useCallback, useMemo } from 'react';
import { useApp, useForm } from '@dex/bubl-dash';
import CoachingTrainingsIntervalsForm from './CoachingTrainingsIntervalsForm';
import CoachingTrainingIntervalsApi from '../../CoachingTrainingIntervals/CoachingTrainingIntervalsApi';

const CoachingTrainingsAddIntervals: React.FC<any> = (props: any) => {

    const { data, handleClose } = props;

    const app = useApp()

    const form = useForm({});

    const process = CoachingTrainingIntervalsApi.create();

    const handleSubmit = useCallback((values) => {

        values.coachingId = data.id;

        process.run({
            data: values,
            onComplete: (data) => {

                app.alert('Intervals Workout Saved', 'success');

                if (handleClose) handleClose();

            }
        });

    }, [props]);

    return useMemo(() => (

        <>
            <CoachingTrainingsIntervalsForm
                form={form}
                data={data}
                process={process}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
            />

        </>

    ), [form.hash, data, process])
}

export default CoachingTrainingsAddIntervals
