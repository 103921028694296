import React, { useMemo } from 'react';
import { GoDash, GoX } from "react-icons/go";
import styles from './coaching.module.scss';
import { getClockTime, getDecimalTime, getMinutesTime, percentageOf } from './sharedTests';
import { date, dateDay, fixedNum, loop, parseNum, unslug } from '@dex/bubl-helpers';
import moment from 'moment';
import * as _ from "lodash";
import { MdStar } from 'react-icons/md';
import { HiCheck, HiArrowNarrowUp, HiX, HiArrowNarrowDown } from "react-icons/hi";

export const getIcon = (target, actual, percent, source = "volume") => {

    let metTarget = <HiCheck className={styles.check} />;
    let aboveImage = <><HiArrowNarrowUp className={styles.up} /> </ >;
    let wayAboveImage = <><HiArrowNarrowUp className={styles.up} /> <HiArrowNarrowUp className={styles.up} /> </>;
    let didNotDoImage = <GoX className={styles.x} />;
    let blankImage = <GoDash className={styles.dash} />;
    let belowImage = <><HiArrowNarrowDown className={styles.down} /></ >;
    let wayBelowImage = <><HiArrowNarrowDown className={styles.down} /> <HiArrowNarrowDown className={styles.down} /> </>;

    if (!actual) return { icon: didNotDoImage, text: "Did Not Do" }

    if (!target) return { icon: blankImage, text: "Blank" }

    if (source != "volume") {
        if (percent <= 20 && percent > -20) {
            return {
                icon: metTarget, text: "Met"
            };
        } else if (percent > 20 && percent <= 50) {
            return {
                icon: aboveImage, text: 'Above'
            };
        } else if (percent > 50) {
            return {
                icon: wayAboveImage, text: 'Above'
            };
        } else if (percent < -20 && percent >= -50) {
            return {
                icon: belowImage, text: 'Below'
            };
        } else if (percent < -50) {
            return {
                icon: wayBelowImage, text: 'Below'
            };
        }
    }

    if (percent <= 15 && percent > -15) {
        return {
            icon: metTarget, text: "Met"
        };
    } else if (percent > 15 && percent <= 30) {
        return {
            icon: aboveImage, text: 'Above'
        };
    } else if (percent > 30) {
        return {
            icon: wayAboveImage, text: 'Above'
        };
    } else if (percent < -15 && percent >= -30) {
        return {
            icon: belowImage, text: 'Below'
        };
    } else if (percent < -30) {
        return {
            icon: wayBelowImage, text: 'Below'
        };
    }

};

export const calculateTarget = (target, actual, inputFormat = "time") => {

    let percent;

    if (inputFormat == "time") {
        target = getDecimalTime(target)
        actual = getDecimalTime(actual)
    }

    target = fixedNum(target)
    actual = fixedNum(actual)
    percent = ((actual - target) / target) * 100;

    return percent;

}

export const totalDuration = (set, reps, duration) => {

    const seconds = getMinutesTime(duration) || 0
    set = parseNum(set) || 1;
    reps = parseNum(reps) || 1;

    const output = (reps * set * seconds) / 60 / 60;

    return getClockTime(output, "hh:mm:ss")

}

export const totalDistance = (set, reps, distance) => {

    if (!distance) return;

    distance = parseNum(distance) || 0;
    set = parseNum(set) || 1;
    reps = parseNum(reps) || 1;

    return (reps * set * distance);

}

export const generateDateOptions = (start, end) => {
    const startDate = moment(start);
    const endDate = moment(end);
    const dates: any = [];

    while (startDate.isSameOrBefore(endDate)) {
        dates.push({
            value: startDate.format('YYYY-MM-DD'),
            label: startDate.format('MMMM D YYYY'),
        });
        startDate.add(1, 'day');
    }

    return dates
};

export const MaxAvgCoaching = (weeks, sportList: any = []) => {

    const volumeMetrics = () => ({
        avg: { distanceActual: 0, distanceTarget: 0, timeActual: 0, timeTarget: 0, workout: 0 },
        max: { distanceActual: 0, distanceTarget: 0, timeActual: 0, timeTarget: 0, workout: 0 },
        len: { distanceActual: 0, distanceTarget: 0, timeActual: 0, timeTarget: 0, workout: 0 },
    });

    const intensityMetrics = () => ({
        avg: { target: 0, hrZone: 0, time: 0, total: 0 },
        max: { target: 0, hrZone: 0, time: 0, total: 0 },
        len: { target: 0, hrZone: 0, time: 0, total: 0 },
    });

    const otherMetrics = () => ({
        avg: { value: 0 },
        max: { value: 0 },
        len: { value: 0 },
    });

    const rpeMetrics = () => ({
        avg: { rpe: 0, felt: 0 },
        max: { rpe: 0, felt: 0 },
        min: { rpe: Infinity, felt: Infinity },
        len: { rpe: 0, felt: 0 },
    });

    const updateMetrics = (metric, result, type) => {

        if (type == "volume") {
            if (metric.max.distanceActual < parseNum(result.distanceActual)) metric.max.distanceActual = parseNum(result.distanceActual);
            if (metric.max.distanceTarget < parseNum(result.distanceTarget)) metric.max.distanceTarget = parseNum(result.distanceTarget);
            if (metric.max.workout < parseNum(result.workout)) metric.max.workout = parseNum(result.workout);
            if (metric.max.timeActual < getMinutesTime(result.timeActual)) metric.max.timeActual = getMinutesTime(result.timeActual);
            if (metric.max.timeTarget < getMinutesTime(result.timeTarget)) metric.max.timeTarget = getMinutesTime(result.timeTarget);

            if (result.distanceActual != null || result.distanceActual != undefined) {
                metric.avg.distanceActual += parseNum(result.distanceActual);
                metric.len.distanceActual += 1;
            }
            if (result.distanceTarget != null || result.distanceTarget != undefined) {
                metric.avg.distanceTarget += parseNum(result.distanceTarget);
                metric.len.distanceTarget += 1;
            }
            if (result.workout != null || result.workout != undefined) {
                metric.avg.workout += parseNum(result.workout);
                metric.len.workout += 1;
            }
            if (result.timeActual != null || result.timeActual != undefined) {
                metric.avg.timeActual += getMinutesTime(result.timeActual);
                metric.len.timeActual += 1;
            }
            if (result.timeTarget != null || result.timeTarget != undefined) {
                metric.avg.timeTarget += getMinutesTime(result.timeTarget);
                metric.len.timeTarget += 1;
            }
        }

        if (type == "intensity") {
            if (metric.max.total < parseNum(result.total)) metric.max.total = parseNum(result.total);
            if (metric.max.target < getMinutesTime(result.target)) metric.max.target = getMinutesTime(result.target);
            if (metric.max.hrZone < getMinutesTime(result.hrZone)) metric.max.hrZone = getMinutesTime(result.hrZone);
            if (metric.max.time < getMinutesTime(result.time)) metric.max.time = getMinutesTime(result.time);

            if (result.total != null || result.total != undefined) {
                metric.avg.total += parseNum(result.total);
                metric.len.total += 1;
            }
            if (result.target != null || result.target != undefined) {
                metric.avg.target += getMinutesTime(result.target);
                metric.len.target += 1;
            }
            if (result.hrZone != null || result.hrZone != undefined) {
                metric.avg.hrZone += getMinutesTime(result.hrZone);
                metric.len.hrZone += 1;
            }
            if (result.time != null || result.time != undefined) {
                metric.avg.time += getMinutesTime(result.time);
                metric.len.time += 1;
            }
        }

        if (type == "other") {
            if (metric.max.value < parseNum(result)) metric.max.value = parseNum(result);
            if (result != null || result != undefined) {
                metric.avg.value += parseNum(result);
                metric.len.value += 1;
            }
        }

        if (type == "rpe") {
            if (metric.max.rpe < parseNum(result.rpe)) metric.max.rpe = parseNum(result.rpe);
            if (metric.min.rpe > parseNum(result.rpe)) metric.min.rpe = parseNum(result.rpe);
            if (result.rpe != null || result.rpe != undefined) {
                metric.avg.rpe += parseNum(result.rpe);
                metric.len.rpe += 1;
            }
            if (metric.max.felt < parseNum(result.felt)) metric.max.felt = parseNum(result.felt);
            if (metric.min.felt > parseNum(result.felt)) metric.min.felt = parseNum(result.felt);
            if (result.felt != null || result.felt != undefined) {
                metric.avg.felt += parseNum(result.felt);
                metric.len.felt += 1;
            }
        }

    };

    const outputFormat = (metric, length, type) => {

        if (type == "volume") {
            metric.avg.distanceActual = fixedNum(metric.avg.distanceActual / metric.len.distanceActual, 2);
            metric.avg.distanceTarget = fixedNum(metric.avg.distanceTarget / metric.len.distanceTarget, 2);
            metric.avg.workout = fixedNum(metric.avg.workout / metric.len.workout, 1);
            metric.avg.timeActual = getClockTime((metric.avg.timeActual / metric.len.timeActual) / 60);
            metric.avg.timeTarget = getClockTime((metric.avg.timeTarget / metric.len.timeTarget) / 60);
            metric.max.timeActual = getClockTime(metric.max.timeActual / 60);
            metric.max.timeTarget = getClockTime(metric.max.timeTarget / 60);
        }

        if (type == "intensity") {
            metric.avg.total = fixedNum(metric.avg.total / metric.len.total, 2);
            metric.avg.target = getClockTime((metric.avg.target / metric.len.target) / 60);
            metric.avg.hrZone = getClockTime((metric.avg.hrZone / metric.len.hrZone) / 60);
            metric.avg.time = getClockTime((metric.avg.time / metric.len.time) / 60);
            metric.max.target = getClockTime(metric.max.target / 60);
            metric.max.hrZone = getClockTime(metric.max.hrZone / 60);
            metric.max.time = getClockTime(metric.max.time / 60);
        }

        if (type == "other") metric.avg.value = fixedNum(metric.avg.value / metric.len.value, 2) || 0;

        if (type == "rpeRating") {
            metric.avg.rpe = fixedNum(metric.avg.rpe / metric.len.rpe, 2) || 0;
            metric.avg.felt = fixedNum(metric.avg.felt / metric.len.felt, 2) || 0;
        }

    };

    const volume = {
        swim: volumeMetrics(),
        bike: volumeMetrics(),
        run: volumeMetrics(),
        gym: volumeMetrics(),
        total: volumeMetrics(),
        totalEndurance: volumeMetrics()
    };

    const intensity = {
        swim: intensityMetrics(),
        bike: intensityMetrics(),
        run: intensityMetrics(),
        hr: intensityMetrics(),
        totalEndurance: intensityMetrics()
    };

    const other = {
        tss: otherMetrics(),
        fitness: otherMetrics(),
        fatigue: otherMetrics(),
        form: otherMetrics(),
        elevation: otherMetrics(),
        work: otherMetrics(),
        peakRun: otherMetrics(),
        peakBike: otherMetrics()
    };

    const rpeRating = {
        rating: rpeMetrics(),
    };

    loop(weeks, (week) => {

        loop(week.volume.results, (volumeResult, key) => {

            switch (key) {
                case "swim":
                    updateMetrics(volume.swim, volumeResult, "volume");
                    break;
                case "bike":
                    updateMetrics(volume.bike, volumeResult, "volume");
                    break;
                case "run":
                    updateMetrics(volume.run, volumeResult, "volume");
                    break;
                case "gym":
                    updateMetrics(volume.gym, volumeResult, "volume");
                    break;
                default:
                    break;
            }
        });

        loop(week.volume, (volumeTotals, key) => {
            switch (key) {
                case "total":
                    updateMetrics(volume.total, volumeTotals, "volume");
                    break;
                case "totalEndurance":
                    updateMetrics(volume.totalEndurance, volumeTotals, "volume");
                    break;
                default:
                    break;
            }
        });

        loop(week?.intensity?.results, (intensityResult, key) => {
            switch (key) {
                case "swim":
                    updateMetrics(intensity?.swim, intensityResult, "intensity");
                    break;
                case "bike":
                    updateMetrics(intensity?.bike, intensityResult, "intensity");
                    break;
                case "run":
                    updateMetrics(intensity?.run, intensityResult, "intensity");
                    break;
                case "hr":
                    updateMetrics(intensity?.hr, intensityResult, "intensity");
                    break;
                default:
                    break;
            }
        });

        loop(week?.intensity, (intensityTotals, key) => {
            switch (key) {
                case "totalEndurance":
                    updateMetrics(intensity?.totalEndurance, intensityTotals, "intensity");
                    break;
                default:
                    break;
            }
        });

        loop(week?.other, (otherResult, key) => {
            switch (key) {
                case "tss":
                    updateMetrics(other?.tss, otherResult, "other");
                    break;
                case "fitness":
                    updateMetrics(other?.fitness, otherResult, "other");
                    break;
                case "fatigue":
                    updateMetrics(other?.fatigue, otherResult, "other");
                    break;
                case "form":
                    updateMetrics(other?.form, otherResult, "other");
                    break;
                case "elevation":
                    updateMetrics(other?.elevation, otherResult, "other");
                    break;
                case "work":
                    updateMetrics(other?.work, otherResult, "other");
                    break;
                case "peakHrRun":
                    updateMetrics(other?.peakRun, otherResult, "other");
                    break;
                case "peakHrBike":
                    updateMetrics(other?.peakBike, otherResult, "other");
                    break;
                default:
                    break;
            }
        });

        loop(week?.rpe?.ratings || [], (ratingRpe) => {
            switch (ratingRpe?.title) {
                case "Rating Avg":
                    updateMetrics(rpeRating?.rating, ratingRpe, "rpe");
                    break;
                default:
                    break;
            }
        });

    });

    Object.values(volume).forEach(volume => outputFormat(volume, weeks.length, "volume"));
    Object.values(intensity).forEach(intensity => outputFormat(intensity, weeks.length, "intensity"));
    Object.values(other).forEach(other => outputFormat(other, other.len, "other"));
    Object.values(rpeRating).forEach(rpeRating => outputFormat(rpeRating, weeks.length, "rpeRating"));

    const { stimulus, stimulusMaxAvg } = stimulusCalculation(sportList, weeks, volume, intensity)

    const { fitness, bodyStress, fitnessMaxAvg, bodyMaxAvg } = fitnessCalculation(weeks, other, intensity, rpeRating, stimulus);

    return { volume, intensity, other, rpeRating, stimulus, stimulusMaxAvg, fitness, bodyStress, fitnessMaxAvg, bodyMaxAvg }
};

export const stimulusCalculation = (sportList, weeks, volume, intensity) => {

    const stimulus: any = [];

    const activities = [
        ...sportList,
        "total"
    ];

    loop(weeks, (w, i) => {

        stimulus[i] = stimulus[i] || {};

        activities.forEach(activity => {
            stimulus[i][activity] = stimulus[i][activity] || {};
            stimulus[i][activity].vol = stimulus[i][activity].vol || undefined;
            stimulus[i][activity].int = stimulus[i][activity].int || undefined;

            loop(w?.volume?.results, (volumeResult, key) => {
                if (key === activity && (volumeResult?.timeActual != null || volumeResult?.timeActual != undefined)) {
                    stimulus[i][activity].vol = percentageOf(getMinutesTime(volumeResult?.timeActual), getMinutesTime(volume[activity]?.avg?.timeActual));
                }
            });

            loop(w?.volume, (volumeTotal, key) => {
                if (key === "total" && activity == "total") {
                    stimulus[i][activity].vol = percentageOf(getMinutesTime(volumeTotal?.timeActual), getMinutesTime(volume.total?.avg?.timeActual));
                }
            });

            loop(w?.intensity?.results, (intensityResult, key) => {
                if (key === activity) {
                    stimulus[i][activity].int = percentageOf(getMinutesTime(intensityResult?.time), getMinutesTime(intensity[activity]?.avg?.time));
                }
            });

            loop(w?.intensity, (intensityTotal, key) => {
                if (key === "totalEndurance" && activity == "total") {
                    stimulus[i][activity].int = percentageOf(getMinutesTime(intensityTotal?.time), getMinutesTime(intensity?.totalEndurance?.avg?.time));
                }
            });

        });

    });

    loop(stimulus, (stim, s) => {

        if (stim?.swim) {
            stim.swim.avg = calcStimulusAvg(stim.swim.vol, stim.swim.int)
        }

        if (stim?.bike) {
            stim.bike.avg = calcStimulusAvg(stim.bike.vol, stim.bike.int)
        }

        if (stim?.run) {
            stim.run.avg = calcStimulusAvg(stim.run.vol, stim.run.int)
        }

        if (stim?.total) {
            stim.total.avg = calcStimulusAvg(stim.total.vol, stim.total.int)
        }
    })

    const stimulusMaxAvg = activities?.reduce((acc, activity) => {
        acc[activity] = {
            max: {
                vol: Math.max(...stimulus.map(s => s[activity]?.vol || 0)),
                int: Math.max(...stimulus.map(s => s[activity]?.int || 0)),
                avg: Math.max(...stimulus.map(s => s[activity]?.avg || 0))
            },
            avg: {
                vol: (_.meanBy(stimulus.filter(item => item[activity]?.vol !== undefined), (item) => item[activity]?.vol) || 0).toFixed(0),
                int: (_.meanBy(stimulus.filter(item => item[activity]?.int !== undefined), (item) => item[activity]?.int) || 0).toFixed(0),
                avg: (_.meanBy(stimulus.filter(item => item[activity]?.avg !== undefined), (item) => item[activity]?.avg) || 0).toFixed(0)
            }
        };
        return acc;
    }, {});

    return { stimulus, stimulusMaxAvg }

};

export const fitnessCalculation = (weeks, others, intensity, rpe, stimulus) => {

    const bodyData: any = [];
    const fitnessData: any = [];
    const maxAvgOther = { sum: 0, avg: 0, max: 0, len: 0 };
    const maxAvgHR = { sum: 0, avg: 0, max: 0, len: 0 };
    const maxAvgRpe = { sum: 0, avg: 0, max: 0, len: 0 };
    const maxAvgFelt = { sum: 0, avg: 0, max: 0, len: 0 };
    const bodyAvg = { sum: 0, avg: 0, max: 0, len: 0 };

    loop(weeks, (w, i) => {

        bodyData[i] = {};
        fitnessData[i] = {};

        loop(w?.other || [], (value, type) => {
            if (type === "fitness") {
                fitnessData[i].fitness = percentageOf(value, others?.fitness?.avg?.value);

            }
        })

        loop(w?.intensity?.results || [], (result, type) => {
            if (type === "hr") {
                bodyData[i].hr = percentageOf(getDecimalTime(result.time), getDecimalTime(intensity?.hr?.avg?.time)) || undefined;
            }
        });

        loop(w?.rpe?.ratings || [], (rpeRating) => {
            if (rpeRating.title == "Rating Avg") {
                bodyData[i].rpe = percentageOf(rpeRating?.rpe, rpe?.rating?.avg?.rpe);
                bodyData[i].felt = percentageOf(rpeRating?.felt, rpe?.rating?.avg?.felt);
            }
        })

    })

    loop(bodyData, (data, n) => {
        const totalStimulus = stimulus[n].total.avg;

        maxAvgHR.sum += parseNum(data.hr);

        if (data.hr != null || data.hr != undefined) {
            maxAvgHR.len += 1;
        }

        maxAvgRpe.sum += parseNum(data.rpe);

        if (data.rpe != null || data.rpe != undefined) {
            maxAvgRpe.len += 1;
        }

        maxAvgFelt.sum += parseNum(data.felt);

        if (data.felt != null || data.felt != undefined) {
            maxAvgFelt.len += 1;
        }

        bodyData[n].avg = fixedNum(((data.hr * 1.1) + (totalStimulus * 1.2) + (data.rpe * 0.8) + (data.felt * 0.9)) / 4, 0) || undefined
    })

    loop(bodyData, (data, n) => {

        bodyAvg.sum += parseNum(data.avg);

        if (data.avg != null || data.avg != undefined) {
            bodyAvg.len += 1;
        }

    })

    loop(fitnessData, (data, n) => {
        maxAvgOther.sum += parseNum(data.fitness);
        if (data.fitness != null || data.fitness != undefined) {
            maxAvgOther.len += 1;
        }
    })

    maxAvgOther.avg = fixedNum(maxAvgOther.sum / maxAvgOther.len, 0)
    maxAvgOther.max = _.maxBy(fitnessData, 'fitness')?.fitness;

    maxAvgHR.avg = fixedNum(maxAvgHR.sum / maxAvgHR.len, 0)
    maxAvgHR.max = _.maxBy(bodyData, 'hr')?.hr;

    maxAvgRpe.avg = fixedNum(maxAvgRpe.sum / maxAvgRpe.len, 0)
    maxAvgRpe.max = _.maxBy(bodyData, 'rpe')?.rpe;

    maxAvgFelt.avg = fixedNum(maxAvgFelt.sum / maxAvgFelt.len, 0)
    maxAvgFelt.max = _.maxBy(bodyData, 'felt')?.felt;

    bodyAvg.avg = fixedNum(bodyAvg.sum / bodyAvg.len, 0)
    bodyAvg.max = _.maxBy(bodyData, 'avg')?.avg;

    const fitnessMaxAvg = { fitness: maxAvgOther }
    const bodyMaxAvg = { hr: maxAvgHR, rpe: maxAvgRpe, felt: maxAvgFelt, avg: bodyAvg }

    return { fitness: fitnessData, bodyStress: bodyData, fitnessMaxAvg, bodyMaxAvg };
}

export const calculateRating = (value, type) => {

    let aboveAverage = <><HiArrowNarrowUp className={styles.up} /> </ >;
    let high = <><HiArrowNarrowUp className={styles.up} /> <HiArrowNarrowUp className={styles.up} /> </>;
    let veryHigh = <><HiArrowNarrowUp className={styles.up} /> <HiArrowNarrowUp className={styles.up} /> <HiArrowNarrowUp className={styles.up} /> </>;
    let average = <HiCheck className={styles.check} />;
    let noData = <GoX className={styles.x} />;
    let belowAverage = <><HiArrowNarrowDown className={styles.down} /></ >;
    let low = <><HiArrowNarrowDown className={styles.down} /> <HiArrowNarrowDown className={styles.down} /> </>;
    let veryLow = <><HiArrowNarrowDown className={styles.down} /> <HiArrowNarrowDown className={styles.down} /> <HiArrowNarrowDown className={styles.down} /> </>;

    let icon: any;

    if (!value || isNaN(value)) {
        return icon = noData;
    }

    if (type == "hr") {
        if (value < 75) {
            return icon = veryLow;
        } else if (value >= 75 && value < 85) {
            return icon = low;
        } else if (value >= 85 && value < 95) {
            return icon = belowAverage;
        } else if (value >= 95 && value < 105) {
            return icon = average;
        } else if (value >= 105 && value < 115) {
            return icon = aboveAverage;
        } else if (value >= 115 && value < 125) {
            return icon = high;
        } else {
            return icon = veryHigh;
        }
    } else if (type === "stimulus") {
        if (value < 20) {
            return icon = veryLow;
        } else if (value >= 20 && value < 50) {
            return icon = low;
        } else if (value >= 50 && value < 80) {
            return icon = belowAverage;
        } else if (value >= 80 && value < 120) {
            return icon = average;
        } else if (value >= 120 && value < 150) {
            return icon = aboveAverage;
        } else if (value >= 150 && value < 180) {
            return icon = high;
        } else {
            return icon = veryHigh;
        }
    } else if (type === "body stress") {
        if (value < 65) {
            return icon = veryLow;
        } else if (value >= 65 && value < 75) {
            return icon = low;
        } else if (value >= 75 && value < 85) {
            return icon = belowAverage;
        } else if (value >= 85 && value < 115) {
            return icon = average;
        } else if (value >= 115 && value < 125) {
            return icon = aboveAverage;
        } else if (value >= 125 && value < 135) {
            return icon = high;
        } else {
            return icon = veryHigh;
        }
    }

};

export const getWeek = (form, data) => {

    const { week, block, weeks } = useMemo(() => {

        const weeks: any = [];
        let week: any = {};
        let block: any = {};

        if (!form.values.planId) return { week, weeks, block }

        loop(data?.plans, (plan) => {

            if (plan.id !== form.values.planId) return;

            loop(plan.blocks, (_block) => {

                loop(_block.weeks, (_week, index) => {

                    if (_week.calendar === form.values.weekNo) {
                        week = _week;
                        block = _block;
                    }

                    weeks.push({ value: _week.calendar, label: "Week " + _week.calendar + ' Ending: ' + _week.endDate })

                });

            })

        })

        weeks.sort((a, b) => b.value - a.value);

        return { week, weeks, block }

    }, [form.values.planId, form.values.weekNo]);

    return { week, weeks, block }

}

export const coachingGetLastWeek = (data, lastWeek) => {

    let dateNow = moment();
    let inBetweenPlan: any = {}
    let targetWeek: any = {};
    let block;

    loop(data.plans, (plan, i) => {

        if (dateNow.isBetween(plan.startDate, plan.endDate)) {

            inBetweenPlan = plan

            loop(plan.blocks, (_block, n) => {

                loop(_block.weeks, (_week, index) => {

                    if (lastWeek == true && dateNow.isAfter(moment(_week.endDate))) {
                        targetWeek = _week;
                        block = _block
                    } else if (lastWeek == false && dateNow.isBetween(moment(_week.startDate), moment(_week.endDate).add(1, 'days'))) {
                        targetWeek = _week;
                        block = _block
                    }

                });

            })
        }

    })

    return { inBetweenPlan, block, targetWeek }
}

export const coachingGetWeeks = (data, past = false) => {

    const weeks: any = [];

    const today = moment();

    loop(data.plans, (plan, plan_index) => {

        loop(plan.blocks, (block) => {

            loop(block.weeks, (week, index) => {

                if (past && today.isBefore(moment(week.endDate))) return;

                weeks.push({ value: week.endDate, label: date(week.endDate, "DD MMMM YYYY") })

            });

        })

    })

    weeks.reverse();

    return weeks

}

export const calculateLogTotals = (values) => {

    let hour: any = 0;
    let revenue: any = 0;
    let rate = 0;

    loop(values, (row) => {
        hour += getDecimalTime(row.totalHour)
        revenue += parseNum(row.price)
    })

    rate = fixedNum(revenue / hour, 0);
    hour = getClockTime(hour);

    return { hour, rate, revenue }
}

export const getStars = (number: number) => {

    let star;

    if (!number) return;

    star = Array.from({ length: number }, (_, index) => (
        <MdStar key={index} className={styles.iconStar} />
    ))

    return star
}

export const getCheck = (number: number) => {

    let star;

    if (!number) return;

    star = Array.from({ length: number }, (_, index) => (
        <MdStar key={index} className={styles.iconStar} />
    ))

    return star
}

export const getArrowDifference = (pre, post) => {

    let metTarget = <HiCheck className={styles.check} />;
    let above = <><HiArrowNarrowUp className={styles.up} /> </ >;
    let didNotDo = <HiX className={styles.x} />;
    let below = <><HiArrowNarrowDown className={styles.down} /></ >;

    if (!pre || !post) return didNotDo;

    const preSec = getMinutesTime(pre);
    const postSec = getMinutesTime(post);

    if (postSec == preSec) {
        return metTarget;
    } else if (postSec > preSec) {
        return above;
    } else {
        return below;
    }
};

export const eventRatingIcons = (rating) => {

    let icons = "";

    while (rating > 0) {
        icons += "★";
        rating--;
    }

    return icons;

}

export const calcStimulusAvg = (vol, int) => {
    let output = 0;
    if ((!vol || !int)) return;
    output = fixedNum(((vol * 0.9) + (int * 1.1)) / 2, 2);
    return output;
}
