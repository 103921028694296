import React, { useContext, useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import EditableContext from '../../../context/EditableContext';
import SessionsCreateEditForm from './SessionsCreateEditForm';

const SessionsCreateNew: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    return useMemo(() => (

        <EditableContext.Provider value={true}>

            <Panel
                heading={"Session Details"}
            >

                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={process.loading}
                >

                    <SessionsCreateEditForm
                        form={form}
                        handleSubmit={handleSubmit}
                        process={process}
                    />

                    <Line />

                    <ButtonRow>
                        <Button
                            label={"Save"}
                            loading={process.loading}
                        />
                    </ButtonRow>

                </Form>

            </Panel>

        </EditableContext.Provider>

    ), [props, form.hash]);

}

export default SessionsCreateNew;
