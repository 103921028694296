import React, { useCallback, useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldRange from '@dex/bubl-dash/src/lib/components/Fields/FieldRange';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';
import LabelWithImage from '../../../elements/LabelWithImage/LabelWithImage';
import { get, set, titleCase } from '@dex/bubl-helpers';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import { calculateRatingStability } from '../../../functions/calculateRating';
import { Button } from '@dex/bubl-dash';
import { getStabilityComments } from '../../../functions/sharedBikeFit';

const SessionsEditTabsStability: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit, settings } = props;

    const getCoreImage = useCallback((id) => {

        const gender = titleCase(data.athlete.gender || "male");

        return require('../../../assets/images/Stability/Core' + id.toString() + gender + ".png");

    }, []);

    const useDefaultText = useCallback((defaultName, name: string) => {

        let text = get(settings.fit, defaultName);

        const values = form.values;

        set(values, name, text);

        form.reset(values);

    }, [props, form.hash]);

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Stability"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, lg: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                form={form}
                                label={"Single Leg Squat (Left)"}
                                name='stability.legSquatLeft'
                                required={false}
                                defaultValue={form.defaults.stability.legSquatLeft}
                                options={[
                                    { value: "Stable", label: "Stable" },
                                    { value: "Some Instability", label: "Some Instability" },
                                    { value: "Lots of Instability", label: "Lots of Instability" }
                                ]}
                                button={true}
                            />

                            {(form.values.stability.legSquatLeft && form.values.stability.legSquatLeft !== "Stable") &&
                                <FieldCheckbox
                                    form={form}
                                    name='stability.legSquatLeftDetails'
                                    list={true}
                                    defaultValue={form.defaults.stability.legSquatLeftDetails}
                                    options={[
                                        { value: "Torso", label: "Torso" },
                                        { value: "Hip", label: "Hip" },
                                        { value: "Knee", label: "Knee" },
                                        { value: "Foot", label: "Foot" },
                                    ]}
                                />
                            }

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                form={form}
                                label={"Single Leg Squat (Right)"}
                                name='stability.legSquatRight'
                                required={false}
                                defaultValue={form.defaults.stability.legSquatRight}
                                options={[
                                    { value: "Stable", label: "Stable" },
                                    { value: "Some Instability", label: "Some Instability" },
                                    { value: "Lots of Instability", label: "Lots of Instability" }
                                ]}
                                button={true}
                            />

                            {(form.values.stability.legSquatRight && form.values.stability.legSquatRight !== "Stable") &&
                                <FieldCheckbox
                                    form={form}
                                    name='stability.legSquatRightDetails'
                                    list={true}
                                    defaultValue={form.defaults.stability.legSquatRightDetails}
                                    options={[
                                        { value: "Torso", label: "Torso" },
                                        { value: "Hip", label: "Hip" },
                                        { value: "Knee", label: "Knee" },
                                        { value: "Foot", label: "Foot" },
                                    ]}
                                />
                            }


                        </Col>

                        <Col col={{ xs: 24 }}>
                            <Line />
                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Core Stability"}
                                name='stability.coreStability'
                                required={false}
                                defaultValue={form.defaults.stability.coreStability}
                                options={[
                                    { value: "1", label: <LabelWithImage image={getCoreImage(1)} text={"Level 1"} /> },
                                    { value: "2", label: <LabelWithImage image={getCoreImage(2)} text={"Level 2"} /> },
                                    { value: "3", label: <LabelWithImage image={getCoreImage(3)} text={"Level 3"} /> },
                                    { value: "4", label: <LabelWithImage image={getCoreImage(4)} text={"Level 4"} /> },
                                    { value: "5", label: <LabelWithImage image={getCoreImage(5)} text={"Level 5"} /> },
                                ]}
                                button={true}
                                wrapClass={"checkbox-with-image"}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>
                            <Line />
                        </Col>

                    </Row>

                </Col>


                <Col col={{ xs: 24, lg: 8 }}>

                    <Row gutter={8} edge={true} justify={'center'} align={'center'}>

                        {form.values.athlete.gender === 'female' &&

                            <img src={require('../../../assets/images/Stability/StabilitySquatFemale.png')}
                                alt='squat-female'
                                className="stabilityImage"
                            />

                        }

                        {form.values.athlete.gender === 'male' &&

                            <img src={require('../../../assets/images/Stability/StabilitySquatMale.png')}
                                alt='squat-male'
                                className="stabilityImage"
                            />


                        }



                    </Row>

                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldRange
                        form={form}
                        label={"Rating (Suggested: " + calculateRatingStability(form.values.stability) + ")"}
                        name='stability.rating'
                        required={false}
                        defaultValue={form.defaults.stability.rating}
                        min={0}
                        max={10}
                        minLabel={"0: Poor"}
                        maxLabel={"10: Good"}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Comments"}
                        name={'stability.comments'}
                        required={false}
                        defaultValue={form.defaults.stability.comments}
                        rows={4}
                        maxLength={400}
                    />

                    <Row edge={true}>

                        <Button
                            label={'Use Default Text'}
                            onClick={useDefaultText.bind(null, `stabilityComment`, "stability.comments")}
                            type={'faded'}
                            confirm={form.values.stability.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current comment' } : undefined}
                        />

                    </Row>

                </Col>

            </Row>

        </SessionsEditTabsPanel >


    ), [props]);

}

export default SessionsEditTabsStability;
