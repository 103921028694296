import React from 'react';
import { Panel } from '@dex/bubl-dash';
import styles from "./GaitReportPage.module.scss";
import { date, upperCase } from '@dex/bubl-helpers';

const GaitReportPage: React.FC<any> = (props: any) => {

    const { data, index, heading, name, forwardedRef } = props;

    return (

        <>

            <Panel
                id={index}
                full={true}
                heading={false}
                forwardedRef={forwardedRef}
                className={styles.page + " report-page"}
            >

                {heading &&
                    <div className={styles.pageHeader}>

                        <div className={styles.row}>
                            <div className={styles.left}>
                                <h3 className={styles.athleteName}>
                                    {data.athlete.fullName}
                                </h3>

                                <p className={styles.headerSubText}>
                                    {data.id}
                                </p>
                            </div>

                            <div className={styles.right}>
                                <img src={require('../../../assets/images/Cover/VO2MAX.png')} alt="logo" className={styles.logo} />

                                <p className={styles.date}>
                                    {date(data.sessionDate)}
                                </p>
                            </div>
                        </div>

                    </div>
                }

                <div className={styles.body}>
                    {props.children}
                </div>

            </Panel>

        </>

    );

}

export const ColoredHeader: React.FC<any> = (props: any) => {

    const { heading, desc } = props;

    return (
        <>
            {heading &&
                <div className={styles.heading}>
                    <h1>{upperCase(heading)}</h1>
                    {desc && <h2>{desc}</h2>}
                </div>
            }
        </>
    )
}

export default GaitReportPage;
