import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';
import FieldRange from '@dex/bubl-dash/src/lib/components/Fields/FieldRange'
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import { calculateRatingSymmetry } from '../../../functions/calculateRating';
import { getHipPhoto, getSymmetryComments } from '../../../functions/sharedBikeFit';
import { Button, ButtonRow } from '@dex/bubl-dash';
import { get, set } from '@dex/bubl-helpers';

const SessionsEditTabsSymmetry: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, settings } = props;

    const useDefaultText = useCallback((defaultName, name: string) => {

        let text = get(settings.fit, defaultName);

        const values = form.values;

        set(values, name, text);

        form.reset(values);

    }, [props, form.hash]);

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Symmetry"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 16, lg: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12, lg: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Hip Level Standing"}
                                name='symmetry.hipLevelStanding'
                                required={false}
                                defaultValue={form.defaults.symmetry.hipLevelStanding}
                                options={[
                                    { value: "Even", label: "Even" },
                                    { value: "Right Lower", label: "Right Lower" },
                                    { value: "Left Lower", label: "Left Lower" },
                                    { value: "Uncertain", label: "Uncertain" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12, lg: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Hip Rotation Lying"}
                                name='symmetry.hipLevelLying'
                                required={false}
                                defaultValue={form.defaults.symmetry.hipLevelLying}
                                options={[
                                    { value: "Even", label: "Even" },
                                    { value: "Right Lower", label: "Right Lower" },
                                    { value: "Right Dropped", label: "Right Dropped" },
                                    { value: "Left Lower", label: "Left Lower" },
                                    { value: "Left Dropped", label: "Left Dropped" },
                                    { value: "Uncertain", label: "Uncertain" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12, lg: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Hip Rotation Standing"}
                                name='symmetry.hipRotationStanding'
                                required={false}
                                defaultValue={form.defaults.symmetry.hipRotationStanding}
                                options={[
                                    { value: "Even", label: "Even" },
                                    { value: "Right Forward", label: "Right Forward" },
                                    { value: "Right Dropped", label: "Right Dropped" },
                                    { value: "Left Forward", label: "Left Forward" },
                                    { value: "Left Dropped", label: "Left Dropped" },
                                    { value: "Uncertain", label: "Uncertain" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12, lg: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Leg Length"}
                                name='symmetry.legLength'
                                required={false}
                                defaultValue={form.defaults.symmetry.legLength}
                                options={[
                                    { value: "Even", label: "Even" },
                                    { value: "Right Shorter", label: "Right Shorter" },
                                    { value: "Left Shorter", label: "Left Shorter" },
                                    { value: "Uncertain", label: "Uncertain" },
                                ]}
                            />

                        </Col>

                        {(
                            form.values.symmetry.hipLevelStanding && form.values.symmetry.hipLevelStanding !== 'Even' ||
                            form.values.symmetry.hipLevelLying && form.values.symmetry.hipLevelLying !== 'Even' ||
                            form.values.symmetry.hipRotationStanding && form.values.symmetry.hipRotationStanding !== 'Even'
                        ) &&

                            <Col col={{ xs: 24, md: 12, lg: 12 }}>

                                <div className='w-240'>

                                    <FieldNumber
                                        form={form}
                                        name="symmetry.hipDrop"
                                        label="Hip Drop"
                                        suffix={" mm"}
                                        defaultValue={form.defaults.symmetry.hipDrop}
                                        allowNegative={true}
                                    />

                                </div>

                            </Col>

                        }

                        {(form.values.symmetry.legLength !== null && form.values.symmetry.legLength !== 'Even') &&

                            <Col col={{ xs: 24, md: 12, lg: 12 }}>

                                <div className='w-240'>

                                    <FieldNumber
                                        form={form}
                                        name="symmetry.legLengthDetails"
                                        label="Leg Length Details"
                                        suffix={" mm"}
                                        defaultValue={form.defaults.symmetry.legLengthDetails}
                                        button={true}
                                    />

                                </div>

                            </Col>

                        }

                        {/* <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Foot Walk Left"}
                                name='symmetry.footWalkLeft'
                                required={false}
                                defaultValue={form.defaults.symmetry.footWalkLeft}
                                options={[
                                    { value: "Neutral", label: "Neutral" },
                                    { value: "Turned Outwards", label: "Turned Outwards" },
                                    { value: "Turned Inwards", label: "Turned Inwards" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Foot Walk Right"}
                                name='symmetry.footWalkRight'
                                required={false}
                                defaultValue={form.defaults.symmetry.footWalkRight}
                                options={[
                                    { value: "Neutral", label: "Neutral" },
                                    { value: "Turned Outwards", label: "Turned Outwards" },
                                    { value: "Turned Inwards", label: "Turned Inwards" }
                                ]}
                            />

                        </Col> */}

                        <Col col={{ xs: 24 }}>
                            <Line />
                        </Col>


                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Hip/Leg Asymmetry"}
                                name='symmetry.asymmetry'
                                required={false}
                                defaultValue={form.defaults.symmetry.asymmetry}
                                options={[
                                    { value: "Functional", label: "Functional" },
                                    { value: "Structural", label: "Structural" },
                                    { value: "Both", label: "Both" },
                                    { value: "Uncertain", label: "Uncertain" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Reactive Eye"}
                                name='symmetry.reactiveEye'
                                required={false}
                                defaultValue={form.defaults.symmetry.reactiveEye}
                                options={[
                                    { value: "Left", label: "Left" },
                                    { value: "Right", label: "Right" },
                                    { value: "Both", label: "Both" },
                                    { value: "Neither", label: "Neither" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12, lg: 12 }}>

                            <FieldRadio
                                form={form}
                                label={"Improved?"}
                                name='symmetry.improved'
                                required={false}
                                defaultValue={form.defaults.symmetry.improved}
                                options={[
                                    { value: "Fully", label: "Fully" },
                                    { value: "Partially", label: "Partially" },
                                    { value: "Unchanged", label: "Unchanged" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12, lg: 12 }}>

                            <FieldCheckbox
                                form={form}
                                label={"With?"}
                                name='symmetry.with'
                                required={false}
                                defaultValue={form.defaults.symmetry.with}
                                options={[
                                    { value: "Isometrics", label: "Isometrics" },
                                    { value: "Magnets", label: "Magnets" },
                                    { value: "Arch Support", label: "Arch Support" },
                                    { value: "Shims", label: "Shims" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <Line />

                        </Col>

                        <Col col={{ xs: 24, lg: 24 }}>

                            <FieldRange
                                form={form}
                                label={"Rating (Suggested: " + calculateRatingSymmetry(form.values.symmetry) + ")"}
                                name='symmetry.rating'
                                required={false}
                                defaultValue={form.defaults.symmetry.rating}
                                min={0}
                                max={10}
                                minLabel={"0: Poor"}
                                maxLabel={"10: Good"}
                            />

                        </Col>

                        <Col col={{ xs: 24, lg: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Comments"}
                                name={'symmetry.comments'}
                                required={false}
                                defaultValue={form.defaults.symmetry.comments}
                                rows={4}
                                maxLength={400}
                            />

                            <ButtonRow>

                                <Button
                                    label={'Use Default Text'}
                                    onClick={useDefaultText.bind(null, `symmetryComment`, "symmetry.comments")}
                                    type={'faded'}
                                    confirm={form.values.stability.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current comment?' } : undefined}
                                />

                            </ButtonRow>

                        </Col>

                    </Row>

                </Col>


                <Col col={{ xs: 24, md: 8 }} align='center' justify='' className='text-center'>

                    <img src={require('../../../assets/images/Hip/Hip' + getHipPhoto((form.values.symmetry.hipLevelStanding || "Even"), (form.values.symmetry.legLength || "Even")))}
                        alt="Hip"
                        className="symmetryImage"
                    />

                </Col>

            </Row>

        </SessionsEditTabsPanel >


    ), [props]);

}

export default SessionsEditTabsSymmetry;
