import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
// import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';

const SessionsEditTabsSizingPosture: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;

    return useMemo(() => (

        <Row gutter={16} edge={true} className='row-top-edge'>

            <Col col={{ xs: 24, md: 12 }}>

                <Seperator
                    bottom="auto"
                    heading={"Upper Body"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldSelect
                            button={true}
                            form={form}
                            label={"Shoulder Shifting"}
                            name={`${prefix}.shoulderShifting`}
                            required={false}
                            defaultValue={bike.shoulderShifting}
                            options={[
                                { value: "None", label: "None" },
                                { value: "Stable", label: "Stable" },
                                { value: "Shift Left", label: "Shift Left" },
                                { value: "Shift Right", label: "Shift Right" },
                                { value: "Minimal", label: "Minimal" },
                                { value: "Moderate", label: "Moderate" },
                                { value: "Excessive", label: "Excessive" },
                                { value: "Left & Right", label: "Left & Right" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />
                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldSelect
                            button={true}
                            form={form}
                            label={"Shoulder Level"}
                            name={`${prefix}.shoulderLevel`}
                            required={false}
                            defaultValue={bike.shoulderLevel}
                            options={[
                                { value: "Even", label: "Even" },
                                { value: "Left Lower", label: "Left Lower" },
                                { value: "Right Lower", label: "Right Lower" },
                                { value: "Left Forward", label: "Left Forward" },
                                { value: "Right Forward", label: "Right Forward" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />
                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldSelect
                            button={true}
                            form={form}
                            label={"Elbow Posture"}
                            name={`${prefix}.elbowPosture`}
                            required={false}
                            defaultValue={bike.elbowPosture}
                            options={[
                                { value: "Both Bent", label: "Both Bent" },
                                { value: "Both Locked", label: "Both Locked" },
                                { value: "Right Locked", label: "Right Locked" },
                                { value: "Left Locked", label: "Left Locked" },
                                { value: "Both Straight", label: "Both Straight" },
                                { value: "Right Straight", label: "Right Straight" },
                                { value: "Left Straight", label: "Left Straight" },
                                { value: "Exaggerated Bend", label: "Exaggerated Bend" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldSelect
                            button={true}
                            form={form}
                            label={"Torso Posture"}
                            name={`${prefix}.torsoPosture`}
                            required={false}
                            defaultValue={bike.torsoPosture}
                            options={[
                                { value: "Normal", label: "Normal" },
                                { value: "Rounded", label: "Rounded" },
                                { value: "Flat", label: "Flat" },
                                { value: "Kinked", label: "Kinked" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldSelect
                            button={true}
                            form={form}
                            label={"Torso Balance"}
                            name={`${prefix}.torsoBalance`}
                            required={false}
                            defaultValue={bike.torsoBalance}
                            options={[
                                { value: "Can Balance", label: "Can Balance" },
                                { value: "Struggle to Hold", label: "Struggle to Hold" },
                                { value: "Cannot Hold", label: "Cannot Hold" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    {bike.handlebarType === 'Aerobars' &&
                        <>
                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldSelect
                                    button={true}
                                    form={form}
                                    label={"Aero Frontal Tuck Position"}
                                    name={`${prefix}.aeroPositionFront`}
                                    required={false}
                                    defaultValue={bike.aeroPositionFront}
                                    options={[
                                        { value: "Pyramid Shape", label: "Pyramid Shape" },
                                        { value: "U-Shape", label: "U-Shape" },
                                        { value: "V-Shape Wide", label: "V-Shape Wide" },
                                        { value: "V-Shape Narrow", label: "V-Shape Narrow" },
                                        { value: "Not Evaluated", label: "Not Evaluated" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldSelect
                                    button={true}
                                    form={form}
                                    label={"Aero View - Hand Position"}
                                    name={`${prefix}.aeroPositionHandSlide`}
                                    required={false}
                                    defaultValue={bike.aeroPositionHandSlide}
                                    options={[
                                        { value: "Below Elbows", label: "Below Elbows" },
                                        { value: "At Elbows", label: "At Elbows" },
                                        { value: "Above Elbows", label: "Above Elbows" },
                                        { value: "Above Elbows++", label: "Above Elbows++" },
                                        { value: "Not Evaluated", label: "Not Evaluated" },

                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldSelect
                                    button={true}
                                    form={form}
                                    label={"Aero View - Elbow Position"}
                                    name={`${prefix}.aeroPositionElbowSide`}
                                    required={false}
                                    defaultValue={bike.aeroPositionElbowSide}
                                    options={[
                                        { value: "Forwards of Shoulder", label: "Forwards of Shoulder" },
                                        { value: "Below Shoulders", label: "Below Shoulders" },
                                        { value: "Behind Shoulders", label: "Behind Shoulders" },
                                        { value: "Not Evaluated", label: "Not Evaluated" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldSelect
                                    button={true}
                                    form={form}
                                    label={"Aero View - Rear Position"}
                                    name={`${prefix}.aeroPositionRear`}
                                    required={false}
                                    defaultValue={bike.aeroPositionRear}
                                    options={[
                                        { value: "Cannot See Head", label: "Cannot See Head" },
                                        { value: "Can See Head a Bit", label: "Can See Head a Bit" },
                                        { value: "Can See Head a Lot", label: "Can See Head a Lot" },
                                        { value: "Not Evaluated", label: "Not Evaluated" },
                                    ]}
                                />

                            </Col>
                        </>

                    }

                </Row>

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <Seperator
                    bottom="auto"
                    heading={"Lower Body"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldSelect
                            button={true}
                            form={form}
                            label={"Hip Shifting"}
                            name={`${prefix}.hipShifting`}
                            required={false}
                            defaultValue={bike.hipShifting}
                            options={[
                                { value: "Stable", label: "Stable" },
                                { value: "Some Shifting", label: "Some Shifting" },
                                { value: "Lots of Shifting", label: "Lots of Shifting" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldSelect
                            button={true}
                            form={form}
                            label={"Hip Rotation"}
                            name={`${prefix}.hipRotation`}
                            required={false}
                            defaultValue={bike.hipRotation}
                            options={[
                                { value: "Even", label: "Even" },
                                { value: "Left Forwards", label: "Left Forwards" },
                                { value: "Right Forwards", label: "Right Forwards" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldSelect
                            button={true}
                            form={form}
                            label={"Hip Drop"}
                            name={`${prefix}.hipDrop`}
                            required={false}
                            defaultValue={bike.hipDrop}
                            options={[
                                { value: "Even", label: "Even" },
                                { value: "Left Drops", label: "Left Drops" },
                                { value: "Right Drops", label: "Right Drops" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Knee Tracking (Left)"}
                            name={`${prefix}.kneeTrackingLeft`}
                            required={false}
                            defaultValue={bike.kneeTrackingLeft}
                            options={[
                                { value: "Straight", label: "Straight" },
                                { value: "Inwards", label: "Inwards" },
                                { value: "Inwards A Lot", label: "Inwards A Lot" },
                                { value: "Outwards", label: "Outwards" },
                                { value: "Outwards A Lot", label: "Outwards A Lot" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Knee Tracking (Right)"}
                            name={`${prefix}.kneeTrackingRight`}
                            required={false}
                            defaultValue={bike.kneeTrackingRight}
                            options={[
                                { value: "Straight", label: "Straight" },
                                { value: "Inwards", label: "Inwards" },
                                { value: "Inwards A Lot", label: "Inwards A Lot" },
                                { value: "Outwards", label: "Outwards" },
                                { value: "Outwards A Lot", label: "Outwards A Lot" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Foot Ankling (Left)"}
                            name={`${prefix}.footAnklingLeft`}
                            required={false}
                            defaultValue={bike.footAnklingLeft}
                            options={[
                                { value: "Normal", label: "Normal" },
                                { value: "Toe Dipping", label: "Toe Dipping" },
                                { value: "Heel Dropping", label: "Heel Dropping" },
                                { value: "Excessive Movement", label: "Excessive Movement" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Foot Ankling (Right)"}
                            name={`${prefix}.footAnklingRight`}
                            required={false}
                            defaultValue={bike.footAnklingRight}
                            options={[
                                { value: "Normal", label: "Normal" },
                                { value: "Toe Dipping", label: "Toe Dipping" },
                                { value: "Heel Dropping", label: "Heel Dropping" },
                                { value: "Excessive Movement", label: "Excessive Movement" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Foot Direction (Left)"}
                            name={`${prefix}.footDirectionLeft`}
                            required={false}
                            defaultValue={bike.footDirectionLeft}
                            options={[
                                { value: "Straight", label: "Straight" },
                                { value: "Inwards", label: "Inwards" },
                                { value: "Inwards A Lot", label: "Inwards A Lot" },
                                { value: "Outwards", label: "Outwards" },
                                { value: "Outwards A Lot", label: "Outwards A Lot" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Foot Direction (Right)"}
                            name={`${prefix}.footDirectionRight`}
                            required={false}
                            defaultValue={bike.footDirectionRight}
                            options={[
                                { value: "Straight", label: "Straight" },
                                { value: "Inwards", label: "Inwards" },
                                { value: "Inwards A Lot", label: "Inwards A Lot" },
                                { value: "Outwards", label: "Outwards" },
                                { value: "Outwards A Lot", label: "Outwards A Lot" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Heel Tilt (Left)"}
                            name={`${prefix}.heelTiltLeft`}
                            required={false}
                            defaultValue={bike.heelTiltLeft}
                            options={[
                                { value: "Straight", label: "Straight" },
                                { value: "Inwards", label: "Inwards" },
                                { value: "Inwards A Lot", label: "Inwards A Lot" },
                                { value: "Outwards", label: "Outwards" },
                                { value: "Outwards A Lot", label: "Outwards A Lot" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                            menuPlacement={"top"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Heel Tilt (Right)"}
                            name={`${prefix}.heelTiltRight`}
                            required={false}
                            defaultValue={bike.heelTiltRight}
                            options={[
                                { value: "Straight", label: "Straight" },
                                { value: "Inwards", label: "Inwards" },
                                { value: "Inwards A Lot", label: "Inwards A Lot" },
                                { value: "Outwards", label: "Outwards" },
                                { value: "Outwards A Lot", label: "Outwards A Lot" },
                                { value: "Not Assessed", label: "Not Assessed" }
                            ]}
                            menuPlacement={"top"}
                        />

                    </Col>

                </Row>

            </Col>

        </Row>


    ), [props]);

}

export default SessionsEditTabsSizingPosture;
