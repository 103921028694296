import React from 'react';

import styles from "./ReportHeading.module.scss";

const ReportHeading: React.FC<ReportHeadingProps> = (props) => {

    let { className, text, children, ...rest } = props;

    let classes = [styles.heading];

    if (className && Array.isArray(className)) classes = [...classes, ...className];
    else if (typeof className === "string") classes.push(className);

    return (

        <div
            className={classes.join(" ")}
            {...rest}
        >

            <h2>{text || children}</h2>

        </div>

    )

}

interface ReportHeadingProps {
    [key: string]: any,
}

export default ReportHeading;