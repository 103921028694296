import React, { useMemo } from 'react';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import { Col, Row } from '@dex/bubl-dash';

const SessionsEditTabsSizingIndex: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;

    return useMemo(() => (

        <Row gutter={16} edge={true} className='row-top-edge'>

            <Col col={{ xs: 12 }}>

                <FieldUpload
                    form={form}
                    name={`${prefix}.indexPhoto`}
                    folder='admin-media'
                    label='Photo'
                    defaultValue={bike.indexPhoto}
                    multiple={false}
                    accept='image/*'
                    limit={1}
                />

            </Col>

        </Row>

    ), [form.hash, bike]);

}

export default SessionsEditTabsSizingIndex;
