import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Row, Col, } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Box, Button, FieldDate, FieldSelect, Form, Seperator, Space, useApp, useForm, useMountEffect } from '@dex/bubl-dash';
import CoachingTrainingsWeekTable from '../Trainings/CoachingTrainingsWeekTable';
import CoachingApi from '../CoachingApi';
import CoachingTrainingsAddWeek from '../Trainings/CoachingTrainingsAddWeek';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { get } from '@dex/bubl-helpers';

const CoachingTrainingsWeeklyTab: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    const scrollRef = useRef<HTMLDivElement>(null);

    const filters = useForm(get(data, 'training.filters', { period: "last-3-months", sortDate: "DESC" }));

    const [key, setKey] = useState(0);

    useMountEffect(() => {

        handleFilter(filters.values, false)

    })

    const trainingList = CoachingApi.format(data.id, "getTrainingList");

    const handleFilter = (values, preventScroll = false) => {

        trainingList.run({
            params: {
                ...values,
                coachingId: data.id,
            },
            background: true,
            onComplete: (response) => {

                if (preventScroll) return;

                setTimeout(() => {

                    if (!scrollRef.current) return;

                    scrollRef.current.scrollIntoView({ behavior: "smooth" });

                    setKey(key + 1);

                }, 100);

            }

        })

    };

    const handleRefresh = () => {

        setTab("stats")

        handleFilter(filters.values, true)

    };

    const [tab, setTab] = useState("stats");

    const tabs = [
        { label: "Stats", name: "stats" },
        { label: "Add Week", name: "addStats" },
    ]

    return useMemo(() => (

        <>

            {trainingList.loading}

            <Seperator
                // heading={tab}
                tabs={tabs}
                activeTab={tab}
                setTab={setTab}
            />

            <Space />

            {tab == "stats" &&
                <WeeklyStats
                    data={data}
                    form={form}
                    filters={filters}
                    handleSubmit={handleSubmit}
                    handleFilter={handleFilter}
                    handleClose={handleRefresh}
                    filteredWeek={trainingList.data || []}
                    loading={trainingList.loading}
                    saving={process.loading}
                    setTab={setTab}
                    scrollRef={scrollRef}
                />
            }

            {tab == "addStats" &&
                <CoachingTrainingsAddWeek
                    data={data}
                    form={form}
                    filters={filters}
                    mode={"create"}
                    handleClose={handleRefresh}
                />
            }

        </>

    ), [data, form, filters, handleSubmit, key, process.loading, tab, trainingList.data, trainingList.loading]);

}

const WeeklyStats: React.FC<any> = (props: any) => {

    const { form, filters, data, handleSubmit, handleFilter, handleClose, filteredWeek, loading, saving, scrollRef } = props;

    const handle = useFullScreenHandle();

    return (
        <>

            <Box
                className='coaching-training-filters'
                gutter={"auto"}
            >

                <Form
                    form={filters}
                    onSubmit={handleFilter}
                    autoDiscardChanges={true}
                    loading={loading}
                >

                    <Row edge gutter={8}>

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldSelect
                                form={filters}
                                name="sortDate"
                                defaultValue={filters.defaults.sortDate}
                                options={[
                                    { label: "Most Recent", value: "DESC" },
                                    { label: "Oldest", value: "ASC" },
                                ]}
                                label="Sort Dates By"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldSelect
                                button
                                form={filters}
                                name="period"
                                defaultValue={filters.defaults.period}
                                options={[
                                    { label: "Last Month", value: "last-month" },
                                    { label: "Last 3 Months", value: "last-3-months" },
                                    { label: "Last 6 Months", value: "last-6-months" },
                                    { label: "Last Year", value: "last-year" },
                                    { label: "All Time", value: "all-time" },
                                    { label: "Custom Date", value: "custom-date" },
                                ]}
                                label="Period"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        {filters.values.period == "custom-date" &&

                            <>

                                <Col col={{ xs: 12, md: 4 }}>

                                    <FieldDate
                                        form={filters}
                                        name='from'
                                        defaultValue={filters.defaults.from}
                                        label='From'
                                        wrapClass={'manual-input'}
                                        required
                                    />

                                </Col>

                                <Col col={{ xs: 12, md: 4 }}>

                                    <FieldDate
                                        form={filters}
                                        name='to'
                                        defaultValue={filters.defaults.to}
                                        label='To'
                                        wrapClass={'manual-input'}
                                        required
                                    />

                                </Col>

                            </>

                        }

                        <Col col={{ xs: 12, md: 4 }} justify='end'>
                            <Button
                                label={"Apply Filters"}
                                type={"light"}
                                onClick={filters.triggerSubmit}
                                htmlType={"button"}
                                loading={loading}
                            />
                            <Space height={8} />
                        </Col>

                    </Row>

                </Form>

            </Box>

            <Space />

            <div ref={scrollRef} />

            {filteredWeek &&
                <>

                    <FullScreen handle={handle}>
                        <CoachingTrainingsWeekTable
                            data={data}
                            form={form}
                            averages={filteredWeek.averages}
                            best={filteredWeek.best}
                            weeks={filteredWeek.weeks}
                            handleClose={handleClose}
                            {...props}
                            loading={false}
                            fullscreenHandle={handle}
                        />

                        <Space />
                    </FullScreen>

                </>
            }

        </>
    )
}

export default CoachingTrainingsWeeklyTab
