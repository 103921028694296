import React, { useMemo } from 'react';

import styles from "./TestSessionsReport.module.scss";

const TestSessionsReportMenu: React.FC<any> = (props: any) => {

    const { data, handleScrollTo, activePage, pages } = props;

    return useMemo(() => (
        <>

            <div className={styles.menu}>

                <div className="inner">

                    <div className={styles.topmenu}>
                    </div>

                    <div className={styles.sidemenu}>

                        {pages.map((item, index) => (

                            <a
                                key={index}
                                onClick={handleScrollTo.bind(null, item.ref)}
                                className={index === activePage ? "active" : "" + " " + (item.sep ? "sep" : "")}
                            >

                                {item.label}
                            </a>

                        ))}

                    </div>

                </div>

            </div>

        </>
    ), [data, activePage, handleScrollTo]);

}

export default TestSessionsReportMenu;
