import React from 'react';

import styles from "./ReportHeadingNameDate.module.scss";
import { Col, Row } from '@dex/bubl-dash';
import { date } from '@dex/bubl-helpers';

const ReportHeadingNameDate: React.FC<ReportHeadingNameDateProps> = (props) => {

    let { name, desc, reportDate } = props;

    return (

        <div className={styles.bar}>
            <Row edge gutter={8} >

                <Col col={{ xs: 24, md: 12 }}>

                    <p className={styles.name}>
                        {name}
                    </p>

                </Col>

                <Col col={{ xs: 24, md: 12 }} align='end'>
                    <p className={styles.dateLabel}>
                        <span className={styles.dateValue}>
                            {reportDate && date(reportDate, "D MMMM YYYY")}
                            {desc}
                        </span>
                    </p>
                </Col>

            </Row>
        </div>

    )

}

interface ReportHeadingNameDateProps {
    [key: string]: any,
}

export default ReportHeadingNameDate;