import CoachingPlansSettings from './CoachingPlansSettings';

export default function (app) {

    const settings = CoachingPlansSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

}