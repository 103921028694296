import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Row, Col, } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Box, Button, FieldAutocomplete, FieldDate, FieldSelect, Form, Seperator, Space, useForm, useMountEffect } from '@dex/bubl-dash';
import CoachingApi from '../CoachingApi';
import { titleCase } from '@dex/bubl-helpers';
import CoachingTrainingsWeekIntervalsTable from '../Trainings/CoachingTrainingsWeekIntervalsTable';
import CoachingTrainingsWeekEnduranceTable from '../Trainings/CoachingTrainingsWeekEnduranceTable';
import CoachingTrainingsAddEndurance from '../Trainings/CoachingTrainingsAddEndurance';
import CoachingTrainingsAddIntervals from '../Trainings/CoachingTrainingsAddIntervals';

const CoachingTrainingsWorkoutTab: React.FC<any> = (props: any) => {

    const { data } = props;

    const handleClose = useCallback((values) => {

        setTab(values)

    }, [props]);

    const [tab, setTab] = useState("Endurance");

    const tabs = [
        { label: "Endurance", name: "Endurance" },
        { label: "Intervals", name: "Intervals" },
        { label: "Add Endurance", name: "AddEndurance" },
        { label: "Add Intervals", name: "AddIntervals" },
    ]

    return useMemo(() => (

        <>

            <Seperator
                tabs={tabs}
                activeTab={tab}
                setTab={setTab}
            />

            <Space />

            {tab == "Endurance" &&
                <EnduranceTab
                    data={data}
                />
            }

            {tab == "Intervals" &&
                <IntervalsTab
                    data={data}
                />
            }

            {tab == "AddEndurance" &&
                <CoachingTrainingsAddEndurance
                    data={data}
                    mode={"create"}
                    handleClose={handleClose.bind(null, "Endurance")}
                />
            }

            {tab == "AddIntervals" &&
                <CoachingTrainingsAddIntervals
                    data={data}
                    mode={"create"}
                    handleClose={handleClose.bind(null, "Intervals")}
                />
            }

        </>

    ), [data, tab])
}

const EnduranceTab: React.FC<any> = (props: any) => {

    const { data } = props;

    const scrollRef = useRef<HTMLDivElement>(null);

    const filters = useForm({
        activity: "all",
        mode: "all",
        type: "training",
        period: "last-3-months",
        sortDate: "DESC",
    });

    const workouts = CoachingApi.format(data.id, "getEnduranceList");

    const handleFilter = useCallback((values) => {

        workouts.run({
            params: {
                ...values,
                coachingId: data.id,
            },
            onComplete: (response) => {

                setTimeout(() => {

                    if (!scrollRef.current) return;

                    scrollRef.current.scrollIntoView({ behavior: "smooth" });

                }, 100);

            }
        })

    }, [data.id]);

    const activity = filters.values?.activity;

    useMountEffect(() => {
        handleFilter(filters.values)
    });

    return (

        <>

            <Box
                className='coaching-training-filters'
                gutter={"auto"}
            >

                <Form
                    form={filters}
                    onSubmit={handleFilter}
                    autoDiscardChanges={true}
                >

                    <Row gutter={"8"} edge>

                        <Col col={{ xs: 12, lg: 4 }}>

                            <FieldSelect
                                form={filters}
                                name="activity"
                                defaultValue={filters.defaults?.activity}
                                options={[
                                    { label: "All", value: "all" },
                                    { label: "Swim", value: "swim" },
                                    { label: "Bike", value: "bike" },
                                    { label: "Run", value: "run" },
                                    { label: "Gym", value: "gym" },
                                    { label: "Other", value: "other" },
                                ]}
                                label="Activity"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12, lg: 4 }}>

                            <FieldSelect
                                form={filters}
                                name="mode"
                                key={activity}
                                defaultValue={filters.defaults?.mode}
                                options={[
                                    { label: "All", value: "all" },
                                    ...(activity == "all" || activity == "bike" || activity == "run"
                                        ? [
                                            { label: "Road", value: "road" },
                                            { label: "Off-Road", value: "off-road" },
                                            { label: "Trainer", value: "trainer" },
                                        ]
                                        : []),
                                    ...(activity == "all" || activity == "run"
                                        ? [{ label: "Treadmill", value: "treadmill" }]
                                        : []),
                                    ...(activity == "all" || activity == "swim"
                                        ? [
                                            { label: "Pool", value: "pool" },
                                            { label: "Pool - 25m", value: "pool-25m" },
                                            { label: "Pool - 50m", value: "pool-50m" },
                                            { label: "Open Water - Fresh", value: "open water-fresh" },
                                            { label: "Open Water - Salt", value: "open water-salt" },
                                        ]
                                        : []),
                                    { label: "Other", value: "other" },
                                ]}
                                label="Mode"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12, lg: 4 }}>

                            <FieldSelect
                                form={filters}
                                name="type"
                                key={activity}
                                defaultValue={filters.defaults?.type}
                                options={[
                                    { label: "All", value: "all" },
                                    { label: "Training", value: "training" },
                                    { label: "Race Simulation", value: "race-simulation" },
                                    { label: "Event", value: "event" },
                                    { label: "Test", value: "test" },
                                    { label: "Other", value: "other" },
                                ]}
                                label="Type"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12, lg: 4 }}>

                            <FieldSelect
                                form={filters}
                                name='duration'
                                defaultValue={filters.defaults?.duration}
                                label='Duration'
                                wrapClass={'manual-input'}
                                options={[
                                    { label: "<30 min", value: "<30 min" },
                                    { label: "30-60 min", value: "30-60 min" },
                                    { label: "1-2 hrs", value: "1-2 hrs" },
                                    { label: "2-4 hrs", value: "2-4 hrs" },
                                    { label: "4-6 hrs", value: "4-6 hrs" },
                                    { label: "6-10 hrs", value: "6-10 hrs" },
                                    { label: "10 hrs+", value: "10 hrs+" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 12, lg: 4 }}>

                            <FieldSelect
                                form={filters}
                                name='distance'
                                defaultValue={filters.defaults?.distance}
                                label={`Distance [${titleCase(activity)}]`}
                                wrapClass={'manual-input'}
                                options={[
                                    ...(activity == "all" || activity == "bike"
                                        ? [
                                            { label: "<50 KM", value: "<50 KM" },
                                            { label: "50-100 KM", value: "50-100 KM" },
                                            { label: "100-150 KM", value: "100-150 KM" },
                                            { label: "150-200 KM", value: "150-200 KM" },
                                            { label: ">200 KM", value: ">200 KM" },
                                        ]
                                        : []),
                                    ...(activity == "all" || activity == "run"
                                        ? [
                                            { label: "<5 KM", value: "<5 M" },
                                            { label: "5-10 KM", value: "5-10 KM" },
                                            { label: "21-42 KM", value: "21-42 KM" },
                                            { label: ">42 KM", value: ">42 KM" },
                                        ]
                                        : []),
                                    ...(activity == "all" || activity == "swim"
                                        ? [
                                            { label: "0.5-1 KM", value: "0.5-1 KM" },
                                            { label: "1-2 KM", value: "1-2 KM" },
                                            { label: "2-4 KM", value: "2-4 KM" },
                                            { label: ">4 KM", value: ">4 KM" },
                                        ]
                                        : []),
                                ]}
                            />

                        </Col>

                    </Row>
                    <Row gutter={"8"} edge>

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldSelect
                                form={filters}
                                name="sortDate"
                                defaultValue={filters.defaults?.sortDate}
                                options={[
                                    { label: "Most Recent", value: "DESC" },
                                    { label: "Oldest", value: "ASC" },
                                ]}
                                label="Sort Dates By"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldSelect
                                button
                                form={filters}
                                name="period"
                                defaultValue={filters.defaults?.period}
                                options={[
                                    { label: "Last Month", value: "last-month" },
                                    { label: "Last 3 Months", value: "last-3-months" },
                                    { label: "Last 6 Months", value: "last-6-months" },
                                    { label: "Last Year", value: "last-year" },
                                    { label: "Custom Date", value: "custom-date" },
                                ]}
                                label="Period"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        {filters.values.period == "custom-date" &&

                            <>
                                <Col col={{ xs: 12, lg: 4 }}>

                                    <FieldDate
                                        form={filters}
                                        name='from'
                                        defaultValue={filters.defaults?.from}
                                        label='From'
                                        wrapClass={'manual-input'}
                                        required
                                    />

                                </Col>

                                <Col col={{ xs: 12, lg: 4 }}>

                                    <FieldDate
                                        form={filters}
                                        name='to'
                                        defaultValue={filters.defaults?.to}
                                        label='To'
                                        wrapClass={'manual-input'}

                                    />

                                </Col>

                            </>

                        }

                        <Col col={{ xs: 12, lg: 4 }} justify='center'>

                            <Space />

                            <Button
                                label="Apply Filters"
                                type={"light"}
                                onClick={filters.triggerSubmit}
                                loading={workouts.loading}
                            />

                        </Col>

                    </Row>

                </Form>

            </Box>

            <div ref={scrollRef} />

            {workouts.data && !workouts.loading &&
                <>
                    <Space />
                    <CoachingTrainingsWeekEnduranceTable
                        data={data}
                        weeks={workouts.data}
                        filters={filters}
                    />
                </>
            }

        </>

    )

}

const IntervalsTab: React.FC<any> = (props: any) => {

    const { data } = props;

    const scrollRef = useRef<HTMLDivElement>(null);

    const filters = useForm({
        activity: "bike",
        mode: 'all',
        type: 'all',
        period: "last-3-months",
        sortDate: "DESC"
    });

    const workouts = CoachingApi.format(data.id, "getIntervalsList");

    const handleFilter = useCallback((values) => {

        workouts.run({
            params: {
                ...values,
                coachingId: data.id,
            },
            onComplete: (response) => {

                setTimeout(() => {

                    if (!scrollRef.current) return;

                    scrollRef.current.scrollIntoView({ behavior: "smooth" });

                }, 100);

            }
        })

    }, [data.id]);

    const activity = filters.values?.activity;

    useMountEffect(() => {
        handleFilter(filters.values)
    });

    return (

        <>

            <Box
                className='coaching-training-filters'
                gutter={"auto"}
            >

                <Form
                    form={filters}
                    onSubmit={handleFilter}
                    autoDiscardChanges={true}
                    loading={workouts.loading}
                >

                    <Row gutter={"8"} edge>

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldSelect
                                form={filters}
                                name="activity"
                                defaultValue={filters.defaults?.activity}
                                options={[
                                    { label: "All", value: "all" },
                                    { label: "Swim", value: "swim" },
                                    { label: "Bike", value: "bike" },
                                    { label: "Run", value: "run" },
                                ]}
                                label="Activity"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldSelect
                                form={filters}
                                name="mode"
                                defaultValue={filters.defaults?.mode}
                                key={activity}
                                options={[
                                    { label: "All", value: "all" },
                                    ...(activity == "all" || activity == "bike" || activity == "run"
                                        ? [
                                            { label: "Road", value: "road" },
                                            { label: "Off-Road", value: "off-road" },
                                            { label: "Trainer", value: "trainer" },
                                        ]
                                        : []),
                                    ...(activity == "all" || activity == "run"
                                        ? [{ label: "Treadmill", value: "treadmill" }]
                                        : []),
                                    ...(activity == "all" || activity == "swim"
                                        ? [
                                            { label: "Pool", value: "pool" },
                                            { label: "Pool - 25m", value: "pool-25m" },
                                            { label: "Pool - 50m", value: "pool-50m" },
                                            { label: "Open Water - Fresh", value: "open water-fresh" },
                                            { label: "Open Water - Salt", value: "open water-salt" },
                                        ]
                                        : []),
                                    { label: "Other", value: "other" },
                                ].filter(Boolean)}
                                label="Mode"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldSelect
                                form={filters}
                                name="type"
                                key={activity}
                                defaultValue={filters.defaults?.type}
                                options={[
                                    { label: "All", value: "all" },
                                    { label: "Training", value: "training" },
                                    { label: "Race Simulation", value: "race-simulation" },
                                    { label: "Event", value: "event" },
                                    { label: "Test", value: "test" },
                                    { label: "Other", value: "other" },
                                ]}
                                label="Type"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        {/* </Row>
                    <Row gutter={"8"} edge> */}

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldAutocomplete
                                form={filters}
                                name="zone"
                                defaultValue={filters.defaults?.zone}
                                label='Zone'
                                wrapClass={"manual-input"}
                                group='zoneList'
                                preload
                                disableCreate
                                options={[
                                    { value: "Endurance", label: "Endurance" },
                                    { value: "Tempo", label: "Tempo" },
                                    { value: "Threshold", label: "Threshold" },
                                    { value: "VO2max", label: "VO2max" },
                                    { value: "Anaerobic", label: "Anaerobic" },
                                    { value: "Mixed", label: "Mixed" },
                                    { value: "Other", label: "Other" }
                                ]}
                                sort={false}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldAutocomplete
                                form={filters}
                                name="intervals"
                                defaultValue={filters.defaults?.intervals}
                                label='Intervals'
                                wrapClass={"manual-input"}
                                group='intervalsList'
                                preload
                                disableCreate
                                options={[
                                    { value: "Tempo-HR", label: "Tempo-HR" },
                                    { value: "Repeats", label: "Repeats" },
                                    { value: "Pyramids", label: "Pyramids" },
                                    { value: "Ladders", label: "Ladders" },
                                    { value: "Race Pace", label: "Race Pace" },
                                    { value: "Triple 3's", label: "Triple 3's" },
                                    { value: "Devil 6's", label: "Devil 6's" },
                                    { value: "Breakouts on Avg kph", label: "Breakouts on Avg kph" },
                                    { value: "1 min On/Offs", label: "1 min On/Offs" },
                                    { value: "30s On/Offs", label: "30s On/Offs" },
                                    { value: "40s:20s On/Offs", label: "40s:20s On/Offs" },
                                    { value: "Tempo-Sprint", label: "Tempo-Sprint" },
                                    { value: "Strength", label: "Strength" },
                                    { value: "Hills", label: "Hills" },
                                    { value: "Anaerobic Ladders", label: "Anaerobic Ladders" },
                                    { value: "Sprints - Short <15s)", label: "Sprints - Short <15s)" },
                                    { value: "Sprints - Long (15-30s)", label: "Sprints - Long (15-30s)" },
                                    { value: "Recovery", label: "Recovery" },
                                ]}
                                sort={false}
                            />

                        </Col>

                    </Row>
                    <Row gutter={"8"} edge>

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldSelect
                                form={filters}
                                name='duration'
                                defaultValue={filters.defaults?.duration}
                                label='Duration'
                                wrapClass={'manual-input'}
                                options={[
                                    { label: "<30 sec", value: "<30 sec" },
                                    { label: "30-60 sec", value: "30-60 sec" },
                                    { label: "1-3 min", value: "1-3 min" },
                                    { label: "3-5 min", value: "3-5 min" },
                                    { label: "5-10 min", value: "5-10 min" },
                                    { label: "10-20 min", value: "10-20 min" },
                                    { label: ">20 min", value: ">20 min" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldSelect
                                form={filters}
                                name='distance'
                                defaultValue={filters.defaults?.distance}
                                label={`Distance [${titleCase(activity)}]`}
                                wrapClass={'manual-input'}
                                allowCustomOption
                                options={[
                                    ...(activity === "all" || activity === "bike"
                                        ? [
                                            { label: "<1 km", value: "<1 km" },
                                            { label: "1-3 km", value: "1-3 km" },
                                            { label: "3-5 km", value: "3-5 km" },
                                            { label: "5-10 km", value: "5-10 km" },
                                            { label: ">10 km", value: ">10 km" },
                                        ]
                                        : []),
                                    ...(activity === "all" || activity === "run"
                                        ? [
                                            { label: "<400 m", value: "<400 m" },
                                            { label: "400-1000 m", value: "400-1000 m" },
                                            { label: "1-3 km", value: "1-3 km" },
                                            { label: "3-5 km", value: "3-5 km" },
                                            { label: ">5 km", value: ">5 km" },
                                        ]
                                        : []),
                                    ...(activity === "all" || activity === "swim"
                                        ? [
                                            { label: "25 m", value: "25 m" },
                                            { label: "50 m", value: "50 m" },
                                            { label: "100 m", value: "100 m" },
                                            { label: "200 m", value: "200 m" },
                                            { label: "300 m", value: "300 m" },
                                            { label: "400 m", value: "400 m" },
                                            { label: "500 m", value: "500 m" },
                                            { label: "500-1000 m", value: "500-1000 m" },
                                            { label: "1000-2000 m", value: "1000-2000 m" },
                                            { label: ">2000 m", value: ">2000 m" },
                                        ]
                                        : []),
                                ].filter(Boolean)}
                            />

                        </Col>

                        <Col col={{ xs: 12, lg: 4 }}>

                            <FieldSelect
                                form={filters}
                                name="sortDate"
                                defaultValue={filters.defaults?.sortDate}
                                options={[
                                    { label: "Most Recent", value: "DESC" },
                                    { label: "Oldest", value: "ASC" },
                                ]}
                                label="Sort Dates By"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 4 }}>

                            <FieldSelect
                                button
                                form={filters}
                                name="period"
                                defaultValue={filters.defaults?.period}
                                options={[
                                    { label: "Last Month", value: "last-month" },
                                    { label: "Last 3 Months", value: "last-3-months" },
                                    { label: "Last 6 Months", value: "last-6-months" },
                                    { label: "Last Year", value: "last-year" },
                                    { label: "Custom Date", value: "custom-date" },
                                ]}
                                label="Period"
                                required
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        {filters.values?.period == "custom-date" &&

                            <>

                                <Col col={{ xs: 12, md: 4 }}>

                                    <FieldDate
                                        form={filters}
                                        name='from'
                                        defaultValue={filters.defaults?.from}
                                        label='From'
                                        wrapClass={'manual-input'}
                                        required
                                    />

                                </Col>

                                <Col col={{ xs: 12, md: 4 }}>

                                    <FieldDate
                                        form={filters}
                                        name='to'
                                        defaultValue={filters.defaults?.to}
                                        label='To'
                                        wrapClass={'manual-input'}
                                        required
                                    />

                                </Col>

                            </>

                        }

                        <Col col={{ xs: 24 }}>

                            <Button
                                label="Apply Filters"
                                type={"light"}
                                onClick={filters.triggerSubmit}
                                loading={workouts.loading}
                            />

                        </Col>

                    </Row>

                </Form>

            </Box>

            <div ref={scrollRef} />

            {workouts.data && !workouts.loading &&
                <>
                    <Space />

                    <CoachingTrainingsWeekIntervalsTable
                        data={data}
                        filters={filters}
                        intervals={workouts.data}
                    />
                </>
            }



        </>
    )
}

export default CoachingTrainingsWorkoutTab
