import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldRadio, FieldText, FieldTextArea, InputTable, InputTableCell, InputTableRow, Line, Seperator, Space } from '@dex/bubl-dash';
import TrainingCard from '../../../elements/TrainingCard/TrainingCard';
import { get } from '@dex/bubl-helpers';

const TrainingZonesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const [addGroup, setAddGroup] = useState(false);

    const columns = [
        {
            key: "zone",
            label: "Zone",
        },
        {
            key: "workout",
            label: "Workout",
        },
        {
            key: "empty",
            label: "",
        }
    ]

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Training Zone Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <Row >

                            <Col col={{ xs: 24, sm: 8 }}>

                                <FieldText
                                    form={form}
                                    name="title"
                                    label="Title"
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, sm: 8 }}>

                        <FieldRadio
                            button
                            form={form}
                            name="demographic"
                            label="Demographic"
                            required={true}
                            defaultValue={form.defaults.demographic}
                            options={[
                                { label: "Recreational", value: "untrained" },
                                { label: "Competitive", value: "trained" },
                                { label: "Pro", value: "elite" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 8 }}>

                        <FieldRadio
                            button
                            form={form}
                            name="activity"
                            label="Activity"
                            defaultValue={form.defaults.activity}
                            required={true}
                            options={[
                                { value: "Running", label: "Running" },
                                { value: "Cycling", label: "Cycling" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 24, md: 24 }}>

                        <InputTable
                            columns={columns}
                        >

                            {[0, 1, 2, 3, 4, 5].map((index) => {

                                return (

                                    <InputTableRow >

                                        <InputTableCell style={{ width: 100, paddingLeft: 12 }}>
                                            <><strong>Zone {index + 1}</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 200 }}>

                                            <FieldTextArea
                                                form={form}
                                                name={`workouts[${index}]`}
                                                defaultValue={get(form.defaults, `workouts[${index}]`)}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 300 }}>
                                            <></>
                                        </InputTableCell>

                                    </InputTableRow>

                                )

                            })}

                        </InputTable>


                    </Col>

                </Row>

                <Seperator heading={"Preview"} top={'auto'} bottom={'auto'} />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, sm: 24 }}>

                        <FieldRadio
                            button
                            form={form}
                            name="preview"
                            label="Zone"
                            defaultValue={"1"}
                            options={[
                                { value: "1", label: "Zone 1" },
                                { value: "2", label: "Zone 2" },
                                { value: "3", label: "Zone 3" },
                                { value: "4", label: "Zone 4" },
                                { value: "5", label: "Zone 5" },
                                { value: "6", label: "Zone 6" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24, lg: 24 }}>

                        <TrainingCard
                            zone={form.values.preview || "1"}
                            data={form.values}
                        />

                        <Space />

                    </Col>

                </Row>

                <Line />

                <Space />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default TrainingZonesCreateEditForm;
