import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Space } from '@dex/bubl-dash';
import { parseNum, titleCase } from '@dex/bubl-helpers';

import styles from "./TestSessionsReport.module.scss";
import CoachingReportPage from './CoachingReportPage';
import TestSessionsReportMenu from '../../TestSessions/Report/TestSessionsReportMenu';
import CoachingReportCover from './CoachingReportCover';
import CoachingReportAthlete from './CoachingReportAthlete';
import CoachingReportTrainingWeek from './CoachingReportTrainingWeek';
import CoachingReportLongTermPlan from './CoachingReportLongTermPlan';
import CoachingReportTracking from './CoachingReportTracking';
import CoachingReportComments from './CoachingReportComments';
import CoachingReportZones from './CoachingReportZones';
import CoachingReportEvents from './CoachingReportEvents';
import CoachingReportWorkouts from './CoachingReportWorkouts';

const CoachingReportPremium: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const [activePage, setActivePage] = useState(0);

    const observer: any = useRef();

    const pages: any = [
        {
            key: "cover",
            label: "Cover",
            heading: false,
            el: CoachingReportCover,
            ref: useRef()
        },
        {
            key: "athlete-profile",
            label: "Athlete Details",
            heading: "Athlete Details",
            el: CoachingReportAthlete,
            ref: useRef()
        },
        {
            key: "training-week",
            label: "Your Training This Week",
            heading: "Your Training This Week",
            el: CoachingReportTrainingWeek,
            ref: useRef()
        },
        {
            key: "long-term-plan",
            label: "Long Term Plan",
            heading: "Long Term Plan",
            el: CoachingReportLongTermPlan,
            ref: useRef()
        },
        {
            key: "tracking-training",
            label: "Tracking Training",
            heading: "Tracking Your Training Over Time",
            el: CoachingReportTracking,
            ref: useRef()
        },
        {
            key: "sport-specific",
            label: "Workouts",
            heading: "Workouts",
            el: CoachingReportWorkouts,
            ref: useRef()
        },
        {
            key: "events",
            label: "Events",
            heading: "Events",
            el: CoachingReportEvents,
            ref: useRef()
        },
        {
            key: "zones",
            label: "Zones",
            heading: "Zones",
            el: CoachingReportZones,
            ref: useRef()
        },
        {
            key: "comment",
            label: "Coach's Comments",
            heading: "Coach's Comments",
            el: CoachingReportComments,
            ref: useRef()
        },
    ].filter((page) => {

        if (!data.reportPages || !data.reportPages.length) return true;

        return data.reportPages.includes(page.key) ? true : false;

    });

    useEffect(() => {

        const callback = (entries, observer) => {

            let inViewTarget: any = null;
            let inViewRatio = 0;

            entries.forEach((entry, index) => {

                if (entry.isIntersecting && entry.intersectionRatio > inViewRatio) {

                    inViewRatio = entry.intersectionRatio;
                    inViewTarget = entry.target;

                }

            })

            if (inViewTarget) setActivePage(parseNum(inViewTarget.id));

        };

        const observer = new IntersectionObserver(callback, {
            threshold: .4,
        });

        pages.map((page) => {

            observer.observe(page.ref.current);

        });

        () => {

            pages.map((page) => {

                observer.unobserve(page.ref.current);

            });

            observer.disconnect();

        }

    }, []);

    const handleScrollTo = useCallback((ref, event) => {

        if (event && event.preventDefault) event.preventDefault();

        ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

    }, []);

    return useMemo(() => (

        <>

            <TestSessionsReportMenu
                data={data}
                activePage={activePage}
                pages={pages}
                handleScrollTo={handleScrollTo}
                form={form}
            />

            {pages.map((page, index) => (
                <React.Fragment key={index}>

                    {index >= 0 &&
                        <Space />
                    }

                    {page.key === "sport-specific" && data?.reportSports.map((sport, sportIndex) => (

                        <React.Fragment key={sportIndex}>

                            {sportIndex >= 0 &&
                                <Space />
                            }

                            <CoachingReportPage
                                key={sportIndex}
                                heading={"Workouts - " + titleCase(sport)}
                                index={index}
                                observer={observer}
                                forwardedRef={page.ref}
                                setActivePage={setActivePage}
                                form={form}
                                name={data.athleteName}
                                date={data?.week}
                            >

                                {page.el &&
                                    <page.el
                                        data={data}
                                        form={form}
                                        type={sport}
                                    />
                                }

                            </CoachingReportPage>

                        </React.Fragment>

                    ))}

                    {page.key !== "sport-specific" && (
                        <CoachingReportPage
                            heading={page.heading}
                            icon={page.icon}
                            index={index}
                            observer={observer}
                            forwardedRef={page.ref}
                            setActivePage={setActivePage}
                            form={form}
                            name={data.athleteName}
                            date={data?.week}
                        >

                            {page.el &&
                                <page.el
                                    data={data}
                                    form={form}
                                />
                            }

                        </CoachingReportPage>
                    )}

                </React.Fragment>
            ))}

        </>

    ), [data, activePage]);

}

export default CoachingReportPremium;