import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'Coachings';

const CoachingApi: baseApiReturn = baseApi(endpoint);

CoachingApi.getReport = (id: string, options?: options): response => {

    return apiGet({
        url: endpoint + "/public/" + id + "/getReport",
        params: {},
        ...options
    });

}

CoachingApi.getReportData = (id: string, options?: options): response => {

    return apiGet({
        url: endpoint + "/public/" + id + "/getReportData",
        params: {},
        ...options
    });

}

export default CoachingApi;