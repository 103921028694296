import React from 'react';
import { ComposedChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop, titleCase } from '@dex/bubl-helpers';
import { nearestMaxHundered } from '../../functions/sharedChart';

const ChartCoachingFitness: React.FC<ChartCoachingFitnessProps> = (props) => {

    let { filters, data } = props;

    const fitness = filters?.trackingFitness;
    const metric = filters?.trackingFitnessReadiness || [];

    const getKey = (key) => {

        if (!key) return null;

        const items = { "Fatigue": "fatigueScore", "Fitness": "fitnessScore", "Form": "formScore", "Body Stress": "bodyStress" };

        return items[key];

    };

    const Axis = new ChartAxis();

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    Axis.x.label = "Week";
    Axis.x.format = undefined;
    Axis.x.key = "date";

    Axis.x.props = {
        interval: 0,
        angle: -45,
        textAnchor: "end",
    }

    if (fitness == "Training Stimulus") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 0,
            key: "totalStimulusAverage",
            axisLabel: fitness,
            label: fitness,
            props: {
                yAxisId: fitness,
                domain: [0, nearestMaxHundered],
            }
        });

    }

    loop(metric, (_metric, i) => {

        Axis.y.push({
            axis: true,
            line: true,
            pos: _metric != "Form" ? "left" : "right",
            index: 0,
            key: getKey(_metric),
            axisLabel: _metric,
            label: _metric,
            props: {
                yAxisId: _metric,
                domain: [0, nearestMaxHundered],
                stroke: ChartProps.trackingColor(_metric),
                label: { fill: ChartProps.trackingColor(_metric) }
            },
        });
    })

    Axis.legend = false;

    return (

        <Chart>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

            </ComposedChart>

        </Chart>


    )

}

interface ChartCoachingFitnessProps {
    [key: string]: any,
}

export default ChartCoachingFitness;