import React from 'react';
import { HiArrowNarrowUp, HiArrowNarrowDown, HiCheck } from 'react-icons/hi';
import { clone, get, loop, parseNum } from "@dex/bubl-helpers";
import { useMemo } from "react";
import { changeDemographic, percentageOf } from "./sharedTests";
import styles from './raceReadyTest.module.scss';
import { FieldHidden } from '@dex/bubl-dash';

export const mergeEffort = (value) => {
    const { effortData, efforts } = useMemo(() => {

        const data = clone(value);

        const effortData: any = [];
        const efforts: any = [];

        loop(data, (stages, effortIndex) => {

            efforts.push(effortIndex + 1);

            loop(stages, (stage, stageIndex) => {

                if (!effortData[stageIndex]) effortData[stageIndex] = stage;

                loop(stage, (value, key) => {

                    if (key[0] === "_") return;

                    effortData[stageIndex][key + "_" + (effortIndex + 1)] = value;

                });

            });

        });

        return { effortData: effortData, efforts: efforts };

    }, []);

    return { effortData, efforts }
};

export const getRange = (metric, targets, gender, activity, data) => {

    if (!targets.data) return {};

    if (!targets.data[metric]) return {};

    const target = targets.data[metric];

    gender = gender || "male";
    const act = (activity === "Cycling") ? "bike" : "run";

    const level = changeDemographic(data.manual?.level || "all").toLowerCase();

    const range = get(target, `steps.${gender}.${act}.${level}`);

    return { range, target };

};

export const withinRange = (metric, value, targets, gender, activity, data) => {

    const { range, target } = getRange(metric, targets, gender, activity, data);

    if (!range) return "";

    value = parseNum(value);

    const ArrowUp = <HiArrowNarrowUp className={styles.arrow} />;
    const ArrowDown = <HiArrowNarrowDown className={styles.arrow} />;
    const Check = <HiCheck className={styles.check} />;

    if (value < range.min) return ArrowDown;
    if (value > range.max) return ArrowUp;
    if (value <= range.max && value >= range.min) return Check;

    return "";

};

export const getRangeOutput = (metric, targets, gender, activity, data) => {

    const { range, target } = getRange(metric, targets, gender, activity, data);

    if (!range) return;

    let output = "";

    if (range.min) output += range.min + target.suffix + " - ";
    else output += "< ";

    output += range.max + target.suffix;

    return output;

};

export const withinRangeCurrent = (metric, currentKey, value, index, form, targets, session, activity, data) => {

    const currentValue = get(form.values, `result.` + currentKey);

    if (!currentValue) return;

    value = percentageOf(value, currentValue);

    const label = currentKey.indexOf("vo2max") === 0 ? "Max" : "FTP";

    const rangeResult = withinRange(metric, value, targets, session, activity, data);

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

            <FieldHidden
                form={form}
                name={`result.${currentKey}Current[${index}]`}
                defaultValue={value}
                hidden
            />
            {value} % of {label} {rangeResult}

        </div>
    );

}