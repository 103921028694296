import FieldSessionsIndex from './FieldSessionsIndex';
import FieldSessionsCreate from './FieldSessionsCreate';
import FieldSessionsSettings from './FieldSessionsSettings';
import FieldSessionsSingle from './FieldSessionsSingle';

export default function (app) {

    const settings = FieldSessionsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 10,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }
        ],
    });

    FieldSessionsIndex(app, settings);

    FieldSessionsCreate(app, settings);

    FieldSessionsSingle(app, settings);

}