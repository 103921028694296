import React from 'react';
import { fixedNum, slug, titleCase } from '@dex/bubl-helpers';
import { Line } from '@dex/bubl-dash';
import { GoDash, GoArrowUp, GoArrowDown } from "react-icons/go";
import { arrowPosition } from '../RatingScale/RatingScaleElements';

import styles from "./FieldRatingsGridItem.module.scss";

const FieldRatingsGridItem: React.FC<FieldRatingsGridItemProps> = (props) => {

    const { title, category, charts } = props;

    return (

        <div className={styles.container}>

            <p className={styles.title}>{titleCase(title)}</p>

            <Line
                top="small"
                bottom="small"
            />

            <div className={styles.charts}>

                {charts.map((chart, index) => (
                    <React.Fragment key={index}>
                        {chart.scale &&
                            <FieldRatingsGridItemChart
                                category={category}
                                {...chart}
                            />
                        }
                    </React.Fragment>
                ))}

            </div>

        </div>
    )

}

const FieldRatingsGridItemChart: React.FC<FieldRatingsGridItemProps> = (props) => {

    const { value, label, icon, subtitle, scale, category, name, bestScore, decimalPlace } = props;

    const minValue = scale.min;
    const maxValue = scale.max;
    const avgValue = scale.mean;
    const suffix = scale.units;

    let height = arrowPosition(value, minValue, maxValue);

    return (
        <div className={[styles.chart, "cat-color-" + slug(category)].join(" ")} title={name}>

            <p className={styles.subtitle}>{titleCase(subtitle)}</p>

            <div className={styles.bar}>

                <div
                    className={styles.dot}
                    title={bestScore == "highest" ? "Higher is better" : "Lower is better"}
                    style={{
                        top: bestScore == "highest" ? "0%" : "100%",
                    }}
                />

                <div className={styles.column} >

                    <div
                        className={styles.progress}
                        style={{
                            height: `${height}%`,
                        }}
                    />

                    {avgValue &&
                        <>
                            <div className={styles.avgLine} />

                            <div className={styles.avgLabel}>
                                {fixedNum(avgValue, decimalPlace)}{suffix}
                            </div>
                        </>
                    }

                    <p className={styles.value}>
                        {fixedNum(value, decimalPlace)}<span>{suffix ? suffix : ""}</span>
                    </p>

                </div>

            </div>

            {label &&
                <p
                    className={styles.label}
                >
                    {label}
                </p>
            }

            {icon &&
                <p className={styles.icon}>
                    {icon === "dash" && <GoDash className={styles.iconDash} />}
                    {icon === "down" && <GoArrowDown className={styles.iconDown} />}
                    {icon === "up" && <GoArrowUp className={styles.iconUp} />}
                </p>
            }

        </div>
    )

}

interface FieldRatingsGridItemProps {
    [key: string]: any,
}

export default FieldRatingsGridItem;