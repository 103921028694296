import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, DataTable, Modal, useApp, useForm } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';
import CoachingsZoneCreateEdit from './CoachingsZoneCreateEdit';
import CoachingTrainingZonesApi from '../../CoachingTrainingZones/CoachingTrainingZonesApi';
import CoachingApi from '../CoachingApi';

const CoachingZonesTable: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm();

    const app = useApp();

    const fetch = CoachingTrainingZonesApi.get();

    const activate = CoachingApi.update(data.id, 'activeZone');

    const [key, setKey] = useState(0);

    const [activeId, setActiveId] = useState(data.activeZoneId);

    const handleActiveZone = useCallback((activeId) => {

        activate.run({
            data: { activeZoneId: activeId },
            onComplete: (data) => {
                setActiveId(activeId);
            },
        });

    }, []);

    useEffect(() => {

        const query: any = { coachingId: data.id };

        fetch.run({
            params: {
                where: query,
                order: ['created.date DESC'],
                limit: 1000,
            }
        });

    }, [key])

    const columns = [
        {
            label: "",
            key: "id",
            render: (value, row) => {
                return (
                    <>
                        {row && <CoachingZonesEditForm data={{ ...row, athleteId: data.athleteId }} setKey={setKey} />}
                    </>
                );
            }
        },
        {
            label: "Description",
            key: "description",
        },
        {
            label: "Active",
            key: "active",
            render: (value, row) => {
                return (
                    <>
                        {activeId === row.id &&
                            <Button
                                label={"Active"}
                                size='small'
                                type='primary'
                                disabled
                            />
                        }
                        {activeId !== row.id &&
                            <Button
                                label={"Set Active"}
                                onClick={handleActiveZone.bind(null, row.id)}
                                loading={activate.loading}
                                size='small'
                                type='light'
                            />
                        }
                    </>
                );
            }
        },
        {
            label: "Starting On",
            key: "zoneStarting",
            format: "date"
        }
    ];

    return useMemo(() => (

        <>

            <DataTable
                data={get(fetch, 'data.data', [])}
                count={get(fetch, 'data.count', null)}
                columns={columns}
                loading={fetch.loading}
            />

        </>

    ), [fetch.loading, form.hash, activeId, activate.loading]);

}

const CoachingZonesEditForm: React.FC<any> = (props: any) => {

    const { data, setKey } = props;

    const [showFormUpdate, setShowFormUpdate] = useState(false);

    const form = useForm(data)

    const process = CoachingTrainingZonesApi.update(data.id, "update");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            onComplete: (data) => {

                app.alert('Zone Saved', 'success');

                setShowFormUpdate(false);

                setKey(k => k + 1)

            }
        });

    }, []);

    const app = useApp();

    return (

        <>

            <a href="#" onClick={() => setShowFormUpdate(true)}>Edit Zone</a >

            {showFormUpdate &&

                <Modal
                    show={showFormUpdate}
                    onClose={setShowFormUpdate.bind(null, false)}
                    width={1920}
                >

                    <CoachingsZoneCreateEdit
                        form={form}
                        process={process}
                        handleSubmit={handleSubmit}
                        data={data}
                        mode={"Edit"}
                    />

                </Modal>
            }

        </>
    )

}

export default CoachingZonesTable;
