import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import TestSessionsEditTabsPanel from './TestSessionsEditTabsPanel';
import { FieldHidden, FieldNumber, FieldRadio, FieldSelect, FieldTextArea, InputTable, InputTableCell, InputTableRow, Seperator, Space, useUpdateEffect } from '@dex/bubl-dash';
import { fixedNum, get, hashCode } from '@dex/bubl-helpers';
import { getBmi, getBodyDensity, getBodyFatPercentage, getCorrectedGirth, getKcalorieValue, getLeanBodyWeight, getLowerBodyMuscleMass, getMuscleMass, getSos4, getSos7, getUpperBodyMuscleMass } from '../../../functions/sharedTests';
import { getAverage, getSum } from '../../../functions/sharedGeneral';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import moment from 'moment';
import { MetricRanking } from '../../../elements/RatingScale/RatingScaleElements';

const TestSessionsEditTabsBioData: React.FC<any> = ( props: any ) => {

    const { form, data } = props;

    return useMemo( () => {

        const generalColumns = [
            {
                key: "props",
                label: "General",
                style: { width: 120 }
            },
            {
                key: "generalReading",
                label: "Reading",
                style: { width: 120 }
            },
            {}
        ]

        const girthsColumns = [
            {
                key: "props",
                label: "Girths",
                style: { width: 120 }
            },
            {
                key: "actualReading",
                label: "Actual",
                style: { width: 120 }
            },
            {
                key: "correctedReading",
                label: "Corrected",
                style: { width: 120 }
            },
        ]

        const computedColumns = [
            {
                key: "props",
                label: "Computed",
                style: { width: 120 }
            },
            {
                key: "actualReading",
                label: "",
                style: { width: 120 }
            },
        ]

        const glycogenColumns = [
            {
                key: "props",
                label: "Glycogen",
                style: { width: 120 }
            },
            {

            },
        ]

        const glycogenLoadColumns = [
            {},
            {
                key: "props",
                label: "Muscle",
                style: { width: 120 }
            },
            {
                key: "grams",
                label: "Liver",
                style: { width: 120 }
            },
            {},
            {
                key: "kg",
                label: "Total",
                style: { width: 120 }
            },
            {}
        ]

        const glycogenFactorColumns = [
            {
                key: "props",
                label: "Diet Factor",
                style: { width: 120 }
            },
            {

            },
        ]

        const glycogenEstimateColumns = [
            {
                key: "props",
                label: "Liver Glycogen Est.",
                style: { width: 120 }
            },
            {

            },
        ]

        const columns = [
            {
                key: "props",
                label: "Skinfolds",
                style: { width: 120 }
            },
            {
                key: "firstReading",
                label: "Measure 1",
                style: { width: 120 }
            },
            {
                key: "secondReading",
                label: "Measure 2",
                style: { width: 120 }
            },
            {
                key: "thirdReading",
                label: "Measure 3",
                style: { width: 120 }
            },
            {
                key: "average",
                label: "Average",
                style: { width: 120 }
            },
        ]

        const rows = [
            {
                key: "triceps",
                label: "Triceps",
                prefix: "",
                suffix: " mm",
            },
            {
                key: "subscapular",
                label: "Subscapular",
                prefix: "",
                suffix: " mm",
            },
            {
                key: "biceps",
                label: "Biceps",
                prefix: "",
                suffix: " mm",
            },
            {
                key: "suprailium",
                label: "Suprailium",
                prefix: "",
                suffix: " mm",
            },
            {
                key: "abdomen",
                label: "Abdomen",
                prefix: "",
                suffix: " mm",
            },
            {
                key: "midThigh",
                label: "Mid Thigh",
                prefix: "",
                suffix: " mm",
            },
            {
                key: "calf",
                label: "Calf",
                prefix: "",
                suffix: " mm",
            },
        ]


        let values = form.values.bio || {};
        let gender = form.values.athlete.gender;
        let activity = form.values.bio?.activity;

        const dob = data.athlete.dob;
        const testDate = moment( data.date );
        const duration = moment.duration( testDate.diff( dob ) );
        const age = duration.years();

        const skinFoldsAverages = {
            triceps: getAverage( get( form.values, `bio.skinFoldsAttr.triceps` ) ),
            subscapular: getAverage( get( form.values, `bio.skinFoldsAttr.subscapular` ) ),
            biceps: getAverage( get( form.values, `bio.skinFoldsAttr.biceps` ) ),
            suprailium: getAverage( get( form.values, `bio.skinFoldsAttr.suprailium` ) ),
            abdomen: getAverage( get( form.values, `bio.skinFoldsAttr.abdomen` ) ),
            midThigh: getAverage( get( form.values, `bio.skinFoldsAttr.midThigh` ) ),
            calf: getAverage( get( form.values, `bio.skinFoldsAttr.calf` ) ),
        }

        const skinFoldsTotal = Object.values( skinFoldsAverages );
        const sos4Total = fixedNum( getAverage( get( form.values, `bio.skinFoldsAttr.triceps` ) ) + getAverage( get( form.values, `bio.skinFoldsAttr.suprailium` ) ) + getAverage( get( form.values, `bio.skinFoldsAttr.abdomen` ) ) + getAverage( get( form.values, `bio.skinFoldsAttr.midThigh` ) ), 2 )

        const bmi = getBmi( values.weight, values.height );
        const skinfoldsTotal = getSum( skinFoldsTotal );
        const bodyDensity = getBodyDensity( gender, skinfoldsTotal );
        const bodyFatPercentage = getBodyFatPercentage( bodyDensity );
        const leanBodyWeight = getLeanBodyWeight( values.weight, values.manualPercentageBf ); //bodyFatPercentage
        const bodyFatSos7 = getSos7( bodyDensity );
        const bodyFatSos4 = getSos4( sos4Total, age, gender );

        const avgBicepTricep = ( ( values.skinFoldsAttr?.bicepsAverage + values.skinFoldsAttr?.tricepsAverage ) / 2 );

        const correctedGirthBiceps = getCorrectedGirth( values.actualGirthsRelaxedBiceps, avgBicepTricep );
        // const correctedGirthUpperForearm = getCorrectedGirth(values.actualGirthsUpperForearm, values.skinFoldsAttr?.upperForearm);
        const correctedGirthMidThigh = getCorrectedGirth( values.actualGirthsMidThigh, values.skinFoldsAttr?.midThighAverage );
        const correctedGirthCalf = getCorrectedGirth( values.actualGirthsCalf, values.skinFoldsAttr?.calfAverage );
        // const correctedGirthAnkle = getCorrectedGirth(values.actualGirthsAnkle, values.skinFoldsAttr?.ankleAverage);
        const waistHipRatio = fixedNum( values.actualGirthsWaist / values.actualGirthsHip, 2 );

        const muscleMass = getMuscleMass( values.height, correctedGirthMidThigh, values.actualGirthsUpperForearm, correctedGirthCalf );
        const upperBodyMuscleMass = getUpperBodyMuscleMass( gender, muscleMass );
        const lowerBodyMuscleMass = getLowerBodyMuscleMass( gender, muscleMass );
        const muscleMassPercent = ( muscleMass / values.weight ) * 100;

        const upperBodyMuscleMassPercent = ( upperBodyMuscleMass / values.weight ) * 100;
        const lowerBodyMuscleMassPercent = ( lowerBodyMuscleMass / values.weight ) * 100;

        const glycogenLoadGrams = get( values, "glycogenLoadGrams" );

        const glycogenLoadKg = values.glycogenLoadGrams / values.weight;
        const glycogenKcal = getKcalorieValue( values?.glycogenLoadGrams );
        const glycogenKcalKg = glycogenKcal / values.weight;

        const glycogenWholeMuscle = ( muscleMass * 0.75 ) * glycogenLoadGrams * values.glycogenDiet
        const glycogenUpperMuscle = ( upperBodyMuscleMass * 0.85 ) * glycogenLoadGrams * values.glycogenDiet
        const glycogenLowerMuscle = ( lowerBodyMuscleMass * ( activity == "running" ? 0.75 : 0.65 ) ) * glycogenLoadGrams * values.glycogenDiet

        const glycogenLiver = values.height - 65;

        const glycogenWholeBody = glycogenLiver + glycogenWholeMuscle;
        const glycogenUpperBody = glycogenLiver + glycogenUpperMuscle;
        const glycogenLowerBody = glycogenLiver + glycogenLowerMuscle;

        const glycogenKcalWholeBody = getKcalorieValue( glycogenWholeBody );
        const glycogenKcalUpperBody = getKcalorieValue( glycogenUpperBody );
        const glycogenKcalLowerBody = getKcalorieValue( glycogenLowerBody );

        useUpdateEffect( () => {

            if ( !form.values.bio.bodyFatType ) return;
            if ( form.values.bio.bodyFatType === "other" ) return;

            if ( form.values.bio.bodyFatType === "sos4" )
                form.handleChange( { silent: true, name: 'bio.manualPercentageBf', value: bodyFatSos4 } );

            if ( form.values.bio.bodyFatType === "sos7" )
                form.handleChange( { silent: true, name: 'bio.manualPercentageBf', value: bodyFatSos7 } );

        }, [ form.values.bio?.bodyFatType, bodyFatSos7, bodyFatSos4 ] );

        return (

            <TestSessionsEditTabsPanel
                heading={"Bio Data"}
                {...props}
            >

                <FieldHidden
                    form={form}
                    name="bio.bmi"
                    value={bmi}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <Seperator heading='General' />

                        <Space />

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, lg: 12 }}>

                                <InputTable
                                // columns={generalColumns}
                                >

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Age</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 540 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.age"
                                                defaultValue={age}
                                                hidden
                                                disabled
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Weight</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 540 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.weight"
                                                suffix={" kg"}
                                                defaultValue={form.defaults.bio?.weight}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Height</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 540 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.height"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.height}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    {/* <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Inseam</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 540 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.inseam"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.inseam}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow> */}

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Body Fat Method</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 540 }}>

                                            <FieldRadio
                                                button={true}
                                                form={form}
                                                name="bio.bodyFatType"
                                                defaultValue={form.defaults.bio?.bodyFatType}
                                                required={false}
                                                options={[
                                                    { value: "sos7", label: "7-Site SOS" },
                                                    { value: "sos4", label: "4-Site SOS" },
                                                    { value: "other", label: "Other" },
                                                ]}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    {form.values.bio?.bodyFatType === "other" &&
                                        <InputTableRow>

                                            <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                                <><strong>Body Fat Other Method</strong></>
                                            </InputTableCell>

                                            <InputTableCell style={{ width: 540 }}>

                                                <FieldSelect
                                                    form={form}
                                                    name={`bio.manualPercentageBfType`}
                                                    defaultValue={form.defaults?.bio?.manualPercentageBfType}
                                                    options={[
                                                        { value: "dexa", label: "DEXA" },
                                                        { value: "bodPod", label: "BodPod" },
                                                        { value: "hydroSaticWeighing", label: "HydroStatic Weighing" },
                                                        { value: "bioElectricalImpedence", label: "Bio-Electrical Impedence" },
                                                        { value: "SkinfoldOther", label: "Skinfolds (Other Formula)" },
                                                        { value: "other", label: "Other" },
                                                    ]}
                                                    disabled={form.values.bio?.bodyFatType !== "other"}
                                                />

                                            </InputTableCell>

                                        </InputTableRow>
                                    }

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Body Fat %</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 540 }}>

                                            <FieldNumber
                                                key={form.values.bio?.bodyFatType !== "other" ? form.values.bio?.manualPercentageBf : ""}
                                                form={form}
                                                name="bio.manualPercentageBf"
                                                suffix={" %"}
                                                defaultValue={get( form.values, 'bio.manualPercentageBf' )}
                                                disabled={form.values.bio?.bodyFatType !== "other"}
                                                hidden={form.values.bio?.bodyFatType !== "other"}
                                                wrapClass={form.values.bio?.bodyFatType !== "other" ? "" : "manual-input"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                </InputTable>

                            </Col>

                            <Col col={{ xs: 24, lg: 12 }}>

                                <RatingScaleCard
                                    metric={'Body Fat'}
                                    value={get( form.values, 'bio.manualPercentageBf' )}
                                    form={form}
                                    name="bio.bodyFatScale"
                                    demographic={data.demographic}
                                    gender={gender}
                                />

                                <Space />

                                <RatingScaleCard
                                    metric={'BMI'}
                                    value={bmi}
                                    form={form}
                                    name="bio.bmiScale"
                                    demographic={data.demographic}
                                    gender={gender}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <Seperator heading='Girth & Skinfold' />

                        <Space />

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, lg: 12 }}>
                                <InputTable
                                    columns={girthsColumns}
                                >

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            <><strong>Biceps-Relaxed</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.actualGirthsRelaxedBiceps"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.actualGirthsRelaxedBiceps}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.correctedGirthsRelaxedBiceps"
                                                suffix={" cm"}
                                                key={correctedGirthBiceps}
                                                defaultValue={correctedGirthBiceps}
                                                disabled
                                                hidden
                                            />

                                        </InputTableCell>

                                    </InputTableRow>
                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            <><strong>Upper-Forearm</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.actualGirthsUpperForearm"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.actualGirthsUpperForearm}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.correctedGirthsUpperForearm"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.correctedGirthsUpperForearm}
                                                disabled
                                                hidden
                                            />

                                        </InputTableCell>

                                    </InputTableRow>
                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            <><strong>Mid-Thigh</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.actualGirthsMidThigh"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.actualGirthsMidThigh}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.correctedGirthsMidThigh"
                                                suffix={" cm"}
                                                key={correctedGirthMidThigh}
                                                defaultValue={correctedGirthMidThigh}
                                                disabled
                                                hidden
                                            />

                                        </InputTableCell>

                                    </InputTableRow>
                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            <><strong>Calf</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.actualGirthsCalf"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.actualGirthsCalf}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.correctedGirthsCalf"
                                                suffix={" cm"}
                                                key={correctedGirthCalf}
                                                defaultValue={correctedGirthCalf}
                                                disabled
                                                hidden
                                            />

                                        </InputTableCell>

                                    </InputTableRow>
                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            <><strong>Ankle</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.actualGirthsAnkle"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.actualGirthsAnkle}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.correctedGirthsAnkle"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.correctedGirthsAnkle}
                                                disabled
                                                hidden
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            <><strong>Waist</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.actualGirthsWaist"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.actualGirthsWaist}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.correctedGirthsWaist"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.correctedGirthsWaist}
                                                disabled
                                                hidden
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            <><strong>Hip</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.actualGirthsHip"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.actualGirthsHip}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.correctedGirthsHip"
                                                suffix={" cm"}
                                                defaultValue={form.defaults.bio?.correctedGirthsHip}
                                                disabled
                                                hidden
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            <><strong>Waist-to-Hip Ratio</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.actualGirthsWaistHipRatio"
                                                defaultValue={waistHipRatio}
                                                key={waistHipRatio}
                                                disabled
                                                hidden
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                        </InputTableCell>

                                    </InputTableRow>

                                </InputTable>

                                <Space />

                                <InputTable
                                    columns={columns}
                                >

                                    {rows.map( ( row, index ) => {

                                        const average = skinFoldsAverages[ row.key ];

                                        return (

                                            <React.Fragment key={index}>

                                                <InputTableRow>

                                                    <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                                        <><strong>{row.label}</strong></>
                                                    </InputTableCell>

                                                    <InputTableCell style={{ width: 220 }}>

                                                        <FieldNumber
                                                            form={form}
                                                            wrapClass={"w-100 manual-input"}
                                                            name={`bio.skinFoldsAttr.${ row.key }.measure1`}
                                                            defaultValue={get( form.defaults, `bio.skinFoldsAttr.${ row.key }.measure1` )}
                                                            suffix={row.suffix}
                                                            prefix={row.prefix}
                                                            allowNegative={true}
                                                        />

                                                    </InputTableCell>

                                                    <InputTableCell style={{ width: 220 }}>

                                                        <FieldNumber
                                                            form={form}
                                                            wrapClass={"w-100 manual-input"}
                                                            name={`bio.skinFoldsAttr.${ row.key }.measure2`}
                                                            defaultValue={get( form.defaults, `bio.skinFoldsAttr.${ row.key }.measure2` )}
                                                            suffix={row.suffix}
                                                            prefix={row.prefix}
                                                            allowNegative={true}
                                                        />

                                                    </InputTableCell>

                                                    <InputTableCell style={{ width: 220 }}>

                                                        <FieldNumber
                                                            form={form}
                                                            wrapClass={"w-100 manual-input"}
                                                            name={`bio.skinFoldsAttr.${ row.key }.measure3`}
                                                            defaultValue={get( form.defaults, `bio.skinFoldsAttr.${ row.key }.measure3` )}
                                                            suffix={row.suffix}
                                                            prefix={row.prefix}
                                                            allowNegative={true}
                                                        />

                                                    </InputTableCell>

                                                    <InputTableCell style={{ width: 220 }}>

                                                        <FieldNumber
                                                            form={form}
                                                            wrapClass={"w-100"}
                                                            name={`bio.skinFoldsAttr.${ row.key }Average`}
                                                            defaultValue={fixedNum( average, 2.00 )}
                                                            key={average}
                                                            suffix={row.suffix}
                                                            prefix={row.prefix}
                                                            allowNegative={true}
                                                            disabled
                                                            hidden
                                                        />

                                                    </InputTableCell>

                                                </InputTableRow>

                                            </React.Fragment>
                                        )

                                    } )}

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }} colSpan={4}>
                                            <><strong>Total</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.skinfoldsSosTotal"
                                                suffix={" mm"}
                                                key={skinfoldsTotal}
                                                defaultValue={fixedNum( skinfoldsTotal, 2.00 )}
                                                wrapClass={"w-100"}
                                                disabled
                                                hidden
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                </InputTable>

                                <Space />

                                <InputTable
                                    columns={computedColumns}
                                >

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Lean Body Mass</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                hidden
                                                disabled
                                                name="bio.leanBodyWeight"
                                                suffix={" kg"}
                                                key={leanBodyWeight}
                                                defaultValue={leanBodyWeight.toFixed( 1 )}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Muscle Mass</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                hidden
                                                disabled
                                                name="bio.muscleMass"
                                                suffix={` kg / ${ muscleMassPercent ? muscleMassPercent?.toFixed( 1 ) : 0 } %`}
                                                key={muscleMass}
                                                defaultValue={muscleMass.toFixed( 1 )}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Upper Muscle</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                hidden
                                                disabled
                                                name="bio.upperBodyMuscleMass"
                                                suffix={` kg / ${ upperBodyMuscleMassPercent ? upperBodyMuscleMassPercent.toFixed( 1 ) : 0 } %`}
                                                key={upperBodyMuscleMass}
                                                defaultValue={upperBodyMuscleMass.toFixed( 1 )}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Lower Muscle</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                hidden
                                                disabled
                                                name="bio.lowerBodyMuscleMass"
                                                suffix={` kg / ${ lowerBodyMuscleMassPercent ? lowerBodyMuscleMassPercent.toFixed( 1 ) : 0 } %`}
                                                key={lowerBodyMuscleMass}
                                                defaultValue={lowerBodyMuscleMass.toFixed( 1 )}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                </InputTable>

                            </Col>

                            <Col col={{ xs: 24, lg: 12 }} >

                                <RatingScaleCard
                                    metric={'SOS'}
                                    value={skinfoldsTotal.toFixed( 2 )}
                                    Scale={get( form.values, 'bio.sosScale' )}
                                    form={form}
                                    name="bio.sosScale"
                                    demographic={data.demographic}
                                    gender={gender}
                                />

                                <Space />

                                <RatingScaleCard
                                    metric={'Waist-to-Hip Ratio'}
                                    value={waistHipRatio.toFixed( 2 )}
                                    form={form}
                                    name="bio.WaistHipRatioScale"
                                    demographic={data.demographic}
                                    gender={gender}
                                />

                                <Space />

                                <FieldTextArea
                                    form={form}
                                    label={"Body Composition"}
                                    name={"bio.description"}
                                    defaultValue={form.defaults?.bio?.description}
                                    rows={4}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <Seperator heading='Glycogen Load' />

                        <Space />

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, lg: 12 }}>

                                <InputTable
                                // columns={glycogenColumns}
                                >

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Glycogen Load per kg</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenLoadGrams"
                                                suffix={" g/kg"}
                                                defaultValue={form.defaults.bio?.glycogenLoadGrams}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Carbohydrate Intake</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldSelect
                                                form={form}
                                                name="bio.glycogenDiet"
                                                defaultValue={form.defaults.bio.glycogenDiet}
                                                options={[
                                                    { value: 1.3, label: "Very High (>400g)" },
                                                    { value: 1.2, label: "High (300-400g)" },
                                                    { value: 1, label: "Moderate (200-300g)" },
                                                    { value: 0.8, label: "Low (100-200g)" },
                                                    { value: 0.7, label: "Very Low (<100g)" },
                                                ]}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>Activity</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldSelect
                                                form={form}
                                                name="bio.activity"
                                                defaultValue={form.defaults.bio?.activity}
                                                options={[
                                                    { value: "running", label: "Running" },
                                                    { value: "cycling", label: "Cycling" },
                                                ]}
                                                wrapClass={"manual-input"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                </InputTable>

                                <Space />

                                <InputTable
                                    columns={glycogenLoadColumns}
                                >

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 110, paddingLeft: 12 }}>
                                            <><strong>Whole Muscle</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenWholeMuscle"
                                                suffix={" g"}
                                                hidden
                                                disabled
                                                key={glycogenWholeMuscle}
                                                defaultValue={glycogenWholeMuscle.toFixed( 0 )}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenLiver"
                                                suffix={" g"}
                                                hidden
                                                disabled
                                                key={glycogenLiver}
                                                defaultValue={glycogenLiver.toFixed( 0 )}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 110, paddingLeft: 12 }}>
                                            <><strong>Whole Body</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenWholeBody"
                                                suffix={" g"}
                                                defaultValue={glycogenWholeBody.toFixed( 0 )}
                                                hidden
                                                disabled
                                                key={glycogenWholeBody}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenKcalWholeBody"
                                                suffix={" kcal"}
                                                defaultValue={glycogenKcalWholeBody.toFixed( 0 )}
                                                hidden
                                                disabled
                                                key={glycogenKcalWholeBody}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 110, paddingLeft: 12 }}>
                                            <><strong>Upper Muscle</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenUpperMuscle"
                                                suffix={" g"}
                                                hidden
                                                disabled
                                                key={glycogenUpperMuscle}
                                                defaultValue={glycogenUpperMuscle.toFixed( 0 )}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenLiver"
                                                suffix={" g"}
                                                hidden
                                                disabled
                                                key={glycogenLiver}
                                                defaultValue={glycogenLiver.toFixed( 0 )}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 110, paddingLeft: 12 }}>
                                            <><strong>Upper Body</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenUpperBody"
                                                suffix={" g"}
                                                defaultValue={glycogenUpperBody.toFixed( 0 )}
                                                hidden
                                                disabled
                                                key={glycogenUpperBody}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenKcalUpperBody"
                                                suffix={" kcal"}
                                                defaultValue={glycogenKcalUpperBody.toFixed( 0 )}
                                                hidden
                                                disabled
                                                key={glycogenKcalUpperBody}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>
                                    <InputTableRow>

                                        <InputTableCell style={{ width: 110, paddingLeft: 12 }}>
                                            <><strong>Lower Muscle</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenLowerMuscle"
                                                suffix={" g"}
                                                hidden
                                                disabled
                                                key={glycogenLowerMuscle}
                                                defaultValue={glycogenLowerMuscle.toFixed( 0 )}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenLiver"
                                                suffix={" g"}
                                                hidden
                                                disabled
                                                key={glycogenLiver}
                                                defaultValue={glycogenLiver.toFixed( 0 )}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 110, paddingLeft: 12 }}>
                                            <><strong>Lower Body</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenLowerBody"
                                                suffix={" g"}
                                                defaultValue={glycogenLowerBody.toFixed( 0 )}
                                                hidden
                                                disabled
                                                key={glycogenLowerBody}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 440 }}>

                                            <FieldNumber
                                                form={form}
                                                name="bio.glycogenKcalLowerBody"
                                                suffix={" kcal"}
                                                defaultValue={glycogenKcalLowerBody.toFixed( 0 )}
                                                hidden
                                                disabled
                                                key={glycogenKcalLowerBody}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                </InputTable>

                            </Col>

                            <Col col={{ xs: 24, lg: 12 }}>

                                <RatingScaleCard
                                    metric={'Glycogen Load per kg Muscle Mass'}
                                    value={get( form.values, 'bio.glycogenLoadGrams' )}
                                    form={form}
                                    name="bio.glycogenScale"
                                    demographic={data.demographic}
                                    altUnit={{
                                        value: glycogenKcal,
                                        suffix: "Kcal/kg",
                                    }}
                                    gender={gender}
                                />

                                <Space />

                                <RatingScaleCard
                                    metric={'Glycogen Load'}
                                    value={get( form.values, 'bio.glycogenWholeBody' )}
                                    form={form}
                                    name="bio.glycogenScale"
                                    demographic={data.demographic}
                                    altUnit={{
                                        value: get( form.values, 'bio.glycogenKcalWholeBody' ),
                                        suffix: "Kcal",
                                    }}
                                    gender={gender}
                                    renderAfter={() => (
                                        <div >

                                            <MetricRanking
                                                data={{
                                                    title: "Upper Body",
                                                    suffix: "g"
                                                }}
                                                value={isNaN( get( form.values, 'bio.glycogenUpperBody' ) ) ? 0 : get( form.values, 'bio.glycogenUpperBody' )}
                                                rating={" "}
                                                altUnit={{
                                                    value: get( form.values, 'bio.glycogenKcalUpperBody' ),
                                                    suffix: "kcal",
                                                    sep: "/"
                                                }}
                                            />

                                            <MetricRanking
                                                data={{
                                                    title: "Lower Body",
                                                    suffix: "g"
                                                }}
                                                value={isNaN( get( form.values, 'bio.glycogenLowerBody' ) ) ? 0 : get( form.values, 'bio.glycogenLowerBody' )}
                                                rating={" "}
                                                altUnit={{
                                                    value: get( form.values, 'bio.glycogenKcalLowerBody' ),
                                                    suffix: "kcal",
                                                    sep: "/"
                                                }}
                                            />

                                        </div>
                                    )}
                                />

                                <Space />

                                {/* <Row gutter={16} edge={true}>

                                    <Col col={{ xs: 12, lg: 12 }}>

                                        <InputTable
                                            columns={glycogenFactorColumns}
                                        >

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <><strong>High Carb</strong></>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>1.2</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <><strong>Mixed Carb-Protein</strong></>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>1.0</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <><strong>Low Carb</strong></>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>0.8</>
                                                </InputTableCell>

                                            </InputTableRow>

                                        </InputTable>

                                    </Col>

                                    <Col col={{ xs: 12, lg: 12 }}>

                                        <InputTable
                                            columns={glycogenEstimateColumns}
                                        >

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <><strong>Height cm (x)</strong></>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <><strong>Liver Glycogen g (y)</strong></>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>150</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>85</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>155</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>90</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>160</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>95</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>165</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>100</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>170</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>105</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>175</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>110</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>180</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>115</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>185</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>120</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>190</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>125</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>195</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>130</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <><strong>Reg Formula:</strong></>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>1</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>-65</>
                                                </InputTableCell>

                                            </InputTableRow>

                                            <InputTableRow>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>(b-slope)</>
                                                </InputTableCell>

                                                <InputTableCell style={{ width: 220, paddingLeft: 12 }}>
                                                    <>(intercept)</>
                                                </InputTableCell>

                                            </InputTableRow>

                                        </InputTable>

                                    </Col>

                                </Row> */}

                            </Col>

                        </Row>

                    </Col>

                </Row>

            </TestSessionsEditTabsPanel>
        )


    }, [ props, form.hash ] );

}

export default TestSessionsEditTabsBioData;
