import React from 'react';

import styles from "./GlycogenFueling.module.scss";
import { Row, Col, Panel } from '@dex/bubl-dash';
import FancyValue from '../FancyValue/FancyValue';
import { getFractionNumber } from '../../functions/sweatTest';
import { getGlycogenImage } from '../../functions/glycogenTest';

const GlycogenFueling: React.FC<GlycogenFuelingProps> = (props) => {

    const { unit, recommendedRate } = props;

    return (
        // <></>
        <Panel heading={false} className={styles.panel}>

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 24 }} align='center'>
                    <strong style={{ fontSize: "2rem" }}>Recommended Fueling</strong>
                </Col>

                <Col col={{ xs: 24 }}>
                    <FancyValue
                        value={recommendedRate}
                        suffix={unit}
                    />
                </Col>

                <Col col={{ xs: 24 }} align='center'>
                    <p style={{ fontSize: "1.5rem", fontWeight: 600 }}>is equivalent to:</p>
                </Col>

                <Col col={{ xs: 6 }} align='center' justify='center'>
                    <p style={{ fontSize: "1.5rem", fontWeight: 600, textAlign: "center" }}>
                        {getFractionNumber(getGlycogenImage(recommendedRate), "Gel")}
                    </p>
                </Col>

                <Col col={{ xs: 18 }} align='center' >
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>

                        {getGlycogenImage(recommendedRate).map((item, index) => (

                            <div style={{ display: "flex", alignItems: "center" }}>
                                {index >= 1 &&
                                    <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                }
                                <img
                                    src={require(`../../assets/images/GlycogenTest/gels-${item}.png`)}
                                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                                    alt={`${item}.png`}
                                />
                            </div>

                        ))}
                    </div>
                </Col>

                <Col col={{ xs: 24 }} align='center' justify='center'>
                    <p style={{ fontSize: "1.5rem", fontWeight: 600 }}>
                        or
                    </p>
                </Col>

                <Col col={{ xs: 6 }} align='center' justify='center'>
                    <p style={{ fontSize: "1.5rem", fontWeight: 600, textAlign: "center" }}>
                        {getFractionNumber(getGlycogenImage(recommendedRate), "Energy Drink")}
                    </p>
                </Col>

                <Col col={{ xs: 18 }} align='center' >

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>

                        {getGlycogenImage(recommendedRate).map((item, index) => (

                            <div style={{ display: "flex", alignItems: "center" }}>
                                {index >= 1 &&
                                    <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                }
                                <img
                                    src={require(`../../assets/images/GlycogenTest/energyDrinks-${item}.png`)}
                                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                                    alt={`${item}.png`}
                                />
                            </div>

                        ))}

                    </div>

                </Col>

            </Row>

        </Panel>
    )

}

interface GlycogenFuelingProps {
    [key: string]: any,
}

export default GlycogenFueling;