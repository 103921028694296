import { Col, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import styles from "./GaitReportAnalysisStance.module.scss";
import { ColoredHeader } from './GaitReportPage';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import { get } from '@dex/bubl-helpers';
import { GaitReportAnalysisChild, RunningAnalysisLegend } from './GaitReportAnalysis';

const GaitReportAnalysisStance: React.FC<any> = (props: any) => {

    const { data, form, run, index } = props;

    const runData = run;
    const gender = data.athlete.gender;

    return useMemo(() => (

        <Row edge gutter={20}>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Running Analysis"
                    desc={`${runData.date}`}
                />

            </Col>


            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Stance Phase"
                    legend={<RunningAnalysisLegend />}
                />

            </Col>

            <Col col={{ xs: 8 }}>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Knee Angle"
                    optimal="135 - 145 Deg"
                    commentKey="side.kneeAngle.comment"
                    valueLeft={get(runData, "side.kneeAngle.leftRating")}
                    valueRight={get(runData, "side.kneeAngle.rightRating")}
                    image="KneeAngle"
                />

            </Col>

            {/* <Col col={{ xs: 8 }}>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Leg Angle"
                    optimal="Straight"
                    commentKey="rear.legAngle.comment"
                    valueRight={get(runData, "rear.legAngle.rightRating")}
                    valueLeft={get(runData, "rear.legAngle.leftRating")}
                    image="LegAngle"
                />

            </Col> */}

            <Col col={{ xs: 8 }} className='table-bdr-left-gray'>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Knee Shifting"
                    optimal="None"
                    commentKey="rear.kneeShifting.comment"
                    valueLeft={get(runData, "rear.kneeShifting.leftRating")}
                    valueRight={get(runData, "rear.kneeShifting.rightRating")}
                    image="KneeShifting"
                    className={"centeredColor"}
                />

            </Col>

            <Col col={{ xs: 8 }} className='table-bdr-left-gray'>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Leg Cross"
                    optimal="None"
                    commentKey="rear.legCross.comment"
                    valueLeft={get(runData, "rear.legCross.leftRating")}
                    valueRight={get(runData, "rear.legCross.rightRating")}
                    image="LegCross"
                    className={"centeredColor"}
                />

            </Col>

            <Col col={{ xs: 8 }}>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Torso Rotation"
                    optimal="Moderate"
                    commentKey="rear.torsoRotation.comment"
                    valueLeft={get(runData, "rear.torsoRotation.leftRating")}
                    valueRight={get(runData, "rear.torsoRotation.rightRating")}
                    image="TorsoRotation"
                    className={"centeredColor"}
                />

            </Col>

            {/* <Col col={{ xs: 8 }}>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Elbow Position"
                    optimal="Slightly Outward"
                    commentKey="rear.elbowPosition.comment"
                    valueLeft={get(runData, "rear.elbowPosition.leftRating")}
                    valueRight={get(runData, "rear.elbowPosition.rightRating")}
                    image="ElbowPosition"
                />

            </Col> */}

            <Col col={{ xs: 8 }} className='table-bdr-left-gray'>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Torso Lean"
                    optimal="~7 Deg Forwards"
                    commentKey="side.torsoLean.comment"
                    value={get(runData, "side.torsoLean.rating")}
                    image="TorsoLean"
                    className={"centeredColor"}
                />

            </Col>

            {/* <Col col={{ xs: 8 }}>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Pelvic Tilt"
                    optimal="Moderate"
                    commentKey="side.pelvicTilt.comment"
                    value={get(runData, "side.pelvicTilt.rating")}
                    image="PelvicTilt"
                    className={"centeredColor"}
                />

            </Col> */}

            <Col col={{ xs: 8 }} className='table-bdr-left-gray'>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Pelvic Drop"
                    optimal="Moderate"
                    commentKey="rear.pelvicDrop.comment"
                    valueRight={get(runData, "rear.pelvicDrop.rightRating")}
                    valueLeft={get(runData, "rear.pelvicDrop.leftRating")}
                    image="PelvicDrop"
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Space />

                <CoachingReportHeading
                    text="Swing Phase"
                />

            </Col>

            <Col col={{ xs: 8 }} >

                <GaitReportAnalysisChild
                    data={runData}
                    title="Toe Off Area"
                    optimal=" Mid To Big Toes"
                    commentKey="rear.toeOffArea.comment"
                    valueRight={get(runData, "rear.toeOffArea.rightRating")}
                    valueLeft={get(runData, "rear.toeOffArea.leftRating")}
                    image="ToeOffArea"
                    gender={gender}
                />

            </Col>

            <Col col={{ xs: 8 }} className='table-bdr-left-gray'>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Heel Whip"
                    optimal=" Straight"
                    commentKey="rear.heelWhip.comment"
                    valueRight={get(runData, "rear.heelWhip.rightRating")}
                    valueLeft={get(runData, "rear.heelWhip.leftRating")}
                    image="HeelWhip"
                    className={"centeredColor"}
                />

            </Col>

            <Col col={{ xs: 8 }} className='table-bdr-left-gray'>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Arm Swing"
                    optimal=" Moderate With Arms Bent ~70-90 Deg"
                    commentKey="side.armSwingStyle.comment"
                    valueRight={get(runData, "side.armSwingStyle.rightRating")}
                    valueLeft={get(runData, "side.armSwingStyle.leftRating")}
                    image="ArmSwingStyle"
                    className={"centeredColor"}
                />

            </Col>

        </Row>

    ), [data]);

}

export default GaitReportAnalysisStance;