import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';

const ExercisesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Exercises Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, sm: 16 }}>

                        <FieldText
                            form={form}
                            name="name"
                            label="Name"
                            required={true}
                            defaultValue={form.defaults.name}
                        />

                        <FieldText
                            form={form}
                            name="instruction"
                            label="Instruction"
                            defaultValue={form.defaults.instruction}
                        />

                        <FieldTextArea
                            form={form}
                            name="description"
                            label="Description"
                            defaultValue={form.defaults.description}
                        />


                    </Col>

                    <Col col={{ xs: 24, sm: 8 }}>

                        <FieldSelect
                            form={form}
                            name="target"
                            label="Target"
                            required={true}
                            defaultValue={form.defaults.target}
                            options={[
                                { value: "Strength", label: "Strength" },
                                { value: "Flexibility", label: "Flexibility" },
                            ]}
                        />

                        <FieldUpload
                            form={form}
                            name='photo'
                            folder='admin-media'
                            label='Photo'
                            defaultValue={form.defaults.photo}
                            multiple={false}
                            accept='image/*'
                            limit={1}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>

                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />

                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default ExercisesCreateEditForm;
