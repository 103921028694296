import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import { FieldAutocomplete, FieldSwitch } from '@dex/bubl-dash';

const SessionsEditTabsSizingDetails: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data } = props;

    const handleBarOptions = useCallback((bikeType) => {

        if (bikeType === "Road") {

            return [
                { value: "Dropbars", label: "Dropbars" },
                { value: "Aerobars", label: "Aerobars" },
                { value: "None", label: "None" },
            ]

        } else if (bikeType === "Time Trial") {

            return [
                { value: "Aerobars", label: "Aerobars" },
                { value: "None", label: "None" },
            ]

        } else if (bikeType === "MTB" || bikeType === "Foldie") {

            return [
                { value: "MTB-Riser", label: "MTB-Riser" },
                { value: "MTB-Straight", label: "MTB-Straight" },
                { value: "MTB-Upsweep", label: "MTB-Upsweep" },
                { value: "MTB-Other", label: "MTB-Other" },
                { value: "None", label: "None" },
            ];

        } else {

            return [
                { value: "Dropbars", label: "Dropbars" },
                { value: "None", label: "None" },
            ];

        }

    }, []);

    useEffect(() => {

        if (bike.type) {

            const options = handleBarOptions(bike.type);

            let hasValidOption = false;

            options.map(option => {

                if (option.value === bike.handlebarType) hasValidOption = true;

            });

            if (hasValidOption) return;

            form.handleChange({ name: `${prefix}.handlebarType`, value: options[0].value });

        }

    }, [bike.type]);

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24 }}>

                <FieldSwitch
                    button={true}
                    form={form}
                    label={"Is RETUL"}
                    name={`${prefix}.isRetul`}
                    defaultValue={bike.isRetul}
                />

            </Col>

            <Col col={{ xs: 24, md: 18 }}>

                <FieldRadio
                    button={true}
                    form={form}
                    label={"Type"}
                    name={`${prefix}.type`}
                    defaultValue={bike.type}
                    required={true}
                    options={[
                        // { value: "RETUL", label: "RETUL" },
                        { value: "Road", label: "Road" },
                        { value: "Time Trial", label: "Time Trial" },
                        { value: "Gravel", label: "Gravel" },
                        { value: "Touring", label: "Touring" },
                        { value: "MTB", label: "MTB" },
                        { value: "Foldie", label: "Foldie" },
                    ]}
                />

            </Col>

            <Col col={{ xs: 24, md: 6 }}>

                <FieldSelect
                    key={bike.type}
                    button={true}
                    form={form}
                    label={"Handlebar Type"}
                    name={`${prefix}.handlebarType`}
                    defaultValue={bike.handlebarType}
                    options={handleBarOptions(bike.type)}
                />

            </Col>

            <Col col={{ xs: 12, md: 6 }}>

                <FieldAutocomplete
                    form={form}
                    required={true}
                    label={"Bike Brand"}
                    name={`${prefix}.brand`}
                    defaultValue={bike.brand}
                    group={"BikeBrand"}
                    preload={true}
                />

            </Col>

            <Col col={{ xs: 12, md: 6 }}>

                <FieldAutocomplete
                    key={bike.brand}
                    form={form}
                    label={"Bike Model"}
                    name={`${prefix}.model`}
                    defaultValue={bike.model}
                    group={"BikeModel-" + bike.brand}
                    preload={true}
                />

            </Col>

            <Col col={{ xs: 12, md: 6 }}>

                <FieldNumber
                    form={form}
                    label={"Year"}
                    name={`${prefix}.year`}
                    defaultValue={bike.year}
                    thousandSeparator=""
                    max={2999}
                />

            </Col>

            <Col col={{ xs: 12, md: 6 }}>

                <FieldText
                    form={form}
                    label={"Bike Size"}
                    name={`${prefix}.setupBikeSize`}
                    defaultValue={bike.setupBikeSize}
                />

            </Col>

        </Row>


    ), [props]);

}

export default SessionsEditTabsSizingDetails;
