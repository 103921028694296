import React, { useMemo } from 'react';
import { Col, Heading, Line, Row, Space } from '@dex/bubl-dash';
import FieldRatingsGridLegend from '../../../elements/FieldRatingsGrid/FieldRatingsGridLegend';
import FieldRatingsGrid from '../../../elements/FieldRatingsGrid/FieldRatingsGrid';
import { loop, titleCase } from '@dex/bubl-helpers';

const FieldResultsTeamRatings: React.FC<any> = ( props: any ) => {

    const { report } = props;

    const items = useMemo( () => {

        const items: any = [];

        loop( report.ratings, ( charts, category ) => {

            loop( charts || [], ( chart, index ) => {

                const template = report.templates[ chart.id ];
                const scale = report.scales[ chart.id ];

                chart.scale = scale;
                chart.name = template.name;
                chart.subtitle = template.name;

                chart.decimalPlace = template.decimalPlace;

                chart.value = chart.score;
                chart.label = chart.rating;

            } )

            items.push( {
                category: category,
                title: titleCase( category ),
                charts: charts || [],
            } )

        } )

        return items;

    }, [ report.ratings ] );

    return useMemo( () => (

        <>

            <Row gutter={"auto"} edge={true}>

                <Col col={{ xs: 24 }}>

                    <Heading size={2} space={false}>Team Results - Ratings Summary</Heading>

                    <Space height={"small"} />

                    <p>
                        Your Team’s average score and ratings on each test attribute are shown below. Ratings where possible are based on international scores from other athletes/teams of a similar level within your sport or ability level.
                    </p>

                    <Space />

                    <FieldRatingsGridLegend />

                </Col>

            </Row>

            <Space />

            <FieldRatingsGrid
                items={items}
            />

        </>

    ), [ props, items ] );

}

export default FieldResultsTeamRatings;
