import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';

const SessionsEditTabsSizingSummary: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Comments"}
                    name={`${prefix}.comments`}
                    defaultValue={bike.comments}
                    rows={4}
                    maxLength={640}
                />
            </Col>

        </Row>

    ), [props]);

}

export default SessionsEditTabsSizingSummary;
