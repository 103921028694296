import React, { useMemo } from 'react';
import { Col, Row } from '@dex/bubl-dash';
import FieldResultsSummaryAttributes from '../../../elements/FieldResultsSummaryAttributes/FieldResultsSummaryAttributes';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';

const FieldResultsAthleteSummary: React.FC<any> = (props: any) => {

    const { session, report } = props;

    return useMemo(() => (

        <>

            <Row gutter={"auto"} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldResultsSummaryAttributes
                        heading={"Conditioning & Focus"}
                        attributes={report.attributes}
                    />

                </Col>

                <Col col={{ xs: 24, md: 16 }}>

                    <ReportMetaTable
                        data={report}
                        columnSize={{ xs: 24 }}
                        columnA={[
                            {
                                key: 'summary',
                                label: 'Comments',
                                format: "nl2br",
                            },
                        ]}
                    />

                </Col>

            </Row>

        </>

    ), [props]);

}

export default FieldResultsAthleteSummary;
