import { Col, Line, MediaImage, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import styles from "./GaitReportExercises.module.scss";
import { ColoredHeader } from './GaitReportPage';

const GaitReportExercises: React.FC<any> = (props: any) => {

    const { data, form } = props;

    let gender = data.athlete?.gender;

    return useMemo(() => (

        <Row gutter={20} edge>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Exercises For You"
                />

            </Col>

            {data.exercisesStrength.length > 0 &&

                <>
                    <Col col={{ xs: 1 }}>

                        <ExercisesSideHeading
                            heading="Strength"
                        />

                    </Col>

                    <Col col={{ xs: 23 }} className={styles.exercisesContainer}>

                        <Row edge gutter={8} >

                            {data.exercisesStrength.map((strength, index) => (

                                <Col col={{ xs: 8 }} key={index}>

                                    <div className={styles.image}>

                                        <MediaImage
                                            id={strength.photo?.fileId}
                                            filters={{ width: "281", height: "160", resizeMode: "contain", fallback: { background: "#313b45", text: strength.name } }}
                                            imgProps={{
                                                crossOrigin: "anonymous"
                                            }}
                                        />

                                    </div>

                                    <Space height={8} />

                                    <p className='sub-heading-title'>{strength.name}</p>

                                    <Space height={8} />

                                    <p className='text-label'>Sets/Reps</p>

                                    <p>{strength.instruction}</p>

                                    <Space height={8} />

                                    <p className='text-label'>Description</p>

                                    <p>{strength.description} </p>

                                </Col>

                            ))}

                        </Row>

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <Space />

                    </Col>

                </>

            }

            {data.exercisesFlexibility.length > 0 &&

                <>

                    <Col col={{ xs: 1 }}>

                        <ExercisesSideHeading
                            heading="Flexibility"
                        />

                    </Col>

                    <Col col={{ xs: 23 }} className={styles.exercisesContainer}>

                        <Row edge gutter={8}>

                            {data.exercisesFlexibility.map((flexibility, index) => (

                                <Col col={{ xs: 8 }} key={index}>

                                    <div className={styles.image}>

                                        <MediaImage
                                            id={flexibility.photo?.fileId}
                                            filters={{ width: "281", height: "160", resizeMode: "contain", fallback: { background: "#313b45", text: flexibility.name } }}
                                            imgProps={{
                                                crossOrigin: "anonymous"
                                            }}
                                        />

                                    </div>

                                    <Space />

                                    <p className='sub-heading-title'>{flexibility.name}</p>

                                    <Space />

                                    <p className='text-label'>Sets/Reps</p>

                                    <p>{flexibility.instruction}</p>

                                    <Space />

                                    <p className='text-label'>Description</p>

                                    <p>{flexibility.description} </p>

                                </Col>

                            ))}

                        </Row>

                    </Col>

                </>

            }

        </Row>

    ), [data]);

}

const ExercisesSideHeading: React.FC<any> = (props: any) => {

    const { heading } = props;

    return (
        <div className={styles.title}>
            {heading}
        </div>
    )
}

export default GaitReportExercises;