import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';


const QuestionnairesComplete: React.FC<any> = (props: any) => {

    return useMemo(() => (

        <Panel
            heading={"Thank you message"}
        >



        </Panel>


    ), [props]);

}

export default QuestionnairesComplete;
