import { Row, Col, Box, FieldSelect, FieldCheckbox, FieldAutocomplete, FieldRadio, useForm, Space } from '@dex/bubl-dash';
import React, { useEffect, useMemo, useState } from 'react';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import ChartCoachingWorkoutRunInterval from '../../../elements/ChartCoaching/ChartCoachingWorkoutRunInterval';
import ChartCoachingWorkoutRunEndurance from '../../../elements/ChartCoaching/ChartCoachingWorkoutRunEndurance';
import ChartCoachingWorkoutRunSummary from '../../../elements/ChartCoaching/ChartCoachingWorkoutRunSummary';
import CoachingReportWeeklyAverage from '../../../elements/CoachingReportWeeklyAverage/CoachingReportWeeklyAverage';
import CoachingReportAverageTable from '../../../elements/CoachingReportAverageTable/CoachingReportAverageTable';
import { PeriodFilter } from './CoachingReportTracking';
import CoachingApi from '../CoachingApi';
import { getClockTime } from '../../../functions/sharedTests';
import ChartProps from '../../../elements/Chart/Chart.Props';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import { fixedNum, hashCode, unslug } from '@dex/bubl-helpers';

const CoachingReportWorkoutsRun: React.FC<any> = (props: any) => {

    const { data, form, type } = props;

    const filter = useForm({ period: "last-3-months", interval: { duration: "60-180 sec" }, endurance: {} });

    const [trackingData, setTrackingData]: any = useState([]);
    const fetch = CoachingApi.getReportData(data.id);

    useEffect(() => {

        fetch.run({
            params: { filters: filter.values, type: "workouts-run" },
            onComplete: (response: any) => {

                setTrackingData(response);

            }

        })

    }, [filter.values.to, filter.values.from, hashCode(filter.values.interval), hashCode(filter.values.endurance)]);

    const handleChange = (name, resetName, value) => {

        filter.handleChange({ name: name, value: value, reset: true });

        filter.handleChange({ name: resetName, value: "", reset: true });

    };

    const enduranceDuration = [
        { label: "<30 min", value: "<30 min" },
        { label: "30-60 min", value: "30-60 min" },
        { label: "1-2 hrs", value: "60-120 min" },
        { label: "2-4 hrs", value: "120-240 hrs" },
        { label: "4-6 hrs", value: "240-460 hrs" },
        { label: "6-10 hrs", value: "460-600 hrs" },
        { label: ">10 hrs", value: ">600 hrs" }
    ];

    const enduranceDistance = [
        { label: "<5 KM", value: "<5 KM" },
        { label: "5-10 KM", value: "5-10 KM" },
        { label: "10-21 KM", value: "10-21 KM" },
        { label: "21-42 KM", value: "21-42 KM" },
        { label: ">42 KM", value: ">42 KM" },
    ];

    const intervalDuration = [
        { label: "<30 sec", value: "<30 sec" },
        { label: "30-60 sec", value: "30-60 sec" },
        { label: "1-3 min", value: "60-180 sec" },
        { label: "3-5 min", value: "180-300 sec" },
        { label: "5-10 min", value: "300-600 sec" },
        { label: "10-20 min", value: "600-1200 sec" },
        { label: ">20 min", value: ">1200 sec" }
    ];

    const intervalDistance = [
        { label: "<400 m", value: "<.4 km" },
        { label: "400-1000 m", value: ".4-1 km" },
        { label: "1-3 km", value: "1-3 km" },
        { label: "3-5 km", value: "3-5 km" },
        { label: ">5 km", value: ">5 km" },
    ];

    const getLabelForValue = (value, options) => {

        const duration = options.find(opt => opt.value === value);

        return duration ? duration.label : "";

    };

    const enduranceDesc = filter.values?.endurance?.duration ?
        `-  ${getLabelForValue(filter.values?.endurance?.duration, enduranceDuration)} ` :
        filter.values?.endurance?.distances ? `-  ${getLabelForValue(filter.values?.endurance?.distances, enduranceDistance)}` : ""

    const intervalDesc = filter.values?.interval?.duration ?
        `-  ${getLabelForValue(filter.values?.interval?.duration, intervalDuration)} ` :
        filter.values?.interval?.distances ? `-  ${getLabelForValue(filter.values?.interval?.distances, intervalDistance)}` : ""

    return useMemo(() => (

        <Row edge gutter={8}>

            <PeriodFilter data={data} filter={filter} />

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text={"Run Summary By Week"}
                    classes="seperator"
                />

                <PanelChart
                    heading={"Run Summary By Week"}
                    renderForm={(form, heading) => (

                        <Row edge gutter={8}>

                            <Col>
                                <FieldRadio
                                    form={form}
                                    name="runSummaryVolume"
                                    defaultValue={form.defaults?.runSummaryVolume}
                                    // label='metric'
                                    required
                                    options={[
                                        { label: "Hours", value: "Hours", color: ChartProps.indexColor(0) },
                                        { label: "KM", value: "KM", color: ChartProps.indexColor(1) },
                                        { label: "# of Runs", value: "# Of Runs", color: ChartProps.indexColor(2) },
                                    ]}
                                />
                            </Col>

                            <Col >
                                <FieldCheckbox
                                    form={form}
                                    name="runSummaryIntensity"
                                    defaultValue={form.defaults?.runSummaryIntensity}
                                    wrapClass={'white-checkbox'}
                                    options={[
                                        { label: "HR Hours > Zone 2", value: "HR Hours > Zone 2", color: ChartProps.metricColor("HR Hours > Zone 2") },
                                        { label: "W Hours > Zone 2", value: "W Hours > Zone 2", color: ChartProps.metricColor("W Hours > Zone 2") },
                                        { label: "Training Stimulus", value: "Training Stimulus", color: ChartProps.metricColor("Training Stimulus") },
                                    ]}
                                />

                            </Col>

                        </Row>

                    )}
                    addZero={false}
                    formDefaults={{ runSummaryVolume: 'Hours', runSummaryIntensity: ["HR Hours > Zone 2"] }}
                    renderChart={(chartType, chartData, values) => (
                        <ChartCoachingWorkoutRunSummary
                            data={trackingData?.weeks}
                            type={chartType}
                            activity={type}
                            filters={values}
                            intensity={data.intensity}
                        />
                    )}
                />

            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text="Weekly Run Summary"
                />

                <ReportDesc
                    className="no-box"
                    value={`Your total running statistics for each week are shown here. Select duration, mileage, intensity or workout count to view your changes over time. Targets met for each week are also given by symbols at the bottom of each column as well as overall averages for the time period selected.`}
                />

            </Col>

            <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                <CoachingReportWeeklyAverage
                    desc={unslug(filter.values?.period)}
                    columnA={[
                        { label: "Duration", value: getClockTime(trackingData?.averages?.duration / 60), suffix: "hh:mm" },
                        { label: "Mileage", value: fixedNum(trackingData?.averages?.distance, 1), suffix: "km" },
                        { label: "HR > Z2*", value: getClockTime(trackingData?.averages?.hrZone3 / 60), suffix: "hh:mm" },
                        { label: "Pace > Z2*", value: getClockTime(trackingData?.averages?.intensityDuration / 60), suffix: "hh:mm" }
                    ]}
                    columnANote="*Hours above Zone 2"
                    metTarget={trackingData?.averages?.distanceTargetMet}
                    belowTarget={trackingData?.averages?.distanceTargetAbove}
                    aboveTarget={trackingData?.averages?.distanceTargetBelow}
                    classes="mini"
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text={"Endurance Runs"}
                    classes="seperator"
                />

                <PanelChart
                    heading={"Endurance Runs"}
                    renderForm={(form, heading) => (

                        <Row edge gutter={8}>

                            <Col>
                                <FieldRadio
                                    form={form}
                                    name="runEnduranceDuration"
                                    defaultValue={form.defaults?.runEnduranceDuration}
                                    options={[
                                        { label: "Hours", value: "Hours", color: ChartProps.indexColor(0) },
                                        { label: "KM", value: "KM", color: ChartProps.indexColor(1) },
                                    ]}
                                />
                            </Col>

                            <Col >
                                <FieldCheckbox
                                    form={form}
                                    name="runIntervalMetric"
                                    defaultValue={form.defaults?.runIntervalMetric}
                                    wrapClass={'white-checkbox'}
                                    options={[
                                        { label: "Avg Pace", value: "Avg Pace", color: ChartProps.metricColor("Avg Pace") },
                                        { label: "Hrs Zone 2+", value: "Hrs Zone 2+", color: ChartProps.metricColor("Hrs Zone 2+") },
                                        { label: "Avg KPH", value: "Avg KPH", color: ChartProps.metricColor("Avg KPH") },
                                        { label: "Climbing M", value: "Climbing M", color: ChartProps.metricColor("Climbing M") },
                                        { label: "Avg HR", value: "Avg HR", color: ChartProps.metricColor("Avg HR") },
                                        { label: "Peak HR", value: "Peak HR", color: ChartProps.metricColor("Peak HR") },
                                    ]}
                                />

                            </Col>

                        </Row>

                    )}
                    addZero={false}
                    formDefaults={{ runEnduranceDuration: 'Hours', runIntervalMetric: ["Avg Pace"] }}
                    renderChart={(chartType, chartData, values) => (
                        <ChartCoachingWorkoutRunEndurance
                            data={trackingData?.endurances}
                            type={chartType}
                            filters={values}
                            activity={type}
                            intensity={data.intensity}
                        />
                    )}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Box
                    className='coaching-training-filters'
                    gutter={"auto"}
                >

                    <Row edge gutter={8}>

                        <Col col={{ xs: 6 }}>

                            <FieldSelect
                                form={filter}
                                name="endurance.mode"
                                defaultValue={filter.defaults?.endurance?.mode}
                                //@ts-ignore
                                options={[
                                    // { label: "All", value: "all" },
                                    { label: "Road", value: "road" },
                                    { label: "Off-Road", value: "off-road" },
                                    { label: "Trainer", value: "trainer" },
                                    { label: "Other", value: "other" }
                                ]}
                                label="Mode"
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 6 }}>

                            <FieldSelect
                                form={filter}
                                name="endurance.type"
                                defaultValue={filter.defaults?.endurance?.type}
                                options={[
                                    { label: "All", value: "all" },
                                    { label: "Training", value: "training" },
                                    { label: "Race Simulation", value: "race-simulation" },
                                    { label: "Event", value: "event" },
                                    { label: "Test", value: "test" },
                                    { label: "Other", value: "other" },
                                ]}
                                label="Type"
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12 }} />

                        <Col col={{ xs: 12 }}>

                            <FieldRadio
                                key={filter.defaults?.endurance?.duration}
                                form={filter}
                                name="endurance.duration"
                                defaultValue={filter.defaults?.endurance?.duration}
                                label='Duration'
                                wrapClass={'manual-input'}
                                onChange={handleChange.bind(null, "endurance.duration", "endurance.distances")}
                                options={enduranceDuration}
                            />

                        </Col>

                        <Col col={{ xs: 12 }}>

                            <FieldRadio
                                key={filter.defaults?.endurance?.distances}
                                form={filter}
                                name="endurance.distances"
                                defaultValue={filter.defaults?.endurance?.distances}
                                options={enduranceDistance}
                                label="Distances"
                                onChange={handleChange.bind(null, "endurance.distances", "endurance.duration")}
                                wrapClass={'manual-input'}
                            />

                        </Col>

                    </Row>

                </Box>

                <Space />

            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text="Endurance Runs"
                    desc={enduranceDesc}
                />

                <ReportDesc
                    className="no-box"
                    value={`This chart lists all the endurance or "Long" runs you have done thus far making it easier to see changes in your running fitness, volume or intensity over time. Select the measures you wish to view and filter down by time, distance or type.`}
                />

            </Col>

            <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                <CoachingReportWeeklyAverage
                    title="Average"
                    desc={unslug(filter.values?.period)}
                    columnA={[
                        { label: "Duration", value: getClockTime(trackingData?.enduranceAverages?.duration / 60), suffix: "hh:mm" },
                        { label: "Mileage", value: fixedNum(trackingData?.enduranceAverages?.distance, 1), suffix: "km" },
                        { label: "Speed", value: fixedNum(trackingData?.enduranceAverages?.avgSpeed, 1), suffix: "kph" },
                        { label: "Pace", value: getClockTime(trackingData?.enduranceAverages?.avgPace / 60), suffix: "min/km" },
                    ]}
                    columnANote="*HR is Avg and Peak"
                    columnB={[
                        { label: "HR*", value: trackingData?.enduranceAverages?.hrAvg + " / " + trackingData?.enduranceAverages?.hrPeak, suffix: "bpm" },
                        { label: "HR Z2+", value: getClockTime(trackingData?.enduranceAverages?.raceTime / 60), suffix: "hh:mm" },
                        { label: "Climbing", value: trackingData?.enduranceAverages?.elevation, suffix: "m" },
                        {}
                    ]}
                    columnBNote="+ HR Hours at or above Zone 2"
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text={"Interval Workouts - Run"}
                    classes="seperator"
                />

                <PanelChart
                    renderForm={(form, heading) => {

                        const handleIntervalChange = (name, resetName, value) => {

                            form.handleChange({ name: name, value: value, reset: true });

                            form.handleChange({ name: resetName, value: "", reset: true });

                        };

                        return (

                            <Row edge gutter={8}>

                                <Col >
                                    <FieldRadio
                                        key={form.defaults?.runIntervalDuration}
                                        form={form}
                                        name="runIntervalDuration"
                                        defaultValue={form.defaults?.runIntervalDuration}
                                        label='Duration'
                                        options={[
                                            { label: "Interval", value: "Interval", color: ChartProps.indexColor(0) },
                                            { label: "Total", value: "Total", color: ChartProps.indexColor(0) },
                                        ]}
                                        onChange={handleIntervalChange.bind(null, "runIntervalDuration", "runIntervalDistance")}
                                    />
                                </Col>

                                <Col>
                                    <FieldRadio
                                        key={form.defaults?.runIntervalDistance}
                                        form={form}
                                        name="runIntervalDistance"
                                        defaultValue={form.defaults?.runIntervalDistance}
                                        label='KM'
                                        options={[
                                            { label: "Interval", value: "Interval", color: ChartProps.indexColor(1) },
                                            { label: "Total", value: "Total", color: ChartProps.indexColor(3) },
                                        ]}
                                        onChange={handleIntervalChange.bind(null, "runIntervalDistance", "runIntervalDuration")}
                                    />
                                </Col>

                                <Col col={{ xs: 13 }}>
                                    <FieldCheckbox
                                        form={form}
                                        name="runIntervalMetric"
                                        defaultValue={form.defaults?.runIntervalMetric}
                                        wrapClass={'white-checkbox'}
                                        label='Metric'
                                        options={[
                                            { label: "Avg Pace", value: "Avg Pace", color: ChartProps.metricColor("Avg Pace") },
                                            { label: "Best Pace", value: "Best Pace", color: ChartProps.metricColor("Best Pace") },
                                            { label: "Avg KPH", value: "Avg KPH", color: ChartProps.metricColor("Avg KPH") },
                                            { label: "Best KPH", value: "Best KPH", color: ChartProps.metricColor("Best KPH") },
                                            { label: "Avg HR", value: "Avg HR", color: ChartProps.metricColor("Avg HR") },
                                            { label: "Peak HR", value: "Peak HR", color: ChartProps.metricColor("Peak HR") },
                                        ]}
                                    />

                                </Col>

                            </Row>

                        )
                    }}
                    addZero={false}
                    formDefaults={{ runIntervalDuration: 'Interval', runIntervalMetric: ["Avg Pace"] }}
                    renderChart={(chartType, chartData, values) => (
                        <ChartCoachingWorkoutRunInterval
                            data={trackingData?.intervals}
                            type={chartType}
                            filters={values}
                            activity={type}
                            intensity={data.intensity}
                        />
                    )}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Box
                    className='coaching-training-filters'
                    gutter={"auto"}
                >

                    <Row edge gutter={8}>

                        <Col col={{ xs: 6 }}>

                            <FieldSelect
                                form={filter}
                                name="interval.mode"
                                defaultValue={filter.defaults?.interval?.mode}
                                //@ts-ignore
                                options={[
                                    // { label: "All", value: "all" },
                                    { label: "Road", value: "road" },
                                    { label: "Off-Road", value: "off-road" },
                                    { label: "Trainer", value: "trainer" },
                                    { label: "Other", value: "other" }
                                ]}
                                label="Mode"
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 6 }}>

                            <FieldSelect
                                form={filter}
                                name="interval.type"
                                defaultValue={filter.defaults?.interval?.type}
                                options={[
                                    { label: "All", value: "all" },
                                    { label: "Training", value: "training" },
                                    { label: "Race Simulation", value: "race-simulation" },
                                    { label: "Event", value: "event" },
                                    { label: "Test", value: "test" },
                                    { label: "Other", value: "other" },
                                ]}
                                label="Type"
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 6 }}>

                            <FieldAutocomplete
                                form={filter}
                                label="Intervals"
                                name="interval.intervals"
                                defaultValue={filter.defaults?.interval?.intervals}
                                wrapClass={"manual-input"}
                                group='intervalsList'
                                disableCreate
                                preload
                                options={[
                                    { value: "Tempo-HR", label: "Tempo-HR" },
                                    { value: "Repeats", label: "Repeats" },
                                    { value: "Pyramids", label: "Pyramids" },
                                    { value: "Ladders", label: "Ladders" },
                                    { value: "Race Pace", label: "Race Pace" },
                                    { value: "Triple 3's", label: "Triple 3's" },
                                    { value: "Devil 6's", label: "Devil 6's" },
                                    { value: "Breakouts on Avg kph", label: "Breakouts on Avg kph" },
                                    { value: "1 min On/Offs", label: "1 min On/Offs" },
                                    { value: "30s On/Offs", label: "30s On/Offs" },
                                    { value: "40s:20s On/Offs", label: "40s:20s On/Offs" },
                                    { value: "Tempo-Sprint", label: "Tempo-Sprint" },
                                    { value: "Strength", label: "Strength" },
                                    { value: "Hills", label: "Hills" },
                                    { value: "Anaerobic Ladders", label: "Anaerobic Ladders" },
                                    { value: "Sprints - Short <15s)", label: "Sprints - Short <15s)" },
                                    { value: "Sprints - Long (15-30s)", label: "Sprints - Long (15-30s)" },
                                    { value: "Recovery", label: "Recovery" },
                                ]}
                                sort={false}
                            />

                        </Col>

                        <Col col={{ xs: 12 }}>

                            <FieldRadio
                                key={filter.defaults?.interval?.duration}
                                form={filter}
                                name="interval.duration"
                                defaultValue={filter.defaults?.interval?.duration}
                                label='Duration'
                                wrapClass={'manual-input'}
                                options={intervalDuration}
                                onChange={handleChange.bind(null, "interval.duration", "interval.distances")}
                            />

                        </Col>

                        <Col col={{ xs: 12 }}>

                            <FieldRadio
                                key={filter.defaults?.interval?.distances}
                                form={filter}
                                name="interval.distances"
                                defaultValue={filter.defaults?.interval?.distances}
                                options={intervalDistance}
                                label="Distances"
                                wrapClass={'manual-input'}
                                onChange={handleChange.bind(null, "interval.distances", "interval.duration")}
                            />

                        </Col>

                    </Row>

                </Box>

                <Space />

            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text="Run Interval Workouts"
                    desc={intervalDesc ? intervalDesc : ""}
                />

                <ReportDesc
                    className="no-box"
                    value={`Interval Workouts are those that were completed to achieve a specifc training effect usually consisting of intervals or repeat efforts. Select the measures your wish to view and filter down to see how you did on specific key workouts over tinme.`}
                />

            </Col>

            <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                <CoachingReportAverageTable
                    title="Interval Average"
                    desc={unslug(filter.values?.period)}
                    avgATitle="Interval/Total"
                    avgA={[
                        { title: "Duration", valueA: getClockTime(trackingData?.intervalAverages?.intervalDuration / 3600), valueB: getClockTime(trackingData?.intervalAverages?.totalDuration / 60, "h:mm:ss"), suffix: "h:mm:ss" },
                        { title: "Mileage", valueA: trackingData?.intervalAverages?.intervalDistance, valueB: trackingData?.intervalAverages?.totalDistance, suffix: "km" },
                    ]}
                    avgBTitle="Avg/Peak"
                    avgB={[
                        { title: "Pace", valueA: getClockTime(trackingData?.intervalAverages?.paceAvg / 60), valueB: getClockTime(trackingData?.intervalMax?.paceBest / 60), suffix: "min/km" },
                        { title: "Speed", valueA: trackingData?.intervalAverages?.speedAvg, valueB: trackingData?.intervalMax?.speedBest, suffix: "kph" },
                        { title: "Heartrate", valueA: trackingData?.intervalAverages?.hrAvg, valueB: trackingData?.intervalMax?.hrBest, suffix: "bpm" },
                    ]}
                />

            </Col>

        </Row>

    ), [data, form.hash, trackingData, filter.hash]);

}

export default CoachingReportWorkoutsRun;