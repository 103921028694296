import SinglePage from '@dex/bubl-dash/src/lib/pages/SinglePage';
import GaitsSingleView from './Components/GaitsSingleView';
import GaitsSingleEdit from './Components/GaitsSingleEdit';
import GaitReport from './Report/GaitReport';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "View",
        zone: settings.zone,
        path: settings.key + "/:id/view",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
                getButtons: settings.singleButtons,
            },
            page: GaitsSingleView,
        },
    });

    app.addRoute({
        name: settings.key + "Edit",
        zone: settings.zone,
        path: settings.key + "/:id/edit/:tab",
        caps: settings.updateCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getButtons: settings.singleButtons,
                getTabs: settings.singleTabs
            },
            page: GaitsSingleEdit,
        },
    });

    app.addRoute({
        name: settings.key + "Report",
        zone: settings.zone,
        path: "gait/report/:id",
        caps: settings.updateCaps,
        component: SinglePage,
        public: true,
        ribbon: false,
        sidebar: false,
        componentProps: {
            getFetch: settings.reportFetch,
            getFetchParams: settings.reportFetchParams,
            header: null,
            page: GaitReport,
        },
    });
}