import { Col, FieldAutocomplete, FieldNumber, FieldSelect, FieldTextArea, FieldTextTime, Row, Space, useUpdateEffect } from '@dex/bubl-dash';
import { hashCode } from '@dex/bubl-helpers';
import React, { useMemo } from 'react';
import { getEventHour, sweatTestDehydration, sweatTestRehydration } from '../../../functions/sweatTest';
import ChartDehydration from '../../../elements/ChartLabTests/ChartDehydration';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import DehydrationSummary from '../../../elements/DehydrationSummary/DehydrationSummary';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';

const LabTestsSweatDehydration: React.FC<LabTestsSweatDehydrationProps> = (props) => {

    const { form, data, session, isReport } = props;

    const {
        chartData, lowCritical, highCritical, intersectLow, intersectHigh,
        getFluidIntakeRateLow, getFluidIntakeRateHigh,
        rehydrationData, rehydrationLow, rehydrationHigh, fluidLossRate,
        climate, event, hours, weight, targetTime,
    } = sweatTestDehydration(form, data);

    return useMemo(() => (

        <>

            <Row gutter={8} edge>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldSelect
                        form={form}
                        name='dehydration.climate'
                        defaultValue={form.defaults.dehydration?.climate}
                        label='Climate/Intensity'
                        options={[
                            { label: "Cool/easy", value: "cool" },
                            { label: "Warm/Moderate", value: "warm" },
                            { label: "Hot/Hard", value: "hot" },
                            { label: "Custom", value: "custom" },
                        ]}
                        wrapClass={"manual-input w-200"}
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name='dehydration.fluidLossRate'
                        defaultValue={fluidLossRate}
                        key={climate}
                        dynamicKey
                        label='Fluid Loss Rate"'
                        wrapClass={climate !== "custom" ? "w-200" : "manual-input w-200"}
                        disabled={climate !== "custom" ? true : false}
                        defer={1000}
                        suffix=" ml/hr"
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name='dehydration.rehydrationLow'
                        defaultValue={rehydrationLow}
                        wrapClass={"manual-input w-200"}
                        label='Rehydration Rate (Low)'
                        suffix=' %'
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name='dehydration.fluidIntakeRateLow'
                        defaultValue={getFluidIntakeRateLow}
                        key={getFluidIntakeRateLow}
                        label="Intake Rate (Low)"
                        suffix=" ml/hr"
                        disabled
                        wrapClass={'w-200'}
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldSelect
                        form={form}
                        name='dehydration.event'
                        defaultValue={form.defaults.dehydration?.event}
                        label='Event'
                        options={[
                            { label: "IM Triathlon", value: "IM Triathlon" },
                            { label: "70.3 Triathlon", value: "70.3 Triathlon" },
                            { label: "Olympic Triathlon", value: "Olympic Triathlon" },
                            { label: "Sprint Marathon", value: "Sprint Marathon" },
                            { label: "Marathon", value: "Marathon" },
                            { label: "Half Marathon", value: "Half Marathon" },
                            { label: "10km Run", value: "10km Run" },
                            { label: "5km Run", value: "5km Run" },
                            { label: "Long Bike Race", value: "Long Bike Race" },
                            { label: "Short Bike Race", value: "Short Bike Race" },
                            { label: "Ultra Endurance", value: "Ultra Endurance" },
                            { label: "Custom", value: "custom" },
                        ]}
                        wrapClass={"manual-input w-200"}
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name='dehydration.duration'
                        defaultValue={getEventHour(event)}
                        wrapClass={event !== "custom" ? "w-200" : "manual-input w-200"}
                        key={event}
                        disabled={event !== "custom" ? true : false}
                        label='Duration'
                        suffix=" hrs"
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name='dehydration.rehydrationHigh'
                        defaultValue={rehydrationHigh}
                        wrapClass={"manual-input w-200"}
                        label='Rehydration Rate (High)'
                        suffix=' %'
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name='dehydration.fluidIntakeRate'
                        defaultValue={getFluidIntakeRateHigh}
                        key={getFluidIntakeRateHigh}
                        disabled
                        label='Intake Rate (High)'
                        suffix=" ml/hr"
                        wrapClass={'w-200'}
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldTextTime
                        form={form}
                        name='dehydration.targetTime'
                        defaultValue={form.values.dehydration?.targetTime}
                        label='Target Time'
                        wrapClass={'w-200 manual-input'}
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <Space />

                    <PanelChart
                        key={hashCode(chartData)}
                        heading={"Dehydration Over Time"}
                        addZero={false}
                        data={chartData}
                        chartKey={form.hash}
                        renderChart={(chartType, chartData) => (
                            <>
                                <ChartDehydration
                                    data={chartData}
                                    form={form}
                                    duration={form.values.dehydration?.duration}
                                    targetTime={targetTime}
                                    criticalLow={lowCritical}
                                    criticalHigh={highCritical}
                                    intersectLow={intersectLow}
                                    intersectHigh={intersectHigh}
                                    fluidIntakeRateHigh={getFluidIntakeRateHigh}
                                    fluidIntakeRateLow={getFluidIntakeRateLow}
                                />
                            </>

                        )}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    {isReport &&
                        <ReportDesc
                            label="Critical Dehydration"
                            value={form.defaults?.dehydration?.criticalDescription}
                        />
                    }
                    {!isReport &&
                        <FieldTextArea
                            form={form}
                            name='dehydration.criticalDescription'
                            defaultValue={form.defaults?.dehydration?.criticalDescription}
                            label='Critical Dehydration Description'
                        />
                    }

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <DehydrationSummary
                        fluidLossRate={fluidLossRate}
                        fluidIntakeRateLow={getFluidIntakeRateLow}
                        fluidIntakeRateHigh={getFluidIntakeRateHigh}
                        criticalLow={lowCritical}
                        criticalHigh={highCritical}
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, data])

}

interface LabTestsSweatDehydrationProps {
    [key: string]: any,
}

export default LabTestsSweatDehydration;
