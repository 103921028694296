import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, ButtonRow, FieldCheckbox, FieldNumber, FieldSelect, FieldSwitch, FieldTable, FieldText, FieldTextArea, FieldTextTime, Form, Heading, InputTableCell, InputTableHead, InputTableHeadCell, InputTableHeadRow, Line, MetaTable, Seperator, Space, useUpdateEffect } from '@dex/bubl-dash';
import { clone, date, fixedNum, get, hashCode, loop, parseNum, set, titleCase } from '@dex/bubl-helpers';
import { getDecimalTime, getPaceFromKph, getSpeed } from '../../../functions/sharedTests';
import CoachingTrainingGenericForm from './CoachingTrainingGenericForm';
import { generateDateOptions, getWeek } from '../../../functions/sharedCoaching';
import CoachingTrainingsBestProvider, { CoachingTrainingsIconBest, CoachingTrainingsIconTop5, CoachingTrainingsIsBest } from './CoachingTrainingsBestProvider';
import CoachingApi from '../CoachingApi';

const CoachingTrainingEnduranceForm: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit, mode, del, handleClose } = props;

    const [show, setShow] = useState(false);

    const [dateOptions, setDateOptions] = useState([]);

    const { week, block, weeks } = getWeek(form, data);

    useEffect(() => {

        if (!week || !block) return;

        setDateOptions(generateDateOptions(week.startDate, week.endDate));

        if (week.week && week.week === form.values.week?.week) return;
        if (week.blockNum && week.blockNum === form.values.week?.blockNum) return;

        form.handleChange({ name: "week.phase", value: block.phase, reset: true })
        form.handleChange({ name: "week.block", value: block.block, reset: true })
        form.handleChange({ name: "week.blockNum", value: block.blockNum, reset: true })
        form.handleChange({ name: "week.week", value: week.week, reset: true })
        form.handleChange({ name: "week.startDate", value: week.startDate, reset: true })
        form.handleChange({ name: "week.endDate", value: week.endDate, reset: true })
        form.handleChange({ name: "week.eventId", value: week.event, reset: true })

    }, [week, block])

    const activity = form.values?.activity;

    useUpdateEffect(() => {

        const duration = form.values.duration;
        const distance = parseNum(form.values.distance);
        const avgSpeed = parseNum(form.values.avgSpeed);
        const fluidIntakeVolume = parseNum(form.values.fluidIntakeVolume);
        const weightLoss = parseNum(form.values.weightLoss);
        const fluidLossTotal = parseNum(form.values.fluidLossTotal);
        const foodIntake = parseNum(form.values.foodIntake);
        const carbIntakeTotal = parseNum(form.values.carbIntakeTotal);

        form.handleChange({ name: "avgSpeed", value: getSpeed(distance, duration), reset: true })
        form.handleChange({ name: "avgPace", value: getPaceFromKph(avgSpeed), reset: true })
        form.handleChange({ name: "fluidIntakeRate", value: fixedNum(fluidIntakeVolume / (getDecimalTime(duration)), 0), reset: true })
        form.handleChange({ name: "fluidLossTotal", value: fixedNum((weightLoss * 1000) + fluidIntakeVolume - foodIntake, 0), reset: true })
        form.handleChange({ name: "fluidIntakeRehydrate", value: fixedNum((fluidIntakeVolume / fluidLossTotal) * 100, 0), reset: true })
        form.handleChange({ name: "fluidLossRate", value: fixedNum(getSpeed(fluidLossTotal, duration), 0), reset: true })
        form.handleChange({ name: "carbIntakePercent", value: fixedNum((carbIntakeTotal / foodIntake) * 100, 0), reset: true })
        form.handleChange({ name: "carbIntakeRate", value: fixedNum(carbIntakeTotal / getDecimalTime(duration), 0), reset: true })

    }, [form.hash])

    useUpdateEffect(() => {

        if (activity == "bike") {
            form.handleChange({ reset: true, name: "mode", value: "trainer" })
            form.handleChange({ reset: true, name: "type", value: "training" })
        } else if (activity == "run") {
            form.handleChange({ reset: true, name: "mode", value: "road" })
            form.handleChange({ reset: true, name: "type", value: "training" })
        }

    }, [activity])

    const types: any = [
        activity == "swim" || activity == "all" ? { name: "swim", icon: "stimulusSwim" } : null,
        activity == "bike" || activity == "all" ? { name: "bike", icon: "stimulusBike" } : null,
        activity == "run" || activity == "all" ? { name: "run", icon: "stimulusRun" } : null,
    ].filter(Boolean);

    const highlights = CoachingApi.format(data.id, 'getHighlights');

    const calculateHighlightsTop = (values) => {

        highlights.run({
            params: {
                type: "Endurance",
                values: values,
                endDate: form.values.workoutDate
            },
            onComplete: (response) => {

                form.handleChange({ name: "highlights", value: response, reset: true })

            },
        });

    };

    return (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <CoachingTrainingGenericForm
                form={form}
                data={data}
                mode={mode}
                weeks={weeks}
                week={week}
                block={block}
            />

            {week && week.week &&

                <CoachingTrainingsBestProvider
                    type={"Endurance"}
                    data={data}
                    form={form}
                    week={week}
                    endDate={form.values.workoutDate || week?.endDate}
                    activity={activity}
                    values={form.values}
                >

                    <Seperator
                        heading={<Row edge >
                            <Col col={{ xs: 16 }} >
                                {"Week " + week.calendar + " Data"}
                            </Col>

                            <Col col={{ xs: 8 }} align='end'>
                                <ButtonRow>
                                    <Button
                                        label={"Save"}
                                        loading={process.loading}
                                        onClick={form.triggerSubmit}
                                        htmlType={"button"}
                                        size="regular"
                                    />

                                    <Button
                                        label={"Cancel"}
                                        onClick={() => handleClose()}
                                        type='light'
                                        size="regular"
                                    />
                                </ButtonRow>
                            </Col >
                        </Row>}
                    />

                    <Space />

                    <Row edge gutter={8}>

                        <Col col={{ xs: 12, lg: 6 }}>

                            <FieldSelect
                                form={form}
                                name="activity"
                                defaultValue={form.defaults?.activity}
                                options={[
                                    { label: "Swim", value: "swim" },
                                    { label: "Bike", value: "bike" },
                                    { label: "Run", value: "run" },
                                    { label: "Gym", value: "gym" },
                                    { label: "Other", value: "other" },
                                ]}
                                label="Activity"
                                required
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 12, lg: 6 }}>

                            <FieldSelect
                                form={form}
                                name="mode"
                                defaultValue={form.defaults?.mode}
                                //@ts-ignore
                                options={[
                                    // { label: "All", value: "all" },
                                    activity == "all" || activity == "bike" || activity == "run" ? { label: "Road", value: "road" } : null,
                                    activity == "all" || activity == "bike" || activity == "run" ? { label: "Off-Road", value: "off-road" } : null,
                                    activity == "all" || activity == "bike" || activity == "run" ? { label: "Trainer", value: "trainer" } : null,
                                    activity == "all" || activity == "run" ? { label: "Treadmill", value: "treadmill" } : null,
                                    activity == "all" || activity == "swim" ? { label: "Pool", value: "pool" } : null,
                                    activity == "all" || activity == "swim" ? { label: "Pool - 25m", value: "pool-25m" } : null,
                                    activity == "all" || activity == "swim" ? { label: "Pool - 50m", value: "pool-50m" } : null,
                                    activity == "all" || activity == "swim" ? { label: "Open Water - Fresh", value: "open water-fresh" } : null,
                                    activity == "all" || activity == "swim" ? { label: "Open Water - Salt", value: "open water-salt" } : null,
                                    { label: "Other", value: "other" }
                                ].filter(Boolean)}
                                label="Mode"
                                required
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 12, lg: 6 }}>

                            <FieldSelect
                                form={form}
                                name="type"
                                defaultValue={form.defaults?.type}
                                options={[
                                    // { label: "All", value: "all" },
                                    { label: "Training", value: "training" },
                                    { label: "Race Simulation", value: "race-simulation" },
                                    { label: "Event", value: "event" },
                                    { label: "Test", value: "test" },
                                    { label: "Other", value: "other" },
                                ]}
                                label="Type"
                                required
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 12, lg: 6 }}>

                            {dateOptions.length > 0 &&
                                <FieldSelect
                                    form={form}
                                    name="workoutDate"
                                    defaultValue={date(form.defaults?.workoutDate, "YYYY-MM-DD")}
                                    options={dateOptions}
                                    label="Workout Date"
                                    required
                                    wrapClass={"manual-input"}
                                />
                            }

                        </Col>

                    </Row>

                    <Row edge gutter={28}>

                        <Col col={{ xs: 6, lg: 4 }}>

                            <Heading space={"small"} size={4} className={"text-center"}>Duration & Distance</Heading>

                            <Row edge gutter={8}>

                                <Col col={{ xs: 12 }}>

                                    <FieldTextTime
                                        form={form}
                                        name="duration"
                                        defaultValue={form.defaults.duration}
                                        wrapClass={"manual-input"}
                                        label="Duration"
                                        defer={500}
                                    />

                                    <CoachingTrainingsIsBest
                                        name={`duration`}
                                    />

                                </Col>

                                <Col col={{ xs: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        name="distance"
                                        defaultValue={form.defaults.distance}
                                        wrapClass={"manual-input"}
                                        label="Distance"
                                        suffix=" km"
                                        defer={500}
                                    />

                                    <CoachingTrainingsIsBest
                                        name={`distance`}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 6, lg: 4 }}>
                            <Heading space={"small"} size={4} className={"text-center"}>Avg Speed & Pace</Heading>

                            <Row edge gutter={8}>

                                <Col col={{ xs: 12 }}>

                                    <FieldNumber
                                        dynamicKey
                                        form={form}
                                        name="avgSpeed"
                                        defaultValue={form.defaults.avgSpeed}
                                        disabled
                                        label="Avg Speed"
                                        suffix=" kph"
                                    />

                                    <CoachingTrainingsIsBest
                                        name={`avgSpeed`}
                                    />

                                </Col>

                                <Col col={{ xs: 12 }}>

                                    <FieldTextTime
                                        dynamicKey
                                        form={form}
                                        name="avgPace"
                                        defaultValue={form.defaults.avgPace}
                                        disabled
                                        label="Avg Pace"
                                        placeholder=" mm:ss"
                                    />

                                    {/* <CoachingTrainingsIsBest
                                    name={`avgPace`}
                                /> */}

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 6, lg: 4 }}>
                            <Heading space={"small"} size={4} className={"text-center"}>Power</Heading>

                            <Row edge gutter={8}>

                                <Col col={{ xs: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        name="powerAvg"
                                        defaultValue={form.defaults.powerAvg}
                                        label="Avg"
                                        suffix=" W"
                                        wrapClass={"manual-input"}
                                        disabled={activity == "run"}
                                    />

                                    <CoachingTrainingsIsBest
                                        name={`powerAvg`}
                                    />

                                </Col>

                                <Col col={{ xs: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        name="powerNorm"
                                        defaultValue={form.defaults.powerNorm}
                                        label="Norm"
                                        suffix=" W"
                                        wrapClass={"manual-input"}
                                        disabled={activity == "run"}
                                    />

                                    <CoachingTrainingsIsBest
                                        name={`powerNorm`}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 6, lg: 4 }}>

                            <Heading space={"small"} size={4} className={"text-center"}>HR</Heading>

                            <Row edge gutter={8}>

                                <Col col={{ xs: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        name="hrAvg"
                                        defaultValue={form.defaults.hrAvg}
                                        label="HR Avg"
                                        suffix=" bpm"
                                        wrapClass={"manual-input"}
                                    />

                                    <CoachingTrainingsIsBest
                                        name={`hrAvg`}
                                    />

                                </Col>

                                <Col col={{ xs: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        name="hrPeak"
                                        defaultValue={form.defaults.hrPeak}
                                        label="HR Peak"
                                        suffix=" bpm"
                                        wrapClass={"manual-input"}
                                    />

                                    <CoachingTrainingsIsBest
                                        name={`hrPeak`}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 6, lg: 4 }}>

                            <Heading space={"small"} size={4} className={"text-center"}>Time & Elevation</Heading>

                            <Row edge gutter={8}>

                                <Col col={{ xs: 12 }}>

                                    <FieldTextTime
                                        form={form}
                                        name="raceTime"
                                        defaultValue={form.defaults.raceTime}
                                        label="Race Time"
                                        wrapClass="manual-input"
                                    />

                                    <CoachingTrainingsIsBest
                                        name={`raceTime`}
                                    />

                                </Col>

                                <Col col={{ xs: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        name="elevation"
                                        defaultValue={form.defaults.elevation}
                                        label="Elevation"
                                        suffix=" m"
                                        wrapClass={"manual-input"}
                                    />

                                    <CoachingTrainingsIsBest
                                        name={`elevation`}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 6, lg: 4 }} justify='end'>


                            <FieldCheckbox
                                form={form}
                                name={"fuelHydration"}
                                defaultValue={form.defaults.fuelHydration}
                                label={"Enable Fuel/Hydration"}
                                singular={true}
                                options={[
                                    { label: "Yes", value: "yes" },
                                ]}
                            />

                        </Col>

                    </Row>

                    {form.values.fuelHydration === "yes" &&
                        <Row edge gutter={28}>

                            <Col col={{ xs: 2 }}>

                                <Heading space={"small"} size={4} className={"text-center"}>Weight Loss</Heading>

                                <Row edge gutter={8}>

                                    <Col col={{ xs: 24 }}>

                                        <FieldNumber
                                            form={form}
                                            name="weightLoss"
                                            defaultValue={form.defaults.weightLoss}
                                            label="Weight Loss"
                                            suffix=" kg"
                                            wrapClass={"manual-input"}
                                        />

                                    </Col>

                                </Row>

                            </Col>

                            <Col col={{ xs: 6 }}>

                                <Heading space={"small"} size={4} className={"text-center"}>Fluid Intake</Heading>

                                <Row edge gutter={8}>

                                    <Col col={{ xs: 8 }}>

                                        <FieldNumber
                                            form={form}
                                            name="fluidIntakeVolume"
                                            defaultValue={form.defaults.fluidIntakeVolume}
                                            label="Volume"
                                            suffix=" ml"
                                            wrapClass={"manual-input"}
                                        />

                                    </Col>

                                    <Col col={{ xs: 8 }}>

                                        <FieldNumber
                                            form={form}
                                            name="fluidIntakeRate"
                                            defaultValue={form.defaults.fluidIntakeRate}
                                            key={form.values.fluidIntakeRate}
                                            label="Rate"
                                            suffix=" ml/hr"
                                            disabled
                                        />

                                    </Col>

                                    <Col col={{ xs: 8 }}>

                                        <FieldNumber
                                            form={form}
                                            name="fluidIntakeRehydrate"
                                            defaultValue={form.defaults.fluidIntakeRehydrate}
                                            key={form.values.fluidIntakeRehydrate}
                                            label="%Rehydrate"
                                            suffix=" %"
                                            disabled
                                        />

                                    </Col>

                                </Row>

                            </Col>

                            <Col col={{ xs: 4 }}>

                                <Heading space={"small"} size={4} className={"text-center"}>Fluid Loss</Heading>

                                <Row edge gutter={8}>

                                    <Col col={{ xs: 12 }}>

                                        <FieldNumber
                                            form={form}
                                            name="fluidLossTotal"
                                            defaultValue={form.defaults.fluidLossTotal}
                                            key={form.values.fluidLossTotal}
                                            label="Total"
                                            suffix=" ml"
                                            disabled
                                        />

                                    </Col>

                                    <Col col={{ xs: 12 }}>

                                        <FieldNumber
                                            form={form}
                                            name="fluidLossRate"
                                            defaultValue={form.defaults.fluidLossRate}
                                            key={form.values.fluidLossRate}
                                            label="Rate"
                                            suffix=" ml/hr"
                                            disabled
                                        />

                                    </Col>

                                </Row>

                            </Col>

                            <Col col={{ xs: 2 }}>

                                <Heading space={"small"} size={4} className={"text-center"}>Food Intake</Heading>

                                <Row edge gutter={8}>

                                    <Col col={{ xs: 24 }}>

                                        <FieldNumber
                                            form={form}
                                            name="foodIntake"
                                            defaultValue={form.defaults.foodIntake}
                                            label="Total"
                                            suffix=" g"
                                            wrapClass={"manual-input"}
                                        />

                                    </Col>

                                </Row>

                            </Col>


                            <Col col={{ xs: 6 }}>

                                <Heading space={"small"} size={4} className={"text-center"}>Carb Intake</Heading>

                                <Row edge gutter={8}>

                                    <Col col={{ xs: 8 }}>

                                        <FieldNumber
                                            form={form}
                                            name="carbIntakeTotal"
                                            defaultValue={form.defaults.carbIntakeTotal}
                                            label="Total"
                                            suffix=" g"
                                            wrapClass={"manual-input"}
                                        />

                                    </Col>

                                    <Col col={{ xs: 8 }}>

                                        <FieldNumber
                                            form={form}
                                            name="carbIntakePercent"
                                            defaultValue={form.defaults.carbIntakePercent}
                                            key={form.values.carbIntakePercent}
                                            label="%Carbs"
                                            suffix=" %"
                                            disabled
                                        />

                                    </Col>

                                    <Col col={{ xs: 8 }}>

                                        <FieldNumber
                                            form={form}
                                            name="carbIntakeRate"
                                            defaultValue={form.defaults.carbIntakeRate}
                                            key={form.values.carbIntakeRate}
                                            label="Rate"
                                            suffix=" g/hr"
                                            disabled
                                        />

                                    </Col>

                                </Row>

                            </Col>

                        </Row>
                    }

                    <Row edge gutter={28}>

                        <Col col={{ xs: 12 }}>

                            <FieldTextArea
                                form={form}
                                name='location'
                                defaultValue={form.defaults.location}
                                rows={2}
                                label='Location'
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12 }}>

                            <FieldTextArea
                                form={form}
                                name='remarks'
                                defaultValue={form.defaults.remarks}
                                rows={4}
                                label='Remarks'
                                wrapClass={'manual-input'}
                            />

                        </Col>

                    </Row>

                </CoachingTrainingsBestProvider>

            }

            <Line />

            <Row edge gutter={8}>

                <Col col={{ xs: 21 }}>

                    <ButtonRow>

                        <Button
                            label={"Save"}
                            loading={process.loading}
                            onClick={form.triggerSubmit}
                            htmlType={"button"}
                        />

                        <Button
                            label={"Cancel"}
                            onClick={() => handleClose()}
                            type='faded'
                        />

                    </ButtonRow>

                </Col>

                {mode == "edit" &&
                    <Col col={{ xs: 3 }} align='end'>
                        <Button
                            confirm={{
                                title: `Delete ${form.values.id}`,
                                message: "Are your sure you want to perform this action?"
                            }}
                            onClick={del.run}
                            label="Delete"
                            type='faded'
                        />
                    </Col>
                }

            </Row>

        </Form>

    )
}

export default CoachingTrainingEnduranceForm
