import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Space } from '@dex/bubl-dash';
import { clone, loop, parseNum, titleCase } from '@dex/bubl-helpers';

import styles from "./TestSessionsReport.module.scss";
import TestSessionsReportMenu from '../../TestSessions/Report/TestSessionsReportMenu';
import GaitReportPage from './GaitReportPage';
import GaitReportCover from './GaitReportCover';
import GaitReportRunner from './GaitReportRunner';
import GaitReportPosture from './GaitReportPosture';
import GaitReportStability from './GaitReportStability';
import GaitReportSummary from './GaitReportSummary';
import GaitReportExercises from './GaitReportExercises';
import GaitReportShoe from './GaitReportShoe';
import GaitReportAnalysis from './GaitReportAnalysis';
import GaitReportMetric from './GaitReportMetric';
import GaitReportPhotos from './GaitReportPhotos';
import GaitReportRating from './GaitReportRating';
import GaitReportAnalysisStance from './GaitReportAnalysisStance';

const GaitReportPages: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const [activePage, setActivePage] = useState(0);

    const observer: any = useRef();

    const getPages = () => {

        let pages: any = [];

        if (data.reportPages.includes("analysis")) data.reportPages.push("analysis-stance")

        let all: any = [
            {
                key: "cover",
                label: "Cover",
                heading: false,
                el: GaitReportCover,
                ref: useRef()
            },
            {
                key: "runner",
                label: "Runner Information",
                heading: "Runner Information",
                el: GaitReportRunner,
                ref: useRef()
            },
            {
                key: "posture",
                label: "Posture/Foot",
                heading: "Runner Posture/Foot",
                el: GaitReportPosture,
                ref: useRef()
            },
            {
                key: "stability",
                label: "Stability, Mobility & Symmetry",
                heading: "Stability, Mobility & Symmetry",
                el: GaitReportStability,
                ref: useRef()
            },
            {
                key: "shoes",
                label: "Shoes",
                heading: "Shoes",
                el: GaitReportShoe,
                ref: useRef()
            },
            {
                key: "analysis",
                label: "Running Analysis",
                heading: "Running Analysis",
                el: GaitReportAnalysis,
                ref: useRef()
            },
            {
                key: "analysis-stance",
                label: "Running Stance Analysis",
                heading: "Running Stance Analysis",
                el: GaitReportAnalysisStance,
                ref: useRef()
            },
            {
                key: "metric",
                label: "Running Metrics",
                heading: "Running Metrics",
                el: GaitReportMetric,
                ref: useRef()
            },
            {
                key: "rating",
                label: "Rating And Changes",
                heading: "Rating And Changes",
                el: GaitReportRating,
                ref: useRef()
            },
            {
                key: "photos",
                label: "Photos",
                heading: "Photos",
                el: GaitReportPhotos,
                ref: useRef()
            },
            {
                key: "summary",
                label: "Summary",
                heading: "Summary",
                el: GaitReportSummary,
                ref: useRef()
            },
            {
                key: "exercises",
                label: "Exercises",
                heading: "Exercises",
                el: GaitReportExercises,
                ref: useRef()
            },
        ].filter((page) => {

            if (!data.reportPages || !data.reportPages.length) return true;

            return data.reportPages.includes(page.key) ? true : false;

        });

        let pageSummary: any;
        let pageExercises: any;

        let pageShoes: any;
        let pageRunAnalytics: any;
        let pageRunStanceAnalytics: any;
        let pageRunMetrics: any;
        let pageRunRating: any;
        let pageRunPhotos: any;

        loop(all, (page, index) => {

            if (page.key === "shoes") return pageShoes = page;
            if (page.key === "analysis") return pageRunAnalytics = page;
            if (page.key === "analysis-stance") return pageRunStanceAnalytics = page;
            if (page.key === "metric") return pageRunMetrics = page;
            if (page.key === "rating") return pageRunRating = page;
            if (page.key === "photos") return pageRunPhotos = page;
            if (page.key === "summary") return pageSummary = page;
            if (page.key === "exercises") return pageExercises = page;

            pages.push(page);

        });

        if (pageShoes) {

            loop(data.report.shoes, (index) => {

                const shoe = data.shoes[index];

                if (!shoe) return;

                pages.push({
                    ...pageShoes,
                    label: pageShoes.label + " - " + shoe.shoeBrand,
                    shoe: shoe,
                    index: index,
                    ref: useRef(),
                });

            });

        }

        loop(data.report.runs, (index) => {

            if (!pageRunAnalytics && !pageRunStanceAnalytics && !pageRunMetrics && !pageRunRating && !pageRunPhotos) return;

            const run = data.runs[index];

            if (!run) return;

            pages.push({
                sep: true,
                label: "Run - " + run.condition,
            });

            [pageRunAnalytics, pageRunStanceAnalytics, pageRunMetrics, pageRunRating, pageRunPhotos].map((page) => {

                if (!page) return;

                pages.push({
                    ...page,
                    label: page.label,
                    run: run,
                    index: index,
                    ref: useRef(),
                });

            });

        });

        if (pageSummary) pages.push(pageSummary);
        if (pageExercises) pages.push(pageExercises);

        return pages;

    };

    const pages = getPages();

    useEffect(() => {

        const callback = (entries, observer) => {

            let inViewTarget: any = null;
            let inViewRatio = 0;

            entries.forEach((entry, index) => {

                if (entry.isIntersecting && entry.intersectionRatio > inViewRatio) {

                    inViewRatio = entry.intersectionRatio;
                    inViewTarget = entry.target;

                }

            })

            if (inViewTarget) setActivePage(parseNum(inViewTarget.id));

        };

        const observer = new IntersectionObserver(callback, {
            threshold: .4,
        });

        pages.map((page) => {

            if (page.ref && page.ref.current) {

                observer.observe(page.ref.current);

            }

        });

        () => {

            pages.map((page) => {

                observer.unobserve(page.ref.current);

            });

            observer.disconnect();

        }

    }, []);

    const handleScrollTo = useCallback((ref, event) => {

        if (!ref || !ref.current) return;

        if (event && event.preventDefault) event.preventDefault();

        ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

    }, []);

    return useMemo(() => (

        <>

            <TestSessionsReportMenu
                data={data}
                activePage={activePage}
                pages={pages}
                handleScrollTo={handleScrollTo}
                form={form}
            />

            {pages.map((page, index) => (

                <React.Fragment key={index}>

                    {index >= 0 && page.el &&
                        <Space />
                    }

                    {page.el &&

                        <GaitReportPage
                            heading={page.heading}
                            icon={page.icon}
                            index={index}
                            observer={observer}
                            forwardedRef={page.ref}
                            setActivePage={setActivePage}
                            form={form}
                            data={data}
                        >

                            {page.el &&
                                <page.el
                                    data={data}
                                    form={form}
                                    index={page.index}
                                    run={page.run}
                                    shoe={page.shoe}
                                />
                            }

                        </GaitReportPage>
                    }

                </React.Fragment>

            ))}

        </>

    ), [data, activePage]);

}

export default GaitReportPages;