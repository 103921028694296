import { Col, FieldRadio, Row } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import { fixedNum, get, loop } from '@dex/bubl-helpers';
import { calculateMinPerKm } from '../../../functions/sharedTests';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartPerformanceTrackingThreshold from '../../../elements/ChartTestSessionReport/ChartPerformanceTrackingThreshold';
import * as _ from 'lodash';
import styles from "./TestSessionsReportPerformanceTrackingThreshold.module.scss";
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import { chartRegressionPolynomial, labAnalysisRegresionCharts } from '../../../functions/chartRegression';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';

const TestSessionsReportPerformanceTrackingThreshold: React.FC<any> = (props: any) => {

    const { data, form } = props;

    let gender = data.athlete?.gender;
    const activity: string = data.test.activity;
    const intensity = data?.test?.intensity;

    const zones = data.test.lactate.zones;
    const anaerobic = zones["4"] || {};
    const ftp = get(data.test, `lactate.measures[0]`);
    const vo2Max = get(data.test, `lactate.measures[1]`);
    const [index, setIndex] = useState(data.history[0].date);
    const anaerobicPerKg = fixedNum(vo2Max?.vo2max_speed / data.bio?.weight, 1);

    const trackingUse = get(form.values, `threshold.trackingUse`, "HR");

    let options;
    let summary;
    let description;

    if (trackingUse == "Power") {

        options = [
            { label: "Watts", name: "Watts", data: data.history },
            { label: "Watts/Kg", name: "WattsPerKg", data: data.history }
        ]
        summary = 'maxVo2Speed'
        description = "This is the maximum average power you can sustain for 40 to 60 minutes and is closely related to your FTP (Functional Threshold Power). The higher this is, the stronger you are"

    } else if (trackingUse == "Speed") {

        options = [
            { label: "kph", name: "speed", data: data.history },
            { label: "min/km", name: "pace", data: data.history }
        ]
        summary = 'maxVo2Speed'
        description = "This is the maximum speed you can hold for 40 to 60 minutes. The higher this is, the faster you are!"

    } else if (trackingUse == "VO2") {

        options = [
            { label: "ml/kg*min", name: "relative", data: data.history },
            { label: "%Vo2max", name: "percent", data: data.history }
        ]
        summary = "vo2RelativeMax"
        description = "This is your oxygen consumption at Threshold. Generally speaking, the higher this is relative to your VO2max (i.e. higher %VO2max), the faster you will be at threshold!"

    } else if (trackingUse == "Carb Burn" || trackingUse == "Fat Burn") {

        options = [
            { label: "g/hr", name: "gram", data: data.history },
            { label: "kcal/hr", name: "kcal", data: data.history }
        ]
        summary = "fatmax"
        if (trackingUse == "Carb Burn") {
            description = "This is the rate of carbohydrates you burn at threshold intensity. For endurance-trained athletes, the lower this value the better while for power or sprint-based athletes, this likely will be higher!"
        } else if (trackingUse == "Fat Burn") {
            description = "This is your rate of fat consumption at threshold intensity. For endurance-trained athletes, the lower this value the better while for power or sprint-based athletes, this likely will be higher! Those on a low-carbohydrate diet will demonstrate higher fat bun rates."
        }

    } else if (trackingUse == "Lactate") {

        summary = "lactate"
        description = "This is your lactate at threshold. Generally, it indicates the highest sustainable intensity you can hold for ~1hour without a continuous or sharp rise in lactate concentration (acidity) in the muscles and blood leading to fatigue. The higher above this point, the quicker you will fatigue. "

    } else if (trackingUse == "HR") {

        options = [
            { label: "bpm", name: "bpm", data: data.history },
            { label: "%Hrmax", name: "%Hrmax", data: data.history }
        ]
        summary = "hrMax"
        description = "This is your heart rate at threshold intensity. Generally speaking, the higher this is relative to your HRmax (i.e. higher %HRmax), the fitter and faster you will be at threshold!"

    }

    const dataWithLactate = useMemo(() => {

        const history: any = [];

        loop(data.history, (test) => {

            const activity = test.activity;

            const lactateX = _.map(test.avgData, test.activity === "Running" ? "speed" : "power_raw").filter(Boolean).map(parseFloat);
            const lactateY = _.map(test.avgData, test.type === "Performance" ? "lactatePost" : "lactate").filter(Boolean).map(parseFloat);

            try {

                const lactate: any = chartRegressionPolynomial(lactateX, lactateY);

                test.lactateThreshold = fixedNum(lactate.regression.predict(parseFloat(test.perfThreshold)), 1);

            } catch (e) {

                test.lactateThreshold = 0;
                console.warn(e)

            }

            const { carb, carbKcal, fat, fatKcal } = labAnalysisRegresionCharts(test.avgData, test.activity);

            try {

                if (carb) test.carbThreshold = fixedNum(carb.regression.predict(parseFloat(test.perfThreshold)), 0);
                if (carbKcal) test.carbKcalThreshold = fixedNum(carbKcal.regression.predict(parseFloat(test.perfThreshold)), 0);

                if (fat) test.fatThreshold = fixedNum(fat.regression.predict(parseFloat(test.perfThreshold)), 0);
                if (fatKcal) test.fatKcalThreshold = fixedNum(fatKcal.regression.predict(parseFloat(test.perfThreshold)), 0);

            } catch (e) {

                test.lactateThreshold = 0;
                console.warn(e)

            }

            history.push(test);

        });

        return history;

    }, []);

    const isPerformance = ["Power", "Speed"];

    return useMemo(() => (

        <>

            <Row edge gutter={8}>

                <Col col={{ xs: 20 }}>

                    <PanelChart
                        key={options + trackingUse}
                        heading={trackingUse + " Threshold"}
                        addZero={false}
                        chartKey={form.hash}
                        types={options}
                        defaultTypes={options ? options[0] : ""}
                        data={dataWithLactate}
                        renderChart={(chartType, chartData) => (
                            <>
                                <ChartPerformanceTrackingThreshold
                                    data={chartData}
                                    activity={activity}
                                    date={data.recentDate}
                                    index={index}
                                    intensity={intensity}
                                    chart={trackingUse}
                                    type={chartType}
                                />
                            </>
                        )}
                    />

                </Col>

                <Col col={{ xs: 4 }}>

                    <FieldRadio
                        list=" vertical-list"
                        key={trackingUse}
                        form={form}
                        required={false}
                        label={"Tracking Over Time"}
                        name={"threshold.trackingUse"}
                        defaultValue={form.values?.threshold?.trackingUse || trackingUse}
                        options={[
                            activity === "Cycling" ? { label: "Power", value: "Power" } : null,
                            activity === "Running" ? { label: "Speed", value: "Speed" } : null,
                            { label: "VO2", value: "VO2" },
                            { label: "HR", value: "HR" },
                            { label: "Lactate", value: "Lactate" },
                            { label: "Carb Burn", value: "Carb Burn" },
                            { label: "Fat Burn", value: "Fat Burn" },
                        ].filter(Boolean)}
                    />

                </Col>

                <Col col={{ xs: 20 }}>

                    <ReportDesc
                        label={description}
                        className={["no-border", "h1"]}
                    />

                </Col>

                <Col col={{ xs: 20 }}>

                    {(trackingUse == "Power" || trackingUse == "Speed" || trackingUse == "VO2") &&

                        <ReportHeading
                            text={"Current Rating"}
                        />

                    }

                    {(trackingUse == "Power" || trackingUse == "Speed") &&

                        <>

                            {activity == "Cycling" ?
                                <RatingScaleCard
                                    metric={"Performance - Cycling - Anaerobic"}
                                    value={vo2Max?.vo2max_speed}
                                    form={form}
                                    demographic={data.test.performance.powerAnaerobicScale || data.demographic}
                                    split={{
                                        metric: "Performance - Cycling - Anaerobic / Kg",
                                        value: anaerobicPerKg,
                                    }}
                                    gender={gender}
                                />
                                :
                                <RatingScaleCard
                                    metric={"Performance - Running - Anaerobic"}
                                    value={anaerobic.powerUpper}
                                    form={form}
                                    demographic={data.test.performance.powerThresholdScale || data.demographic}
                                    split={{
                                        metric: "Performance - Running - Threshold",
                                        value: ftp?.ftp_speed,
                                        altUnit: {
                                            value: calculateMinPerKm(ftp?.ftp_speed),
                                            suffix: "min/km",
                                        }
                                    }}
                                    altUnit={{
                                        value: calculateMinPerKm(anaerobic.powerUpper),
                                        suffix: "min/km",
                                        sep: " / "
                                    }}
                                    gender={gender}
                                />
                            }

                        </>

                    }

                    {trackingUse == "VO2" &&

                        <RatingScaleCard
                            metric={'VO2 Max'}
                            value={get(data.test, 'vo2.' + get(data.test, "vo2.zoneUse", "relativeMax"))}
                            form={form}
                            demographic={data.test.vo2.vo2MaxScale || data.demographic}
                            gender={gender}
                        />

                    }

                </Col>

                <Col col={{ xs: 20 }}>

                    <ReportChartSummary
                        label={""}
                        valueKey={summary}
                        indexKey={"date"}
                        data={data.history}
                        type={isPerformance.includes(trackingUse) ? "performance" : "size"}
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, trackingUse, options]);

}

export default TestSessionsReportPerformanceTrackingThreshold;