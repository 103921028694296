import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Box, Button, Modal, Panel, Space, useApp } from '@dex/bubl-dash';
import EditableContext from '../../../context/EditableContext';
import CoachingEventsForm from '../Events/CoachingEventsForm';
import CoachingEventsApi from '../../CoachingEvents/CoachingEventsApi';
import CoachingEventsTable from '../Events/CoachingEventsTable';

const CoachingEditTabsEvents: React.FC<any> = (props: any) => {

    const { form, data } = props;

    const editable = useContext(EditableContext);

    const app = useApp()

    const process = CoachingEventsApi.create();

    const handleSubmit = useCallback((values) => {

        values.coachingId = data.id;

        process.run({
            data: values,
            onComplete: (data) => {

                app.alert('Event Saved', 'success');
                setShowForm(false)

            }
        });

    }, [props]);

    const [showForm, setShowForm] = useState(false);

    return useMemo(() => (
        <>

            <Box gutter={"auto"}>

                <Panel
                    heading={"Events"}
                    actions={
                        <Button
                            label={"Add New Event"}
                            onClick={setShowForm.bind(null, true)}
                        />
                    }
                    full={true}
                >

                    <Space />

                    {!showForm &&
                        <CoachingEventsTable
                            data={data}
                        />
                    }

                    {showForm &&

                        <Modal
                            show={showForm}
                            onClose={setShowForm.bind(null, false)}
                            width={1920}
                        >
                            <CoachingEventsForm
                                form={form}
                                process={process}
                                handleSubmit={handleSubmit}
                                mode={"create"}
                                handleClose={setShowForm.bind(null, false)}
                            />

                        </Modal>
                    }

                </Panel>

            </Box>

        </>

    ), [form.hash, data, process, showForm])
}

export default CoachingEditTabsEvents
