import { Col, FieldAutocomplete, FieldDate, FieldRelationship, FieldSelect, FieldText, FieldTextArea, Row } from '@dex/bubl-dash';
import React from 'react';

const FieldTestsDetail: React.FC<FieldTestsDetailProps> = (props) => {

    const { form, data, session } = props;

    return (

        <Row gutter={20} edge={true}>

            <Col col={{ xs: 24, md: 8 }}>

                <FieldDate
                    form={form}
                    label={"Test Date"}
                    name={`date`}
                    required={true}
                    defaultValue={form.defaults.date}
                    date={true}
                />

            </Col>

            <Col col={{ xs: 24, md: 8 }}>

                <FieldText
                    form={form}
                    label={"Time of Day"}
                    name={`timeOfDay`}
                    required={true}
                    defaultValue={form.defaults.timeOfDay}
                />

            </Col>

            <Col col={{ xs: 24, md: 8 }}>

                <FieldText
                    form={form}
                    label={"Testing Venue"}
                    name={`details.venue`}
                    required={false}
                    defaultValue={form.defaults?.details?.venue ?? session?.team?.trainingVenue}
                />

            </Col>

            <Col col={{ xs: 24, md: 8 }}>

                <FieldSelect
                    form={form}
                    label={"Weather"}
                    name={`details.weather`}
                    required={false}
                    defaultValue={form.defaults.details.weather}
                    options={[
                        { label: "Sunny", value: "Sunny" },
                        { label: "Partly Sunny", value: "Partly Sunny" },
                        { label: "Cloudy", value: "Cloudy" },
                        { label: "Drizzle", value: "Drizzle" },
                        { label: "Rain", value: "Rain" },
                        { label: "Heavy Rain", value: "Heavy Rain" },
                        { label: "Snowing", value: "Snowing" },
                        { label: "Not Applicable", value: "Not Applicable" },
                        { label: "Uncertain", value: "Uncertain" },
                    ]}
                />

            </Col>

            <Col col={{ xs: 24, md: 8 }}>

                <FieldSelect
                    form={form}
                    label={"Climate"}
                    name={`details.climate`}
                    required={false}
                    defaultValue={form.defaults.details.climate}
                    options={[
                        { label: "Very Hot (+30 deg)", value: "Very Hot (+30 deg)" },
                        { label: "Hot (26-30 deg)", value: "Hot (26-30 deg)" },
                        { label: "Warm (20-25 deg)", value: "warm (20-25 deg)" },
                        { label: "Cool (15-19 deg)", value: "Cool (15-19 deg)" },
                        { label: "Chilly (10-14 deg)", value: "Chilly (10-14 deg)" },
                        { label: "Cold (5-9 deg)", value: "Cold (5-9 deg)" },
                        { label: "Very Cold (0-4 deg)", value: "Very Cold (0-4 deg)" },
                        { label: "Freezing (O to -10 deg)", value: "Freezing (O to -10 deg)" },
                        { label: "Artic (<-10 deg)", value: "Artic (<-10 deg)" },
                        { label: "Uncertain", value: "Uncertain" }
                    ]}
                />

            </Col>

            <Col col={{ xs: 24, md: 8 }}>

                <FieldRelationship
                    form={form}
                    label={"Appraisers"}
                    name={`fitterIds`}
                    required={false}
                    defaultValue={form.defaults.fitterIds}
                    endpoint={"Fitters"}
                    preload
                    isMulti
                />

            </Col>

            <Col col={{ xs: 24, lg: 12 }}>

                <FieldTextArea
                    form={form}
                    label={"Comments"}
                    name={`details.comments`}
                    required={false}
                    defaultValue={form.defaults.details.comments}
                />

            </Col>

        </Row>

    )

}

interface FieldTestsDetailProps {
    [key: string]: any,
}

export default FieldTestsDetail;