import React from 'react';
import { Heading, Panel, Space } from '@dex/bubl-dash';
import styles from "./FieldRatingChart.module.scss";
import { Arrow, DisplayAs, Scale, arrowPosition } from '../RatingScale/RatingScaleElements';
import indicator from "ordinal/indicator"
import ReportMetaTable from '../ReportMetaTable/ReportMetaTable';
import { fixedNum } from '@dex/bubl-helpers';

const FieldRatingChart: React.FC<FieldRatingChartProps> = (props) => {

    let { comments } = props;

    return (

        <Panel heading={false} className={styles.panel} full={true}>

            <div className={styles.inner}>

                {props.scale &&
                    <FieldRatingChartRating
                        {...props}
                    />
                }
                {!props.scale &&
                    <>
                        <Heading tag={3} space={false}>No Rating Scale Selected</Heading>
                    </>
                }

                <FieldRatingChartRanking
                    {...props}
                />

                <ReportMetaTable
                    data={{ comments: comments }}
                    columnSize={{ xs: 24 }}
                    columnA={[
                        {
                            key: 'comments',
                            label: 'Comments',
                            format: "nl2br"
                        },
                    ]}
                />

            </div>

        </Panel >

    )

}

const FieldRatingChartRating: React.FC<FieldRatingChartProps> = (props) => {

    let { scale, score, rating, label, options, values, decimalPlace } = props;

    const left = arrowPosition(values['left'], scale.min, scale.max);
    const right = arrowPosition(values['right'], scale.min, scale.max);
    const main = arrowPosition(score, scale.min, scale.max);

    return (

        <div className={styles.ratingContainer}>

            <div className={styles.topContainer}>

                <div className={styles.rating}>
                    <div className={styles.header}>
                        Your Rating
                    </div>
                    <div className={styles.value}>
                        {label || rating}
                    </div>
                </div>

                {options === "left/right" &&

                    <div className={styles.altScores}>

                        <div className={styles.options}>

                            <div className={styles.optionsItem}>
                                <div className={styles.optionsItemLabel}>
                                    Left:
                                </div>

                                <div className={styles.optionsItemValue}>
                                    {values['left']} <span className={styles.units}>{scale.units}</span>
                                </div>

                            </div>

                            <div className={styles.optionsItem}>

                                <div className={styles.optionsItemLabel}>
                                    Right:
                                </div>

                                <div className={styles.optionsItemValue}>
                                    {values['right']} <span className={styles.units}>{scale.units}</span>
                                </div>

                            </div>

                            <div className={styles.optionsItem}>

                                <div className={styles.optionsItemLabel}>
                                    Diff:
                                </div>

                                <div className={styles.optionsItemValue}>
                                    {values['diff']} <span className={styles.units}>%</span>
                                </div>

                            </div>

                        </div>

                    </div>
                }

                <div className={styles.score}>
                    <div className={styles.header}>
                        Your Score
                    </div>
                    <div className={styles.value}>
                        {fixedNum(score, decimalPlace)} <span className={styles.units}>{scale.units}</span>
                    </div>
                </div>

            </div>

            <Space height={10} />

            <DisplayAs
                data={scale}
                left={null}
                steps={scale.steps}
                min={scale.min}
                max={scale.max}
                render={() => (
                    <>
                        {options === "left/right" ?
                            <>

                                <Arrow left={main} alt={"dashed"} /> {/* Average */}

                                <Arrow left={left} />{/* Left */}

                                <Arrow left={right} alt={true} /> {/* Right */}


                            </>
                            :
                            <>

                                <Arrow left={main} />

                            </>
                        }
                    </>
                )}
            />

            <Space height={10} />

            <div className={styles.source}>
                Rating Based on: {scale.source}
            </div>

        </div>
    )

}

const FieldRatingChartRanking: React.FC<FieldRatingChartProps> = (props) => {

    let { athleteCount, ranking, percentile, options, values } = props;

    // 0 to 100% fixed scale

    const min = 0;
    const max = 100;

    const suffix = "%";

    const ranges = [
        { num: 10, suffix },
        { num: 20, suffix },
        { num: 30, suffix },
        { num: 40, suffix },
        { num: 50, suffix },
        { num: 60, suffix },
        { num: 40, suffix },
        { num: 50, suffix },
        { num: 60, suffix },
        { num: 70, suffix },
        { num: 80, suffix },
        { num: 90, suffix },
        { num: 100, suffix }
    ];

    const steps = [
        { label: "Lowest", min: 0, max: 40 },
        { label: "Average", min: 40, max: 60 },
        { label: "Highest", min: 60, max: 100 },
    ];


    return (

        <div className={styles.rankingContainer}>

            <div className={styles.topContainer}>

                <div className={styles.rating}>
                    <div className={styles.header}>
                        Your Team Ranking
                    </div>
                    <div className={styles.value}>
                        {ranking}<span className={styles.indicator}>{indicator(ranking)}</span> <span>Best out of {athleteCount}</span>
                    </div>
                </div>

                <div className={styles.score}>
                    <div className={styles.header}>
                        Percentile
                    </div>
                    <div className={styles.value}>
                        {percentile} <span>%</span>
                    </div>

                </div>

            </div>

            <Space height={10} />

            <DisplayAs
                data={{
                    displayAs: "scale",
                }}
                left={null}
                rangeSteps={ranges}
                steps={steps}
                min={min}
                max={max}
                render={() => (
                    <>
                        {options === "left/right" ?
                            <>

                                <Arrow left={percentile} alt={"dashed"} /> {/* Average */}

                                <Arrow left={values['left-percentile']} />{/* Left */}

                                <Arrow left={values['right-percentile']} alt={true} /> {/* Right */}


                            </>
                            :
                            <>

                                <Arrow left={percentile} />

                            </>
                        }
                    </>
                )}
            />

        </div>
    )

}

interface FieldRatingChartProps {
    [key: string]: any,
}

export default FieldRatingChart;