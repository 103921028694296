import React, { useMemo, useState } from 'react';
import { Col, Heading, Line, Row, Space } from '@dex/bubl-dash';
import FieldRatingsGridLegend from '../../../elements/FieldRatingsGrid/FieldRatingsGridLegend';
import FieldRatingsGrid from '../../../elements/FieldRatingsGrid/FieldRatingsGrid';
import { loop, titleCase } from '@dex/bubl-helpers';

const FieldResultsAthleteRatings: React.FC<any> = ( props: any ) => {

    const { report } = props;

    const items = useMemo( () => {

        const items: any = [];

        loop( report.ratings, ( charts, category ) => {

            loop( charts || [], ( chart, index ) => {

                const template = report.templates[ chart.id ];
                const scale = report.scales[ chart.id ];

                chart.scale = scale;
                chart.name = template.name;
                chart.subtitle = template.name;
                chart.decimalPlace = template.decimalPlace;
                chart.bestScore = template.bestScore;

                chart.value = chart.score;
                chart.label = chart.rating;

            } )

            items.push( {
                category: category,
                title: titleCase( category ),
                charts: charts || [],
            } )

        } )

        return items;

    }, [ report.ratings ] );

    return useMemo( () => (

        <>

            <Row gutter={"auto"} edge={true}>

                <Col col={{ xs: 24 }}>

                    <Heading size={2} space={false}>Ratings Summary</Heading>

                    <Space height={"small"} />

                    <p>
                        Your ratings on each of the test attributes are shown below. These ratings are based on international norms or scores from other athletes of a similar level within your sport.
                    </p>

                    <Space />

                    <FieldRatingsGridLegend />

                </Col>

            </Row>

            <Space />

            <FieldRatingsGrid
                items={items}
            />

        </>

    ), [ props ] );

}

export default FieldResultsAthleteRatings;
