import React from 'react';
import { Modal, RibbonZoneIcon } from '@dex/bubl-dash';

import CalculatorBlack from '../../assets/Calculator-Black.svg';
import CalculatorWhite from '../../assets/Calculator-White.svg';
import RibbonPaceCalculator from './RibbonPaceCalculator';

const RibbonAfterZoneIcons: React.FC<any> = (props: any) => {

    const [showCacl, setShowCacl] = React.useState(false);

    const zone = {
        key: "cacl",
        label: "Calc",
        icon: CalculatorBlack,
        iconActive: CalculatorWhite,
        onClick: setShowCacl.bind(null, true)
    }

    return (

        <>

            <RibbonZoneIcon
                zone={zone}
            />

            {showCacl &&
                <Modal
                    show={showCacl}
                    onClose={setShowCacl.bind(null, false)}
                    width="1080"
                >

                    <RibbonPaceCalculator
                        handleClose={setShowCacl.bind(null, false)}
                    />

                </Modal >
            }

        </>

    );

}

export default RibbonAfterZoneIcons;
