import { Col, Row, useForm, useMountEffect } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import CoachingTrainingZoneTable, { CoachingTrainingZoneMiniTable } from '../../../elements/CoachingTrainingZoneTable/CoachingTrainingZoneTable';

const CoachingReportZones: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ trainingZone: { run: "pace", swim: "pace" } });

    return useMemo(() => (

        <Row edge gutter={"auto"}>

            {data?.zone &&

                <>

                    <Col col={{ xs: 24 }}>

                        <CoachingReportHeading
                            text="Your Current Best Measures"
                        />

                    </Col>

                    <CoachingTrainingZoneMiniTable
                        zone={data?.zone}
                        data={data}
                    />

                    <Col col={{ xs: 8 }}>
                        <ReportDesc
                            value={`Your threshold (FTP), maximum measures and Training Zones are given here. These values were determined from your test results or training data and represent your current fitness limits. Make sure to set your trainng app's data and zones similar as these so you are using the most accurate and up-to-date training guidelines.`}
                        />
                    </Col>

                    <CoachingTrainingZoneTable
                        zone={data?.zone}
                        form={form}
                    />

                </>

            }

        </Row>

    ), [data, form.hash]);

}


export default CoachingReportZones;