import React, { useEffect, useMemo, useState } from 'react';
import FieldSessionsApi from '../FieldSessionsApi';
import { FieldSelect, Form, Panel, Space, useApp, useForm } from '@dex/bubl-dash';
import FieldResultsAthlete from './FieldResultsAthlete';
import FieldResultsAthleteCover from './FieldResultsAthleteCover';

const FieldResultsAthletes: React.FC<any> = ( props: any ) => {

    const { data } = props;

    const form = useForm( { athleteId: null } );

    const athletes = useMemo( () => {

        return data.athletes.map( athlete => {
            return { value: athlete.id, label: athlete.callName }
        } )

    }, [ data.athletes ] );

    return useMemo( () => (

        <>

            <Panel>

                <Form
                    form={form}
                    autoDiscardChanges
                >

                    <FieldSelect
                        button
                        form={form}
                        name={"athleteId"}
                        label={'Select Athlete'}
                        defaultValue={form.defaults.athleteId}
                        options={athletes}
                    />

                </Form>

            </Panel>

            <Space height={20} />

            {form.values.athleteId &&

                <FieldResultsAthletesSingle
                    key={form.values.athleteId}
                    athleteId={form.values.athleteId}
                    session={data}
                    form={form}
                />

            }

        </>

    ), [ props, form.hash ] );

}

const FieldResultsAthletesSingle: React.FC<any> = ( props: any ) => {

    const { athleteId, form } = props;

    const app = useApp();

    const [ nav ] = app.store( "nav" );

    const report = FieldSessionsApi.getPublicReport( nav.currentRoute.state.params.id, { params: { athleteId: athleteId, view: "athlete" } } ); //data.id

    useEffect( () => {

        report.cancel();
        report.reset();

        if ( !athleteId ) return;

        report.run();

    }, [ athleteId ] );

    return (

        <>
            {report && report.data &&

                <>
                    <FieldResultsAthleteCover
                        report={report.data}
                        form={form}
                    />

                    <Space />

                    <FieldResultsAthlete
                        report={report.data}
                        form={form}
                    />

                </>

            }
        </>

    )

}

export default FieldResultsAthletes;
