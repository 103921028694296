import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ButtonRow, DataTable, Modal, useApp, useForm } from '@dex/bubl-dash';
import CoachingReportsApi from '../../CoachingReports/CoachingReportsApi';
import { date, get } from '@dex/bubl-helpers';
import CoachingReportsForm from './CoachingReportsForm';
import moment from 'moment';

const CoachingReportsTable: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ year: undefined });

    const app = useApp();

    const fetch = CoachingReportsApi.get();

    const [key, setKey] = useState(0);

    useEffect(() => {

        const query: any = { coachingId: data.id };

        if (form.values.year) {
            query.year = moment(form.values.year).format("YYYY")
        }

        fetch.run({
            params: {
                where: query,
                order: ['created.date DESC'],
                limit: 1000,
            }
        });

    }, [key, form.values.year])

    const copyLink = useCallback((link) => {

        navigator.clipboard.writeText(link);

        app.alert('Link Copied To Clipboard', 'info');

    }, [data]);

    const path = window.location.origin;

    const columns = [
        {
            label: "",
            key: "id",
            render: (value, row) => {
                return (
                    <CoachingReportsEditForm data={{ ...row, plans: data.plans, planOptions: data.planOptions, athlete: data.athlete }} setKey={setKey} />
                );
            }
        },
        {
            label: "Type",
            key: "type",
            format: "status"
        },
        {
            label: "Purpose",
            key: "purpose"
        },
        {
            label: "Sport",
            key: "sport"
        },
        {
            label: "Date",
            key: "endDate",
            format: "date",
            render: (value, row) => {

                if (row.fixedWeek) return "Latest Week";

                return date(value);

            }
        },
        {
            label: "Report",
            key: "report",
            render: (value, row) => {
                return (

                    <ButtonRow>
                        <Button
                            label={"View Report"}
                            path={`${path}/coaching/report/${row.id}`}
                            target={"_blank"}
                            size={"small"}
                            type={"primary"}
                        />

                        <Button
                            label={"Copy Link"}
                            onClick={copyLink.bind(null, `${path}/coaching/report/${row.id}`)}
                            size={"small"}
                            type={"light"}
                        />
                    </ButtonRow>
                );
            }
        },
    ];

    return useMemo(() => (

        <>

            {/* <Box gutter={"auto"} edge={{ top: true }}>
                 <Row>

                    <Col col={{ xs: 4 }}>

                        <FieldDate
                            form={form}
                            name='year'
                            defaultValue={form.defaults.year}
                            label="Year"
                            dateFormat="YYYY"
                            dateMode="year"
                            wrapClass="manual-input"
                        />

                    </Col>

                </Row>
            </Box> */}

            <DataTable
                data={get(fetch, 'data.data', [])}
                count={get(fetch, 'data.count', null)}
                columns={columns}
                loading={fetch.loading}
            />

        </>

    ), [fetch.loading, form.hash]);

}

const CoachingReportsEditForm: React.FC<any> = (props: any) => {

    const { data, setKey } = props;

    const [showFormUpdate, setShowFormUpdate] = useState(false);

    const form = useForm(data)

    const process = CoachingReportsApi.update(data.id, "update");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            onComplete: (data) => {

                app.alert('Report Saved', 'success');

                setShowFormUpdate(false);

                setKey(k => k + 1)


            }
        });

    }, []);

    const app = useApp();

    return (

        <>

            <a href="#" onClick={() => setShowFormUpdate(true)}>Edit Report</a >

            {showFormUpdate &&

                <Modal
                    show={showFormUpdate}
                    onClose={setShowFormUpdate.bind(null, false)}
                    width={1080}
                >

                    <CoachingReportsForm
                        form={form}
                        process={process}
                        handleSubmit={handleSubmit}
                        data={data}
                        mode={"edit"}
                    />

                </Modal>
            }

        </>
    )

}


export default CoachingReportsTable;
