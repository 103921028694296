import { Button, Col, DataTableCell, FieldAutocomplete, FieldNumber, FieldSelect, FieldTextArea, Heading, InputTable, InputTableCell, InputTableRow, Line, Modal, Panel, Row, Seperator, Space, useUpdateEffect } from '@dex/bubl-dash';
import { get, loop } from '@dex/bubl-helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { getBottleImage, getFluidLimit, getFractionNumber, getPillImage, sweatTestGetActivities, sweatTestRehydration, sweatTestRehydrationFluidIntake, sweatTestRehydrationLossRate, sweatTestRehydrationSodiumIntake } from '../../../functions/sweatTest';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';

const LabTestsSweatRehydration: React.FC<LabTestsSweatRehydrationProps> = (props) => {

    const { form, data, session } = props;

    const {
        finalAverageWeight, finalAverageHeight,
        finalAverageWeightLoss, finalAverageRehydration,
        finalAverageFluidLossRate, finalAverageFluidIntakeRate,
        finalAverageSodiumLossRate,
    } = sweatTestRehydration(form, data);

    const activities = sweatTestGetActivities(form);

    return useMemo(() => (
        <>

            <Row edge={true} gutter={8}>

                <Col col={{ xs: 24, md: 6 }} >

                    <FieldAutocomplete
                        form={form}
                        name="rehydration.sweatMeter"
                        label="Sweat Meter"
                        defaultValue={form.values.rehydration.sweatMeter}
                        group='sweatMeter'
                        options={[
                            { value: "Horiba Laqua Twin Na+", label: "Horiba Laqua Twin Na+" },
                        ]}
                        wrapClass={"manual-input w-400"}
                    />

                </Col>

            </Row>

            <Row edge={true} gutter={8}>

                <Col col={{ xs: 24 }} >

                    <Seperator
                        heading={"Average Across Workouts"}
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }} >

                    <FieldSelect
                        form={form}
                        label={"Activity"}
                        name={`rehydration.activity`}
                        wrapClass={"manual-input w-400"}
                        defaultValue={form.defaults.rehydration.activity}
                        options={activities}
                        isClearable={false}
                    />

                </Col>

            </Row>

            <Row edge={true} gutter={8}>

                <Col col={{ xs: 24, md: 12 }}>

                    <Row edge={true} gutter={8}>

                        <Col col={{ xs: 12, md: 6 }} >

                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.avgWeight`}
                                wrapClass={"manual-input w-240"}
                                defaultValue={finalAverageWeight}
                                key={finalAverageWeight}
                                suffix=" kg"
                                disabled
                                label='Avg Weight'
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 6 }} >

                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.avgHeight`}
                                wrapClass={"manual-input  w-240"}
                                defaultValue={finalAverageHeight}
                                key={finalAverageHeight}
                                suffix=" cm"
                                disabled
                                label='Avg Height'
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 6 }} >

                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.fluidLossRate`}
                                wrapClass={"manual-input  w-240"}
                                defaultValue={finalAverageFluidLossRate}
                                key={finalAverageFluidLossRate}
                                suffix=" ml/hr"
                                disabled
                                label='Fluid Loss Rate'
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 6 }} >

                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.sodiumLossRate`}
                                wrapClass={"manual-input  w-240"}
                                defaultValue={finalAverageSodiumLossRate}
                                key={finalAverageSodiumLossRate}
                                suffix=" mg/hr"
                                disabled
                                label='Sodium Loss Rate'
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 6 }} >

                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.weightLoss`}
                                wrapClass={"manual-input  w-240"}
                                defaultValue={finalAverageWeightLoss}
                                key={finalAverageWeightLoss}
                                suffix=" kg"
                                disabled
                                label='Weight Loss'
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 6 }} >

                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.fluidIntake`}
                                wrapClass={"manual-input  w-240"}
                                defaultValue={finalAverageFluidIntakeRate}
                                key={finalAverageFluidIntakeRate}
                                suffix=" ml/hr"
                                disabled
                                label='Fluid Intake Rate'
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 6 }} >

                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.rehydration`}
                                wrapClass={"manual-input  w-240"}
                                defaultValue={finalAverageRehydration}
                                key={finalAverageRehydration}
                                suffix=" %"
                                disabled
                                label='Rehydration'
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, md: 12 }}>
                    <RatingScaleCard
                        metric={'Average Rehydration'}
                        value={finalAverageRehydration}
                        form={form}
                        name="rehydration.rehydrationRating"
                        demographic={get(form.values, 'rehydration.rehydrationRating', session.demographic)}
                    />
                </Col>

                <Col col={{ xs: 24, md: 12 }}>
                    <FieldTextArea
                        form={form}
                        name="rehydration.lossRateDescription"
                        label='Average Across Workouts Description'
                        defaultValue={form.defaults.rehydration.lossRateDescription}
                    />
                </Col>

            </Row>


            <Seperator
                heading={"Loss Rate - Adjustment For Workout Conditions"}
                bottom={"auto"}
                top={"auto"}
            />

            <LossRateForm
                form={form}
                session={session}
                data={data}
                sodiumLossRate={finalAverageSodiumLossRate}
                fluidLossRate={finalAverageFluidLossRate}
            />

            <Seperator
                heading={"Fluid Intake - Adjustment For Workout Conditions"}
                bottom={"auto"}
                top={"auto"}
            />

            <FluidIntakeForm
                form={form}
                session={session}
                data={data}
                height={finalAverageHeight}
            />

            <Seperator
                heading={"Sodium Intake - Adjustment For Workout Conditions"}
                bottom={"auto"}
                top={"auto"}
            />

            <SodiumIntakeForm
                form={form}
                session={session}
                data={data}
            />

        </>

    ), [form.hash, data, session])

}

const LossRateForm: React.FC<LabTestsSweatRehydrationProps> = (props) => {

    const { form, sodiumLossRate, fluidLossRate } = props;

    const { adjustedCoolFluid, adjustedCoolSodium, adjustedWarmFluid, adjustedWarmSodium, adjustedHotFluid, adjustedHotSodium } = sweatTestRehydrationLossRate(form, fluidLossRate, sodiumLossRate);

    return (
        <Row edge gutter={"auto"}>

            <Col col={{ xs: 24 }}>

                <InputTable
                    columns={[
                        { style: { width: "120px" } },
                        {
                            label: "Cool/Easy",
                            alignMiddle: true,
                            colSpan: 2,
                        },
                        {
                            label: "Warm/Moderate",
                            alignMiddle: true,
                            colSpan: 2
                        },
                        {
                            label: "Hot/Hard",
                            alignMiddle: true,
                            colSpan: 2
                        }
                    ]}
                >

                    <InputTableRow>
                        <InputTableCell></InputTableCell>
                        <InputTableCell alignMiddle>Fluids</InputTableCell>
                        <InputTableCell alignMiddle>Sodium</InputTableCell>
                        <InputTableCell alignMiddle>Fluids</InputTableCell>
                        <InputTableCell alignMiddle>Sodium</InputTableCell>
                        <InputTableCell alignMiddle>Fluids</InputTableCell>
                        <InputTableCell alignMiddle>Sodium</InputTableCell>
                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>% Adjustment:</InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.cool.fluidLossAdjustment`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={get(form.values, "rehydration.cool.fluidLossAdjustment")}
                                suffix=" %"
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.cool.sodiumLossAdjustment`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={get(form.values, "rehydration.cool.sodiumLossAdjustment")}
                                suffix=" %"
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.warm.fluidLossAdjustment`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={get(form.values, "rehydration.warm.fluidLossAdjustment")}
                                suffix=" %"
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.warm.sodiumLossAdjustment`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={get(form.values, "rehydration.warm.sodiumLossAdjustment")}
                                suffix=" %"
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.hot.fluidLossAdjustment`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={get(form.values, "rehydration.hot.fluidLossAdjustment")}
                                suffix=" %"
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.hot.sodiumLossAdjustment`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={get(form.values, "rehydration.hot.sodiumLossAdjustment")}
                                suffix=" %"
                            />
                        </InputTableCell>
                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>Adjusted Rate:</InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.cool.fluidLossAdjustedRate`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={adjustedCoolFluid}
                                key={adjustedCoolFluid}
                                suffix=" ml/hr"
                                disabled
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.cool.sodiumLossAdjustedRate`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={adjustedCoolSodium}
                                key={adjustedCoolSodium}
                                suffix=" mg/hr"
                                disabled
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.warm.fluidLossAdjustedRate`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={adjustedWarmFluid}
                                key={adjustedWarmFluid}
                                suffix=" ml/hr"
                                disabled
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.warm.sodiumLossAdjustedRate`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={adjustedWarmSodium}
                                key={adjustedWarmSodium}
                                suffix=" mg/hr"
                                disabled
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.hot.fluidLossAdjustedRate`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={adjustedHotFluid}
                                key={adjustedHotFluid}
                                suffix=" ml/hr"
                                disabled
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.hot.sodiumLossAdjustedRate`}
                                wrapClass={"manual-input w-100 field-center"}
                                defaultValue={adjustedHotSodium}
                                key={adjustedHotSodium}
                                suffix=" mg/hr"
                                disabled
                            />
                        </InputTableCell>

                    </InputTableRow>

                </InputTable>

            </Col>

        </Row>

    )

}

const FluidIntakeForm: React.FC<LabTestsSweatRehydrationProps> = (props) => {

    const { form, fluidLoss, height } = props;

    const { adjustedCoolFluid, adjustedWarmFluid, adjustedHotFluid } = sweatTestRehydrationFluidIntake(form);

    const [legend, setLegend] = useState(false);

    return (
        <Row gutter={"auto"} edge>

            <Col col={{ xs: 24 }}>

                <Button
                    label={legend ? "Hide Legend" : "Show Legend"}
                    size='small'
                    type={"light"}
                    onClick={setLegend.bind(null, !legend)}
                />

                <Modal
                    show={legend}
                    width={720}
                    onClose={setLegend.bind(null, false)}
                >
                    <Panel
                        heading={"Fluid Intake Legend"}
                    >
                        <BottleLegend />
                        <Line bottom={false} />
                        <LimitLegend />
                    </Panel>
                </Modal>

            </Col>

            <Col col={{ xs: 24 }}>

                <InputTable
                    columns={[
                        {
                            // style: { width: "200px" }
                        },
                        {
                            label: "Cool/Easy",
                            alignMiddle: true,
                        },
                        {
                            label: "Warm/Moderate",
                            alignMiddle: true,
                        },
                        {
                            label: "Hot/Hard",
                            alignMiddle: true,
                        },
                        {}
                    ]}
                >

                    <InputTableRow>

                        <InputTableCell>% Adjustment:</InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.cool.fluidIntakeAdjustment`}
                                wrapClass={"manual-input w-200 field-center"}
                                defaultValue={get(form.values, "rehydration.cool.fluidIntakeAdjustment")}
                                suffix=" %"
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.warm.fluidIntakeAdjustment`}
                                wrapClass={"manual-input w-200 field-center"}
                                defaultValue={get(form.values, "rehydration.warm.fluidIntakeAdjustment")}
                                suffix=" %"
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.hot.fluidIntakeAdjustment`}
                                wrapClass={"manual-input w-200 field-center"}
                                defaultValue={get(form.values, "rehydration.hot.fluidIntakeAdjustment")}
                                suffix=" %"
                            />
                        </InputTableCell>

                        <InputTableCell>
                            {
                                adjustedCoolFluid > getFluidLimit(height) ||
                                    adjustedWarmFluid > getFluidLimit(height) ||
                                    adjustedHotFluid > getFluidLimit(height)
                                    ? <>Limit is : {getFluidLimit(height)} ml/hr</>
                                    : null
                            }
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>Avg Intake:</InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.cool.fluidIntakeAdjustedRate`}
                                wrapClass={"manual-input w-200 field-center"}
                                defaultValue={adjustedCoolFluid}
                                key={adjustedCoolFluid}
                                suffix=" ml/hr"
                                disabled
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.warm.fluidIntakeAdjustedRate`}
                                wrapClass={"manual-input w-200 field-center"}
                                defaultValue={adjustedWarmFluid}
                                key={adjustedWarmFluid}
                                suffix=" ml/hr"
                                disabled
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`rehydration.hot.fluidIntakeAdjustedRate`}
                                wrapClass={"manual-input w-200 field-center"}
                                defaultValue={adjustedHotFluid}
                                key={adjustedHotFluid}
                                suffix=" ml/hr"
                                disabled
                            />
                        </InputTableCell>

                        <InputTableCell>
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>Water Bottle Equivalent:</InputTableCell>

                        <InputTableCell alignMiddle>

                            <div style={{ display: "flex", justifyContent: "center" }}>

                                {(getBottleImage(adjustedCoolFluid) || []).map((item, index) => (

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {index >= 1 &&
                                            <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                        }
                                        <img
                                            src={require(`../../../assets/images/SweatTest/Bottle_Icon_${item}%.png`)}
                                            style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                                            alt={`Bottle_Icon_${item}%.png`}
                                        />
                                    </div>

                                ))}

                            </div>

                            <Col col={{ xs: 24 }} align='center'>
                                <Heading>
                                    {getFractionNumber(getBottleImage(adjustedCoolFluid), "Bottle")}
                                </Heading>
                            </Col>

                        </InputTableCell>

                        <InputTableCell alignMiddle>

                            <div style={{ display: "flex", justifyContent: "center" }}>

                                {((getBottleImage(adjustedWarmFluid) || [])).map((item, index) => (

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {index >= 1 &&
                                            <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                        }
                                        <img
                                            src={require(`../../../assets/images/SweatTest/Bottle_Icon_${item}%.png`)}
                                            style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                                            alt={`Bottle_Icon_${item}%.png`}
                                        />
                                    </div>

                                ))}

                            </div>

                            <Col col={{ xs: 24 }} align='center'>
                                <Heading>
                                    {adjustedWarmFluid && getFractionNumber(getBottleImage(adjustedWarmFluid), "Bottle")}
                                </Heading>
                            </Col>

                        </InputTableCell>

                        <InputTableCell alignMiddle>

                            <div style={{ display: "flex", justifyContent: "center" }}>

                                {(getBottleImage(adjustedHotFluid) || []).map((item, index) => (

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {index >= 1 &&
                                            <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                        }
                                        <img
                                            src={require(`../../../assets/images/SweatTest/Bottle_Icon_${item}%.png`)}
                                            style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                                            alt={`Bottle_Icon_${item}%.png`}
                                        />
                                    </div>

                                ))}

                            </div>

                            <Col col={{ xs: 24 }} align='center'>
                                <Heading>
                                    {getFractionNumber(getBottleImage(adjustedHotFluid), "Bottle")}
                                </Heading>
                            </Col>

                        </InputTableCell>

                        <InputTableCell >

                        </InputTableCell>

                    </InputTableRow>

                </InputTable>
            </Col>

            <Col col={{ xs: 24, md: 12 }}>
                <FieldTextArea
                    form={form}
                    name="rehydration.fluidIntakeDescription"
                    label='Fluid Intake Description'
                    defaultValue={get(form.values, "rehydration.fluidIntakeDescription")}
                />
            </Col>

        </Row>
    )
}

const BottleLegend: React.FC<LabTestsSweatRehydrationProps> = (props) => {
    return (
        <Row edge={true} gutter={"auto"}>

            <Col col={{ xs: 4 }} align='center'>
                <img
                    src={require(`../../../assets/images/SweatTest/Bottle_Icon_100%.png`)}
                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                />
                <div style={{ textAlign: "center" }}>
                    <strong>1</strong>
                    <br />
                    <strong>600ml</strong>
                </div>
            </Col>

            <Col col={{ xs: 4 }} align='center'>
                <img
                    src={require(`../../../assets/images/SweatTest/Bottle_Icon_75%.png`)}
                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                />
                <div style={{ textAlign: "center" }}>
                    <strong>3/4</strong>
                    <br />
                    <strong>450ml</strong>
                </div>
            </Col>

            <Col col={{ xs: 4 }} align='center'>
                <img
                    src={require(`../../../assets/images/SweatTest/Bottle_Icon_50%.png`)}
                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                />
                <div style={{ textAlign: "center" }}>
                    <strong>1/2</strong>
                    <br />
                    <strong>300ml</strong>
                </div>
            </Col>

            <Col col={{ xs: 4 }} align='center'>
                <img
                    src={require(`../../../assets/images/SweatTest/Bottle_Icon_25%.png`)}
                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                />
                <div style={{ textAlign: "center" }}>
                    <strong>1/4</strong>
                    <br />
                    <strong>150ml</strong>
                </div>
            </Col>

            <Col col={{ xs: 4 }} align='center'>
                <img
                    src={require(`../../../assets/images/SweatTest/Bottle_Icon_0%.png`)}
                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                />
                <div style={{ textAlign: "center" }}>
                    <strong>0</strong>
                    <br />
                    <strong>0ml</strong>
                </div>
            </Col>

        </Row>
    )
}

const LimitLegend: React.FC<LabTestsSweatRehydrationProps> = (props) => {

    const data = [
        { size: "Very Small", limit: '600 ml', height: "< 155 cm" },
        { size: "Small", limit: '800 ml', height: "155-165 cm" },
        { size: "Medium", limit: '1000 ml', height: "165-175 cm" },
        { size: "Large", limit: '1200 ml', height: "175-185 cm" },
        { size: "Very Large", limit: "1500 ml", height: ">185 cm" }
    ]

    return (
        <Row edge>
            <Col col={{ xs: 24 }}>
                <Heading>Athlete Fluid Intake Limits</Heading>
            </Col>

            <InputTable
                columns={[
                    { label: <strong>Athlete Size</strong> },
                    { label: <strong>Upper Limit</strong> },
                    { label: <strong>Height</strong> },
                ]}
                className={"mini-table"}
            >

                {data.map((item, index) => (
                    <InputTableRow>
                        <DataTableCell>{item.size}</DataTableCell>
                        <DataTableCell>{item.limit}</DataTableCell>
                        <DataTableCell>{item.height}</DataTableCell>
                    </InputTableRow>
                ))}

            </InputTable>
        </Row >
    )
}

const SodiumIntakeForm: React.FC<LabTestsSweatRehydrationProps> = (props) => {

    const { form } = props;

    const { adjustedCoolSodium, adjustedWarmSodium, adjustedHotSodium } = sweatTestRehydrationSodiumIntake(form);

    const [legend, setLegend] = useState(false);

    return (
        <Row edge gutter={"auto"}>

            <Col col={{ xs: 24 }}>

                <Button
                    label={legend ? "Hide Legend" : "Show Legend"}
                    size='small'
                    type={"light"}
                    onClick={setLegend.bind(null, !legend)}
                />

                <Modal
                    show={legend}
                    width={720}
                    onClose={setLegend.bind(null, false)}
                >
                    <Panel
                        heading={"Sodium Intake Legend"}
                    >
                        <PillLegend />
                    </Panel>
                </Modal>

            </Col>

            <InputTable
                columns={[
                    {
                        style: { width: "200px" }
                    },
                    {
                        label: "Cool/Easy",
                        alignMiddle: true,
                    },
                    {
                        label: "Warm/Moderate",
                        alignMiddle: true,
                    },
                    {
                        label: "Hot/Hard",
                        alignMiddle: true,
                    }
                ]}
            >

                <InputTableRow>

                    <InputTableCell>% Adjustment:</InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            allowNegative
                            form={form}
                            name={`rehydration.cool.sodiumIntakeAdjustment`}
                            wrapClass={"manual-input w-200 field-center"}
                            defaultValue={form.values.rehydration.cool?.sodiumIntakeAdjustment}
                            suffix=" %"
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            allowNegative
                            form={form}
                            name={`rehydration.warm.sodiumIntakeAdjustment`}
                            wrapClass={"manual-input w-200 field-center"}
                            defaultValue={form.values.rehydration.warm?.sodiumIntakeAdjustment}
                            suffix=" %"
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            allowNegative
                            form={form}
                            name={`rehydration.hot.sodiumIntakeAdjustment`}
                            wrapClass={"manual-input w-200 field-center"}
                            defaultValue={form.values.rehydration.hot?.sodiumIntakeAdjustment}
                            suffix=" %"
                        />
                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell>Avg Intake:</InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            allowNegative
                            form={form}
                            name={`rehydration.cool.sodiumIntakeAdjustedRate`}
                            wrapClass={"manual-input w-200 field-center"}
                            defaultValue={adjustedCoolSodium}
                            key={adjustedCoolSodium}
                            suffix=" mg/hr"
                            disabled
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            allowNegative
                            form={form}
                            name={`rehydration.warm.sodiumIntakeAdjustedRate`}
                            wrapClass={"manual-input w-200 field-center"}
                            defaultValue={adjustedWarmSodium}
                            key={adjustedWarmSodium}
                            suffix=" mg/hr"
                            disabled
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            allowNegative
                            form={form}
                            name={`rehydration.hot.sodiumIntakeAdjustedRate`}
                            wrapClass={"manual-input w-200 field-center"}
                            defaultValue={adjustedHotSodium}
                            key={adjustedHotSodium}
                            suffix=" mg/hr"
                            disabled
                        />
                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell>Salt Pill Equivalent:</InputTableCell>

                    <InputTableCell alignMiddle>

                        <div style={{ display: "flex", justifyContent: "center" }}>

                            {getPillImage(adjustedCoolSodium).map((item, index) => (

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {index >= 1 &&
                                        <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                    }
                                    <img
                                        src={require(`../../../assets/images/SweatTest/Pill_${item}%.png`)}
                                        style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                                        alt={`Bottle_Icon_${item}%.png`}
                                    />
                                </div>

                            ))}

                        </div>

                        <Col col={{ xs: 24 }} align='center'>
                            <Heading>
                                {getFractionNumber(getPillImage(adjustedCoolSodium), "Pill")}
                            </Heading>
                        </Col>

                    </InputTableCell>

                    <InputTableCell alignMiddle>

                        <div style={{ display: "flex", justifyContent: "center" }}>

                            {getPillImage(adjustedWarmSodium).map((item, index) => (

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {index >= 1 &&
                                        <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                    }
                                    <img
                                        src={require(`../../../assets/images/SweatTest/Pill_${item}%.png`)}
                                        style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                                        alt={`Bottle_Icon_${item}%.png`}
                                    />
                                </div>

                            ))}

                        </div>

                        <Col col={{ xs: 24 }} align='center'>
                            <Heading>
                                {getFractionNumber(getPillImage(adjustedWarmSodium), "Pill")}
                            </Heading>
                        </Col>

                    </InputTableCell>

                    <InputTableCell alignMiddle>

                        <div style={{ display: "flex", justifyContent: "center" }}>

                            {getPillImage(adjustedHotSodium).map((item, index) => (

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {index >= 1 &&
                                        <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                    }
                                    <img
                                        src={require(`../../../assets/images/SweatTest/Pill_${item}%.png`)}
                                        style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                                        alt={`Bottle_Icon_${item}%.png`}
                                    />
                                </div>

                            ))}

                        </div>

                        <Col col={{ xs: 24 }} align='center'>
                            <Heading>
                                {getFractionNumber(getPillImage(adjustedHotSodium), "Pill")}
                            </Heading>
                        </Col>

                    </InputTableCell>

                </InputTableRow>

            </InputTable>

            <Col col={{ xs: 24, md: 12 }}>
                <FieldTextArea
                    form={form}
                    name="rehydration.sodiumIntakeDescription"
                    label='Sodium Intake Description'
                    defaultValue={form.defaults.rehydration.sodiumIntakeDescription}
                />
            </Col>

        </Row>
    )
}

const PillLegend: React.FC<LabTestsSweatRehydrationProps> = (props) => {
    return (
        <Row edge={true} gutter={"auto"}>

            <Col col={{ xs: 4 }} align='center'>
                <img
                    src={require(`../../../assets/images/SweatTest/Pill_100%.png`)}
                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                />
                <div style={{ textAlign: "center" }}>
                    <strong>1</strong>
                    <br />
                    <strong>400mg</strong>
                </div>
            </Col>

            <Col col={{ xs: 4 }} align='center'>
                <img
                    src={require(`../../../assets/images/SweatTest/Pill_75%.png`)}
                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                />
                <div style={{ textAlign: "center" }}>
                    <strong>3/4</strong>
                    <br />
                    <strong>300mg</strong>
                </div>
            </Col>

            <Col col={{ xs: 4 }} align='center'>
                <img
                    src={require(`../../../assets/images/SweatTest/Pill_50%.png`)}
                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                />
                <div style={{ textAlign: "center" }}>
                    <strong>1/2</strong>
                    <br />
                    <strong>200mg</strong>
                </div>
            </Col>

            <Col col={{ xs: 4 }} align='center'>
                <img
                    src={require(`../../../assets/images/SweatTest/Pill_25%.png`)}
                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                />
                <div style={{ textAlign: "center" }}>
                    <strong>1/4</strong>
                    <br />
                    <strong>100mg</strong>
                </div>
            </Col>

            <Col col={{ xs: 4 }} align='center'>
                <img
                    src={require(`../../../assets/images/SweatTest/Pill_0%.png`)}
                    style={{ width: "70px", height: "70px", objectFit: "scale-down" }}
                />
                <div style={{ textAlign: "center" }}>
                    <strong>0</strong>
                    <br />
                    <strong>0mg</strong>
                </div>
            </Col>

        </Row>
    )
}

interface LabTestsSweatRehydrationProps {
    [key: string]: any,
}

export default LabTestsSweatRehydration;