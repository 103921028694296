import { Button, ButtonRow, DataTable, Seperator, Space, useApp } from '@dex/bubl-dash';
import React, { useCallback, useMemo, useState } from 'react';
import FieldSessionsApi from '../FieldSessionsApi';

const FieldSessionsSingleAthletes: React.FC<any> = (props: any) => {

    const { data } = props;

    const [athletes, setAthletes] = useState(data.athletes || []);

    const refresh = FieldSessionsApi.update(data.id, "refreshAthletes");

    const app = useApp();

    const [nav] = app.store("nav");

    const columns = [
        {
            key: "__num",
            label: "#",
            style: { width: 44, paddingLeft: 12 },
        },
        {
            key: "this",
            label: "Athlete Name",
            format: "ModelLink",
            style: { paddingLeft: 12 },
        },
        {
            key: "shortName",
            label: "Short Name",
            style: { paddingLeft: 12 },
        },
        {
            key: "discipline",
            label: "Discipline",
            style: { paddingLeft: 12 },
        },
        {
            key: "position",
            label: "Position",
            style: { paddingLeft: 12 },
        },
        {
            key: "age",
            label: "Age",
            style: { paddingLeft: 12 },
        },
    ];

    const handleRefresh = useCallback(() => {

        refresh.run({
            onComplete: (data) => {

                app.navigate(nav, 'update', {});

            }
        });

    }, []);

    return useMemo(() => (

        <>

            <Seperator
                heading={"Athletes"}
                top={"auto"}
                bottom={"auto"}
            />

            <ButtonRow>

                <Button
                    label={"Refresh Athletes"}
                    type={"light"}
                    size={"small"}
                    loading={refresh.loading}
                    onClick={handleRefresh}
                />

            </ButtonRow>

            <Space />

            <DataTable
                columns={columns}
                data={data.athletes}
            />

        </>

    ), [data, refresh.loading]);

}

export default FieldSessionsSingleAthletes;
