import React, { useCallback, useEffect, useMemo } from 'react';
import { useApp, useForm } from '@dex/bubl-dash';
import CoachingTrainingWeeksApi from '../../CoachingTrainingWeeks/CoachingTrainingWeeksApi';
import CoachingTrainingsWeekForm from './CoachingTrainingsWeekForm';

const CoachingTrainingsEditWeek: React.FC<any> = (props: any) => {

    const { data, handleClose, weekId } = props;

    const app = useApp()

    const form = useForm({ training: data.training, });

    const process: any = CoachingTrainingWeeksApi.update(weekId, "update", {});

    const week = CoachingTrainingWeeksApi.getOne(weekId);

    useEffect(() => {

        week.run({
            onComplete: (response) => {

                form.reset({ ...response, training: data.training });

            }
        });

    }, [weekId]);

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            onComplete: (data) => {

                app.alert('Week Saved', 'success');

                // handleClose();
            }

        });

    }, [weekId]);

    const del: any = CoachingTrainingWeeksApi.delete(weekId, {
        onComplete: (data) => {

            app.alert('Week Deleted', 'success');

            handleClose();
        }
    });

    return (

        <>
            {week.data &&
                <CoachingTrainingsWeekForm
                    form={form}
                    data={data}
                    process={process}
                    handleSubmit={handleSubmit}
                    mode="edit"
                    del={del}
                    handleClose={handleClose}
                    app={app}
                />
            }
        </>

    )
}

export default CoachingTrainingsEditWeek
