import React from 'react';

import './styles/App.scss';

import BublDash from '@dex/bubl-dash/src/lib/Dash';
import Media from '@dex/bubl-dash/src/lib/views/Media/Media';
import Users from '@dex/bubl-dash/src/lib/views/Users/Users';
import Defaults from '@dex/bubl-dash/src/lib/views/Defaults';
import Wrapper from '@dex/bubl-dash/src/lib/Wrapper';

import FitWhite from './assets/Fit-White.svg';
import FitBlack from './assets/Fit-Black.svg';

import PeopleBlack from './assets/People-Black.svg';
import PeopleWhite from './assets/People-White.svg';

import LabBlack from './assets/Lab-Black.svg';
import LabWhite from './assets/Lab-White.svg';

import FieldBlack from './assets/Field-Black.svg';
import FieldWhite from './assets/Field-White.svg';

import CoachingBlack from './assets/Coaching-Black.svg';
import CoachingWhite from './assets/Coaching-White.svg';

import GaitBlack from './assets/Gait-Black.svg';
import GaitWhite from './assets/Gait-White.svg';

import SettingsMods from './views/Settings/SettingsMods';

//Dashboard
import DashboardView from './views/Dashboard/DashboardView';

//People
import Teams from './views/Teams/Teams';
import Athletes from './views/Athletes/Athletes';
import Fitters from './views/Fitters/Fitters';

//Fit
import Sessions from './views/Sessions/Sessions';
import Questionnaires from './views/Questionnaires/Questionnaires';
import Exercises from './views/Exercises/Exercises';

//Lab
import LabTests from './views/LabTests/LabTests';
import TestSessions from './views/TestSessions/TestSessions';
import TrainingZones from './views/TrainingZones/TrainingZones';
import RatingScales from './views/RatingScales/RatingScales';
import EventTarget from "./views/EventTargets/EventTargets"

//Field
import FieldTemplates from './views/FieldTemplates/FieldTemplates';
import FieldTests from './views/FieldTests/FieldTests';
import FieldRatingScales from './views/FieldRatingScales/FieldRatingScales';
import FieldSessions from './views/FieldSessions/FieldSessions';

//Coaching
import Coaching from './views/Coaching/Coaching';
import CoachingEvents from './views/CoachingEvents/CoachingEvents'
import CoachingPlans from './views/CoachingPlans/CoachingPlans'
import CoachingTrainingEndurance from './views/CoachingTrainingEndurance/CoachingTrainingEndurance';
import CoachingTrainingWeeks from './views/CoachingTrainingWeeks/CoachingTrainingWeeks';
import CoachingTrainingIntervals from './views/CoachingTrainingIntervals/CoachingTrainingIntervals';

//gait
import Gaits from './views/Gaits/Gaits';
import RibbonMods from './views/Ribbon/RibbonMods';

const app = new BublDash({
    name: "VO2max",
    //icon: "assets/logo.png",
    accessRoles: ['admin', 'developer', 'fitter', 'super'], //must be one of these roles in order to sign in
    userRoles: [
        {
            key: 'fitter',
            label: 'Fitter'
        },
        {
            key: 'admin',
            label: 'Admin'
        },
        {
            key: 'super',
            label: 'Super'
        }
    ],

    env: window['bublENV'] || process.env.REACT_APP_ENV || "development", //set env by modifying the output sent by express server
});

app.addRoute({
    name: "home",
    path: "/",
    component: DashboardView,
    componentProps: {},
    caps: [],
});

app.addZone({
    key: "gait",
    label: "Gait",
    icon: GaitBlack,
    iconActive: GaitWhite,
});

app.addZone({
    key: "coaching",
    label: "Coaching",
    icon: CoachingBlack,
    iconActive: CoachingWhite,
});

app.addZone({
    key: "fit",
    label: "Fit / Size",
    icon: FitBlack,
    iconActive: FitWhite,
});

app.addZone({
    key: "lab",
    label: "Lab",
    icon: LabBlack,
    iconActive: LabWhite,
});

app.addZone({
    key: "field",
    label: "Field",
    icon: FieldBlack,
    iconActive: FieldWhite,
});

app.addZone({
    key: "people",
    label: "People",
    icon: PeopleBlack,
    iconActive: PeopleWhite,
});

//Add New Models Here
const extensions: any = [
    SettingsMods,
    RibbonMods,
    Defaults,
    Users,
    Media,

    // People
    Athletes,
    Fitters,
    Teams,

    // Fit
    Sessions,
    Exercises,
    Questionnaires,

    // Lab
    TestSessions,
    LabTests,
    TrainingZones,
    RatingScales,
    EventTarget,

    // Field
    FieldTemplates,
    FieldSessions,
    FieldRatingScales,
    FieldTests,

    //coaching
    Coaching,
    CoachingPlans,
    CoachingTrainingEndurance,
    CoachingTrainingIntervals,
    CoachingTrainingWeeks,
    CoachingEvents,

    //gait
    Gaits,

];

extensions.map(extension => {

    extension(app);

    return null;

});

const App: React.FC = () => {

    return (
        <Wrapper>
            {app.render()}
        </Wrapper>
    )

}

export default App;