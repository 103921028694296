import React, { useMemo } from 'react';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { Col, Form, Heading, Row, Seperator, Space, useApp, useForm } from '@dex/bubl-dash';
import SessionsEditTabsInfo from '../../Sessions/Components/SessionsEditTabsInfo';
import SessionsEditTabsSummary from '../../Sessions/Components/SessionsEditSummary';
import SessionsEditTabsBike from '../../Sessions/Components/SessionsEditTabsBike';
import SessionsEditTabsBio from '../../Sessions/Components/SessionsEditTabsBio';
import SessionsEditTabsCleat from '../../Sessions/Components/SessionsEditTabsCleat';
import SessionsEditTabsExercises from '../../Sessions/Components/SessionsEditTabsExercises';
import SessionsEditTabsFoot from '../../Sessions/Components/SessionsEditTabsFoot';
import SessionsEditTabsInjury from '../../Sessions/Components/SessionsEditTabsInjury';
import SessionsEditTabsMobility from '../../Sessions/Components/SessionsEditTabsMobility';
import SessionsEditTabsPosture from '../../Sessions/Components/SessionsEditTabsPosture';
import SessionsEditTabsSizing from '../../Sessions/Components/SessionsEditTabsSizing';
import SessionsEditTabsStability from '../../Sessions/Components/SessionsEditTabsStability';
import SessionsEditTabsSymmetry from '../../Sessions/Components/SessionsEditTabsSymmetry';
import SessionsEditTabsToeTouch from '../../Sessions/Components/SessionsEditTabsToeTouch';
import SessionsCreateEditForm from '../../Sessions/Components/SessionsCreateEditForm';

const AtheletsSessionBikeFit: React.FC<any> = (props: any) => {

    const { athlete, session, tab } = props;

    const app = useApp();

    const form = useForm(session);

    let TabElement: any = React.Fragment;

    if (tab === "info" || tab === "general" || !tab) TabElement = SessionsCreateEditForm;
    else if (tab === "bio") TabElement = SessionsEditTabsBio;
    else if (tab === "injury") TabElement = SessionsEditTabsInjury;
    else if (tab === "posture") TabElement = SessionsEditTabsPosture;
    else if (tab === "mobility") TabElement = SessionsEditTabsMobility;
    else if (tab === "symmetry") TabElement = SessionsEditTabsSymmetry;
    else if (tab === "stability") TabElement = SessionsEditTabsStability;
    else if (tab === "foot") TabElement = SessionsEditTabsFoot;
    else if (tab === "cleat") TabElement = SessionsEditTabsCleat;
    else if (tab === "bike") TabElement = SessionsEditTabsBike;
    else if (tab === "sizing") TabElement = SessionsEditTabsSizing;
    else if (tab === "exercises") TabElement = SessionsEditTabsExercises;
    else if (tab === "summary") TabElement = SessionsEditTabsSummary;
    else if (tab === "toeTouch") TabElement = SessionsEditTabsToeTouch;

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={() => { }}
            autoDiscardChanges
        >

            <div
                className={"form-not-editable"}
            >

                <TabElement
                    form={form}
                    data={session}
                    handleSubmit={() => { }}
                />

            </div>

        </Form>

    ), [props, tab]);

}

export default AtheletsSessionBikeFit;
