import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { DeletePanel } from '@dex/bubl-dash';
import FieldSessionsCreateEditForm from './FieldSessionsCreateEditForm';
import EditableContext from '../../../context/EditableContext';

const FieldSessionsEditTabsInfo: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <EditableContext.Provider value={true}>

                    <FieldSessionsCreateEditForm
                        mode={"edit"}
                        form={form}
                        data={data}
                        handleSubmit={handleSubmit}
                        process={process}
                    />

                </EditableContext.Provider>

            </Col>

            <Col
                className={'side'}
            >

                <DeletePanel
                    data={data}
                />

            </Col>

        </Row>


    ), [props]);

}

export default FieldSessionsEditTabsInfo;
