import { Col, FieldSelect, Heading, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import { fixedNum, get } from '@dex/bubl-helpers';
import FancyValue from '../../../elements/FancyValue/FancyValue';
import { calculateMinMaxAvgValues, getBottleImage, getEnabled, getFluidLimit, getFractionNumber, getPillImage, sweatTestGetActivities, sweatTestRehydration, sweatTestRehydrationFluidIntake, sweatTestRehydrationLossRate, sweatTestRehydrationSodiumIntake } from '../../../functions/sweatTest';
import SweatTestAverageChart from '../../../elements/SweatTestAverageChart/SweatTestAverageChart';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportSweatTestRehydration: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const gender = data.athlete.gender;

    const {
        finalAverageWeight, finalAverageHeight,
        finalAverageWeightLoss, finalAverageRehydration,
        finalAverageFluidLossRate, finalAverageFluidIntakeRate,
        finalAverageSodiumLossRate,
    } = sweatTestRehydration(form, data.test);

    const activities = sweatTestGetActivities(form);

    sweatTestRehydrationLossRate(form, finalAverageFluidLossRate, finalAverageSodiumLossRate);
    sweatTestRehydrationFluidIntake(form);
    sweatTestRehydrationSodiumIntake(form);

    const rehydration = form.values.rehydration;
    const allData = getEnabled(get(form.values, "workouts", []));
    const activity = get(form.values, "rehydration.activity", "All")
    const selectedData = activity == "All" ? allData : get(form.values.fluid.enabledData, `${activity}`,)

    const totalFluidIntake: any = calculateMinMaxAvgValues(selectedData, 'fluidIntakeRate');
    const totalFluidLoss: any = calculateMinMaxAvgValues(selectedData, 'fluidLossRate');

    const chartArray = [
        {},
        {
            ...totalFluidLoss,
            type: "Fluid Loss"
        },
        {
            ...totalFluidIntake,
            type: "Fluid Intake"
        },
        {
            avg: getFluidLimit(get(data.test, "rehydration.avgHeight")),
            type: "Gut Limit"
        },
        {
            avg: get(rehydration, 'rehydration'),
            min: fixedNum((totalFluidIntake?.min / totalFluidLoss?.min) * 100, 0),
            max: fixedNum((totalFluidIntake?.max / totalFluidLoss?.max) * 100, 0),
            type: "Rehydration",
            suffix: "%",
            total: 100,
        },
    ];

    const getRehydration = (intake, loss) => fixedNum((intake / loss) * 100, 0);

    return useMemo(() => (

        <>

            <Row edge gutter={"auto"} justify='center'>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldSelect
                        key={form.values.rehydration.activity}
                        form={form}
                        name='rehydration.activity'
                        defaultValue={form.values.rehydration?.activity}
                        label='Select Activity'
                        options={activities}
                        wrapClass={"w-400"}
                        isClearable={false}
                    />

                </Col>

            </Row>

            <Space height={"small"} />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 24 }}>

                    <ReportHeading
                        text="Rehydration During Workout"
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <SweatTestAverageChart
                        data={chartArray}
                        suffix="ml/hr"
                        title=""
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={'Average Rehydration'}
                        value={get(rehydration, 'rehydration')}
                        demographic={rehydration.rehydrationRating || data.demographic}
                        gender={gender}
                    />

                    <Space />

                    <ReportDesc
                        label=""
                        value={rehydration.lossRateDescription}
                    />

                </Col>

                <Col col={{ xs: 24 }}>
                    <ReportHeading
                        text="Fluid Intake Recommendations"
                    />
                </Col>

                <Col col={{ xs: 24 }}>

                    <Row edge gutter={"auto"}>

                        <Col col={{ xs: 3 }}>
                        </Col>

                        <Col col={{ xs: 7 }} align='center'>

                            <Row edge>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/LabReport/icon_cool.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/TrainingCard/Zone 1.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                            </Row>

                            <p style={{ textAlign: 'center' }}><strong>Cool/Easy</strong></p>
                            <p style={{ textAlign: 'center' }}>Avg</p>

                        </Col>

                        <Col col={{ xs: 7 }} align='center'>

                            <Row edge>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/LabReport/icon_warm.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/TrainingCard/Zone 4.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                            </Row>

                            <p style={{ textAlign: 'center' }}><strong>Warm/Moderate</strong></p>
                            <p style={{ textAlign: 'center' }}>Avg</p>

                        </Col>

                        <Col col={{ xs: 7 }} align='center'>

                            <Row edge>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/LabReport/icon_hot.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/TrainingCard/Zone 5.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                            </Row>

                            <p style={{ textAlign: 'center' }}><strong>Hot/Hard</strong></p>
                            <p style={{ textAlign: 'center' }}>Avg</p>

                        </Col>

                    </Row>

                    <Row edge gutter={"auto"}>

                        <Col col={{ xs: 3 }} justify='center'>
                            <strong>Intake Rate</strong>
                        </Col>

                        <Col col={{ xs: 7 }}>
                            <FancyValue value={rehydration.cool?.fluidIntakeAdjustedRate} suffix=" ml/hr" />
                        </Col>

                        <Col col={{ xs: 7 }}>
                            <FancyValue value={rehydration.warm?.fluidIntakeAdjustedRate} suffix=" ml/hr" />
                        </Col>

                        <Col col={{ xs: 7 }}>
                            <FancyValue value={rehydration.hot?.fluidIntakeAdjustedRate} suffix=" ml/hr" />
                        </Col>

                    </Row>

                    <Row edge gutter={"auto"}>

                        <Col col={{ xs: 3 }} justify='center'>
                            <strong>Rehydration</strong>
                        </Col>

                        <Col col={{ xs: 7 }} align='center'>
                            <strong style={{ fontSize: "1.5rem" }}>
                                {getRehydration(rehydration.cool?.fluidIntakeAdjustedRate, rehydration.cool?.fluidLossAdjustedRate)} %
                            </strong>
                        </Col>

                        <Col col={{ xs: 7 }} align='center'>
                            <strong style={{ fontSize: "1.5rem" }}>
                                {getRehydration(rehydration.warm?.fluidIntakeAdjustedRate, rehydration.warm?.fluidLossAdjustedRate)} %
                            </strong>
                        </Col>

                        <Col col={{ xs: 7 }} align='center'>
                            <strong style={{ fontSize: "1.5rem" }}>
                                {getRehydration(rehydration.hot?.fluidIntakeAdjustedRate, rehydration.hot?.fluidLossAdjustedRate)} %
                            </strong>
                        </Col>

                    </Row>

                    <Row edge gutter={"auto"}>

                        <Col col={{ xs: 3 }} justify='center'>
                            <strong>Water Bottle Equivalent</strong>
                        </Col>

                        <Col col={{ xs: 7 }}>
                            <div style={{ display: "flex", justifyContent: "center" }}>

                                {(getBottleImage(rehydration.cool?.fluidIntakeAdjustedRate) || []).map((item, index) => (

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {index >= 1 &&
                                            <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                        }
                                        <img
                                            src={require(`../../../assets/images/SweatTest/Bottle_Icon_${item}%.png`)}
                                            style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                            alt={`Bottle_Icon_${item}%.png`}
                                        />
                                    </div>

                                ))}

                            </div>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Heading>
                                    {getFractionNumber(getBottleImage(rehydration.cool?.fluidIntakeAdjustedRate), "Bottle")}
                                </Heading>
                            </div>
                        </Col>

                        <Col col={{ xs: 7 }}>
                            <div style={{ display: "flex", justifyContent: "center" }}>

                                {(getBottleImage(rehydration.warm?.fluidIntakeAdjustedRate) || []).map((item, index) => (

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {index >= 1 &&
                                            <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                        }
                                        <img
                                            src={require(`../../../assets/images/SweatTest/Bottle_Icon_${item}%.png`)}
                                            style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                            alt={`Bottle_Icon_${item}%.png`}
                                        />
                                    </div>

                                ))}

                            </div>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Heading>
                                    {getFractionNumber(getBottleImage(rehydration.warm?.fluidIntakeAdjustedRate), "Bottle")}
                                </Heading>
                            </div>
                        </Col>

                        <Col col={{ xs: 7 }}>
                            <div style={{ display: "flex", justifyContent: "center" }}>

                                {(getBottleImage(rehydration.hot?.fluidIntakeAdjustedRate) || []).map((item, index) => (

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {index >= 1 &&
                                            <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                        }
                                        <img
                                            src={require(`../../../assets/images/SweatTest/Bottle_Icon_${item}%.png`)}
                                            style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                            alt={`Bottle_Icon_${item}%.png`}
                                        />
                                    </div>

                                ))}

                            </div>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Heading>
                                    {getFractionNumber(getBottleImage(rehydration.hot?.fluidIntakeAdjustedRate), "Bottle")}
                                </Heading>
                            </div>
                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <ReportDesc
                        value={rehydration.fluidIntakeDescription}
                        label=""
                    />

                </Col>

                <Col col={{ xs: 24 }}>
                    <ReportHeading
                        text="Sodium (Salt) Intake Recommendations"
                    />
                </Col>

                <Col col={{ xs: 24 }}>

                    <Row edge gutter={"auto"}>

                        <Col col={{ xs: 3 }}>
                        </Col>

                        <Col col={{ xs: 7 }} align='center'>

                            <Row edge>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/LabReport/icon_cool.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/TrainingCard/Zone 1.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                            </Row>

                            <p style={{ textAlign: 'center' }}><strong>Cool/Easy</strong></p>
                            <p style={{ textAlign: 'center' }}>Avg</p>

                        </Col>

                        <Col col={{ xs: 7 }} align='center'>

                            <Row edge>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/LabReport/icon_warm.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/TrainingCard/Zone 4.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                            </Row>

                            <p style={{ textAlign: 'center' }}><strong>Warm/Moderate</strong></p>
                            <p style={{ textAlign: 'center' }}>Avg</p>

                        </Col>

                        <Col col={{ xs: 7 }} align='center'>

                            <Row edge>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/LabReport/icon_hot.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                                <Col col={{ xs: 12 }}>
                                    <img
                                        src={require(`../../../assets/images/TrainingCard/Zone 5.png`)}
                                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                        alt={`cool image`}
                                    />
                                </Col>
                            </Row>

                            <p style={{ textAlign: 'center' }}><strong>Hot/Hard</strong></p>
                            <p style={{ textAlign: 'center' }}>Avg</p>

                        </Col>

                    </Row>

                    <Row edge gutter={"auto"}>

                        <Col col={{ xs: 3 }} justify='center'>
                            <strong>Intake Rate</strong>
                        </Col>

                        <Col col={{ xs: 7 }}>
                            <FancyValue value={rehydration.cool?.sodiumIntakeAdjustedRate} suffix=" mg/hr" />
                        </Col>

                        <Col col={{ xs: 7 }}>
                            <FancyValue value={rehydration.warm?.sodiumIntakeAdjustedRate} suffix=" mg/hr" />
                        </Col>

                        <Col col={{ xs: 7 }}>
                            <FancyValue value={rehydration.hot?.sodiumIntakeAdjustedRate} suffix=" mg/hr" />
                        </Col>

                    </Row>

                    <Row edge gutter={"auto"}>

                        <Col col={{ xs: 3 }} justify='center'>
                            <strong>Rehydration</strong>
                        </Col>

                        <Col col={{ xs: 7 }} align='center'>
                            <strong style={{ fontSize: "1.5rem" }}>
                                {getRehydration(rehydration.cool?.sodiumIntakeAdjustedRate, rehydration.cool?.sodiumLossAdjustedRate)} %
                            </strong>
                        </Col>

                        <Col col={{ xs: 7 }} align='center'>
                            <strong style={{ fontSize: "1.5rem" }}>
                                {getRehydration(rehydration.warm?.sodiumIntakeAdjustedRate, rehydration.warm?.sodiumLossAdjustedRate)} %
                            </strong>
                        </Col>

                        <Col col={{ xs: 7 }} align='center'>
                            <strong style={{ fontSize: "1.5rem" }}>
                                {getRehydration(rehydration.hot?.sodiumIntakeAdjustedRate, rehydration.hot?.sodiumLossAdjustedRate)} %
                            </strong>
                        </Col>

                    </Row>

                    <Row edge gutter={"auto"}>

                        <Col col={{ xs: 3 }} justify='center'>
                            <strong>Sodium Pill Equivalent</strong>
                        </Col>

                        <Col col={{ xs: 7 }}>

                            <div style={{ display: "flex", justifyContent: "center" }}>

                                {(getPillImage(rehydration.cool?.sodiumIntakeAdjustedRate) || []).map((item, index) => (

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {index >= 1 &&
                                            <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                        }
                                        <img
                                            src={require(`../../../assets/images/SweatTest/Pill_${item}%.png`)}
                                            style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                            alt={`Bottle_Icon_${item}%.png`}
                                        />
                                    </div>

                                ))}

                            </div>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Heading>
                                    {getFractionNumber(getPillImage(rehydration.cool?.sodiumIntakeAdjustedRate), "Pill")}
                                </Heading>
                            </div>

                        </Col>

                        <Col col={{ xs: 7 }}>
                            <div style={{ display: "flex", justifyContent: "center" }}>

                                {(getPillImage(rehydration.warm?.sodiumIntakeAdjustedRate) || []).map((item, index) => (

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {index >= 1 &&
                                            <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                        }
                                        <img
                                            src={require(`../../../assets/images/SweatTest/Pill_${item}%.png`)}
                                            style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                            alt={`Bottle_Icon_${item}%.png`}
                                        />
                                    </div>

                                ))}

                            </div>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Heading>
                                    {getFractionNumber(getPillImage(rehydration.warm?.sodiumIntakeAdjustedRate), "Pill")}
                                </Heading>
                            </div>
                        </Col>

                        <Col col={{ xs: 7 }}>
                            <div style={{ display: "flex", justifyContent: "center" }}>

                                {(getPillImage(rehydration.hot?.sodiumIntakeAdjustedRate) || []).map((item, index) => (

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {index >= 1 &&
                                            <strong style={{ fontSize: "1.5rem" }}>+</strong>
                                        }
                                        <img
                                            src={require(`../../../assets/images/SweatTest/Pill_${item}%.png`)}
                                            style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                                            alt={`Bottle_Icon_${item}%.png`}
                                        />
                                    </div>

                                ))}

                            </div>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Heading>
                                    {getFractionNumber(getPillImage(rehydration.hot?.sodiumIntakeAdjustedRate), "Pill")}
                                </Heading>
                            </div>
                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <ReportDesc
                        value={rehydration.sodiumIntakeDescription}
                        label=""
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, data]);

}

export default TestSessionsReportSweatTestRehydration;