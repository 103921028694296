import React from 'react';

import styles from "./ReportDesc.module.scss";
import nl2br from 'react-nl2br';

const ReportDesc: React.FC<ReportDescProps> = (props) => {

    let { label, value, className, ...rest } = props;

    let classes = [styles.desc];

    if (className && Array.isArray(className)) classes = [...classes, ...className.map(name => styles[name])];
    else if (typeof className === "string") classes.push(styles[className]);

    return (

        <div
            className={classes.join(" ")}
            {...rest}
        >

            {label &&
                <span className={styles.label}>{nl2br(label)} </span>
            }
            {value &&
                <span className={styles.value}>{nl2br(value)}</span>
            }

        </div>

    )

}

interface ReportDescProps {
    [key: string]: any,
}

export default ReportDesc;