import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import InputTable, { InputTableCell, InputTableRow } from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import { FieldUpload } from '@dex/bubl-dash';

const SessionsEditTabsSizingSetup: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;

    const columns = [
        {
            key: "prop",
            label: "Properties",
            style: { width: 120 }
        },
        {
            key: "values",
            label: "Values",
            style: { width: 120 }
        },
    ]

    return useMemo(() => (

        <Row gutter={16} edge={true}>

            <Col col={{ xs: 24 }} >

                <InputTable
                    columns={columns}
                >

                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            <><strong>Stem Size</strong></>
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldText
                                form={form}
                                wrapClass={"w-300"}
                                name={`${prefix}.stemSize`}
                                defaultValue={bike.stemSize}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>
                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            <><strong>Spacers</strong></>
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-300"}
                                name={`${prefix}.spacers`}
                                defaultValue={bike.spacers}
                                suffix={' mm'}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>
                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            <><strong>Bar Reach</strong></>
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-300"}
                                name={`${prefix}.barReach`}
                                defaultValue={bike.barReach}
                                suffix={' mm'}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>
                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            <><strong>Seatpost Offset</strong></>
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-300"}
                                name={`${prefix}.seatpostOffset`}
                                defaultValue={bike.seatpostOffset}
                                suffix={' mm'}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>
                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            <><strong>Crank Length</strong></>
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-300"}
                                name={`${prefix}.crankLength`}
                                defaultValue={bike.crankLength}
                                suffix={' mm'}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                </InputTable>

            </Col>

            <Col col={{ xs: 18, sm: 12, md: 8 }}>

                <FieldUpload
                    form={form}
                    name={`${prefix}.bikeSizingPhoto`}
                    folder='admin-media'
                    label='Bike Sizing Photo'
                    defaultValue={bike.bikeSizingPhoto}
                    multiple={false}
                    accept='image/*'
                />

            </Col>

        </Row>


    ), [props]);

}

export default SessionsEditTabsSizingSetup;
