import { Row, Col, FieldSelect, FieldTextArea, FieldNumber, InputTable, InputTableCell, InputTableRow, FieldTextTime, useUpdateEffect } from '@dex/bubl-dash';
import { fixedNum, get } from '@dex/bubl-helpers';
import React, { useEffect, useMemo } from 'react';
import { getDecimalTime } from '../../../functions/sharedTests';
import { getPercentHr, getVo2Estimate } from '../../../functions/sweatTest';

const LabTestsSweatWorkoutInfo: React.FC<any> = (props: any) => {

    const { form, data, prefix, workout, session } = props;

    const hrMax = parseFloat(get(form.values, `currentMax.currentHrMax`));
    const vo2Max = parseFloat(get(form.values, `currentMax.currentVo2Max`));
    const averageHr = get(form.values, `${prefix}.avgHr`);
    const intensity = get(form.values, `${prefix}.intensity`);
    const averagePower = get(form.values, `${prefix}.avgPower`);
    const activity = get(form.values, `${prefix}.activity`);

    const percentHr = fixedNum(getPercentHr(averageHr, intensity, hrMax), 1);

    let getAverageSpeed;

    if (activity !== "Cycling") {
        getAverageSpeed = (1 / getDecimalTime(averagePower)) * 60;
    }

    const estimateVo2Relative = fixedNum(getVo2Estimate(vo2Max, averageHr, percentHr, intensity, activity, form, prefix), 2);
    const estimateVo2Absolute = fixedNum((estimateVo2Relative * (get(form.values, `${prefix}.preWeight`) || session.bio.weight)) / 1000, 2);
    const percentVo2max = (estimateVo2Relative / vo2Max) * 100

    const getEstimateRER = fixedNum((0.4 * (percentVo2max / 100)) + 0.6);

    useUpdateEffect(() => {

        form.handleChange({ silent: true, name: `${prefix}.percentHrmax`, value: percentHr });
        form.handleChange({ silent: true, name: `${prefix}.estimateVo2Relative`, value: estimateVo2Relative });
        form.handleChange({ silent: true, name: `${prefix}.estimateVo2Absolute`, value: estimateVo2Absolute });
        form.handleChange({ silent: true, name: `${prefix}.rerEstimated`, value: getEstimateRER });
        form.handleChange({ silent: true, name: `${prefix}.avgSpeed`, value: fixedNum(getAverageSpeed, 2) });
        form.handleChange({ silent: true, name: `${prefix}.percentVo2max`, value: fixedNum(percentVo2max, 0) });

    }, [estimateVo2Relative, percentHr, getAverageSpeed]);

    return useMemo(() => (

        <>

            <Row gutter={8} edge>

                <Col col={{ xs: 24 }}>
                    <FieldTextArea
                        form={form}
                        label='Workout Description'
                        name={`${prefix}.description`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.description}
                    />
                </Col>

                <InputTable>

                    <InputTableRow>

                        <InputTableCell>
                            <strong>Workout Duration</strong>
                        </InputTableCell>

                        <InputTableCell>

                            <FieldTextTime
                                form={form}
                                name={`${prefix}.duration`}
                                wrapClass={"manual-input"}
                                defaultValue={workout.duration}
                                placeholder="hh:mm"
                                maxHours={99}
                            />

                        </InputTableCell>

                        <InputTableCell>
                            <strong>%HRmax</strong>
                        </InputTableCell>

                        <InputTableCell>

                            <FieldNumber
                                form={form}
                                name={`${prefix}.percentHrmax`}
                                defaultValue={workout.percentHrmax}
                                dynamicValue={workout.percentHrmax}
                                suffix=' %'
                                wrapClass={"manual-input"}
                            />

                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>
                            <strong>Intensity</strong>
                        </InputTableCell>

                        <InputTableCell>
                            <FieldSelect
                                form={form}
                                name={`${prefix}.intensity`}
                                wrapClass={"manual-input"}
                                defaultValue={workout.intensity}
                                options={[
                                    { value: "very easy", label: "Very Easy" },
                                    { value: "easy", label: "Easy" },
                                    { value: "moderate", label: "Moderate" },
                                    { value: "somewhat hard", label: "Somewhat Hard" },
                                    { value: "hard", label: "Hard" },
                                    { value: "very hard", label: "Very Hard" },
                                    { value: "maximal", label: "Maximal" },
                                    { value: "not indicated", label: "Not Indicated" },
                                ]}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <strong>VO2 Estimated</strong>
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.estimateVo2Absolute`}
                                defaultValue={workout.estimateVo2Absolute}
                                dynamicKey={workout.estimateVo2Absolute}
                                suffix=" L/min"
                                wrapClass={"manual-input"}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>
                            <strong>Avg HR</strong>
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.avgHr`}
                                wrapClass={"manual-input"}
                                defaultValue={workout.avgHr}
                                suffix=" bpm"
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <strong>VO2 Estimated</strong>
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.estimateVo2Relative`}
                                defaultValue={workout.estimateVo2Relative}
                                dynamicKey={workout.estimateVo2Relative}
                                suffix=" ml*kg/min"
                                wrapClass={"manual-input"}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>
                            <strong> {activity !== "Cycling" ? "Avg Pace (min/km)" : "Avg Power"}</strong>
                        </InputTableCell>

                        <InputTableCell>
                            {activity !== "Cycling" ?
                                <FieldTextTime
                                    form={form}
                                    name={`${prefix}.avgPower`}
                                    wrapClass={"manual-input"}
                                    defaultValue={workout.avgPower}
                                    placeholder="mm:ss"
                                    suffix=" min/km"
                                />
                                :
                                <FieldNumber
                                    form={form}
                                    name={`${prefix}.avgPower`}
                                    wrapClass={"manual-input"}
                                    defaultValue={workout.avgPower}
                                    suffix=" W"
                                />
                            }
                        </InputTableCell>

                        <InputTableCell>
                            <strong>%Vo2max</strong>
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.percentVo2max`}
                                defaultValue={workout.percentVo2max}
                                dynamicKey={workout.percentVo2max}
                                wrapClass={"manual-input"}
                                suffix=" %"
                            />
                        </InputTableCell>

                    </InputTableRow>


                    <InputTableRow>

                        {activity !== "Cycling" &&
                            <>
                                <InputTableCell>
                                    <strong>Avg Speed</strong>
                                </InputTableCell>

                                <InputTableCell>
                                    <FieldNumber
                                        form={form}
                                        name={`${prefix}.avgSpeed`}
                                        defaultValue={workout.avgSpeed}
                                        dynamicKey={workout.avgSpeed}
                                        wrapClass={"manual-input"}
                                        suffix=" kph"
                                    />
                                </InputTableCell>
                            </>
                        }

                        <InputTableCell>
                            <strong>RER Estimated</strong>
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.rerEstimated`}
                                defaultValue={workout.rerEstimated}
                                dynamicKey={workout.rerEstimated}
                                wrapClass={"manual-input"}
                            />
                        </InputTableCell>

                    </InputTableRow>

                </InputTable>

            </Row>

        </>

    ), [form.hash, data, session]);

}

export default LabTestsSweatWorkoutInfo;
