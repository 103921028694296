import { DataTableCell, InputTableRow, InputTable, Link, Modal, Panel, Button, ButtonRow } from '@dex/bubl-dash';
import React, { useCallback, useMemo, useState } from 'react';
import { getClockTime, getMinutesTime } from '../../../functions/sharedTests';
import { date, fixedNum, get, loop, titleCase } from '@dex/bubl-helpers';
import CoachingTrainingsEditEndurance from './CoachingTrainingsEditEndurance';

const CoachingTrainingsWeekEnduranceTable: React.FC<any> = (props: any) => {

    const { filters, data, weeks } = props;

    const calculateWeekAverageMax = (data, type, inputFormat) => {

        let average: any = 0;
        let max: any = 0;
        let min: any = Infinity;
        let count: number = 0;

        loop(data, (_week, index) => {

            let value;

            if (inputFormat == "time" && get(_week, type)) {
                value = getMinutesTime(get(_week, type))
            } else {
                value = parseFloat(get(_week, type));
            }

            if (!isNaN(value)) {
                count++;
                average += value;
                if (value > max) {
                    max = value;
                }
                if (value < min) {
                    min = value;
                }
            }

        })

        average = average / count || 0;

        if (inputFormat == "time") {
            average = getClockTime(average / 60)
            max = getClockTime(max / 60)
            min = getClockTime(min / 60)
        } else {
            average = fixedNum(average, 0)
        }

        return { average, max, min }

    }

    const handleRefresh = useCallback(() => {

        setEditWeek(null);

        filters.triggerSubmit();

    }, []);

    const [editWeek, setEditWeek] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const handlePrevClick = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const handleNextClick = () => {
        setCurrentPage((prevPage) => {
            const maxPage = Math.ceil(weeks?.length / itemsPerPage) - 1;
            return Math.min(prevPage + 1, maxPage);
        });
    };

    const startIndex = currentPage * itemsPerPage;

    const displayedWeeks = weeks?.slice(startIndex, startIndex + itemsPerPage);

    return useMemo(() => (

        <>

            {editWeek &&
                <Modal
                    show={true}
                    onClose={handleRefresh}
                    width={1920}
                >
                    <Panel heading={"Edit Endurance Workout"}>
                        <CoachingTrainingsEditEndurance
                            data={data}
                            handleClose={handleRefresh}
                            week={editWeek}
                        />
                    </Panel>
                </Modal>
            }

            <InputTable className={"mini-table"} style={{ overflow: "auto", minHeight: 450, maxHeight: 850 }}>

                <InputTableRow >

                    <DataTableCell className={"table-bdr-none"} />

                    <DataTableCell className="table-bdr-top-none table-bdr-btm-none" colSpan={2} />

                    <DataTableCell className="table-bdr-top-none table-bdr-btm-none table-bdr-right-gray" colSpan={4} alignEnd>

                        <ButtonRow>

                            <Button
                                onClick={handlePrevClick}
                                disabled={currentPage === 0}
                                label="Previous Page"
                                size="small"
                                type={currentPage === 0 ? "disabled" : 'faded'}
                            />

                            <Button
                                onClick={handleNextClick}
                                disabled={currentPage === Math.ceil(weeks?.length / itemsPerPage) - 1}
                                label="Next Page"
                                size="small"
                                type={currentPage === Math.ceil(weeks?.length / itemsPerPage) - 1 ? "disabled" : 'faded'}
                            />

                        </ButtonRow>

                    </DataTableCell>

                    <DataTableCell className="table-bg-gray" >
                        <strong>Max/Best</strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray">
                        {calculateWeekAverageMax(weeks, "duration", "time").max}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "distance", "").max}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "avgSpeed", "").max}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "avgPace", "time").min}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "raceTime", "time").max}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "powerAvg", "").max}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "powerNorm", "").max}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "hrAvg", "").max}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "hrPeak", "").max}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "elevation", "").max}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "fluidLossRate", "").max}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "fluidIntakeRehydrate", "").max}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "carbIntakeRate", "").max}
                    </DataTableCell>

                    <DataTableCell colSpan={6} rowSpan={3} className="table-bdr-left-gray " />

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell className={"table-bdr-none"} />

                    <DataTableCell className="table-bdr-top-none table-bdr-btm-none table-bdr-right-gray" colSpan={6} />

                    <DataTableCell className="table-bg-gray" >
                        <strong>
                            Average
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray">
                        {calculateWeekAverageMax(weeks, "duration", "time").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "distance", "").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "avgSpeed", "").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "avgPace", "time").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "raceTime", "time").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "powerAvg", "").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "powerNorm", "").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "hrAvg", "").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "hrPeak", "").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "elevation", "").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "fluidLossRate", "").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "fluidIntakeRehydrate", "").average}
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray table-bg-gray" >
                        {calculateWeekAverageMax(weeks, "carbIntakeRate", "").average}
                    </DataTableCell>

                </InputTableRow>

                <InputTableRow >

                    <DataTableCell />

                    <DataTableCell />

                    <DataTableCell />

                    <DataTableCell />

                    <DataTableCell />

                    <DataTableCell />

                    <DataTableCell />

                    <DataTableCell />

                    <DataTableCell className="table-bdr-left-gray" >
                        <strong style={{ whiteSpace: "pre-wrap" }}>Duration</strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>Distance</strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong> Speed Avg</strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>Pace Avg</strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray" >
                        <strong style={{ whiteSpace: "pre-wrap" }}>Time Race</strong>
                    </DataTableCell>

                    <DataTableCell colSpan={2} className="table-bdr-left-gray">
                        <strong >Power</strong>
                    </DataTableCell>

                    <DataTableCell colSpan={2} className="table-bdr-left-gray">
                        <strong style={{ whiteSpace: "pre-wrap" }}>HR</strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong style={{ whiteSpace: "pre-wrap" }}>Elevation</strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong style={{ whiteSpace: "pre-wrap" }}>Fluid Loss Rate</strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong style={{ whiteSpace: "pre-wrap" }}>Rehydrate</strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong style={{ whiteSpace: "pre-wrap" }}> Carb Fuel Rate</strong>
                    </DataTableCell>

                </InputTableRow>

                <InputTableRow className={"table-bg-gray "} >

                    <DataTableCell>
                    </DataTableCell>

                    <DataTableCell>
                        <strong>
                            Activity
                        </strong>
                    </DataTableCell>

                    <DataTableCell>
                        <strong>
                            Calendar #
                        </strong>
                    </DataTableCell>

                    <DataTableCell>
                        <strong>
                            Phase
                        </strong>
                    </DataTableCell>

                    <DataTableCell>
                        <strong>
                            Block
                        </strong>
                    </DataTableCell>

                    <DataTableCell>
                        <strong>
                            Block #
                        </strong>
                    </DataTableCell>

                    <DataTableCell>
                        <strong>
                            Week #
                        </strong>
                    </DataTableCell>

                    <DataTableCell>
                        <strong>
                            Workout Date
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            hh:mm
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            km
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            kph
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            min/km
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            hh:mm
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            Avg
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            Norm
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            Avg
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            Peak
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            m
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            ml/hr
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            %
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            g/hr
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            Mode
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            Type
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            Location
                        </strong>
                    </DataTableCell>

                    <DataTableCell className="table-bdr-left-gray">
                        <strong>
                            Remarks
                        </strong>
                    </DataTableCell>

                </InputTableRow>

                {weeks && displayedWeeks.map((w, i) => (

                    <InputTableRow key={w.id} className={`table-bg-zone-${i % 2 === 0 ? 3 : ""}`} >

                        <DataTableCell>
                            <Link onClick={setEditWeek.bind(null, w)}>
                                Edit Workout
                            </Link>
                        </DataTableCell>

                        <DataTableCell>
                            {titleCase(w.activity)}
                        </DataTableCell>

                        <DataTableCell>
                            {w.weekNo}
                        </DataTableCell>

                        <DataTableCell>
                            {w.week.phase}
                        </DataTableCell>

                        <DataTableCell>
                            {w.week.block}
                        </DataTableCell>

                        <DataTableCell>
                            {w.week.blockNum}
                        </DataTableCell>

                        <DataTableCell>
                            {w.week.week}
                        </DataTableCell>

                        <DataTableCell>
                            {date(w.workoutDate, "YYYY-MM-DD")}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "duration", "time").max == w.duration ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.duration}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "distance", "").max == w.distance ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.distance}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "avgSpeed", "").max == w.avgSpeed ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.avgSpeed}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "avgPace", "time").min == w.avgPace ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.avgPace}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "raceTime", "time").max == w.raceTime ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.raceTime}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "powerAvg", "").max == w.powerAvg ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.powerAvg}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "powerNorm", "").max == w.powerNorm ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.powerNorm}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "hrAvg", "").max == w.hrAvg ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.hrAvg}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "hrPeak", "").max == w.hrPeak ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.hrPeak}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "elevation", "").max == w.elevation ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.elevation}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "fluidLossRate", "").max == w.fluidLossRate ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.fluidLossRate}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "fluidIntakeRehydrate", "").max == w.fluidIntakeRehydrate ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.fluidIntakeRehydrate}
                        </DataTableCell>

                        <DataTableCell className={calculateWeekAverageMax(weeks, "carbIntakeRate", "").max == w.carbIntakeRate ? "table-bg-red table-bdr-left-gray" : "table-bdr-left-gray"}>
                            {w.carbIntakeRate}
                        </DataTableCell>

                        <DataTableCell className="table-bdr-left-gray">
                            {w.mode}
                        </DataTableCell>

                        <DataTableCell className="table-bdr-left-gray">
                            {w.type}
                        </DataTableCell>

                        <DataTableCell className="table-bdr-left-gray">
                            {w.location}
                        </DataTableCell>

                        <DataTableCell className="table-bdr-left-gray">
                            {w.remarks}
                        </DataTableCell>

                    </InputTableRow>
                ))}

            </InputTable>

        </>

    ), [data, weeks, editWeek, currentPage])
}


export default CoachingTrainingsWeekEnduranceTable
