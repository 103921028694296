import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, useApp, useForm } from '@dex/bubl-dash';
import { loopAsync } from '@dex/bubl-helpers';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReportContext from '../../../context/ReportContext';

import styles from "./GaitReport.module.scss";
import GaitReportPages from './GaitReportPages';

const GaitReport: React.FC<any> = (props: any) => {

    const canvasRef: any = useRef();

    const { data } = props;

    const form = useForm({ ...data.test, athlete: data.athlete });

    const app = useApp();

    const [printing, setPrinting] = useState(false);

    const dpi = 1;
    // const dpi = window.devicePixelRatio;

    const css = `
        #root {
            height: auto;
            overflow: inherit;
        }
        #page {
            flex: 0;
            overflow: inherit;
        }
        body {
            min-width: 992px;
        }
    `;

    useEffect(() => {

        app.setTitle(data.reportTitle + " Report");

    }, [data]);

    const getImageDimensions = useCallback((file) => {

        return new Promise(function (resolved, rejected) {
            var i = new Image()
            i.onload = function () {
                resolved({ w: i.width, h: i.height })
            };
            i.src = file
        });

    }, []);

    const handlePDf = useCallback(async () => {

        const pages = document.querySelectorAll('.report-page');

        const canvas: any = canvasRef.current;

        if (!canvas) return;

        setPrinting(true);

        try {

            const context = canvas.getContext("2d");

            context.save();

            const options = {
                canvas: canvas,
                windowWidth: 960 * dpi,
                windowHeight: 3508 * dpi,
                allowTaint: true,
                useCORS: true,
                scale: dpi,
                logging: false,
            };

            const images: any = [];

            await loopAsync(pages, async page => {

                const w = page.clientWidth * dpi;
                const h = page.clientHeight * dpi;

                options.windowHeight = h;

                canvas.height = h;

                const draw = await html2canvas(page, options);

                const src = draw.toDataURL('image/png');

                // const dimensions: any = await getImageDimensions(src);

                images.push({ src: src, w: w, h: h });

                context.restore();

            });

            const pdf = new jsPDF({
                orientation: "portrait",
                unit: "px",
                compress: true,
                format: [images[0].w, images[0].h]
            });

            images.forEach((image, index) => {

                if (index > 0) pdf.addPage([image.w, image.h]);

                pdf.addImage(image.src, 'PNG', 0, 0, image.w, image.h);

            });

            pdf.save(`${data.reportTitle} ${data.athlete.fullName}.pdf`);

        } catch (e) {

            console.error(e);

        }

        setPrinting(false);

    }, []);

    return useMemo(() => (

        <Box>

            <Button
                className={styles.pdfButton}
                label={"Save as PDF"}
                size={"small"}
                type={"secondary"}
                onClick={handlePDf}
                loading={printing}
            />

            <style>{css}</style>

            {data &&
                <ReportContext.Provider value={{ report: true, print: printing }}>
                    <div className={styles.reports}>

                        <GaitReportPages
                            data={data}
                            form={form}
                        />

                    </div>
                </ReportContext.Provider>
            }

            <canvas
                ref={canvasRef}
                width={960 * dpi}
                height={1090}
                className={styles.canvas}
            >
            </canvas>

        </Box>

    ), [form.hash, data, printing]);

}

export default GaitReport;
