import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Space } from '@dex/bubl-dash';
import { parseNum } from '@dex/bubl-helpers';
import TestSessionsReportMenu from './TestSessionsReportMenu';
import TestSessionsReportPage from './TestSessionsReportPage';

import styles from "./TestSessionsReport.module.scss";
import TestSessionsReportSweatTestFluid from './TestSessionsReportSweatTestFluid';
import TestSessionsReportSweatTestWorkout from './TestSessionsReportSweatTestWorkout';
import TestSessionsReportSweatTestRehydration from './TestSessionsReportSweatTestRehydration';
import TestSessionsReportSweatTestDehydration from './TestSessionsReportSweatTestDehydration';
import TestSessionsReportSweatTestComment from './TestSessionsReportSweatTestComment';
import TestSessionsReportSweatTestCover from './TestSessionsReportSweatTestCover';
import TestSessionsReportSweatTestPage from './TestSessionsReportSweatTestPage';

const TestSessionsReportSweatTest: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const [activePage, setActivePage] = useState(0);

    const observer: any = useRef();

    const pages: any = [
        {
            key: "cover",
            label: "Cover",
            heading: false,
            el: TestSessionsReportSweatTestCover,
            ref: useRef()
        },
        {
            key: "fluid",
            label: "Fluid And Sodium Loss",
            heading: "Fluid And Sodium Loss",
            icon: require("../../../assets/images/LabReport/icon-fluidSodiumLoss.png"),
            el: TestSessionsReportSweatTestFluid,
            ref: useRef()
        },
        {
            key: "rehydration",
            label: "Rehydration",
            heading: "Rehydration",
            icon: require("../../../assets/images/LabReport/icon-hydration.png"),
            el: TestSessionsReportSweatTestRehydration,
            ref: useRef()
        },
        {
            key: "dehydration",
            label: "Dehydration",
            heading: "Dehydration",
            icon: require("../../../assets/images/LabReport/icon-dehydration.png"),
            el: TestSessionsReportSweatTestDehydration,
            ref: useRef()
        },
        {
            key: "workout",
            label: "Workout",
            heading: "Workout Details",
            icon: require("../../../assets/images/LabReport/icon-workoutDetails.png"),
            el: TestSessionsReportSweatTestWorkout,
            ref: useRef()
        },
        {
            key: "comment",
            label: "Comment",
            heading: "Appraiser's Comment",
            icon: require("../../../assets/images/LabReport/icon-summary.png"),
            el: TestSessionsReportSweatTestComment,
            ref: useRef()
        },
        {
            key: "tip",
            label: "Tips",
            heading: "Helpful Guidlines and Tips",
            icon: require("../../../assets/images/LabReport/icon-helpfulGuidelines.png"),
            el: TestSessionsReportSweatTestCover,
            ref: useRef()
        }
    ].filter((page) => {

        if (!data.reportPages || !data.reportPages.length) return true;

        return data.reportPages.includes(page.key) ? true : false;

    });

    useEffect(() => {

        const callback = (entries, observer) => {

            let inViewTarget: any = null;
            let inViewRatio = 0;

            entries.forEach((entry, index) => {

                if (entry.isIntersecting && entry.intersectionRatio > inViewRatio) {

                    inViewRatio = entry.intersectionRatio;
                    inViewTarget = entry.target;

                }

            })

            if (inViewTarget) setActivePage(parseNum(inViewTarget.id));

        };

        const observer = new IntersectionObserver(callback, {
            threshold: .4,
        });

        pages.map((page) => {

            observer.observe(page.ref.current);

        });

        () => {

            pages.map((page) => {

                observer.unobserve(page.ref.current);

            });

            observer.disconnect();

        }

    }, []);

    const handleScrollTo = useCallback((ref, event) => {

        if (event && event.preventDefault) event.preventDefault();

        ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

    }, []);

    return useMemo(() => (

        <>

            <TestSessionsReportMenu
                data={data}
                activePage={activePage}
                pages={pages}
                handleScrollTo={handleScrollTo}
                form={form}
            />

            {pages.map((page, index) => (
                <React.Fragment key={index}>

                    {index >= 0 &&
                        <Space />
                    }

                    <TestSessionsReportSweatTestPage
                        heading={page.heading}
                        name={data.athlete.fullName}
                        icon={page.icon}
                        index={index}
                        observer={observer}
                        forwardedRef={page.ref}
                        setActivePage={setActivePage}
                        form={form}
                    >

                        {page.el &&
                            <page.el
                                data={data}
                                form={form}
                            />
                        }

                    </TestSessionsReportSweatTestPage>


                </React.Fragment>
            ))}

        </>

    ), [form.hash, data, activePage]);

}

export default TestSessionsReportSweatTest;