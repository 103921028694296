import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line'
import { getArmRatio, getLegRatio, getThighShinRatio, getArmProportion, getLegProportion, getThighShinProportion } from '../../../functions/sharedBikeFit';
import GaitsEditTabsPanel from './GaitsEditTabsPanel';

const GaitsEditTabsBio: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => {

        const torsoLength = form.values.bio.height - form.values.bio.inseam;
        const armRatio = getArmRatio(form.values.bio.armSpan, form.values.bio.height);
        const legRatio = getLegRatio(form.values.bio.inseam, form.values.bio.height);
        const shinRatio = getThighShinRatio(form.values.bio.shinLength, form.values.bio.thighLength);

        const armProportion = getArmProportion(armRatio)
        const legProportion = getLegProportion(legRatio)
        const thighShinProportion = getThighShinProportion(shinRatio)

        return (

            <GaitsEditTabsPanel
                heading={"Bio Data"}
                {...props}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, lg: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.weight"
                                    label="Weight (kg)"
                                    suffix={" kg"}
                                    defaultValue={form.defaults.bio.weight}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.height"
                                    label="Height (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.height}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.shoulderWidth"
                                    label="Shoulder Width (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.shoulderWidth}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.armLength"
                                    label="Arm Length (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.armLength}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.inseam"
                                    label="Inseam (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.inseam}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            {/* <Col col={{ xs: 24, md: 24 }}>

                                <Line top={null} bottom={null} />

                            </Col> */}

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.armSpan"
                                    label="Arm Span (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.armSpan}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.shoulderHeight"
                                    label="Shoulder Height (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.shoulderHeight}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            {/* <Col col={{ xs: 24, md: 24 }}>

                                <Line top={null} bottom={null} />

                            </Col> */}

                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.thighLength"
                                    label="Thigh Length (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.thighLength}
                                    wrapClass={"manual-input"}
                                />

                            </Col>


                            <Col col={{ xs: 24, sm: 12, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    name="bio.shinLength"
                                    label="Shin Length (cm)"
                                    suffix={" cm"}
                                    defaultValue={form.defaults.bio.shinLength}
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, sm: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    disabled
                                    name="bio.torsoLength"
                                    label="Torso Length (cm)"
                                    suffix={" cm"}
                                    key={torsoLength}
                                    defaultValue={torsoLength.toFixed(2)}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>
                                <Line />
                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    disabled
                                    name="bio.armRatio"
                                    label="Arm Ratio"
                                    key={armRatio}
                                    defaultValue={armRatio}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    disabled
                                    name="bio.legRatio"
                                    label="Leg Ratio"
                                    key={legRatio}
                                    defaultValue={legRatio}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    disabled
                                    name="bio.thighShinRatio"
                                    label="Thigh-Shin Ratio"
                                    key={shinRatio}
                                    defaultValue={shinRatio}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldText
                                    form={form}
                                    disabled
                                    name="bio.armProportion"
                                    label="Arm Proportion"
                                    key={armProportion}
                                    defaultValue={armProportion}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldText
                                    form={form}
                                    disabled
                                    name="bio.legProportion"
                                    label="Leg Proportion"
                                    key={legProportion}
                                    defaultValue={legProportion}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldText
                                    form={form}
                                    disabled
                                    name="bio.thighShinProportion"
                                    label="Thigh-Shin Proportion"
                                    value={thighShinProportion}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, lg: 8 }} align='center' justify='center'>

                        {form.values.athlete.gender === 'female' &&

                            <img src={require('../../../assets/images/BioStandingFemale.png')}
                                alt="Bio Standing"
                                className="mobilityImage"
                            />

                        }

                        {form.values.athlete.gender === 'male' &&

                            <img src={require('../../../assets/images/BioStandingMale.png')}
                                alt="Bio Standing"
                                className="mobilityImage"
                            />

                        }

                    </Col>

                </Row>

            </GaitsEditTabsPanel>

        )

    }, [props, form.hash]);

}

export default GaitsEditTabsBio;
