import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldSelect, FieldText, FieldDate, FieldHidden, FieldNumber, FieldTextTime, InputTable, InputTableCell, InputTableRow, useUpdateEffect } from '@dex/bubl-dash';
import { fixedNum, get, hashCode, loop } from '@dex/bubl-helpers';
import { getSwimPace, getPace, getSpeed, getDecimalTime, getClockTime, getMinutesTime } from '../../../functions/sharedTests';
import { getTableRow, glycogenEventList } from '../../../functions/glycogenTest';
import BigNumber from 'bignumber.js';

const TestSessionsEditTabsEventsFormDetails: React.FC<any> = (props: any) => {

    const { form, data, process, prefix } = props;

    const event = get(form.values, `${prefix}.type`);
    const events = event ? getTableRow(event) : [];

    useUpdateEffect(() => {

        const activities: any = [];

        loop(events, (row, index) => {

            activities[index] = { distance: row.distance };

        });

        form.handleChange({ name: `${prefix}.activities`, value: activities, reset: true });

    }, [event]);

    return useMemo(() => (

        <Row gutter={"auto"} edge>

            <Col col={{ xs: 24, md: 8 }}>
                <FieldSelect
                    form={form}
                    name={`${prefix}.type`}
                    defaultValue={get(form.defaults, `${prefix}.type`)}
                    label='Event Type'
                    options={glycogenEventList}
                    required
                    wrapClass={"manual-input"}
                />
            </Col>

            <Col col={{ xs: 24, md: 8 }}>
                <FieldText
                    form={form}
                    name={`${prefix}.name`}
                    defaultValue={get(form.defaults, `${prefix}.name`)}
                    label='Event Name'
                    required
                    wrapClass={"manual-input"}
                />
            </Col>

            <Col col={{ xs: 24, md: 8 }}>
                <FieldDate
                    form={form}
                    name={`${prefix}.date`}
                    defaultValue={get(form.defaults, `${prefix}.date`)}
                    label='Date'
                    wrapClass={"manual-input"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <Space />

                <CalculationTable
                    key={event}
                    event={event}
                    events={events}
                    form={form}
                    prefix={prefix}
                />

            </Col>

        </Row>

    ), [form.hash]);

}

const CalculationTable: React.FC<any> = (props: any) => {

    const { event, events, form, prefix } = props;

    const column = [
        {
            key: "type",
            style: { width: "220px" }
        },
        {
            key: "distance",
            label: "Distance"
        },
        {
            key: "time",
            label: "Time"
        },
        {
            key: "pace",
            label: "Pace"
        },
        {
            key: "speed",
            label: "Speed"
        },
        {
            key: "power",
            label: "Power"
        },
    ];

    const calculatePace = (type, distance, time) => {

        if (!distance || !time) return 0

        if (type == "Swim") {
            return getSwimPace(distance, time)
        }

        return getPace(distance, time)

    };

    const calculateSpeed = (distance, time) => {

        if (!distance || !time) return 0

        return getSpeed(distance, time)

    };

    useUpdateEffect(() => {

        const activities = get(form.values, `${prefix}.activities`);

        const totals = activities.reduce((acc, obj) => {

            if (!obj.distance) obj.distance = 0;
            if (!obj.speed) obj.speed = 0;
            if (!obj.time) obj.time = 0;
            if (!obj.pace) obj.pace = 0;

            return {
                distance: parseFloat(acc.distance) + parseFloat(obj.distance),
                time: getDecimalTime(obj.time) + acc.time,
                // speed: fixedNum(parseFloat(acc.speed) + parseFloat(obj.speed), 2),
                // pace: getDecimalTime(obj.pace) + acc.pace,
            };

        }, { pace: 0, distance: 0, time: 0, speed: 0 });

        if (totals.distance && totals.time) {
            totals.speed = getSpeed(totals.distance, getClockTime(totals.time));
            totals.pace = getDecimalTime(getPace(225.8, getClockTime(totals.time)));
        }

        form.handleChange({ reset: true, name: `${prefix}.overall`, value: totals });

    }, [hashCode(get(form.values, `${prefix}.activities`, []))]);

    const overall = get(form.values, `${prefix}.overall`, {});

    return (

        <InputTable
            columns={column}
            key={event}
        >

            {events.map((item, index) => {

                const distance = get(form.values, `${prefix}.activities[${index}].distance`);
                const time = get(form.values, `${prefix}.activities[${index}].time`);

                const pace = calculatePace(item.type, distance, time);
                const speed = calculateSpeed(distance, time);

                const power = ""; //todo need formula from patrick

                return (
                    <InputTableRow>

                        <InputTableCell>
                            <strong>{item.type}</strong>
                            <FieldHidden
                                form={form}
                                name={`${prefix}.activities[${index}].activity`}
                                value={item.type}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                dynamicKey
                                form={form}
                                wrapClass={"manual-input"}
                                suffix=" km"
                                name={`${prefix}.activities[${index}].distance`}
                                defaultValue={get(form.defaults, `${prefix}.activities[${index}].distance`)}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldTextTime
                                form={form}
                                name={`${prefix}.activities[${index}].time`}
                                defaultValue={get(form.defaults, `${prefix}.activities[${index}].time`)}
                                placeholder={"hh:mm"}
                                wrapClass={"manual-input"}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldText
                                dynamicKey
                                form={form}
                                disabled
                                hidden
                                name={`${prefix}.activities[${index}].pace`}
                                defaultValue={(pace || "0") + (item.type == "Swim" ? " min/100m" : " min/km")}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                dynamicKey
                                form={form}
                                disabled
                                hidden
                                suffix=" km/h"
                                name={`${prefix}.activities[${index}].speed`}
                                defaultValue={speed}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            {item.type == "Bike" &&
                                <FieldNumber
                                    dynamicKey
                                    form={form}
                                    suffix=" W"
                                    name={`${prefix}.activities[${index}].power`}
                                    defaultValue={get(form.defaults, `${prefix}.activities[${index}].power`)}
                                    wrapClass={"manual-input"}
                                />
                            }
                        </InputTableCell>

                    </InputTableRow>
                )
            })}

            {events.length > 1 &&
                <InputTableRow
                    key={hashCode(overall)}
                >

                    <InputTableCell>
                        <strong>Overall</strong>
                        <FieldHidden
                            form={form}
                            name={`${prefix}.overall.activity`}
                            value={'Overall'}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            dynamicKey
                            form={form}
                            suffix=" km"
                            name={`${prefix}.overall.distance`}
                            disabled
                            hidden
                            defaultValue={overall.distance}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldTextTime
                            dynamicKey
                            form={form}
                            name={`${prefix}.overall.time`}
                            placeholder={"hh:mm"}
                            disabled
                            defaultValue={getClockTime(overall.time)}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldText
                            dynamicKey
                            form={form}
                            disabled
                            name={`${prefix}.overall.pace`}
                            defaultValue={(getClockTime(overall.pace) || "0") + " min/km"}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            dynamicKey
                            form={form}
                            disabled
                            suffix=" km/h"
                            name={`${prefix}.overall.speed`}
                            defaultValue={overall.speed}
                        />
                    </InputTableCell>

                    <InputTableCell>

                    </InputTableCell>

                </InputTableRow>
            }

        </InputTable>
    )
}

export default TestSessionsEditTabsEventsFormDetails;
