import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'FieldSessions';

const FieldSessionsApi: baseApiReturn = baseApi(endpoint);

FieldSessionsApi.getPublicReport = (id: string, filters, options?: options): response => {

    return apiGet({
        url: endpoint + "/public/" + id + "/getReport",
        params: { view: filters.params.view, athleteId: filters.params.athleteId },
        ...options
    });

}

export default FieldSessionsApi;