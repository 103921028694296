import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'FieldRatingScales';

const FieldRatingScalesApi: baseApiReturn = baseApi(endpoint);

FieldRatingScalesApi.getData = (options?: options): response => {

    return apiGet({
        url: endpoint + "/getData",
        params: {},
        ...options
    });
}

export default FieldRatingScalesApi;