import React from 'react';
import SVG from 'react-inlinesvg';
import InjuryBackSvg from './InjuryBack.svg';
import InjuryBackRunSvg from './InjuryBackRun.svg';
import { hash } from '@dex/bubl-helpers';

import "./InjuryBack.module.scss";
import { injuryValuesToClasses } from '../../functions/sharedBikeFit';
import { fillImage } from '../../functions/sharedGeneral';

const InjuryBack: React.FC<InjuryBackProps> = (props) => {

    const { checked, activity } = props;

    let { injuryBackClasses } = injuryValuesToClasses(checked);

    return (

        <div
            key={hash([injuryBackClasses])}
            styleName={'wrap'}
        >
            <SVG
                src={activity == "run" ? InjuryBackRunSvg : InjuryBackSvg}
                preProcessor={(code) => {
                    code = fillImage(code, injuryBackClasses, "#3D5AFE")
                    return code;
                }}
            />

        </div>

    )

}

interface InjuryBackProps {
    [key: string]: any,
}

export default InjuryBack;