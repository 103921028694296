import { DataTableCell, InputTableRow, InputTable, Link, Modal, Panel } from '@dex/bubl-dash';
import { date, hashCode, titleCase } from '@dex/bubl-helpers';
import React, { useCallback, useMemo, useState } from 'react';
import CoachingTrainingsEditIntervals from './CoachingTrainingsEditIntervals';

const CoachingTrainingsWeekIntervalsTable: React.FC<any> = (props: any) => {

    const { filters, data, intervals } = props;

    const activity = filters.values?.activity;

    let column: any = [
        { label: "" },
        { label: "Interval" },
        { label: "Activity" },
        { label: "Workout Date" },
        { label: "Mode" },
        { label: "Type" },
        { label: "Intervals" },
        { label: "Zone" },
        { label: "Set" },
        { label: "Reps" },
    ].filter(Boolean);

    if (activity !== "all") {
        column = [
            ...column,
            ...(activity !== "swim" ? [{ label: "Interval Duration" }] : []),
            { label: "Interval Distance" },
            ...(activity !== "swim" ? [{ label: "Total Duration" }] : []),
            { label: "Total Distance" },
            { label: "Recovery" },
            ...(activity === "bike" ? [{ label: "Power", colSpan: 4, alignMiddle: true }] : []),
            ...(activity === "run" ? [{ label: "Pace [mm:ss/km]", colSpan: 4, alignMiddle: true }] : []),
            ...(activity === "swim" ? [{ label: "Time [h:mm:ss]", colSpan: 2, alignMiddle: true }] : []),
            ...(activity === "swim" ? [{ label: "Pace [min/100m]", colSpan: 2, alignMiddle: true }] : []),
            { label: "HR [bpm]", colSpan: 2, alignMiddle: true },
            ...(activity !== "swim" ? [{ label: "Speed [kph]", colSpan: 2, alignMiddle: true }] : []),
            ...(activity === "bike" ? [{ label: "RPM", colSpan: 2, alignMiddle: true }] : []),
            ...(activity === "swim" ? [{ label: "Aids" }] : []),
            { label: "Remarks" },
        ];
    }

    const handleRefresh = useCallback(() => {

        setEditWeek(null);

        filters.triggerSubmit();

    }, []);

    const [editWeek, setEditWeek] = useState(null);

    return useMemo(() => (

        <>

            {editWeek &&
                <Modal
                    show={true}
                    onClose={handleRefresh}
                    width={1920}
                >
                    <Panel heading={"Edit Intervals"}>
                        <CoachingTrainingsEditIntervals
                            data={data}
                            handleClose={handleRefresh}
                            week={editWeek}
                        />
                    </Panel>
                </Modal>
            }

            <InputTable className={"mini-table"} style={{ overflow: "auto" }} columns={column}>

                <InputTableRow className={"table-bg-gray"} >

                    <DataTableCell />

                    <DataTableCell>
                        #
                    </DataTableCell>

                    <DataTableCell />

                    <DataTableCell />

                    <DataTableCell />

                    <DataTableCell />

                    <DataTableCell className="table-bdr-left-gray " />

                    <DataTableCell className="table-bdr-left-gray " />

                    <DataTableCell className="table-bdr-left-gray " />

                    <DataTableCell className="table-bdr-left-gray " />

                    {activity != "all" &&
                        <>

                            {activity !== "swim" &&
                                <DataTableCell className="table-bdr-left-gray " >
                                    [mm:ss]
                                </DataTableCell>
                            }

                            <DataTableCell className={activity == "swim" && "table-bdr-left-gray"}>
                                {activity == "swim" ? "[m]" : "[km]"}
                            </DataTableCell>

                            {activity !== "swim" &&
                                <DataTableCell className="table-bdr-left-gray ">
                                    [h:mm:ss]
                                </DataTableCell>
                            }

                            <DataTableCell >
                                {activity == "swim" ? "[m]" : "[km]"}
                            </DataTableCell>

                            <DataTableCell className="table-bdr-left-gray ">
                                [mm:ss]
                            </DataTableCell>

                            <DataTableCell className="table-bdr-left-gray ">
                                Avg
                            </DataTableCell>

                            <DataTableCell>
                                Best
                            </DataTableCell>

                            {activity !== "swim" &&
                                <DataTableCell>
                                    High
                                </DataTableCell>
                            }

                            {activity !== "swim" &&
                                <DataTableCell>
                                    Low
                                </DataTableCell>
                            }

                            <DataTableCell className="table-bdr-left-gray ">
                                Avg
                            </DataTableCell>

                            {activity !== "swim" &&
                                <DataTableCell>
                                    Peak
                                </DataTableCell>
                            }

                            {activity == "swim" &&
                                <DataTableCell>
                                    Best
                                </DataTableCell>
                            }

                            <DataTableCell className="table-bdr-left-gray ">
                                Avg
                            </DataTableCell>

                            <DataTableCell>
                                Peak
                            </DataTableCell>

                            {activity == "bike" &&
                                <>
                                    <DataTableCell className="table-bdr-left-gray ">
                                        Avg
                                    </DataTableCell>

                                    <DataTableCell>
                                        Peak
                                    </DataTableCell>
                                </>
                            }

                            <DataTableCell className="table-bdr-left-gray " />

                            {activity == "swim" &&
                                <DataTableCell className="table-bdr-left-gray ">

                                </DataTableCell>
                            }

                        </>
                    }

                </InputTableRow>

                {intervals &&

                    <>
                        {intervals.map((row, i) => {

                            return (
                                <React.Fragment key={row.id}>

                                    {(row.interval || []).map((_interval, n) => (

                                        <InputTableRow key={n} className={`table-bg-zone-${n % 2 === 0 ? 3 : ""}`} >

                                            <DataTableCell >
                                                <Link onClick={setEditWeek.bind(null, row)}>
                                                    {!n ? "Edit Intervals" : ""}
                                                </Link>
                                            </DataTableCell>

                                            <DataTableCell>
                                                {n + 1}
                                            </DataTableCell>

                                            <DataTableCell>
                                                {!n ? titleCase(row.activity) : ""}
                                            </DataTableCell>

                                            <DataTableCell>
                                                {!n ? date(row.workoutDate, "YYYY-MM-DD") : ""}
                                            </DataTableCell>

                                            <DataTableCell>
                                                {!n ? titleCase(row.mode) : ""}
                                            </DataTableCell>

                                            <DataTableCell>
                                                {!n ? titleCase(row.type) : ""}
                                            </DataTableCell>

                                            <DataTableCell className="table-bdr-left-gray ">
                                                {titleCase(_interval.interval)}
                                            </DataTableCell>

                                            <DataTableCell className="table-bdr-left-gray ">
                                                {titleCase(_interval.zone)}
                                            </DataTableCell>

                                            <DataTableCell className="table-bdr-left-gray ">
                                                {_interval.set}
                                            </DataTableCell>

                                            <DataTableCell className="table-bdr-left-gray ">
                                                {_interval.reps}
                                            </DataTableCell>

                                            {activity != "all" &&
                                                <>

                                                    {activity !== "swim" &&
                                                        <>
                                                            <DataTableCell className="table-bdr-left-gray ">
                                                                {_interval.intervalDuration}
                                                            </DataTableCell>

                                                        </>
                                                    }

                                                    <DataTableCell className={activity == "swim" && "table-bdr-left-gray"}>
                                                        {_interval.intervalDistance}
                                                    </DataTableCell>

                                                    {activity !== "swim" &&
                                                        <>
                                                            <DataTableCell className="table-bdr-left-gray ">
                                                                {_interval.totalDuration}
                                                            </DataTableCell>

                                                        </>
                                                    }

                                                    <DataTableCell>
                                                        {_interval.totalDistance}
                                                    </DataTableCell>

                                                    <DataTableCell className="table-bdr-left-gray ">
                                                        {_interval.recovery}
                                                    </DataTableCell>

                                                    {activity == "bike" &&
                                                        <>
                                                            <DataTableCell className="table-bdr-left-gray ">
                                                                {_interval.powerAvg}
                                                            </DataTableCell>

                                                            <DataTableCell>
                                                                {_interval.powerBest}
                                                            </DataTableCell>

                                                            <DataTableCell>
                                                                {_interval.powerHigh}
                                                            </DataTableCell>

                                                            <DataTableCell>
                                                                {_interval.powerLow}
                                                            </DataTableCell>
                                                        </>
                                                    }

                                                    {activity == "run" &&
                                                        <>
                                                            <DataTableCell className="table-bdr-left-gray ">
                                                                {_interval.paceAvg}
                                                            </DataTableCell>

                                                            <DataTableCell>
                                                                {_interval.paceBest}
                                                            </DataTableCell>

                                                            <DataTableCell>
                                                                {_interval.paceHigh}
                                                            </DataTableCell>

                                                            <DataTableCell>
                                                                {_interval.paceLow}
                                                            </DataTableCell>
                                                        </>
                                                    }

                                                    {activity == "swim" &&
                                                        <>
                                                            <DataTableCell className="table-bdr-left-gray ">
                                                                {_interval.timeAvg}
                                                            </DataTableCell>

                                                            <DataTableCell>
                                                                {_interval.timeBest}
                                                            </DataTableCell>

                                                            <DataTableCell className="table-bdr-left-gray ">
                                                                {_interval.paceAvg}
                                                            </DataTableCell>

                                                            <DataTableCell>
                                                                {_interval.paceBest}
                                                            </DataTableCell>

                                                        </>
                                                    }

                                                    <DataTableCell className="table-bdr-left-gray " >
                                                        {_interval.hrAvg}
                                                    </DataTableCell>

                                                    <DataTableCell>
                                                        {_interval.hrBest}
                                                    </DataTableCell>

                                                    {activity !== "swim" &&
                                                        <>
                                                            <DataTableCell className="table-bdr-left-gray ">
                                                                {_interval.speedAvg}
                                                            </DataTableCell>

                                                            <DataTableCell>
                                                                {_interval.speedBest}
                                                            </DataTableCell>
                                                        </>
                                                    }

                                                    {activity == "bike" &&
                                                        <>
                                                            <DataTableCell className="table-bdr-left-gray ">
                                                                {_interval.rpmAvg}
                                                            </DataTableCell>

                                                            <DataTableCell>
                                                                {_interval.rpmPeak}
                                                            </DataTableCell>
                                                        </>
                                                    }

                                                    {activity == "swim" &&
                                                        <DataTableCell className="table-bdr-left-gray ">
                                                            {_interval.aids ? "Aids" : ""}
                                                        </DataTableCell>
                                                    }

                                                    <DataTableCell className="table-bdr-left-gray ">
                                                        {_interval.remarks}
                                                    </DataTableCell>

                                                </>
                                            }

                                        </InputTableRow>
                                    ))
                                    }
                                </React.Fragment>
                            )
                        })}

                    </>
                }
            </InputTable>

        </>

    ), [hashCode(intervals), data, intervals, activity, editWeek])
}

export default CoachingTrainingsWeekIntervalsTable
