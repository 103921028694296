import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { DataTableCell, FieldNumber, FieldSelect, FieldTextTime, Heading, InputTable, InputTableCell, InputTableRow, Space, useUpdateEffect } from '@dex/bubl-dash';
import { clone, fixedNum, get, hashCode, loop, parseNum } from '@dex/bubl-helpers';
import { labTestMergeData } from '../../../functions/labTestData';
import { getTableRow, glycogenEventList } from '../../../functions/glycogenTest';

const LabTestsRaceReadyManual: React.FC<any> = (props: any) => {

    const { form, data, session } = props;

    const activity = form.values.activity;

    const [efforts] = useState(() => clone(form.values.avgData || []));

    useUpdateEffect(() => {

        const manualData = form.values.manualData || [];
        const avgData = form.values.avgData || [];

        const mergedData: any = [];

        loop(avgData, (effort, index) => {

            mergedData[index] = labTestMergeData(avgData[index] || [], manualData[index] || []);

        });

        form.handleChange({ silent: true, name: `avgData`, value: mergedData });

    }, [hashCode(form.values.manualData || [])])

    const stages: any = [];

    let columns: any = [
        {
            key: "stage",
            label: "Stage",
            desc: "[#]"
        },
        {
            key: "time",
            label: "Time",
            desc: "[hh:mm:ss]"
        },
        activity == "Running" ?
            {
                key: "targetTreadmill",
                desc: "Treadmill Speed [kph]",
                label: "Target"
            }
            :
            null,
        activity == "Running" ?
            {
                key: "estimatedTreadmill",
                label: "Calculated",
                desc: "Treadmill Speed [kph]"
            }
            :
            null,
        activity == "Cycling" ?
            {
                key: "powerTarget",
                label: "Power",
                desc: "Target [W]"
            }
            :
            null,
        activity == "Cycling" ?
            {
                key: "powerRaw",
                label: "Power",
                desc: "Raw [W]"
            }
            :
            null,
        {
            key: "lactate",
            label: "Lactate",
            desc: "[mmol/L]"
        },
        {
            key: "rpe",
            label: "RPE",
            desc: "[#]"
        },
        {
            key: "vla",
            label: "Vla",
            desc: "[mmol/L/min]"
        },
    ].filter(Boolean);

    if (activity === 'Cycling') {

        columns = [
            ...columns,
            {
                key: "empty",
                label: "",
            },
        ];

    }

    if (activity === 'Running') {

        columns = [
            ...columns,
            {
                key: "timeRev",
                label: "Time Rev",
                desc: "[sec]"
            },
            {
                key: "timeStrides",
                label: "Time Strides",
                desc: "[sec]"
            },
            {
                key: "strideFrequency",
                label: "Stride Frequency",
                desc: "[per min]"
            },
            {
                key: "strideLength",
                label: "Stride Length",
                desc: "[m/stride]"
            },

        ]

    } else if (activity === 'Cycling') {

        columns = [
            ...columns,
        ]

    }

    const stageInput = (key: string, index, effortIndex, args?: any) => {

        let hidden = false;
        let prefix = `manualData[${effortIndex}][${index}]`;
        let defaultValue = get(form.defaults, `${prefix}.${key}`);

        const rev = parseNum(form.values.manual.revolutions || 0);
        const belt = parseNum(form.values.manual.beltLength || 0);

        let timeRev = parseNum(get(form.values, `${prefix}.timeRev`, 0));
        let timeStrides = parseNum(get(form.values, `${prefix}.timeStrides`, 0));
        let lactacte = parseNum(get(form.values, `${prefix}.lactate`, 0));
        let timeM = parseNum(get(efforts, `[${effortIndex}][${index}].timeM`, 0));
        let strideFrequency = parseNum(get(form.values, `${prefix}.strideFrequency`, 0));
        let speed = parseNum(get(form.values, `${prefix}.treadmillSpeedTarget`, 0));

        let vla: any = 0;
        let prev: any = null;
        let prevIndex = index - 1;

        while (!prev && prevIndex >= 0) {

            prev = get(form.values, `avgData[${effortIndex}][${prevIndex}]`, null);

            if (prev && !prev.lactate) prev = null;

            prevIndex--;

        }

        if (prev) {

            const timeDiff = timeM - prev.timeM;

            vla = (lactacte - prev.lactate) / timeDiff;

        }

        if (key === "empty") {

            return (
                <InputTableCell style={{ width: 200 }}>
                </InputTableCell>
            );

        }

        if (key === "strideFrequency" || key == "strideLength" || key === "treadmillSpeed" || key === "vla") {

            hidden = true;
            defaultValue = "";

            if (key === "strideFrequency") {

                if (rev && timeStrides)
                    defaultValue = fixedNum((rev * 60) / timeStrides, 1);

            } else if (key === "strideLength") {

                if (rev && strideFrequency)
                    defaultValue = fixedNum(((speed * 1000) / (60 * strideFrequency)), 2);

            } else if (key === "treadmillSpeed") {

                if (belt && timeRev)
                    defaultValue = fixedNum(((rev * belt) / timeRev) * 3.6, 1);

            } else if (key === "vla") {

                defaultValue = fixedNum(vla, 2)

            }

            if (isNaN(defaultValue) || !isFinite(defaultValue)) defaultValue = "";

        }

        return (

            <InputTableCell style={{ minWidth: 100 }}>

                {(index || key === "lactate") &&

                    <>

                        {hidden &&
                            <FieldNumber
                                key={form.hash}
                                form={form}
                                name={`${prefix}.${key}`}
                                defaultValue={defaultValue}
                                allowNegative={true}
                                {...args}
                                disabled
                                hidden
                            />
                        }

                        {!hidden &&
                            <FieldNumber
                                form={form}
                                name={`${prefix}.${key}`}
                                defaultValue={defaultValue}
                                allowNegative={true}
                                wrapClass={"manual-input"}
                                {...args}
                            />
                        }

                    </>

                }

            </InputTableCell>

        );

    };

    const [key, setKey] = useState(0);

    const options: any = [];

    loop(session.events || [], (item: any, index) => {

        options.push({
            label: item.name,
            value: index + "",
        });

    });

    useEffect(() => {

        const newActivity = activity == "Running" ? "Run" : "Bike";
        const eventIndex = form.values?.manual?.name;
        const event = session.events[parseInt(eventIndex)] || {};

        const eventRows = getTableRow(event.type);
        let activityIndex = null;

        loop(eventRows, (item, index) => {
            if (item.type === newActivity) activityIndex = index;
        })

        const activities = () => {

            let selectedActivity = {};

            loop(event.activities, (item, index) => {
                if (activityIndex === index) selectedActivity = item
            })

            return selectedActivity;
        };

        const selectedActivity: any = activities();

        form.handleChange({ silent: true, reset: true, name: `manual.type`, value: event.type })
        form.handleChange({ silent: true, reset: true, name: `manual.time`, value: selectedActivity.time })
        form.handleChange({ silent: true, reset: true, name: `manual.distance`, value: selectedActivity.distance })

        setKey((key) => key + 1);

    }, [form.hash]);

    return useMemo(() => (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <Heading space={false}>
                        Targeted Event
                    </Heading>

                </Col>

                <Col col={{ xs: 12, md: 6 }}>

                    <FieldSelect
                        form={form}
                        name={`manual.name`}
                        defaultValue={get(form.values, `manual.name`)}
                        label='Event Name'
                        options={options}
                        wrapClass={"manual-input"}
                        required
                    />

                </Col>

                <Col col={{ xs: 12, md: 5 }}>

                    <FieldSelect
                        key={get(form.values, `manual.type`)}
                        form={form}
                        name={`manual.type`}
                        defaultValue={get(form.values, `manual.type`)}
                        label='Event Type'
                        options={glycogenEventList}
                        disabled
                        hidden
                    />

                </Col>

                <Col col={{ xs: 12, md: 4 }}>

                    <FieldTextTime
                        key={get(form.values, `manual.time`)}
                        form={form}
                        name={`manual.time`}
                        defaultValue={get(form.values, `manual.time`)}
                        label={activity == "Cycling" ? 'Bike Time' : "Run Time"}
                        placeholder={"hh:mm"}
                        disabled
                        hidden
                    />

                </Col>

                <Col col={{ xs: 12, md: 4 }}>

                    <FieldNumber
                        key={get(form.values, `manual.distance`)}
                        form={form}
                        name={`manual.distance`}
                        defaultValue={get(form.values, `manual.distance`)}
                        label={"Distance"}
                        disabled
                        hidden
                        suffix=" km"
                    />

                </Col>

                <Col col={{ xs: 12, md: 5 }}>

                    <FieldSelect
                        form={form}
                        name="manual.level"
                        label="Level"
                        defaultValue={get(form.values, `manual.level`)}
                        options={[
                            { label: "Recreational", value: "recreational" },
                            { label: "Competitive", value: "competitive" },
                            { label: "Pro", value: "pro" },
                        ]}
                        wrapClass={"manual-input"}
                    />

                </Col>

                {activity === 'Running' &&
                    <>
                        <Col col={{ xs: 12, md: 6 }}>

                            <FieldNumber
                                form={form}
                                label={"Revolutions (#)"}
                                name={"manual.revolutions"}
                                defaultValue={form.defaults?.manual?.revolutions}
                                allowNegative
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 6 }}>

                            <FieldNumber
                                form={form}
                                label={"Belt Length (m)"}
                                name={"manual.beltLength"}
                                suffix={" M"}
                                defaultValue={form.defaults?.manual?.beltLength}
                                allowNegative
                                wrapClass={"manual-input"}
                            />

                        </Col>
                    </>
                }

                <Col col={{ xs: 24 }}>
                    <Space height={8} />
                </Col>

            </Row>

            {efforts.map((stages, effortIndex) => (

                <React.Fragment key={effortIndex}>

                    <Heading space={"small"}>
                        Effort {effortIndex + 1}
                    </Heading>

                    <InputTable columns={columns}>

                        {stages.map((stage, index) => {

                            return (

                                <InputTableRow key={index}>

                                    <DataTableCell style={{ width: 10 }}>
                                        {index}
                                    </DataTableCell>

                                    <DataTableCell style={{ width: 10 }}>
                                        {stage?.timeH}
                                    </DataTableCell>

                                    {activity === 'Cycling' &&

                                        <>

                                            <DataTableCell style={{ width: 10 }}>
                                                {stage?.power_target} W
                                            </DataTableCell>

                                            <DataTableCell style={{ width: 10 }}>
                                                {stage?.power_raw} W
                                            </DataTableCell>

                                        </>

                                    }

                                    {activity === 'Running' &&
                                        <>
                                            {stageInput("treadmillSpeedTarget", index, effortIndex)}

                                            {stageInput("treadmillSpeed", index, effortIndex)}
                                        </>
                                    }

                                    {stageInput("lactate", index, effortIndex)}

                                    {stageInput("rpe", index, effortIndex)}

                                    {stageInput("vla", index, effortIndex)}

                                    {activity === 'Cycling' &&

                                        <>
                                            {stageInput("empty", index, effortIndex)}
                                        </>

                                    }

                                    {activity === 'Running' &&

                                        <>

                                            {stageInput("timeRev", index, effortIndex)}

                                            {stageInput("timeStrides", index, effortIndex)}

                                            {stageInput("strideFrequency", index, effortIndex)}

                                            {stageInput("strideLength", index, effortIndex)}

                                        </>

                                    }

                                </InputTableRow>

                            )

                        })}

                    </InputTable>

                </React.Fragment>

            ))}

        </>

    ), [activity, stages, options, form.hash]);

}

export default LabTestsRaceReadyManual;
