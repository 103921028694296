import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Panel, Button, ButtonRow, Line, MetaTable, Space } from '@dex/bubl-dash';
import AthletesSessionTabGeneral from '../../Athletes/Components/AthletesSingleViewGeneral';

const GaitsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Session Details"}
                    actions={() => (
                        <>
                            <Button
                                label="Edit Session"
                                type="faded"
                                size="small"
                                path={`/gait/${data.id}/edit/info`}
                            />
                        </>
                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Fit Type",
                                key: "fitType",
                                type: 'text',
                                format: "titleCase",
                            },
                            {
                                label: "Session Date",
                                key: "sessionDate",
                                format: 'date',
                            },
                            {
                                label: "Athlete",
                                key: "athlete",
                                format: 'modelLink',
                            },

                        ]}
                        columnB={[
                            {
                                label: "Runner Level",
                                key: "runnerLevel",
                                type: 'text',
                                format: "titleCase",
                            },
                            {
                                label: "Running Mileage Per Week",
                                key: "runningMileagePerWeek",
                                type: 'text',
                                format: "titleCase",
                            },
                            {
                                label: "Competes",
                                key: "competes",
                                type: 'text',
                                format: "titleCase",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Years Running",
                                key: "yearsRunning",
                            },
                            {
                                label: "Appraisers",
                                key: "appraiser",
                                format: 'modelLink',
                            },
                        ]}
                    />

                    <Line />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Reason For Analysis",
                                key: "reasonForAnalysis",
                                type: 'text',
                                format: "titleCase",
                            },
                        ]}
                        columnB={[

                            {
                                label: "Performance Goals",
                                key: "performanceGoals",
                                type: 'text',
                                format: "titleCase",
                            },

                        ]}
                    />

                </Panel>

                <Space />

                <Panel
                    heading={data.athlete.fullName}
                    actions={(
                        <ButtonRow>

                            <Button
                                label={"View Athlete"}
                                size="small"
                                type="faded"
                                path={`/athletes/${data.athlete.id}/view`}
                            />

                        </ButtonRow>
                    )}
                >

                    <AthletesSessionTabGeneral data={data.athlete} />

                </Panel>



            </Col>

            {/* <Col className={'side'}>
                <GaitsSingleReport data={data} />
            </Col> */}

        </Row>

    ), [data]);

}

export default GaitsSingleView;
