import { Button, Col, FieldText, FieldTextTime, Form, InputTable, InputTableCell, InputTableRow, Line, Row, Space, useForm, useUpdateEffect } from '@dex/bubl-dash';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { fixedNum, get, hashCode, isEmpty, loop, parseNum } from '@dex/bubl-helpers';
import React, { useCallback } from 'react';
import { getClockTime, getDecimalTime } from '../../../functions/sharedTests';

const CoachingTrainingsIntervalsCalculator: React.FC<any> = (props: any) => {

    const { row, rowForm, activity, type, n, setOpenCalculator } = props;

    const form = useForm()

    const handleCopy = useCallback((values) => {

        if (activity == "swim") {

            if (type == "time") {
                rowForm.handleChange({ reset: true, name: `timeAvg`, value: values.result.avg });
                rowForm.handleChange({ reset: true, name: `timeBest`, value: values.result.best });

            } else {
                rowForm.handleChange({ reset: true, name: `hrAvg`, value: values.result.avg });
                rowForm.handleChange({ reset: true, name: `hrBest`, value: values.result.best });

            }

        } else if (activity == "bike") {

            if (type == "power") {
                rowForm.handleChange({ reset: true, name: `powerAvg`, value: values.result.avg });
                rowForm.handleChange({ reset: true, name: `powerBest`, value: values.result.best });

            } else if (type == "hr") {
                rowForm.handleChange({ reset: true, name: `hrAvg`, value: values.result.avg });
                rowForm.handleChange({ reset: true, name: `hrPeak`, value: values.result.best });

            } else if (type == "rpm") {
                rowForm.handleChange({ reset: true, name: `rpmAvg`, value: values.result.avg });
                rowForm.handleChange({ reset: true, name: `rpmPeak`, value: values.result.best });

            } else {
                rowForm.handleChange({ reset: true, name: `speedAvg`, value: values.result.avg });
                rowForm.handleChange({ reset: true, name: `speedBest`, value: values.result.best });

            }

        } else {
            if (type == "time") {
                rowForm.handleChange({ reset: true, name: `paceAvg`, value: values.result.avg });
                rowForm.handleChange({ reset: true, name: `paceBest`, value: values.result.best });

            } else {
                rowForm.handleChange({ reset: true, name: `hrAvg`, value: values.result.avg });
                rowForm.handleChange({ reset: true, name: `hrBest`, value: values.result.best });

            }
        }

        rowForm.setKey();

        setOpenCalculator("")

    }, []);

    const intervalsArray = form.values.intervals || [];

    const handleAdd = useCallback((values) => {
        let intervals;

        if (activity !== "bike") {
            intervals = [...intervalsArray];
            const diff = values.reps - intervals.length;
            if (diff > 0) {
                intervals = [...intervals, ...Array.from({ length: diff }, () => ({ minute: parseNum(values.starting) }))];
            } else if (diff < 0) {
                intervals = intervals.slice(0, values.reps);
            }
        } else {
            intervals = [...intervalsArray];
            const diff = values.reps - intervals.length;
            if (diff > 0) {
                intervals = [...intervals, ...Array.from({ length: diff }, () => ({ multiplier: parseNum(values.starting) }))];
            } else if (diff < 0) {
                intervals = intervals.slice(0, values.reps);
            }
        }
        form.handleChange({ reset: true, name: "intervals", value: intervals })
    }, [intervalsArray]);

    useUpdateEffect(() => {

        loop(intervalsArray, (row: { minute, second, multiplier, watt }, index: any) => {

            if (activity !== "bike") {
                let minute = row.minute;
                let second = row.second || 0;
                if (second >= 60) {
                    const additionalMinutes = Math.floor(second / 60);
                    minute += additionalMinutes;
                    second %= 60;
                }

                if (minute < 10 && minute !== null) minute = "0" + minute;
                if (second < 10) second = "0" + second;

                let time: any = minute + ":" + second;

                if (minute == null && (second == null || second == 0)) time = null;

                form.handleChange({ reset: true, name: `intervals[${index}].time`, value: time });
            } else {
                let multiplier = parseNum(row.multiplier);

                const watt = parseNum(row.watt);

                let total: any = fixedNum((multiplier * 100) + watt, 0) || 0;

                form.handleChange({ reset: true, name: `intervals[${index}].totalWatt`, value: total })
            }

        })

        let avg: any = 0;
        let worst: any = 0;
        let best: any = 0;

        const filterArray = (array, name) => {
            return array.filter(row => (row[name] !== undefined && row[name] !== "" && row[name] !== null)).map(row => row[name]);
        };

        const calculateAvgWorstBest = (array: number[], type = "default") => {

            let avg = 0;
            let worst = 0;
            let best = 0;

            if (array.length > 0) {

                let sum: number = 0;

                if (type === "time") {
                    loop(array, (row: any) => {
                        sum += getDecimalTime(row);
                    });
                } else {
                    sum = array.reduce((a, b) => parseNum(a) + parseNum(b));
                }

                avg = fixedNum(sum / array.length, type === "time" ? undefined : 0);

                if (type == "hr") {
                    worst = Math.max(...array);
                    best = Math.min(...array);
                } else if (type == "time") {
                    const decimalTimeArray = array.map(getDecimalTime);
                    worst = Math.max(...decimalTimeArray);
                    best = Math.min(...decimalTimeArray);
                } else {
                    worst = Math.min(...array);
                    best = Math.max(...array);
                }

            }

            return { avg, worst, best };
        };

        if (intervalsArray.length >= 1 && activity == "bike") {
            if (type == "power") {
                const watt = filterArray(intervalsArray, "totalWatt");
                ({ avg, worst, best } = calculateAvgWorstBest(watt));
            } else if (type == "hr") {
                const hr = filterArray(intervalsArray, "hr");
                ({ avg, worst, best } = calculateAvgWorstBest(hr, "hr"));
            } else if (type == "speed") {
                const speed = filterArray(intervalsArray, "speed");
                ({ avg, worst, best } = calculateAvgWorstBest(speed));

            } else if (type == "rpm") {
                const rpm = filterArray(intervalsArray, "rpm");
                ({ avg, worst, best } = calculateAvgWorstBest(rpm));

            }
        }

        if (intervalsArray.length >= 1 && activity !== "bike") {
            if (type == "time") {
                const times = filterArray(intervalsArray, "time");
                ({ avg, worst, best } = calculateAvgWorstBest(times, "time"));
            } else {
                const hr = filterArray(intervalsArray, "hr");
                ({ avg, worst, best } = calculateAvgWorstBest(hr, "hr"));
            }
        }

        if (type == "time") {
            avg = getClockTime(avg);
            best = getClockTime(best);
            worst = getClockTime(worst);
        }

        form.handleChange({ reset: true, name: "result.avg", value: avg });
        form.handleChange({ reset: true, name: "result.best", value: best });
        form.handleChange({ reset: true, name: "result.worst", value: worst });

    }, [hashCode(intervalsArray)]);

    return (

        <Form
            form={form}
            onSubmit={handleCopy}
            autoDiscardChanges={true}
        >

            <Panel>

                <Row edge gutter={8}>

                    <Col >

                        <FieldText
                            form={form}
                            name='activity'
                            defaultValue={activity}
                            label='Activity'
                            wrapClass={"w-120"}
                            disabled
                        />

                    </Col>

                    <Col >

                        <FieldText
                            form={form}
                            name='interval'
                            defaultValue={n + 1}
                            label='Interval #'
                            wrapClass={"w-120"}
                            disabled
                        />

                    </Col>

                    <Col >
                        {activity == "swim" ?
                            <FieldNumber
                                form={form}
                                name='intervalDistance'
                                defaultValue={row.intervalDistance}
                                label='Interval Distance'
                                wrapClass={"w-120"}
                                suffix=" m"
                                disabled
                            />
                            :
                            <FieldTextTime
                                form={form}
                                name='intervalDistance'
                                defaultValue={row.intervalDuration}
                                label='Interval Duration'
                                wrapClass={"w-120"}
                                placeholder="mm:ss"
                                disabled
                            />
                        }
                    </Col>

                    <Col >

                        <FieldNumber
                            form={form}
                            name='reps'
                            defaultValue={form.defaults.reps}
                            label='Total Reps'
                            wrapClass={"manual-input w-120"}
                        />

                    </Col>

                    {(type == "time" || type == "power") &&
                        <Col >

                            <FieldNumber
                                form={form}
                                name='starting'
                                defaultValue={form.defaults.starting}
                                label={activity == "bike" ? 'Starting Watts (x100)' : 'Starting Minute'}
                                wrapClass={"manual-input w-150"}
                            />

                        </Col>
                    }

                    <Col justify='center'>
                        <Space />

                        <Button
                            label="Add"
                            type={"faded"}
                            size={"small"}
                            onClick={handleAdd.bind(null, form.values)}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    {activity !== "bike" ?
                        <SwimRunCalculator
                            form={form}
                            handleCopy={handleCopy}
                            activity={activity}
                            type={type}
                            intervalsArray={intervalsArray}
                        />
                        :
                        <BikeCalculator
                            form={form}
                            handleCopy={handleCopy}
                            activity={activity}
                            type={type}
                            intervalsArray={intervalsArray}
                        />
                    }

                </Row>

            </Panel>

        </Form>

    )
}

const SwimRunCalculator: React.FC<any> = (props: any) => {

    const { form, handleCopy, activity, type, intervalsArray } = props;

    const colums: any = [
        { label: "#" },
        type == "time" ? { label: "Min" } : null,
        type == "time" ? { label: "Sec" } : null,
        type == "time" ? { label: "mm:ss" } : null,
        type == "hr" ? { label: "HR" } : null,
    ].filter(Boolean);

    return (
        <>

            <Col col={{ xs: 24 }}>

                {type == "time" ?
                    <Row edge gutter={8}>

                        <Col >

                            <FieldTextTime
                                form={form}
                                name={`result.avg`}
                                defaultValue={form.defaults.result?.avg}
                                key={form.values.result?.avg}
                                disabled
                                placeholder="mm:ss"
                                label="Avg"
                            />

                        </Col>

                        <Col>

                            <FieldTextTime
                                form={form}
                                name={`result.best`}
                                defaultValue={form.defaults.result?.best}
                                key={form.values.result?.best}
                                disabled
                                placeholder='mm:ss'
                                label="Best"
                            />

                        </Col>

                        <Col>

                            <FieldTextTime
                                form={form}
                                name={`result.worst`}
                                defaultValue={form.defaults.result?.worst}
                                key={form.values.result?.worst}
                                disabled
                                placeholder="mm:ss"
                                label="Worst"
                            />

                        </Col>

                        <Col justify='center'>
                            <Space />
                            <Button
                                label="Copy"
                                onClick={handleCopy.bind(null, form.values)}
                                htmlType="button"
                            />

                        </Col>

                    </Row>
                    :
                    <Row edge gutter={8}>

                        <Col >

                            <FieldNumber
                                form={form}
                                name={`result.avg`}
                                defaultValue={form.defaults.result?.avg}
                                key={form.values.result?.avg}
                                disabled
                                label="Avg"
                            />

                        </Col>

                        <Col>

                            <FieldNumber
                                form={form}
                                name={`result.best`}
                                defaultValue={form.defaults.result?.best}
                                key={form.values.result?.best}
                                disabled
                                label="Best"
                            />

                        </Col>

                        <Col>

                            <FieldNumber
                                form={form}
                                name={`result.worst`}
                                defaultValue={form.defaults.result?.worst}
                                key={form.values.result?.worst}
                                disabled
                                label="Worst"
                            />

                        </Col>

                        <Col justify='center'>
                            <Space />

                            <Button
                                label="Copy"
                                onClick={handleCopy.bind(null, form.values)}
                                htmlType="button"
                            />

                        </Col>

                    </Row>
                }

            </Col>

            <Col col={{ xs: 24 }}>

                <InputTable
                    columns={colums}
                >
                    {intervalsArray.map((row, index) => (

                        <InputTableRow key={index}>

                            <InputTableCell>
                                {index + 1}
                            </InputTableCell>

                            {type == "time" ?
                                <>
                                    <InputTableCell>

                                        <FieldNumber
                                            form={form}
                                            name={`intervals[${index}].minute`}
                                            defaultValue={get(form.defaults, `intervals[${index}].minute`)}
                                            key={row.minute}
                                            wrapClass={"manual-input"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell>

                                        <FieldNumber
                                            form={form}
                                            name={`intervals[${index}].second`}
                                            defaultValue={get(form.defaults, `intervals[${index}].second`)}
                                            wrapClass={"manual-input"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell>

                                        <FieldTextTime
                                            form={form}
                                            name={`intervals[${index}].time`}
                                            defaultValue={row.time}
                                            key={row.time}
                                            placeholder="mm:ss"
                                            disabled
                                        />

                                    </InputTableCell>

                                </>
                                :
                                <>
                                    <InputTableCell>

                                        <FieldNumber
                                            form={form}
                                            name={`intervals[${index}].hr`}
                                            defaultValue={get(form.values, `intervals[${index}].hr`)}
                                            wrapClass={"manual-input w-120"}
                                        />

                                    </InputTableCell>

                                </>
                            }

                        </InputTableRow>

                    ))}

                </InputTable>

            </Col>

        </>
    )
}

const BikeCalculator: React.FC<any> = (props: any) => {

    const { form, handleCopy, activity, type, intervalsArray } = props;

    const colums: any = [
        { label: "#" },
        type == "power" ? { label: "W [x100]" } : null,
        type == "power" ? { label: "W" } : null,
        type == "power" ? { label: "Watts" } : null,
        type == "hr" ? { label: "HR" } : null,
        type == "rpm" ? { label: "RPM" } : null,
        type == "speed" ? { label: "Speed" } : null,
    ].filter(Boolean);

    return (
        <>

            <Col col={{ xs: 24 }}>

                <Row edge gutter={8}>

                    <Col >

                        <FieldNumber
                            form={form}
                            name={`result.avg`}
                            defaultValue={form.defaults.result?.avg}
                            key={form.values.result?.avg}
                            disabled
                            label="Avg"
                        />

                    </Col>

                    <Col>

                        <FieldNumber
                            form={form}
                            name={`result.best`}
                            defaultValue={form.defaults.result?.best}
                            key={form.values.result?.best}
                            disabled
                            label="Best"
                        />

                    </Col>

                    <Col>

                        <FieldNumber
                            form={form}
                            name={`result.worst`}
                            defaultValue={form.defaults.result?.worst}
                            key={form.values.result?.worst}
                            disabled
                            label="Worst"
                        />

                    </Col>

                    <Col justify='center'>
                        <Space />

                        <Button
                            label="Copy"
                            onClick={handleCopy.bind(null, form.values)}
                            htmlType="button"
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24 }}>

                <InputTable
                    columns={colums}
                >
                    {intervalsArray.map((row, index) => (

                        <InputTableRow key={index}>

                            <InputTableCell>
                                {index + 1}
                            </InputTableCell>

                            {type == "power" &&
                                <>
                                    <InputTableCell>

                                        <FieldNumber
                                            form={form}
                                            name={`intervals[${index}].multiplier`}
                                            defaultValue={get(form.defaults, `intervals[${index}].multiplier`)}
                                            key={row.multiplier}
                                            wrapClass={"manual-input"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell>

                                        <FieldNumber
                                            form={form}
                                            name={`intervals[${index}].watt`}
                                            defaultValue={get(form.defaults, `intervals[${index}].watt`)}
                                            wrapClass={"manual-input"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell>

                                        <FieldNumber
                                            form={form}
                                            name={`intervals[${index}].totalWatt`}
                                            defaultValue={row.totalWatt}
                                            key={row.totalWatt}
                                            disabled
                                        />

                                    </InputTableCell>

                                </>
                            }

                            {type == "hr" &&

                                <>
                                    <InputTableCell>

                                        <FieldNumber
                                            form={form}
                                            name={`intervals[${index}].hr`}
                                            defaultValue={get(form.defaults, `intervals[${index}].hr`)}
                                            wrapClass={"manual-input w-120"}
                                        />

                                    </InputTableCell>

                                </>
                            }

                            {type == "rpm" &&

                                <>
                                    <InputTableCell>

                                        <FieldNumber
                                            form={form}
                                            name={`intervals[${index}].rpm`}
                                            defaultValue={get(form.defaults, `intervals[${index}].rpm`)}
                                            wrapClass={"manual-input w-120"}
                                        />

                                    </InputTableCell>

                                </>
                            }

                            {type == "speed" &&

                                <>
                                    <InputTableCell>

                                        <FieldNumber
                                            form={form}
                                            name={`intervals[${index}].speed`}
                                            defaultValue={get(form.defaults, `intervals[${index}].speed`)}
                                            wrapClass={"manual-input w-120"}
                                        />

                                    </InputTableCell>

                                </>
                            }

                        </InputTableRow>

                    ))}

                </InputTable>

            </Col>

        </>
    )
}

export default CoachingTrainingsIntervalsCalculator
