import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import FieldRatingScalesApi from './FieldRatingScalesApi';
import { ModelLink } from '@dex/bubl-dash';
import { render } from 'react-dom';

let settings;

export default settings = {
    key: 'field/rating-scales',
    zone: 'field',
    path: '/field/rating-scales',
    title: "Field Rating Scales",
    endpoint: FieldRatingScalesApi.endpoint,
    modelName: 'FieldRatingScales',
    idKey: 'id',
    primaryKey: 'title',
    actions: ['trash', 'clone'],
    indexCaps: ["viewFieldRatingScales"],
    viewCaps: ["viewFieldRatingScales"],
    createCaps: ["createFieldRatingScales"],
    updateCaps: ["updateFieldRatingScales"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: FieldRatingScalesApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = {
            where: {},
            order: ['created.date DESC'],
            fields: [
                "title", "id", "modelName",
                "level", "source", "sport", "bestScore",
                "units", "suffix",
                "created", "updated",
            ],
            limit: 10,
            skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['title'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Name",
                key: "title",
                type: "text",
            },
            {
                label: "Sport",
                key: "sport",
                type: "text",
            },
            {
                label: "Source",
                key: "source",
                type: "text",
            },
            {
                label: "Best Score",
                key: "bestScore",
                type: "choice",
                options: [
                    { value: "lowest", label: "Lowest" },
                    { value: "highest", label: "Highest" }
                ],
            },
            {
                label: "Unit",
                key: "units",
                type: "text"
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Title",
                key: "this",
                format: "ModelLink",
                wrap: true,
            },
            {
                label: "Source",
                key: "source",
                wrap: true,
                style: { maxWidth: "220px" }
            },
            {
                label: "Level",
                key: "level",
                format: "titleCase"
            },
            {
                label: "Sport",
                key: "sport",
            },
            {
                label: "Best Score is",
                key: "bestScore",
                format: "titleCase"
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: FieldRatingScalesApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: FieldRatingScalesApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ["template"] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};