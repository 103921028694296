import React, { useContext, useMemo, useState } from 'react';
import { Box, Panel } from '@dex/bubl-dash';
import EditableContext from '../../../context/EditableContext';
import CoachingTrainingsWeeklyTab from './CoachingTrainingsWeeklyTab';
import CoachingTrainingsWorkoutTab from './CoachingTrainingsWorkoutTab';

const CoachingEditTabsTraining: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    const editable = useContext(EditableContext);

    const [tab, setTab] = useState("weekly-stats");

    const tabs = [
        { label: "Weekly Stats", name: "weekly-stats" },
        { label: "Workouts", name: "workouts" },
    ]

    return useMemo(() => (
        <>

            {!editable &&
                <>
                    {props.children}
                </>
            }

            {editable &&

                <Box gutter={"auto"}>

                    <Panel
                        heading={"Training"}
                        tabs={tabs}
                        activeTab={tab}
                        setTab={setTab}
                        full
                    >

                        <Box gutter={"auto"} edge={{ top: true }}>

                            {tab == "weekly-stats" &&
                                <CoachingTrainingsWeeklyTab
                                    data={data}
                                    form={form}
                                    handleSubmit={handleSubmit}
                                    process={process}
                                />
                            }

                            {tab == "workouts" &&
                                <CoachingTrainingsWorkoutTab
                                    data={data}
                                    form={form}
                                />
                            }

                        </Box>

                    </Panel>

                </Box>

            }

        </>

    ), [form.hash, data, tab, process.loading])
}

export default CoachingEditTabsTraining
