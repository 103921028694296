import React, { useMemo } from 'react';
import { Col, FieldSelect, Heading, Row, Space, useForm } from '@dex/bubl-dash';
import FieldRatingsGridLegend from '../../../elements/FieldRatingsGrid/FieldRatingsGridLegend';
import FieldRatingsGrid from '../../../elements/FieldRatingsGrid/FieldRatingsGrid';
import { loop, titleCase } from '@dex/bubl-helpers';

const FieldResultsTeamChange: React.FC<any> = ( props: any ) => {

    const { report } = props;

    const history = report.history;

    const form = useForm( { date: history?.list[ 0 ]?.value } );

    const items = useMemo( () => {

        const items: any = [];

        const results = history?.sessions[ form.values.date ]?.grouped || {};

        if ( !results ) return items;

        loop( results, ( charts, category ) => {

            loop( charts || [], ( chart, index ) => {

                const template = report.templates[ chart.id ];

                chart.scale = { min: -25, max: 25, bestScore: "highest", units: "%", mean: "0" };
                chart.name = template.name;
                chart.subtitle = template.name;
                chart.decimalPlace = template.decimalPlace;
                chart.bestScore = template.bestScore;

                let icon: any = "dash";

                if ( chart.score >= 1 ) icon = "up";
                if ( chart.score <= -1 ) icon = "down";

                chart.value = chart.score;
                chart.icon = icon;

            } )

            items.push( {
                category: category,
                title: titleCase( category ),
                charts: charts || [],
            } )

        } )

        return items;

    }, [ report.ratings, form.values.date ] );

    return useMemo( () => (

        <>

            <Row gutter={"auto"} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <Heading size={2} space={false}>Team Results - % Change</Heading>

                    <Space height={"small"} />

                    <p>
                        Your team’s average % change over time on test attributes.
                    </p>

                    <Space />

                    <FieldRatingsGridLegend />

                </Col>

                <Col col={{ xs: 12, md: 6, xl: 5 }} justify='end'>

                    <FieldSelect
                        form={form}
                        name={"date"}
                        label={"Compared To"}
                        defaultValue={form.defaults.date}
                        options={history.list}
                        isClearable={false}
                        required
                    />

                </Col>

            </Row>

            <Space />

            <FieldRatingsGrid
                items={items}
            />

            <Space />

            <p>
                Changes of more than 1% are considered improvements. Changes below 1% are considered regressive.
            </p>

        </>

    ), [ props, items, form.values ] );

}

export default FieldResultsTeamChange;
