import { Button, ButtonRow, Col, FieldRadio, Form, Line, Panel, Row, useApp, useForm } from '@dex/bubl-dash';
import moment from 'moment';
import React, { useCallback } from 'react';
import TestSessionsApi from '../TestSessionsApi';

const LabTestsAddNew: React.FC<any> = (props: any) => {

    const { data, onComplete } = props;

    const app = useApp();

    const form = useForm({ date: moment().toLocaleString() });

    const add = TestSessionsApi.update(data.id, "addLabTest", {

        onComplete: (data) => {

            app.alert('Lab Test Added', 'success');

            onComplete(data.id);

        }

    });

    const handleSubmit = useCallback((values) => {

        add.run({ data: values });

    }, [props]);

    const hasActivity = ["VO2max", "Race Ready", "MET", "Performance", "Sprint"].includes(form.values.type);
    const hasIntensity = form.values.activity === "Running" && ["VO2max", "Race Ready", "MET", "Performance"].includes(form.values.type);

    return (

        <Panel
            heading={"Add Lab Test"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={add.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRadio
                            button={true}
                            form={form}
                            label={"Type"}
                            name='type'
                            required={true}
                            isMulti={false}
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "VO2max", label: "VO2max" },
                                { value: "Race Ready", label: "Race Ready" },
                                { value: "RMR", label: "RMR" },
                                { value: "Sweat Test", label: "Sweat Test" },
                                { value: "Glycogen Depletion", label: "Glycogen Depletion" },
                                { value: "MET", label: "MET" },
                                { value: "Performance", label: "Performance" },
                                { value: "Sprint", label: "Sprint" },
                            ]}
                        />

                    </Col>

                    {hasActivity &&
                        <Col col={{ xs: 24, lg: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Activity"}
                                name='activity'
                                required={true}
                                isMulti={false}
                                defaultValue={form.defaults.activity}
                                options={[
                                    { value: "Cycling", label: "Cycling" },
                                    { value: "Running", label: "Running" },
                                ]}
                            />

                        </Col>
                    }

                    {hasIntensity &&
                        <Col col={{ xs: 24, lg: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Intensity"}
                                name='intensity'
                                required={true}
                                isMulti={false}
                                defaultValue={form.defaults.intensity}
                                options={[
                                    { value: "Submax", label: "Submax" },
                                    { value: "Peak", label: "Peak" },
                                ]}
                            />

                        </Col>
                    }

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={add.loading}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    )
}



export default LabTestsAddNew;