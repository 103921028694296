import React, { useMemo } from 'react';
import { MediaImage, Space } from '@dex/bubl-dash';
import styles from "./CoachingReportCover.module.scss";
import { date, get } from '@dex/bubl-helpers';
import moment from 'moment';

const CoachingReportCover: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const logo = [
        { img: require('../../../assets/images/Cover/VO2MASTER.png'), alt: "VO2MASTER" },
        { img: require('../../../assets/images/Cover/CORTEX.png'), alt: "CORTEX" },
        { img: require('../../../assets/images/Cover/MOXY.png'), alt: "MOXY" },
        { img: require('../../../assets/images/Cover/CSEP.png'), alt: "CSEP" },
        { img: require('../../../assets/images/Cover/IRONMAN.png'), alt: "IRONMAN" },
        { img: require('../../../assets/images/Cover/HORIBA.png'), alt: "HORIBA" },
    ]

    let formattedDateRange = "";

    if (data.week?.weekStartDate && data.week?.weekEndDate) {

        const startDate = moment(data.week.weekStartDate);
        const endDate = moment(data.week.weekEndDate);

        const formattedStartDate = startDate.format('MMM DD');
        const formattedEndDate = endDate.format('MMM DD YYYY');

        formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

    };

    return useMemo(() => (

        <div className={styles.cover}>

            <Space height={160} />

            <div className={styles.innerCover}>

                <div>
                    <img
                        src={require('../../../assets/images/Cover/VO2MAX.png')}
                        alt="VO2MAX"
                        className={styles.vo2Logo}
                    />
                </div>

                <div className={styles.borderBlue}>

                    <div className={styles.borderGreen} >

                        <div className={styles.top}>

                            <p className={styles.altTitle}>{data.reportTitle} Report</p>

                            <p className={styles.subHeading}>{formattedDateRange}</p>

                            <div className={styles.imageWrapper}>
                                {data.general &&
                                    <MediaImage
                                        id={get(data.general, "photo.fileId", data.athlete?.photo?.fileId)}
                                        filters={{ height: 350, resizeMode: "contain", exact: true }}
                                        className={styles.image}
                                        imgProps={{
                                            crossOrigin: "anonymous"
                                        }}
                                    />
                                }
                            </div>

                            <p className={styles.altTitle}>{data.athleteName}</p>

                            <p className={styles.title}>{data.athlete.mainSport}</p>

                            <p className={styles.subHeading}>{data.athlete.discipline}</p>

                            <p className={styles.altSubHeading}>Coach: {data.coach.name}</p>

                        </div>

                        <div className={styles.logoRow}>
                            {logo.map((item: any, index: any) => (
                                <div
                                    className={styles.logoContainer}
                                    key={index}
                                >
                                    <img
                                        src={item.img}
                                        alt={item.alt}
                                        className={styles.logo}
                                    />
                                </div>
                            ))}
                        </div>

                        <Space height={80} />

                    </div>

                </div>

            </div>

        </div>

    ), [data]);

}

export default CoachingReportCover;