import React, { useMemo } from 'react';
import { Col, Row } from '@dex/bubl-dash';
import FieldResultsSummaryAttributes from '../../../elements/FieldResultsSummaryAttributes/FieldResultsSummaryAttributes';
import FieldResultsAthleteFocus from '../../../elements/FieldResultsAthleteFocus/FieldResultsAthleteFocus';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';

const FieldResultsTeamFocus: React.FC<any> = (props: any) => {

    const { report } = props;

    return useMemo(() => (

        <>

            <Row gutter={"auto"} edge={true}>

                <Col col={{ xs: 24 }}>

                    <ReportDesc
                        label="Here are the Fitness attributes your team needs to focus on in training and conditioning. In addition, athlete's specific areas of weakness are highlighted."
                    />
                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldResultsSummaryAttributes
                        heading={"Team Focus"}
                        attributes={report.attributesTeam || {}}
                    />

                </Col>

                <Col col={{ xs: 24, md: 16 }}>

                    <FieldResultsAthleteFocus
                        attributes={report.attributes || {}}
                    />

                </Col>

            </Row>

        </>

    ), [props]);

}

export default FieldResultsTeamFocus;
