import { Box, Col, DataTable, FieldDate, FieldSelect, InputTableCell, InputTableHead, InputTableHeadCell, InputTableHeadRow, InputTableRow, Row, useForm, useMountEffect } from '@dex/bubl-dash';
import React, { useEffect, useMemo, useState } from 'react';
import InputTable from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import { date, fixedNum, get, isEmpty, loop, titleCase, unslug } from '@dex/bubl-helpers';
import { DataTableCell } from '@rmwc/data-table';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { getArrowDifference, getStars } from '../../../functions/sharedCoaching';
import moment from 'moment';
import { glycogenEventList } from '../../../functions/glycogenTest';
import CoachingEventsApi from '../../CoachingEvents/CoachingEventsApi';

import styles from "./CoachingReportEvents.module.scss";
import CoachingApi from '../CoachingApi';
import { getClockTime } from '../../../functions/sharedTests';

const CoachingReportEvents: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ year: moment().format('YYYY'), type: 'all' });

    const fetch = CoachingApi.getReportData(data.id);

    const [events, setEvents] = useState({ upcoming: [], completed: [] });

    useEffect(() => {

        const query: any = { coachingId: data.id };

        if (form.values.year) {
            query.year = moment(form.values.year).format("YYYY")
        }

        if (form.values.type && form.values.type !== "all") {
            query.type = form.values.type
        }

        fetch.run({
            params: { filters: query, type: "event" },
            onComplete: (response: any) => {

                setEvents(seperateEvent(response.events));

            }

        })

    }, [form.values.year, form.values.type]);

    function seperateEvent(events) {

        const completed: any = [];
        const upcoming: any = [];

        loop(events, (event: any) => {
            if (event.completed === true) {
                completed.push(event);
            } else {
                upcoming.push(event);
            }
        });

        return { upcoming, completed }
    };

    let column = [
        { label: "", className: "table-bdr-right-gray table-bg-white" },
        { label: "Event", key: "event", colSpan: 3, alignMiddle: true, className: "table-bdr-right-gray" },
        { label: "", colSpan: 3, className: "table-bdr-right-gray" },
        { label: "Target", key: "target", colSpan: 2, alignMiddle: true, className: "table-bdr-right-gray" },
        { label: "", colSpan: 2 },
    ];

    let completedColumn = [
        { label: "", className: "table-bdr-right-gray table-bg-white" },
        { label: "Event", key: "event", colSpan: 3, alignMiddle: true, className: "table-bdr-right-gray" },
        { label: "", colSpan: 3, className: "table-bdr-right-gray" },
        { label: "Actual", key: "target", colSpan: 2, alignMiddle: true, className: "table-bdr-right-gray" },
        { label: "", colSpan: 2 },
    ];

    return useMemo(() => (

        <Row edge gutter={"auto"}>

            <Col col={{ xs: 8 }}>

                <FieldDate
                    form={form}
                    name='year'
                    defaultValue={form.defaults.year}
                    label="Year"
                    dateFormat="YYYY"
                    dateMode="year"
                    wrapClass="manual-input"
                />

            </Col>

            <Col col={{ xs: 8 }}>

                <FieldSelect
                    form={form}
                    name={`type`}
                    defaultValue={get(form.defaults, `type`)}
                    label='Event Type'
                    options={[{ label: "All", value: "all" }, ...glycogenEventList]}
                    wrapClass={"manual-input"}
                />

            </Col>

            <Col col={{ xs: 8 }}>


            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text={"Your Upcoming Events"}
                    icons={require("../../../assets/images/Coaching/preferredEventsStart.png")}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <InputTable
                    columns={column}
                >

                    <InputTableHeadRow className="table-bg-gray">

                        <InputTableHeadCell
                            className="table-bdr-right-gray table-bg-white"
                        />

                        <InputTableHeadCell
                            label={"Type"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Name"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Date"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Priority"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Activity"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Distance"}
                            desc="km"
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Time"}
                            desc="hh:mm:ss"
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Speed"}
                            desc="kph"
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Goal"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Status"}
                            desc={<>&nbsp;</>}
                        />

                    </InputTableHeadRow>

                    {events?.upcoming.map((event: any, index: number) => (

                        <CoachingReportEventsRow
                            key={index}
                            event={event}
                            index={index}
                            complete={false}
                            bg="table-bg-blue"
                        />

                    ))}

                </InputTable>

            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text={"Your Completed Events"}
                    icons={require("../../../assets/images/Coaching/preferredEvents.png")}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <InputTable
                    // data={seperatedEvents.completedEvents}
                    columns={completedColumn}
                >

                    <InputTableHeadRow className="table-bg-gray">

                        <InputTableHeadCell
                            className="table-bdr-right-gray table-bg-white"
                        />

                        <InputTableHeadCell
                            label={"Type"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Name"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Date"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Priority"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Activity"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Distance"}
                            desc="km"
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Time"}
                            desc="hh:mm:ss"
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Speed"}
                            desc="kph"
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Position"}
                            desc={<>&nbsp;</>}
                            className="table-bdr-right-gray"
                        />

                        <InputTableHeadCell
                            label={"Status"}
                            desc={<>&nbsp;</>}
                        />

                    </InputTableHeadRow>

                    {events?.completed.map((event: any, index: number) => (

                        <CoachingReportEventsRow
                            key={index}
                            event={event}
                            index={index}
                            complete={true}
                            bg="table-bg-green"
                        />

                    ))}

                </InputTable>

            </Col>

        </Row>

    ), [data, events, form.hash]);

}

export default CoachingReportEvents;

const CoachingReportEventsRow: React.FC<any> = (props: any) => {

    const { event, complete, index, bg } = props;

    const [show, setShow] = useState(false);

    function countWeeksToGo(date) {

        const today = moment();
        const upcomingEvents = moment(date);

        const totalWeek = upcomingEvents.diff(today, 'weeks')

        return totalWeek;
    };

    const column: any = [
        complete ? {
            label: "Target", desc: <>&nbsp;</>,
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        } : null,
        {
            label: "Activity", desc: <>&nbsp;</>,
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        },
        {
            label: "Distance", desc: "km",
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        },
        {
            label: "Target", desc: "Time",
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        },
        complete ? {
            label: "Actual", desc: "Time",
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        } : null,
        {
            label: "Speed", desc: "kph",
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        },
        {
            label: "Pace", desc: "min/km",
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        },
        {
            label: "Power", desc: "Raw",
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        },
        {
            label: "Power", desc: "Norm",
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        },
        {
            label: "HR", desc: "Avg",
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        },
        {
            label: "HR", desc: "Peak",
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        },
        {
            label: "Hydration", desc: <>&nbsp;</>,
            className: "table-bdr-right-gray",
            style: { minWidth: "50px" }
        },
        {
            label: "Fueling", desc: <>&nbsp;</>,
            style: { minWidth: "50px" }
        },
    ].filter(Boolean);

    const activities = complete ? event?.post?.activities : event?.pre?.activities;
    const total = complete ? event?.post?.total : event?.pre?.total;

    return (
        <>

            <InputTableRow className={index % 2 === 0 ? bg : ""}>

                <DataTableCell className='table-bdr-right-gray table-bg-white' alignMiddle>
                    <div style={{ height: 16 }}>
                        {show ? (
                            <FaMinus onClick={() => setShow(!show)} style={{ cursor: "pointer" }} />
                        ) : (
                            <FaPlus onClick={() => setShow(!show)} style={{ cursor: "pointer" }} />
                        )}
                    </div>
                </DataTableCell>

                <DataTableCell className='table-bdr-right-gray'>
                    {titleCase(event?.type)}
                </DataTableCell>

                <DataTableCell className='table-bdr-right-gray'>
                    {event?.name}
                </DataTableCell>

                <DataTableCell className='table-bdr-right-gray'>
                    {date(event?.date)}
                </DataTableCell>

                <DataTableCell className='table-bdr-right-gray'>
                    <div style={{ height: 16 }}>{getStars(event?.rating)}</div>
                </DataTableCell>

                <DataTableCell className='table-bdr-right-gray'>
                    Overall
                </DataTableCell>

                {complete ?
                    <>
                        <DataTableCell className='table-bdr-right-gray'>
                            {event.post?.overall?.distance}
                        </DataTableCell>

                        <DataTableCell className='table-bdr-right-gray'>
                            {event.post?.overall?.time}
                        </DataTableCell>

                        <DataTableCell className='table-bdr-right-gray'>
                            {event.post?.overall?.speed}
                        </DataTableCell>

                        <DataTableCell className='table-bdr-right-gray'>
                            {event?.post?.finishPosition}
                        </DataTableCell>

                        <DataTableCell >
                            Completed
                        </DataTableCell>
                    </>
                    :
                    <>
                        <DataTableCell className='table-bdr-right-gray'>
                            {event.pre?.overall?.distance}
                        </DataTableCell>

                        <DataTableCell className='table-bdr-right-gray'>
                            {event.pre?.overall?.time}
                        </DataTableCell>

                        <DataTableCell className='table-bdr-right-gray'>
                            {event.pre?.overall?.speed}
                        </DataTableCell>

                        <DataTableCell className='table-bdr-right-gray'>
                            {event.pre.goal}
                        </DataTableCell>

                        <DataTableCell>
                            {countWeeksToGo(event.date)} Weeks To Go
                        </DataTableCell>
                    </>
                }

            </InputTableRow>

            {show &&
                <InputTableRow>

                    <DataTableCell colSpan={1} />

                    <DataTableCell className={styles.eventCell} colSpan={100}>

                        <Box className={styles.eventBox}>

                            <DataTable
                                className={"mini-table " + (complete ? styles.eventsTableComplete : styles.eventsTableUpcoming)}
                                head={(
                                    <InputTableHeadRow className="table-bg-gray">
                                        {column.map((col, index) => (
                                            <>
                                                <InputTableHeadCell
                                                    key={index}
                                                    label={col.label}
                                                    desc={col.desc}
                                                    className={col.className}
                                                    style={col.style}
                                                />
                                            </>
                                        ))}
                                    </InputTableHeadRow>
                                )}
                            >

                                {complete && activities.length > 1 &&
                                    <InputTableRow>

                                        {complete &&
                                            <InputTableCell className='table-bdr-right-gray' alignMiddle>
                                                {getArrowDifference(event?.pre?.overall?.time, event?.post?.overall?.time)}
                                            </InputTableCell>
                                        }

                                        <DataTableCell className='table-bdr-right-gray'>
                                            Overall
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.post?.overall?.distance}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.pre?.overall?.time}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.post?.overall?.time}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.post?.overall?.speed}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.post?.overall?.pace}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.post?.overall?.powerRaw}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.post?.overall?.powerNorm}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {!isEmpty(event?.post?.overall?.hrAvg) && fixedNum(event?.post?.overall?.hrAvg, 0)}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {!isEmpty(event?.post?.overall?.hrPeak) && fixedNum(event?.post?.overall?.hrPeak, 0)}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.post?.overall?.hydration} L/hr
                                        </DataTableCell>

                                        <DataTableCell >
                                            {event?.post?.overall?.fueling} g/hr
                                        </DataTableCell>

                                    </InputTableRow>
                                }

                                {!complete && activities.length > 1 &&
                                    <InputTableRow
                                    >

                                        <DataTableCell className='table-bdr-right-gray'>
                                            Overall
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.pre?.overall?.distance}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.pre?.overall?.time}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.pre?.overall?.speed}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.pre?.overall?.pace}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.pre?.overall?.powerRaw}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.pre?.overall?.powerNorm}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {!isEmpty(event?.pre?.overall?.hrAvg) && fixedNum(event?.pre?.overall?.hrAvg, 0)}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {!isEmpty(event?.pre?.overall?.hrPeak) && fixedNum(event?.pre?.overall?.hrPeak, 0)}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {event?.pre?.overall?.hydration} L/hr
                                        </DataTableCell>

                                        <DataTableCell >
                                            {event?.pre?.overall?.fueling} g/hr
                                        </DataTableCell>

                                    </InputTableRow>
                                }

                                {activities.map((activity, index) => (

                                    <InputTableRow
                                        key={index}
                                    >

                                        {complete &&
                                            <InputTableCell className='table-bdr-right-gray' alignMiddle>
                                                {getArrowDifference(event?.pre?.activities[index].time, activity.time)}
                                            </InputTableCell>
                                        }

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {activity?.activity}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {activity?.distance}
                                        </DataTableCell>

                                        {complete &&
                                            <DataTableCell className='table-bdr-right-gray'>
                                                {(!isEmpty(event?.pre?.activities[index].time) && event?.pre?.activities[index].time !== 0) && (event?.pre?.activities[index].time)}
                                            </DataTableCell>
                                        }

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {(!isEmpty(activity?.time) && activity?.time !== 0) && (activity?.time)}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {(!isEmpty(activity?.speed) && activity?.speed !== 0) && fixedNum(activity?.speed, 0)}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {activity?.pace}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {activity?.powerRaw}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {activity?.powerNorm}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {(!isEmpty(activity?.hrAvg) && activity?.hrAvg !== 0) && fixedNum(activity?.hrAvg, 0)}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {(!isEmpty(activity?.hrPeak) && activity?.hrPeak !== 0) && fixedNum(activity?.hrPeak, 0)}
                                        </DataTableCell>

                                        <DataTableCell className='table-bdr-right-gray'>
                                            {activity?.activity == "Transition" ? "" : activity?.hydration + " L"}
                                        </DataTableCell>

                                        <DataTableCell >
                                            {activity?.activity == "Transition" ? "" : activity?.fueling + " g"}
                                        </DataTableCell>

                                    </InputTableRow>

                                ))}

                                <InputTableRow>

                                    {complete &&
                                        <>
                                            <DataTableCell className='table-bdr-right-gray' />
                                            <DataTableCell className='table-bdr-right-gray' />
                                        </>
                                    }

                                    <DataTableCell className='table-bdr-right-gray' />
                                    <DataTableCell className='table-bdr-right-gray' />
                                    <DataTableCell className='table-bdr-right-gray' />
                                    <DataTableCell className='table-bdr-right-gray' />
                                    <DataTableCell className='table-bdr-right-gray' />
                                    <DataTableCell className='table-bdr-right-gray' />
                                    <DataTableCell className='table-bdr-right-gray' />
                                    <DataTableCell className='table-bdr-right-gray' />
                                    <DataTableCell className='table-bdr-right-gray' />

                                    <DataTableCell className='table-bdr-right-gray'>
                                        {total?.hydration} L
                                    </DataTableCell>

                                    <DataTableCell >
                                        {total?.fueling} g
                                    </DataTableCell>

                                </InputTableRow>

                                <InputTableRow>

                                    {complete &&
                                        <DataTableCell className='table-bdr-right-gray' />
                                    }

                                    <DataTableCell className='table-bdr-right-gray'>
                                        Remarks
                                    </DataTableCell>

                                    <DataTableCell colSpan={11} >
                                        {complete ? event?.post?.comment : event?.pre?.comment}
                                    </DataTableCell>

                                </InputTableRow>

                            </DataTable>

                        </Box>

                    </DataTableCell>

                </InputTableRow >
            }

        </>
    )

};