import React, { useCallback, useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { ButtonRow } from '@dex/bubl-dash';
import GaitsEditTabsPanel from './GaitsEditTabsPanel';

const GaitsEditTabsReport: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, data } = props;

    const app = useApp();

    const shoeValues: any = [];
    const runValues: any = [];
    const pageValues: any = [];

    const shoeOptions = data?.shoes?.map((shoe, i) => {

        shoeValues.push(i + "");

        return { value: i + "", label: "Shoe " + (i + 1) + " - " + shoe.shoeBrand };

    });

    const runOptions = data?.runs?.map((run, i) => {

        runValues.push(i + "");

        return { value: i + "", label: "Run Analysis " + (i + 1) + " - " + run.date + " " + run.condition };

    });

    let pageOptions = [
        { value: "cover", label: "Cover" },
        { value: "runner", label: "Runner Information" },
        { value: "posture", label: "Posture/Foot" },
        { value: "stability", label: "Stability, Mobility & Symmetry" },
        { value: "shoes", label: "Shoes" },
        { value: "analysis", label: "Running Analysis" },
        { value: "metric", label: "Running Metrics" },
        { value: "rating", label: "Ratings And Changes" },
        { value: "photos", label: "Photos" },
        { value: "summary", label: "Summary" },
        { value: "exercises", label: "Exercises" },
    ];

    pageOptions.map((option, i) => {

        pageValues.push(option.value);

        return option;

    });

    const path = window.location.origin;

    const copyLink = useCallback((link) => {

        navigator.clipboard.writeText(link);

        app.alert('Link Copied To Clipboard', 'info');

    }, [data]);

    return useMemo(() => (

        <GaitsEditTabsPanel
            heading={"Report"}
            {...props}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <ButtonRow>
                            <Button
                                label={"View Report"}
                                path={`${path}/gait/report/${form.values?.reportId}`}
                                target={"_blank"}
                                size={"small"}
                                type={"primary"}
                            />

                            <Button
                                label={"Copy Link"}
                                onClick={copyLink.bind(null, `${path}/gait/report/${form.values?.reportId}`)}
                                size={"small"}
                                type={"light"}
                            />
                        </ButtonRow>

                    </Col>

                    <Col col={{ xs: 24, sm: 24 }}>

                        <FieldCheckbox
                            list={true}
                            form={form}
                            label={"Pages"}
                            name='report.pages'
                            defaultValue={form.defaults.report.pages}
                            required={true}
                            options={pageOptions}
                        />

                    </Col>

                    {(form.values.report.pages?.includes("shoes")) &&
                        <Col col={{ xs: 24, sm: 24 }}>

                            <FieldCheckbox
                                list={true}
                                form={form}
                                label={"Shoes"}
                                name='report.shoes'
                                defaultValue={form.defaults.report.shoes}
                                required={true}
                                options={shoeOptions}
                            />

                        </Col>
                    }

                    {((form.values.report.pages?.includes("analysis")) || (form.values.report.pages?.includes("metric")) ||
                        (form.values.report.pages?.includes("rating")) || (form.values.report.pages?.includes("photos"))) &&
                        <Col col={{ xs: 24, sm: 24 }}>

                            <FieldCheckbox
                                list={true}
                                form={form}
                                label={"Runs"}
                                name='report.runs'
                                defaultValue={form.defaults.report.runs}
                                required={true}
                                options={runOptions}
                            />

                        </Col>
                    }

                </Row>

            </Form>

        </GaitsEditTabsPanel>

    ), [props, form.hash, shoeOptions]);

}

export default GaitsEditTabsReport;