import React from 'react';
import { ComposedChart, Scatter, Cell, Bar, LabelList } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop, titleCase } from '@dex/bubl-helpers';
import { fancyTimeFormat } from '../../functions/sharedChart';
import { getMinutesTime } from '../../functions/sharedTests';

const ChartCoachingWeeklyIntensity: React.FC<ChartCoachingWeeklyIntensityProps> = (props) => {

    let { data, type, activity, intensity } = props;

    let dataArray: any = [];

    loop(data, (item, key) => {

        dataArray.push({
            key: titleCase(key).replace("Hr", "HR"),
            time: getMinutesTime(item.time),
            target: getMinutesTime(item.target),
            percent: item.total,
        });


    });

    const Axis = new ChartAxis();

    Axis.x.label = "Activity";
    Axis.x.key = "key";
    Axis.x.props = {
        interval: 0,
        type: "category",
        ticks: dataArray.map(item => item.key),
    }

    if (type == "hrs") {
        Axis.y.push({
            axis: true,
            // bar: true,
            pos: "left",
            index: 6,
            key: "time",
            axisLabel: "Hours",
            label: "Actual",
            props: {
                tickFormatter: fancyTimeFormat
            }
        });
        Axis.y.push({
            // axis: true,
            // bar: true,
            pos: "left",
            index: 6,
            key: "target",
            label: "Target",
            props: {
                tickFormatter: fancyTimeFormat
            }
        });
    } else {
        Axis.y.push({
            axis: true,
            // bar: true,
            pos: "left",
            index: 6,
            key: "percent",
            axisLabel: "Percentage [%]",
            label: "Percentage of Activity Time",
            props: {
                domain: [0, (dataMax) => Math.max(dataMax, 100)],
            }
        });
    };

    return (

        <Chart>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={dataArray}
            >

                {Axis.render()}

                <Bar
                    dataKey={Axis.y[0].key || ""}
                    yAxisId={"left-axis"}
                    barSize={60}
                    fill='#ffffff'
                >
                    {dataArray.map((item, index) => (
                        <>
                            <LabelList position="top" fill={ChartProps.indexColor(6)} formatter={Axis.y[0].props.tickFormatter} />
                            <Cell key={`cell-${index}`} fill={ChartProps.activityColor(item.key)} />
                        </>
                    ))}
                </Bar>


                {type === "hrs" &&
                    <Scatter
                        dataKey={'target'}
                        yAxisId={"left-axis"}
                        fill={ChartProps.indexColor(2)}
                    >
                        {dataArray.map((item, index) => (
                            <>
                                <Cell key={`cell-${index}`} fill={ChartProps.indexColor(2)} />
                            </>
                        ))}
                    </Scatter>
                }

            </ComposedChart>

        </Chart>

    )

}

interface ChartCoachingWeeklyIntensityProps {
    [key: string]: any,
}

export default ChartCoachingWeeklyIntensity;