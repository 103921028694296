import React from 'react';

import styles from "./ReportLabelValue.module.scss";

const ReportLabelValue: React.FC<ReportLabelValueProps> = (props) => {

    let { label, value, suffix, prefix, className, center, ...rest } = props;

    let classes = [styles.labelvalue];

    if (className && Array.isArray(className)) classes = [...classes, ...className];
    else if (typeof className === "string") classes.push(className);

    if (isNaN(value)) value = "-";

    return (

        <div
            className={classes.join(" ")}
            {...rest}
        >

            <div className={styles.label}>{label}</div>

            <div className={styles.value}>
                {prefix &&
                    <span className={styles.prefix}>{prefix} </span>
                }
                {value}
                {suffix &&
                    <span className={styles.suffix}> {suffix}</span>
                }
            </div>
        </div>

    )

}

interface ReportLabelValueProps {
    [key: string]: any,
}

export default ReportLabelValue;