import { Col, DataTableCell, InputTable, InputTableRow, Row, Space } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import { fixedNum, get, loop } from '@dex/bubl-helpers';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartSprintPerformance from '../../../elements/ChartLabTests/ChartSprintPerformance';
import PanelChart from '../../../elements/PanelChart/PanelChart';

const TestdatasReportSprintPerformance: React.FC<any> = (props: any) => {

    const { data, form } = props;
    const activity = data.test.activity;

    const [chartUse, setChartUse] = useState("avg");

    const checkArrayValue = (value, array, item) => {
        let selectedObject;

        for (const row of array) {
            if (row[item] === value) {
                selectedObject = row;
                break;
            }
        }

        return selectedObject?.time;
    };

    let powerPeak;
    let rpmPeak;

    if (activity == "Cycling") {

        if (chartUse == "raw") {

            powerPeak = checkArrayValue(get(data.test, `sumRawData.power_raw.max`), data.test?.rawData, "power_raw")
            rpmPeak = checkArrayValue(data.test?.sumRawData?.rpm?.max, data.test?.rawData, "rpm")

        } else if (chartUse == "rolling") {

            powerPeak = checkArrayValue(get(data.test, `sumData.power_raw.max`), data.test?.rollingData, "power_raw")
            rpmPeak = checkArrayValue(data.test?.sumData?.rpm?.max, data.test?.rollingData, "rpm")

        } else {

            powerPeak = checkArrayValue(get(data.test, `sumAvgData.power_raw.max`), data.test?.avgData, "power_raw")
            rpmPeak = checkArrayValue(data.test?.sumAvgData?.rpm?.max, data.test?.avgData, "rpm")

        }

    } else {

        if (chartUse == "raw") {

            powerPeak = checkArrayValue(data.test?.sumRawData?.speed?.max, data.test?.rawData, "speed")
            rpmPeak = checkArrayValue(data.test?.sumRawData?.pace?.max, data.test?.rawData, "pace")

        } else if (chartUse == "rolling") {

            powerPeak = checkArrayValue(data.test?.sumData?.speed?.max, data.test?.rollingData, "speed")
            rpmPeak = checkArrayValue(data.test?.sumData?.pace?.max, data.test?.rollingData, "pace")

        } else {

            powerPeak = checkArrayValue(data.test?.sumAvgData?.speed?.max, data.test?.avgData, "speed")
            rpmPeak = checkArrayValue(data.test?.sumAvgData?.pace?.max, form.values?.avgData, "pace")
        }
    };

    return useMemo(() => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 24, md: 24 }}>

                    <PanelChart
                        heading={activity == "Cycling" ? "Sprint Power and Cadence" : "Sprint Speed and Pace"}
                        types={[
                            {
                                name: "avg",
                                label: "Sprint Only",
                                data: data.test.avgData
                            },
                            {
                                name: "rolling",
                                label: "Average 5 Sec",
                                data: data.test.rollingData
                            },
                            {
                                name: "raw",
                                label: "Raw",
                                data: data.test.rawData
                            }
                        ]}
                        onTypeChange={setChartUse}
                        addZero={false}
                        renderChart={(chartType, chartData) => (

                            <>
                                <ChartSprintPerformance
                                    data={chartData}
                                    activity={activity}
                                    intensity={data.intensity}
                                    xAxis={{
                                        interval: ChartProps.interval(activity, chartType),
                                    }}
                                    type={chartType}
                                    peak={powerPeak}
                                    rpm={rpmPeak}
                                    performance={"power_raw"}
                                />
                            </>

                        )}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    {activity == "Cycling" ?

                        <InputTable
                            columns={[
                                {},
                                { colSpan: 2, label: "Power", alignMiddle: true },
                                { label: "RPM", alignMiddle: true },
                            ]}
                        >
                            <InputTableRow>

                                <DataTableCell>
                                    <strong>Peak - 1 Sec</strong>
                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumRawData?.power_raw?.max} W</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{fixedNum(data.test?.sumRawData?.power_raw?.max / data?.bio?.weight, 1)} W/kg</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumRawData?.rpm?.max}</strong>

                                </DataTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <DataTableCell>
                                    <strong>Peak - 5 Sec</strong>
                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumData?.power_raw?.max} W</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{fixedNum(data.test?.sumData?.power_raw?.max / data?.bio?.weight, 1)} W/kg</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumData?.rpm?.max}</strong>

                                </DataTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <DataTableCell>
                                    <strong>Avg - Full Sprint</strong>
                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumRawData?.power_raw?.avg} W</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{fixedNum(data.test?.sumRawData?.power_raw?.avg / data?.bio?.weight, 1)} W/kg</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumRawData?.rpm?.avg}</strong>

                                </DataTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <DataTableCell>
                                    <strong>Time To Peak</strong>
                                </DataTableCell>

                                <DataTableCell alignMiddle colSpan={2}>

                                    <strong>{powerPeak} sec</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{rpmPeak} sec</strong>

                                </DataTableCell>

                            </InputTableRow>

                        </InputTable>
                        :
                        <InputTable
                            columns={[
                                {},
                                { colSpan: 2, label: "Speed", alignMiddle: true },
                                { label: "Pace", alignMiddle: true },
                            ]}
                        >
                            <InputTableRow>

                                <DataTableCell>
                                    <strong>Peak - 1 Sec</strong>
                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumRawData?.speed?.max} kph</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{fixedNum(data.test?.sumRawData?.speed?.max / data?.bio?.weight, 1)} kph/kg</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumRawData?.pace?.max}</strong>

                                </DataTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <DataTableCell>
                                    <strong>Peak - 5 Sec</strong>
                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumAvgData?.speed?.max} kph</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{fixedNum(data.test?.sumAvgData?.speed?.max / data?.bio?.weight, 1)} kph/kg</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumAvgData?.pace?.max}</strong>

                                </DataTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <DataTableCell>
                                    <strong>Avg - Full Sprint</strong>
                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumAvgData?.speed?.avg} W</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{fixedNum(data.test?.sumAvgData?.speed?.avg / data?.bio?.weight, 1)} W/kg</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{data.test?.sumAvgData?.pace?.avg}</strong>

                                </DataTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <DataTableCell>
                                    <strong>Time To Peak</strong>
                                </DataTableCell>

                                <DataTableCell alignMiddle colSpan={2}>

                                    <strong>{powerPeak} sec</strong>

                                </DataTableCell>

                                <DataTableCell alignMiddle>

                                    <strong>{rpmPeak} sec</strong>

                                </DataTableCell>

                            </InputTableRow>

                        </InputTable>
                    }

                    <Space />

                    <ReportDesc
                        label="Sprint Capacity"
                        value={data.test.performance?.performanceDescription}
                    />


                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    {activity == "Cycling" ?
                        <RatingScaleCard
                            metric={'Sprint Power 5 sec'}
                            value={data.test?.sumData?.power_raw?.max}
                            name="performance.fiveSecondRatingScale"
                            demographic={get(form.values, 'performance.fiveSecondRatingScale', data.demographic)}
                            split={{
                                metric: 'Sprint Power/kg 5 sec',
                                value: fixedNum(data.test?.sumData?.power_raw?.max / data?.bio?.weight, 1),
                            }}
                        />
                        :
                        <RatingScaleCard
                            metric={'Sprint Speed 5 sec'}
                            value={data.test?.sumData?.speed?.max}
                            name="performance.fiveSecondRatingScale"
                            demographic={get(form.values, 'performance.fiveSecondRatingScale', data.demographic)}
                            split={{
                                metric: 'Sprint Pace 5 sec',
                                value: data.test?.sumData?.pace?.max,
                            }}
                        />
                    }

                    <Space />

                    {activity == "Cycling" ?
                        <RatingScaleCard
                            metric={'Sprint Power 20 sec'}
                            value={data.test?.sumAvgData?.power_raw?.avg}
                            name="performance.fiveSecondRatingScale"
                            demographic={get(form.values, 'performance.fiveSecondRatingScale', data.demographic)}
                            split={{
                                metric: 'Sprint Power/kg 20 sec',
                                value: fixedNum(data.test?.sumAvgData?.power_raw?.avg / data?.bio?.weight, 1),
                            }}
                        />
                        :
                        <RatingScaleCard
                            metric={'Sprint Speed 20 sec'}
                            value={data.test?.sumAvgData?.speed?.avg}
                            name="performance.twentySecondRatingScale"
                            demographic={get(form.values, 'performance.twentySecondRatingScale', data.demographic)}
                            split={{
                                metric: 'Sprint Pace 20 sec',
                                value: data.test?.sumAvgData?.pace?.avg,
                            }}
                        />
                    }

                </Col>

            </Row>

        </>

    ), [data, rpmPeak, powerPeak])
}

export default TestdatasReportSprintPerformance;