import React from 'react';
import styles from "./DehydrationSummary.module.scss";
import { Panel, Space } from '@dex/bubl-dash';

const DehydrationSummary: React.FC<DehydrationSummaryProps> = (props) => {

    const { fluidLossRate, fluidIntakeRateLow, fluidIntakeRateHigh, criticalLow, criticalHigh } = props

    return (

        <Panel heading="Your Dehydration" className={styles.panel} >

            <p>
                Assuming your body loses <span className={styles.greenText}>{fluidLossRate} ml/hr</span> of fluids:
            </p>

            <Space height={10} />

            <p>
                -If you consume <span className={styles.greenText}>{fluidIntakeRateLow} ml/hr </span>of fluids, it will take you <span className={styles.redText}>{criticalLow} hours</span> to dehydrate to a critical level of 5% body weight.
            </p>

            <Space height={10} />

            <p>
                -If you consume  <span className={styles.greenText}>{fluidIntakeRateHigh} ml/hr</span> of fluids, it will take you <span className={styles.redText}>{criticalHigh} hours</span>  before hitting critical dehydration.
            </p>

        </Panel>

    )

}

interface DehydrationSummaryProps {
    [key: string]: any,
}

export default DehydrationSummary;