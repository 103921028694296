import { Button, ButtonRow, Col, FieldDate, FieldRadio, FieldText, Form, Line, Panel, Row, useApp, useForm, useUpdateEffect } from '@dex/bubl-dash';
import moment from 'moment';
import React, { useCallback } from 'react';
import CoachingApi from '../CoachingApi';

const CoachingAddNewPlan: React.FC<any> = (props: any) => {

    const { data, onComplete } = props;

    const app = useApp();

    const form = useForm({ plans: {} });

    const add = CoachingApi.update(data.id, "addPlan", {

        onComplete: (data) => {

            app.alert('Plan Added', 'success');

            onComplete(data.id);

        }

    });

    const handleSubmit = useCallback((values) => {

        add.run({ data: values });

    }, [props]);

    return (

        <Panel
            heading={"Add Plan"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={add.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, sm: 8 }}>
                        <FieldDate
                            form={form}
                            name={'startDate'}
                            label={'Start Date'}
                            required={true}
                            defaultValue={form.defaults.startDate}
                            date
                        />
                    </Col>

                    <Col col={{ xs: 24, sm: 8 }}>
                        <FieldDate
                            form={form}
                            name={'endDate'}
                            label={'End Date'}
                            required={true}
                            defaultValue={form.defaults.endDate}
                            date
                        />
                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={add.loading}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    )
}



export default CoachingAddNewPlan;