import React from 'react';
import { HiArrowNarrowUp, HiArrowNarrowDown, HiCheck } from 'react-icons/hi';
import styles from "./RaceReadyLegend.module.scss";
import { Col, Row } from '@dex/bubl-dash';

const RaceReadyLegend: React.FC<RaceReadyLegendProps> = (props) => {

    const { justify } = props;

    const ArrowUp = <HiArrowNarrowUp className={styles.arrow} />;
    const ArrowDown = <HiArrowNarrowDown className={styles.arrow} />;
    const Check = <HiCheck className={styles.check} />;

    return (

        <Row edge gutter={"auto"} justify={justify}>

            <Col col={{ xs: 6, md: 4 }} align='center' >
                <div className={styles.text}>
                    {Check} <strong>Within Target</strong>
                </div>
            </Col>

            <Col col={{ xs: 6, md: 4 }} align='center' >
                <div className={styles.text}>
                    {ArrowDown} <strong>Below Target</strong>
                </div>
            </Col>

            <Col col={{ xs: 6, md: 4 }} align='center' >
                <div className={styles.text}>
                    {ArrowUp} <strong>Above Target</strong>
                </div>
            </Col>

        </Row>

    )

}

interface RaceReadyLegendProps {
    [key: string]: any,
}

export default RaceReadyLegend;