import { Button, Col, FieldTextArea, Row, Space } from '@dex/bubl-dash';
import { set } from '@dex/bubl-helpers';
import React, { useCallback, useMemo } from 'react';

const LabTestsRaceReadyComment: React.FC<LabTestsRaceReadyCommentProps> = (props) => {

    const { form, data, session } = props;

    const useDefaultText = useCallback((key: string) => {

        let text = ""
        const values = form.values;

        set(values, key, text);

        form.reset(values);

    }, [props, form.hash]);

    return useMemo(() => (

        <Row edge gutter={8}>

            <Col col={{ xs: 20 }}>

                <FieldTextArea
                    form={form}
                    label={"Appraiser's Summary"}
                    name={'comment.summary'}
                    required={false}
                    defaultValue={form.defaults?.comment?.summary}
                    rows={8}
                    maxLength={5000}
                />
            </Col>

            <Col col={{ xs: 4 }}>

                <Space />

                <Button
                    label={'Use Default Text'}
                    onClick={useDefaultText.bind(null, "comment.summary")}
                    type={'faded'}
                    confirm={
                        form.values?.comment?.summary ?
                            { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' }
                            :
                            undefined
                    }
                />

            </Col>

        </Row>

    ), [form.hash, data, session])
}

interface LabTestsRaceReadyCommentProps {
    [key: string]: any,
}

export default LabTestsRaceReadyComment;