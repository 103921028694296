import { Col, InputTable, InputTableCell, InputTableRow, Row, Space } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';

import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import PanelChart from '../../../elements/PanelChart/PanelChart'
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartEnergyFueling from '../../../elements/ChartTestSessionReport/ChartEnergyFueling';
import { labTestGetNutritionData } from '../../../functions/labTestData';
import ChartEnergy from '../../../elements/ChartLabTests/ChartEnergy';
import { MetricRanking } from '../../../elements/RatingScale/RatingScaleElements';
import { get, loop } from '@dex/bubl-helpers';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportEnergy: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const activity: string = data.test.activity;
    const intensity = data?.test?.intensity;
    const gender = data?.athlete?.gender;

    const [fuelUnit, setFuelUnit] = useState("Carb kcal");
    let unit;

    if (fuelUnit == "Carb kcal" || fuelUnit == "Fat kcal") {
        unit = "kcal"
    } else {
        unit = "g"
    };

    const [index, setIndex] = useState(data.history[0].date);

    const avgData = data.test.avgData;

    const tableData = labTestGetNutritionData(data.test, activity);

    const columns = [
        {
            key: "props",
            label: "Fuel-Burning Zones",
            style: { width: 440 }
        },
        {
            key: "firstCollumn",
            label: activity == "Cycling" ? "Power" : "Speed",
            desc: activity == "Cycling" ? "(W)" : "(kph)",
            style: { width: 300 }
        },
        {
            key: "secondCollum",
            label: "Carb Intake",
            desc: unit == "g" ? "(g/hr)" : "(kcal/hr)",
            style: { width: 300 }
        },
    ];

    const labels = {
        "Fat-Burning Primarily": "Fats Mostly",
        "Mixed Carb & Fat-Burning": "Carbs & Fats",
        "Carb-Burning Primarily": "Carbs Mostly"
    };

    const excludeNutrition = data.test?.energy?.nutritionUndetectable || [];

    const excludedUndetectableNutrition = (value) => {
        let conditional = true;

        loop(excludeNutrition, (nutrition, index) => {
            if (value == nutrition) conditional = false
        })

        return conditional
    }

    return useMemo(() => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={16} edge>

                <Col col={{ xs: 24 }}>

                    <ReportHeading
                        text={"Fatmax"}
                    />

                    <PanelChart
                        heading={"Energy Burn"}
                        addZero={false}
                        data={data.allData}
                        types={[
                            {
                                name: "Carb kcal",
                                label: "Carb Kcal/hr",
                                data: data.allData
                            },
                            {
                                name: "Fat kcal",
                                label: "Fat Kcal/hr",
                                data: data.allData
                            },
                            {
                                name: "Carb g",
                                label: "Carb g/hr",
                                data: data.allData
                            },
                            {
                                name: "Fat g",
                                label: "Fat g/hr",
                                data: data.allData
                            }
                        ]}
                        onTypeChange={setFuelUnit}
                        renderChart={(chartType, chartData) => (
                            <ChartEnergyFueling
                                activity={activity}
                                data={chartData}
                                date={data.recentDate}
                                index={index}
                                intensity={intensity}
                                xAxis={{
                                    interval: ChartProps.interval(activity, 'avg'),
                                }}
                                type="burn"
                                unit={chartType}
                                testData={data}
                            />
                        )}
                    />

                    <ReportChartSummary
                        key={index}
                        label={"Your FATMAX"}
                        valueKey={'fatmax'}
                        indexKey={"date"}
                        data={data.history}
                        type={"performance"}
                        index={index}
                        onIndexChange={setIndex}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={'Fatmax Rating - ' + activity}
                        value={data.test.energy.fatmaxFatPercent}
                        form={form}
                        demographic={data.test.energy.mepRatingScale || data.demographic}
                        renderAfter={() => (
                            <>
                                <MetricRanking
                                    data={{
                                        title: "Fatmax Rate",
                                        suffix: activity == "Running" ? "kph" : "w"
                                    }}
                                    value={data.test.energy.fatmaxLoad}
                                    rating={" "}
                                    altUnit={{
                                        value: unit == "g" ? data.test.energy?.fatmaxFatg : data.test.energy?.fatmaxFatkcal,
                                        suffix: unit == "g" ? "g/hr" : "kcal/hr",
                                        position: "before",
                                        sep: "@"
                                    }}
                                />
                            </>
                        )}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Fatmax"
                        value={data?.test.energy?.fatmaxDescription}
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <Space />

                    <ReportHeading
                        text={"Metabolic Efficiency Point (MEP)"}
                    />

                    <PanelChart
                        heading={"Metabolic Efficiency - Carb% vs Fat%"}
                        addZero={false}
                        types={[
                            {
                                name: "All",
                                label: "All",
                                data: data.allData
                            },
                            {
                                name: "Carb",
                                label: "Carb",
                                data: data.allData
                            }, {
                                name: "Fat",
                                label: "Fat",
                                data: data.allData
                            }

                        ]}
                        defaultType="All"
                        renderChart={(chartType, chartData) => (
                            <ChartEnergyFueling
                                activity={activity}
                                data={chartData}
                                date={data.recentDate}
                                index={index}
                                intensity={intensity}
                                xAxis={{
                                    interval: ChartProps.interval(activity, 'avg'),
                                }}
                                type="mep"
                                unit={chartType}
                                testData={data}
                            />
                        )}
                    />

                    <ReportChartSummary
                        key={index}
                        label={"Your MEP"}
                        valueKey={'mep'}
                        indexKey={"date"}
                        data={data.history}
                        type={"performance"}
                        onIndexChange={setIndex}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={'MEP Rating - ' + activity}
                        value={data.test.energy.mepLoadPercent}
                        form={form}
                        demographic={data.test.energy.mepRatingScale || data.demographic}
                        renderAfter={() => (
                            <>
                                <MetricRanking
                                    data={{
                                        title: "ME Point",
                                        suffix: activity == "Running" ? "kph" : "w"
                                    }}
                                    value={data.test.energy.mepLoad}
                                    rating={" "}
                                />
                            </>
                        )}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Metabolic Efficiency Point (MEP)"
                        value={data.test.energy?.mepDescription}
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <Space />

                    <ReportHeading
                        text={"Fueling"}
                    />

                    <PanelChart
                        heading={"Carbohydrate Fueling"}
                        addZero={false}
                        data={avgData}
                        types={[
                            {
                                name: "kcal",
                                label: "Kcal/hr",
                                data: avgData
                            },
                            {
                                name: "g",
                                label: "g/hr",
                                data: avgData
                            }
                        ]}
                        onTypeChange={setFuelUnit}
                        renderChart={(chartType, chartData) => (
                            <ChartEnergy
                                activity={activity}
                                data={chartData}
                                xAxis={{
                                    interval: ChartProps.interval(activity, 'avg'),
                                }}
                                testData={data}
                                intensity={intensity}
                                type="fueling"
                                unit={chartType}
                            />
                        )}
                    />
                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <InputTable
                        columns={columns}
                    >

                        {(tableData || []).map((row, index) => {

                            return (

                                <React.Fragment key={index}>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>{labels[row.zone]}</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 300, paddingLeft: 12 }}>

                                            {activity == "Cycling" ? row.power_raw : row.speed}

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 300, paddingLeft: 12 }}>

                                            {excludedUndetectableNutrition(row.zone) &&
                                                <>
                                                    {unit == "g" ? row.refuel : row.eerefuel}
                                                </>
                                            }

                                        </InputTableCell>

                                    </InputTableRow>

                                </React.Fragment>
                            )

                        })}

                    </InputTable>

                </Col>

                <Col col={{ xs: 24, md: 12 }}>
                    <ReportDesc
                        label="The Carbohydrate"
                        value={data?.test.energy?.carbDescription}
                    />
                </Col>

            </Row>

        </>

    ), [data, tableData]);

}

export default TestSessionsReportEnergy;