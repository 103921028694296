import { Row, Col, FieldNumber, FieldSelect, Line, useUpdateEffect, useMountEffect, Heading, Space } from '@dex/bubl-dash';
import React, { useEffect, useMemo, useState } from 'react';
import LabTestsApi from '../LabTestsApi';
import { date, dateDay, get, loop } from '@dex/bubl-helpers';
import { FormulaTable } from '../Vo2Max/LabTestsVo2Analysis';
import { labAnalysisRegresionCharts } from '../../../functions/chartRegression';

const LabTestsSweatWorkoutCurrentMax: React.FC<any> = (props: any) => {

    const { form, data, session, tab } = props;

    const [options, setOptions] = useState<any>([]);
    const [recentTest, setRecentTest] = useState<any>([]);
    const currentMax = get(form.values, 'currentMax');

    const recentLabTests = LabTestsApi.get();

    const [key, setKey] = useState(0);

    useMountEffect(() => {

        recentLabTests.run({
            params: {
                where: {
                    date: { lte: dateDay(session.date) },
                    athleteId: session.athleteId
                },
                order: ["date DESC"]
            },
            onComplete: (response) => {

                const options: any = [];

                loop(response.data, (item: any) => {

                    if (!["VO2max", "Race Ready", "Performance"].includes(item.type)) return;

                    options.push({
                        label: [date(item.date), item.type, item.activity, item.intensity].filter(Boolean).join(", "),
                        value: item.id,
                    });

                });

                setOptions(options);

            },
        });

    });

    useEffect(() => {

        let labTest: any = null;

        loop(recentLabTests?.data?.data, (item: any) => {

            if (item.id !== currentMax?.labTestId) return;

            labTest = item;

            const hrMaxKey = 'hr.' + get(labTest, 'hr.zoneUse', 'max');
            const hrMax = get(labTest, hrMaxKey);
            const vo2Max = get(labTest, 'vo2.relativeMax');

            form.handleChange({ silent: true, reset: true, name: `currentMax.currentVo2Max`, value: vo2Max });
            form.handleChange({ silent: true, reset: true, name: `currentMax.currentHrMax`, value: hrMax });

            setKey((key) => key + 1);

        });

        setRecentTest(labTest);

    }, [currentMax?.labTestId, recentLabTests.loading]);

    //@ts-ignore
    const { mergedAvg, hr, vo2, vo2hr, x } = useMemo(() => {

        try {

            if (!recentTest) return {};

            return labAnalysisRegresionCharts(recentTest.avgData, recentTest.activity);

        } catch (error) {


        }

        return {};

    }, [recentTest]);

    useEffect(() => {

        form.handleChange({ silent: true, reset: true, name: `intercept`, value: vo2?.regression?.intercept });
        form.handleChange({ silent: true, reset: true, name: `slope`, value: vo2?.regression?.slope });

    }, [vo2])

    return useMemo(() => (

        <>

            {options && options.length > 0 &&
                <Row gutter={8} edge>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSelect
                            form={form}
                            label='Recent Lab Test'
                            name={`currentMax.labTestId`}
                            defaultValue={currentMax?.labTestId}
                            options={options}
                        />

                    </Col>

                </Row>
            }

            <Line />

            <Row gutter={8} edge >

                <Col col={{ xs: 24, md: 16 }}>

                    <Heading space={false}>
                        Current Max Measures
                    </Heading>

                    <Row gutter={8} edge key={currentMax?.labTestId}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldNumber
                                key={key}
                                form={form}
                                label='Vo2 Max'
                                name={`currentMax.currentVo2Max`}
                                defaultValue={currentMax?.currentVo2Max}
                                suffix=' ml/kg*min'
                                wrapClass={!currentMax?.labTestId ? 'manual-input' : ''}
                                disabled={currentMax?.labTestId}
                                hidden={currentMax?.labTestId}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldNumber
                                key={key}
                                form={form}
                                label='HR Max'
                                name={`currentMax.currentHrMax`}
                                defaultValue={currentMax?.currentHrMax}
                                suffix=' bpm'
                                wrapClass={!currentMax?.labTestId ? 'manual-input' : ''}
                                disabled={currentMax?.labTestId}
                                hidden={currentMax?.labTestId}
                            />

                        </Col>

                    </Row>

                </Col>

                {recentTest && vo2 &&
                    <Col col={{ xs: 24, md: 8 }}>

                        <FormulaTable
                            reg={vo2}
                        />

                    </Col>
                }

            </Row>

        </>

    ), [form.hash, recentTest, currentMax, data, session, options, key]);

}

export default LabTestsSweatWorkoutCurrentMax;
