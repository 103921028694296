import React, { useEffect, useMemo, useState } from 'react';
import { fixedNum, get, unslug } from '@dex/bubl-helpers';
import { Col, FieldSelect, Panel, Row, Space, useForm, useUpdateEffect } from '@dex/bubl-dash';

import styles from "./FieldResultsTeamChangeChart.module.scss";

const FieldResultsTeamChangeChart: React.FC<FieldResultsTeamChangeChartProps> = (props) => {

    const { history, template, category, heading, scale, index, setIndex, missed } = props;

    const getHistoryLabel = (value) => {

        const result = history?.list?.find(item => item.label === value);

        return result ? result.value : "";

    };

    const getHistoryValue = (value) => {

        const result = history?.list?.find(item => item.value === value);

        return result ? result.label : "";

    };

    const form = useForm({ date: getHistoryLabel(index) || history?.list[0]?.value });

    const { changes, team, changeMissed } = useMemo(() => {

        if (template) {

            const results = get(history, `sessions[${form.values.date}].results[${template.id}]`, {});

            return { changes: results?.changes || {}, team: results?.team, changeMissed: results?.missed || [] };

        }

        if (category) {

            const summary = get(history, `sessions[${form.values.date}].summary[${category}]`);

            return { changes: summary?.summary || {}, team: summary?.team, changeMissed: summary?.missed || [] };

        }

        return { changes: {}, team: null };

    }, [form.values.date, history, template, category]);

    let bestScore = scale?.bestScore || "highest";

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: "date", value: getHistoryLabel(index) })
    }, [index])

    const changeDate = (val) => {

        if (!setIndex) return;

        let value = getHistoryValue(val.value);

        setIndex(value)

    };

    const [combinedMissed, setCombinedMissed] = useState<any>([]);

    useEffect(() => {

        setCombinedMissed(Array.from(new Set([...missed, ...changeMissed])));

    }, [template, category]);

    return (

        <>

            <Panel
                heading={heading}
                actions={<>
                    <FieldSelect
                        form={form}
                        name={"date"}
                        defaultValue={form.defaults.date}
                        options={history.list}
                        isClearable={false}
                        onChange={changeDate}
                        required
                    />
                </>}
                className={styles.panel}
                full={true}
            >

                <div className={styles.innerAthlete}>
                    {Object.values(changes).map((item: any, index) => (

                        <FieldResultsTeamChangeChartBar
                            key={index}
                            {...item}
                            units={template?.units || ""}
                            bestScore={bestScore}
                            template={template}
                            category={category}
                        />

                    ))}
                </div>

                {!team &&
                    <Space />
                }

                {team &&
                    <TeamChange
                        title="Team Average Change"
                        change={team.change}
                        unit={template?.units}
                        percent={team.percent}
                        bestScore={bestScore}
                        template={template}
                        category={category}
                    />
                }

            </Panel>

            <Row edge gutter={8} className={styles.legend}>

                <Col col={{ xs: 8 }}>
                    <div className={styles.improved}> <div />Improved</div>
                </Col>

                <Col col={{ xs: 8 }}>
                    <div className={styles["no-change"]}> <div />No Change</div>
                </Col>

                <Col col={{ xs: 8 }}>
                    <div className={styles.worsened}> <div />Worsened</div>
                </Col>

                <Col col={{ xs: 24 }} >
                    <p className={styles.underText}>%Change &gt;1% is considered an improvement while below -1% as worsened.</p>
                </Col>

            </Row >

            {combinedMissed.length >= 1 &&

                <Row edge gutter={8}>

                    <Col>
                        <p>Athlete with No Change Score:<br />(Missed a Test Session)</p>
                    </Col>

                    <Col grow={1} className={styles.listName}>

                        <Row edge gutter={1}>

                            {combinedMissed.map((miss: any, index) => (
                                <Col col={{ xs: 8 }} key={index}>
                                    * {miss.name}
                                </Col>
                            ))}

                        </Row>

                    </Col>

                </Row >
            }

        </>

    )

}

const TeamChange: React.FC<FieldResultsTeamChangeChartProps> = (props) => {

    const { title, change, unit, percent, bestScore, template } = props;

    let changeLabel = "no-change";

    if (percent >= 1) changeLabel = "Improved";
    else if (percent <= -1) changeLabel = "Worsened";

    return (
        <div className={styles.innerTeam}>

            <div className={styles.metric}>

                <>{title}: </>

                <Space height={8} />

                <div className={[styles.rating, styles[changeLabel]].join(" ")}>{unslug(changeLabel)}</div>

            </div>

            {template ?
                <div className={[styles.valueContainer, styles[changeLabel]].join(" ")}>

                    {fixedNum(change, template.decimalPlace)}<span className={styles.unit}>{unit} </span>

                    <span className={styles.sep}> / </span>

                    <span>{Math.abs(percent)}</span> <span className={styles.unit}> %</span>

                </div>
                :
                <div className={[styles.valueContainer, styles[changeLabel]].join(" ")}>

                    <span>{Math.abs(percent)}</span> <span className={styles.unit}> %</span>

                </div>
            }
        </div>
    )
}

const FieldResultsTeamChangeChartBar: React.FC<FieldResultsTeamChangeChartProps> = (props) => {

    const { ranking, percent, units, value, name, bestScore, category, template } = props;

    let change = "nochange"

    if (percent >= 1) change = "improved";
    else if (percent <= -1) change = "regressed";
    else if (percent === null) change = "nodata";

    return (

        <div className={styles.container}>

            <p className={styles.name}>{ranking}. {name}</p>

            <div className={[styles.chart, styles["chart-" + change]].join(" ")}>

                <div
                    className={styles.row}
                >

                    <div
                        className={styles.bar}
                        style={{ width: `${Math.abs(percent)}%` }}
                    />

                    {template ?
                        <p className={styles.label}>{fixedNum(value, template.decimalPlace)} {units} / {Math.abs(percent)}%</p>
                        :
                        <p className={styles.label}>{percent}%</p>
                    }

                </div>

                <div className={styles.center} />

            </div>

        </div>

    )

}

interface FieldResultsTeamChangeChartProps {
    [key: string]: any,
}

export default FieldResultsTeamChangeChart;