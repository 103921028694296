import React, { useEffect } from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { slug } from '@dex/bubl-helpers';

const ChartBrDepth: React.FC<ChartBrDepthProps> = (props) => {

    let { data, activity, intensity, type, date, index } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity(activity, intensity);

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    if (type == "rate") {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "rf_" + slug(date),
            label: date,
            axisLabel: "Rate - brpm",
            props: {
                domain: [0, 80]
            }
        });

        if (date !== index) {

            Axis.y.push({
                axis: false,
                line: true,
                pos: "left",
                index: 0,
                key: "rf_" + slug(index),
                label: index,
                props: {
                    strokeDasharray: "6 4",
                    connectNulls: true,
                }
            });

        }

    }

    if (type == "depth") {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 1,
            key: "tvl_" + slug(date),
            label: date,
            axisLabel: "Depth (Tidal Volume) - L",
            props: {
                domain: [0, 6]
            }
        });

        if (date !== index) {

            Axis.y.push({
                axis: false,
                line: true,
                pos: "left",
                index: 1,
                key: "tvl_" + slug(index),
                label: index,
                props: {
                    strokeDasharray: "6 4",
                    connectNulls: true,
                }
            });

        }

    }

    useEffect(() => {
        props.getChartType(type)
    }, [type])

    return (

        <Chart>

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartBrDepthProps {
    [key: string]: any,
}

export default ChartBrDepth;