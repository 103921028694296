import { FieldAutocomplete, FieldRadio, FieldSelect, InputTable, InputTableCell, InputTableRow } from '@dex/bubl-dash';
import { get, loop } from '@dex/bubl-helpers';
import React, { useMemo } from 'react';

const FieldTestsConditions: React.FC<FieldTestsConditionsProps> = (props) => {

    const { form, data, session } = props;

    const tests = useMemo(() => {

        const tests: any = [];
        const templates: any = {};

        const enabled = Object.keys(form.values?.tests?.enabled || {});

        loop(session.templates, (template) => {

            if (template.options === "overall") return;

            templates[template.id] = template;

        });

        loop(enabled, (templateId) => {

            if (!templates[templateId]) return;

            tests.push(templates[templateId]);

        });

        return tests;

    }, [session.templates]);

    const columns = [
        {
            label: "Test Name",
            key: "template.name",
        },
        {
            label: "Indoor / Outdoor",
            key: "roofing",
        },
        {
            label: "Facility Type",
            key: "facilityType",
        },
        {
            label: "Flooring",
            key: "flooring",
        },
    ];

    return useMemo(() => (

        <>

            <InputTable
                columns={columns}
            >

                {(tests || []).map((test, n) => (

                    <InputTableRow key={n}>

                        <InputTableCell style={{ paddingLeft: 12 }}>
                            {test.name}
                        </InputTableCell>

                        <InputTableCell style={{ width: 260 }}>

                            <FieldRadio
                                form={form}
                                name={`conditions[${test.id}].roofing`}
                                defaultValue={get(form.defaults, `conditions[${test.id}].roofing`)}
                                options={[
                                    { label: "Indoor", value: "indoor" },
                                    { label: "Outdoor", value: "outdoor" },
                                ]}
                            />

                        </InputTableCell>

                        <InputTableCell style={{ width: 260 }}>

                            <FieldAutocomplete
                                form={form}
                                name={`conditions[${test.id}].facilityType`}
                                defaultValue={get(form.defaults, `conditions[${test.id}].facilityType`)}
                                group={"facilityType"}
                                preload={true}
                                sort={false}
                            />

                        </InputTableCell>

                        <InputTableCell style={{ width: 260 }}>

                            <FieldAutocomplete
                                form={form}
                                name={`conditions[${test.id}].flooring`}
                                defaultValue={get(form.defaults, `conditions[${test.id}].flooring`)}
                                group={"flooring"}
                                preload={true}
                                sort={false}
                            />

                        </InputTableCell>

                    </InputTableRow>
                ))}

            </InputTable>

        </>

    ), [form.hash, tests])

}

interface FieldTestsConditionsProps {
    [key: string]: any,
}

export default FieldTestsConditions;