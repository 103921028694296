import React, { useCallback, useContext, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, ButtonRow, FieldCheckbox, FieldNumber, FieldRadio, FieldSelect, FieldText, FieldTextArea, FieldTextTime, Form, InputTable, InputTableCell, InputTableRow, Line, MetaTable, Space, useApp, useForm } from '@dex/bubl-dash';
import { getPace, getSpeed, getSwimPace } from '../../../functions/sharedTests';
import { get } from '@dex/bubl-helpers';
import TestSessionsApi from '../TestSessionsApi';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import EditableContext from '../../../context/EditableContext';

const TestSessionsGeneralForm: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const editable = useContext(EditableContext);

    const form = useForm(data);

    const update = TestSessionsApi.update(data.id, "general", {
        onComplete: (data) => {

            app.alert('Test Session Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    const swimPace = getSwimPace(get(form.values, 'general.currentSwimDistance'), get(form.values, 'general.currentSwimTime'));
    const bikePace = getPace(get(form.values, 'general.currentBikeDistance'), get(form.values, 'general.currentBikeTime'));
    const runPace = getPace(get(form.values, 'general.currentRunDistance'), get(form.values, 'general.currentRunTime'));

    const swimSpeed = getSpeed(get(form.values, 'general.currentSwimDistance'), get(form.values, 'general.currentSwimTime'));
    const bikeSpeed = getSpeed(get(form.values, 'general.currentBikeDistance'), get(form.values, 'general.currentBikeTime'));
    const runSpeed = getSpeed(get(form.values, 'general.currentRunDistance'), get(form.values, 'general.currentRunTime'));

    const bikePower = 0; //todo need formula from patrick

    const columns = [
        {
            key: "props",
            label: "Current FTPs",
        },
        {
            key: "distance",
            label: "Distance",
        },
        {
            key: "time",
            label: "Time",
        },
        {
            key: "pace",
            label: "Pace",
        },
        {
            key: "speed",
            label: "Speed",
        },
        {
            key: "power",
            label: "Avg Power",
        },
    ];

    return useMemo(() => {

        return (

            <>

                <MetaTable
                    data={data}
                    columnA={[
                        {
                            label: "Athlete",
                            key: "athlete",
                            format: 'modelLink',
                        },
                    ]}
                    columnB={[
                        {
                            label: "Session Date",
                            key: "date",
                            format: 'date',
                        },
                    ]}
                    columnC={[
                        {
                            label: "Appraisers",
                            key: "fitters",
                            format: 'modelLink',
                        },
                    ]}

                />

                <Line />

                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={update.loading}
                >

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24, lg: 12 }}>

                            <FieldTextArea
                                form={form}
                                name="general.reasonForTest"
                                label="Reason For Test"
                                required={false}
                                defaultValue={form.defaults.general.reasonForTest}
                                maxLength={400}
                                rows={5}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24, lg: 12 }}>

                            <FieldTextArea
                                form={form}
                                name="general.performanceGoals"
                                label="Performance Goals"
                                required={false}
                                defaultValue={form.defaults.general.performanceGoals}
                                maxLength={400}
                                rows={5}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24, lg: 12 }}>

                            <FieldTextArea
                                form={form}
                                rows={5}
                                maxLength={300}
                                name="general.exercise24Hrs"
                                label="Exercise 24hrs"
                                defaultValue={form.defaults.general?.exercise24Hrs}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24, lg: 12 }}>

                            <FieldText
                                form={form}
                                name="general.foodToday"
                                label="Food Today"
                                defaultValue={form.defaults.general?.foodToday}
                                wrapClass={"manual-input"}
                            />


                            <FieldText
                                form={form}
                                name="general.timeOfLastMeal"
                                label="Time of Last Meal"
                                defaultValue={form.defaults.general?.timeOfLastMeal}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 24, sm: 24, md: 12 }}>

                            <FieldSelect
                                button={true}
                                form={form}
                                name="general.nutritionDiet"
                                label="Nutrition Diet"
                                defaultValue={form.defaults.general?.nutritionDiet}
                                required={false}
                                options={[
                                    { value: "High Carb", label: "High Carb" },
                                    { value: "Mixed Carb/Protein", label: "Mixed Carb/Protein" },
                                    { value: "Low Carb", label: "Low Carb" },
                                ]}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24, lg: 12 }}>

                            <FieldSelect
                                form={form}
                                label={"Carb Intake"}
                                name='general.carbIntake'
                                defaultValue={form.defaults.general.carbIntake}
                                options={[
                                    { value: "Very High (>400g)", label: "Very High (>400g)" },
                                    { value: "High (300-400g)", label: "High (300-400g)" },
                                    { value: "Moderate (200-300g)", label: "Moderate (200-300g)" },
                                    { value: "Low (100g-200g)", label: "Low (100g-200g)" },
                                    { value: "Very Low (<100g)", label: "Very Low (<100g)" },
                                ]}
                                list
                                wrapClass={"manual-input"}
                            // info={`Guide:\n-1 Fruit drink is 30g\n-1 Slice of Bread is 15g\n-1 Cup of pasta is 40g\n-1 Cup of grapes is ~27g`}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24, lg: 12 }}>

                            <FieldText
                                form={form}
                                name="general.currentTrainingHours"
                                label="Current Training Hours"
                                defaultValue={form.defaults.general?.currentTrainingHours}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24, lg: 12 }}>

                            <FieldText
                                form={form}
                                label={"Years of Training"}
                                name={`general.yearsOfTraining`}
                                defaultValue={form.defaults.general.yearsOfTraining}
                                wrapClass={"manual-input"}
                            />

                        </Col >

                        <Col col={{ xs: 24, md: 24, lg: 12 }}>

                            <FieldSelect
                                form={form}
                                label={`Frequency Of Hard Workouts`}
                                name='general.accustomedHardWorkout'
                                defaultValue={form.defaults.general.accustomedHardWorkout}
                                options={[
                                    { value: "Yes Frequently", label: "Yes Frequently" },
                                    { value: "Yes Sometimes", label: "Yes Sometimes" },
                                    { value: "Rarely", label: "Rarely" },
                                    { value: "Never", label: "Never" },
                                ]}
                                list
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24, lg: 12 }}>

                            <FieldRadio
                                form={form}
                                name="general.fitnessLevel"
                                label="Fitness Level"
                                defaultValue={form.defaults.general?.fitnessLevel}
                                wrapClass={"manual-input"}
                                options={[
                                    { value: "Untrained", label: "Untrained" },
                                    { value: "Low", label: "Low" },
                                    { value: "Moderate", label: "Moderate" },
                                    { value: "High", label: "High" },
                                    { value: "Very High", label: "Very High" }
                                ]}
                                button
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24, lg: 12 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Reason For Training"}
                                name='general.reasonForCycling'
                                defaultValue={form.defaults.general.reasonForCycling}
                                options={[
                                    { value: "Fun and Recreation", label: "Fun and Recreation" },
                                    { value: "Fitness or Training", label: "Fitness or Training" },
                                    { value: "Racing and Competing", label: "Racing and Competing" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12, lg: 12 }}>

                            <FieldUpload
                                form={form}
                                name='general.photo'
                                folder='admin-media'
                                label='Photo'
                                defaultValue={form.defaults.general.photo}
                                multiple={false}
                                accept='image/*'
                                limit={1}
                            />

                        </Col>

                        <Col col={{ xs: 24 }} >

                            <Space height={"small"} />

                            <InputTable
                                columns={columns}
                            >

                                <InputTableRow>

                                    <InputTableCell style={{ width: 180, paddingLeft: 12 }}>
                                        <><strong>Swim</strong></>
                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldNumber
                                            form={form}
                                            name="general.currentSwimDistance"
                                            suffix={" km"}
                                            defaultValue={form.defaults.general?.currentSwimDistance}
                                            wrapClass={"manual-input"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldTextTime
                                            form={form}
                                            name="general.currentSwimTime"
                                            defaultValue={form.defaults.general?.currentSwimTime}
                                            placeholder={"h:mm"}
                                            wrapClass={"manual-input"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldText
                                            form={form}
                                            disabled
                                            name="general.currentSwimPace"
                                            key={swimPace}
                                            defaultValue={(swimPace || "0") + " min/100m"}
                                        />

                                    </InputTableCell><InputTableCell style={{ width: 120 }}>

                                        <FieldNumber
                                            form={form}
                                            disabled
                                            suffix={" km/h"}
                                            name="general.currentSwimSpeed"
                                            key={swimSpeed}
                                            defaultValue={swimSpeed}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        {/* <FieldNumber
                                        form={form}
                                        name="general.currentSwimAvgPower"
                                        defaultValue={form.defaults.general?.currentSwimAvgPower}
                                        suffix={" watts"}
                                    /> */}

                                    </InputTableCell>

                                </InputTableRow>

                                <InputTableRow>

                                    <InputTableCell style={{ width: 180, paddingLeft: 12 }}>
                                        <><strong>Bike</strong></>
                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldNumber
                                            form={form}
                                            name="general.currentBikeDistance"
                                            suffix={" km"}
                                            defaultValue={form.defaults.general?.currentBikeDistance}
                                            wrapClass={"manual-input"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldTextTime
                                            form={form}
                                            name="general.currentBikeTime"
                                            defaultValue={form.defaults.general?.currentBikeTime}
                                            placeholder={"h:mm"}
                                            wrapClass={"manual-input"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldText
                                            form={form}
                                            disabled
                                            name="general.currentBikePace"
                                            key={bikePace}
                                            defaultValue={bikePace + " min/km"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldNumber
                                            form={form}
                                            disabled
                                            suffix={" km/h"}
                                            name="general.currentBikeSpeed"
                                            key={bikeSpeed}
                                            defaultValue={bikeSpeed}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldNumber
                                            form={form}
                                            name="general.currentBikeAvgPower"
                                            // key={bikePower}
                                            defaultValue={form.defaults.general?.currentBikeAvgPower}
                                            suffix={" watts"}
                                            wrapClass={'manual-input'}
                                        />

                                    </InputTableCell>

                                </InputTableRow>

                                <InputTableRow>

                                    <InputTableCell style={{ width: 180, paddingLeft: 12 }}>
                                        <><strong>Run</strong></>
                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldNumber
                                            form={form}
                                            name="general.currentRunDistance"
                                            suffix={" km"}
                                            defaultValue={form.defaults.general?.currentRunDistance}
                                            wrapClass={"manual-input"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldTextTime
                                            form={form}
                                            name="general.currentRunTime"
                                            defaultValue={form.defaults.general?.currentRunTime}
                                            placeholder={"h:mm"}
                                            wrapClass={"manual-input"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldText
                                            form={form}
                                            disabled
                                            name="general.currentRunPace"
                                            key={runPace}
                                            defaultValue={runPace + " min/km"}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        <FieldNumber
                                            form={form}
                                            disabled
                                            suffix={" km/h"}
                                            name="general.currentRunSpeed"
                                            key={runSpeed}
                                            defaultValue={runSpeed}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: 120 }}>

                                        {/* <FieldNumber
                                        form={form}
                                        name="general.currentRunAvgPower"
                                        defaultValue={form.defaults.general?.currentRunAvgPower}
                                        suffix={" watts"}
                                    /> */}

                                    </InputTableCell>

                                </InputTableRow>

                            </InputTable>

                        </Col>

                    </Row>

                    {editable &&
                        <>
                            <Line />

                            <ButtonRow>
                                <Button
                                    label={"Save"}
                                    loading={update.loading}
                                />
                            </ButtonRow>
                        </>
                    }

                </Form>

            </>
        )

    }, [props, form.values, update.loading]);

}

export default TestSessionsGeneralForm;
