import { Row, Col, FieldDate, FieldText, FieldSelect, FieldHidden, FieldNumber, FieldTextTime, InputTable, InputTableCell, InputTableRow, Space, DataTableCell } from '@dex/bubl-dash';
import { get, loop } from '@dex/bubl-helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { getClockTime } from '../../../functions/sharedTests';
import { glycogenEventList } from '../../../functions/glycogenTest';

const LabTestsGlycogenManualEvent: React.FC<any> = (props: any) => {

    const { i, form, data, prefix, item, session, recentTest, setRecentTest } = props;

    const [key, setKey] = useState(0);

    const eventIndex = get(form.values, `${prefix}.event`);

    const options: any = [];

    loop(session.events || [], (item: any, index) => {

        options.push({
            label: item.name,
            value: index + "",
        });

    });

    useEffect(() => {

        const event = session.events[parseInt(eventIndex)] || {};
        form.handleChange({ silent: true, reset: true, name: `${prefix}.type`, value: event.type })
        form.handleChange({ silent: true, reset: true, name: `${prefix}.name`, value: event.name })
        form.handleChange({ silent: true, reset: true, name: `${prefix}.date`, value: event.date })
        form.handleChange({ silent: true, reset: true, name: `${prefix}.activities`, value: event.activities })
        form.handleChange({ silent: true, reset: true, name: `${prefix}.overall`, value: event.overall })

        setKey((key) => key + 1);

    }, [event]);

    return useMemo(() => (

        <>

            <Row gutter={8} edge>

                <Col col={{ xs: 24, md: 6 }}>
                    <FieldSelect
                        form={form}
                        name={`${prefix}.event`}
                        defaultValue={get(form.values, `${prefix}.event`)}
                        label='Event'
                        options={options}
                        required={options.length >= 1 ? true : false}
                        wrapClass={"manual-input"}
                    />
                </Col>


                <Col col={{ xs: 6 }}>

                    <FieldSelect
                        form={form}
                        label="Activity"
                        name={`${prefix}.activity`}
                        defaultValue={get(form.values, `${prefix}.activity`)}
                        options={[
                            { value: "Cycling", label: "Cycling" },
                            { value: "Running", label: "Running" },
                            { value: "Swimming", label: "Swimming" },
                        ]}
                        wrapClass={"manual-input"}
                        required={get(form.values, `${prefix}.event`) ? true : false}
                    />

                </Col >

                <Col col={{ xs: 24, md: 6 }}>
                    <FieldSelect
                        form={form}
                        name={`${prefix}.type`}
                        defaultValue={get(form.values, `${prefix}.type`)}
                        label='Event Type'
                        options={glycogenEventList}
                        disabled
                        hidden
                    />
                </Col>

                <Col col={{ xs: 24, md: 6 }}>
                    <FieldDate
                        form={form}
                        name={`${prefix}.date`}
                        defaultValue={get(form.values, `${prefix}.date`)}
                        label='Date'
                        disabled
                    />
                </Col>

                <Col col={{ xs: 24 }}>

                    <Space />

                    {item.activities &&
                        <CalculationTable
                            event={get(form.values, `${prefix}.type`)}
                            form={form}
                            prefix={prefix}
                            activities={item.activities || []}
                            isDisabled={true}
                            overall={item.overall}
                        />
                    }

                </Col>

            </Row>

        </>

    ), [form.hash, recentTest, data, session, options]);

}

const CalculationTable: React.FC<any> = (props: any) => {

    const { event, form, prefix, activities, isDisabled, overall } = props;

    const column = [
        {
            key: "type",
            style: { width: "220px" }
        },
        {
            key: "distance",
            label: "Distance"
        },
        {
            key: "time",
            label: "Time"
        },
        {
            key: "pace",
            label: "Pace"
        },
        {
            key: "speed",
            label: "Speed"
        },
        {
            key: "power",
            label: "Power"
        },
    ];

    return (
        <InputTable
            columns={column}
            key={event}
        >
            {activities.map((item, index) => {

                return (
                    <InputTableRow key={index}>

                        <DataTableCell>
                            <strong>{item.activity}</strong>
                            <FieldHidden
                                form={form}
                                name={`${prefix}.activities[${index}].activity`}
                                value={item.activity}
                            />
                        </DataTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                suffix=" km"
                                name={`${prefix}.activities[${index}].distance`}
                                defaultValue={get(item, "distance")}
                                disabled={isDisabled}
                                hidden={isDisabled}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldTextTime
                                form={form}
                                name={`${prefix}.activities[${index}].time`}
                                defaultValue={get(item, "time")}
                                placeholder={"h:mm"}
                                disabled={isDisabled}
                                hidden={isDisabled}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldText
                                form={form}
                                name={`${prefix}.activities[${index}].pace`}
                                defaultValue={`${get(item, `pace`)}`}
                                disabled={isDisabled}
                                hidden={isDisabled}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                suffix=" km/h"
                                name={`${prefix}.activities[${index}].speed`}
                                defaultValue={get(item, `speed`)}
                                disabled={isDisabled}
                                hidden={isDisabled}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            {item.activity == "Bike" &&
                                <FieldNumber
                                    form={form}
                                    suffix=" W"
                                    name={`${prefix}.activities[${index}].power`}
                                    defaultValue={get(item, `power`)}
                                    disabled={isDisabled}
                                    hidden={isDisabled}
                                />
                            }
                        </InputTableCell>

                    </InputTableRow>
                )
            })}

            {activities.length > 1 &&
                <InputTableRow
                    key={overall}
                >

                    <DataTableCell>
                        <strong>Overall</strong>
                        <FieldHidden
                            form={form}
                            name={`${prefix}.overall.activity`}
                            value={'Overall'}
                        />
                    </DataTableCell>

                    <InputTableCell>
                        <FieldNumber
                            key={overall.distance}
                            form={form}
                            suffix=" km"
                            name={`${prefix}.overall.distance`}
                            disabled
                            hidden
                            defaultValue={overall.distance}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldTextTime
                            key={overall.time}
                            form={form}
                            name={`${prefix}.overall.time`}
                            placeholder=" h:mm"
                            disabled
                            defaultValue={getClockTime(overall.time)}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldText
                            key={overall.pace}
                            form={form}
                            disabled
                            name={`${prefix}.overall.pace`}
                            defaultValue={(getClockTime(overall.pace) || "0") + " min/km"}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            key={overall.speed}
                            form={form}
                            disabled
                            suffix=" km/h"
                            name={`${prefix}.overall.speed`}
                            defaultValue={overall.speed}
                        />
                    </InputTableCell>

                    <InputTableCell>

                    </InputTableCell>

                </InputTableRow>
            }

        </InputTable>
    )
}

export default LabTestsGlycogenManualEvent;
