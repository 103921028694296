import React, { useMemo, useState } from 'react';
import ReportChartSummary from '../ReportChartSummary/ReportChartSummary';
import { hash, loop } from '@dex/bubl-helpers';
import PerformanceChart from '../FieldResultChart/PerformanceChart/PerformanceChart';
import PanelChart from '../PanelChart/PanelChart';
import styles from "./FieldResultsPerformanceChart.module.scss";

const FieldResultsPerformanceChart: React.FC<FieldResultsPerformanceChartProps> = (props) => {

    let { template, results, report, history, isTeam, index, setIndex } = props;

    const getAthleteData = () => {

        const data: any = [];

        loop(history.sessions, (history) => {

            const _results = history.results[template.id];

            if (!_results) return;

            data.push({
                id: history.id,
                date: history.period,
                score: _results.score,
                rating: _results.rating,
                ranking: _results.ranking,
                percentile: _results.percentile,
            });

        })

        data.push({
            id: report.session.id,
            date: report.session.period,
            score: results.score,
            rating: results.rating,
            ranking: results.ranking,
            percentile: results.percentile,
        });

        return data;

    }

    const getTeamData = () => {

        const data: any = [];

        loop(history.sessions, (session) => {

            const _results = session.results[template.id];

            if (!_results) return;

            //add to start of array
            data.unshift({
                id: session.id,
                sessionDate: session.sessionDate,
                date: session.period,
                score: _results?.team?.past,
            });

        });

        //current session
        data.push({
            id: report.session.id,
            date: report.session.period,
            score: results?.team?.avg,
        });

        return data;

    }

    const data = useMemo(() => {

        if (!index) return;

        if (isTeam) return getTeamData();
        else return getAthleteData();

    }, [index, history, template]);

    return (

        <div>

            {data && data.length > 0 &&

                <>

                    <PanelChart
                        key={hash(data)}
                        heading={"Performance Over Time"}
                        data={data}
                        addZero={true}
                        addZeroEnd={true}
                        renderChart={(chartType, chartData) => (

                            <>
                                <PerformanceChart
                                    data={chartData}
                                    units={template.units}
                                    options={template.options}
                                    isTeam={isTeam}
                                    decimalPlace={template.decimalPlace}
                                    xAxis={{
                                        interval: 0
                                    }}
                                />
                            </>

                        )}
                    />

                </>
            }

            <ReportChartSummary
                label={"Performance"}
                valueKey={'score'}
                indexKey={"date"}
                type={"performance"}
                data={data}
                index={index}
                onIndexChange={setIndex}
            />

        </div>

    )

}

interface FieldResultsPerformanceChartProps {
    [key: string]: any,
}

export default FieldResultsPerformanceChart;