import { Col, Row, Space } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';

import styles from "./TestSessionsReport.module.scss";
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import PanelChart from '../../../elements/PanelChart/PanelChart'
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ChartVo2Max from '../../../elements/ChartTestSessionReport/ChartVo2Max';
import { get } from '@dex/bubl-helpers';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportFitness: React.FC<any> = (props: any) => {

    const { data, form } = props;
    const activity = data.test.activity;
    const intensity = data?.test?.intensity;
    const gender = data?.athlete?.gender;

    const [index, setIndex] = useState(data.history[0].date);

    return useMemo(() => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={16} edge>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={'VO2 Max'}
                        value={get(data.test, 'vo2.' + get(data.test, "vo2.zoneUse", "relativeMax"))}
                        form={form}
                        demographic={data.test.vo2.vo2MaxScale || data.demographic}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="VO2max"
                        value={data.test.vo2?.vo2Description}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={'Engine Size'}
                        value={get(data.test, 'vo2.' + get(data.test, "vo2.zoneUseAbsolute", "absoluteMax"))}
                        form={form}
                        demographic={data.test.vo2.engineSizeScale || data.demographic}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Engine Size"
                        value="is an indicator of your body’s total aerobic
                    capacity. It is influence not only by your fitness level but also
                    your body size. So generally, the larger and fitter the body,
                    the more oxygen that can be consumed. Elite athletes can
                    have engine sizes above 6 L per min."
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <ReportHeading
                        text={"Your VO2max Over Time"}
                    />

                    <PanelChart
                        heading={"VO2max Over Time"}
                        data={data.allData}
                        renderChart={(chartType, chartData) => (
                            <ChartVo2Max
                                activity={activity}
                                data={chartData}
                                index={index}
                                date={data.recentDate}
                                intensity={intensity}
                                xAxis={{
                                    interval: ChartProps.interval(activity, 'avg'),
                                }}
                            />
                        )}
                    />

                    <ReportChartSummary
                        label={"Your V02max"}
                        valueKey={'vo2RelativeMax'}
                        indexKey={"date"}
                        data={data.history}
                        type={"performance"} //performance, size
                        onIndexChange={setIndex}
                    />

                </Col>

            </Row>

        </>

    ), [data, index]);

}

export default TestSessionsReportFitness;