import React from 'react';
import { LineChart, ReferenceLine, Label } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartSprintLactate: React.FC<ChartSprintLactateProps> = (props) => {

    let { data, activity, intensity, peak } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity("time", intensity);


    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
        interval: 0,
        tickFormatter: undefined
    };

    Axis.x.label = "Recovery Time - Min";
    Axis.x.key = "manualTime";

    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 0,
        key: "lactate",
        label: "mmol/L",
        axisLabel: "Lactate - mmol/L",
        props: {
            domain: [0, 10]
        }
    });

    return (

        <Chart>

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >

                {peak &&
                    <ReferenceLine
                        x={peak}
                        stroke={ChartProps.indexColor(2)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={"Peak Lactate"}
                            position='top'
                            fill={ChartProps.indexColor(2)}
                            stroke={ChartProps.indexColor(2)}
                        />
                    </ReferenceLine>
                }

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartSprintLactateProps {
    [key: string]: any,
}

export default ChartSprintLactate;