import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldCheckbox, FieldNumber, FieldRadio, FieldSelect, FieldTextArea, Heading, Line, Seperator, Space } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartMuscle from '../../../elements/ChartLabTests/ChartMuscle';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartSprintMuscle from '../../../elements/ChartLabTests/ChartSprintMuscle';

const LabTestsSprintMuscle: React.FC<any> = (props: any) => {

    const { form, data, session } = props;

    const activity = data.activity;

    return useMemo(() => (

        <Row gutter={20} edge={true}>

            <Col col={{ xs: 24, md: 12 }}>

                <Seperator
                    heading={`Moxy A - ${form.values.equipment.moxyLocationA || "-"}`}
                    description={form.values.equipment.moxySensorA || "-"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <PanelChart
                            heading={"Muscle Oxygen & THb"}
                            types={[
                                {
                                    name: "avg",
                                    label: "Sprint Only",
                                    data: form.values.avgData
                                },
                                {
                                    name: "rolling",
                                    label: "Average 5 Sec",
                                    data: form.values.rollingData
                                },
                                {
                                    name: "raw",
                                    label: "Raw",
                                    data: form.values.rawData
                                }
                            ]}
                            addZero={false}
                            renderChart={(chartType, chartData) => (

                                <>
                                    <ChartSprintMuscle
                                        data={chartData}
                                        activity={activity}
                                        keyIndex={"1"}
                                        intensity={data.intensity}
                                        xAxis={{
                                            interval: ChartProps.interval(activity, chartType),
                                        }}
                                        type={chartType}
                                        duration={data.duration}
                                    />
                                </>

                            )}
                        />

                        <FieldCheckbox
                            key={form.values?.muscle?.zoneUse}
                            form={form}
                            label={form.values.equipment.moxyLocationA || "-"}
                            name={"muscle.zoneUse"}
                            defaultValue={form.values?.muscle?.zoneUse}
                            singular={true}
                            options={[
                                { label: "Used For Report", value: "a" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <Heading space={false}>SMO2 %</Heading>

                        <Space height={8} />

                        <FieldNumber
                            form={form}
                            name={"muscle.a.smo2Min"}
                            defaultValue={form.defaults?.muscle?.a?.smo2Min}
                            label={"Min"}
                            wrapClass={"w-160"}
                        />

                        <FieldNumber
                            form={form}
                            name={"muscle.a.smo2Avg"}
                            defaultValue={form.defaults?.muscle?.a?.smo2Avg}
                            label={"Avg"}
                            wrapClass={"w-160"}
                        />

                        <FieldNumber
                            form={form}
                            name={"muscle.a.smo2Max"}
                            defaultValue={form.defaults?.muscle?.a?.smo2Max}
                            label={"Max"}
                            wrapClass={"w-160"}
                        />

                        <FieldNumber
                            form={form}
                            name={"muscle.a.smo2Drop"}
                            defaultValue={form.defaults?.muscle?.a?.smo2Drop}
                            label={"Drop"}
                            wrapClass={"w-160"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <Heading space={false}>THb mg/dl</Heading>

                        <Space height={8} />

                        <FieldNumber
                            form={form}
                            name={"muscle.a.thbMin"}
                            defaultValue={form.defaults?.muscle?.a?.thbMin}
                            label={"Min"}
                            wrapClass={"w-160"}
                        />

                        <FieldNumber
                            form={form}
                            name={"muscle.a.thbAvg"}
                            defaultValue={form.defaults?.muscle?.a?.thbAvg}
                            label={"Avg"}
                            wrapClass={"w-160"}
                        />

                        <FieldNumber
                            form={form}
                            name={"muscle.a.thbMax"}
                            defaultValue={form.defaults?.muscle?.a?.thbMax}
                            label={"Max"}
                            wrapClass={"w-160"}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <Space height={8} />

                        <RatingScaleCard
                            metric={'SMO2 Rating'}
                            form={form}
                            value={get(form.values, 'muscle.a.smo2Min')}
                            name="muscle.smo2RatingScale"
                            demographic={session.demographic}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <Seperator
                    heading={`Moxy B - ${form.values.equipment.moxyLocationB || "-"}`}
                    description={form.values.equipment.moxySensorB || "-"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <PanelChart
                            heading={"Muscle Oxygen & THb"}
                            types={[
                                {
                                    name: "avg",
                                    label: "Sprint Only",
                                    data: form.values.avgData
                                },
                                {
                                    name: "rolling",
                                    label: "Average 5 Sec",
                                    data: form.values.rollingData
                                },
                                {
                                    name: "raw",
                                    label: "Raw",
                                    data: form.values.rawData
                                }
                            ]}
                            addZero={false}
                            renderChart={(chartType, chartData) => (

                                <>
                                    <ChartSprintMuscle
                                        data={chartData}
                                        activity={activity}
                                        keyIndex={"2"}
                                        intensity={data.intensity}
                                        xAxis={{
                                            interval: ChartProps.interval(activity, chartType),
                                        }}
                                        type={chartType}
                                        duration={data.duration}
                                    />
                                </>

                            )}
                        />

                        <FieldCheckbox
                            key={form.values?.muscle?.zoneUse}
                            form={form}
                            label={form.values.equipment.moxyLocationB || "-"}
                            name={"muscle.zoneUse"}
                            defaultValue={form.values?.muscle?.zoneUse}
                            singular={true}
                            options={[
                                { label: "Used For Report", value: "b" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <Heading space={false}>SMO2 %</Heading>

                        <Space height={8} />

                        <FieldNumber
                            form={form}
                            name={"muscle.b.smo2Min"}
                            defaultValue={form.defaults?.muscle?.b?.smo2Min}
                            label={"Min"}
                            wrapClass={"w-160"}
                        />

                        <FieldNumber
                            form={form}
                            name={"muscle.b.smo2Avg"}
                            defaultValue={form.defaults?.muscle?.b?.smo2Avg}
                            label={"Avg"}
                            wrapClass={"w-160"}
                        />

                        <FieldNumber
                            form={form}
                            name={"muscle.b.smo2Max"}
                            defaultValue={form.defaults?.muscle?.b?.smo2Max}
                            label={"Max"}
                            wrapClass={"w-160"}
                        />

                        <FieldNumber
                            form={form}
                            name={"muscle.b.smo2Drop"}
                            defaultValue={form.defaults?.muscle?.b?.smo2Drop}
                            label={"Drop"}
                            wrapClass={"w-160"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <Heading space={false}>THb mg/dl</Heading>

                        <Space height={8} />

                        <FieldNumber
                            form={form}
                            name={"muscle.b.thbMin"}
                            defaultValue={form.defaults?.muscle?.b?.thbMin}
                            label={"Min"}
                            wrapClass={"w-160"}
                        />

                        <FieldNumber
                            form={form}
                            name={"muscle.b.thbAvg"}
                            defaultValue={form.defaults?.muscle?.b?.thbAvg}
                            label={"Avg"}
                            wrapClass={"w-160"}
                        />

                        <FieldNumber
                            form={form}
                            name={"muscle.b.thbMax"}
                            defaultValue={form.defaults?.muscle?.b?.thbMax}
                            label={"Max"}
                            wrapClass={"w-160"}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <Space height={8} />

                        <RatingScaleCard
                            metric={'SMO2 Rating'}
                            form={form}
                            value={get(form.values, 'muscle.b.smo2Min')}
                            name="muscle.smo2RatingScale"
                            demographic={session.demographic}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24 }}>

                <Line
                    top={false}
                    bottom={false}
                />

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <FieldTextArea
                    form={form}
                    label={"Muscle Oxygen (SMO2) Description"}
                    name={"muscle.sprintSmo2Description"}
                    defaultValue={form.defaults?.muscle?.sprintSmo2Description}
                    rows={4}
                />

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <FieldTextArea
                    form={form}
                    label={"Total Hemoglobin (THb) Description"}
                    name={"muscle.sprintTbhDescription"}
                    defaultValue={form.defaults?.muscle?.sprintTbhDescription}
                    rows={4}
                />

            </Col>

        </Row>

    ), [props]);

}

export default LabTestsSprintMuscle;
