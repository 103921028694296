import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Button } from '@dex/bubl-dash';
import TestSessionsGeneralForm from './TestSessionsGeneralForm';
import EditableContext from '../../../context/EditableContext';

const TestSessionsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>


            <Row
                gutter={'auto'}
            >

                <Col
                    col={{ xs: 24 }}
                // className={'main'}
                >

                    <Panel
                        heading={"Test Session Details"}
                        actions={() => (
                            <>
                                <Button
                                    label="Edit Session"
                                    type="faded"
                                    size="small"
                                    path={`/lab/test-sessions/${data.id}/edit/info`}
                                />
                            </>
                        )}
                    >

                        <EditableContext.Provider value={true}>

                            <TestSessionsGeneralForm
                                data={data}
                            />

                        </EditableContext.Provider>

                    </Panel>

                </Col>

                <Col className={'side'}>


                </Col>

            </Row>

        </>

    ), [data]);

}

export default TestSessionsSingleView;
