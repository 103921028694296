import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';

const SessionsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24, md: 24, lg: 8 }}>

                <FieldSelect
                    button={true}
                    form={form}
                    label={"Fit Type"}
                    name='fitType'
                    required={true}
                    isMulti={false}
                    defaultValue={form.defaults.fitType}
                    options={[
                        { value: "Comprehensive Bike Fit", label: "Comprehensive Bike Fit" },
                        { value: "Basic Bike Fit", label: "Basic Bike Fit" },
                        { value: "Bike Sizing", label: "Bike Sizing" },
                        { value: "Size and Fit", label: "Size and Fit" },
                        { value: "Remote Sizing", label: "Remote Sizing" },
                        { value: "Refit Same Bike ", label: "Refit Same Bike " },
                        { value: "Refit New Bike", label: "Refit New Bike" },
                        { value: "Cleat Alignment ", label: "Cleat Alignment" },
                        { value: "Other Services", label: "Other Services" },
                    ]}
                />

            </Col>

            <Col col={{ xs: 24, md: 12, lg: 8 }}>

                <FieldRelationship
                    form={form}
                    label='Athlete'
                    name='athleteId'
                    required={true}
                    preload={true}
                    endpoint={'Athletes'}
                    defaultValue={form.defaults.athleteId}
                />

            </Col>

            <Col col={{ xs: 24, md: 12, lg: 8 }}>

                <FieldDate
                    form={form}
                    label={"Session Date"}
                    name={`sessionDate`}
                    required={true}
                    defaultValue={form.defaults.sessionDate}
                    date={true}
                />

            </Col>

            <Col col={{ xs: 24 }}>
                <Line />
            </Col>


            <Col col={{ xs: 24, md: 8 }}>

                <FieldSelect
                    form={form}
                    label={"Cyclist Level"}
                    name='cyclistLevel'
                    defaultValue={form.defaults.cyclistLevel}
                    options={[
                        { value: "Novice", label: "Novice" },
                        { value: "Intermediate", label: "Intermediate" },
                        { value: "Experienced", label: "Experienced" },
                        { value: "Elite", label: "Elite" }
                    ]}
                />

            </Col>

            <Col col={{ xs: 24, md: 8 }}>

                <FieldSelect
                    form={form}
                    label={"Cycling Mileage per Week"}
                    name='cyclingMileagePerWeek'
                    defaultValue={form.defaults.cyclingMileagePerWeek}
                    options={[
                        { value: "None Currently", label: "None Currently" },
                        { value: "Very-Low (<50KM)", label: "Very Low (<50KM)" },
                        { value: "Low (50-100KM)", label: "Low (50-100KM)" },
                        { value: "Moderate (101-200KM)", label: "Moderate (101-200KM)" },
                        { value: "Moderately-High (201-300KM)", label: "Moderately-High (201-300KM)" },
                        { value: "High (301-400KM)", label: "High (301-400KM)" },
                        { value: "Very-High (401-500KM)", label: "Very High (401-500KM)" },
                        { value: "Elite (>501KM)", label: "Elite (>501KM)" }
                    ]}
                />

            </Col>

            <Col col={{ xs: 24, md: 8 }}>

                <FieldSelect
                    form={form}
                    label={"Competes?"}
                    name='competes'
                    defaultValue={form.defaults.competes}
                    options={[
                        { value: "Occasionally (2-5 events per year)", label: "Occasionally (2-5 events per year)" },
                        { value: "Frequently (5-10 events per year)", label: "Frequently (5-10 events per year)" },
                        { value: "Often ( 1 or more a month)", label: "Often ( 1 or more a month)" },
                        { value: "Rarely (1 or less/year)", label: "Rarely (1 or less/year)" },
                        { value: "Not Currently", label: "Not Currently" },
                        { value: "No, Never", label: "No, Never" },
                    ]}
                />

            </Col>

            <Col col={{ xs: 24, md: 8 }}>

                <FieldText
                    form={form}
                    name="yearsRiding"
                    label="Years Riding"
                    suffix={" Years"}
                    defaultValue={form.defaults.yearsRiding}
                />

            </Col>

            <Col col={{ xs: 24, sm: 8 }}>

                <FieldText
                    form={form}
                    name="fittedElsewhere"
                    label="Fitted Elsewhere?"
                    required={false}
                    defaultValue={form.defaults.fittedElsewhere}
                />

            </Col>

            <Col col={{ xs: 24, sm: 8 }}>

                <FieldRelationship
                    form={form}
                    name="fitterId"
                    label="Fitter"
                    required={false}
                    defaultValue={form.defaults.fitterId}
                    preload={true}
                    endpoint={'Fitters'}
                />

            </Col>

            <Col col={{ xs: 24, sm: 8 }}>

                <FieldRelationship
                    form={form}
                    name="assistantFittersId"
                    label="Assistant Fitters"
                    required={false}
                    defaultValue={form.defaults.assistantFittersId}
                    preload={true}
                    endpoint={'Fitters'}
                    isMulti={true}
                />

            </Col>

            <Col col={{ xs: 24 }}>
                <Line />
            </Col>

            <Col col={{ xs: 24, sm: 24 }}>

                <FieldTextArea
                    form={form}
                    name="reasonForFit"
                    label="Reason For Fit"
                    required={false}
                    defaultValue={form.defaults.reasonForFit}
                    maxLength={400}
                />

            </Col>

            <Col col={{ xs: 24, sm: 24 }}>

                <FieldText
                    form={form}
                    name="performanceGoals"
                    label="Performance Goals"
                    required={false}
                    defaultValue={form.defaults.performanceGoals}
                    maxLength={150}
                />

            </Col>

            <Col col={{ xs: 24, sm: 24 }}>

                <FieldTextArea
                    form={form}
                    name="comfortIssuesWhenRiding"
                    label="Comfort Issues When Riding"
                    defaultValue={form.defaults.comfortIssuesWhenRiding}
                    maxLength={650}
                />

            </Col>

        </Row>

    ), [props]);

}

export default SessionsCreateEditForm;
