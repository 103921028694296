import React, { useEffect, useMemo, useState } from 'react';
import { Col, FieldSelect, Line, Row, Space } from '@dex/bubl-dash';
import FieldResultsTestSummary from '../../../elements/FieldResultsTestSummary/FieldResultsTestSummary';
import FieldRatingChart from '../../../elements/FieldRatingChart/FieldRatingChart';
import FieldResultsPerformanceChart from '../../../elements/FieldResultsPerformanceChart/FieldResultsPerformanceChart';

const FieldResultsAthleteScore: React.FC<any> = (props: any) => {

    const { report, form } = props;

    useEffect(() => {

        if (form.values.testId) return;

        if (report.tests && report.tests[0]) form.handleChange({ name: "testId", value: report.tests[0].value, reset: true });

    }, []);

    const { results, template, scale } = useMemo(() => {

        if (!form.values.testId) return {};

        const results = report.results[form.values.testId] || null;

        const template = results ? report.templates[results.templateId] : null;

        const scale = results ? report.scales[results.templateId] : null;

        return { results, template, scale };

    }, [form.values.testId]);

    const [index, setIndex] = useState(report?.history?.list[0]?.label || null);

    return useMemo(() => (

        <>

            <Row gutter={"auto"} edge={true}>

                <Col col={{ xs: 8 }}>

                    <FieldSelect
                        form={form}
                        name={"testId"}
                        label={'Select Test'}
                        defaultValue={form.defaults.testId}
                        options={report.tests}
                        required={true}
                        isClearable={false}
                    />

                </Col>

            </Row>

            <Line />

            {!results &&
                <Space height={400} />
            }

            {results &&
                <Row gutter={"auto"} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldResultsTestSummary
                            template={template}
                            details={results.details}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldRatingChart
                            scale={scale}
                            score={results.score}
                            rating={results.rating}
                            ranking={results.ranking}
                            label={results.label}
                            athleteCount={report.session.athletesIds.length}
                            percentile={results.percentile}
                            comments={results.summary}
                            options={template.options} //left/right
                            values={results.values} //left/right/diff
                            decimalPlaces={template.decimalPlaces}
                        />

                    </Col>

                    {report.history.list.length > 0 &&
                        <Col col={{ xs: 24 }}>

                            <FieldResultsPerformanceChart
                                template={template}
                                report={report}
                                results={results}
                                history={report.history}
                                index={index}
                                setIndex={setIndex}
                            />

                        </Col>
                    }

                </Row>
            }

        </>

    ), [report, results]);

}

export default FieldResultsAthleteScore;
