import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Space } from '@dex/bubl-dash';
import { parseNum } from '@dex/bubl-helpers';
import TestSessionsReportMenu from './TestSessionsReportMenu';
import TestSessionsReportPage from './TestSessionsReportPage';

import styles from "./TestSessionsReport.module.scss";
import TestSessionsReportCover from './TestSessionsReportCover';
import TestSessionsReportGlycogenDepletion from './TestSessionsReportGlycogenDepletion';

const TestSessionsReportGlycogen: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const [activePage, setActivePage] = useState(0);

    const observer: any = useRef();

    const pages: any = [
        {
            key: "cover",
            label: "Cover",
            heading: false,
            el: TestSessionsReportCover,
            ref: useRef()
        },
        {
            key: "energy-depletion",
            label: "Energy Depletion",
            heading: "Energy Depletion And Fueling",
            el: TestSessionsReportGlycogenDepletion,
            ref: useRef()
        }
    ].filter((page) => {

        if (!data.reportPages || !data.reportPages.length) return true;

        return data.reportPages.includes(page.key) ? true : false;

    });

    useEffect(() => {

        const callback = (entries, observer) => {

            let inViewTarget: any = null;
            let inViewRatio = 0;

            entries.forEach((entry, index) => {

                if (entry.isIntersecting && entry.intersectionRatio > inViewRatio) {

                    inViewRatio = entry.intersectionRatio;
                    inViewTarget = entry.target;

                }

            })

            if (inViewTarget) setActivePage(parseNum(inViewTarget.id));

        };

        const observer = new IntersectionObserver(callback, {
            threshold: .4,
        });

        pages.map((page) => {

            observer.observe(page.ref.current);

        });

        () => {

            pages.map((page) => {

                observer.unobserve(page.ref.current);

            });

            observer.disconnect();

        }

    }, []);

    const handleScrollTo = useCallback((ref, event) => {

        if (event && event.preventDefault) event.preventDefault();

        ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

    }, []);

    return useMemo(() => (

        <>

            <TestSessionsReportMenu
                data={data}
                activePage={activePage}
                pages={pages}
                handleScrollTo={handleScrollTo}
                form={form}
            />

            {pages.map((page, index) => {

                return (
                    <React.Fragment key={index}>

                        {index >= 0 &&
                            <Space />
                        }

                        {page.key === "energy-depletion" &&

                            <>
                                {data.test?.glycogens.map((test, testIndex) => (

                                    <TestSessionsReportPage
                                        key={testIndex}
                                        heading={page.heading}
                                        icon={test.activity == "Running" ? require("../../../assets/images/LabReport/icon-running.png") : require("../../../assets/images/LabReport/icon-cycling.png")}
                                        index={index}
                                        observer={observer}
                                        forwardedRef={page.ref}
                                        setActivePage={setActivePage}
                                        form={form}
                                    >

                                        {page.el &&
                                            <page.el
                                                data={data}
                                                test={test}
                                                form={form}
                                            />
                                        }

                                    </TestSessionsReportPage>

                                ))}

                            </>

                        }

                        {page.key !== "energy-depletion" &&

                            <TestSessionsReportPage
                                heading={page.heading}
                                icon={page.icon}
                                index={index}
                                observer={observer}
                                forwardedRef={page.ref}
                                setActivePage={setActivePage}
                                form={form}
                            >

                                {page.el &&
                                    <page.el
                                        data={data}
                                        form={form}
                                    />
                                }

                            </TestSessionsReportPage>

                        }



                    </React.Fragment>
                )
            })}

        </>

    ), [data, activePage]);

}

export default TestSessionsReportGlycogen;