import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import CoachingApi from '../CoachingApi';
import EditableContext from '../../../context/EditableContext';
import { get } from '@dex/bubl-helpers';
import CoachingCreateEditForm from './CoachingCreateEditForm';
import CoachingEditTabsPlans from './CoachingEditTabsPlans';
import CoachingEditTabsEvents from './CoachingEditTabsEvents';
import CoachingEditTabsTraining from './CoachingEditTabsTraining';
import CoachingEditTabsLog from './CoachingEditTabsLog';
import CoachingEditTabsZone from './CoachingEditTabsZone';
import CoachingEditTabsReport from './CoachingEditTabsReport';
import { Box } from '@dex/bubl-dash';

const CoachingSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const tab = get(props, 'routeParams.tab');

    const form = useForm(data);

    const [nav] = app.store("nav");

    let action = tab;

    if (tab === "info" || !tab) action = "update"

    const update = CoachingApi.update(data.id, action, {
        onComplete: (data) => {

            app.alert('Coaching Saved', 'success');

            app.navigate(nav, 'update', {});
        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    let TabElement: any = React.Fragment;

    if (tab === "info" || !tab) TabElement = CoachingCreateEditForm;
    if (tab === "plans") TabElement = CoachingEditTabsPlans;
    if (tab === "events") TabElement = CoachingEditTabsEvents;
    if (tab === "training") TabElement = CoachingEditTabsTraining;
    if (tab === "log") TabElement = CoachingEditTabsLog;
    if (tab === "reports") TabElement = CoachingEditTabsReport;
    if (tab === "zones") TabElement = CoachingEditTabsZone;

    return useMemo(() => (

        <>
            <EditableContext.Provider value={true}>

                <Box gutter={tab === "info" ? "auto" : ""}>

                    {data && TabElement &&

                        <TabElement
                            form={form}
                            data={data}
                            handleSubmit={handleSubmit}
                            process={update}
                        />

                    }

                </Box>

            </EditableContext.Provider>

        </>

    ), [data, form.hash, update]);

}

export default CoachingSingleEdit;
