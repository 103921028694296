import React, { useMemo } from 'react';
import { MediaImage, Panel, Space } from '@dex/bubl-dash';
import styles from "./FieldResultsTeamCover.module.scss";
import { date, get, upperCase } from '@dex/bubl-helpers';

const FieldResultsAthleteCover: React.FC<any> = ( props: any ) => {

    const { report, form } = props;

    const logo = [
        { img: require( '../../../assets/images/Cover/VO2MASTER.png' ), alt: "VO2MASTER" },
        { img: require( '../../../assets/images/Cover/CORTEX.png' ), alt: "CORTEX" },
        { img: require( '../../../assets/images/Cover/MOXY.png' ), alt: "MOXY" },
        { img: require( '../../../assets/images/Cover/CSEP.png' ), alt: "CSEP" },
        { img: require( '../../../assets/images/Cover/IRONMAN.png' ), alt: "IRONMAN" }
    ];

    return useMemo( () => (

        <Panel className={[ styles.panel, "report-page", styles.cover ].join( " " )}>

            <Space height={160} />

            <div className={styles.innerCover}>

                <div>
                    <img
                        src={require( '../../../assets/images/Cover/VO2MAX.png' )}
                        alt="VO2MAX"
                        className={styles.vo2Logo}
                    />
                </div>

                <div className={styles.borderBlue}>

                    <div className={styles.borderGreen} >

                        <div className={styles.top}>

                            <p className={styles.title}> <span>Fitness Assessment </span> Athlete Report</p>

                            <div className={styles.blackText}>

                                <p>by</p>

                                {report.fitters.map( ( item: any, index: any ) => (
                                    <div
                                        className={styles.value}
                                        key={index}
                                    >
                                        {index > 0 &&
                                            <p>  &  </p>
                                        }
                                        <p> {item.name} </p>
                                    </div>
                                ) )}

                            </div>

                            <p className={styles.blackText}>{date( report.session.sessionDate, "D MMMM YYYY" )}</p>

                            <div className={styles.imageWrapper}>
                                {get( report.athlete, `photo.fileId` ) ?
                                    <MediaImage
                                        id={get( report.athlete, `photo.fileId` )}
                                        filters={{ height: 350, resizeMode: "contain", exact: true }}
                                        className={styles.image}
                                        imgProps={{
                                            crossOrigin: "anonymous"
                                        }}
                                    />
                                    :
                                    <img
                                        src={require( '../../../assets/images/Default Profile/Default-Athlete-Photo.png' )}
                                        alt="default photo"
                                        width={"100%"}
                                    />
                                }
                            </div>

                            <p className={styles.title}>{report.athlete.fullName}</p>

                            <p className={styles.athlete}>{report.team.name}</p>

                        </div>

                        <div className={styles.logoRow}>
                            {logo.map( ( item: any, index: any ) => (
                                <div
                                    className={styles.logoContainer}
                                    key={index}
                                >
                                    <img
                                        src={item.img}
                                        alt={item.alt}
                                        className={styles.logo}
                                    />
                                </div>
                            ) )}
                        </div>

                        <Space height={80} />

                    </div>

                </div>

            </div>

        </Panel>

    ), [ report ] );

}

export default FieldResultsAthleteCover;