import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import FieldSessionsApi from './FieldSessionsApi';

let settings;

export default settings = {
    key: 'field/field-sessions',
    zone: 'field',
    path: '/field/field-sessions',
    title: "Field Test Sessions",
    endpoint: FieldSessionsApi.endpoint,
    modelName: 'FieldSessions',
    idKey: 'id',
    primaryKey: 'id',
    actions: [ 'trash', 'clone' ],
    indexCaps: [ "viewFieldSessions" ],
    viewCaps: [ "viewFieldSessions" ],
    createCaps: [ "createFieldSessions" ],
    updateCaps: [ "updateFieldSessions" ],
    indexTitle: ( routeName, params, users ) => {

        let title: string = settings.title;

        if ( params && params.name ) title = title + " / " + titleCase( params.name );

        return title;

    },
    indexTabs: ( routeName, params, users ) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: ( routeName, params, users ) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: ( routeName, params, dontation ) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: FieldSessionsApi.get,
    indexFetchParams: ( routeName, params, users ) => {

        const filters: any = {
            where: {},
            include: [ 'team', 'fitters' ],
            order: [ 'sessionDate DESC' ],
            fields: [
                "id",
                "modelName", "teamId",
                "sessionDate", "period",
                "fitters", "fitterIds", "name",
                "created", "updated",
            ],
            limit: 10,
            skip: 0
        };

        if ( params && params.status ) filters.where.status = { inq: [ params.status ] };

        return filters;

    },
    indexQuickSearch: ( routeName, params, users ) => {

        const fields = [ 'id', 'teamName', 'period' ];

        return fields;

    },
    indexSearchFields: ( routeName, params, users ) => {

        const fields = [
            {
                label: "Team",
                key: "teamId",
                type: "relationship",
                model: "Teams",
            },
            {
                label: "Session Date",
                key: "sessionDate",
                type: "date",
            },
            {
                label: "Appraisers",
                key: "fitterIds",
                type: "relationship",
                model: "Fitters",
            },
        ];

        return fields;

    },

    indexColumns: ( routeName, params, users ) => {

        const columns = [
            {
                label: "ID",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Team",
                key: "team",
                format: "ModelLink",
            },
            {
                label: "Session Date",
                key: "sessionDate",
                format: 'date',
                sort: "date",
            },
            {
                label: "Appraisers",
                key: "fitters",
                format: "ModelLink",
            },
            {
                label: "Test Period",
                key: "period",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: FieldSessionsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: FieldSessionsApi.getOne,
    singleFetchParams: ( routeName, params, users, fetch ) => {

        const filters = {
            include: [
                'team',
                'fitters',
                'athletes',
                'templates',
                {
                    relation: 'fieldTests',
                    scope: {
                        fields: [ "id", "date", "timeOfDay", "details", "conditions", "fitters", "fitterIds", "tests" ]
                    }
                }
            ]
        };

        return filters;

    },

    reportFetch: FieldSessionsApi.getPublicReport,
    reportFetchParams: ( routeName, params, users, fetch ) => {

        const filters = {
            athleteId: params?.athleteId,
            view: params?.view,
        };

        return filters;

    },

    singleTitle: ( routeName, params, users, fetch, app ) => {

        let title: string = "Loading...";

        const complete = get( fetch, 'complete' );
        const error = get( fetch, 'error' );
        const data = get( fetch, 'data' );

        if ( error ) title = "Error";
        else if ( data ) title = [ data.team.name, data.period ].filter( Boolean ).join( " - " );
        else if ( complete && !data ) title = "Not Found";

        return title;

    },
    singleTabs: ( routeName, params, users, fetch ) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Test Selection",
                path: path + "/edit/selection",
                caps: settings.updateCaps
            },
            {
                label: "Sessions",
                path: path + "/edit/sessions",
                caps: settings.updateCaps
            },
            {
                label: "Report Formats",
                path: path + "/edit/format",
                caps: settings.updateCaps
            },
            {
                label: "Final Scores",
                path: path + "/edit/scores",
                caps: settings.updateCaps
            },
            {
                label: "Reports",
                path: path + "/edit/reports",
                caps: settings.updateCaps
            },
        ]

    },
};