import { Box, Col, Row } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';

const CoachingReportComments: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const week = data.week;

    return useMemo(() => (

        <Row edge gutter={"auto"}>

            <Col col={{ xs: 24 }}>
                <ReportDesc
                    label={`Previous Training Week\n\n`}
                    value={week.comments?.pastWeek}
                />
            </Col>

            <Col col={{ xs: 24 }}>
                <ReportDesc
                    label={`Upcoming Training Week\n\n`}
                    value={week.comments?.upcomingWeek}
                />
            </Col>

        </Row>

    ), [data]);

}


export default CoachingReportComments;