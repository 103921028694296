import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, ButtonRow, DataTableCell, FieldAutocomplete, FieldHidden, FieldNumber, FieldRadio, FieldSelect, FieldSwitch, FieldTable, FieldText, FieldTextArea, FieldTextTime, Form, Heading, InputTableCell, InputTableHead, InputTableHeadCell, InputTableHeadRow, Line, Link, Modal, Panel, Seperator, Space, WhiteSpace, useForm, useUpdateEffect } from '@dex/bubl-dash';
import { date, fixedNum, get, parseNum } from '@dex/bubl-helpers';
import { getClockTime, getDecimalTime } from '../../../functions/sharedTests';
import CoachingTrainingGenericForm from './CoachingTrainingGenericForm';
import { generateDateOptions, getWeek, totalDistance, totalDuration } from '../../../functions/sharedCoaching';
import { FaCalculator } from 'react-icons/fa';
import CoachingTrainingsIntervalsCalculator from './CoachingTrainingsIntervalsCalculator';
import styles from "./CoachingTrainingsIntervals.module.scss"

const CoachingTrainingsIntervalsForm: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit, mode, del, handleClose } = props;

    const [dateOptions, setDateOptions] = useState([]);

    const { week, block, weeks } = getWeek(form, data);

    useEffect(() => {

        form.handleChange({ name: "week.phase", value: block.phase, reset: true })
        form.handleChange({ name: "week.block", value: block.block, reset: true })
        form.handleChange({ name: "week.blockNum", value: block.blockNum, reset: true })
        form.handleChange({ name: "week.week", value: week.week, reset: true })
        form.handleChange({ name: "week.startDate", value: week.startDate, reset: true })
        form.handleChange({ name: "week.endDate", value: week.endDate, reset: true })
        form.handleChange({ name: "week.eventId", value: week.event, reset: true })

        setDateOptions(generateDateOptions(week.startDate, week.endDate));

    }, [week, block])

    const activity = form.values?.activity;

    return (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <CoachingTrainingGenericForm
                form={form}
                data={data}
                mode={mode}
                week={week}
                block={block}
                weeks={weeks}
            />

            <Seperator
                heading={"Week " + week.calendar + " Data"}
            />

            <Space />

            <Row edge gutter={8}>

                <Col col={{ xs: 4 }}>

                    <FieldSelect
                        form={form}
                        name="activity"
                        defaultValue={form.defaults?.activity}
                        options={[
                            // { label: "All", value: "all" },
                            { label: "Swim", value: "swim" },
                            { label: "Bike", value: "bike" },
                            { label: "Run", value: "run" },
                            // { label: "Gym", value: "gym" },
                            // { label: "Other", value: "other" },
                        ]}
                        label="Activity"
                        required
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 4 }}>

                    <FieldSelect
                        form={form}
                        name="mode"
                        defaultValue={form.defaults?.mode}
                        options={[
                            // { label: "All", value: "all" },
                            ...(activity == "bike" || activity == "run" ? [{ label: "Road", value: "road" }] : []),
                            ...(activity == "bike" || activity == "run" ? [{ label: "Off-Road", value: "off-road" }] : []),
                            ...(activity == "bike" || activity == "run" ? [{ label: "Trainer", value: "trainer" }] : []),
                            ...(activity == "run" ? [{ label: "Treadmill", value: "treadmill" }] : []),
                            ...(activity == "swim" ? [{ label: "Pool", value: "pool" }] : []),
                            ...(activity == "swim" ? [{ label: "Pool - 25m", value: "pool-25m" }] : []),
                            ...(activity == "swim" ? [{ label: "Pool - 50m", value: "pool-50m" }] : []),
                            ...(activity == "swim" ? [{ label: "Open Water - Fresh", value: "open water-fresh" }] : []),
                            ...(activity == "swim" ? [{ label: "Open Water - Salt", value: "open water-salt" }] : []),
                            { label: "Other", value: "other" }
                        ].filter(Boolean)}
                        label="Mode"
                        required
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 4 }}>

                    <FieldSelect
                        form={form}
                        name="type"
                        defaultValue={form.defaults?.type}
                        options={[
                            // { label: "All", value: "all" },
                            { label: "Training", value: "training" },
                            { label: "Race Simulation", value: "race-simulation" },
                            { label: "Event", value: "event" },
                            { label: "Test", value: "test" },
                            { label: "Other", value: "other" },
                        ]}
                        label="Type"
                        required
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 4 }}>

                    {dateOptions.length > 0 &&
                        <FieldSelect
                            form={form}
                            name="workoutDate"
                            defaultValue={date(form.defaults?.workoutDate, "YYYY-MM-DD")}
                            options={dateOptions}
                            label="Workout Date"
                            required
                            wrapClass={"manual-input"}
                        />
                    }

                </Col>

                <Col col={{ xs: 24 }} className={styles.table}>

                    {activity &&
                        <FieldTable
                            form={form}
                            name={'interval'}
                            defaultValue={form.defaults.interval}
                            defaultValues={{}}
                            head={(
                                <InputTableHead
                                >

                                    <InputTableHeadRow>

                                        <InputTableHeadCell
                                            label={"#"}
                                            desc={<br />}
                                        />

                                        <InputTableHeadCell
                                            label={"Actions"}
                                            desc={<br />}
                                        />

                                        <InputTableHeadCell
                                        />

                                        <InputTableHeadCell
                                            label={"Zone"}
                                            desc={<br />}
                                        />

                                        <InputTableHeadCell
                                            label={"Intervals"}
                                            desc={<br />}
                                        />

                                        <InputTableHeadCell
                                            label={"Set"}
                                            desc={<br />}
                                        />

                                        <InputTableHeadCell
                                            label={"Reps"}
                                            desc={<br />}
                                        />

                                        {activity == "swim" && <InputTableHeadCell
                                            label={"Distance"}
                                            desc={<br />}
                                        />}

                                        {(activity == "bike" || activity == "run") && <InputTableHeadCell
                                            label={"Duration"}
                                            desc={"Interval"}
                                        />}

                                        {(activity == "bike" || activity == "run") && <InputTableHeadCell
                                            label={"Distance"}
                                            desc={"Interval"}
                                        />}

                                        {(activity == "bike" || activity == "run") && <InputTableHeadCell
                                            label={"Duration"}
                                            desc={"Total"}
                                        />}

                                        <InputTableHeadCell
                                            label={"Distance"}
                                            desc={"Total"}
                                        />

                                        <InputTableHeadCell
                                            label={"Recovery"}
                                            desc={<br />}
                                        />

                                        {activity
                                            == "run" && <InputTableHeadCell
                                                label={"Pace"}
                                                desc={"Avg"}
                                            />}

                                        {activity
                                            == "run" && <InputTableHeadCell
                                                label={"Pace"}
                                                desc={"Best"}
                                            />}

                                        {activity
                                            == "run" && <InputTableHeadCell
                                                label={"Pace"}
                                                desc={"High"}
                                            />}

                                        {activity
                                            == "run" && <InputTableHeadCell
                                                label={"Pace"}
                                                desc={"Low"}
                                            />}

                                        {activity
                                            == "bike" && <InputTableHeadCell
                                                label={"Power"}
                                                desc={"Avg"}
                                            />}

                                        {activity
                                            == "bike" && <InputTableHeadCell
                                                label={"Power"}
                                                desc={"Best"}
                                            />}

                                        {/* {activity
                                    == "bike" && <InputTableHeadCell
                                        label={"Power High"}
                                    />}

                                {activity
                                    == "bike" && <InputTableHeadCell
                                        label={"Power Low"}
                                    />} */}

                                        {activity == "swim" && <InputTableHeadCell
                                            label={"Time"}
                                            desc={"Avg"}
                                        />}

                                        {activity == "swim" && <InputTableHeadCell
                                            label={"Time"}
                                            desc={"Best"}
                                        />}

                                        {activity == "swim" && <InputTableHeadCell
                                            label={"Pace"}
                                            desc={"Avg"}
                                        />}

                                        {activity == "swim" && <InputTableHeadCell
                                            label={"Pace"}
                                            desc={"Best"}
                                        />}

                                        <InputTableHeadCell
                                            label={"HR"}
                                            desc={"Avg"}
                                        />

                                        {(activity == "bike" || activity == "run") && <InputTableHeadCell
                                            label={"HR"}
                                            desc={"Peak"}

                                        />}

                                        {activity == "swim" && <InputTableHeadCell
                                            label={"HR"}
                                            desc={"Best"}
                                        />}

                                        {(activity == "bike" || activity == "run") && <InputTableHeadCell
                                            label={"Speed"}
                                            desc={"Avg"}
                                        />}

                                        {activity == "bike" && <InputTableHeadCell
                                            label={"Speed"}
                                            desc={"Peak"}
                                        />}

                                        {(activity == "bike") && <InputTableHeadCell
                                            label={"RPM"}
                                            desc={"Avg"}
                                        />}

                                        {activity == "bike" && <InputTableHeadCell
                                            label={"RPM"}
                                            desc={"Peak"}
                                        />}

                                        {activity == "run" && <InputTableHeadCell
                                            label={"Speed"}
                                            desc={"Best"}
                                        />}

                                        {activity == "swim" && <InputTableHeadCell
                                            label={"Aids"}
                                            desc={<br />}
                                        />}

                                        <InputTableHeadCell
                                            label={"Remarks"}
                                            desc={<br />}
                                        />

                                    </InputTableHeadRow>

                                </InputTableHead>
                            )}
                        >

                            {(form.values.interval || []).map((row, n) => (

                                < React.Fragment key={form.key + "-" + n}
                                >

                                    <FieldHidden
                                        form={form}
                                        name={"interval[" + n + "]"}
                                        defaultValue={row}
                                    />

                                    {activity == "swim" &&
                                        <SwimTableData
                                            form={form}
                                            data={data}
                                            prefix={`interval[${n}]`}
                                            row={row}
                                            index={n}
                                        />
                                    }

                                    {activity == "bike" &&
                                        <BikeTableData
                                            form={form}
                                            data={data}
                                            prefix={`interval[${n}]`}
                                            row={row}
                                            index={n}
                                        />
                                    }

                                    {activity == "run" &&
                                        <RunTableData
                                            form={form}
                                            data={data}
                                            prefix={`interval[${n}]`}
                                            row={row}
                                            index={n}
                                        />
                                    }

                                </React.Fragment>

                            ))}

                        </FieldTable>
                    }

                </Col>

            </Row>

            <Line />

            <Row edge gutter={8}>

                <Col col={{ xs: 21 }}>

                    <ButtonRow>

                        <Button
                            label={"Save"}
                            loading={process.loading}
                        />

                        <Button
                            label={"Close"}
                            onClick={() => handleClose()}
                            type='faded'
                        />

                    </ButtonRow>

                </Col>

                {mode == "edit" &&

                    <Col col={{ xs: 3 }} align='end'>

                        <Button
                            confirm={{
                                title: `Delete The Interval Data`,
                                message: "Are your sure you want to perform this action?"
                            }}
                            onClick={del.run}
                            label="Delete"
                            type='faded'
                        />
                    </Col>
                }

            </Row >
        </Form>

    )
}

const SwimTableForm: React.FC<any> = (props: any) => {

    const { form, intervalsForm, prefix, index } = props;

    const [openCalculator, setOpenCalculator] = useState("");

    useEffect(() => {

        const calculationPace = (time, distance) => {

            let output;

            if (!distance) return 0

            time = getDecimalTime(time);
            distance = parseNum(distance)

            output = time / (distance / 100)

            return getClockTime(output, "mm:ss")

        }

        const set = get(form.values, `set`);
        const reps = get(form.values, `reps`);
        const distance = get(form.values, `intervalDistance`);
        const timeAvg = get(form.values, `timeAvg`);
        const timeBest = get(form.values, `timeBest`);

        form.handleChange({ reset: true, name: `totalDistance`, value: totalDistance(set, reps, distance) })
        form.handleChange({ reset: true, name: `paceAvg`, value: calculationPace(timeAvg, distance) })
        form.handleChange({ reset: true, name: `paceBest`, value: calculationPace(timeBest, distance) })

    }, [form.hash, openCalculator]);

    return (
        <Row edge gutter={8}>

            <Col col={{ xs: 8 }}>
                <FieldAutocomplete
                    form={form}
                    name={`zone`}
                    defaultValue={get(form.values, `zone`)}
                    wrapClass={"manual-input"}
                    group='zoneList'
                    preload
                    options={[
                        { value: "Endurance", label: "Endurance" },
                        { value: "Tempo", label: "Tempo" },
                        { value: "Threshold", label: "Threshold" },
                        { value: "VO2max", label: "VO2max" },
                        { value: "Anaerobic", label: "Anaerobic" },
                        { value: "Mixed", label: "Mixed" },
                        { value: "Other", label: "Other" }
                    ]}
                    label="Zone"
                    sort={false}
                />
            </Col>

            <Col col={{ xs: 8 }}>
                <FieldAutocomplete
                    form={form}
                    name={`interval`}
                    defaultValue={get(form.values, `interval`)}
                    wrapClass={"manual-input"}
                    group='intervalList'
                    preload
                    options={[
                        { value: "Tempo-HR", label: "Tempo-HR" },
                        { value: "Repeats", label: "Repeats" },
                        { value: "Pyramids", label: "Pyramids" },
                        { value: "Ladders", label: "Ladders" },
                        { value: "Race Pace", label: "Race Pace" },
                        { value: "Triple 3's", label: "Triple 3's" },
                        { value: "Devil 6's", label: "Devil 6's" },
                        { value: "Breakouts on Avg kph", label: "Breakouts on Avg kph" },
                        { value: "1 min On/Offs", label: "1 min On/Offs" },
                        { value: "30s On/Offs", label: "30s On/Offs" },
                        { value: "40s:20s On/Offs", label: "40s:20s On/Offs" },
                        { value: "Tempo-Sprint", label: "Tempo-Sprint" },
                        { value: "Strength", label: "Strength" },
                        { value: "Hills", label: "Hills" },
                        { value: "Anaerobic Ladders", label: "Anaerobic Ladders" },
                        { value: "Sprints - Short <15s)", label: "Sprints - Short <15s)" },
                        { value: "Sprints - Long (15-30s)", label: "Sprints - Long (15-30s)" },
                        { value: "Recovery", label: "Recovery" },
                        { value: "Broken Tempo", label: "Broken Tempo" },
                    ]}
                    label="Intervals"
                    sort={false}
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`set`}
                    defaultValue={get(form.values, `set`)}
                    wrapClass={"manual-input"}
                    label="Set"
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`reps`}
                    defaultValue={get(form.values, `reps`)}
                    wrapClass={"manual-input"}
                    label="Reps"
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`intervalDistance`}
                    defaultValue={get(form.values, `intervalDistance`)}
                    wrapClass={"manual-input"}
                    suffix=" m"
                    label="Distance"
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`totalDistance`}
                    defaultValue={get(form.values, `totalDistance`)}
                    dynamicKey
                    suffix=" m"
                    disabled
                    wrapClass={""}
                    label="Distance"
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldTextTime
                    form={form}
                    name={`recovery`}
                    defaultValue={get(form.values, `recovery`)}
                    placeholder="mm:ss"
                    wrapClass={"manual-input"}
                    label="Recovery"
                />
            </Col>

            <Col col={{ xs: 12 }} />

            <Col col={{ xs: 4 }}>
                <FieldTextTime
                    form={form}
                    name={`timeAvg`}
                    defaultValue={get(form.values, `timeAvg`)}
                    dynamicKey
                    defer={1000}
                    wrapClass={"manual-input"}
                    placeholder="mm:ss"
                    label="Time Avg"
                />
            </Col>

            <Col col={{ xs: 4 }} style={{ minWidth: "120px", paddingRight: "10px" }}>

                <Row edge gutter={8}>

                    <Col col={{ xs: 20 }}>

                        <FieldTextTime
                            form={form}
                            name={`timeBest`}
                            defaultValue={get(form.values, `timeBest`)}
                            dynamicKey
                            defer={1000}
                            wrapClass={"manual-input"}
                            placeholder="mm:ss"
                            label="Time Best"
                        />

                    </Col>

                    <Col col={{ xs: 4 }} justify='center' align='start'>

                        <FaCalculator
                            onClick={setOpenCalculator.bind(null, "time")}
                            className={styles.iconCalculator}
                        />

                    </Col>

                </Row >

            </Col>

            <Col col={{ xs: 4 }}>
                <FieldTextTime
                    form={form}
                    name={`paceAvg`}
                    defaultValue={get(form.values, `paceAvg`)}
                    dynamicKey
                    disabled
                    wrapClass={""}
                    label="Pace Avg"
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldTextTime
                    form={form}
                    name={`paceBest`}
                    defaultValue={get(form.values, `paceBest`)}
                    dynamicKey
                    disabled
                    wrapClass={""}
                    label="Pace Best"
                />
            </Col>

            <Col col={{ xs: 8 }} />

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`hrAvg`}
                    defaultValue={get(form.values, `hrAvg`)}
                    dynamicKey
                    defer={1000}
                    wrapClass="manual-input"
                    label="HR Avg"
                />
            </Col>

            <Col col={{ xs: 4 }} style={{ minWidth: "120px", paddingRight: "10px" }}>

                <Row edge gutter={8}>

                    <Col col={{ xs: 20 }}>

                        <FieldNumber
                            form={form}
                            name={`hrBest`}
                            defaultValue={get(form.values, `hrBest`)}
                            dynamicKey
                            defer={1000}
                            wrapClass="manual-input"
                            label="HR Best"
                        />

                    </Col>

                    <Col col={{ xs: 4 }} justify='center' align='start'>

                        <FaCalculator
                            onClick={setOpenCalculator.bind(null, "hr")}
                            className={styles.iconCalculator}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldSwitch
                    form={form}
                    name={`aids`}
                    defaultValue={get(form.values, `aids`)}
                    label="Aids"
                />

            </Col>

            <Col col={{ xs: 12 }} />

            <Col col={{ xs: 8 }} style={{ minWidth: "200px" }}>
                <FieldText
                    form={form}
                    name={`remarks`}
                    defaultValue={get(form.values, `remarks`)}
                    wrapClass={"manual-input"}
                    label="Remarks"
                />
            </Col>

            {openCalculator !== "" &&

                <Modal
                    show={openCalculator !== ""}
                    onClose={setOpenCalculator.bind(null, "")}
                    width={1080}
                >
                    <CoachingTrainingsIntervalsCalculator
                        row={form.values}
                        rowForm={form}
                        activity={"swim"}
                        n={index}
                        type={openCalculator}
                        setOpenCalculator={setOpenCalculator}
                    />
                </Modal>
            }

        </Row>

    )
}

const SwimTableData: React.FC<any> = (props: any) => {

    const { prefix, row, index } = props;

    const form = useForm(row);

    const [showEdit, setShowEdit] = useState(false);

    const handleUpdate = useCallback((values) => {

        props.form.handleChange({ name: prefix, value: values, })

        setShowEdit(false)

    }, []);

    return (
        <>

            <DataTableCell>
                <Button
                    label={"Edit"}
                    onClick={setShowEdit.bind(null, true)}
                    size={"small"}
                    type={"light"}
                />
            </DataTableCell>

            {showEdit &&
                <Modal
                    show={showEdit}
                    onClose={setShowEdit.bind(null, false)}
                    width={1080}
                >
                    <Panel
                        heading={"Edit Interval " + (index + 1)}
                    >

                        <Form
                            form={form}
                            onSubmit={handleUpdate}
                        >

                            <SwimTableForm
                                form={form}
                                intervalsForm={props.form}
                                prefix={prefix}
                                index={index}
                            />

                            <Line />

                            <Button
                                label={"Apply Changes"}
                                onClick={handleUpdate.bind(null, form.values)}
                            />

                        </Form>

                    </Panel>
                </Modal>
            }

            <DataTableCell>
                {get(row, `zone`)}
            </DataTableCell>

            <DataTableCell>
                {get(row, `interval`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell}>
                {get(row, `set`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell}>
                {get(row, `reps`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell}>
                {get(row, `intervalDistance`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell}>
                {get(row, `totalDistance`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell}>
                {get(row, `recovery`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell}>
                {get(row, `timeAvg`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell} >
                {get(row, `timeBest`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell}>
                {get(row, `paceAvg`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell}>
                {get(row, `paceBest`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell}>
                {get(row, `hrAvg`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell} >
                {get(row, `hrBest`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `aids`) ? "Aids" : ""}
            </DataTableCell >

            <DataTableCell>
                {get(row, `remarks`)}
            </DataTableCell>
        </>

    )
}

const BikeTableForm: React.FC<any> = (props: any) => {

    const { form, intervalsForm, prefix, index } = props;

    useUpdateEffect(() => {

        const set = get(form.values, `set`);
        const reps = get(form.values, `reps`);
        const intervalDistance = get(form.values, `intervalDistance`);
        const intervalDuration = get(form.values, `intervalDuration`);

        form.handleChange({ reset: true, name: `totalDuration`, value: totalDuration(set, reps, intervalDuration) })
        form.handleChange({ reset: true, name: `totalDistance`, value: totalDistance(set, reps, intervalDistance) })

    }, [form.hash]);

    const [openCalculator, setOpenCalculator] = useState("");

    return (

        <Row edge gutter={8}>

            <Col col={{ xs: 8 }}>

                <FieldAutocomplete
                    form={form}
                    name={`zone`}
                    defaultValue={get(form.values, `zone`)}
                    wrapClass={"manual-input"}
                    group='zoneList'
                    preload
                    options={[
                        { value: "Endurance", label: "Endurance" },
                        { value: "Tempo", label: "Tempo" },
                        { value: "Threshold", label: "Threshold" },
                        { value: "VO2max", label: "VO2max" },
                        { value: "Anaerobic", label: "Anaerobic" },
                        { value: "Mixed", label: "Mixed" },
                        { value: "Other", label: "Other" }
                    ]}
                    label="Zone"
                    sort={false}
                />

            </Col>

            <Col col={{ xs: 8 }}>

                <FieldAutocomplete
                    form={form}
                    name={`interval`}
                    defaultValue={get(form.values, `interval`)}
                    wrapClass={"manual-input"}
                    group='intervalList'
                    preload
                    options={[
                        { value: "Tempo-HR", label: "Tempo-HR" },
                        { value: "Tempo", label: "Tempo" },
                        { value: "Pyramids", label: "Pyramids" },
                        { value: "Ladders", label: "Ladders" },
                        { value: "Race Pace", label: "Race Pace" },
                        { value: "Triple 3's", label: "Triple 3's" },
                        { value: "Devil 6's", label: "Devil 6's" },
                        { value: "Breakouts on Avg kph", label: "Breakouts on Avg kph" },
                        { value: "1 min On/Offs", label: "1 min On/Offs" },
                        { value: "30s On/Offs", label: "30s On/Offs" }
                    ]}
                    label="Intervals"
                    sort={false}
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldNumber
                    form={form}
                    name={`set`}
                    defaultValue={get(form.values, `set`)}
                    wrapClass={"manual-input"}
                    label="Set"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldNumber
                    form={form}
                    name={`reps`}
                    defaultValue={get(form.values, `reps`)}
                    wrapClass={"manual-input"}
                    label="Reps"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldTextTime
                    form={form}
                    name={`intervalDuration`}
                    defaultValue={get(form.values, `intervalDuration`)}
                    wrapClass={"manual-input"}
                    placeholder="mm:ss"
                    label="Interval Duration"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldNumber
                    form={form}
                    name={`intervalDistance`}
                    defaultValue={get(form.values, `intervalDistance`)}
                    suffix=" km"
                    wrapClass={"manual-input"}
                    label="Interval Distance"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldTextTime
                    form={form}
                    name={`totalDuration`}
                    defaultValue={get(form.values, `totalDuration`)}
                    key={get(form.values, `totalDuration`)}
                    disabled
                    wrapClass={""}
                    placeholder="mm:ss"
                    label="Total Duration"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldNumber
                    form={form}
                    name={`totalDistance`}
                    defaultValue={get(form.values, `totalDistance`)}
                    key={get(form.values, `totalDistance`)}
                    suffix=" km"
                    disabled
                    wrapClass={""}
                    label="Total Distance"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldTextTime
                    form={form}
                    name={`recovery`}
                    defaultValue={get(form.values, `recovery`)}
                    placeholder="mm:ss"
                    wrapClass={"manual-input"}
                    label="Recovery"
                />

            </Col>

            <Col col={{ xs: 4 }} />

            <Col col={{ xs: 4 }}>

                <FieldNumber
                    dynamicKey
                    form={form}
                    name={`powerAvg`}
                    defaultValue={get(form.values, `powerAvg`)}
                    wrapClass={"manual-input"}
                    defer={1000}
                    label="Power Avg"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <Row edge gutter={8}>

                    <Col col={{ xs: 20 }}>

                        <FieldNumber
                            form={form}
                            name={`powerBest`}
                            defaultValue={get(form.values, `powerBest`)}
                            dynamicKey
                            wrapClass={"manual-input"}
                            defer={1000}
                            label="Power Best"
                        />

                    </Col>

                    <Col col={{ xs: 4 }} justify='center' align='start'>
                        <FaCalculator
                            onClick={setOpenCalculator.bind(null, "power")}
                            className={styles.iconCalculator}
                        />
                    </Col>
                </Row>

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldNumber
                    form={form}
                    name={`hrAvg`}
                    defaultValue={get(form.values, `hrAvg`)}
                    key={get(form.values, `hrAvg`)}
                    wrapClass={"manual-input"}
                    defer={1000}
                    label="HR Avg"
                />

            </Col>

            {/* <InputTableCell>

                <FieldNumber
                    form={form}
                    name={`powerHigh`}
                    defaultValue={get(form.values, `powerHigh`)}
                     wrapClass={"manual-input"}
                />

            </InputTableCell>

            <InputTableCell>

                <FieldNumber
                    form={form}
                    name={`powerLow`}
                    defaultValue={get(form.values, `powerLow`)}
                     wrapClass={"manual-input"}
                />

            </InputTableCell> */}

            <Col col={{ xs: 4 }}>

                <Row edge gutter={8}>

                    <Col col={{ xs: 20 }}>

                        <FieldNumber
                            form={form}
                            name={`hrPeak`}
                            defaultValue={get(form.values, `hrPeak`)}
                            key={get(form.values, `hrPeak`)}
                            wrapClass={"manual-input"}
                            defer={1000}
                            label="HR Peak"
                        />

                    </Col>

                    <Col col={{ xs: 4 }} justify='center' align='start'>

                        <FaCalculator
                            onClick={setOpenCalculator.bind(null, "hr")}
                            className={styles.iconCalculator}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 8 }} />

            <Col col={{ xs: 4 }}>

                <FieldNumber
                    form={form}
                    name={`speedAvg`}
                    defaultValue={get(form.values, `speedAvg`)}
                    key={get(form.values, `speedAvg`)}
                    wrapClass={"manual-input"}
                    defer={1000}
                    label="Speed Avg"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <Row edge gutter={8}>

                    <Col col={{ xs: 20 }}>

                        <FieldNumber
                            form={form}
                            name={`speedBest`}
                            defaultValue={get(form.values, `speedBest`)}
                            key={get(form.values, `speedBest`)}
                            wrapClass={"manual-input"}
                            defer={1000}
                            label="Speed Peak"
                        />

                    </Col>

                    <Col col={{ xs: 4 }} justify='center' align='start'>

                        <FaCalculator
                            onClick={setOpenCalculator.bind(null, "speed")}
                            className={styles.iconCalculator}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldNumber
                    key={get(form.values, `rpmAvg`)}
                    form={form}
                    name={`rpmAvg`}
                    defaultValue={get(form.values, `rpmAvg`)}
                    wrapClass={"manual-input"}
                    defer={1000}
                    label="RPM Avg"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <Row edge gutter={8}>

                    <Col col={{ xs: 20 }}>

                        <FieldNumber
                            form={form}
                            name={`rpmPeak`}
                            defaultValue={get(form.values, `rpmPeak`)}
                            key={get(form.values, `rpmPeak`)}
                            dynamicKey
                            wrapClass={"manual-input"}
                            defer={1000}
                            label="RPM Peak"
                        />

                    </Col>

                    <Col col={{ xs: 4 }} justify='center' align='start'>
                        <FaCalculator
                            onClick={setOpenCalculator.bind(null, "rpm")}
                            className={styles.iconCalculator}
                        />
                    </Col>
                </Row>

            </Col>

            <Col col={{ xs: 8 }} />

            <Col col={{ xs: 8 }}>

                <FieldTextArea
                    form={form}
                    name={`remarks`}
                    defaultValue={get(form.values, `remarks`)}
                    wrapClass={"manual-input"}
                    rows={1}
                    label="Remarks"
                />

            </Col>

            {openCalculator !== "" &&

                <Modal
                    show={openCalculator !== ""}
                    onClose={setOpenCalculator.bind(null, "")}
                    width={1080}
                >
                    <CoachingTrainingsIntervalsCalculator
                        row={form.values}
                        rowForm={form}
                        activity={"bike"}
                        n={index}
                        type={openCalculator}
                        setOpenCalculator={setOpenCalculator}
                    />
                </Modal>
            }

        </Row>
    )
}

const BikeTableData: React.FC<any> = (props: any) => {

    const { prefix, row, index } = props;

    const form = useForm(row);

    const [showEdit, setShowEdit] = useState(false);

    const handleUpdate = useCallback((values) => {

        props.form.handleChange({ name: prefix, value: values, })

        setShowEdit(false)

    }, []);

    return (

        <>

            <DataTableCell>
                <Button
                    label={"Edit"}
                    onClick={setShowEdit.bind(null, true)}
                    size={"small"}
                    type={"light"}
                />
            </DataTableCell>

            {showEdit &&
                <Modal
                    show={showEdit}
                    onClose={setShowEdit.bind(null, false)}
                    width={1080}
                >
                    <Panel
                        heading={"Edit Interval " + (index + 1)}
                    >

                        <Form
                            form={form}
                            onSubmit={handleUpdate}
                        >

                            <BikeTableForm
                                form={form}
                                intervalsForm={props.form}
                                prefix={prefix}
                                index={index}
                            />

                            <Line />

                            <Button
                                label={"Apply Changes"}
                                onClick={handleUpdate.bind(null, form.values)}
                            />

                        </Form>

                    </Panel>
                </Modal>
            }

            <DataTableCell >
                {get(row, `zone`)}
            </DataTableCell>

            <DataTableCell>
                {get(row, `interval`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `set`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `reps`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `intervalDuration`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `intervalDistance`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `totalDuration`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `totalDistance`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `recovery`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `powerAvg`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `powerBest`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `hrAvg`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `hrPeak`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `speedAvg`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `speedBest`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `rpmAvg`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell} >
                {get(row, `rpmPeak`)}
            </DataTableCell>

            <DataTableCell>
                {get(row, `remarks`)}
            </DataTableCell>
        </>
    )
}

const RunTableForm: React.FC<any> = (props: any) => {

    const { form, intervalsForm, prefix, index } = props;

    useUpdateEffect(() => {

        const calculateSpeed = (pace) => {

            let output;

            if (!pace) return 0;

            pace = getDecimalTime(pace)

            output = (1 / pace) * 60

            return fixedNum(output, 1)

        };

        const set = get(form.values, `set`);
        const reps = get(form.values, `reps`);
        const intervalDistance = get(form.values, `intervalDistance`);
        const intervalDuration = get(form.values, `intervalDuration`);
        const paceAvg = get(form.values, `paceAvg`);
        const paceBest = get(form.values, `paceBest`);

        form.handleChange({ reset: true, name: `totalDuration`, value: totalDuration(set, reps, intervalDuration) })
        form.handleChange({ reset: true, name: `totalDistance`, value: totalDistance(set, reps, intervalDistance) })
        form.handleChange({ reset: true, name: `speedAvg`, value: calculateSpeed(paceAvg) })
        form.handleChange({ reset: true, name: `speedBest`, value: calculateSpeed(paceBest) })

    }, [form.hash]);

    const [openCalculator, setOpenCalculator] = useState("");

    return (

        <Row edge gutter={8}>

            <Col col={{ xs: 8 }}>

                <FieldAutocomplete
                    form={form}
                    name={`zone`}
                    defaultValue={get(form.values, `zone`)}
                    wrapClass={"manual-input"}
                    group='zoneList'
                    preload
                    options={[
                        { value: "Endurance", label: "Endurance" },
                        { value: "Tempo", label: "Tempo" },
                        { value: "Threshold", label: "Threshold" },
                        { value: "VO2max", label: "VO2max" },
                        { value: "Anaerobic", label: "Anaerobic" },
                        { value: "Mixed", label: "Mixed" },
                        { value: "Other", label: "Other" }
                    ]}
                    label="Zone"
                    sort={false}
                />
            </Col>
            <Col col={{ xs: 8 }}>

                <FieldAutocomplete
                    form={form}
                    name={`interval`}
                    defaultValue={get(form.values, `interval`)}
                    wrapClass={"manual-input"}
                    group='intervalList'
                    preload
                    options={[
                        { value: "Tempo-HR", label: "Tempo-HR" },
                        { value: "Tempo", label: "Tempo" },
                        { value: "Pyramids", label: "Pyramids" },
                        { value: "Ladders", label: "Ladders" },
                        { value: "Race Pace", label: "Race Pace" },
                        { value: "Triple 3's", label: "Triple 3's" },
                        { value: "Devil 6's", label: "Devil 6's" },
                        { value: "Breakouts on Avg kph", label: "Breakouts on Avg kph" },
                        { value: "1 min On/Offs", label: "1 min On/Offs" },
                        { value: "30s On/Offs", label: "30s On/Offs" }
                    ]}
                    label="Intervals"
                    sort={false}
                />

            </Col>

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`set`}
                    defaultValue={get(form.values, `set`)}
                    wrapClass={"manual-input"}
                    label="Set"
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`reps`}
                    defaultValue={get(form.values, `reps`)}
                    wrapClass={"manual-input"}
                    label="Reps"
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldTextTime
                    form={form}
                    name={`intervalDuration`}
                    defaultValue={get(form.values, `intervalDuration`)}
                    wrapClass={"manual-input"}
                    placeholder="mm:ss"
                    label="Interval Duration"
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`intervalDistance`}
                    defaultValue={get(form.values, `intervalDistance`)}
                    suffix=" km"
                    wrapClass={"manual-input"}
                    label="Interval Distance"
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldTextTime
                    form={form}
                    name={`totalDuration`}
                    defaultValue={get(form.values, `totalDuration`)}
                    key={get(form.values, `totalDuration`)}
                    disabled
                    wrapClass={""}
                    placeholder="mm:ss"
                    label="Total Duration"
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`totalDistance`}
                    defaultValue={get(form.values, `totalDistance`)}
                    key={get(form.values, `totalDistance`)}
                    suffix=" km"
                    disabled
                    wrapClass={""}
                    label="Total Distance"
                />
            </Col>

            <Col col={{ xs: 4 }}>

                <FieldTextTime
                    form={form}
                    name={`recovery`}
                    defaultValue={get(form.values, `recovery`)}
                    placeholder="mm:ss"
                    wrapClass={"manual-input"}
                    label="Recovery"
                />

            </Col>

            <Col col={{ xs: 4 }} />

            <Col col={{ xs: 4 }}>

                <FieldTextTime
                    form={form}
                    name={`paceAvg`}
                    defaultValue={get(form.values, `paceAvg`)}
                    key={get(form.values, `paceAvg`)}
                    dynamicKey
                    wrapClass={"manual-input"}
                    placeholder="mm:ss"
                    label="Pace Avg"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldTextTime
                    form={form}
                    name={`paceBest`}
                    defaultValue={get(form.values, `paceBest`)}
                    key={get(form.values, `paceBest`)}
                    dynamicKey
                    wrapClass={"manual-input"}
                    placeholder="mm:ss"
                    label="Pace Best"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldTextTime
                    form={form}
                    name={`paceHigh`}
                    defaultValue={get(form.values, `paceHigh`)}
                    wrapClass={"manual-input"}
                    placeholder="mm:ss"
                    label="Pace High"
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <Row edge gutter={8}>

                    <Col col={{ xs: 20 }}>

                        <FieldTextTime
                            form={form}
                            name={`paceLow`}
                            defaultValue={get(form.values, `paceLow`)}
                            wrapClass={"manual-input"}
                            placeholder="mm:ss"
                            label="Pace Low"
                        />

                    </Col>

                    <Col col={{ xs: 4 }} justify='center' align='start'>
                        <FaCalculator
                            onClick={setOpenCalculator.bind(null, "time")}
                            className={styles.iconCalculator}
                        />
                    </Col>
                </Row >
            </Col >

            <Col col={{ xs: 8 }} />

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`hrAvg`}
                    defaultValue={get(form.values, `hrAvg`)}
                    key={get(form.values, `hrAvg`)}
                    wrapClass={"manual-input"}
                    defer={1000}
                    label="HR Avg"
                />
            </Col>

            <Col col={{ xs: 4 }}>

                <Row edge gutter={8}>

                    <Col col={{ xs: 20 }}>

                        <FieldNumber
                            form={form}
                            name={`hrBest`}
                            defaultValue={get(form.values, `hrBest`)}
                            key={get(form.values, `hrBest`)}
                            wrapClass={"manual-input"}
                            defer={1000}
                            label="HR Peak"
                        />

                    </Col>

                    <Col col={{ xs: 4 }} justify='center' align='start'>
                        <FaCalculator
                            onClick={setOpenCalculator.bind(null, "hr")}
                            className={styles.iconCalculator}
                        />
                    </Col>
                </Row >
            </Col >

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`speedAvg`}
                    defaultValue={get(form.values, `speedAvg`)}
                    key={get(form.values, `speedAvg`)}
                    disabled
                    wrapClass={""}
                    label="Speed Avg"
                />
            </Col>

            <Col col={{ xs: 4 }}>
                <FieldNumber
                    form={form}
                    name={`speedBest`}
                    defaultValue={get(form.values, `speedBest`)}
                    key={get(form.values, `speedBest`)}
                    disabled
                    wrapClass={""}
                    label="Speed Best"
                />
            </Col>

            <Col col={{ xs: 8 }} />

            <Col col={{ xs: 8 }}>

                <FieldTextArea
                    form={form}
                    name={`remarks`}
                    defaultValue={get(form.values, `remarks`)}
                    wrapClass={"manual-input"}
                    rows={1}
                    label="Remarks"
                />
            </Col>

            {openCalculator !== "" &&

                <Modal
                    show={openCalculator !== ""}
                    onClose={setOpenCalculator.bind(null, "")}
                    width={1080}
                >
                    <CoachingTrainingsIntervalsCalculator
                        row={form.values}
                        rowForm={form}
                        activity={"run"}
                        n={index}
                        type={openCalculator}
                        setOpenCalculator={setOpenCalculator}
                    />
                </Modal>
            }

        </Row >

    )
}

const RunTableData: React.FC<any> = (props: any) => {

    const { prefix, row, index } = props;

    const form = useForm(row);

    const [showEdit, setShowEdit] = useState(false);

    const handleUpdate = useCallback((values) => {

        props.form.handleChange({ name: prefix, value: values, })

        setShowEdit(false)

    }, []);

    return (

        <>

            <DataTableCell>
                <Button
                    label={"Edit"}
                    onClick={setShowEdit.bind(null, true)}
                    size={"small"}
                    type={"light"}
                />
            </DataTableCell>

            {showEdit &&
                <Modal
                    show={showEdit}
                    onClose={setShowEdit.bind(null, false)}
                    width={1080}
                >
                    <Panel
                        heading={"Edit Interval " + (index + 1)}
                    >

                        <Form
                            form={form}
                            onSubmit={handleUpdate}
                        >

                            <RunTableForm
                                form={form}
                                intervalsForm={props.form}
                                prefix={prefix}
                                index={index}
                            />

                            <Line />

                            <Button
                                label={"Apply Changes"}
                                onClick={handleUpdate.bind(null, form.values)}
                            />

                        </Form>

                    </Panel>
                </Modal>
            }

            <DataTableCell>
                {get(row, `zone`)}
            </DataTableCell>

            <DataTableCell>
                {get(row, `interval`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `set`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `reps`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `intervalDuration`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `intervalDistance`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `totalDuration`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `totalDistance`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `recovery`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `paceAvg`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `paceBest`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `paceHigh`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `paceLow`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell}>
                {get(row, `hrAvg`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `hrBest`)}
            </DataTableCell >

            <DataTableCell className={styles.tableCell}>
                {get(row, `speedAvg`)}
            </DataTableCell>

            <DataTableCell className={styles.tableCell}>
                {get(row, `speedBest`)}
            </DataTableCell>

            <DataTableCell>
                {get(row, `remarks`)}
            </DataTableCell>

        </>

    )
}

export default CoachingTrainingsIntervalsForm
