import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const ExercisesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Exercises Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                key: 'name',
                                label: 'Name',
                            },
                            {
                                key: 'description',
                                label: 'Description',
                            },
                            {
                                key: 'instruction',
                                label: 'Instruction',
                            },
                        ]}
                        columnB={[
                            {
                                key: 'target',
                                label: 'Target',
                            },
                            {
                                key: 'photo',
                                label: 'Photo',
                                format: "image",
                                link: true,
                                filters:{
                                    height: 100
                                }
                            },
                        ]}
                        columnC={[

                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default ExercisesSingleView;
