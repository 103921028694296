import React, { useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import AthletesApi from '../AthletesApi';
import { Link, useForm } from '@dex/bubl-dash';
import AthletesSessionsSelectForm from './AthletesSessionsSelectForm';
import AtheletsSessionBikeFit from './AtheletsSessionBikeFit';
import AtheletsSessionTestSession from './AtheletsSessionTestSession';
import EditableContext from '../../../context/EditableContext';

const AthletesSingleSessionHistory: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ sessionId: "" });

    const session = AthletesApi.getSession(data.id);

    useEffect(() => {

        if (!form.values.sessionId) return;

        session.run({
            params: { sessionId: form.values.sessionId }
        });

    }, [form.values.sessionId]);

    const [tab, setTab] = useState("general");

    const tabs = useMemo(() => {

        let tabs: any = [];

        if (!session.data) return tabs;

        if (session.data.modelName === "TestSessions") {

            tabs = [
                { label: "General", name: "general" },
                { label: "Bio", name: "bio" },
                { label: "Health", name: "health" },
                { label: "Lab Tests", name: "tests" },
                { label: "Summary", name: "summary" },
            ];

        } else if (session.data.modelName === "Sessions") {

            const fitType = session.data.fitType;

            tabs = [
                {
                    label: "General",
                    name: "general"
                },
                {
                    label: "Bio Data",
                    name: "bio"
                },
                {
                    label: "Injury",
                    name: "injury"
                },
            ]

            if (fitType === 'Bike Sizing' || fitType === 'Remote Sizing') {
                tabs = [
                    ...tabs,
                    {
                        label: "Toe Touch",
                        name: "toeTouch"
                    },
                    {
                        label: "Bike Sizing",
                        name: "sizing"
                    },
                    {
                        label: "Summary",
                        name: "summary"
                    },
                ]
            } else if (fitType === 'Basic Bike Fit') {
                tabs = [
                    ...tabs,
                    {
                        label: "Mobility",
                        name: "mobility"
                    },
                    {
                        label: "Foot",
                        name: "foot"
                    },
                    {
                        label: "Cleat",
                        name: "cleat"
                    },
                    {
                        label: "Bike",
                        name: "bike"
                    },
                    {
                        label: "Exercises",
                        name: "exercises"
                    },
                    {
                        label: "Summary",
                        name: "summary"
                    },
                ]
            } else if (fitType) {
                tabs = [
                    ...tabs,
                    {
                        label: "Posture",
                        name: "posture"
                    },
                    {
                        label: "Mobility",
                        name: "mobility"
                    },
                    {
                        label: "Symmetry",
                        name: "symmetry"
                    },
                    {
                        label: "Stability",
                        name: "stability"
                    },
                    {
                        label: "Foot",
                        name: "foot"
                    },
                    {
                        label: "Cleat",
                        name: "cleat"
                    },
                    {
                        label: "Bike",
                        name: "bike"
                    },
                    {
                        label: "Exercises",
                        name: "exercises"
                    },
                    {
                        label: "Summary",
                        name: "summary"
                    },
                ]
            }

        }

        return tabs;

    }, [session.data])

    useEffect(() => {

        if (tabs && tabs[0]) {

            let hasTab = false;

            tabs.map(item => {
                if (item.name === tab) hasTab = true;
            });

            if (!hasTab) setTab(tabs[0].name);

        }

    }, [tabs]);

    return useMemo(() => (

        <>

            <Panel
                heading={"Session History"}
                actions={(
                    <>

                        <AthletesSessionsSelectForm
                            form={form}
                            data={data}
                        />

                    </>
                )}
                tabs={tabs}
                activeTab={tab}
                setTab={setTab}
                footer={
                    <>
                        {session.data && session.data.modelName === "TestSessions" &&
                            <Link
                                path={`/test-sessions/${session.data.id}/view`}
                            >
                                View Test Session
                            </Link>
                        }

                        {session.data && session.data.modelName === "Sessions" &&
                            <Link
                                path={`/sessions/${session.data.id}/view`}
                            >
                                View {session.data.fitType} Session
                            </Link>
                        }
                    </>
                }
            >

                <EditableContext.Provider value={false}>

                    {session.data &&
                        <>

                            {session.data.modelName === "TestSessions" &&
                                <AtheletsSessionTestSession
                                    athlete={data}
                                    session={session.data || {}}
                                    tab={tab}
                                />
                            }

                            {session.data.modelName === "Sessions" &&
                                <AtheletsSessionBikeFit
                                    athlete={data}
                                    session={session.data || {}}
                                    tab={tab}
                                />
                            }

                        </>
                    }

                </EditableContext.Provider>

            </Panel>

        </>

    ), [data, tab, tabs, session]);

}

export default AthletesSingleSessionHistory;
