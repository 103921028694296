import React from 'react';
import { ComposedChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop, titleCase } from '@dex/bubl-helpers';
import { nearestMaxHundered, nearestMaxTen } from '../../functions/sharedChart';

const ChartCoachingSelfRating: React.FC<ChartCoachingSelfRatingProps> = (props) => {

    let { filters, data } = props;

    const distribution = filters?.trackingDistribution || [];
    const metric = filters?.trackingWorkoutRating || [];

    const getKey = (key) => {

        if (!key) return null;

        const items = { "% Felt Strong": "feltStrongPercent", "% Felt Weak": "feltWeakPercent", "% No Rating": "feltMissingPercent", "Avg RPE": "avgRpe", "Avg Felt": "avgFelt" };

        return items[key];
    };

    const Axis = new ChartAxis();

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    Axis.x.label = "Week";
    Axis.x.format = undefined;
    Axis.x.key = "date";

    Axis.x.props = {
        interval: 0,
        angle: -45,
        textAnchor: "end",
    }

    loop(distribution, (_distribution, i) => {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 0,
            key: getKey(_distribution),
            axisLabel: i == 0 ? "% Of Felt" : "",
            label: _distribution,
            props: {
                yAxisId: "Felt",
                stackId: "a",
                domain: [0, 100],
                stroke: ChartProps.trackingColor(_distribution),
                label: { fill: ChartProps.indexColor(6) },
                fill: ChartProps.trackingColor(_distribution),
            },
        });
    })

    loop(metric, (_metric, i) => {

        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 0,
            key: getKey(_metric),
            axisLabel: i == 0 ? "Ratings" : "",
            label: _metric,
            props: {
                yAxisId: "Ratings",
                domain: [0, 10],
                stroke: ChartProps.trackingColor(_metric),
                label: { fill: ChartProps.indexColor(6) },
            },
        });
    })
    Axis.legend = false;

    return (

        <Chart>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

            </ComposedChart>

        </Chart>
    )

}

interface ChartCoachingSelfRatingProps {
    [key: string]: any,
}

export default ChartCoachingSelfRating;