import React from 'react';
import { ComposedChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { loop, titleCase } from '@dex/bubl-helpers';
import { fancyTimeFormat, nearestMaxHour, nearestMaxTen } from '../../functions/sharedChart';

const ChartCoachingWorkoutRunSummary: React.FC<ChartCoachingWorkoutRunSummaryProps> = (props) => {

    let { filters, data } = props;

    const volume = filters?.runSummaryVolume;
    const intensity = filters?.runSummaryIntensity || [];

    const getKey = (key: string) => {

        if (!key) return;

        const item = { "HR Hours > Zone 2": "hrZone3", "W Hours > Zone 2": "intensityDuration", "Training Stimulus": "stimulus" }

        return item[key];

    }

    const Axis = new ChartAxis();

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    Axis.x.label = "Week";
    Axis.x.format = undefined;
    Axis.x.key = "date";
    Axis.x.props = {
        interval: 0,
        angle: -45,
        textAnchor: "end",
    }

    if (volume == "Hours") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 0,
            key: "duration",
            axisLabel: volume,
            label: volume,
            props: {
                yAxisId: volume,
                domain: [0, nearestMaxHour],
                tickFormatter: fancyTimeFormat
            }
        });

    } else if (volume == "KM") {
        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 1,
            key: "distance",
            axisLabel: volume,
            label: volume,
            props: {
                yAxisId: volume,
                domain: [0, nearestMaxTen],
            }
        });

    } else if (volume == "# Of Runs") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 2,
            key: "workout",
            axisLabel: volume,
            label: volume,
            props: {
                yAxisId: volume,
                domain: [0, nearestMaxTen],
            }
        });
    }

    loop(intensity, (_intensity, i) => {
        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 0,
            key: getKey(_intensity),
            axisLabel: _intensity,
            label: _intensity,
            props: {
                yAxisId: _intensity,
                domain: _intensity == "Training Stimulus" ? [0, nearestMaxTen] : [0, 360],
                tickFormatter: _intensity == "Training Stimulus" ? undefined : fancyTimeFormat,
                stroke: ChartProps.metricColor(_intensity),
                label: { fill: ChartProps.metricColor(_intensity) }
            },
        });
    })

    Axis.legend = false;

    return (

        <Chart>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

            </ComposedChart>

        </Chart>

    )

}

interface ChartCoachingWorkoutRunSummaryProps {
    [key: string]: any,
}

export default ChartCoachingWorkoutRunSummary;