import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { DataTableCell, FieldNumber, FieldSelect, InputTable, InputTableCell, InputTableRow, useMountEffect, useUpdateEffect } from '@dex/bubl-dash';
import { get, dateDay, loop, date, hashCode } from '@dex/bubl-helpers';
import LabTestsApi from '../LabTestsApi';
import LabTestsRaceReadyChart from './LabTestsRaceReadyChart';
import RaceReadyResultTable from '../../../elements/RaceReadyResultTable/RaceReadyResultTable';
import RaceReadyRecommendation from '../../../elements/RaceReadyRecommendation/RaceReadyRecommendation';
import EventTargetsApi from '../../EventTargets/EventTargetsApi';

const LabTestsRaceReadyResult: React.FC<any> = (props: any) => {

    const { form, data, session } = props;

    const activity = data.activity;
    const chartUse = form.values?.result?.chartUse || "Power";

    const [options, setOptions] = useState<any>([]);

    const result = get(form.values, 'result');

    const recentLabTests = LabTestsApi.get();

    const [recentTest, setRecentTest] = useState([]);

    const [key, setKey] = useState(0);

    useMountEffect(() => {

        recentLabTests.run({
            params: {
                where: {
                    date: { lte: dateDay(session.date) },
                    athleteId: session.athleteId
                },
                limit: 1000,
                order: ["date DESC"]
            },
            onComplete: (response) => {

                const options: any = [];

                loop(response.data, (item: any) => {

                    if (!"VO2max".includes(item.type) && !"Performance".includes(item.type)) return;
                    // if (!data.activity.includes(item.activity)) return;

                    options.push({
                        label: [date(item.date), item.type, item.activity, item.intensity].filter(Boolean).join(", "),
                        value: item.id,
                    });

                });

                setOptions(options);

            },
        });

    });

    useUpdateEffect(() => {

        let labTest: any = null;

        loop(recentLabTests?.data?.data, (item: any) => {

            if (item.id !== result?.labTestId) return;

            labTest = item;

            const lactate = labTest?.lactate;

            if (!lactate) return;

            form.handleChange({ silent: true, reset: true, name: `result.ftpHr`, value: lactate.measures[0]?.ftp_hr });
            form.handleChange({ silent: true, reset: true, name: `result.ftpVo2`, value: lactate.measures[0]?.ftp_vo2 });
            form.handleChange({ silent: true, reset: true, name: `result.ftp`, value: lactate.measures[0]?.ftp_speed });
            form.handleChange({ silent: true, reset: true, name: `result.vo2maxHr`, value: lactate.measures[1]?.vo2max_hr });
            form.handleChange({ silent: true, reset: true, name: `result.vo2maxVo2`, value: lactate.measures[1]?.vo2max_vo2 });
            form.handleChange({ silent: true, reset: true, name: `result.vo2max`, value: lactate.measures[1]?.vo2max_speed });

            setKey((key) => key + 1);

        });

        setRecentTest(labTest);

    }, [result?.labTestId]);

    const targets = EventTargetsApi.getTargetsByEvent();

    useEffect(() => {

        if (!form.values.manual?.type) return;

        targets.run({
            params: { eventType: form.values.manual?.type, activity: activity },
        });

    }, [form.values.manual?.type, activity]);

    return useMemo(() => (

        <>

            {form.values.avgData && form.values.avgData.length > 0 &&
                <LabTestsRaceReadyChart
                    form={form}
                    data={data}
                    session={session}
                />
            }

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldSelect
                        key={recentLabTests.loading ? "loading" : hashCode(options)}
                        form={form}
                        label='Recent Lab Test'
                        name={`result.labTestId`}
                        defaultValue={get(form.values, "result.labTestId")}
                        options={options}
                    />

                </Col>

                <Col col={{ xs: 24, md: 16 }}>

                    <InputTable
                        columns={[
                            {},
                            { label: "FTP" },
                            { label: "MAX" },
                        ]}
                        className={"mini-table"}
                    >
                        <InputTableRow>

                            <DataTableCell style={{ width: "25%" }}>
                                HR
                            </DataTableCell>

                            <InputTableCell>
                                <FieldNumber
                                    form={form}
                                    name='result.ftpHr'
                                    defaultValue={get(form.values, "result.ftpHr")}
                                    suffix=" bpm"
                                    key={key}
                                />
                            </InputTableCell>

                            <InputTableCell>
                                <FieldNumber
                                    form={form}
                                    name='result.vo2maxHr'
                                    defaultValue={get(form.values, "result.vo2maxHr")}
                                    suffix=" bpm"
                                    key={key}
                                />
                            </InputTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                VO2
                            </DataTableCell>

                            <InputTableCell>
                                <FieldNumber
                                    form={form}
                                    name='result.ftpVo2'
                                    defaultValue={get(form.values, "result.ftpVo2")}
                                    suffix=" ml/kg*min"
                                    key={key}
                                />
                            </InputTableCell>

                            <InputTableCell>
                                <FieldNumber
                                    form={form}
                                    name='result.vo2maxVo2'
                                    defaultValue={get(form.values, "result.vo2maxVo2")}
                                    suffix=" ml/kg*min"
                                    key={key}
                                />
                            </InputTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell>
                                {activity == "Cycling" ? "Power" : "Speed"}
                            </DataTableCell>

                            <InputTableCell>
                                <FieldNumber
                                    form={form}
                                    name='result.ftp'
                                    defaultValue={get(form.values, "result.ftp")}
                                    suffix={activity == "Cycling" ? " W" : " kph"}
                                    key={key}
                                />
                            </InputTableCell>

                            <InputTableCell>
                                <FieldNumber
                                    form={form}
                                    name='result.vo2max'
                                    defaultValue={get(form.values, "result.vo2max")}
                                    suffix={activity == "Cycling" ? "  W" : " kph"}
                                    key={key}
                                />
                            </InputTableCell>

                        </InputTableRow>

                    </InputTable>

                </Col>

            </Row>

            <RaceReadyResultTable
                form={form}
                data={data}
                activity={activity}
                session={session}
                targets={targets}
            />

            <RaceReadyRecommendation
                form={form}
                data={data}
                activity={activity}
                session={session}
                targets={targets}
            />

        </>

    ), [form.hash, targets.loading, chartUse, recentLabTests.loading, recentTest, result]);

}


export default LabTestsRaceReadyResult;
