import { Button, ButtonRow, useApp } from '@dex/bubl-dash';
import React, { useCallback } from 'react';

import "./ShareLink.module.scss";

const ShareLink: React.FC<ShareLinkProps> = (props) => {

    let { label, path } = props;

    const app = useApp();

    const copyLink = useCallback((link) => {

        navigator.clipboard.writeText(link);

        app.alert('Link Copied To Clipboard', 'info');

    }, [path]);

    return (

        <div styleName='card'>

            <div styleName="label">{label}</div>

            <div className="links">
                <ButtonRow>
                    <Button
                        label={"View Report"}
                        path={`${path}`}
                        target={"_blank"}
                        size={"small"}
                        type={"primary"}
                    />

                    <Button
                        label={"Copy Link"}
                        onClick={copyLink.bind(null, `${path}`)}
                        size={"small"}
                        type={"light"}
                    />
                </ButtonRow>
            </div>

        </div>

    )

}

interface ShareLinkProps {
    [key: string]: any,
}

export default ShareLink;