import React from 'react';

import styles from "./FieldRangeLabels.module.scss";

const FieldRangeLabels: React.FC<FieldRangeLabelsProps> = (props) => {

    let { label } = props;

    return (

        <div className={'form-field-label ' + styles.labels}>

            {label && label.map((str: string, index: number) => (
                <span key={index} styleName='label'>{str}</span>
            ))}

        </div>

    )

}

interface FieldRangeLabelsProps {
    [key: string]: any,
}

export default FieldRangeLabels;