import React, { useMemo } from 'react';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import InputTable, { InputTableCell, InputTableRow } from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import Heading from '@dex/bubl-dash/src/lib/components/Heading/Heading';

const SessionsEditTabsBikeFinal: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;

    const columns = [
        {
            key: "prop",
            label: "Bike Parts",
        },
        {
            key: "values",
            label: "Values",
        }
    ]

    return useMemo(() => (

        <InputTable columns={columns}>

            {/* bike */}
            <>
                <InputTableRow>

                    <InputTableCell colSpan={3}>
                        <Heading space={null}>Bike</Heading>
                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell style={{ width: 300 }}>
                        Frame Stack:
                    </InputTableCell>

                    <InputTableCell style={{ width: 300 }}>
                        <FieldNumber
                            form={form}
                            name={`${prefix}.frameStack`}
                            defaultValue={bike.frameStack}
                            suffix={" mm"}
                            allowNegative={true}
                        />

                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell style={{ width: 300 }}>
                        Frame Reach:
                    </InputTableCell>

                    <InputTableCell style={{ width: 300 }}>
                        <FieldNumber
                            form={form}
                            name={`${prefix}.frameReach`}
                            defaultValue={bike.frameReach}
                            suffix={" mm"}
                            allowNegative={true}
                        />

                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell style={{ width: 300 }}>
                        Seat Tube Angle (Eff.):
                    </InputTableCell>

                    <InputTableCell style={{ width: 300 }}>
                        <FieldNumber
                            form={form}
                            name={`${prefix}.effSeatTubeAngle`}
                            defaultValue={bike.effSeatTubeAngle}
                            suffix={" °"}
                            allowNegative={true}
                        />

                    </InputTableCell>

                </InputTableRow>
            </>

            {/* saddle */}
            <>
                <InputTableRow>

                    <InputTableCell colSpan={3}>
                        <Heading space={null}>Saddle</Heading>
                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell style={{ width: 600 }}>
                        Saddle Height:
                    </InputTableCell>

                    <InputTableCell style={{ width: 300 }}>
                        <FieldNumber
                            form={form}
                            name={`${prefix}.saddleHeight`}
                            defaultValue={bike.saddleHeight}
                            suffix={" mm"}
                            allowNegative={true}
                        />
                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell style={{ width: 600 }}>
                        Saddle Setback:
                    </InputTableCell>

                    <InputTableCell style={{ width: 300 }}>

                        <FieldNumber
                            form={form}
                            name={`${prefix}.saddleSetback`}
                            defaultValue={bike.saddleSetback}
                            suffix={" mm"}
                            allowNegative={true}
                        />

                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell style={{ width: 600 }}>
                        Saddle Tilt:
                    </InputTableCell>

                    <InputTableCell style={{ width: 300 }}>

                        <FieldNumber
                            form={form}
                            name={`${prefix}.saddleAngle`}
                            defaultValue={bike.saddleAngle}
                            suffix={" °"}
                            allowNegative={true}
                        />

                    </InputTableCell>

                </InputTableRow>
            </>

            {(bike.handlebarType === 'Dropbars' || bike.handlebarType?.indexOf('MTB') === 0) &&

                <>

                    <InputTableRow>

                        <InputTableCell colSpan={3}>
                            <Heading space={null}>Handlebars</Heading>
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Handlebar Reach:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.handlebarsGripReach`}
                                defaultValue={bike.handlebarsGripReach}
                                suffix={" mm"}
                                allowNegative={true}
                            />

                        </InputTableCell>

                    </InputTableRow>


                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Handlebar Drop:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.handlebarsDrop`}
                                defaultValue={bike.handlebarsDrop}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Grip Width:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.handlebarsGripWidth`}
                                defaultValue={bike.handlebarsGripWidth}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Grip Drop:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.handlebarsGripDrop`}
                                defaultValue={bike.handlebarsGripDrop}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                </>
            }

            {bike.handlebarType === 'Aerobars' &&

                <>
                    <InputTableRow>

                        <InputTableCell colSpan={3}>
                            <Heading space={null}>Aerobars</Heading>
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Arm Pad Reach:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.armPadReach`}
                                defaultValue={bike.armPadReach}
                                suffix={" mm"}
                                allowNegative={true}
                            />

                        </InputTableCell>

                    </InputTableRow>


                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Arm Pad Drop:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.armPadDrop`}
                                defaultValue={bike.armPadDrop}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Arm Pad Width:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.armPadWidth`}
                                defaultValue={bike.armPadWidth}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Arm Pad Stack BB:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.armPadStackBb`}
                                defaultValue={bike.armPadStackBb}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Arm Pad Reach BB:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.armPadReachBb`}
                                defaultValue={bike.armPadReachBb}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Extension Length:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.extensionLength`}
                                defaultValue={bike.extensionLength}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Grip Drop:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.gripDrop`}
                                defaultValue={bike.gripDrop}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 600 }}>
                            Grip Width:
                        </InputTableCell>

                        <InputTableCell style={{ width: 300 }}>
                            <FieldNumber
                                form={form}
                                name={`${prefix}.gripWidth`}
                                defaultValue={bike.gripWidth}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                </>
            }

        </InputTable>

    ), [props]);

}

export default SessionsEditTabsBikeFinal;
