import { Button, Col, DataTable, FieldDate, FieldHidden, FieldRepeater, FieldSwitch, FieldTextArea, Row, Space } from '@dex/bubl-dash';
import { get, set } from '@dex/bubl-helpers';
import React, { useCallback, useEffect, useMemo } from 'react';

const LabTestsSweatComment: React.FC<LabTestsSweatCommentProps> = (props) => {

    const { form, data, session } = props;

    const useDefaultText = useCallback((key: string) => {

        let text = `Your fluid (sweat) loss rate and sodium loss measures are given in this report along with recommendations for fluid and sodium replenishment across different intensity and temperature scenarios. As the report shows, your sweat rate is ${form.values.rehydration?.fluidLossRate} and your sodium loss is ${form.values.rehydration?.sodiumLossRate}.

Just follow the re-fueling guidelines suggested in this report to help reduce the risk of dehydration, cramping and/or a drop in your performance. Avoid severe dehydration as this can have adverse effects on your health as well during an activity.`;

        const values = form.values;

        set(values, key, text);

        form.reset(values);

    }, [props, form.hash]);

    const handleArchive = (values) => {

        const summary = get(form.values, "comment.summary");
        const archive = get(form.defaults, "comment.archive", [])

        archive.unshift({
            summary: summary,
            date: new Date().toJSON(),
            show: true,
        });

        form.handleChange({ name: "comment.archive", value: archive });

        form.handleChange({ name: "comment.summary", value: "", default: true, silent: true });

        form.setKey();

    };

    return useMemo(() => (

        <Row edge gutter={8}>

            <Col col={{ xs: 20 }}>

                <FieldTextArea
                    key={form.key}
                    form={form}
                    label={"Session Summary"}
                    name={'comment.summary'}
                    required={false}
                    defaultValue={form.defaults?.comment?.summary}
                    rows={8}
                    maxLength={5000}
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <Space />

                <Button
                    label={'Use Default Text'}
                    onClick={useDefaultText.bind(null, "comment.summary")}
                    type={'faded'}
                    confirm={
                        form.values?.comment?.summary ?
                            { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' }
                            :
                            undefined
                    }
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <Button
                    label={"Archive Session Summary"}
                    type={"light"}
                    size={"small"}
                    onClick={handleArchive.bind(null, form.values)}
                />

            </Col>

            <Col col={{ xs: 24, md: 24 }}>

                <FieldRepeater
                    form={form}
                    name={`comment.archive`}
                    addButtonLabel={"Add Archived Summary"}
                >

                    {(form.values.comment?.archive || []).map((row, n) => (

                        <Row gutter={"auto"} edge>

                            <Col col={{ xs: 20 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Session Summary"}
                                    name={`comment.archive[${n}.summary]`}
                                    required={false}
                                    defaultValue={row?.summary}
                                    rows={8}
                                    maxLength={5000}
                                />

                            </Col>

                            <Col col={{ xs: 4 }}>

                                <FieldDate
                                    form={form}
                                    label={"Date"}
                                    name={`comment.archive[${n}.date]`}
                                    defaultValue={row?.date}
                                />

                                <FieldSwitch
                                    form={form}
                                    label={"Include In Report"}
                                    name={`comment.archive[${n}.show]`}
                                    defaultValue={row?.show}
                                />

                            </Col>

                        </Row>

                    ))}

                </FieldRepeater>

            </Col>

        </Row>

    ), [form.hash])
}

interface LabTestsSweatCommentProps {
    [key: string]: any,
}

export default LabTestsSweatComment;