import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import FieldRatingScalesApi from './FieldRatingScalesApi';
import FieldRatingScaleCreateNew from './Components/FieldRatingScaleCreateNew';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New Field Rating Scale",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: FieldRatingScaleCreateNew,
                getCreate: FieldRatingScalesApi.create,
                getClone: FieldRatingScalesApi.getOne,
                defaults: {
                    steps: [
                        { label: "Poor" },
                        { label: "Very Low" },
                        { label: "Low" },
                        { label: "Average" },
                        { label: "Good" },
                        { label: "Very Good" },
                        { label: "Excellent" },
                    ],
                    bestScore: "highest"
                },

                successMessage: "Rating Scale Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey,
                rowProps: { gutter: "auto", },
                colProps: { className: "", col: { xs: 24 } }
            }
        },
    });

}