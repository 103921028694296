import React, { useContext, useMemo } from 'react';
import { fixedNum, formatNum, get, loop, parseNum } from '@dex/bubl-helpers';
import { getRandomInt } from '../../functions/sharedGeneral';
import { FiEdit2 } from 'react-icons/fi';

import styles from "./RatingScaleCard.module.scss";
import { Link, useApp } from '@dex/bubl-dash';
import ReportContext from '../../context/ReportContext';
import BigNumber from "bignumber.js";

export const DisplayAs: React.FC<any> = (props: any) => {

    const { data, left, labels, steps, rangeSteps, split, min, max, demographic, gender, render, hideRange, classes } = props;

    return (

        <div className={[styles[data.displayAs], data.bestScore === "lowest" ? styles.bestLowest : "", data.bestScore === "highest" ? styles.bestHighest : ""].join(" ")}>

            {data.displayAs === "scale" &&
                <Scale
                    left={left}
                    labels={labels}
                    steps={steps}
                    data={data}
                    split={split}
                    min={min}
                    max={max}
                    demographic={demographic}
                    gender={gender}
                    rangeSteps={rangeSteps}
                    render={render}
                    hideRange={hideRange}
                    classes={classes}
                />
            }

            {data.displayAs === "steps" &&
                <Steps
                    left={left}
                    labels={labels}
                    steps={steps}
                    data={data}
                    split={split}
                    min={min}
                    max={max}
                    demographic={demographic}
                    gender={gender}
                    render={render}
                    classes={classes}
                />
            }

            {data.displayAs === "levels" &&
                <Levels
                    left={left}
                    labels={labels}
                    steps={steps}
                    data={data}
                    split={split}
                    min={min}
                    max={max}
                    demographic={demographic}
                    gender={gender}
                    rangeSteps={rangeSteps}
                    render={render}
                    classes={classes}
                />
            }

        </div>

    )

};


export const Scale: React.FC<any> = (props) => {

    const { left, labels, steps, rangeSteps, data, split, value, min, max, demographic, gender, render, hideRange, classes } = props;

    return (

        <div className={styles.bottom}>

            {!hideRange &&
                <RangeLabels
                    min={min}
                    max={max}
                    steps={rangeSteps}
                />
            }

            <ColorBar
                classes={classes}
                // labels={labels}
                steps={steps}
                min={min}
                max={max}
            />

            {left !== null &&
                <Arrow
                    left={left}
                    alt={false}
                />
            }

            {render && render()}

            {data.split &&
                <>
                    <SplitRange
                        data={data.split}
                        value={split?.value}
                        demographic={demographic}
                        gender={gender}
                        hideRange={hideRange}
                    />
                </>
            }

        </div>

    )

}

export const Steps: React.FC<any> = (props) => {

    const { left, labels, steps, data, min, max, value, demographic, gender, index, render, split, classes } = props;

    return (

        <div className={styles.bottom}>

            <RangeLabels
                steps={steps}
                min={min}
                max={max}
            // index={index}
            />

            <ColorBar
                classes={classes}
                // labels={labels}
                steps={steps}
                min={min}
                max={max}
            />

            <Arrow
                left={left}
                alt={false}
            />

            {render && render()}

            {data.split &&
                <>
                    <SplitRange
                        data={data.split}
                        value={split?.value}
                        demographic={demographic}
                        gender={gender}
                    />
                </>
            }
        </div>

    )

}

export const Levels: React.FC<any> = (props) => {

    const { left, labels, steps, data, min, max, value, demographic, gender, index, render, split, classes } = props;

    return (

        <div className={styles.bottom}>

            <RangeLabels
                steps={steps}
                min={min}
                max={max}
            />

            <ColorBar
                classes={classes}
                steps={steps}
                min={min}
                max={max}
            />

            <Arrow
                left={left}
                alt={false}
            />

            {render && render()}

            {data.split &&
                <>
                    <SplitRange
                        data={data.split}
                        value={split?.value}
                        demographic={demographic}
                        gender={gender}
                    />
                </>
            }

        </div>

    )

}

export const RangeLabels: React.FC<any> = ({ min, max, steps }) => {

    const position = (num) => {

        let pos = 0;

        pos = (num - min) / (max - min) * 100;

        return pos;

    }

    const diff = max - min;

    const niceNumber = (num: number) => {

        const fixed = fixedNum(num, 1);

        const [interger, decimal] = (fixed + "").split(".");

        if (decimal && decimal == ".5") {

            return fixed;

        } else if (decimal) {

            if (diff <= 10) return fixed;

            return Math.round(fixed);

        }

        return num;

    }

    return useMemo(() => {

        if (!steps) {

            steps = [];

            let step = fixedNum((max - min) / 4, 1);

            let num = new BigNumber(max).minus(min).toNumber();

            steps.push({ num: niceNumber(new BigNumber(num).multipliedBy(.2).plus(min).toNumber()) });
            steps.push({ num: niceNumber(new BigNumber(num).multipliedBy(.4).plus(min).toNumber()) });
            steps.push({ num: niceNumber(new BigNumber(num).multipliedBy(.6).plus(min).toNumber()) });
            steps.push({ num: niceNumber(new BigNumber(num).multipliedBy(.8).plus(min).toNumber()) });
            steps.push({ num: max });


        }

        return (

            <div className={styles.rangesBar + " rangesBar"}>

                {min !== undefined && min !== steps[0].num &&
                    <span style={{ left: position(min) + "%" }}>
                        <i>{formatNum(min, "auto", undefined, steps[0].suffix)}</i>
                    </span>
                }
                {steps?.map((step, stepIndex) => {
                    return (
                        <span key={stepIndex} style={{ left: position(step.num) + "%" }}>
                            <i>{formatNum(step.num, "auto", undefined, steps[0].suffix).toString()}</i>
                        </span>
                    )
                })}

            </div>
        )

    }, [min, max, steps]);

};

export const ColorBar: React.FC<any> = ({ min, max, steps, classes }) => {

    const width = (step) => {

        let w = 0;

        w = (step.max - step.min) / (max - min) * 100;

        return w;

    }

    return (

        <div className={[styles.colorBar + " colorBar" + "colorBar-n-" + steps.length, styles[classes]].join(" ")}>

            {steps?.map((step, index) => {
                return (
                    <span className={step.hidden ? styles.hidden : ""} key={index} style={{ flexBasis: width(step) + "%" }}>
                        <i>{step.chartLabel ? step.chartLabel : step.label}</i>
                    </span>
                )
            })}

        </div>

    )

};

export const Arrow: React.FC<any> = ({ left, alt }) => {

    const classNames = ["arrow", styles.arrow];

    if (alt === "dashed") classNames.push(styles.arrowDashed);
    else if (alt) classNames.push(styles.arrowAlt);

    return (

        <div className={classNames.join(" ")} style={{ left: left + '%' }}>

        </div>

    )

};

export const SplitRange: React.FC<any> = ({ data, value, demographic, gender, hideRange }) => {

    const { steps, min, max }: any = stepsMinMax(data, demographic, gender);

    value = (value === "rand") ? getRandomInt(min, max) : parseFloat(value);

    const labels = stepLabels(steps);

    const rating = ratingLabel(value, steps, min, max);

    const left = data.displayAs === "levels" ? arrowPositionLevels(value, steps) : arrowPosition(value, min, max);

    return (

        <>

            <Arrow
                left={left}
                alt={true}
            />

            {!hideRange &&
                <RangeLabels
                    steps={(data.displayAs === "steps" || data.displayAs === "levels") ? steps : undefined}
                    min={min}
                    max={max}
                />
            }

        </>

    )

};

export const MetricRanking: React.FC<any> = ({ data, value, alt, renderHeading, renderValue, rating, altUnit, field, prefix }) => {

    const app = useApp();

    const decimalPlace = -Math.abs(parseNum(typeof data.decimalPlaces === "number" ? data.decimalPlaces : 1));

    const maybeFormatValue = (value, decimals) => {

        if (typeof value === "string" && value.length === (parseNum(value) + "").length) value = parseNum(value);

        if (typeof value === "string" && value.indexOf(":")) return value;

        return formatNum(value, decimals)

    }

    const context = useContext(ReportContext);

    return (

        <div className={[styles.ranking, alt ? styles.rankingAlt : undefined].filter(Boolean).join(" ")}>

            <div className={styles.metric}>

                {app.userCan(["updateRatingScales"]) && data.id && !context.report &&
                    <Link
                        className={styles.edit}
                        target={"_blank"}
                        href={field ? `/field/rating-scales/${data.id}/edit` : `/lab/rating-scales/${data.id}/edit`}
                        title={"Edit Rating Scale"}
                    >
                        <FiEdit2 />
                    </Link>
                }

                {renderHeading && renderHeading(data.title ? data.title : data.metric)}
                {!renderHeading && <>{data.title ? data.title : data.metric}: </>}

                <div className={styles.rating}>
                    <span className={styles.ratingValue}>{rating || "-"}</span>
                </div>

            </div>


            <div className={styles.valueContainer}>

                {renderValue ?

                    renderValue({ data, value, styles, rating })

                    :

                    <span className={styles.measurement}>


                        {altUnit && altUnit.position === "before" &&
                            <>{maybeFormatValue(altUnit.value, altUnit.decimalPlace || decimalPlace)}<span className={styles.unit}> {altUnit.suffix}</span> <span className={styles.altSep}>{altUnit.sep || "/"}</span> </>
                        }

                        {prefix &&
                            <><span className={styles.prefix}>{prefix} = </span></>
                        }

                        {value ? maybeFormatValue(value, decimalPlace) : "0"}<span className={styles.unit}> {data.suffix}</span>

                        {altUnit && altUnit.position !== "before" &&
                            <> <span className={styles.altSep}>{altUnit.sep || "/"}</span> {maybeFormatValue(altUnit.value, altUnit.decimalPlace || decimalPlace)}<span className={styles.unit}> {altUnit.suffix}</span></>
                        }

                    </span>

                }

            </div>

        </div>

    )

};

export const MetricRankingAlt: React.FC<any> = ({ data, value, demographic, gender, renderValue, altUnit, decimalPlace }) => {

    const { steps, min, max }: any = stepsMinMax(data, demographic, gender);

    value = (value === "rand") ? getRandomInt(min, max) : parseFloat(value);

    const labels = stepLabels(steps);

    const rating = ratingLabel(value, steps, min, max);

    return (

        <MetricRanking
            alt={true}
            data={data}
            value={value}
            rating={rating}
            renderValue={renderValue}
            altUnit={altUnit}
        />

    )

};

export const arrowPosition = (value, min, max) => {

    let left = (value - min) / (max - min) * 100;

    left = Math.min(left, 100);

    left = Math.max(left, 0);

    return left;

};

export const arrowPositionLevels = (value, steps) => {

    let left = 0;

    const total = steps.length;
    const levelWidth = 100 / total;

    steps.map((step, index) => {

        if (value > step.min && value <= step.max) {

            left = levelWidth * index;

            left += (value - step.min) / (step.max - step.min) * levelWidth;

        }

    })

    return left;

};

export const stepLabels = (steps) => {

    const labels: any = [];

    loop(steps || [], (step, index) => {

        if (step.hidden) return;

        labels.push(step.label || "");

    });

    return labels;

};

export const ratingLabel = (value, steps, min, max) => {

    let rating = '-';

    let normalized = Math.max(value, min);

    normalized = Math.min(normalized, max);

    loop(steps || [], (step, index) => {

        const min = step.min;
        const max = step.max;

        if (!max) {

            if (normalized > min) rating = step.label;

        } else {

            if (!index && normalized <= min) rating = step.label;
            else if (normalized > min && normalized <= max) rating = step.label;

        }

    })

    return rating;

};

export const stepsMinMax = (data, demographic, gender) => {

    const steps: any = [];

    let min = 9000;
    let max = -9999;

    loop(data?.steps || [], (step, index) => {

        if (!step.label) return;

        step = { ...step };

        step.min = parseFloat(get(step, `${gender}.${demographic}.min`));
        step.max = parseFloat(get(step, `${gender}.${demographic}.max`));

        delete (step.male);
        delete (step.female);

        if (!isNaN(step.min)) min = Math.min(step.min, min);
        if (!isNaN(step.max)) max = Math.max(step.max, max);

        step.num = step.max ? step.max : step.min;

        steps.push(step);

    });

    // loop(data?.steps || [], (step, index) => {

    //     const size = min + step.min - step.max;

    //     // data.steps[index].size = ;
    //     // data.steps[index].pos = ;

    // });

    return { steps, min, max };

};