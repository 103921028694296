import { Col, DataTable, DataTableCell, InputTable, InputTableRow, Line, Panel, Row, Space } from '@dex/bubl-dash';
import React, { useEffect, useMemo } from 'react';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import FancyValue from '../../../elements/FancyValue/FancyValue';
import { date, fixedNum, formatNum, get, hashCode } from '@dex/bubl-helpers';
import ChartGlycogen from '../../../elements/ChartLabTests/ChartGlycogen';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import GlycogenFueling from '../../../elements/GlycogenFueling/GlycogenFueling';
import * as _ from "lodash";
import { getFuelingRateData, getGlycogenChartData, getGlycogenTestData } from '../../../functions/glycogenTest';
import LabTestsApi from '../../LabTests/LabTestsApi';
import styles from "./TestSessionsReportGlycogenDepletion.module.scss"
import moment from 'moment';
import { getClockTime } from '../../../functions/sharedTests';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportGlycogenDepletion: React.FC<any> = (props: any) => {

    const { data, test, form } = props;

    const recentTest = LabTestsApi.getOne(test?.labTestId);

    useEffect(() => {

        if (!test?.labTestId) return;

        recentTest.run();

    }, [test.labTestId]);

    const {
        unit, calculateRate, getEstimated,
        glycogenWholeBody, glycogenUpperBody, glycogenLowerBody,
        glycogenPart, glycogenAmount, calculateAmount,
        activitySuffix, activityEstimatedSuffix,
        getEstimatedPace, getEstimatedSwimPace, getEstimatedPower,
        carbUnit, computeCarbBurn, usedBurnRateKey, usedBurnRate
    } = getGlycogenTestData(data, test, recentTest.data);

    const { chartData, tableMax, tableMin,
        burnRateUsed, fuelingRateMin, fuelingRateMax,
        activity, targetTime, initialCarb
    } = getGlycogenChartData(test);

    const {
        fuelingPercentMin, fuelingPercentMax,
        decimalTargetTime, recommendedRate,
        bonkMax, bonkMin, computeCarbNeeded,
        bonkMinHour, bonkMaxHour
    } = getFuelingRateData(test);

    return useMemo(() => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row edge gutter={'auto'}>

                <Col col={{ xs: 24, md: 10 }}>

                    <GlycogenBarChart
                        data={data}
                        test={test}
                        unit={unit}
                    />

                </Col>

                <Col col={{ xs: 24, md: 14 }}>

                    <ReportMetaTable
                        className={""}
                        data={data}
                        columnSize={{ xs: 24, sm: 8 }}
                        columnA={[
                            {
                                key: 'athlete.fullName',
                                label: 'Athlete Name'
                            }
                        ]}
                        columnB={[
                            {
                                key: 'activity',
                                label: 'Activity',
                                render: (value, data) => test.activity,
                            },
                        ]}
                        columnC={[
                            {
                                key: 'date',
                                label: 'Test Date',
                                format: 'date',
                            },
                        ]}

                    />

                    <Line />

                    <ReportMetaTable
                        data={test}
                        columnSize={{ xs: 24, sm: 8 }}
                        columnA={[
                            {
                                key: 'name',
                                label: 'Event Name'
                            }
                        ]}
                        columnB={[
                            {
                                key: 'type',
                                label: 'Event Type',
                                format: "titleCase"
                            },
                        ]}
                        columnC={[
                            {
                                key: 'date',
                                label: 'Event Date',
                                format: "date"
                            },
                        ]}

                    />

                    <Space />

                    {test?.activities &&

                        <InputTable columns={[
                            {},
                            { label: "Distance", desc: 'km' },
                            { label: "Time", desc: 'hh:mm' },
                            { label: "Pace", desc: 'min/km' },
                            { label: "Speed", desc: 'kph' },
                            { label: "Power", desc: 'W' },
                        ].filter(Boolean)}>

                            {test?.activities.map((item, index) => (

                                <InputTableRow className={test?.activities.length % 3 == 0 && index == 1 ? "table-bg-gray" : ""}>

                                    <DataTableCell>{item.activity}</DataTableCell>
                                    <DataTableCell>{item.distance}</DataTableCell>
                                    <DataTableCell>{item.time}</DataTableCell>
                                    <DataTableCell>{item.pace}</DataTableCell>
                                    <DataTableCell>{item.speed}</DataTableCell>
                                    <DataTableCell>{item?.power}</DataTableCell>

                                </InputTableRow>

                            ))}

                            {test?.overall && test?.activities.length > 1 &&

                                <InputTableRow className={"table-bg-gray"}>

                                    <DataTableCell>{test.overall.activity}</DataTableCell>
                                    <DataTableCell>{test.overall.distance}</DataTableCell>
                                    <DataTableCell>{getClockTime(test.overall.time)}</DataTableCell>
                                    <DataTableCell>{getClockTime(test.overall.pace)} min/km</DataTableCell>
                                    <DataTableCell>{test.overall.speed}</DataTableCell>
                                    <DataTableCell>{test.overall?.power}</DataTableCell>

                                </InputTableRow>

                            }

                        </InputTable>

                    }

                </Col>

                <Col col={{ xs: 24 }}>

                    <PanelChart
                        key={hashCode(chartData)}
                        heading={`Energy Depletion During ${activity}`}
                        addZero={false}
                        data={chartData}
                        chartKey={form.hash}
                        renderChart={(chartType, chartData) => (
                            <>
                                <ChartGlycogen
                                    data={chartData}
                                    activity={activity}
                                    targetTime={targetTime}
                                    fuelingRateMax={fuelingRateMax}
                                    fuelingRateMin={fuelingRateMin}
                                    unit={unit}
                                />
                            </>

                        )}
                    />

                </Col>

                <Col col={{ xs: 24, md: 10 }}>

                    <GlycogenTargetTable
                        activity={activity}
                        targetTime={targetTime}
                        test={test}
                        initialCarb={initialCarb}
                        carbBurnRate={usedBurnRate}
                        unit={unit}
                    />

                </Col>

                <Col col={{ xs: 24, md: 14 }}>

                    <GlycogenFuelingTable
                        fuelingRateMin={fuelingRateMin}
                        fuelingRateMax={fuelingRateMax}
                        fuelingPercentMin={fuelingPercentMin}
                        fuelingPercentMax={fuelingPercentMax}
                        bonkMinHour={bonkMinHour}
                        bonkMaxHour={bonkMaxHour}
                        test={test}
                        unit={unit}
                    />

                </Col>


                <Col col={{ xs: 24, md: 12 }}>

                    <GlycogenFueling
                        recommendedRate={test.bonk.recommendedRate}
                        unit={unit}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="About Energy and Fueling"
                        value={test.summary}
                    />

                </Col>

            </Row>

        </>

    ), [data, form.hash, recentTest])
};


const GlycogenTargetTable: React.FC<any> = (props: any) => {

    const { activity, targetTime, test, initialCarb, carbBurnRate, unit } = props;

    return (

        <Panel heading={false} className={styles.panel}>

            <Row edge gutter={"small"}>

                <Col col={{ xs: 24 }}>
                    <p className={styles.heading}>{activity}</p>
                </Col>

                <Col col={{ xs: 24 }}>
                    <strong>Target</strong>
                </Col>

                <Col col={{ xs: 12 }} justify='center'>
                    <p>Duration</p>
                </Col>

                <Col col={{ xs: 12 }} align='start'>
                    <FancyValue value={targetTime} suffix="hh:mm" />
                </Col>

                <Col col={{ xs: 12 }} justify='center'>
                    <p>{activity == "Cycling" ? "Power" : "Speed"}</p>
                </Col>

                <Col col={{ xs: 12 }} align='start'>
                    <FancyValue value={test.power} suffix={activity == "Cycling" ? "W" : "kph"} />
                </Col>

                <Col col={{ xs: 24 }}>
                    <Line top={"0"} bottom={"0"} />
                </Col>

                <Col col={{ xs: 24 }}>
                    <strong>Glycogen :</strong>
                </Col>

                <Col col={{ xs: 12 }} justify='center'>
                    <p>Initial Load</p>
                </Col>

                <Col col={{ xs: 12 }} align='start'>
                    <FancyValue value={initialCarb} suffix={unit == 'g/hr' ? "g" : "kcal"} />
                </Col>

                <Col col={{ xs: 12 }} justify='center'>
                    <p>Burn Rate</p>
                </Col>

                <Col col={{ xs: 12 }} align='start'>
                    <FancyValue value={carbBurnRate} suffix={unit} />
                </Col>

            </Row>

        </Panel>
    )

};

const GlycogenFuelingTable: React.FC<any> = (props: any) => {

    const { fuelingRateMin, fuelingRateMax, fuelingPercentMin, fuelingPercentMax, bonkMinHour, bonkMaxHour, test, unit, data } = props;

    const sufficientMin = test.bonk.sufficientMin;
    const sufficientMax = test.bonk.sufficientMax;
    const xAxis = test.xAxis

    return (
        <Panel heading={false} className={styles.panel}>

            <Row edge gutter={"small"}>

                <Col col={{ xs: 24 }}>
                    <p className={styles.heading}>Fueling Rate</p>
                </Col>

                <Col col={{ xs: 8 }}>

                </Col>

                <Col col={{ xs: 8 }} align='center'>
                    <strong>Option 1</strong>
                </Col>

                <Col col={{ xs: 8 }} align='center'>
                    <strong>Option 2</strong>
                </Col>

                <Col col={{ xs: 8 }} justify='center'>
                    <p>Fueling Rate</p>
                </Col>

                <Col col={{ xs: 8 }} >
                    <FancyValue value={fuelingRateMin} suffix={unit} />
                </Col>

                <Col col={{ xs: 8 }} >
                    <FancyValue value={fuelingRateMax} suffix={unit} />
                </Col>

                <Col col={{ xs: 8 }} justify='center'>
                    <p>% of Burn Rate</p>
                </Col>

                <Col col={{ xs: 8 }}>
                    <FancyValue value={fuelingPercentMin} suffix="%" />
                </Col>

                <Col col={{ xs: 8 }}>
                    <FancyValue value={fuelingPercentMax} suffix="%" />
                </Col>

                <Col col={{ xs: 8 }} justify='center'>
                    <p>Time To Bonk</p>
                </Col>

                <Col col={{ xs: 8 }}>
                    <FancyValue value={getClockTime(bonkMinHour)} suffix="hh:mm" />
                </Col>

                <Col col={{ xs: 8 }}>
                    <FancyValue value={bonkMaxHour ? getClockTime(bonkMaxHour) : ">" + xAxis} suffix="hh:mm" />
                </Col>

                <Col col={{ xs: 8 }} justify='center'>
                    <p>Fuel Adequate ?</p>
                </Col>

                <Col col={{ xs: 8 }}>
                    <FancyValue value={sufficientMin} suffix="" />
                </Col>

                <Col col={{ xs: 8 }}>
                    <FancyValue value={sufficientMax} suffix="" />
                </Col>

            </Row>

        </Panel>
    )
};

const GlycogenBarChart: React.FC<any> = (props: any) => {

    const { data, test, unit } = props;

    const glycogenWholeBody = unit == "g/hr" ? get(data, "bio.glycogenWholeBody") : get(data, "bio.glycogenKcalWholeBody");
    const glycogenUpperBody = unit == "g/hr" ? get(data, "bio.glycogenUpperBody") : get(data, "bio.glycogenKcalUpperBody");
    const glycogenLowerBody = unit == "g/hr" ? get(data, "bio.glycogenLowerBody") : get(data, "bio.glycogenKcalLowerBody");
    const weight = get(data, "bio.weight");

    const items = [
        {
            value: parseFloat(glycogenWholeBody),
            label: "Whole"
        },
        {
            value: parseFloat(glycogenUpperBody),
            label: "Upper"
        },
        {
            value: parseFloat(glycogenLowerBody),
            label: "Lower"
        },
    ];

    const getColumnHeight = (value) => {

        const val = _.map(items, "value");
        const max = _.max(val);

        if (value == max) value = value * .80;

        return value / max * 100 + "%";

    };

    return (

        <Panel heading={false} className={styles.panel}>

            <Row edge gutter={"small"}>

                <Col col={{ xs: 24 }} align='center'>

                    <strong style={{ fontSize: "2rem" }}>Glycogen Load</strong>

                </Col>

                <Col col={{ xs: 24 }} align='center' justify='start'>

                    <strong style={{ fontSize: "1.1rem" }}>Stored In Your Body</strong>

                </Col>

                <Col col={{ xs: 24 }} align='center'>

                    <strong style={{ fontSize: "1.1rem" }}>Body Weight : <span style={{ color: "#76CA3F" }}>{formatNum(weight, 0)} Kg</span></strong>

                </Col>

                <Col col={{ xs: 24 }} align='center'>

                    <div className={styles.items}>

                        {items.map((item, index) => (

                            <div key={index} className={styles.item}>

                                <div className={[styles.value, styles[`${item.label}Value`]].join(' ')}>
                                    {formatNum(item.value, 0)} {unit == 'g/hr' ? "g" : "kcal"}
                                </div>

                                <div className={styles.column} style={{ height: getColumnHeight(item.value) }}>

                                    <div className={[styles.graphic, styles[item.label]].join(' ')} />

                                </div>

                                <div className={styles.label}>
                                    <strong>{item.label} Body</strong>
                                </div>

                            </div>

                        ))}

                    </div>

                </Col>

            </Row>

        </Panel>

    )

};

export default TestSessionsReportGlycogenDepletion;