import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Row, Col, } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import TestSessionsEditTabsPanel from './TestSessionsEditTabsPanel';
import { FieldList, FieldPopup } from '@dex/bubl-dash';
import EditableContext from '../../../context/EditableContext';
import { get } from '@dex/bubl-helpers';
import TestSessionsEditTabsEventsFormDetails from './TestSessionsEditTabsEventsFormDetails';

const TestSessionsEditTabsEvents: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    return useMemo(() => (
        <>
            <TestSessionsEditTabsPanel
                heading={"Events"}
                {...props}
            >
                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldList
                            form={form}
                            name={`events`}
                            label={"Events"}
                            addButtonLabel={"Add Event"}
                        >

                            {(form.values.events || []).map((event, i) => (

                                <TestSessionsEditTabsEventsForm
                                    event={event}
                                    form={form}
                                    i={i}
                                />

                            ))}

                        </FieldList>

                    </Col>

                </Row>
            </TestSessionsEditTabsPanel>
        </>

    ), [form.hash, data, process])
}

const TestSessionsEditTabsEventsForm: React.FC<any> = (props: any) => {

    const { i, event, form, data, session } = props;

    const prefix = `events[${i}]`;

    const [key, setKey] = useState(0);

    const [tab, setTab] = useState("detail");

    const upKey = useCallback(() => {

        setKey(key + 1);

    }, [key]);

    const editable = useContext(EditableContext);

    const [recentTest, setRecentTest] = useState<any>(null);



    return (

        <FieldPopup
            buttonLabel={"Event " + (i + 1) + " - " + get(form.values, `${prefix}.name`)}
            heading={"Event " + (i + 1) + " - " + get(form.values, `${prefix}.name`)}
            form={form}
            name={prefix}
            modalProps={{
                className: 'modal-top'
            }}
            panelProps={{
                tabs: [
                    { label: "Details", name: "detail" },
                ].filter(Boolean),
                setTab: setTab,
                activeTab: tab
            }}
        >

            <div key={key} className={!editable ? 'form-not-editable' : ''}>

                <div className={tab === "detail" ? "" : "hidden"}>

                    <TestSessionsEditTabsEventsFormDetails
                        form={form}
                        prefix={prefix}
                    />

                </div>

            </div>

        </FieldPopup>

    )
}

export default TestSessionsEditTabsEvents
