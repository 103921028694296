import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldRadio } from '@dex/bubl-dash';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartRaceReady from '../../../elements/ChartLabTests/ChartRaceReady';
import { clone, loop } from '@dex/bubl-helpers';
import { mergeEffort } from '../../../functions/raceReadyTest';

const LabTestsRaceReadyChart: React.FC<any> = (props: any) => {

    const { form, data, session } = props;

    const activity = data.activity;

    const chartUse = form.values?.result?.chartUse || "HR";

    const { effortData, efforts } = mergeEffort(form.values.avgData)

    let types: any = [];

    if (chartUse === "Power") {

        types = [
            {
                name: "w",
                label: "W",
                data: effortData,
            },
            {
                name: "w/kg",
                label: "W/kg",
                data: effortData,
            }
        ];

    } else if (chartUse === "Speed") {

        types = [
            {
                name: "speed",
                label: "speed",
                data: effortData,
            },
            {
                name: "pace",
                label: "pace",
                data: effortData,
            }
        ];

    } else if (chartUse === "SMO2") {

        types = [
            {
                name: "smo2_1",
                label: "SMO2 1",
                data: effortData,
            },
            {
                name: "smo2_2",
                label: "SMO2 2",
                data: effortData,
            }
        ];

    } else if (chartUse === "VO2") {

        types = [
            {
                name: "vo2relative",
                label: "ml/kg*min",
                data: effortData,
            },
            {
                name: "vo2absolute",
                label: "L/min",
                data: effortData,
            }
        ];

    } else if (chartUse === "Energy") {

        types = [
            {
                name: "g/hr",
                label: "g/hr",
                data: effortData,
            },
            {
                name: "kcal/hr",
                label: "kcal/hr",
                data: effortData,
            }
        ];

    } else if (chartUse === "Breathing") {

        types = [
            {
                name: "brpm",
                label: "BRPM",
                data: effortData,
            },
            {
                name: "tvl",
                label: "TVL",
                data: effortData,
            }
        ];

    } else if (chartUse === "Lactate") {

        types = [
            {
                name: "mmol/L",
                label: "mmol/L",
                data: effortData,
            },
            {
                name: "mmol/L/min",
                label: "mmol/L/min",
                data: effortData,
            }
        ];

    } else if (chartUse === "Stride Rate") {

        types = [
            {
                name: "per min",
                label: "per min",
                data: effortData,
            },
            {
                name: "length [m]",
                label: "length [m]",
                data: effortData,
            }
        ];

    };


    return useMemo(() => (

        <Row edge gutter={"8"}>

            <Col col={{ xs: 21 }}>

                <PanelChart
                    key={chartUse}
                    heading={chartUse}
                    types={types}
                    defaultType={types[0]?.name}
                    addZero={false}
                    addIntervalEnd={true}
                    data={effortData}
                    renderChart={(chartType, chartData) => (
                        <ChartRaceReady
                            type={chartUse}
                            data={chartData}
                            efforts={efforts}
                            activity={activity}
                            intensity={data.intensity}
                            unit={chartType}
                            xAxis={{
                                interval: ChartProps.interval("time", null),
                            }}
                        />
                    )}
                />

            </Col>

            <Col col={{ xs: 3 }}>

                <FieldRadio
                    list=" vertical-list"
                    key={chartUse}
                    form={form}
                    required={false}
                    label={""}
                    name={"result.chartUse"}
                    defaultValue={form.values?.result?.chartUse || chartUse}
                    options={[
                        activity === "Cycling" ? { label: "Power", value: "Power" } : null,
                        activity === "Running" ? { label: "Speed", value: "Speed" } : null,
                        { label: "SMO2", value: "SMO2" },
                        { label: "VO2", value: "VO2" },
                        { label: "Energy", value: "Energy" },
                        { label: "HR", value: "HR" },
                        { label: "RPE", value: "RPE" },
                        { label: "Lactate", value: "Lactate" },
                        { label: "Breathing", value: "Breathing" },
                        activity === "Running" ? { label: "Stride", value: "Stride Rate" } : null,
                    ].filter(Boolean)}
                />

            </Col>

        </Row>

    ), [form.hash]);

}


export default LabTestsRaceReadyChart;
