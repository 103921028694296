import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';

import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldRange from '@dex/bubl-dash/src/lib/components/Fields/FieldRange';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import { calculateRatingMobility } from '../../../functions/calculateRating';

const SessionsEditTabsToeTouch: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Toe Touch"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, lg: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                button={true}
                                form={form}
                                label={"Toe Touch"}
                                name='toeTouch.toeTouch'
                                required={false}
                                defaultValue={form.defaults.toeTouch.toeTouch}
                                options={[
                                    { value: "Good", label: "Good" },
                                    { value: "Fair", label: "Fair" },
                                    { value: "Restricted", label: "Restricted" },
                                    { value: "Not Assesed", label: "Not Assesed" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <div className='w-240'>

                                <FieldNumber
                                    form={form}
                                    label={"Pelvic Angle"}
                                    name={"toeTouch.pelvicAngle"}
                                    suffix={"°"}
                                    required={false}
                                    defaultValue={form.defaults.toeTouch.pelvicAngle}
                                />

                            </div>

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, lg: 8 }}>

                    <FieldUpload
                        form={form}
                        folder={'admin-media'}
                        label={"Photo"}
                        name={`toeTouch.photo`}
                        required={false}
                        defaultValue={form.defaults.toeTouch.photo}
                        limit={1}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldRange
                        form={form}
                        label={"Rating (Suggested: " + calculateRatingMobility(form.values.mobility) + ")"}
                        name='toeTouch.rating'
                        required={false}
                        defaultValue={form.defaults.toeTouch.rating}
                        min={0}
                        max={10}
                        minLabel={"0: Poor"}
                        maxLabel={"10: Good"}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Comments"}
                        name={'toeTouch.comments'}
                        required={false}
                        defaultValue={form.defaults.toeTouch.comments}
                        rows={4}
                        maxLength={400}
                    />

                </Col>

            </Row>

        </SessionsEditTabsPanel>

    ), [props]);

}

export default SessionsEditTabsToeTouch;
