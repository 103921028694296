import React, { useCallback, useMemo } from 'react';
import FieldSessionsEditTabsPanel from './FieldSessionsEditTabsPanel';
import { Button, Col, DataTableCell, FieldAutocomplete, FieldNumber, FieldRelationship, FieldWrap, InputTable, InputTableCell, InputTableRow, Row, Space } from '@dex/bubl-dash';
import { get, loop, titleCase } from '@dex/bubl-helpers';
import FieldSessionsApi from '../FieldSessionsApi';

const FieldSessionsEditTabsFormat: React.FC<any> = ( props: any ) => {

    const { form, data, process, handleSubmit } = props;

    const autoSelect = FieldSessionsApi.format( data.id, "autoSelectRatings" );

    const tests = useMemo( () => {

        const tests: any = [ ...data.templates ];

        return tests;

    }, [] );

    const columns = [
        {
            key: "category",
            label: "Category"
        },
        {
            key: "subcategory",
            label: "Subcategory"
        },
        {
            key: "Test",
            label: "Test Name"
        },
        {
            key: "units",
            label: "Units"
        },
        // {
        //     key: "options",
        //     label: "Options"
        // },
        {
            key: "weight",
            label: "Weight"
        },
        {
            key: "scaleId",
            label: "Rating Scale"
        }
    ];

    const handleAutoSelect = useCallback( ( source ) => {

        const testIds = tests.map( ( test ) => test.id );

        autoSelect.run( {
            params: { source: source, testIds: testIds },
            onComplete: ( response ) => {

                loop( response, ( scaleId, testId ) => {

                    form.handleChange( { name: `format.scaleIds[${ testId }]`, value: scaleId, reset: true } );

                } );

                form.setKey();

            }
        } );

    }, [ tests ] );

    return useMemo( () => (

        <FieldSessionsEditTabsPanel
            heading={"Report Formats"}
            {...props}
        >

            <Row gutter={"auto"} edge={true}>

                <Col col={{ xs: 24, sm: 12, md: 8 }}>

                    <FieldAutocomplete
                        form={form}
                        name={`format.ratingSource`}
                        label={'Rating Source'}
                        defaultValue={form.defaults.format.ratingSource}
                        required={true}
                        group='rating.source'
                        preload={true}
                    />

                </Col>

                {form.values.format?.ratingSource &&
                    <Col col={{ xs: 24, sm: 12, md: 16 }} align="" justify='end'>

                        <FieldWrap>
                            <Button
                                label={"Auto Select Rating Scales"}
                                onClick={handleAutoSelect.bind( null, form.values.format?.ratingSource )}
                                loading={autoSelect.loading}
                            // type={"secondary"}
                            />
                        </FieldWrap>

                    </Col>
                }

            </Row>

            <Space />

            <InputTable
                columns={columns}
            >

                {tests.map( ( test, index ) => (

                    <InputTableRow index={index}>

                        <DataTableCell style={{ width: 50 }}>
                            {titleCase( test.category )}
                        </DataTableCell>

                        <DataTableCell style={{ width: 50 }}>
                            {titleCase( test.subcategory )}
                        </DataTableCell>

                        <DataTableCell>
                            {test.name}
                        </DataTableCell>

                        <DataTableCell style={{ width: 50 }}>
                            {test.units}
                        </DataTableCell>

                        {/* <DataTableCell>
                            {titleCase(test.options)}
                        </DataTableCell> */}

                        <InputTableCell style={{ width: 100 }}>

                            {test.options !== "overall" &&
                                <FieldNumber
                                    form={form}
                                    name={`format.weights[${ test.id }]`}
                                    defaultValue={get( form.defaults, `format.weights[${ test.id }]` )}
                                    min={0}
                                    placeholder={"1"}
                                />
                            }

                        </InputTableCell>

                        <InputTableCell >

                            <FieldRelationship
                                form={form}
                                name={`format.scaleIds[${ test.id }]`}
                                defaultValue={get( form.defaults, `format.scaleIds[${ test.id }]` )}
                                preload={true}
                                endpoint={'FieldRatingScales'}
                                where={{ templateId: test.id }}
                                format={[ 'title', 'level', 'sport' ]}
                            />

                        </InputTableCell>

                    </InputTableRow>

                ) )}
            </InputTable>

        </FieldSessionsEditTabsPanel>

    ), [ props, tests, autoSelect.loading ] );

}

export default FieldSessionsEditTabsFormat;
