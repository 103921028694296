import React from 'react';
import * as _ from "lodash";
import styles from "./SweatTestAverageChart.module.scss";
import { Col, Heading, Row } from '@dex/bubl-dash';
import { fixedNum, formatNum, get, slug } from '@dex/bubl-helpers';

const SweatTestAverageChart: React.FC<SweatTestAverageChartProps> = (props) => {

    const { data, title, suffix, img } = props;

    const getColumnHeight = (items, item) => {

        let score = item.avg;

        const scores = _.map(items, "avg");
        const max = item.total || _.max(scores);

        if (score >= max / 2) score = score * 0.8;

        const heightPercentage = (score / max) * 100;
        const maxHeight = 200;
        const heightInPixels = (heightPercentage / 100) * maxHeight;

        return heightInPixels + "px";

    };

    const colSpan = Math.floor(24 / data?.length);

    return (

        <div className={styles.container}>

            <p className={styles.heading}>{title}</p>

            <Row align='center'>

                {img &&
                    <Col col={{ xs: 6 }} >

                        <img
                            src={suffix == "ml/hr" ? require(`../../assets/images/LabReport/icon_fluid_loss_run.png`) : require(`../../assets/images/LabReport/icon_sodium_loss.jpg`)}
                            style={{ width: "100px", height: "100px", display: "flex", justifyContent: "center" }}
                            alt='image'
                        />

                    </Col>
                }

                <Col col={img ? { xs: 18 } : { xs: 24 }}>

                    <Row>

                        {data?.map((item: any, index: any) => {

                            const _suffix = item.suffix || suffix;

                            return (
                                <Col
                                    // col={{ xs: colSpan }}
                                    grow={1}
                                    align='center'
                                    justify='end'
                                    key={index}
                                >
                                    {Object.keys(item).length === 0 &&
                                        <>
                                            <div className='table'>
                                                <strong>Min</strong>
                                                <strong>Max</strong>
                                            </div>
                                        </>
                                    }

                                    {Object.keys(item).length !== 0 &&
                                        <>
                                            <div style={{ textAlign: "center" }}>
                                                <strong >{formatNum(item.avg)}</strong>
                                                {_suffix && _suffix.length > 1 && <br />}
                                                <strong>{_suffix}</strong>

                                            </div>

                                            <div className={`column column-${slug(get(item, "type", index))}`} style={{ height: getColumnHeight(data, item) }} >
                                                <div className={styles.graphic} />
                                            </div>

                                            <div className={`title title-${slug(get(item, "type", index))}`}>
                                                <strong >{item.type}</strong>
                                            </div>

                                            <div className='table'>
                                                <strong>{item.min ? `${formatNum(item.min)} ${_suffix}` : "-"}</strong>
                                                <strong>{item.max ? `${formatNum(item.max)} ${_suffix}` : "-"}</strong>
                                            </div>
                                        </>
                                    }
                                </Col>

                            )

                        })}

                    </Row>

                </Col>

            </Row>

        </div>

    )

}

interface SweatTestAverageChartProps {
    [key: string]: any,
}

export default SweatTestAverageChart;