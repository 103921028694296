import React, { useMemo } from 'react';
import { Col, FieldRadio, Heading, Line, Row, useForm } from '@dex/bubl-dash';
import FieldResultsTeamRankingChart from '../../../elements/FieldResultsTeamRankingChart/FieldResultsTeamRankingChart';
import { loop, titleCase } from '@dex/bubl-helpers';
import FieldResultsTeamChangeChart from '../../../elements/FieldResultsTeamChangeChart/FieldResultsTeamChangeChart';

import style from "./FieldResult.module.scss";

const FieldResultsTeamFitness: React.FC<any> = (props: any) => {

    const { report } = props;

    const history = report.history;

    const form = useForm({ category: "overall", view: "ranking", date: history?.list[0]?.value });

    const categories = useMemo(() => {

        const list: any = [];

        loop(report.summary, (values, category) => {

            list.push({ value: category, label: titleCase(category) });

        })

        return list;

    }, [report.summary])

    const getRankingLabel = (name) => {
        if (name === "overall") {
            return "Overall Fitness Ranking";
        } else if (name === "speed") {
            return "Speed Ranking";
        } else if (name === "strength") {
            return "Strength Ranking";
        } else if (name === "power") {
            return "Power Ranking";
        } else if (name === "stamina") {
            return "Stamina Ranking";
        } else if (name === "suppleness") {
            return "Suppleness Ranking";
        } else if (name === "skill") {
            return "Skill Ranking";
        } else if (name === "body size") {
            return "Body Size Ranking";
        } else {
            return "";
        }
    };

    const { summary, scale } = useMemo(() => {

        const summary = report.summary[form.values.category] || null;

        //min max from summary

        const scale = { min: 0, max: 100, bestScore: "higher" };

        return { summary, scale };

    }, [report, form.hash])

    let missed = report.summaryMissed[form.values.category] || [];

    return useMemo(() => (

        <>

            <Row gutter={"auto"} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldRadio
                        button
                        form={form}
                        name={"category"}
                        label={"Category"}
                        defaultValue={form.defaults.category}
                        required
                        options={categories}
                    />

                    {history.list &&
                        <>
                            <Line />

                            <FieldRadio
                                button
                                form={form}
                                name={"view"}
                                label={"View As"}
                                defaultValue={form.defaults.view}
                                required
                                options={[
                                    { value: "ranking", label: "Ranking" },
                                    { value: "change", label: "Performance Change" }
                                ]}
                            />
                        </>
                    }

                </Col>

                <Col col={{ xs: 24, md: 12 }} >

                    {(form.values.view === "ranking" && summary) &&
                        <>
                            {scale &&
                                <FieldResultsTeamRankingChart
                                    heading={getRankingLabel(form.values.category)}
                                    scores={summary}
                                    scale={scale}
                                />
                            }
                            {!scale &&
                                <Heading level={3} space={false}>No Rating Scale Selected</Heading>
                            }
                        </>
                    }

                    {(form.values.view === "change" && summary) &&
                        <FieldResultsTeamChangeChart
                            heading={"Performance Change Since"}
                            category={form.values.category}
                            history={report.history}
                            missed={missed}
                            scale={scale}
                        />
                    }

                </Col>

            </Row>

        </>

    ), [props, summary, form.values]);

}

export default FieldResultsTeamFitness;
