import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, MetaTable, Panel, Seperator } from '@dex/bubl-dash';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';

const RatingScalesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [gender, setGender] = useState("male");

    const form = useForm({ "demographic": "untrained" });

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Rating Scale Details"}
                    actions={
                        <Button
                            label={"Copy To New Scale"}
                            type={"faded"}
                            path={`/lab/rating-scales/create?cloneId=${data.id}`}
                        />
                    }
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Metric",
                                key: "metric",
                            },
                            {
                                label: "Title",
                                key: "title",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Unit",
                                key: "suffix",
                            },
                            {
                                label: "Display As",
                                key: "displayAs",
                            },
                            {
                                label: "Best Score",
                                key: "bestScore",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Decription",
                                key: "description",
                            },
                            {
                                label: "Source",
                                key: "source",
                            },
                        ]}
                    />

                    <Seperator
                        heading={"Preview"}
                        top={"auto"}
                        bottom={"auto"}
                        activeTab={gender}
                        setTab={setGender}
                        tabs={[
                            { name: "male", label: "Male" },
                            { name: "female", label: "Female" },
                        ]}
                    />

                    <RatingScaleCard
                        data={data}
                        value={"rand"}
                        gender={gender}
                        form={form}
                        name="demographic"
                    />

                </Panel>

            </Col>

        </Row>

    ), [data, gender, form.values]);

}

export default RatingScalesSingleView;
