import React, { useCallback, useMemo } from 'react';
import { useApp, useForm } from '@dex/bubl-dash';
import CoachingTrainingWeeksApi from '../../CoachingTrainingWeeks/CoachingTrainingWeeksApi';
import CoachingTrainingsWeekForm from './CoachingTrainingsWeekForm';

const CoachingTrainingsAddWeek: React.FC<any> = (props: any) => {

    const { data, handleClose } = props;

    const app = useApp();

    const form = useForm({
        training: data.training,
        comments: {
            trainingDistruption: "None",
        },
        trainingZone: {
            zone: data?.activeZoneId,
        }
    });

    const process = CoachingTrainingWeeksApi.create();

    const handleSubmit = useCallback((values) => {

        values.coachingId = data.id;

        process.run({
            data: values,
            onComplete: (data) => {

                app.alert('Week Saved', 'success');

                // form.reset();

                if (handleClose) handleClose();

            }
        });

    }, [props]);

    return useMemo(() => (

        <>
            <CoachingTrainingsWeekForm
                form={form}
                data={data}
                process={process}
                handleSubmit={handleSubmit}
                mode={"create"}
                handleClose={handleClose}
            />

        </>

    ), [form.hash, data, process])
}

export default CoachingTrainingsAddWeek
