import React, { useEffect, useState } from 'react';
import styles from "./GaitRatingCard.module.scss";
import { get, hash, isEmpty, titleCase } from '@dex/bubl-helpers';
import { getRandomInt } from '../../functions/sharedGeneral';
import RatingScalesApi from '../../views/RatingScales/RatingScalesApi';
import { DisplayAs, MetricRanking, MetricRankingAlt, arrowPosition, arrowPositionLevels, ratingLabel, stepLabels, stepsMinMax } from '../RatingScale/RatingScaleElements';
import { getIssueType } from '../../functions/sharedGait';
import { Col, Row, Space } from '@dex/bubl-dash';

const GaitRatingCard: React.FC<GaitRatingCardProps> = (props) => {

    let { metric, value, form, name, demographic, source, gender, altUnit, split, renderValue, prefix, renderAfter, renderHeading, title, type, classes, className } = props;

    const group = RatingScalesApi.getData();

    const [data, setData]: any = useState({ metric: metric, demographic: "Scale Not Found" });

    if (form && name) demographic = get(form.values, name) || demographic;

    if (!gender && form) gender = get(form.values, "athlete.gender");

    if (!gender) gender = "male";

    if (!demographic) demographic = "all";

    useEffect(() => {

        if (props.data) setData(props.data);

    }, [hash(props.data)])

    useEffect(() => {

        if (!metric) return;

        group.run({
            params: {
                metric: metric,
                split: split ? split.metric : null,
            },
            onComplete: (response) => {

                if (response) setData(response);

            }
        });

    }, [metric]);

    const { steps, min, max }: any = stepsMinMax(data, demographic, gender);

    value = (props.value === "rand") ? getRandomInt(min, max) : parseFloat(value);

    return (

        <>

            {data &&

                <GaitRatingCardInner
                    data={data}
                    value={value}
                    demographic={demographic}
                    showDemographic={!form || !name}
                    source={source}
                    gender={gender}
                    altUnit={altUnit}
                    split={split}
                    renderValue={renderValue}
                    renderHeading={renderHeading}
                    steps={steps}
                    min={min}
                    max={max}
                    prefix={prefix}
                    renderAfter={renderAfter}
                    title={title}
                    type={type}
                    classes={classes}
                    className={className}
                />

            }

        </>

    )

}

const GaitRatingCardInner: React.FC<GaitRatingCardProps> = (props) => {

    let { value, demographic, gender, altUnit, split, data, steps, min, max, renderAfter, title, type, classes, className } = props;

    const labels = stepLabels(steps);

    const rating = (val) => ratingLabel(val, steps, min, max);

    const left = data.displayAs === "levels" ? arrowPositionLevels(value, steps) : arrowPosition(value, min, max);

    function firstLetterToLowerCase(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    const getIssue = (val) => {

        if (isEmpty(val)) return

        return getIssueType(firstLetterToLowerCase(type), val);
    }

    return (

        <Row edge gutter={8}>

            <Col col={{ xs: type ? 22 : 24 }} >

                <div className={styles.label}>
                    <p className={[styles.top, styles[classes]].join(" ")}>{title}</p>
                    <p className={styles.top}>{rating(value)}</p>
                </div>

            </Col>

            {type &&
                <Col col={{ xs: 2 }} align='center' justify='center'>

                    {!isEmpty(value) &&
                        <div className={styles[getIssue(value)]} />
                    }

                </Col>
            }

            <Col col={{ xs: type ? 22 : 24 }} >

                <DisplayAs
                    left={left}
                    labels={labels}
                    steps={steps}
                    data={data}
                    split={split}
                    min={min}
                    max={max}
                    demographic={demographic}
                    gender={gender}
                    hideRange={true}
                    classes={className}
                />

            </Col>

            {type &&
                <Col col={{ xs: 2 }} />
            }

            <Col col={{ xs: type ? 22 : 24 }} >

                {data.split &&
                    <>
                        <div className={styles.label}>
                            <p className={styles.bottom}>{props.split.title}</p>
                            <p className={styles.bottom}> {rating(props.split?.value)}</p>
                        </div>
                    </>
                }

            </Col>

            {type &&
                <Col col={{ xs: 2 }} align='center' justify='center'>

                    {!isEmpty(props.split?.value) &&
                        <div className={styles[getIssue(props.split?.value)]} />
                    }

                </Col>
            }

        </Row>

    )

}

interface GaitRatingCardProps {
    [key: string]: any,
}

export default GaitRatingCard;