import { Col, DataTableCell, FieldRange, FieldSelect, Heading, InputTable, InputTableRow, Line, MediaImage, Row, Space, useMountEffect } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import styles from "./GaitReportRating.module.scss";
import { get, loop } from '@dex/bubl-helpers';
import { GoDash } from 'react-icons/go';
import { HiArrowUp, HiArrowDown } from 'react-icons/hi';
import { BsQuestion } from "react-icons/bs";
import { ColoredHeader } from './GaitReportPage';
import GaitRatingCard from '../../../elements/GaitRatingCard/GaitRatingCard';

const GaitReportRating: React.FC<any> = (props: any) => {

    const { data, form, run, index } = props;

    const runData = run;
    const gender = data.athlete.gender;

    const columns = [
        ...(runData?.changes || []).map((metric, i) => (
            {
                label: `Trial ${i + 1}`,
            }
        ))
    ];

    const options = [
        { value: 'Improved a lot', label: <><HiArrowUp className={styles.up} /><HiArrowUp className={styles.up} /><HiArrowUp className={styles.up} /></> },
        { value: 'Improved Moderately', label: <><HiArrowUp className={styles.up} /><HiArrowUp className={styles.up} /></> },
        { value: 'Improved a bit', label: <><HiArrowUp className={styles.up} /></> },
        { value: 'Worsened a lot', label: <><HiArrowDown className={styles.down} /><HiArrowDown className={styles.down} /><HiArrowDown className={styles.down} /></> },
        { value: 'Worsened Moderately', label: <><HiArrowDown className={styles.down} /><HiArrowDown className={styles.down} /></> },
        { value: 'Worsened a bit', label: <><HiArrowDown className={styles.down} /></> },
        { value: "Can't tell/Uncertain", label: <BsQuestion className={styles.question} /> },
        { value: 'No Change', label: <GoDash className={styles.dash} /> },
    ];

    const getIcon = (value) => {

        let icon;

        if (!value) return;

        loop(options, (opt) => {
            if (value == opt.value) return icon = opt.label
        })

        return icon
    };

    return useMemo(() => (

        <Row edge gutter={20}>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Rating and changes"
                    desc={`${runData.date}`}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <InputTable
                    className={""}
                    columns={columns}
                >

                    <InputTableRow >

                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i} style={{ width: i === 0 ? '400px' : "" }} >

                                {column.condition}

                            </DataTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i}>

                                {column.shoe}

                            </DataTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i}>
                                {i == 0 ?
                                    <GaitRatingCard
                                        metric={"Rating And Changes"}
                                        value={column.footStrike}
                                        gender={gender}
                                        title="Foot Strike"
                                        classes="blackTop"
                                    />
                                    :
                                    getIcon(column.footStrike)
                                }
                            </DataTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i}>

                                {i == 0 ?
                                    <GaitRatingCard
                                        metric={"Rating And Changes"}
                                        value={column.footControl}
                                        gender={gender}
                                        title="Foot Control/Stability"
                                        classes="blackTop"
                                    />
                                    :
                                    getIcon(column.footControl)
                                }

                            </DataTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow >


                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i}>
                                {i == 0 ?
                                    <GaitRatingCard
                                        metric={"Rating And Changes"}
                                        value={column.kneeStability}
                                        gender={gender}
                                        title="Knee Stability"
                                        classes="blackTop"
                                    />
                                    :
                                    getIcon(column.kneeStability)
                                }

                            </DataTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow >


                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i}>
                                {i == 0 ?
                                    <GaitRatingCard
                                        metric={"Rating And Changes"}
                                        value={column.hipStability}
                                        gender={gender}
                                        title="Hip Stability"
                                        classes="blackTop"
                                    />
                                    :
                                    getIcon(column.hipStability)
                                }

                            </DataTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i}>
                                {i == 0 ?
                                    <GaitRatingCard
                                        metric={"Rating And Changes"}
                                        value={column.torsoPosture}
                                        gender={gender}
                                        title="Torso Posture"
                                        classes="blackTop"
                                    />
                                    :
                                    getIcon(column.torsoPosture)
                                }

                            </DataTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i}>
                                {i == 0 ?
                                    <GaitRatingCard
                                        metric={"Rating And Changes"}
                                        value={column.armSwing}
                                        gender={gender}
                                        title="Arm Swing"
                                        classes="blackTop"
                                    />
                                    :
                                    getIcon(column.armSwing)
                                }

                            </DataTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i}>
                                {i == 0 ?
                                    <GaitRatingCard
                                        metric={"Rating And Changes"}
                                        value={column.footNoise}
                                        gender={gender}
                                        title="Foot Noise"
                                        classes="blackTop"
                                    />
                                    :
                                    getIcon(column.footNoise)
                                }

                            </DataTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i}>
                                {i == 0 ?
                                    <GaitRatingCard
                                        metric={"Rating And Changes"}
                                        value={column.cadence}
                                        gender={gender}
                                        title="Cadence"
                                        classes="blackTop"
                                    />
                                    :
                                    getIcon(column.cadence)
                                }

                            </DataTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i}>
                                {i == 0 ?
                                    <GaitRatingCard
                                        metric={"Rating And Changes"}
                                        value={column.verticalHip}
                                        gender={gender}
                                        title="Vertical Hip Motion"
                                        classes="blackTop"
                                    />
                                    :
                                    getIcon(column.verticalHip)
                                }

                            </DataTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow >

                        {runData?.changes?.map((column, i) => (

                            <DataTableCell className={i !== 0 ? "table-bdr-left-gray" : ""} key={i}>
                                {i == 0 ?
                                    <GaitRatingCard
                                        metric={"Rating And Changes"}
                                        value={column.gtcBalance}
                                        gender={gender}
                                        title="Ground Contact Time Balance (Left vs Right)"
                                        classes="blackTop"
                                    />
                                    :
                                    getIcon(column.gtcBalance)
                                }

                            </DataTableCell>

                        ))}

                    </InputTableRow>

                </InputTable>

            </Col>

            <Col col={{ xs: 24 }}>

                <p className='sub-heading-title'>Change Legend</p>

                <Space height={8} />

                <Row edge gutter={8} className={styles.legend}>

                    <Col col={{ xs: 8 }}>

                        <Row edge gutter={8}>

                            <Col col={{ xs: 4 }} align='end'>
                                <GoDash className={styles.dash} />
                            </Col>

                            <Col col={{ xs: 20 }} justify='center'>
                                <strong> No Change</strong>
                            </Col>

                            <Col col={{ xs: 4 }} align='end'>
                                <BsQuestion className={styles.question} />
                            </Col>

                            <Col col={{ xs: 20 }} justify='center'>
                                <strong> Can't tell/Uncertain </strong>
                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 8 }}>
                        <Row edge gutter={8}>

                            <Col col={{ xs: 8 }} align='end'>
                                <><HiArrowUp className={styles.up} /></>
                            </Col>

                            <Col col={{ xs: 16 }} justify='center'>
                                <strong>Improved A Bit </strong>
                            </Col>

                            <Col col={{ xs: 8 }} align='end'>
                                <HiArrowUp className={styles.up} /><HiArrowUp className={styles.up} />
                            </Col>

                            <Col col={{ xs: 16 }} justify='center'>
                                <strong> Improved Significantly</strong>
                            </Col>

                            <Col col={{ xs: 8 }} align='end'>
                                <><HiArrowUp className={styles.up} /><HiArrowUp className={styles.up} /><HiArrowUp className={styles.up} /></>
                            </Col>

                            <Col col={{ xs: 16 }} justify='center'>
                                <strong>Improved A Lot</strong>
                            </Col>

                        </Row>
                    </Col>

                    <Col col={{ xs: 8 }}>
                        <Row edge gutter={8}>

                            <Col col={{ xs: 8 }} align='end'>
                                <><HiArrowDown className={styles.down} /></>
                            </Col>

                            <Col col={{ xs: 16 }} justify='center'>
                                <strong>A Bit Worse</strong>
                            </Col>

                            <Col col={{ xs: 8 }} align='end'>
                                <><HiArrowDown className={styles.down} /><HiArrowDown className={styles.down} /></>
                            </Col>

                            <Col col={{ xs: 16 }} justify='center'>
                                <strong> Significantly Worse</strong>
                            </Col>

                            <Col col={{ xs: 8 }} align='end'>
                                <><HiArrowDown className={styles.down} /><HiArrowDown className={styles.down} /><HiArrowDown className={styles.down} /></>
                            </Col>

                            <Col col={{ xs: 16 }} justify='center'>
                                <strong> A Lot Worse </strong>
                            </Col>

                        </Row>
                    </Col>

                </Row>

            </Col>

        </Row >

    ), [data]);

}

export default GaitReportRating;