import React, { useCallback, useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import QuestionnairesApi from '../QuestionnairesApi';
import { ButtonRow, Col, Form, useApp, useForm } from '@dex/bubl-dash';
import { Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';

const QuestionnairesReview: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store('nav');

    const { data, session } = props;

    const form = useForm(data);

    const update = QuestionnairesApi.update(data.id, "review", {
        onComplete: (data) => {

            app.alert('Questionnaires Saved', 'success');

            if (data.modelName === 'Sessions') {
                app.navigate(nav, 'push', {
                    type: "Navigation/NAVIGATE",
                    routeName: "sessionsView",
                    params: { id: data.id }
                })
            }

            if (data.modelName === 'TestSessions') {
                app.navigate(nav, 'push', {
                    type: "Navigation/NAVIGATE",
                    routeName: "lab/test-sessionsView",
                    params: { id: data.id }
                })
            }

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Panel
            heading={"Review"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={update.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 7 }}>

                        <FieldRadio
                            form={form}
                            label='Status'
                            name='status'
                            required={true}
                            defaultValue={form.defaults.status}
                            options={[
                                { value: "pending", label: "Pending" },
                                { value: "filled", label: "Filled" },
                                { value: "reviewed", label: "Reviewed" },
                            ]}
                        />

                    </Col>

                    {form.values.status === 'reviewed' &&

                        <>
                            {!["ReFit on Same Bike", "ReFit on New Bike", "Remote Bike Sizing"].includes(data.typeOfService) &&
                                <Col col={{ xs: 4 }}>

                                    <FieldSwitch
                                        form={form}
                                        label='Update Athlete Profile'
                                        name='updateAthleteProfile'
                                        defaultValue={form.defaults.updateAthleteProfile}
                                    />
                                </Col>
                            }

                            <Col col={{ xs: 4 }}>

                                <FieldSwitch
                                    form={form}
                                    label='Generate Session'
                                    name='generateSession'
                                    defaultValue={form.defaults.generateSession}
                                />

                            </Col>

                            {form.values.generateSession &&

                                <>
                                    <Col col={{ xs: 5 }}>
                                        <FieldDate
                                            form={form}
                                            name={'sessionDate'}
                                            label={'Session Date'}
                                            required={true}
                                        />
                                    </Col>

                                    {data.typeOfService !== "Lab Test" &&
                                        <Col col={{ xs: 4 }}>

                                            <FieldSwitch
                                                form={form}
                                                label='Copy Bike Details'
                                                name='copyBikeDetails'
                                                defaultValue={form.defaults.copyBikeDetails}
                                            />
                                        </Col>
                                    }
                                </>

                            }

                        </>

                    }

                </Row>

                <Line />

                <ButtonRow>

                    <Button
                        label={"Save"}
                        loading={update.loading}
                        onClick={form.triggerSubmit}
                    />

                </ButtonRow>

            </Form>

        </Panel>

    ), [props, update, form]);

}

export default QuestionnairesReview;
