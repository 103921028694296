import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, MetaTable, Panel, Seperator } from '@dex/bubl-dash';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import { clone, loop } from '@dex/bubl-helpers';

const FieldRatingScalesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ "demographic": "untrained" });

    loop(data.steps, (step: any, index) => {

        data.steps[index].male = { untrained: { min: step.min, max: step.max } };

    });

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Field Rating Scale Details"}
                    actions={
                        <Button
                            label={"Copy To New Scale"}
                            type={"faded"}
                            path={`/field/rating-scales/create?cloneId=${data.id}`}
                        />
                    }
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Template",
                                key: "template",
                                format: "modelLink"
                            },
                            {
                                label: "Source",
                                key: "source",
                            },
                            {
                                label: "Sport",
                                key: "sport",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Level",
                                key: "level",
                                format: "titleCase"
                            },
                            {
                                label: "Unit",
                                key: "units",
                            },
                            {
                                label: "Best Score is",
                                key: "bestScore",
                                format: "titleCase"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Mean",
                                key: "mean",
                                format: "number"
                            },
                            {
                                label: "Standard Deviation",
                                key: "std",
                                format: "number"
                            },
                        ]}
                    />

                    <Seperator
                        heading={"Preview"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <RatingScaleCard
                        data={{
                            ...data,
                            id: undefined,
                            title: data.template.name,
                        }}
                        value={"rand"}
                        gender={"male"}
                        demographic={"untrained"}
                        demographicLabel={data.level}
                    />

                </Panel>

            </Col>

        </Row>

    ), [data, form.hash]);

}

export default FieldRatingScalesSingleView;
