import React, { useEffect, useState } from 'react';
import { Row, Col, } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, ButtonRow, DataTableCell, FieldHidden, FieldNumber, FieldRelationship, FieldTextTime, FieldUpload, FieldWrap, Heading, InputTable, InputTableCell, InputTableRow, useApp, useUpdateEffect } from '@dex/bubl-dash';
import { clone, fixedNum, get, hashCode, loop, titleCase, upperCase } from '@dex/bubl-helpers';
import { getClockTime, getDecimalTime, getMinutesTime } from '../../../functions/sharedTests';
import { calculateTarget, coachingGetWeeks, getIcon } from '../../../functions/sharedCoaching';
import CoachingApi from '../CoachingApi';
import * as _ from 'lodash';
import { CoachingTrainingsIsBest } from './CoachingTrainingsBestProvider';
import CoachingTrainingWeeksApi from '../../CoachingTrainingWeeks/CoachingTrainingWeeksApi';
import { getAverage } from '../../../functions/sharedGeneral';

const CoachingTrainingsWeekVolTab: React.FC<any> = (props: any) => {

    const { form, data, mode } = props;

    const app = useApp();

    const ocrImage = CoachingApi.update(data.id, "handleOcrImage");

    const [ocrData, setOcrData]: any = useState({});

    const targetWeek = CoachingTrainingWeeksApi.getOne(":id");
    const coaching = CoachingApi.getOne(data.id);

    const getTargetWeek = async (weekEnding, type) => {

        let targets: any = {};

        if (!weekEnding) {

            //get latest coaching targets
            coaching.run({
                onComplete: (response) => {

                    targets = clone(get(response, 'training.final', {}));

                    setTargetWeek(targets, type);

                }
            });

            return;

        }

        targetWeek.run({
            url: targetWeek.options.url?.replace(":id", weekEnding),
            onComplete: (response) => {

                if (response) {

                    targets.volumeTotalTime = response.volume?.total?.timeTarget;
                    targets.volumeSwimTime = response.volume?.results.swim?.timeTarget;
                    targets.volumeBikeTime = response.volume?.results.bike?.timeTarget;
                    targets.volumeRunTime = response.volume?.results.run?.timeTarget;
                    targets.volumeGymTime = response.volume?.results.gym?.timeTarget;

                    targets.volumeTotalDistance = response.volume?.total?.distanceTarget;
                    targets.volumeSwimDistance = response.volume?.results.swim?.distanceTarget;
                    targets.volumeBikeDistance = response.volume?.results.bike?.distanceTarget;
                    targets.volumeRunDistance = response.volume?.results.run?.distanceTarget;

                    targets.intensitySwimTime = response.intensity?.results?.swim?.target;
                    targets.intensityBikeTime = response.intensity?.results?.bike?.target;
                    targets.intensityRunTime = response.intensity?.results?.run?.target;
                    targets.intensityHRTime = response.intensity?.results?.hr?.target;
                    targets.intensityTotalTime = response.intensity?.totalEndurance?.target;

                }

                setTargetWeek(targets, type);

            }
        });

    }

    const setTargetWeek = async (targets, type) => {

        if (type === "volume") {

            form.handleChange({ reset: true, name: `volume.results.swim.timeTarget`, value: targets?.volumeSwimTime });
            form.handleChange({ reset: true, name: `volume.results.bike.timeTarget`, value: targets?.volumeBikeTime });
            form.handleChange({ reset: true, name: `volume.results.run.timeTarget`, value: targets?.volumeRunTime });
            form.handleChange({ reset: true, name: `volume.results.gym.timeTarget`, value: targets?.volumeGymTime });

            form.handleChange({ reset: true, name: `volume.results.swim.distanceTarget`, value: targets?.volumeSwimDistance });
            form.handleChange({ reset: true, name: `volume.results.bike.distanceTarget`, value: targets?.volumeBikeDistance });
            form.handleChange({ reset: true, name: `volume.results.run.distanceTarget`, value: targets?.volumeRunDistance });

        }

        if (type === "intensity") {

            form.handleChange({ reset: true, name: `intensity.results.swim.target`, value: targets?.intensitySwimTime });
            form.handleChange({ reset: true, name: `intensity.results.bike.target`, value: targets?.intensityBikeTime });
            form.handleChange({ reset: true, name: `intensity.results.run.target`, value: targets?.intensityRunTime });
            form.handleChange({ reset: true, name: `intensity.results.hr.target`, value: targets?.intensityHRTime });
            form.handleChange({ reset: true, name: `intensity.totalEndurance.target`, value: targets?.intensityTotalTime });

        }

        app.alert(`${titleCase(type)} Data Filled`, 'success');

    }

    const handleFill = (type) => {

        if (type === "ocr") {

            ocrImage.run({
                data: { photoId: form.values.volume.photo.fileId },
                onComplete: (response) => {

                    setOcrData(response)

                    app.alert('Photo Data Filled', 'success');

                }
            })

        }

        if (type === "volume") {

            getTargetWeek(form.values.targetsWeekEnding, "volume");

        } else if (type === "intensity") {

            getTargetWeek(form.values.targetsWeekEnding, "intensity");

        }

    };

    const weekOptions: any = [
        { value: '', label: "Current Targets" },
        ...coachingGetWeeks(data, true)
    ];

    return (

        <>

            <Row
                gutter={16}
                edge
            >

                <Col col={{ xs: 12, md: 7 }}>

                    <FieldUpload
                        form={form}
                        name='volume.photo'
                        folder='coaching-media'
                        label='Photo'
                        defaultValue={form.values.volume?.photo}
                        multiple={false}
                        limit={1}
                        accept='image/*'
                    />

                </Col>

                <Col col={{ xs: 12, md: 5 }} justify='end' align='center'>

                    <FieldWrap>
                        {form.values.volume?.photo &&

                            <Button
                                label="AutoFill From Photo"
                                onClick={handleFill.bind(null, "ocr")}
                                type={"light"}
                                loading={ocrImage.loading}
                            />

                        }
                    </FieldWrap>

                </Col>

                <Col col={{ xs: 12, md: 6 }}>

                    <FieldRelationship
                        form={form}
                        name='targetsWeekEnding'
                        defaultValue={form.defaults.targetsWeekEnding}
                        placeholder="Current Targets"
                        endpoint={'CoachingTrainingWeeks'}
                        where={{ coachingId: data.id }}
                        order={'weekEndDate DESC'}
                        limit={100}
                        preload={true}
                        label='Targets From Week Ending'
                    />

                </Col>

                <Col col={{ xs: 12, md: 6 }} >
                    <FieldWrap label='Fill Targets'>
                        <ButtonRow>
                            <Button
                                label="Volume"
                                onClick={handleFill.bind(null, "volume")}
                                type={"light"}
                            />
                            <Button
                                label="Intensity"
                                onClick={handleFill.bind(null, "intensity")}
                                type={"light"}
                            />
                        </ButtonRow>
                    </FieldWrap>

                </Col>

                <Col col={{ xs: 24 }}>

                    <VolumeTable
                        data={data}
                        form={form}
                        ocrData={ocrData}
                        mode={mode}
                    />

                </Col>

                <Col col={{ xs: 16 }}>

                    <IntensityTable
                        data={data}
                        form={form}
                        ocrData={ocrData}
                        mode={mode}
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <OCRTable
                        data={data}
                        form={form}
                        ocrData={ocrData}
                        mode={mode}
                    />

                </Col>

            </Row>

        </>

    );

}

const VolumeTable: React.FC<any> = (props: any) => {

    const { data, form, ocrData } = props;

    const column = [
        {
            key: "type",
            label: <Heading space={false} size={4}>VOLUME</Heading>,
            style: { width: "80px" },
            className: "table-bg-green"
        },
        {
            style: { width: "80px" },
            key: "timeTarget",
            label: "Time",
            desc: "Target"
        },
        {
            style: { width: "80px" },
            key: "timeActual",
            label: "Time",
            desc: "Actual"
        },
        {
            style: { width: "80px" },
            key: "timeMet",
            label: <>&nbsp;</>,
            desc: "Met"

        },
        {
            style: { width: "80px" },
            key: "best",
            label: "Best / Top 5%",
            desc: <>&nbsp;</>,
        },
        {
            style: { width: "80px" },
            key: "distanceTarget",
            label: "Distance",
            desc: "Target"
        },
        {
            style: { width: "80px" },
            key: "distanceActual",
            label: "Distance",
            desc: "Actual"
        },
        {
            style: { width: "80px" },
            key: "distanceMet",
            label: <>&nbsp;</>,
            desc: "Met"
        },
        {
            style: { width: "80px" },
            key: "best",
            label: "Best / Top 5%",
            desc: <>&nbsp;</>,
        },
        {
            style: { width: "50px" },
            key: "workouts",
            label: "Workouts",
            desc: "#"
        },
    ];

    const events: any = [
        ...data?.general?.sportList?.map((item) => ({ type: item, label: upperCase(item) })),
        ...["gym", "other", "bricks"].map((item) => ({ type: item, label: upperCase(item) })),
        { type: "testRace", label: upperCase("Test / Race") }
    ];

    const totals: any = [
        { label: "TOTAL", type: "total" },
        { label: "TOTAL ENDURANCE", type: "totalEndurance" },
    ];

    const calculateTotals = (results) => {

        if (!results) return;

        const totals = results.reduce((acc, obj) => {

            obj.timeTarget = obj.timeTarget ?? 0;
            obj.timeActual = obj.timeActual ?? 0;
            obj.distanceTarget = obj.distanceTarget ?? 0;
            obj.distanceActual = obj.distanceActual ?? 0;
            obj.workout = obj.workout ?? 0;

            if (obj.key == "swim") {
                obj.distanceTargetKm = obj.distanceTarget / 1000;
                obj.distanceActualKm = obj.distanceActual / 1000;
            } else {
                obj.distanceTargetKm = obj.distanceTarget;
                obj.distanceActualKm = obj.distanceActual;
            }

            return {
                distanceTarget: Number(acc.distanceTarget) + Number(obj.distanceTargetKm),
                distanceActual: Number(acc.distanceActual) + Number(obj.distanceActualKm),
                timeTarget: getMinutesTime(obj.timeTarget) + acc.timeTarget,
                timeActual: getMinutesTime(obj.timeActual) + acc.timeActual,
                workout: Number(acc.workout) + Number(obj.workout),
            };

        }, { timeActual: 0, distanceTarget: 0, timeTarget: 0, distanceActual: 0, workout: 0 });

        totals.timeTarget = getClockTime(totals.timeTarget / 60);
        totals.timeActual = getClockTime(totals.timeActual / 60);
        totals.distanceTarget = fixedNum(totals.distanceTarget, 2);
        totals.distanceActual = fixedNum(totals.distanceActual, 2);

        return totals;
    };

    useEffect(() => {

        let results = clone(get(form.values, `volume.results`));

        if (results) {

            results = Object.entries(results).map(([key, activity]) => ({ key, ...(activity as object) }));
            results = results.filter(activity => activity.key !== 'bricks' && activity.key !== 'testRace');

            const totals = calculateTotals(results);

            form.handleChange({ reset: true, name: `volume.total`, value: totals });

            const filteredResults = results.filter((item, index) => data?.general?.sportList?.includes(events[index]?.type));

            const totalsEndurance = calculateTotals(filteredResults);

            form.handleChange({ reset: true, name: `volume.totalEndurance`, value: totalsEndurance });
        }

    }, [hashCode(get(form.values, `volume.results`))]);

    useUpdateEffect(() => {

        if (!ocrData) return;

        loop(events, (event, i) => {
            if (event.type == "swim") {
                form.handleChange({ reset: true, silent: true, name: `volume.results.swim.distanceTarget`, value: ocrData.SwimDistanceTarget })
                form.handleChange({ reset: true, silent: true, name: `volume.results.swim.distanceActual`, value: ocrData.SwimDistanceActual })
                form.handleChange({ reset: true, silent: true, name: `volume.results.swim.timeTarget`, value: ocrData.SwimDurationTarget })
                form.handleChange({ reset: true, silent: true, name: `volume.results.swim.timeActual`, value: ocrData.SwimDurationActual })
            } else if (event.type == "bike") {
                form.handleChange({ reset: true, silent: true, name: `volume.results.bike.distanceTarget`, value: ocrData.BikeDistanceTarget })
                form.handleChange({ reset: true, silent: true, name: `volume.results.bike.distanceActual`, value: ocrData.BikeDistanceActual })
                form.handleChange({ reset: true, silent: true, name: `volume.results.bike.timeTarget`, value: ocrData.BikeDurationTarget })
                form.handleChange({ reset: true, silent: true, name: `volume.results.bike.timeActual`, value: ocrData.BikeDurationActual })
            } else if (event.type == "run") {
                form.handleChange({ reset: true, silent: true, name: `volume.results.run.distanceTarget`, value: ocrData.RunDistanceTarget })
                form.handleChange({ reset: true, silent: true, name: `volume.results.run.distanceActual`, value: ocrData.RunDistanceActual })
                form.handleChange({ reset: true, silent: true, name: `volume.results.run.timeTarget`, value: ocrData.RunDurationTarget })
                form.handleChange({ reset: true, silent: true, name: `volume.results.run.timeActual`, value: ocrData.RunDurationActual })
            } else if (event.type == "gym") {
                form.handleChange({ reset: true, silent: true, name: `volume.results.gym.timeTarget`, value: ocrData.StrengthDurationTarget })
                form.handleChange({ reset: true, silent: true, name: `volume.results.gym.timeActual`, value: ocrData.StrengthDurationActual })
            }
        })

    }, [ocrData]);

    useUpdateEffect(() => {

        form.handleChange({ reset: true, name: "rpe.workoutsCompleted", value: form.values?.volume?.total?.workout })

    }, [form.values?.volume?.total?.workout])

    return (

        <InputTable
            columns={column}
        >

            {(events || []).map((item, index) => (
                <InputTableRow key={index}>

                    <DataTableCell>
                        <strong>{item.label}</strong>
                    </DataTableCell>

                    <InputTableCell>
                        {item.type != "bricks" && item.type != "testRace" &&
                            <FieldTextTime
                                dynamicKey
                                form={form}
                                name={`volume.results.${item.type}.timeTarget`}
                                defaultValue={get(form.values, `volume.results.${item.type}.timeTarget`)}
                                wrapClass={item.type == "other" ? "manual-input" : "green-input"}
                            />
                        }
                    </InputTableCell>

                    <InputTableCell>
                        {item.type != "bricks" && item.type != "testRace" &&
                            <FieldTextTime
                                dynamicKey
                                form={form}
                                name={`volume.results.${item.type}.timeActual`}
                                defaultValue={get(form.values, `volume.results.${item.type}.timeActual`)}
                                wrapClass={item.type == "other" ? "manual-input" : "green-input"}
                            />
                        }
                    </InputTableCell>

                    <InputTableCell>
                        {item.type != "bricks" && item.type != "testRace" &&
                            <>
                                {getIcon(get(form.values, `volume.results.${item.type}.timeTarget`), get(form.values, `volume.results.${item.type}.timeActual`), calculateTarget(get(form.values, `volume.results.${item.type}.timeTarget`), get(form.values, `volume.results.${item.type}.timeActual`)))?.icon}

                                <FieldHidden
                                    form={form}
                                    name={`volume.results.${item.type}.timeMet`}
                                    value={calculateTarget(get(form.values, `volume.results.${item.type}.timeTarget`), get(form.values, `volume.results.${item.type}.timeActual`))}
                                />

                            </>
                        }
                    </InputTableCell>

                    <InputTableCell>
                        {(item.type == "swim" || item.type == "bike" || item.type == "run") &&
                            <CoachingTrainingsIsBest
                                name={`volume.results.${item.type}.timeActual`}
                            />
                        }
                    </InputTableCell>

                    <InputTableCell>
                        {item.type != "gym" && item.type != "other" && item.type != "bricks" && item.type != "testRace" &&

                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`volume.results.${item.type}.distanceTarget`}
                                defaultValue={get(form.values, `volume.results.${item.type}.distanceTarget`)}
                                key={get(form.values, `volume.results.${item.type}.distanceTarget`)}
                                wrapClass={"green-input"}
                                suffix={item.type != "swim" ? " km" : " m"}
                                defer={1000}
                            />
                        }
                    </InputTableCell>

                    <InputTableCell>
                        {item.type != "gym" && item.type != "other" && item.type != "bricks" && item.type != "testRace" &&
                            <FieldNumber
                                allowNegative
                                form={form}
                                name={`volume.results.${item.type}.distanceActual`}
                                defaultValue={get(form.values, `volume.results.${item.type}.distanceActual`)}
                                key={get(form.values, `volume.results.${item.type}.distanceActual`)}
                                wrapClass={"green-input"}
                                suffix={item.type != "swim" ? " km" : " m"}
                                defer={1000}
                            />
                        }
                    </InputTableCell>

                    <InputTableCell>

                        {item.type != "gym" && item.type != "other" && item.type != "bricks" && item.type != "testRace" &&

                            <>

                                {getIcon(get(form.values, `volume.results.${item.type}.distanceTarget`), get(form.values, `volume.results.${item.type}.distanceActual`), calculateTarget(get(form.values, `volume.results.${item.type}.distanceTarget`), get(form.values, `volume.results.${item.type}.distanceActual`), "number"))?.icon}

                                <FieldHidden
                                    form={form}
                                    name={`volume.results.${item.type}.distanceMet`}
                                    value={calculateTarget(get(form.values, `volume.results.${item.type}.distanceTarget`), get(form.values, `volume.results.${item.type}.distanceActual`), "number")}
                                />

                            </>

                        }
                    </InputTableCell>

                    <InputTableCell>
                        {(item.type == "swim" || item.type == "bike" || item.type == "run") &&
                            <CoachingTrainingsIsBest
                                name={`volume.results.${item.type}.distanceActual`}
                            />
                        }
                    </InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            form={form}
                            name={`volume.results.${item.type}.workout`}
                            defaultValue={get(form.values, `volume.results.${item.type}.workout`)}
                            wrapClass={"manual-input"}
                        />
                    </InputTableCell>

                </InputTableRow>

            ))}

            {(totals || []).map((item, index) => {

                return (
                    <InputTableRow key={index}>

                        <DataTableCell>
                            <strong>{item.label}</strong>
                        </DataTableCell>

                        <InputTableCell>

                            <FieldTextTime
                                key={get(form.values, `volume.${item.type}.timeTarget`)}
                                form={form}
                                name={`volume.${item.type}.timeTarget`}
                                defaultValue={get(form.values, `volume.${item.type}.timeTarget`)}
                                disabled
                            />

                        </InputTableCell>

                        <InputTableCell>

                            <FieldTextTime
                                key={get(form.values, `volume.${item.type}.timeActual`)}
                                form={form}
                                name={`volume.${item.type}.timeActual`}
                                defaultValue={get(form.values, `volume.${item.type}.timeActual`)}
                                disabled
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <>
                                {getIcon(get(form.values, `volume.${item.type}.timeTarget`), get(form.values, `volume.${item.type}.timeActual`), calculateTarget(get(form.values, `volume.${item.type}.timeTarget`), get(form.values, `volume.${item.type}.timeActual`)))?.icon}

                                <FieldHidden
                                    form={form}
                                    name={`volume.${item.type}.timeMet`}
                                    value={calculateTarget(get(form.values, `volume.${item.type}.timeTarget`), get(form.values, `volume.${item.type}.timeActual`))}
                                />

                            </>
                        </InputTableCell>

                        <InputTableCell>
                            <CoachingTrainingsIsBest
                                name={`volume.${item.type}.timeActual`}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            {item.type != "total" &&
                                <FieldNumber
                                    dynamicKey
                                    form={form}
                                    name={`volume.${item.type}.distanceTarget`}
                                    defaultValue={get(form.values, `volume.${item.type}.distanceTarget`)}
                                    disabled
                                />
                            }
                        </InputTableCell>

                        <InputTableCell>
                            {item.type != "total" &&
                                <FieldNumber
                                    dynamicKey
                                    form={form}
                                    name={`volume.${item.type}.distanceActual`}
                                    disabled
                                    defaultValue={get(form.values, `volume.${item.type}.distanceActual`)}
                                />
                            }
                        </InputTableCell>

                        <InputTableCell>
                            {item.type != "total" &&

                                <>

                                    {getIcon(get(form.values, `volume.${item.type}.distanceTarget`), get(form.values, `volume.${item.type}.distanceActual`), calculateTarget(get(form.values, `volume.${item.type}.distanceTarget`), get(form.values, `volume.${item.type}.distanceActual`), "number"))?.icon}

                                    <FieldHidden
                                        form={form}
                                        name={`volume.${item.type}.distanceMet`}
                                        value={calculateTarget(get(form.values, `volume.${item.type}.distanceTarget`), get(form.values, `volume.${item.type}.distanceActual`), "number")}
                                    />

                                </>
                            }
                        </InputTableCell>

                        <InputTableCell>
                            <CoachingTrainingsIsBest
                                name={`volume.${item.type}.distanceActual`}
                            />
                        </InputTableCell>

                        <InputTableCell>

                            <FieldNumber
                                allowNegative
                                dynamicValue
                                form={form}
                                name={`volume.${item.type}.workout`}
                                defaultValue={get(form.values, `volume.${item.type}.workout`)}
                                disabled
                            />

                        </InputTableCell>

                    </InputTableRow>

                )
            })}

        </InputTable>

    );

}

const IntensityTable: React.FC<any> = (props: any) => {

    const { data, form, mode } = props;

    const column = [
        {
            key: "type",
            label: <Heading space={false} size={4}>INTENSITY</Heading>,
            style: { width: "80px" },
            className: "table-bg-red"
        },
        {
            style: { width: "80px" },
            key: "target",
            label: "Target",
            desc: "[Target]"
        },
        {
            style: { width: "80px" },
            key: "time",
            label: "Time",
            desc: "[Actual]",
        },
        {
            style: { width: "80px" },
            key: "total",
            label: "%Total",
            desc: "%"
        },
        {
            style: { width: "80px" },
            key: "timeMet",
            label: " ",
            desc: ""
        },
        {
            style: { width: "80px" },
            key: "best",
            label: "Best / Top 5%",
            desc: <>&nbsp;</>,
        },
        {
            style: { width: "80px" },
            key: "hrZone",
            label: "HR Zone 3+",
            desc: "[hh:mm]"
        },
    ];

    const events: any = [
        ...data?.general?.sportList?.map((item) => ({ type: item, label: upperCase(item) })),
        ...["hr"].map((item) => ({ type: item, label: upperCase(item) })),
    ];

    const calculateTotals = (results) => {

        const totals = results.reduce((acc, obj) => {

            obj.target = obj.target ?? 0;
            obj.time = obj.time ?? 0;
            obj.hrZone = obj.hrZone ?? 0;

            return {
                target: getMinutesTime(obj.target) + acc.target,
                time: getMinutesTime(obj.time) + acc.time,
                hrZone: getMinutesTime(obj.hrZone) + acc.hrZone,
            };

        }, { time: 0, target: 0, hrZone: 0 });

        totals.target = getClockTime(totals.target / 60);
        totals.time = getClockTime(totals.time / 60);
        totals.hrZone = getClockTime(totals.hrZone / 60);

        return totals;
    };

    useUpdateEffect(() => {

        let intensity = clone(get(form.values, `intensity.results`, null));
        let volume = clone(get(form.values, `volume.results`, null));

        if (intensity && volume) {

            intensity = intensity;
            volume = volume;

            let filteredIntensity = _.omit(intensity, 'hr')

            const totalsEndurance = calculateTotals(Object.values(filteredIntensity));

            form.handleChange({ reset: true, name: `intensity.totalEndurance.time`, value: totalsEndurance.time });
            // form.handleChange({ reset: true, name: `intensity.totalEndurance.total`, value: totalsEndurance.total });

            let hrZone = 0;
            let totalPercents: any = [];

            loop(events, (result, index) => {

                const key = result?.type;

                if (intensity[key]?.hrZone) hrZone = hrZone + getDecimalTime(volume[key]?.timeActual)

                if (key != "hr") {

                    const percentTotal = fixedNum((getDecimalTime(intensity[key]?.time) / getDecimalTime(volume[key]?.timeActual)) * 100, 0);

                    totalPercents.push(percentTotal);

                    form.handleChange({ reset: true, name: `intensity.results.${result.type}.total`, value: percentTotal });

                }

                if (key == "hr") {

                    const hrPercentTotal = fixedNum((getDecimalTime(totalsEndurance?.hrZone) / hrZone) * 100, 0)

                    form.handleChange({ reset: true, name: `intensity.results.${result.type}.time`, value: totalsEndurance?.hrZone });
                    form.handleChange({ reset: true, name: `intensity.results.${result.type}.total`, value: hrPercentTotal });

                }

            })

            form.handleChange({ reset: true, name: `intensity.totalEndurance.total`, value: getAverage(totalPercents, 0) });

        }

    }, [hashCode([form.values?.intensity?.results, form.values?.volume?.results])]);

    const totals: any = [
        { label: "TOTAL ENDURANCE", type: "totalEndurance" },
    ];

    return (

        <InputTable
            columns={column}
        >

            {(events || []).map((item, index) => (
                <InputTableRow key={index}>

                    <DataTableCell>
                        <strong>{item.label}</strong>
                    </DataTableCell>

                    <InputTableCell>
                        <FieldTextTime
                            dynamicKey
                            form={form}
                            name={`intensity.results.${item.type}.target`}
                            defaultValue={get(form.defaults, `intensity.results.${item.type}.target`)}
                            wrapClass={item.type == "other" ? "manual-input" : ""}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldTextTime
                            dynamicKey={item.type == "hr" ? true : false}
                            form={form}
                            name={`intensity.results.${item.type}.time`}
                            defaultValue={get(form.values, `intensity.results.${item.type}.time`)}
                            wrapClass={item.type != "hr" ? "manual-input" : ""}
                            disabled={item.type == "hr" ? true : false}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            dynamicKey
                            form={form}
                            name={`intensity.results.${item.type}.total`}
                            defaultValue={get(form.defaults, `intensity.results.${item.type}.total`)}
                            allowNegative
                            disabled
                        />
                    </InputTableCell>

                    <DataTableCell>
                        <>
                            {getIcon(get(form.values, `intensity.results.${item.type}.target`), get(form.values, `intensity.results.${item.type}.time`), calculateTarget(get(form.values, `intensity.results.${item.type}.target`), get(form.values, `intensity.results.${item.type}.time`)), "intensity")?.icon}

                            <FieldHidden
                                form={form}
                                name={`intensity.results.${item.type}.timeMet`}
                                value={calculateTarget(get(form.values, `intensity.results.${item.type}.target`), get(form.values, `intensity.results.${item.type}.time`))}
                            />

                        </>
                    </DataTableCell>

                    <InputTableCell>
                        <CoachingTrainingsIsBest
                            name={`intensity.results.${item.type}.time`}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        {item.type != "hr" &&
                            <FieldTextTime
                                form={form}
                                name={`intensity.results.${item.type}.hrZone`}
                                defaultValue={get(form.values, `intensity.results.${item.type}.hrZone`)}
                                wrapClass={"manual-input"}
                            />
                        }
                    </InputTableCell>

                </InputTableRow>

            ))}

            {(totals || []).map((item, index) => (
                <InputTableRow key={index}>

                    <DataTableCell>
                        <strong>{item.label}</strong>
                    </DataTableCell>

                    <InputTableCell>
                        <FieldTextTime
                            dynamicKey
                            form={form}
                            name={`intensity.${item.type}.target`}
                            defaultValue={get(form.defaults, `intensity.${item.type}.target`)}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldTextTime
                            dynamicKey
                            form={form}
                            name={`intensity.${item.type}.time`}
                            defaultValue={get(form.values, `intensity.${item.type}.time`)}
                            disabled
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <FieldNumber
                            dynamicKey
                            allowNegative
                            form={form}
                            name={`intensity.${item.type}.total`}
                            defaultValue={get(form.values, `intensity.${item.type}.total`)}
                            disabled
                        />
                    </InputTableCell>

                    <InputTableCell>
                        <>
                            {getIcon(get(form.values, `intensity.${item.type}.target`), get(form.values, `intensity.${item.type}.time`), calculateTarget(get(form.values, `intensity.${item.type}.target`), get(form.values, `intensity.${item.type}.time`)), "intensity")?.icon}

                            <FieldHidden
                                form={form}
                                name={`intensity.${item.type}.timeMet`}
                                value={calculateTarget(get(form.values, `intensity.${item.type}.target`), get(form.values, `intensity.${item.type}.time`))}
                            />

                        </>
                    </InputTableCell>

                    <InputTableCell>
                        <CoachingTrainingsIsBest
                            name={`intensity.${item.type}.time`}
                        />
                    </InputTableCell>

                    <InputTableCell>
                        {/* <FieldTextTime
                            key={get(form.values, `intensity.${item.type}.hrZone`)}
                            form={form}
                            name={`intensity.${item.type}.hrZone`}
                            disabled
                            defaultValue={get(form.values, `intensity.${item.type}.hrZone`)}
                        /> */}
                    </InputTableCell>

                </InputTableRow>

            ))}

        </InputTable>

    )
}

const OCRTable: React.FC<any> = (props: any) => {

    const { data, form, ocrData } = props;

    const column = [
        {
            key: "other",
            label: <Heading space={false} size={4}>OTHER</Heading>,
            className: "table-bg-blue"
        },
        {
            key: "blank",
        }
    ];

    const events: any = [
        { label: "TSS", type: "tss" },
        { label: "Fitness (CTL)", type: "fitness" },
        { label: "Fatigue (ATL)", type: "fatigue" },
        { label: "Form (TSB)", type: "form" },
        { label: "Elevation Gain", type: "elevation" },
        { label: "Work", type: "work" },
        { label: "Peak HR Run", type: "peakHrRun" },
        { label: "Peak HR Bike", type: "peakHrBike" },
    ];

    useUpdateEffect(() => {

        form.handleChange({ reset: true, silent: true, name: `other.tss`, value: ocrData.TotalTrainingActual })
        form.handleChange({ reset: true, silent: true, name: `other.fitness`, value: ocrData.fitness })
        form.handleChange({ reset: true, silent: true, name: `other.fatigue`, value: ocrData.fatigue })
        form.handleChange({ reset: true, silent: true, name: `other.form`, value: ocrData.form })
        form.handleChange({ reset: true, silent: true, name: `other.elevation`, value: ocrData.ElGain })
        form.handleChange({ reset: true, silent: true, name: `other.work`, value: ocrData.Work })

    }, [ocrData]);

    return (

        <InputTable
            columns={column}
        >

            {(events || []).map((item, index) => (
                <InputTableRow key={index}>

                    <DataTableCell>
                        <strong>{upperCase(item.label)}</strong>
                    </DataTableCell>

                    <InputTableCell>

                        <FieldNumber
                            allowNegative
                            dynamicKey
                            form={form}
                            name={`other.${item.type}`}
                            defaultValue={get(form.values, `other.${item.type}`)}
                            wrapClass={index >= 6 ? "manual-input" : "green-input"}
                        />

                    </InputTableCell>

                </InputTableRow>

            ))}

        </InputTable>

    )
}

export default CoachingTrainingsWeekVolTab
