import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldSelect, FieldText, FieldDate, FieldHidden, FieldNumber, FieldTextTime, InputTable, InputTableCell, InputTableRow, useUpdateEffect, FieldRadio, FieldCheckbox, FieldTextArea, Form, Panel, Button, ButtonRow, Line, Seperator, FieldSwitch } from '@dex/bubl-dash';
import { fixedNum, get, hashCode, loop, set, slug } from '@dex/bubl-helpers';
import { getSwimPace, getPace, getSpeed, getDecimalTime, getClockTime, getMinutesTime } from '../../../functions/sharedTests';
import { getTableRow, glycogenEventList } from '../../../functions/glycogenTest';
import styles from '../../../functions/coaching.module.scss';
import { getArrowDifference } from '../../../functions/sharedCoaching';
import { time } from 'console';

const CoachingEventsForm: React.FC<any> = ( props: any ) => {

    const { form, mode, process, handleSubmit, handleClose, handleDelete } = props;

    return useMemo( () => (

        <Panel
            heading={mode === "edit" ? "Edit Event" : "Add New Event"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <CoachingEventsSingleForm
                    {...props}
                    heading="Pre-event"
                    prefix="pre"
                />

                {form.values.completed == true &&

                    <CoachingEventsSingleForm
                        {...props}
                        heading="Post-event"
                        prefix="post"
                    />

                }

                <Line />

                <ButtonRow>

                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />

                    <Button
                        label={"Cancel"}
                        onClick={handleClose}
                        type='faded'
                        htmlType="button"
                    />

                    {mode == "edit" &&
                        <Button
                            confirm={{
                                title: `Delete The Event Data`,
                                message: "Are your sure you want to perform this action?"
                            }}
                            onClick={handleDelete.run}
                            label="Delete"
                            type='faded'
                        />
                    }

                </ButtonRow>

            </Form>

        </Panel>

    ), [ form.hash, process.loading ] );

}

const CoachingEventsSingleForm: React.FC<any> = ( props: any ) => {

    const { form, data, prefix, process, handleSubmit, heading } = props;

    const event = get( form.values, `type` );
    const events = event ? getTableRow( event ) : [];

    useUpdateEffect( () => {

        const activities: any = [];

        loop( events, ( row, index ) => {

            activities[ index ] = { distance: row.distance };

        } );

        form.handleChange( { name: `${ prefix }.activities`, value: activities, reset: true } );

    }, [ event ] );

    return (

        <Row gutter={8} edge>

            <Col col={{ xs: 24 }}>
                <Seperator heading={heading} />
            </Col>

            {heading == "Pre-event" &&

                <>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldSelect
                            form={form}
                            name={`type`}
                            defaultValue={get( form.values, `type` )}
                            key={get( form.values, `type` )}
                            label='Event Type'
                            options={glycogenEventList}
                            required
                            wrapClass={"manual-input"}
                        />
                    </Col>

                    <Col col={{ xs: 12, md: 6 }}>
                        <FieldText
                            form={form}
                            name={`name`}
                            defaultValue={get( form.values, `name` )}
                            key={get( form.values, `name` )}
                            label='Event Name'
                            required
                            wrapClass={"manual-input"}
                        />
                    </Col>

                    <Col col={{ xs: 12, md: 6 }}>
                        <FieldDate
                            form={form}
                            name={`date`}
                            defaultValue={get( form.values, `date` )}
                            key={get( form.values, `date` )}
                            label='Date'
                            required
                            wrapClass={"manual-input"}
                        />
                    </Col>

                    <Col col={{ xs: 8, md: 3 }}>

                        <FieldSelect
                            form={form}
                            name={`rating`}
                            defaultValue={get( form.values, `rating` )}
                            key={get( form.values, `rating` )}
                            wrapClass={'manual-input'}
                            label='Rating'
                            options={[
                                { label: "★", value: 1 },
                                { label: "★★", value: 2 },
                                { label: "★★★", value: 3 },
                                { label: "★★★★", value: 4 },
                                { label: "★★★★★", value: 5 }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 4, md: 3 }}>

                        <FieldSwitch
                            form={form}
                            name={`completed`}
                            defaultValue={get( form.defaults, `completed` )}
                            label='Completed?'
                        />

                    </Col>
                </>
            }

            <Col col={{ xs: 24 }}>

                <Space />

                <CalculationTable
                    key={event}
                    event={event}
                    events={events}
                    form={form}
                    prefix={prefix}
                    heading={heading}
                />

            </Col>

            <Col col={{ xs: 18 }}>

                <FieldTextArea
                    form={form}
                    name={`${ prefix }.comment`}
                    defaultValue={get( form.defaults, `${ prefix }.comment` )}
                    label={heading == "Pre-event" ? 'Pre-event Comment' : 'Post-event Comment'}
                    wrapClass={"manual-input"}
                    row={4}
                />

            </Col>

            <Col col={{ xs: 12, md: 6 }}>

                {heading != "Pre-event" ?

                    <FieldText
                        form={form}
                        name={`${ prefix }.finishPosition`}
                        defaultValue={get( form.defaults, `${ prefix }.finishPosition` )}
                        label='Finish Position'
                        wrapClass={"manual-input"}
                    />
                    :
                    <FieldText
                        form={form}
                        name={`${ prefix }.goal`}
                        defaultValue={get( form.defaults, `${ prefix }.goal` )}
                        label='Goal'
                        wrapClass={"manual-input"}
                    />
                }

            </Col>

        </Row>
    )

}

const CalculationTable: React.FC<any> = ( props: any ) => {

    const { event, events, form, prefix, heading } = props;

    const column: any = [
        {
            key: "type",
            style: { width: "120px" }
        },
        heading == "Post-event" ? {
            key: "arrow",
            style: { width: "40" }
        } : null,
        {
            key: "distance",
            label: "Distance",
        },
        {
            key: "time",
            label: "Time"
        },
        {
            key: "pace",
            label: "Pace"
        },
        {
            key: "speed",
            label: "Speed"
        },
        {
            key: "power",
            label: "Power",
            desc: "Raw"
        },
        {
            key: "power",
            label: "Power",
            desc: "Norm"
        },
        {
            key: "HR",
            label: "HR",
            desc: "Avg"
        },
        {
            key: "HR",
            label: "HR",
            desc: "Peak"
        },
        {
            key: "Hydration",
            label: "Hydration",
            desc: "(L)"
        },
        {
            key: "Carb Fueling",
            label: "Carb Fueling",
            desc: "(g)"
        },
    ].filter( Boolean );

    const calculatePace = ( type, distance, time ) => {

        if ( !distance || !time ) return 0

        if ( type == "Swim" ) {
            return getSwimPace( distance, time )
        }

        return getPace( distance, time )

    };

    const calculateSpeed = ( distance, time ) => {

        if ( !distance || !time ) return 0

        return getSpeed( distance, time )

    };

    function addTimes( time1, time2 ) {

        if ( !time1 && !time2 ) return "00:00:00";
        if ( !time1 ) return time2;

        function timeToSeconds( time ) {
            const [ hours, minutes, seconds ] = time.split( ':' ).map( Number );
            return hours * 3600 + minutes * 60 + seconds;
        }

        function secondsToTime( seconds ) {
            const hours = Math.floor( seconds / 3600 ).toString().padStart( 2, '0' );
            const minutes = Math.floor( ( seconds % 3600 ) / 60 ).toString().padStart( 2, '0' );
            const secs = ( seconds % 60 ).toString().padStart( 2, '0' );
            return `${ hours }:${ minutes }:${ secs }`;
        }

        const totalSeconds = timeToSeconds( time1 ) + timeToSeconds( time2 );

        return secondsToTime( totalSeconds );
    }

    useUpdateEffect( () => {

        const activities = get( form.values, `${ prefix }.activities` );

        const filteredActivities = ( activities || [] ).filter( activity => activity.activity !== 'Transition' );

        let hrAvgLength = 0;
        let hrPeakLength = 0;

        const hydration = get( form.values, `${ prefix }.${ slug( heading ) }.hydration` );
        const fueling = get( form.values, `${ prefix }.${ slug( heading ) }.fueling` );

        const overalls = activities.reduce( ( acc, obj ) => {

            if ( !obj.distance ) obj.distance = 0;
            if ( !obj.speed ) obj.speed = 0;
            if ( !obj.time ) obj.time = "00:00:00";
            if ( !obj.pace ) obj.pace = 0;
            if ( !obj.hrAvg ) obj.hrAvg = 0;
            if ( !obj.hrPeak ) obj.hrPeak = 0;
            if ( !obj.fueling ) obj.fueling = 0;
            if ( !obj.hydration ) obj.hydration = 0;

            if ( obj.hrAvg ) hrAvgLength++;
            if ( obj.hrPeak ) hrPeakLength++;

            return {
                distance: parseFloat( acc.distance ) + parseFloat( obj.distance ),
                speed: fixedNum( parseFloat( acc.speed ) + parseFloat( obj.speed ), 2 ),
                time: addTimes( acc.time, obj.time ),
                hrAvg: parseFloat( acc.hrAvg ) + parseFloat( obj.hrAvg ),
                hrPeak: Math.max( acc.hrPeak, parseFloat( obj.hrPeak ) ),
                fueling: parseFloat( acc.fueling ) + parseFloat( obj.fueling ),
                hydration: parseFloat( acc.hydration ) + parseFloat( obj.hydration ),
            };
        }, { pace: 0, distance: 0, time: 0, speed: 0, hrAvg: 0, hrPeak: 0, fueling: 0, hydration: 0 } );

        overalls.distance = overalls.distance ? fixedNum( overalls.distance, 0 ) : "";
        overalls.hrAvg = hrAvgLength ? fixedNum( overalls.hrAvg / hrAvgLength, 0 ) : "";
        overalls.hrPeak = overalls.hrPeak ? fixedNum( overalls.hrPeak, 0 ) : "";
        overalls.time = overalls.time ? overalls.time : "";
        overalls.speed = ( overalls.distance && overalls.time ) ? getSpeed( overalls.distance, overalls.time ) : "";
        overalls.pace = ( overalls.distance && overalls.time ) ? getPace( overalls.distance, overalls.time ) : "";

        total.fueling = overalls.fueling ? fixedNum( overalls.fueling + fueling, 0 ) : "";
        total.hydration = overalls.hydration ? fixedNum( overalls.hydration + hydration, 0 ) : "";

        overalls.fueling = fixedNum( total.fueling / getDecimalTime( overalls.time ), 0 )
        overalls.hydration = fixedNum( total.hydration / getDecimalTime( overalls.time ), 0 )

        form.handleChange( { reset: true, name: `${ prefix }.overall`, value: overalls } );
        form.handleChange( { reset: true, name: `${ prefix }.total`, value: total } );

    }, [ hashCode( get( form.values, `${ prefix }.activities`, [] ) ), hashCode( get( form.values, `${ prefix }.${ slug( heading ) }`, [] ) ) ] );

    const overall = get( form.values, `${ prefix }.overall`, {} );
    const total = get( form.values, `${ prefix }.total`, {} );

    let value;
    const eventsLength = events?.length;

    useUpdateEffect( () => {

        const preEvent = get( form.values, `pre` )

        loop( preEvent?.activities, ( row, i ) => {
            form.handleChange( { name: `post.activities[${ i }].distance`, value: row.distance, reset: true } )
        } )

    }, [ get( form.values, `completed` ) ] );

    return (

        <InputTable
            columns={column}
            key={event}
        >

            <InputTableRow>

                <InputTableCell colSpan={heading == "Post-event" ? 9 : 8} />

                <InputTableCell>
                    <strong>Pre-Race Meal</strong>
                </InputTableCell>

                <InputTableCell>
                    <FieldNumber
                        form={form}
                        name={`${ prefix }.${ slug( heading ) }.hydration`}
                        defaultValue={get( form.defaults, `${ prefix }.${ slug( heading ) }.hydration` )}
                        wrapClass={"manual-input"}
                        suffix=" ml"
                    />
                </InputTableCell>

                <InputTableCell>
                    <FieldNumber
                        form={form}
                        name={`${ prefix }.${ slug( heading ) }.fueling`}
                        defaultValue={get( form.defaults, `${ prefix }.${ slug( heading ) }.fueling` )}
                        wrapClass={"manual-input"}
                        suffix=" g"
                    />
                </InputTableCell>

            </InputTableRow>

            {events.map( ( item, index ) => {

                const distance = get( form.values, `${ prefix }.activities[${ index }].distance` );
                const time = get( form.values, `${ prefix }.activities[${ index }].time` );

                const pace = calculatePace( item.type, distance, time );
                const speed = calculateSpeed( distance, time );

                const power = ""; //todo need formula from patrick

                return (
                    <InputTableRow>

                        <InputTableCell>
                            <strong>{item.type}</strong>
                            <FieldHidden
                                form={form}
                                name={`${ prefix }.activities[${ index }].activity`}
                                value={item.type}
                            />
                        </InputTableCell>

                        {heading == "Post-event" &&
                            <InputTableCell>
                                {getArrowDifference( get( form.values, `pre.activities[${ index }].time` ), get( form.values, `post.activities[${ index }].time` ) )}
                            </InputTableCell>
                        }

                        <InputTableCell>
                            <FieldNumber
                                dynamicKey
                                defer={500}
                                form={form}
                                wrapClass={"manual-input"}
                                suffix=" km"
                                name={`${ prefix }.activities[${ index }].distance`}
                                defaultValue={get( form.defaults, `${ prefix }.activities[${ index }].distance` )}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldTextTime
                                form={form}
                                name={`${ prefix }.activities[${ index }].time`}
                                defaultValue={get( form.defaults, `${ prefix }.activities[${ index }].time` )}
                                placeholder={"hh:mm:ss"}
                                wrapClass={"manual-input"}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldText
                                dynamicKey
                                form={form}
                                disabled
                                hidden
                                name={`${ prefix }.activities[${ index }].pace`}
                                defaultValue={pace + ( item.type == "Swim" ? " min/100m" : " min/km" )}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                dynamicKey
                                form={form}
                                disabled
                                hidden
                                suffix=" km/hr"
                                name={`${ prefix }.activities[${ index }].speed`}
                                defaultValue={speed}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            {item.type == "Bike" &&
                                <FieldNumber
                                    form={form}
                                    suffix=" W"
                                    name={`${ prefix }.activities[${ index }].powerRaw`}
                                    defaultValue={get( form.defaults, `${ prefix }.activities[${ index }].powerRaw` )}
                                    wrapClass={"manual-input"}
                                />
                            }
                        </InputTableCell>

                        <InputTableCell>
                            {item.type == "Bike" &&
                                <FieldNumber
                                    form={form}
                                    suffix=" W"
                                    name={`${ prefix }.activities[${ index }].powerNorm`}
                                    defaultValue={get( form.defaults, `${ prefix }.activities[${ index }].powerNorm` )}
                                    wrapClass={"manual-input"}
                                />
                            }
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                suffix=" bpm"
                                name={`${ prefix }.activities[${ index }].hrAvg`}
                                defaultValue={get( form.defaults, `${ prefix }.activities[${ index }].hrAvg` )}
                                wrapClass={"manual-input"}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                suffix=" bpm"
                                name={`${ prefix }.activities[${ index }].hrPeak`}
                                defaultValue={get( form.defaults, `${ prefix }.activities[${ index }].hrPeak` )}
                                wrapClass={"manual-input"}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`${ prefix }.activities[${ index }].hydration`}
                                defaultValue={get( form.defaults, `${ prefix }.activities[${ index }].hydration` )}
                                wrapClass={"manual-input"}
                                suffix=" ml"
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`${ prefix }.activities[${ index }].fueling`}
                                defaultValue={get( form.defaults, `${ prefix }.activities[${ index }].fueling` )}
                                wrapClass={"manual-input"}
                                suffix=" g"
                            />
                        </InputTableCell>

                    </InputTableRow>
                )
            } )}

            {events.length > 1 ?

                <>

                    <InputTableRow>

                        <InputTableCell>
                            <strong>{value = "Transition"}</strong>
                            <FieldHidden
                                form={form}
                                name={`${ prefix }.activities[${ eventsLength }].activity`}
                                value={value}
                            />
                        </InputTableCell>

                        {heading == "Post-event" &&
                            <InputTableCell />
                        }

                        <InputTableCell />

                        <InputTableCell>
                            <FieldTextTime
                                form={form}
                                name={`${ prefix }.activities[${ eventsLength }].time`}
                                defaultValue={get( form.defaults, `${ prefix }.activities[${ eventsLength }].time` )}
                                placeholder={"hh:mm:ss"}
                                wrapClass={"manual-input"}
                            />
                        </InputTableCell>

                        <InputTableCell colSpan={8} />

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell />

                        {heading == "Post-event" &&
                            <InputTableCell />
                        }

                        <InputTableCell colSpan={7} />

                        <InputTableCell >
                            <strong>Total Nutrition:</strong>
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`${ prefix }.total.hydration`}
                                defaultValue={total.hydration}
                                wrapClass={"manual-input"}
                                dynamicKey
                                suffix=" ml"
                                disabled
                                hidden
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                dynamicKey
                                suffix=" g"
                                disabled
                                hidden
                                form={form}
                                name={`${ prefix }.total.fueling`}
                                defaultValue={total.fueling}
                                wrapClass={"manual-input"}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow
                        key={hashCode( overall )}
                    >

                        <InputTableCell>
                            <strong>Overall</strong>
                            <FieldHidden
                                form={form}
                                name={`${ prefix }.overall.activity`}
                                value={'Overall'}
                            />
                        </InputTableCell>

                        {heading == "Post-event" &&
                            <InputTableCell>
                                {getArrowDifference( get( form.defaults, `pre.overall.time` ), overall?.time )}
                            </InputTableCell>
                        }

                        <InputTableCell>
                            <FieldNumber
                                dynamicKey
                                form={form}
                                suffix=" km"
                                name={`${ prefix }.overall.distance`}
                                disabled
                                hidden
                                defaultValue={overall.distance}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldTextTime
                                dynamicKey
                                form={form}
                                name={`${ prefix }.overall.time`}
                                placeholder={"hh:mm:ss"}
                                disabled
                                defaultValue={overall?.time}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldText
                                dynamicKey
                                form={form}
                                disabled
                                name={`${ prefix }.overall.pace`}
                                defaultValue={overall.pace + " min/km"}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                dynamicKey
                                form={form}
                                disabled
                                suffix=" km/hr"
                                name={`${ prefix }.overall.speed`}
                                defaultValue={overall.speed}
                            />
                        </InputTableCell>

                        <InputTableCell colSpan={2} />

                        <InputTableCell>
                            <FieldNumber
                                dynamicKey
                                form={form}
                                suffix=" bpm"
                                name={`${ prefix }.overall.hrAvg`}
                                disabled
                                hidden
                                defaultValue={overall.hrAvg}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                dynamicKey
                                form={form}
                                suffix=" bpm"
                                name={`${ prefix }.overall.hrPeak`}
                                disabled
                                hidden
                                defaultValue={overall.hrPeak}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`${ prefix }.overall.hydration`}
                                defaultValue={overall.hydration}
                                wrapClass={"manual-input"}
                                dynamicKey
                                suffix=" ml/hr"
                                disabled
                                hidden
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                dynamicKey
                                suffix=" g/hr"
                                disabled
                                hidden
                                form={form}
                                name={`${ prefix }.overall.fueling`}
                                defaultValue={overall.fueling}
                                wrapClass={"manual-input"}
                            />
                        </InputTableCell>

                    </InputTableRow>

                </>
                :
                < >

                    <FieldHidden
                        form={form}
                        name={`${ prefix }.overall.activity`}
                        value={'Overall'}
                    />

                    <FieldHidden
                        form={form}
                        name={`${ prefix }.overall.distance`}
                        value={overall.distance}
                    />

                    <FieldHidden
                        form={form}
                        name={`${ prefix }.overall.time`}
                        value={overall?.time}
                    />

                    <FieldHidden
                        form={form}
                        name={`${ prefix }.overall.pace`}
                        value={overall.pace + " min/km"}
                    />

                    <FieldHidden
                        form={form}
                        name={`${ prefix }.overall.speed`}
                        value={overall.speed}
                    />

                    <FieldHidden
                        form={form}
                        name={`${ prefix }.overall.hrAvg`}
                        value={overall.hrAvg}
                    />

                    <FieldHidden
                        form={form}
                        name={`${ prefix }.overall.hrPeak`}
                        value={overall.hrPeak}
                    />

                    <FieldHidden
                        form={form}
                        name={`${ prefix }.overall.hydration`}
                        value={get( form.defaults, `${ prefix }.overall.hydration` )}
                    />

                    <FieldHidden
                        form={form}
                        name={`${ prefix }.overall.fueling`}
                        value={get( form.defaults, `${ prefix }.overall.fueling` )}
                    />

                </>
            }

        </InputTable>
    )
}

export default CoachingEventsForm;
