import { Box, Panel, PanelTabs, Space } from '@dex/bubl-dash';
import React from 'react';

import styles from "./PanelFieldReport.module.scss";
import FieldResultsHeader from '../FieldResultsHeader/FieldResultsHeader';

const PanelFieldReport: React.FC<PanelFieldReportProps> = (props) => {

    let { header, tabs, setTab, tab } = props;

    return (

        <>

            <Panel
                className={[styles.panel, "report-page"].join(" ")}
                heading={false}
                full={true}
            >

                <FieldResultsHeader
                    {...header}
                />

                {tabs &&
                    <PanelTabs
                        tabs={tabs}
                        activeTab={tab}
                        setTab={setTab}
                    />
                }

                <Box gutter={"auto"}>

                    {props.children}

                </Box>

            </Panel>

            <Space />

        </>

    )

}

interface PanelFieldReportProps {
    [key: string]: any,
}

export default PanelFieldReport;