import React, { useCallback, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, FieldCheckbox, FieldTextArea, FieldWrap, Line, useApp, useMountEffect } from '@dex/bubl-dash';
import { get, loop, set } from '@dex/bubl-helpers';
import nl2br from 'react-nl2br';
import moment from 'moment';

const CoachingTrainingsWeekCommentsTab: React.FC<any> = (props: any) => {

    const { form, data, handleSubmit, mode } = props;

    const app = useApp();

    const [settings] = app.store("appSettings");

    const useDefaultText = useCallback((defaultName, name: string) => {

        let text = get(settings.coaching, defaultName);

        const values = form.values;

        set(values, name, text);

        form.reset(values);

    }, [props, form.hash]);

    const lastweek = moment(form.values?.week?.startDate).subtract(1, 'days').format('YYYY-MM-DD');

    const [previousWeek, setPreviousWeek] = useState<any>(null);
    const [showPreviousWeek, setShowPreviousWeek] = useState(false);

    useMountEffect(() => {

        loop(data?.weeks, (week) => {

            if (lastweek === week?.label) {

                setPreviousWeek(week);

            }
        });

    });

    return (

        <>
            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 18 }}>

                    <FieldCheckbox
                        form={form}
                        name='comments.trainingDistruption'
                        defaultValue={form.defaults.comments?.trainingDistruption}
                        label='Training Distruptions'
                        options={[
                            { label: "Sick/Injured", value: "Sick/Injured" },
                            { label: "Travel", value: "Travel" },
                            { label: "Work", value: "Work" },
                            { label: "None", value: "None" },
                        ]}
                    />

                </Col>

                {previousWeek &&
                    <Col col={{ xs: 24, md: 6 }} justify='end'>

                        <FieldWrap>
                            <Button
                                label={showPreviousWeek ? 'Hide Previous Weeks Comments' : 'Show Previous Weeks Comments'}
                                onClick={setShowPreviousWeek.bind(null, !showPreviousWeek)}
                                type={'light'}
                                size="small"
                            />
                        </FieldWrap>

                    </Col>
                }

                {showPreviousWeek && previousWeek &&
                    <Col col={{ xs: 24, lg: 20 }}>


                        <FieldWrap
                            label={`Session Summary: ${previousWeek.label}`}
                        >
                            {nl2br(previousWeek.comments?.pastWeek)}
                        </FieldWrap>

                    </Col>
                }

                <Col col={{ xs: 24, lg: 20 }}>

                    <FieldTextArea
                        form={form}
                        label={"Session Summary"}
                        name={'comments.pastWeek'}
                        required={false}
                        defaultValue={form.defaults.comments?.pastWeek}
                        rows={14}
                        maxLength={10000}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 4 }}>

                    <Space />

                    <Row edge={true}>

                        <Button
                            label={'Use Default Text'}
                            onClick={useDefaultText.bind(null, `pastWeek`, "comments.pastWeek")}
                            type={'faded'}
                            confirm={form.values.comments?.pastWeek ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' } : undefined}
                            size="small"
                        />

                    </Row>

                    <Space />

                </Col>

                <Col col={{ xs: 24 }}>
                    <Line space={false} />
                </Col>

                {showPreviousWeek && previousWeek &&
                    <Col col={{ xs: 24, lg: 20 }}>


                        <FieldWrap
                            label={`Going Forward: ${previousWeek.label}`}
                        >
                            {nl2br(previousWeek.comments?.upcomingWeek)}
                        </FieldWrap>

                    </Col>
                }

                <Col col={{ xs: 24, lg: 20 }}>

                    <FieldTextArea
                        form={form}
                        label={"Going Forward"}
                        name={'comments.upcomingWeek'}
                        required={false}
                        defaultValue={form.defaults.comments?.upcomingWeek}
                        rows={14}
                        maxLength={5000}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 4 }}>

                    <Space />

                    <Row edge={true}>

                        <Button
                            label={'Use Default Text'}
                            onClick={useDefaultText.bind(null, "upcomingWeek", "comments.upcomingWeek")}
                            type={'faded'}
                            confirm={form.values.comments?.upcomingWeek ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' } : undefined}
                            size="small"
                        />

                    </Row>

                    <Space />

                </Col>

            </Row>
        </>

    );

}

export default CoachingTrainingsWeekCommentsTab;
