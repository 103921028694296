import React, { useEffect, useState } from 'react';
import styles from "./RaceReadyResultTable.module.scss";
import { Col, FieldHidden, FieldNumber, FieldRadio, FieldTextTime, InputTable, InputTableCell, InputTableRow, Row, Space, useMountEffect, useUpdateEffect } from '@dex/bubl-dash';
import { clone, fixedNum, get, hashCode, loop, parseNum, titleCase } from '@dex/bubl-helpers';
import { labTestGetEffortData, labTestSumData } from '../../functions/labTestData';
import { getRangeOutput, withinRange, withinRangeCurrent } from '../../functions/raceReadyTest';
import RaceReadyLegend from '../RaceReadyLegend/RaceReadyLegend';
import { getClockTime } from '../../functions/sharedTests';

const RaceReadyResultTable: React.FC<RaceReadyResultTableProps> = (props) => {

    const { form, data, activity, session, targets } = props;

    const effortData = clone(form?.values?.avgData || []);

    const [key, setKey] = useState(0);

    const unit = get(form.values, "result.unit", "g/hr");

    const gender = session.athlete.gender || "male";

    const sumRawData = (duration) => {

        const rawData = clone(form?.values?.rawData || []);

        const effortData = labTestGetEffortData(rawData);

        loop(effortData, (effort, effortIndex) => {

            const endTime = effort[effort.length - 1]?.timeM;
            const maxTime = endTime - duration;

            loop(effort, (stage, stageIndex) => {

                if (stage.timeM < maxTime) {
                    delete (effort[stageIndex]);
                }

            });

            effort = effort.filter(Boolean);

            const sum = labTestSumData(effort, {});

            form.handleChange({ silent: true, reset: true, name: `result.sum.ftp[${effortIndex}]`, value: activity == "Running" ? sum.speed?.avg : sum.power_raw?.avg });
            form.handleChange({ silent: true, reset: true, name: `result.sum.ftpKg[${effortIndex}]`, value: activity == "Running" ? sum.calc_speed_kg?.avg : sum.calc_power_raw_kg?.avg });
            form.handleChange({ silent: true, reset: true, name: `result.sum.pace[${effortIndex}]`, value: getClockTime(sum.calc_pace?.avg / 60) });
            form.handleChange({ silent: true, reset: true, name: `result.sum.vo2relative[${effortIndex}]`, value: sum.vo2relative?.avg });
            form.handleChange({ silent: true, reset: true, name: `result.sum.vo2absolute[${effortIndex}]`, value: sum.vo2absolute?.avg });
            form.handleChange({ silent: true, reset: true, name: `result.sum.hr[${effortIndex}]`, value: sum.hr?.avg });
            form.handleChange({ silent: true, reset: true, name: `result.sum.smo2[${effortIndex}]`, value: sum.smo2_1?.avg });

            form.handleChange({ silent: true, reset: true, name: `result.sum.fatBurnG[${effortIndex}]`, value: sum.fat?.avg });
            form.handleChange({ silent: true, reset: true, name: `result.sum.fatBurnKcal[${effortIndex}]`, value: sum.eefat?.avg });

            form.handleChange({ silent: true, reset: true, name: `result.sum.carbBurnG[${effortIndex}]`, value: sum.cho?.avg });
            form.handleChange({ silent: true, reset: true, name: `result.sum.carbBurnKcal[${effortIndex}]`, value: sum.eecho?.avg });

            form.handleChange({ silent: true, reset: true, name: `result.sum.beRate[${effortIndex}]`, value: sum.rf?.avg });
            form.handleChange({ silent: true, reset: true, name: `result.sum.beVolume[${effortIndex}]`, value: sum.tvl?.avg });

        });

    }

    const sumAvgData = (duration) => {

        const effortData = clone(form?.values?.avgData || []);

        loop(effortData, (effort, effortIndex) => {

            const endTime = effort[effort.length - 1]?.timeM;
            const maxTime = endTime - duration;

            loop(effort, (stage, stageIndex) => {

                if (stage.timeM < maxTime) {
                    delete (effort[stageIndex]);
                }

            });

            effort = effort.filter(Boolean);

            const sum = labTestSumData(effort, {});

            form.handleChange({ silent: true, reset: true, name: `result.sum.lactate[${effortIndex}]`, value: fixedNum(sum.lactate?.avg, 1) });
            form.handleChange({ silent: true, reset: true, name: `result.sum.vla[${effortIndex}]`, value: fixedNum(sum.vla?.avg, 1) });
            form.handleChange({ silent: true, reset: true, name: `result.sum.rpe[${effortIndex}]`, value: fixedNum(sum.rpe?.avg, 1) });
            form.handleChange({ silent: true, reset: true, name: `result.sum.strideFrequency[${effortIndex}]`, value: fixedNum(sum.strideFrequency?.avg, 0) });
            form.handleChange({ silent: true, reset: true, name: `result.sum.strideLength[${effortIndex}]`, value: fixedNum(sum.strideLength?.avg, 2) });

        });

    }

    useEffect(() => {

        const duration = form.values.result?.averageOverLast || 10; // from minutes to seconds

        sumRawData(duration);
        sumAvgData(duration);

        setKey((key) => key + 1);

    }, [form.values.result?.averageOverLast, unit]);

    useUpdateEffect(() => {

        const percent = get(form.values, `result.sum.percentCarbRefuel`, []);

        const burnRateG = get(form.values, `result.sum.carbBurnG`);
        const burnRateKcal = get(form.values, `result.sum.carbBurnKcal`);

        loop(effortData, (effort, effortIndex) => {

            const calculateCarb = (carb, percent) => {

                if (!carb || !percent) return;

                return fixedNum(carb * (percent / 100), 0)
            }

            const refuelG = calculateCarb(get(burnRateG, `[${effortIndex}]`), get(percent, `[${effortIndex}]`));
            const refuelKcal = calculateCarb(get(burnRateKcal, `[${effortIndex}]`), get(percent, `[${effortIndex}]`));

            form.handleChange({ silent: true, reset: true, name: `result.sum.carbRefuelG[${effortIndex}]`, value: refuelG });
            form.handleChange({ silent: true, reset: true, name: `result.sum.carbRefuelKcal[${effortIndex}]`, value: refuelKcal });

        });

    }, [key, unit, hashCode(get(form.values, `result.sum.percentCarbRefuel`))]);



    return (

        <Row gutter={"auto"} edge>

            <Col col={{ xs: 8 }}>

                <FieldNumber
                    form={form}
                    name={`result.averageOverLast`}
                    label='Average Over Last'
                    defaultValue={get(form.defaults, `result.averageOverLast`, 10)}
                    wrapClass={"manual-input w-200"}
                    suffix=" min"
                />

            </Col>

            <Col col={{ xs: 16 }} justify='end'>
                <RaceReadyLegend
                    justify="end"
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <InputTable
                    key={key}
                    columns={[
                        { colSpan: 2 },
                        ...(effortData || []).map((item, index) => ({
                            key: index,
                            label: `Effort ${index + 1}`,
                            desc: <>Measure &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Results</>,
                            colSpan: 2,
                            alignMiddle: true,
                            className: `table-bdr-left-gray ${index == 1 ? "table-bg-blue" : "table-bg-green"}`,
                            style: { width: 300 }
                        })),
                        {
                            label: "Target",
                            alignMiddle: true,
                            className: "table-bdr-left-gray ",
                            desc: titleCase(form.values.manual?.level)
                        }
                    ]}
                >

                    <InputTableRow key={key}>

                        <InputTableCell rowSpan={2}><strong>{activity == "Cycling" ? "Power" : "Speed"}</strong></InputTableCell>

                        <InputTableCell>{activity == "Cycling" ? "W" : "kph"}</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell
                                    style={{ width: 150 }}
                                    className="table-bdr-left-gray"
                                >

                                    <FieldNumber
                                        form={form}
                                        name={`result.sum.ftp[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.ftp[${index}]`)}
                                        disabled
                                        hidden
                                        suffix={activity == "Cycling" ? " W" : " kph"}
                                        wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                    />

                                </InputTableCell>

                                <InputTableCell
                                    style={{ width: 150 }}
                                    alignMiddle
                                >
                                    {withinRangeCurrent("%FTP", 'ftp', get(form.values, `result.sum.ftp[${index}]`), index, form, targets, gender, activity, data)}
                                </InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell
                            style={{ width: 150 }}
                            className="table-bdr-left-gray"
                            alignMiddle
                        >
                            {getRangeOutput("%FTP", targets, gender, activity, form.values)}
                        </InputTableCell>

                        <FieldHidden
                            form={form}
                            name={`result.target.${activity == "Cycling" ? "Power" : "Speed"}`}
                            value={getRangeOutput("%FTP", targets, gender, activity, form.values)}
                        />

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>Pace</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldTextTime
                                        form={form}
                                        name={`result.sum.pace[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.pace[${index}]`)}
                                        disabled
                                        hidden
                                        wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                    />

                                </InputTableCell>

                                <InputTableCell />

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" />

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell rowSpan={2}><strong>VO2</strong></InputTableCell>

                        <InputTableCell>VO2</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        form={form}
                                        name={`result.sum.vo2absolute[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.vo2absolute[${index}]`)}
                                        disabled
                                        hidden
                                        suffix=" l/min"
                                        wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                    />

                                </InputTableCell>

                                <InputTableCell />

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" />

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>VO2/kg</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        form={form}
                                        name={`result.sum.vo2relative[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.vo2relative[${index}]`)}
                                        disabled
                                        hidden
                                        suffix=" ml/kg*min"
                                        wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                    />

                                </InputTableCell>

                                <InputTableCell alignMiddle>{withinRangeCurrent("%VO2max", 'vo2maxVo2', get(form.values, `result.sum.vo2relative[${index}]`), index, form, targets, gender, activity, data)}</InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>
                            {getRangeOutput("%VO2max", targets, gender, activity, form.values)}
                        </InputTableCell>

                        <FieldHidden
                            form={form}
                            name={`result.target.VO2`}
                            value={getRangeOutput("%VO2max", targets, gender, activity, form.values)}
                        />

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell><strong>HR</strong></InputTableCell>

                        <InputTableCell>Avg</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        form={form}
                                        name={`result.sum.hr[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.hr[${index}]`)}
                                        disabled
                                        hidden
                                        suffix=" bpm"
                                        wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                    />

                                </InputTableCell>

                                <InputTableCell alignMiddle>{withinRangeCurrent("%HRmax", 'vo2maxHr', get(form.values, `result.sum.hr[${index}]`), index, form, targets, gender, activity, data)}</InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>{getRangeOutput("%HRmax", targets, gender, activity, form.values)}</InputTableCell>

                        <FieldHidden
                            form={form}
                            name={`result.target.HR`}
                            value={getRangeOutput("%HRmax", targets, gender, activity, form.values)}
                        />

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell rowSpan={2}><strong>Lactate</strong></InputTableCell>

                        <InputTableCell>Lac</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        form={form}
                                        name={`result.sum.lactate[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.lactate[${index}]`)}
                                        disabled
                                        hidden
                                        suffix=" mmol/L"
                                        wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                    />

                                </InputTableCell>

                                <InputTableCell alignMiddle>{withinRange("Lactate", get(form.values, `result.sum.lactate[${index}]`), targets, gender, activity, data)}</InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>{getRangeOutput("Lactate", targets, gender, activity, form.values)}</InputTableCell>

                        <FieldHidden
                            form={form}
                            name={`result.target.Lactate`}
                            value={getRangeOutput("Lactate", targets, gender, activity, form.values)}
                        />

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>VLA</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        form={form}
                                        name={`result.sum.vla[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.vla[${index}]`)}
                                        disabled
                                        hidden
                                        suffix=" mmol/L/min"
                                        allowNegative
                                        wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                    />

                                </InputTableCell>

                                <InputTableCell alignMiddle>{withinRange("Lactate VLA", get(form.values, `result.sum.vla[${index}]`), targets, gender, activity, data)}</InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>{getRangeOutput("Lactate VLA", targets, gender, activity, form.values)}</InputTableCell>

                        <FieldHidden
                            form={form}
                            name={`result.target.VLA`}
                            value={getRangeOutput("Lactate VLA", targets, gender, activity, form.values)}
                        />

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell><strong>SMO2</strong></InputTableCell>

                        <InputTableCell>Avg</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        form={form}
                                        name={`result.sum.smo2[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.smo2[${index}]`)}
                                        hidden
                                        disabled
                                        suffix=" %"
                                        wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                    />

                                </InputTableCell>

                                <InputTableCell></InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>Individual</InputTableCell>

                        <FieldHidden
                            form={form}
                            name={`result.target.SMO2`}
                            value='Individual'
                        />

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell rowSpan={4}>

                            <strong>Energy</strong>

                            <Space />

                            <FieldRadio
                                key={unit}
                                button
                                form={form}
                                name={"result.unit"}
                                defaultValue={get(form.values, "result.unit", "g/hr")}
                                options={[
                                    { label: "g/hr", value: "g/hr" },
                                    { label: "kcal/hr", value: "kcal/hr" },
                                ]}
                            />

                        </InputTableCell>

                        <InputTableCell>Carb Burn</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        wrapClass={`${unit === "g/hr" ? "" : "hidden"} ${index == 1 ? "blue-input" : "green-input"}`}
                                        form={form}
                                        name={`result.sum.carbBurnG[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.carbBurnG[${index}]`)}
                                        disabled
                                        hidden
                                        suffix={` ${unit}`}
                                    />

                                    <FieldNumber
                                        wrapClass={`${unit === "kcal/hr" ? "" : "hidden"} ${index == 1 ? "blue-input" : "green-input"}`}
                                        form={form}
                                        name={`result.sum.carbBurnKcal[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.carbBurnKcal[${index}]`)}
                                        disabled
                                        hidden
                                        suffix={` ${unit}`}
                                    />

                                </InputTableCell>

                                <InputTableCell></InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>Individual</InputTableCell>

                        <FieldHidden
                            form={form}
                            name={`result.target.Energy`}
                            value='Individual'
                        />

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>Fat Burn</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        wrapClass={`${unit === "g/hr" ? "" : "hidden"} ${index == 1 ? "blue-input" : "green-input"}`}
                                        form={form}
                                        name={`result.sum.fatBurnG[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.fatBurnG[${index}]`)}
                                        disabled
                                        hidden
                                        suffix={` ${unit}`}
                                    />
                                    <FieldNumber
                                        wrapClass={`${unit === "kcal/hr" ? "" : "hidden"} ${index == 1 ? "blue-input" : "green-input"}`}
                                        form={form}
                                        name={`result.sum.fatBurnKcal[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.fatBurnKcal[${index}]`)}
                                        disabled
                                        hidden
                                        suffix={` ${unit}`}
                                    />

                                </InputTableCell>

                                <InputTableCell></InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>Individual</InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>%Carb Refuel</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        form={form}
                                        wrapClass={"w-140 manual-input"}
                                        name={`result.sum.percentCarbRefuel[${index}]`}
                                        defaultValue={get(form.values, `result.sum.percentCarbRefuel[${index}]`)}
                                        suffix={` %`}
                                    />

                                </InputTableCell>

                                <InputTableCell>
                                </InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>Carb Refuel</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        wrapClass={`${unit === "g/hr" ? "" : "hidden"} ${index == 1 ? "blue-input" : "green-input"}`}
                                        key={get(form.defaults, `result.sum.carbRefuelG[${index}]`)}
                                        form={form}
                                        name={`result.sum.carbRefuelG[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.carbRefuelG[${index}]`)}
                                        disabled
                                        hidden
                                        suffix={` g/hr`}
                                    />

                                    <FieldNumber
                                        wrapClass={`${unit === "kcal/hr" ? "" : "hidden"} ${index == 1 ? "blue-input" : "green-input"}`}
                                        key={get(form.defaults, `result.sum.carbRefuelKcal[${index}]`)}
                                        form={form}
                                        name={`result.sum.carbRefuelKcal[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.carbRefuelKcal[${index}]`)}
                                        disabled
                                        hidden
                                        suffix={` kcal/hr`}
                                    />

                                </InputTableCell>

                                <InputTableCell></InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell rowSpan={2}><strong>Breathing</strong></InputTableCell>

                        <InputTableCell>Breaths</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        form={form}
                                        name={`result.sum.beRate[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.beRate[${index}]`)}
                                        disabled
                                        hidden
                                        suffix={` brpm`}
                                        wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                    />

                                </InputTableCell>

                                <InputTableCell alignMiddle>{withinRange("Breathing", get(form.values, `result.sum.beRate[${index}]`), targets, gender, activity, data)}</InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>{getRangeOutput("Breathing", targets, gender, activity, form.values)}</InputTableCell>

                        <FieldHidden
                            form={form}
                            name={`result.target.Breathing`}
                            value={getRangeOutput("Breathing", targets, gender, activity, form.values)}
                        />

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>TV</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        form={form}
                                        name={`result.sum.beVolume[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.beVolume[${index}]`)}
                                        disabled
                                        hidden
                                        suffix={` L`}
                                        wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                    />

                                </InputTableCell>

                                <InputTableCell></InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>Individual</InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell><strong>RPE</strong></InputTableCell>

                        <InputTableCell>Avg</InputTableCell>

                        {effortData.map((item, index) => (
                            <React.Fragment key={index}>

                                <InputTableCell className="table-bdr-left-gray">

                                    <FieldNumber
                                        form={form}
                                        name={`result.sum.rpe[${index}]`}
                                        defaultValue={get(form.defaults, `result.sum.rpe[${index}]`)}
                                        disabled
                                        hidden
                                        wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                    />

                                </InputTableCell>

                                <InputTableCell alignMiddle>{withinRange("RPE", get(form.values, `result.sum.rpe[${index}]`), targets, gender, activity, data)}</InputTableCell>

                            </React.Fragment>
                        ))}

                        <InputTableCell className="table-bdr-left-gray" alignMiddle>Individual</InputTableCell>

                        <FieldHidden
                            form={form}
                            name={`result.target.RPE`}
                            value='Individual'
                        />

                    </InputTableRow>

                    {activity === "Running" &&
                        <InputTableRow>

                            <InputTableCell><strong>Stride Frequency</strong></InputTableCell>

                            <InputTableCell>Avg</InputTableCell>

                            {effortData.map((item, index) => (
                                <React.Fragment key={index}>

                                    <InputTableCell className="table-bdr-left-gray">

                                        <FieldNumber
                                            form={form}
                                            name={`result.sum.strideFrequency[${index}]`}
                                            defaultValue={get(form.defaults, `result.sum.strideFrequency[${index}]`)}
                                            disabled
                                            hidden
                                            suffix=" per min"
                                            wrapClass={`${index == 1 ? "blue-input" : "green-input"}`}
                                        />

                                        <FieldHidden
                                            form={form}
                                            name={`result.sum.strideLength[${index}]`}
                                        />

                                    </InputTableCell>

                                    <InputTableCell alignMiddle>{withinRange("Stride Frequency", get(form.values, `result.sum.strideFrequency[${index}]`), targets, gender, activity, data)}</InputTableCell>

                                </React.Fragment>
                            ))}

                            <InputTableCell className="table-bdr-left-gray" alignMiddle>{getRangeOutput("strides", targets, gender, activity, form.values)}</InputTableCell>

                            <FieldHidden
                                form={form}
                                name={`result.target.Stride`}
                                value={getRangeOutput("strides", targets, gender, activity, form.values)}
                            />



                        </InputTableRow>}

                </InputTable>

            </Col>

        </Row>

    )

}

interface RaceReadyResultTableProps {
    [key: string]: any,
}

export default RaceReadyResultTable;