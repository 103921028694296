import { getSum } from "./sharedGeneral";
import BigNumber from "bignumber.js";
import moment from 'moment';
import { fixedNum, parseNum } from "@dex/bubl-helpers";

// Test Session Bio Data Calculated Fields
export const getBmi = ( weight, height ) => {

    if ( !weight || !height ) return 0;

    return fixedNum( ( ( weight / height ) / height ) * 10000, 2 );

}

export const getBodyDensity = ( gender, skinFoldsSosTotal ) => {

    let val = 0;

    if ( gender === 'female' ) val = ( 1.20953 - ( 0.08294 * Math.log10( skinFoldsSosTotal ) ) )
    else val = ( 1.0988 - ( 0.0004 * skinFoldsSosTotal ) )

    return val;

}

export const getSos7 = ( bodyDensity ) => {

    let val = 0;

    val = ( 495 / parseFloat( bodyDensity ) ) - 450

    return val.toFixed( 1 );

}

export const getSos4 = ( sos, age, gender ) => {

    let val = 0;

    if ( gender === 'male' ) val = ( 0.29288 * sos ) - ( 0.0005 * Math.pow( sos, 2 ) ) + ( 0.15845 * age ) - 5.76377
    else val = ( 0.29669 * sos ) - ( 0.00043 * Math.pow( sos, 2 ) ) + ( 0.02963 * age ) - 1.4072

    return val.toFixed( 1 );

}

export const getBodyFatPercentage = ( bodyDensity ) => {

    let val = 0;

    val = ( ( 495 / parseFloat( bodyDensity ) ) - 450 ) / 100

    return val.toFixed( 2 );

}

export const getLeanBodyWeight = ( weight, bodyFatPercentage ) => {

    return weight - ( ( bodyFatPercentage / 100 ) * weight )

}

export const getCorrectedGirth = ( a, b ) => {

    let val = 0;

    val = a - ( ( b / 10 ) * Math.PI );

    return val.toFixed( 1 );

}

export const getMuscleMass = ( height, correctedMidThighGirth, forearmGirth, correctedCalfGirth ) => {

    let val = 0;

    val = ( height * ( ( 0.0553 * Math.pow( correctedMidThighGirth, 2 ) ) + ( 0.0987 * Math.pow( forearmGirth, 2 ) ) + ( 0.0331 * Math.pow( correctedCalfGirth, 2 ) ) ) - 2445 ) / 1000;

    return val;

}

export const getUpperBodyMuscleMass = ( gender, muscleMass ) => {

    let val = 0;

    if ( gender === 'female' ) val = muscleMass * 0.4;
    else val = muscleMass * 0.43;

    return val;

}

export const getLowerBodyMuscleMass = ( gender, muscleMass ) => {

    let val = 0;

    if ( gender === 'female' ) val = muscleMass * 0.58;
    else val = muscleMass * 0.55;

    return val;

}

export const getKcalorieValue = ( grams ) => {

    if ( !grams ) return 0;

    return grams * 4.12;

}

// Test Session Health Calculated Fields
export const getWellnessRating = ( wellnessTotal ) => {

    if ( wellnessTotal >= 20 && wellnessTotal <= 25 ) {

        return "Good";

    } else if ( wellnessTotal >= 15 && wellnessTotal <= 19 ) {

        return "Satisfactory";

    } else if ( wellnessTotal >= 10 && wellnessTotal <= 15 ) {

        return "Compromised";

    } else if ( wellnessTotal >= 1 && wellnessTotal < 10 ) {

        return "Very Poor";

    } else {

        return "";

    }

}

export const getFevFvcRatioPercentage = ( fev1, fvc ) => {

    return ( fev1 / fvc ) * 100;

}

export const getAverage = ( values ) => {

    if ( typeof values === 'object' ) values = Object.values( values );
    if ( !values ) return 0;

    let sum = getSum( values );

    return sum / values.length;
}

export const getStrideFrequency = ( revoultions, timeForstrides ) => {

    return revoultions * 60 / timeForstrides;

}

export const getTreadmillSpeed = ( beltLength, revolutions, timeRev ) => {

    return beltLength * revolutions / timeRev * 3.6;

}

// Lab Test - Equipment
export const getEquipment = () => {
    return 1;
}
// Lab Test - Manual
export const getManual = () => {
    return 1;
}

// Lab Test - V02
export const getVo2Test = () => {
    return 1;
}

// Lab Test - HR
export const getHrMaxEst = ( age ) => {
    return 208 - ( 0.7 * age );
}

// Lab Test - Energy
export const getMep = ( power, speed, fatPercentage, choPercentange ) => {
    return 1;
}

export const getMepHr = () => {
    return 1;
}

export const getMepVo2 = () => {
    return 1;
}

export const getFatmax = () => {
    return 1;
}

export const getFatmaxHr = () => {
    return 1;
}

export const getFatmaxVo2 = () => {
    return 1;
}

// Lab Test - Performance
export const getPerformance = () => {
    return 1;
}

// Lab Test - Breathing
export const getBreathing = () => {
    return 1;
}

// Lab Test - Muscle
export const getMuscle = () => {
    return 1;
}

// Lab Test - Lactate
export const getLactate = () => {
    return 1;
}

// Lab Test - Time Charts
export const getTimeCharts = () => {
    return 1;
}

export const getDecimalTime = ( clockTime ) => {

    if ( !clockTime ) return 0

    const timeParts = clockTime?.split( ':' );
    const hours = parseInt( timeParts[ 0 ] ) || 0;
    const minutes = parseInt( timeParts[ 1 ] ) || 0;
    const seconds = parseInt( timeParts[ 2 ] ) || 0;

    const decimalHours = fixedNum( hours + ( minutes / 60 ), 2 );

    return decimalHours;

}

export const getMinutesTime = ( clockTime ) => {

    if ( !clockTime ) return 0;

    const timeParts = clockTime.split( ':' );

    const hours = parseInt( timeParts[ 0 ] ) || 0;
    const minutes = parseInt( timeParts[ 1 ] ) || 0;
    // const seconds = parseInt(timeParts[2]) || 0;

    return ( hours * 60 ) + minutes;

}

export const getClockTime = ( decimalTime, format: string = "hh:mm" ) => {

    if ( !decimalTime ) decimalTime = 0;

    let hours: any = Math.floor( decimalTime );
    let minutes: any = Math.round( ( decimalTime - hours ) * 60 );
    let seconds: any = Math.round( ( ( ( decimalTime - hours ) * 60 ) - minutes ) * 60 );

    if ( hours < 10 && ( format.indexOf( "hh:" ) >= 0 || format.indexOf( "mm:" ) == 0 ) ) {
        hours = "0" + hours;
    }

    if ( minutes < 10 ) {
        minutes = "0" + minutes;
    }

    if ( seconds < 10 ) {
        seconds = "0" + seconds;
    }

    let clockTime: string;

    switch ( format ) {
        case 'hh:mm:ss':
        case 'h:mm:ss':
            clockTime = `${ hours }:${ minutes }:${ seconds }`;
            break;
        case 'hh:mm':
        case 'h:mm':
            clockTime = `${ hours }:${ minutes }`;
            break;
        case 'mm:ss':
        case 'm:ss':
            clockTime = `${ hours }:${ minutes }`;
            break;
        default:
            clockTime = `${ hours }:${ minutes }:${ seconds }`;
            break;
    }

    return clockTime;


}

export const getPace = ( distance, time, format = "mm:ss" ) => {

    if ( !distance || !time ) return 0;

    time = getMinutesTime( time );

    const pace = new BigNumber( time )
        .dividedBy( distance )
        .toFixed( 2 );

    return getClockTime( pace, format );

}

export const getSpeed = ( distance, time ) => {

    if ( !distance || !time ) return 0;

    time = getDecimalTime( time );

    const speed = new BigNumber( distance )
        .dividedBy( time )
        .toFixed( 2 );

    return speed;

}

// export const getDuration = (distance, speed, includeFormat) => {

//     if (!distance || !speed) return 0;

//     let duration: any = new BigNumber(distance)
//         .dividedBy(speed)
//         .multipliedBy(60)
//         .toNumber();

//     let format = "m:ss";

//     if (duration > 60) {
//         format = "h:mm";
//         duration = duration / 60;
//     } else if (duration > 10) {
//         format = "mm:ss";
//     }

//     if (includeFormat) duration = getClockTime(duration, format) + " " + format;

//     return duration;

// }

export const getDuration = ( distance, speed, includeFormat ) => {

    if ( !distance || !speed ) return 0;

    let duration: any = speed * distance

    let format = "m:ss";

    if ( duration > 60 ) {
        format = "h:mm";
        duration = duration / 60;
    } else if ( duration > 10 ) {
        format = "mm:ss";
    }

    if ( includeFormat ) duration = getClockTime( duration, format ) + " " + format;

    return duration;

}

export const getSwimPace = ( distance, time, format = "mm:ss" ) => {

    if ( !distance || !time ) return 0;

    distance = new BigNumber( distance )
        .multipliedBy( 10 )
        .toNumber();

    return getPace( distance, time, format );

}

export const getPower = ( distance, time ) => {

    if ( !distance || !time ) return 0;

    const seconds = getMinutesTime( time ) * 60;
    const speed = getSpeed( distance, time );

    // (speed * speed) / (distance / time) * 0.0075;

    const X = new BigNumber( speed )
        .multipliedBy( speed )
        .toNumber();

    const Y = new BigNumber( distance )
        .multipliedBy( 1000 )
        .dividedBy( seconds )
        .toNumber();

    const power = new BigNumber( X )
        .dividedBy( Y )
        .multipliedBy( 0.0075 )
        .toFixed( 2 );

    return power;

}

export const getHeightRating = ( height ) => {

    let rating = "Medium";

    height = parseInt( height );

    if ( !height ) return rating;

    if ( height <= 165 ) rating = "Small";
    else if ( height <= 179 ) rating = "Medium";
    else if ( height >= 180 ) rating = "Large";

    return rating;

}

export const getPaceFromKph = ( kph, format = "mm:ss" ) => {

    kph = parseNum( kph );

    if ( !kph ) return;

    let pace: any = fixedNum( ( 1 / kph ) * 60, 2 );

    pace = getClockTime( pace, format );

    return pace;

}

export const percentageOf = ( value, max, decimal = 0 ) => {

    value = parseNum( value );
    max = parseNum( max );

    if ( !value ) return;
    if ( !max ) return;

    return fixedNum( value / max * 100, decimal );

}

export const minusSecond = ( time, format = 'mm:ss' ) => {

    if ( !time ) return;

    const [ minutes, seconds ] = time.split( ":" );
    const totalSeconds = parseInt( minutes ) * 60 + parseInt( seconds );

    const newTotalSeconds = totalSeconds - 1;

    return getClockTime( newTotalSeconds / 60, format );

    // const newMinutes = Math.floor(newTotalSeconds / 60);
    // const newSeconds = newTotalSeconds % 60;

    // return `${newMinutes.toString().padStart(1, '0')}:${newSeconds.toString().padStart(2, '0')}`;

}

export const changeDemographic = ( demographic ) => {
    if ( demographic == "untrained" ) {
        return "Recreational"
    } else if ( demographic == "trained" ) {
        return "Competitive";
    } else if ( demographic == "elite" ) {
        return "Pro"
    } else {
        return demographic;
    }
}

export const getAdjustedRate = ( rate, adjustment ) => {

    let adjustedRate = 0;

    if ( adjustment || adjustment === 0 ) {
        adjustedRate = rate * ( parseNum( adjustment ) / 100 );
    } else {
        adjustedRate = rate;
    }

    return fixedNum( adjustedRate, 0 );
}

export const calculateMinPerKm = ( km ) => {
    const timePerKm = 60 / km;

    const minutes = Math.floor( timePerKm );
    const seconds = Math.round( ( timePerKm - minutes ) * 60 );

    // const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${ seconds }` : seconds;

    return `${ minutes }:${ formattedSeconds }`;
};


export const getDiffRating = ( value ) => {
    let diff;

    if ( value == 0 ) {
        diff = "Average"
    } else if ( value > 0 ) {
        diff = "Above"
    } else {
        diff = "Below"
    }

    return diff;
};

export const getKphFromPace = ( pace ) => {

    if ( !pace ) return;

    const [ minutes, seconds ] = pace.split( ":" );

    const totalSeconds = parseInt( minutes ) * 60 + parseInt( seconds );

    return fixedNum( 1 / ( totalSeconds / 3600 ), 1 );
};

export const getSwimSpeed = ( time, distance = 1 ) => {

    if ( !time ) return;

    const minute = getMinutesTime( time );

    distance = distance * 1000

    const speed = ( distance / 10 ) / minute;

    return fixedNum( speed, 2 );

}

export const getSwimPaceNew = ( speed, distance = 1 ) => {

    if ( !speed ) return;

    distance = distance * 1000

    const totalMinute = ( distance / 10 ) / speed;

    const time = getClockTime( totalMinute / 60 );

    return time;

}

export const getSwimPaceFromDistanceTime = ( time, distance ) => {

    if ( !time || !distance ) return;

    const minute = getMinutesTime( time );

    const distanceMeters = parseFloat( distance ) * 1000;

    const pacePer100m = minute / ( distanceMeters / 100 );

    const output = getClockTime( pacePer100m, "mm:ss" )

    return output;

}

export const carbIntakeLabel = ( carb ) => {

    const data = [
        { value: 1.3, label: "Very High (>400g)" },
        { value: 1.2, label: "High (300-400g)" },
        { value: 1, label: "Moderate (200-300g)" },
        { value: 0.8, label: "Low (100-200g)" },
        { value: 0.7, label: "Very Low (<100g)" },
    ];

    const result = data.find( item => item.value === carb );

    return result ? result.label : "";

}

export const getStepLength = ( speed, cadence ) => {

    if ( !speed && !cadence ) return;

    let output = 0;

    const denominator = 1000 / 60;

    const perMin = speed * denominator;

    output = fixedNum( perMin / cadence, 2 );

    return output;
}

export const cmToM = ( cm: number ) => {
    return cm / 100;
};
