import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import TestSessionsApi from '../TestSessionsApi';
import { get } from '@dex/bubl-helpers';
import TestSessionsGeneralForm from './TestSessionsGeneralForm';
import TestSessionsEditTabsBioData from './TestSessionsEditTabsBioData';
import TestSessionsEditTabsHealth from './TestSessionsEditTabsHealth';
import TestSessionsEditTabsTests from './TestSessionsEditTabsTests';
import TestSessionsEditTabsSummary from './TestSessionsEditTabsSummary';
import TestSessionsEditTabsReport from './TestSessionsEditTabsReport';
import TestSessionsEditTabsInfo from './TestSessionsEditTabsInfo';
import EditableContext from '../../../context/EditableContext';
import TestSessionsEditTabsEvents from './TestSessionsEditTabsEvents';

const TestSessionsSingleEdit: React.FC<any> = ( props: any ) => {

    const app = useApp();

    const { data } = props;

    const tab = get( props, 'routeParams.tab' );

    const form = useForm( data );

    let action = tab;

    if ( tab === "info" || !tab ) action = "update"

    const update = TestSessionsApi.update( data.id, action, {
        onComplete: ( data ) => {

            app.alert( 'Test Session Saved', 'success' );

        }
    } );

    const handleSubmit = useCallback( ( values ) => {

        update.run( { data: values } );

    }, [ props ] );

    let TabElement: any = React.Fragment;

    if ( tab === "info" || !tab ) TabElement = TestSessionsEditTabsInfo;
    else if ( tab === "bio" ) TabElement = TestSessionsEditTabsBioData;
    else if ( tab === "health" ) TabElement = TestSessionsEditTabsHealth;
    else if ( tab === "events" ) TabElement = TestSessionsEditTabsEvents;
    else if ( tab === "tests" ) TabElement = TestSessionsEditTabsTests;
    else if ( tab === "summary" ) TabElement = TestSessionsEditTabsSummary;
    else if ( tab === "reports" ) TabElement = TestSessionsEditTabsReport;

    return useMemo( () => (

        <>
            <EditableContext.Provider value={true}>

                {data && TabElement &&

                    <TabElement
                        form={form}
                        data={data}
                        handleSubmit={handleSubmit}
                        process={update}
                    />

                }

            </EditableContext.Provider>

        </>

    ), [ data, tab, form.hash, update ] );

}

export default TestSessionsSingleEdit;
