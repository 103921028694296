import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import DeletePanel from '@dex/bubl-dash/src/lib/components/SinglePanels/DeletePanel';
import EditableContext from '../../../context/EditableContext';
import GaitsCreateEditForm from './GaitsCreateEditForm';
import GaitsCopyData from './GaitsCopyData';

const GaitsEditTabsInfo: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <EditableContext.Provider value={true}>

                    <GaitsCreateEditForm
                        mode={"edit"}
                        form={form}
                        data={data}
                        handleSubmit={handleSubmit}
                        process={process}
                    />

                </EditableContext.Provider>

                <Space />

                <GaitsCopyData data={data} />

            </Col>

            <Col
                className={'side'}
            >

                <DeletePanel
                    data={data}
                />

            </Col>

        </Row>


    ), [props]);

}

export default GaitsEditTabsInfo;
