import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import MobilityBackMaleSvg from './MobilityBackMale.svg'
import MobilityBackFemaleSvg from './MobilityBackFemale.svg'

import './MobilityBack.module.scss';
import { hash } from '@dex/bubl-helpers';
import { mobilityValuesToClasses } from '../../functions/sharedBikeFit';
import { fillImage } from '../../functions/sharedGeneral';

const MobilityBack: React.FC<any> = (props: any) => {

    const { values, gender } = props;

    const { fair, restricted } = mobilityValuesToClasses(values);

    return useMemo(() => (

        <div
            key={hash([fair, restricted])}
            styleName={'wrap'}
        >
            <SVG
                src={gender === 'female' ? MobilityBackFemaleSvg : MobilityBackMaleSvg}
                preProcessor={(code) => {

                    code = fillImage(code, fair, "#FED000")
                    code = fillImage(code, restricted, "#ff0000")

                    return code;
                }}
            />
        </div>


    ), [fair, restricted]);

}

export default MobilityBack;
