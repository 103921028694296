import SettingsCoaching from './Components/SettingsCoaching';
import SettingsFitSize from './Components/SettingsFitSize';
import SettingsGait from './Components/SettingsGait';
import SettingsGeneral from './Components/SettingsGeneral';
import SettingsLabTests from './Components/SettingsLabTests';
import SettingsPerformance from './Components/SettingsPerformance';

export default function (app) {

    app.mods.Settings = {
        groups: [
            {
                key: "testSessions",
                label: "Lab Test Session Settings",
                caps: ["saveSettings"],
                panel: SettingsGeneral
            },
            {
                key: "labTests",
                label: "Lab Test Settings",
                caps: ["saveSettings"],
                panel: SettingsLabTests
            },
            {
                key: "performanceTracking",
                label: "Performance Tracking Settings",
                caps: ["saveSettings"],
                panel: SettingsPerformance
            },
            {
                key: "coaching",
                label: "Coachings Settings",
                caps: ["saveSettings"],
                panel: SettingsCoaching
            },
            {
                key: "gait",
                label: "Gaits Settings",
                caps: ["saveSettings"],
                panel: SettingsGait
            },
            {
                key: "fit",
                label: "Fit/Size Settings",
                caps: ["saveSettings"],
                panel: SettingsFitSize
            },
        ]
    }

}