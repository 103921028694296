import { Col, FieldRadio, Row } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import { get } from '@dex/bubl-helpers';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartPerformanceTrackingEconomy from '../../../elements/ChartTestSessionReport/ChartPerformanceTrackingEconomy';
import styles from "./TestSessionsReportPerformanceTrackingEconomy.module.scss";
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';

const TestSessionsReportPerformanceTrackingEconomy: React.FC<any> = (props: any) => {

    const { data, form, description } = props;

    let gender = data.athlete?.gender;
    const activity: string = data.test.activity;
    const intensity = data?.test?.intensity;

    const [index, setIndex] = useState(data.history[0].date);

    const trackingUse = get(form.values, `economy.trackingUse`, activity == "Cycling" ? "Power" : "Speed");

    let summary = "perfEconomy"

    return useMemo(() => (

        <>

            <Row edge gutter={8}>

                <Col col={{ xs: 20 }}>

                    <PanelChart
                        key={trackingUse}
                        heading={"Economy"}
                        addZero={false}
                        chartKey={form.hash}
                        data={data.history}
                        renderChart={(chartType, chartData) => (
                            <>
                                <ChartPerformanceTrackingEconomy
                                    data={chartData}
                                    activity={activity}
                                    date={data.recentDate}
                                    index={index}
                                    intensity={intensity}
                                    chart={trackingUse}
                                    type={chartType}
                                />
                            </>
                        )}
                    />

                </Col>

                <Col col={{ xs: 4 }}>

                    <FieldRadio
                        list=" vertical-list"
                        key={trackingUse}
                        form={form}
                        required={false}
                        label={"Tracking Over Time"}
                        name={"economy.trackingUse"}
                        defaultValue={form.values?.economy?.trackingUse || trackingUse}
                        options={[
                            activity === "Cycling" ? { label: "Power", value: "Power" } : null,
                            activity === "Running" ? { label: "Speed", value: "Speed" } : null,
                        ].filter(Boolean)}
                    />

                </Col>

                <Col col={{ xs: 20 }}>

                    <ReportDesc
                        label={description?.economyDescription}
                        className={["no-border", "h1"]}
                    />

                </Col>

                <Col col={{ xs: 20 }}>

                    {(trackingUse == "Power" || trackingUse == "Speed") &&

                        <ReportHeading
                            text={"Current Rating"}
                        />

                    }

                    {(trackingUse == "Power" || trackingUse == "Speed") &&

                        <>

                            {activity == "Cycling" ?
                                <RatingScaleCard
                                    metric={'Peak Cycling Economy'}
                                    value={data.history[data.history.length - 1]?.perfEconomy}
                                    form={form}
                                    demographic={data.test.performance.economyScale || data.demographic}
                                    gender={gender}
                                />
                                :
                                < RatingScaleCard
                                    metric={'Peak Running Economy'}
                                    value={data.history[data.history.length - 1]?.perfEconomy}
                                    form={form}
                                    demographic={data.test.performance.economyScale || data.demographic}
                                    gender={gender}
                                />
                            }

                        </>

                    }

                </Col>

                <Col col={{ xs: 20 }}>

                    <ReportChartSummary
                        label={""}
                        valueKey={summary}
                        indexKey={"date"}
                        data={data.history}
                        type={"performance"}
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, trackingUse]);

}

export default TestSessionsReportPerformanceTrackingEconomy;