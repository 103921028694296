import React, { useMemo } from 'react';
import { Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, FieldRelationship, Line, Space } from '@dex/bubl-dash';

const CoachingCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Coaching Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldRelationship
                            form={form}
                            label='Athlete'
                            name='athleteId'
                            required={true}
                            preload={true}
                            endpoint={'Athletes'}
                            defaultValue={form.defaults.athleteId}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldRelationship
                            form={form}
                            name="coachId"
                            label="Coach"
                            required
                            defaultValue={form.defaults.coachId}
                            preload={true}
                            endpoint={'Fitters'}
                            wrapClass={"manual-input"}
                        />

                    </Col>

                </Row>

                <Line />

                <Space />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default CoachingCreateEditForm;
