import React, { useMemo } from 'react';

const LabelWithImage: React.FC<any> = (props: any) => {

    const { image, text } = props;

    return useMemo(() => (

        <div className='label-wrap'>

            <div className="label-image">
                <img
                    src={image}
                    alt={text}
                />
            </div>

            <div className="label-text">
                {text}
            </div>

        </div>

    ), [props]);

}

export default LabelWithImage;