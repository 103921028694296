import React from 'react';
import { BarChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartPerformanceTracking: React.FC<ChartPerformanceTrackingProps> = (props) => {

    let { data, activity, date, index, intensity, chart, type, report } = props;

    const Axis = new ChartAxis();

    Axis.legend = false;

    Axis.setXByActivity("history", intensity);

    const trackingData = Axis.prepareTrackingData(data, 'economy');

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    if (chart == "Speed") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 0,
            key: "perfEconomy",
            label: date,
            axisLabel: "Speed - ml/kg*min/m per sec"
        });

    } else if (chart == "Power") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 0,
            key: "perfEconomy",
            label: date,
            axisLabel: "Power - %"
        });

    }

    return (

        <Chart>

            <BarChart
                {...ChartProps.BarChart()}
                data={trackingData}
            >
                {Axis.render()}

            </BarChart>

        </Chart>


    )

}

interface ChartPerformanceTrackingProps {
    [key: string]: any,
}

export default ChartPerformanceTracking;