import React, { useMemo } from 'react';
import { Form, useForm } from '@dex/bubl-dash';
import TestSessionsEditTabsBioData from '../../TestSessions/Components/TestSessionsEditTabsBioData';
import TestSessionsEditTabsHealth from '../../TestSessions/Components/TestSessionsEditTabsHealth';
import TestSessionsEditTabsTests from '../../TestSessions/Components/TestSessionsEditTabsTests';
import TestSessionsEditTabsSummary from '../../TestSessions/Components/TestSessionsEditTabsSummary';
import TestSessionsGeneralForm from '../../TestSessions/Components/TestSessionsGeneralForm';

const AtheletsSessionTestSession: React.FC<any> = (props: any) => {

    const { athlete, session, tab } = props;

    const form = useForm(session);

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={() => { }}
            autoDiscardChanges
        >

            <div
                className={"form-not-editable"}
            >

                {tab === "general" &&
                    <>

                        <TestSessionsGeneralForm
                            data={session}
                        />

                    </>
                }

                {tab === "bio" &&
                    <>

                        <TestSessionsEditTabsBioData
                            form={form}
                            data={session}
                        />

                    </>
                }

                {tab === "health" &&
                    <>

                        <TestSessionsEditTabsHealth
                            form={form}
                            data={session}
                        />

                    </>
                }

                {tab === "tests" &&
                    <>

                        <TestSessionsEditTabsTests
                            form={form}
                            data={session}
                        />

                    </>
                }

                {tab === "summary" &&
                    <>

                        <TestSessionsEditTabsSummary
                            form={form}
                            data={session}
                        />

                    </>
                }

            </div>

        </Form>

    ), [props]);

}

export default AtheletsSessionTestSession;
