import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, FieldRange, FieldSelect, FieldTextArea, Line, useApp } from '@dex/bubl-dash';
import TestSessionsEditTabsPanel from './TestSessionsEditTabsPanel';
import { get, set } from '@dex/bubl-helpers';

const TestSessionsEditTabsSummary: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    const app = useApp();

    const [settings] = app.store("appSettings");

    const summaryOptions = [
        {
            label: "VO2max Cycling",
            value: "VO2maxCyclingSessionSummary",
        },
        {
            label: "VO2max Running",
            value: "VO2maxCyclingSessionSummary",
        },
        {
            label: "Met Cycling",
            value: "MetCyclingSessionSummary",
        },
        {
            label: "Met Running",
            value: "MetRunningSessionSummary",
        },
        {
            label: "Sprint Cycling",
            value: "sprintCyclingSessionSummary",
        },
        {
            label: "Sprint Running",
            value: "sprintRunningSessionSummary",
        },
    ];

    const forwardOptions = [
        {
            label: "VO2max Cycling",
            value: "VO2maxCyclingGoingForward",
        },
        {
            label: "VO2max Running",
            value: "VO2maxRunningGoingForward",
        },
        {
            label: "Met Cycling",
            value: "MetCyclingGoingForward",
        },
        {
            label: "Met Running",
            value: "MetRunningGoingForward",
        },
        {
            label: "Sprint Cycling",
            value: "sprintCyclingGoingForward",
        },
        {
            label: "Sprint Running",
            value: "sprintRunningGoingForward",
        },
    ];


    const useDefaultText = useCallback((index, name: string) => {

        let text = get(settings.testSessions, index);

        const values = form.values;

        set(values, name, text);

        form.reset(values);

    }, [props, form.hash]);

    const { hasCycling, hasRaceReady, hasRunning, hasVo2max } = useMemo(() => {

        let hasRunning = false;
        let hasCycling = false;
        let hasRaceReady = false;
        let hasVo2max = false;

        data.labTests.map((labTest) => {

            if (labTest.activity === "Running") hasRunning = true;
            if (labTest.activity === "Cycling") hasCycling = true;
            if (labTest.type === "VO2max") hasVo2max = true;
            if (labTest.type === "Race Ready") hasRaceReady = true;

        });

        return { hasCycling, hasRaceReady, hasRunning, hasVo2max }

    }, [data]);

    const ratings = [
        { key: "health", label: "Health" },
        { key: "bodyComp", label: "Body Comp" },
        { key: "fitness", label: "Fitness" },
        { key: "circulation", label: "Blood Circulation" },
        { key: "breathing", label: "Breathing" },
        { key: "muscle", label: "Muscle Fitness" },
        { key: "energy", label: "Energy Metabolism" },
        hasRunning ? { key: "speed", label: "Speed" } : null,
        hasCycling ? { key: "power", label: "Power" } : null,
    ].filter(Boolean);

    return useMemo(() => (

        <TestSessionsEditTabsPanel
            heading={"Summary"}
            {...props}
        >

            <Row gutter={16} edge={true}>

                {ratings.map((rating: any) => (
                    <Col key={rating.key} col={{ xs: 24, md: 8, lg: 6 }}>

                        <FieldRange
                            form={form}
                            label={rating.label}
                            name={`summary.ratings.${rating.key}`}
                            required={false}
                            defaultValue={get(form, `defaults.summary.ratings.${rating.key}`)}
                            min={0}
                            max={5}
                            step={.5}
                        />
                    </Col>
                ))}

            </Row>

            <Line />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Session Summary"}
                        name={'summary.comments'}
                        required={false}
                        defaultValue={form.defaults.summary.comments}
                        rows={14}
                        maxLength={8000}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 8 }}>

                    <Space />

                    <Row edge={true}>


                        <Col col={{ xs: 24 }}>

                            <FieldSelect
                                form={form}
                                name="summary.defaultTextSummary"
                                options={summaryOptions}
                            />

                        </Col>

                        <Button
                            label={'Use Default Text'}
                            onClick={useDefaultText.bind(null, get(form.values, `summary.defaultTextSummary`), "summary.comments")}
                            type={'faded'}
                            confirm={form.values.summary.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' } : undefined}
                        />

                    </Row>

                    <Space />

                </Col>

                <Col col={{ xs: 24 }}>
                    <Line space={false} />
                </Col>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Going Forward"}
                        name={'summary.goingForward'}
                        required={false}
                        defaultValue={form.defaults.summary.goingForward}
                        rows={14}
                        maxLength={8000}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 8 }}>

                    <Space />

                    <Row edge={true}>


                        <Col col={{ xs: 24 }}>

                            <FieldSelect
                                form={form}
                                name="summary.defaultTextForward"
                                options={forwardOptions}
                            />

                        </Col>

                        <Button
                            label={'Use Default Text'}
                            onClick={useDefaultText.bind(null, get(form.values, `summary.defaultTextForward`), "summary.goingForward")}
                            type={'faded'}
                            confirm={form.values.summary.goingForward ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' } : undefined}
                        />

                    </Row>

                    <Space />

                </Col>

            </Row>


        </TestSessionsEditTabsPanel>

    ), [form.hash]);

}

export default TestSessionsEditTabsSummary;
