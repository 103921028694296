import React from 'react';

import styles from "./FieldResultsTeamRankingChart.module.scss";
import { Heading, Panel, Space } from '@dex/bubl-dash';
import { arrowPosition } from '../RatingScale/RatingScaleElements';
import { fixedNum, loop, slug } from '@dex/bubl-helpers';
import { RatingScaleCardInner } from '../RatingScale/RatingScaleCard';
import { nearestMaxAuto, nearestMinAuto } from '../../functions/sharedChart';

const FieldResultsTeamRankingChart: React.FC<FieldResultsTeamRankingChartProps> = (props) => {

    const { heading, team, scores, scale, template } = props;

    const scoresArray = Object.values(scores);

    let min = scale?.min || 0;
    let max = scale?.max || 100;
    let bestScore = scale?.bestScore;

    if (!scale) {

        bestScore = (template.bestScore) ? template.bestScore : "higher";

        const values: Array<number> = [];

        loop(scores, (score: any) => {
            values.push(score.score);
        });

        min = nearestMinAuto(Math.min(...values));
        max = nearestMaxAuto(Math.max(...values));

    }

    return (

        <Panel
            heading={heading}
            className={styles.panel}
            full={true}
        >

            {scores &&
                <div className={styles.innerAthlete}>

                    {scoresArray.map((score: any, index) => (
                        <FieldResultsTeamRankingChartBar
                            key={index}
                            ranking={score.ranking}
                            rating={score.rating}
                            label={score.label}
                            percentile={score.percentile}
                            name={score.name}
                            score={fixedNum(score.score, template?.decimalPlace)}
                            max={max}
                            min={min}
                            bestScore={bestScore}
                        />
                    ))}

                </div>
            }

            {!team &&
                <Space />
            }

            {team && scale &&

                <div className={styles.innerTeam}>

                    <RatingScaleCardInner
                        data={{
                            ...scale,
                            displayAs: "scale",
                            suffix: scale?.units,
                            title: "Team Score",
                        }}
                        steps={scale.steps}
                        min={scale.min}
                        max={scale.max}
                        value={fixedNum(team.avg, template.decimalPlace)}
                        rating={team.rating}
                        source={scale.source}
                        field={true}
                    />

                </div>

            }

        </Panel>

    )

}

const FieldResultsTeamRankingChartBar: React.FC<FieldResultsTeamRankingChartProps> = (props) => {

    const { ranking, name, score, rating, label, max, min, percentile, bestScore } = props;

    let width = arrowPosition(score, max, min);

    width = (100 - width) * .9;

    return (

        <div className={[styles.container, `rating-color-${slug(rating)}`].join(" ")}>
            <p className={styles.name}>{ranking}. {name}</p>

            <div className={styles.chart}>
                <div
                    className={styles.bar + " bar"}
                    style={{
                        width: `${width}%`,
                    }}
                />
                <p className={styles.label}>{score} {label}</p>
            </div>
        </div>

    )

}

interface FieldResultsTeamRankingChartProps {
    [key: string]: any,
}

export default FieldResultsTeamRankingChart;