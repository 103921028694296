import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldDate, FieldRadio, FieldRelationship, FieldSelect, FieldTextArea } from '@dex/bubl-dash';


const TestSessionsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Test Session Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>


                    {/* <Col col={{ xs: 24, md: 24, lg: 24 }}>

                        <FieldRadio
                            button={true}
                            form={form}
                            label={"Type"}
                            name='type'
                            required={true}
                            isMulti={false}
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "Individual", label: "Individual" },
                                // { value: "Team", label: "Team" },
                            ]}
                        />

                    </Col> */}

                    <Col col={{ xs: 24, md: 24, lg: 8 }}>

                        <FieldRelationship
                            form={form}
                            label='Athlete'
                            name='athleteId'
                            required={true}
                            preload={true}
                            endpoint={'Athletes'}
                            defaultValue={form.defaults.athleteId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24, lg: 8 }}>

                        <FieldDate
                            form={form}
                            label={"Session Date"}
                            name={`date`}
                            required={true}
                            defaultValue={form.defaults.date}
                            date={true}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24, lg: 8 }}>

                        <FieldRelationship
                            form={form}
                            name="fitterIds"
                            label="Appraisers"
                            required={false}
                            defaultValue={form.defaults.fitterIds}
                            preload={true}
                            endpoint={'Fitters'}
                            isMulti={true}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default TestSessionsCreateEditForm;
