import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Col, DataTable, FieldDate, FieldSelect, Modal, Row, useApp, useForm, useUpdateEffect } from '@dex/bubl-dash';
import CoachingEventsApi from '../../CoachingEvents/CoachingEventsApi';
import { get, loop } from '@dex/bubl-helpers';
import CoachingEventsForm from './CoachingEventsForm';
import moment from 'moment';

const CoachingEventsTable: React.FC<any> = ( props: any ) => {

    const { data } = props;

    const form = useForm( { year: new Date().toJSON() } );

    const fetch = CoachingEventsApi.get();

    const [ key, setKey ] = useState( 0 );

    useEffect( () => {

        const query: any = { coachingId: data.id };

        if ( form.values.year ) {
            query.year = moment( form.values.year ).format( "YYYY" )
        }

        fetch.run( {
            params: {
                where: query,
                order: [ 'date ASC' ],
                limit: 1000,
            }
        } );

    }, [ key, form.values.year ] )

    const columns = [
        {
            label: "",
            key: "id",
            render: ( value, row ) => {

                return (
                    <CoachingEventsEditForm data={row} setKey={setKey} />
                );
            }
        },
        {
            label: "Type",
            key: "type",
            format: "titleCase"
        },
        {
            label: "Name",
            key: "name"
        },
        {
            label: "Date",
            key: "date",
            format: "date"
        },
        {
            label: "Rating",
            key: "rating"
        },
        {
            label: "Completed",
            key: "completed",
            render: ( value, row ) => {

                return (
                    <>{value == true ? "Yes" : "No"}</>
                );
            }
        },
    ];


    const rowisComplete = ( row, index ) => {

        const complete = get( row, `completed` );

        return complete ? "table-bg-green" : "table-bg-blue";

    };

    return useMemo( () => (

        <>
            <Box gutter={"auto"} edge={{ top: true }}>
                <Row >

                    <Col col={{ xs: 4 }}>

                        <FieldDate
                            form={form}
                            name='year'
                            defaultValue={form.defaults.year}
                            label="Year"
                            dateFormat="YYYY"
                            dateMode="year"
                            wrapClass="manual-input"
                        />

                    </Col>

                </Row>
            </Box>

            <DataTable
                data={get( fetch, 'data.data', [] )}
                count={get( fetch, 'data.count', null )}
                columns={columns}
                loading={fetch.loading}
                rowClassName={rowisComplete}
            />

        </>

    ), [ fetch.loading, form.hash ] );

}

const CoachingEventsEditForm: React.FC<any> = ( props: any ) => {

    const { data, setKey } = props;

    const [ showFormUpdate, setShowFormUpdate ] = useState( false );

    const form = useForm( data )

    const process = CoachingEventsApi.update( data.id, "update" );

    const handleSubmit = useCallback( ( values ) => {

        process.run( {
            data: values,
            onComplete: ( data ) => {

                app.alert( 'Event Saved', 'success' );
                setShowFormUpdate( false );
                setKey( k => k + 1 )

            }
        } );

    }, [] );

    const app = useApp();

    const handleDelete: any = CoachingEventsApi.delete( data.id, {
        onComplete: ( data ) => {

            app.alert( 'Event Deleted', 'success' );

            setShowFormUpdate( false );

            setKey( k => k + 1 )
        }
    } );

    return (

        <>

            <a href="#" onClick={() => setShowFormUpdate( true )}>Edit Event</a >

            {showFormUpdate &&

                <Modal
                    show={showFormUpdate}
                    onClose={setShowFormUpdate.bind( null, false )}
                    width={1920}
                >

                    <CoachingEventsForm
                        form={form}
                        process={process}
                        handleSubmit={handleSubmit}
                        mode={"edit"}
                        handleClose={setShowFormUpdate.bind( null, false )}
                        handleDelete={handleDelete}
                    />

                </Modal>
            }

        </>
    )

}


export default CoachingEventsTable;
