import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import CoachingPlansApi from '../CoachingPlansApi';
import { Button, ButtonRow, Col, DataTableCell, FieldAutocomplete, FieldCheckbox, FieldDate, FieldHidden, FieldNumber, FieldRelationship, FieldRepeater, FieldSelect, FieldTextArea, InputTable, InputTableCell, InputTableHead, InputTableHeadCell, InputTableHeadRow, InputTableRow, Line, Row, Seperator, useUpdateEffect } from '@dex/bubl-dash';
import CoachingApi from '../../Coaching/CoachingApi';
import { date, fixedNum, get, loop } from '@dex/bubl-helpers';
import moment from 'moment';
import { eventRatingIcons } from '../../../functions/sharedCoaching';

const CoachingPlansSingleEdit: React.FC<any> = (props: any) => {

    const { id, session, save, setSave } = props;

    const plan = CoachingPlansApi.getOne(id);

    useEffect(() => {

        plan.run();

    }, [id]);

    return useMemo(() => (

        <>

            {plan.data &&
                <CoachingPlansSingleEditForm
                    {...props}
                    data={plan.data}
                />
            }

        </>

    ), [plan.loading, save, setSave]);

}

const CoachingPlansSingleEditForm: React.FC<any> = (props: any) => {

    const { data, session, save, setSave, editable, handleRefresh } = props;

    const app = useApp();

    const [nav] = app.store("nav");

    const [key, setKey] = useState(0);

    const form = useForm({ ...data, athlete: session.athlete });

    const trash = CoachingApi.update(session.id, "removePlan", {
        onComplete: (data) => {

            setSave(false);

            app.alert('Plan Removed', 'success');

            app.navigate(nav, 'update', {});

        }
    });

    const update = CoachingPlansApi.update(data.id, "update", {
        onComplete: (data) => {

            setSave(false);

            app.alert('Plan Saved', 'success');

            // refresh page
            // app.navigate(nav, 'update', {});

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({
            data: values,
        });

    }, [props]);

    useEffect(() => {

        if (save) form.triggerSubmit();

    }, [save]);

    const handleTrash = useCallback(() => {

        trash.run({
            data: { id: data.id }
        });

        app.navigate(nav, 'update', {});

    }, []);

    useEffect(() => {
        var a = moment(form.values.startDate);
        var b = moment(form.values.endDate);

        const totalWeek = b.diff(a, 'weeks')

        form.handleChange({ name: "totalWeek", value: totalWeek, reset: true });

    }, [form.values.startDate, form.values.endDate])

    useUpdateEffect(() => {

        let weekPlanned = 0;

        loop(form.values.blocks, (block) => {
            weekPlanned += fixedNum(block?.totalWeeks)
        })

        form.handleChange({ name: "weekPlanned", value: weekPlanned, reset: true });

    }, [form.hash]);

    return useMemo(() => (

        <>

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={update.loading}
                autoDiscardChanges={true}
            >

                <Row edge gutter={8}>

                    <Col col={{ xs: 24 }}>

                        <Seperator
                            heading={"Plan Details"}
                            bottom={"small"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <Row edge gutter={8}>

                            <Col col={{ xs: 12 }}>

                                <FieldDate
                                    form={form}
                                    name='startDate'
                                    defaultValue={form.defaults.startDate}
                                    label='Start Date'
                                    wrapClass={'manual-input'}
                                />

                            </Col>

                            <Col col={{ xs: 12 }}>

                                <FieldDate
                                    form={form}
                                    name='endDate'
                                    defaultValue={form.defaults.endDate}
                                    label='End Date'
                                    wrapClass={'manual-input'}
                                />

                            </Col>

                            <Col col={{ xs: 12 }}>

                                <FieldNumber
                                    dynamicKey
                                    form={form}
                                    name='totalWeek'
                                    defaultValue={form.defaults.totalWeek}
                                    label='Total Weeks'
                                    disabled
                                    hidden
                                />

                            </Col>

                            <Col col={{ xs: 12 }}>

                                <FieldNumber
                                    dynamicKey
                                    form={form}
                                    name='weekPlanned'
                                    defaultValue={form.defaults.weekPlanned}
                                    label='Weeks Planned'
                                    disabled
                                    hidden
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldTextArea
                            form={form}
                            name='goals'
                            defaultValue={form.defaults?.goals}
                            label='Goals For This Plan'
                            rows={5}
                        />

                    </Col>

                    <Line />

                    <Col col={{ xs: 24 }}>

                        <FieldRepeater
                            form={form}
                            name={"blocks"}
                            required={false}
                            addButtonLabel={"Add Block"}
                        >

                            {(form.values.blocks || []).map((row, n) => (

                                <BlockForm
                                    key={n}
                                    form={form}
                                    data={row}
                                    n={n}
                                    session={session}
                                />

                            ))}

                        </FieldRepeater>

                    </Col>

                </Row>

                {editable &&
                    <>
                        <Line />

                        <Row>

                            <Col col={{ xs: 24, sm: 16 }}>

                                <ButtonRow>
                                    <Button
                                        label={"Save"}
                                        // onPress={form.handleSubmit}
                                        loading={update.loading}
                                    />
                                    <Button
                                        label={"Reset"}
                                        type={"faded"}
                                        onClick={form.reset}
                                    />
                                    <Button
                                        label={"Cancel"}
                                        onClick={handleRefresh}
                                        type='faded'
                                    />
                                </ButtonRow>

                            </Col>
                            <Col col={{ xs: 24, sm: 8 }} align={"end"}>

                                <ButtonRow>
                                    <Button
                                        label={"Remove Plan"}
                                        type={"light"}
                                        onClick={handleTrash}
                                        loading={trash.loading}
                                        confirm={{
                                            title: "Are you sure you want continue?",
                                            message: "This data cannot be recovered once removed.",
                                        }}
                                    />
                                </ButtonRow>

                            </Col>

                        </Row>
                    </>
                }

            </Form>

        </>

    ), [data, form.hash, update.loading, key, trash.loading, props]);

}

const BlockForm: React.FC<any> = (props: any) => {

    const { form, data, n, session } = props;

    const block = get(form.defaults, `blocks[${n}]`);

    const lastBlock = get(form.values, `blocks[${n - 1}]`);

    let startCalendar = 0;

    if (lastBlock && lastBlock.weeks?.length > 0 && lastBlock.weeks[lastBlock.weeks.length - 1]?.calendar) startCalendar = lastBlock.weeks[lastBlock.weeks.length - 1]?.calendar;

    const getStartDate = () => {

        let startDate = form.values.startDate;

        if (lastBlock && lastBlock.weeks.length > 0 && lastBlock.weeks[lastBlock.weeks.length - 1]?.endDate) startDate = moment(lastBlock.weeks[lastBlock.weeks.length - 1]?.endDate).add(1, 'days').format('YYYY-MM-DD');

        return startDate;

    }

    useUpdateEffect(() => {

        const count = get(form.values, `blocks[${n}].totalWeeks`) - 1
        const weeks = get(form.values, `blocks[${n}].weeks`, []);

        const startDate = getStartDate();

        const array: any = [];

        for (let i = 0; i <= count; i++) {

            let week = weeks[i];

            if (!week) {
                week = {
                    startDate: moment(startDate).add(i, 'weeks').format('YYYY-MM-DD'),
                    endDate: moment(startDate).add(i, 'weeks').add(6, 'days').format('YYYY-MM-DD'),
                }
            }

            array.push(week)

        }

        form.handleChange({ name: `blocks[${n}].weeks`, value: array, reset: true })

    }, [get(form.values, `blocks[${n}].totalWeeks`)]);


    const onRowDateChange = (index, curentDate) => {

        loop(form.values.blocks[n].weeks, (week, i) => {

            if (i < index) return;

            const startDate = moment(curentDate).format('YYYY-MM-DD');
            const endDate = moment(curentDate).add(6, 'days').format('YYYY-MM-DD');

            curentDate = curentDate.add(7, 'days');

            form.handleChange({ name: `blocks[${n}].weeks[${i}].startDate`, value: startDate, reset: true });
            form.handleChange({ name: `blocks[${n}].weeks[${i}].endDate`, value: endDate, reset: true });

        });

    }

    const onStartDateChange = (index, value) => {

        let curentDate = moment(value);

        onRowDateChange(index, curentDate);

    }

    const onEndDateChange = (index, value) => {

        let curentDate = moment(value).subtract(6, 'days');

        onRowDateChange(index, curentDate);

    }

    const onChangeEvent = useCallback(({ name, value }) => {

        loop(session?.events, (row, i) => {

            const label = date(row.date) + " | " + row.name;

            if (value === label) {
                form.handleChange({ name: name.replace(".event", '.rating'), value: row.rating, reset: true });
            }

        });

    }, [form.hash]);

    const getEventsByDate = (startDate, endDate) => {

        const events: any = [];
        const nonWeekEvents: any = [];

        startDate = moment(startDate).startOf('day');
        endDate = moment(endDate).endOf('day');

        loop(session?.events, (row, i) => {

            const label = date(row.date) + " | " + row.name;

            if ((moment(row?.date).isSame(startDate) || moment(row?.date).isSame(endDate)) || moment(row?.date).isBetween(startDate, endDate, null, '[]')) {

                events.push({
                    label: label,
                    value: label,
                    rating: row.rating,
                })

            } else {

                nonWeekEvents.push({
                    label: label,
                    value: label,
                    rating: row.rating,
                })

            }

        })

        events.push(...nonWeekEvents);

        return events;

    };

    return (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 12, sm: 6 }}>

                <FieldSelect
                    form={form}
                    name={`blocks[${n}].phase`}
                    defaultValue={get(form.values, `blocks[${n}].phase`)}
                    label='Training Phase'
                    options={[
                        { label: "General Prep", value: "General Prep" },
                        { label: "Specific Prep", value: "Specific Prep" },
                        { label: "Competition", value: "Competition" },
                        { label: "Transition", value: "Transition" },
                        { label: "Off-Training", value: "Off-Training" },
                    ]}
                    wrapClass={"manual-input"}
                />

            </Col>

            <Col col={{ xs: 12, sm: 6 }}>

                <FieldSelect
                    form={form}
                    name={`blocks[${n}].block`}
                    defaultValue={get(form.values, `blocks[${n}].block`)}
                    label='Training Block'
                    options={[
                        { label: "Base", value: "Base" },
                        { label: "Build", value: "Build" },
                        { label: "Taper", value: "Taper" },
                        { label: "Race", value: "Race" },
                        { label: "Recovery", value: "Recovery" },
                    ]}
                    wrapClass={"manual-input"}
                />

            </Col>

            <Col col={{ xs: 12, sm: 6 }}>

                <FieldAutocomplete
                    form={form}
                    name={`blocks[${n}].focus`}
                    defaultValue={get(form.values, `blocks[${n}].focus`)}
                    label='Focus'
                    isMulti
                    options={[
                        { label: "Aerobic Base", value: "Aerobic Base" },
                        { label: "Fat-Burning", value: "Fat-Burning" },
                        { label: "Lactate Tolerance", value: "Lactate Tolerance" },
                        { label: "Strength Development", value: "Strength Development" },
                        { label: "Speed Development", value: "Speed Development" },
                        { label: "Anaerobic Capacity", value: "Anaerobic Capacity" },
                        { label: "Skill/Technique Work", value: "Skill/Technique Work" },
                        { label: "Race Practice/Prep", value: "Race Practice/Prep" },
                    ]}
                    group='coaching_focus'
                    wrapClass={"manual-input"}
                    sort={false}
                />

            </Col>

            <Col col={{ xs: 12, sm: 4 }}>

                <FieldNumber
                    form={form}
                    name={`blocks[${n}].totalWeeks`}
                    defaultValue={get(form.values, `blocks[${n}].totalWeeks`)}
                    label='Weeks'
                    wrapClass={"manual-input"}
                />

                <FieldHidden
                    form={form}
                    name={`blocks[${n}].blockNum`}
                    value={n + 1}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <InputTable
                    style={{ overflow: "auto" }}
                    columns={[
                        {
                            colSpan: 8,
                            style: { width: "120px" },
                            className: "table-bdr-btm-none",
                            alignMiddle: true
                        },
                        {
                            label: "Training Load",
                            key: "Up",
                            colSpan: 4,
                            style: { width: "120px" },
                            alignMiddle: true
                        }
                    ]}
                >

                    <InputTableHeadRow className="table-bg-gray">

                        <InputTableHeadCell>
                            Calendar
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            Week
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            From
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            To
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            Event
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            Rating
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            Race
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            Test
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            Up
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            Down
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            Hold
                        </InputTableHeadCell>

                        <InputTableHeadCell>
                            Recovery
                        </InputTableHeadCell>

                    </InputTableHeadRow>

                    {(get(form.values, `blocks[${n}].weeks`) || []).map((week, index) => (

                        <InputTableRow key={form.key + "-" + index}>

                            <DataTableCell style={{ width: 10 }}>

                                {moment(get(block, `weeks[${index}].endDate`)).week()}
                                <FieldHidden
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].calendar`}
                                    defaultValue={moment(get(block, `weeks[${index}].endDate`)).week()}
                                />

                            </DataTableCell>

                            <DataTableCell style={{ width: 10 }}>

                                {index + 1}
                                <FieldHidden
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].week`}
                                    defaultValue={index + 1}
                                />

                            </DataTableCell>

                            <InputTableCell style={{ maxWidth: "160px" }}>

                                <FieldDate
                                    key={get(block, `weeks[${index}].startDate`)}
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].startDate`}
                                    defaultValue={get(block, `weeks[${index}].startDate`)}
                                    wrapClass={'manual-input w-160'}
                                    onChange={onStartDateChange.bind(null, index)}
                                />

                            </InputTableCell>

                            <InputTableCell style={{ maxWidth: "160px" }}>

                                <FieldDate
                                    key={get(block, `weeks[${index}].endDate`)}
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].endDate`}
                                    defaultValue={get(block, `weeks[${index}].endDate`)}
                                    wrapClass={'manual-input w-160'}
                                    onChange={onEndDateChange.bind(null, index)}
                                />

                            </InputTableCell>

                            <InputTableCell style={{ maxWidth: "220px" }}>

                                <FieldSelect
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].event`}
                                    defaultValue={get(block, `weeks[${index}].event`)}
                                    wrapClass={'manual-input w-220'}
                                    allowCustomOption
                                    options={getEventsByDate(get(block, `weeks[${index}].startDate`), get(block, `weeks[${index}].endDate`))}
                                    onChange={onChangeEvent}
                                />

                            </InputTableCell>

                            <InputTableCell style={{ minWidth: "170px", maxWidth: "160px" }}>

                                <FieldSelect
                                    dynamicKey
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].rating`}
                                    defaultValue={get(block, `weeks[${index}].rating`)}
                                    wrapClass={'manual-input'}
                                    options={[
                                        { label: eventRatingIcons(1), value: 1 },
                                        { label: eventRatingIcons(2), value: 2 },
                                        { label: eventRatingIcons(3), value: 3 },
                                        { label: eventRatingIcons(4), value: 4 },
                                        { label: eventRatingIcons(5), value: 5 }
                                    ]}
                                />

                            </InputTableCell>

                            <InputTableCell style={{ width: "30px" }} >

                                <FieldCheckbox
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].race`}
                                    defaultValue={get(block, `weeks[${index}].race`)}
                                    wrapClass={'manual-input'}
                                    options={[
                                        { label: "", value: "yes" },
                                    ]}
                                />
                            </InputTableCell>

                            <InputTableCell style={{ width: "30px" }}>

                                <FieldCheckbox
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].test`}
                                    defaultValue={get(block, `weeks[${index}].test`)}
                                    wrapClass={'manual-input'}
                                    options={[
                                        { label: "", value: "yes" },
                                    ]}
                                />

                            </InputTableCell>

                            <InputTableCell style={{ width: "30px" }}>

                                <FieldCheckbox
                                    key={get(form.values, `blocks[${n}].weeks[${index}].trainingStimulus`)}
                                    singular={true}
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].trainingStimulus`}
                                    defaultValue={get(form.values, `blocks[${n}].weeks[${index}].trainingStimulus`)}
                                    options={[
                                        { label: "", value: "up" },
                                    ]}
                                />
                            </InputTableCell>

                            <InputTableCell style={{ width: "30px" }}>

                                <FieldCheckbox
                                    key={get(form.values, `blocks[${n}].weeks[${index}].trainingStimulus`)}
                                    singular={true}
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].trainingStimulus`}
                                    defaultValue={get(form.values, `blocks[${n}].weeks[${index}].trainingStimulus`)}
                                    options={[
                                        { label: "", value: "down" },
                                    ]}
                                />
                            </InputTableCell>

                            <InputTableCell style={{ width: "30px" }}>

                                <FieldCheckbox
                                    key={get(form.values, `blocks[${n}].weeks[${index}].trainingStimulus`)}
                                    singular={true}
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].trainingStimulus`}
                                    defaultValue={get(form.values, `blocks[${n}].weeks[${index}].trainingStimulus`)}
                                    options={[
                                        { label: "", value: "hold" },
                                    ]}
                                />
                            </InputTableCell>

                            <InputTableCell style={{ width: "30px" }}>

                                <FieldCheckbox
                                    key={get(form.values, `blocks[${n}].weeks[${index}].trainingStimulus`)}
                                    singular={true}
                                    form={form}
                                    name={`blocks[${n}].weeks[${index}].trainingStimulus`}
                                    defaultValue={get(form.values, `blocks[${n}].weeks[${index}].trainingStimulus`)}
                                    options={[
                                        { label: "", value: "recovery" },
                                    ]}
                                />

                            </InputTableCell>

                        </InputTableRow>

                    ))}

                </InputTable>

            </Col>

        </Row>
    )

}

export default CoachingPlansSingleEdit;
