import React, { useEffect, useMemo } from 'react';
import FieldSessionsEditTabsPanel from './FieldSessionsEditTabsPanel';
import { Col, FieldCheckbox, FieldHidden, FieldRadio, FieldTextArea, InputTable, InputTableCell, InputTableRow, Row, Seperator, useApp, useForm } from '@dex/bubl-dash';
import { fixedNum, get, loop, titleCase } from '@dex/bubl-helpers';
import FieldSessionsApi from '../FieldSessionsApi';
import { howMany } from '@dex/bubl-helpers';
import * as _ from 'lodash';

const FieldSessionsEditTabsScores: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    const athletes = data.athletes;

    const tests = FieldSessionsApi.format(data.id, "getTestList");

    const filters = useForm({ category: "", display: ["score", "ranking", "percentile", "weighted", "summary"] });

    useEffect(() => {

        tests.run({});

    }, [data.templatesIds]);

    const { categories, options } = useMemo(() => {

        const options: any = [];

        const categories: any = {};

        if (!tests.data) return { categories, options };

        loop(tests.data || [], (test) => {

            if (!categories[test.category]) categories[test.category] = { tests: [], ids: [] };

            categories[test.category].tests.push(test);
            categories[test.category].ids.push(test.id);

        });

        loop(categories || [], (category, name) => {

            options.push({
                label: titleCase(name),
                name: name,
                value: name,
                ids: category.ids,
                tests: category.tests,
                desc: category.tests.length + " " + howMany(category.tests.length, "Test", "Tests"),
            });

        });

        filters.handleChange({ name: "category", value: options[0].value, reset: true, silent: true });

        return { categories, options };

    }, [tests.loading]);

    const category = useMemo(() => {

        let category;

        loop(options || [], (cat) => {

            if (cat.name === filters.values.category) category = cat;

        });

        return category;

    }, [filters.values.category, options]);

    return useMemo(() => (

        <FieldSessionsEditTabsPanel
            heading={"Final Scores"}
            {...props}
        >

            {options && options.length > 0 &&
                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRadio
                            button
                            form={filters}
                            label='Select Category'
                            name={"category"}
                            defaultValue={filters.defaults.category}
                            options={options}
                            required
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldCheckbox
                            button
                            form={filters}
                            label='Display Columns'
                            name={"display"}
                            defaultValue={filters.defaults.display}
                            options={[
                                { value: "supplementary", label: "Supplementary Scores" },
                                { value: "score", label: "Score" },
                                { value: "ranking", label: "Ranking" },
                                { value: "percentile", label: "Percentile" },
                                { value: "weighted", label: "Weighted T-Score" },
                                { value: "summary", label: "Summary" },
                            ]}
                            required
                        />

                    </Col>

                </Row>
            }

            {(filters.values.display && !filters.values.display.includes("summary")) &&
                <FieldHidden
                    form={form}
                    name={`summaries`}
                    defaultValue={get(form.values, `summaries`, {})}
                />
            }

            {category && category.ids && tests.data && tests.data.map((test, index) => (
                <div
                    className={category.ids.includes(test.id) ? "" : "hidden"}
                    key={index}
                >
                    <ScoresTable
                        form={form}
                        test={test}
                        data={data}
                        athletes={athletes}
                        display={filters.values.display || []}
                        editable={true}
                    />
                </div>
            ))}

        </FieldSessionsEditTabsPanel>

    ), [options, athletes, category, props, filters.values]);

}


const ScoresTable: React.FC<any> = (props: any) => {

    const { test, data, athletes, form, display, editable } = props;

    const app = useApp();

    const columns = useMemo(() => {

        const style = { width: 100, paddingLeft: 12, paddingRight: 12 };

        let columns: any = [
            {
                key: "callName",
                label: "Athlete Name",
                desc: "",
                style: { ...style, width: undefined },
            },
        ];

        if (!test) return columns;

        if (test.options === "7site---") {

            //too many columns

        } else if (test.optionKeys && test.optionKeys.length > 1) {

            if (display.includes("supplementary")) {

                const keys = [...test.optionKeys];

                if (test.options === "left/right") keys.push("diff");

                loop(keys, (key, index) => {

                    const units = key === "diff" ? "%" : test.units;

                    columns.push({
                        key: key,
                        label: titleCase(key),
                        desc: `[${units || ""}]`,
                        format: "number",
                        style: style,
                        className: (index + 1) === keys.length ? "table-bdr-right-gray" : ""
                    });

                })

            }

        }

        columns = [
            ...columns,
            display.includes("score") ? {
                key: "score",
                label: "Score",
                desc: `[${test.units || ""}]`,
                format: "number",
                style: style,
                render: (value) => (
                    fixedNum(value, test.decimalPlace) || ""
                )
            } : null,
            display.includes("ranking") ? {
                key: "ranking",
                label: "Ranking",
                desc: "of " + data.athletes.length,
                format: "number",
                style: style,
            } : null,
            display.includes("percentile") ? {
                key: "percentile",
                label: "Percentile",
                desc: "%",
                format: "number",
                suffix: "%",
                style: style,
            } : null,
        ];

        if (test.options !== "overall" && display.includes("weighted")) {

            columns.push({
                key: "weighted",
                label: "Weighted",
                desc: "T-Scores",
                format: "number",
                decimals: 1,
                suffix: "",
                style: style,
                className: "table-bg-blue table-bdr-left-gray",
                render: (value) => (
                    fixedNum(value, test.decimalPlace) || ""
                )
            });

        }

        if (display.includes("summary")) {

            columns.push({
                key: "summary",
                label: "Summary",
                desc: "By Appraiser",
                style: { width: 300 },
                className: "table-bg-white table-bdr-left-gray"
            });

        }

        return columns.filter(Boolean);

    }, [test, display]);

    const rows = useMemo(() => {

        let rows: any = [];

        if (!athletes || !test) return rows;

        loop(athletes || [], (athlete, index) => {

            let row = {
                ...athlete,
                test: test,
                index: index + 1,
            };

            if (test.optionKeys && test.optionKeys.length > 1) {

                const keys = [...test.optionKeys];

                if (test.options === "left/right") keys.push("diff");

                loop(keys, (key, index) => {

                    row[key] = get(data.scores, `values.${test.id}-${key}.${athlete.id}`, '');

                })

            }

            row.score = get(data.scores, `values.${test.id}.${athlete.id}`, '');
            row.ranking = get(data.scores, `ranking.${test.id}.${athlete.id}`, '');
            row.weighted = get(data.scores, `weighted.${test.id}.${athlete.id}`, '');
            row.percentile = get(data.scores, `percentile.${test.id}.${athlete.id}`, '');

            rows.push(row);

        });

        //sort by ranking
        rows = _.sortBy(rows, ['ranking']);

        return rows;

    }, [athletes, test]);

    const scale = get(data, `scores.scales[${test.id}]`, null);
    const weight = get(data, `format.weights[${test.id}]`, null);

    const desc = [titleCase(test.category), titleCase(test.subcategory)];

    if (scale) {
        desc.push("Mean: " + scale.mean);
        desc.push("STD: " + scale.std);
    }

    if (weight !== null) {
        desc.push("Weight: " + weight);
    }

    return (
        <>

            <Seperator
                heading={test.name}
                description={desc.join(", ")}
                top={"auto"}
                bottom={"auto"}
            />

            <InputTable
                key={test.id}
                columns={columns}
                data={rows}
            >

                {rows.map((row, index) => (

                    <InputTableRow>

                        {columns.map((column, index) => (

                            <InputTableCell key={index} style={column.style} className={column.className || ""}>

                                {column.key === "summary" ?
                                    <FieldTextArea
                                        wrapClass={editable ? "" : "hidden"}
                                        form={form}
                                        name={`summaries.${test.id}.${row.id}`}
                                        defaultValue={get(form.defaults, `summaries.${test.id}.${row.id}`, "")}
                                        rows={2}
                                    />
                                    :
                                    app.columnValue(column, row)
                                }

                            </InputTableCell>

                        ))}


                    </InputTableRow>
                ))}

            </InputTable>

        </>
    )

};

export default FieldSessionsEditTabsScores;
