import { loop, parseNum, fixedNum, get } from "@dex/bubl-helpers";
import { getDecimalTime, getMinutesTime, getClockTime, minusSecond } from "./sharedTests";

export const fillZone = (type, form, swimLevel) => {

    loop(get(form.values, `zones`), (zone, n) => {

        let bikeFtp = get(form.values, `bikeFTP`);
        let swimFtp = getDecimalTime(get(form.values, `swimFTP`));
        let swimFtpSec = getMinutesTime(get(form.values, `swimFTP`));
        let runFtp = getDecimalTime(get(form.values, `runFTP`));
        let runFtpSec = getMinutesTime(get(form.values, `runFTP`));
        let runFtpSpeed: any = parseNum(get(form.values, `runSpeedFTP`));
        let hrMax = get(form.values, `hrMax`);

        let swimOutput;
        let runPaceOutput;

        const secToDecimalTIme = (sec) => {
            if (!sec) return 0;
            return sec / 60
        };

        if (type == "swim") {

            if (n == 0) {

                if (swimFtp) {
                    if (swimLevel == "strong") {
                        swimOutput = secToDecimalTIme(swimFtpSec + 30);
                    } else if (swimLevel == "fair") {
                        swimOutput = secToDecimalTIme(swimFtpSec + 20);
                    } else {
                        swimOutput = secToDecimalTIme(swimFtpSec + 15);
                    }
                }

                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimFrom`, value: null })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimTo`, value: getClockTime(swimOutput, 'mm:ss') })

            } else if (n == 1) {

                if (swimFtp) {
                    if (swimLevel == "strong") {
                        swimOutput = secToDecimalTIme(swimFtpSec + 15);
                    } else if (swimLevel == "fair") {
                        swimOutput = secToDecimalTIme(swimFtpSec + 12);
                    } else {
                        swimOutput = secToDecimalTIme(swimFtpSec + 8);
                    }
                }

                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimFrom`, value: minusSecond(get(form.values, `zones[${n - 1}].swimTo`)) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimTo`, value: getClockTime(swimOutput, 'mm:ss') })

            } else if (n == 2) {

                if (swimFtp) {
                    if (swimLevel == "strong") {
                        swimOutput = secToDecimalTIme(swimFtpSec + 7);
                    } else if (swimLevel == "fair") {
                        swimOutput = secToDecimalTIme(swimFtpSec + 5);
                    } else {
                        swimOutput = secToDecimalTIme(swimFtpSec + 4);
                    }
                }

                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimFrom`, value: minusSecond(get(form.values, `zones[${n - 1}].swimTo`)) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimTo`, value: getClockTime(swimOutput, 'mm:ss') })

            } else if (n == 3) {

                if (swimFtp) {

                    if (swimLevel == "strong") {
                        swimOutput = secToDecimalTIme(swimFtpSec);
                    } else if (swimLevel == "fair") {
                        swimOutput = secToDecimalTIme(swimFtpSec);
                    } else {
                        swimOutput = secToDecimalTIme(swimFtpSec);
                    }
                }

                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimFrom`, value: minusSecond(get(form.values, `zones[${n - 1}].swimTo`)) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimTo`, value: getClockTime(swimOutput, 'mm:ss') })

            } else if (n == 4) {

                if (swimFtp) {

                    if (swimLevel == "strong") {
                        swimOutput = secToDecimalTIme(swimFtpSec - 15);
                    } else if (swimLevel == "fair") {
                        swimOutput = secToDecimalTIme(swimFtpSec - 10);
                    } else {
                        swimOutput = secToDecimalTIme(swimFtpSec - 5);
                    }
                }

                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimFrom`, value: minusSecond(get(form.values, `zones[${n - 1}].swimTo`)) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimTo`, value: getClockTime(swimOutput, 'mm:ss') })

            } else if (n == 5) {

                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimFrom`, value: minusSecond(get(form.values, `zones[${n - 1}].swimTo`)) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].swimTo`, value: null })

            }

        } else if (type == "bike") {

            if (n == 0) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeTo`, value: fixedNum(bikeFtp * .75, 0) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeFrom`, value: null })
            } else if (n == 1) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeFrom`, value: fixedNum(get(form.values, `zones[${n - 1}].bikeTo`) + 1, 0) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeTo`, value: fixedNum(bikeFtp * .85, 0) })
            } else if (n == 2) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeFrom`, value: fixedNum(get(form.values, `zones[${n - 1}].bikeTo`) + 1, 0) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeTo`, value: fixedNum(bikeFtp * .90, 0) })
            } else if (n == 3) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeFrom`, value: fixedNum(get(form.values, `zones[${n - 1}].bikeTo`) + 1, 0) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeTo`, value: fixedNum(bikeFtp * 1.05, 0) })
            } else if (n == 4) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeFrom`, value: fixedNum(get(form.values, `zones[${n - 1}].bikeTo`) + 1, 0) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeTo`, value: fixedNum(bikeFtp * 1.4, 0) })
            } else if (n == 5) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeFrom`, value: fixedNum(get(form.values, `zones[${n - 1}].bikeTo`) + 1, 0) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].bikeTo`, value: null })
            }

        } else if (type == "run") {

            if (n == 0) {

                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToSpeed`, value: fixedNum(runFtpSpeed * .80, 0) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromSpeed`, value: null })

                runPaceOutput = secToDecimalTIme(runFtpSec / .8);

                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromPace`, value: getClockTime(runPaceOutput) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToPace`, value: null })

            } else if (n == 1) {

                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToSpeed`, value: fixedNum(runFtpSpeed * .88, 1) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromSpeed`, value: fixedNum(get(form.values, `zones[${n - 1}].runToSpeed`) + .1, 1) })

                runPaceOutput = secToDecimalTIme(runFtpSec / .88);
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromPace`, value: getClockTime(runPaceOutput) })

                runPaceOutput = secToDecimalTIme(runFtpSec / .81);
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToPace`, value: minusSecond(get(form.values, `zones[${n - 1}].runFromPace`)) })

            } else if (n == 2) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToSpeed`, value: fixedNum(runFtpSpeed * .95, 1) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromSpeed`, value: fixedNum(get(form.values, `zones[${n - 1}].runToSpeed`) + .1, 1) })

                runPaceOutput = secToDecimalTIme(runFtpSec / .95);
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromPace`, value: getClockTime(runPaceOutput) })

                runPaceOutput = secToDecimalTIme(runFtpSec / .89);
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToPace`, value: minusSecond(get(form.values, `zones[${n - 1}].runFromPace`)) })
            } else if (n == 3) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToSpeed`, value: fixedNum(runFtpSpeed * 1.05, 1) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromSpeed`, value: fixedNum(get(form.values, `zones[${n - 1}].runToSpeed`) + .1, 1) })

                runPaceOutput = secToDecimalTIme(runFtpSec / 1.05);
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromPace`, value: getClockTime(runPaceOutput) })

                runPaceOutput = secToDecimalTIme(runFtpSec / .96);
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToPace`, value: minusSecond(get(form.values, `zones[${n - 1}].runFromPace`)) })
            } else if (n == 4) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToSpeed`, value: fixedNum(runFtpSpeed * 1.3, 1) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromSpeed`, value: fixedNum(get(form.values, `zones[${n - 1}].runToSpeed`) + .1, 1) })

                runPaceOutput = secToDecimalTIme(runFtpSec / 1.3);
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromPace`, value: getClockTime(runPaceOutput) })

                runPaceOutput = secToDecimalTIme(runFtpSec / 1.06);
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToPace`, value: minusSecond(get(form.values, `zones[${n - 1}].runFromPace`)) })
            } else if (n == 5) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromSpeed`, value: fixedNum(get(form.values, `zones[${n - 1}].runToSpeed`) + .1, 1) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runFromPace`, value: null })

                runPaceOutput = secToDecimalTIme(runFtpSec / 1.3);
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToPace`, value: getClockTime(runPaceOutput) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].runToSpeed`, value: null })
            }
        } else if (type == "hr") {
            if (n == 0) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].hrTo`, value: fixedNum(hrMax * .75, 0) })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].hrFrom`, value: null })
            } else if (n == 1) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].hrFrom`, value: fixedNum(hrMax * .75, 0) + 1 })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].hrTo`, value: fixedNum(hrMax * .83, 0) })
            } else if (n == 2) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].hrFrom`, value: fixedNum(hrMax * .83, 0) + 1 })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].hrTo`, value: fixedNum(hrMax * .88, 0) })
            } else if (n == 3) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].hrFrom`, value: fixedNum(hrMax * .88, 0) + 1 })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].hrTo`, value: fixedNum(hrMax * .92, 0) })
            } else if (n == 4) {
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].hrFrom`, value: fixedNum(hrMax * .92, 0) + 1 })
                form.handleChange({ reset: true, silent: true, name: `zones[${n}].hrTo`, value: hrMax })
            } else if (n == 5) {

            }
        }

    })

};

export const getBg = (index: number) => {
    const color = ["table-bg-blue", "table-bg-green", "table-bg-darkGreen", "table-bg-yellow", "table-bg-brown", "table-bg-red"];
    return color[index];
}