import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import RatingCreator from '../../../elements/RatingCreator/RatingCreator';
import RatingScalesCreateEditForm from './RatingScalesCreateEditForm';

const RatingScaleCreateNew: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    return useMemo(() => (

        <Row gutter={'auto'} edge={true}>

            <Col col={{ xs: 24, lg: 17 }}>

                <RatingScalesCreateEditForm
                    form={form}
                    handleSubmit={handleSubmit}
                    process={process}

                />

            </Col>

            <Col col={{ xs: 24, lg: 7 }}>

                <RatingCreator
                    mainForm={form}
                    gender={true}
                    level={true}
                    displayAs={form.values.displayAs}
                />

            </Col>

        </Row>

    ), [props, form.hash]);

}

export default RatingScaleCreateNew;
