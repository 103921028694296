import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldAutocomplete, FieldDate, FieldSelect, useMountEffect } from '@dex/bubl-dash';
import moment from 'moment';

const GaitsEditTabsRunDetails: React.FC<any> = (props: any) => {

    const { form, prefix, run, data, i } = props;

    useMountEffect(() => {

        if (!run.date) {

            if (form.values?.runs?.length == 1) {
                form.handleChange({ reset: true, name: `${prefix}.date`, value: moment(form.values.sessionDate).format("YYYY-MM-DD") })
            } else {
                form.handleChange({ reset: true, name: `${prefix}.date`, value: moment().format("YYYY-MM-DD") })
            }

        }

        if (!run.condition && i == 0) form.handleChange({ reset: true, name: `${prefix}.condition`, value: "Initial Assessment" })

    });

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24, md: 6 }}>

                <FieldDate
                    key={run.date}
                    button={true}
                    form={form}
                    label={"Date Of Analysis"}
                    name={`${prefix}.date`}
                    defaultValue={run.date}
                // disabled
                />

            </Col>

            <Col col={{ xs: 24, md: 6 }}>

                <FieldAutocomplete
                    form={form}
                    label={"Condition"}
                    name={`${prefix}.condition`}
                    defaultValue={run.condition}
                    options={[
                        { value: "Initial Assessment", label: "Initial Assessment" },
                        { value: "Different Shoes", label: "Different Shoes" },
                        { value: "Outdoor Running", label: "Outdoor Running" },
                        { value: "Faster Run Speed", label: "Faster Run Speed" },
                        { value: "Slower Run Speed", label: "Slower Run Speed" },
                        { value: "Barefoot Running", label: "Barefoot Running" },
                        { value: "G8 Insoles", label: "G8 Insoles" },
                        { value: "Push Insoles", label: "Push Insoles" },
                        { value: "Corrective Insoles", label: "Corrective Insoles" },
                        { value: "In-Shoe Wedges", label: "In-Shoe Wedges" },
                    ]}
                    wrapClass={'manual-input'}
                    group={"condition"}
                    preload={true}
                    sort={false}
                />

            </Col>

        </Row>


    ), [props, form.hash]);

}

export default GaitsEditTabsRunDetails;
