import React from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";

import styles from "./ReportCheck.module.scss";

const ReportCheck: React.FC<ReportCheckProps> = (props) => {

    let { checked, radio } = props;

    const CkechedEl = radio ? MdRadioButtonChecked : MdCheckBox;
    const UncheckedEl = radio ? MdRadioButtonUnchecked : MdCheckBoxOutlineBlank;

    let Element = <UncheckedEl className={styles.icon} />;

    if (Array.isArray(checked)) checked = checked[0];

    if (checked === true || checked === "yes") Element = <CkechedEl className={styles.iconChecked} />;

    return (

        <div className={styles.check}>

            {Element}

        </div>

    )

}

interface ReportCheckProps {
    [key: string]: any,
}

export default ReportCheck;