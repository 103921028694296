import React, { useMemo } from 'react';
import { Panel } from '@dex/bubl-dash';
import FieldSessionsReportLinks from '../Components/FieldSessionsReportLinks';

const FieldResultsLink: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Panel
            heading={data.title}
        >

            <FieldSessionsReportLinks
                data={data}
                isReportPage={true}
            />

        </Panel>

    ), [props]);

}

export default FieldResultsLink;
