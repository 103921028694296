import { Col, Line, MetaTable, Space } from '@dex/bubl-dash';
import { Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import styles from "./CoachingReportAthlete.module.scss";
import { date, nl2br } from '@dex/bubl-helpers';
import moment from 'moment';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import ReportCheck from '../../../elements/ReportCheck/ReportCheck';
import { getClockTime } from '../../../functions/sharedTests';

const CoachingReportAthlete: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const events = data.general?.preferredEvent;

    const environments = data.general?.trainingMode;

    const equipments = [
        { value: "Road Bike" },
        { value: "TT Bike" },
        { value: "MTB" },
        { value: "Smart Trainer" },
        { value: "Treadmill" },
        { value: "Powermeter" },
        { value: "HR Monitor" },
        { value: "Kickboard" },
        { value: "Pull Buoy" },
        { value: "Paddles" }
    ];

    const facilities = [
        { value: "Pool - 50m" },
        { value: "Pool - 25m" },
        { value: "Pool - Other" },
        { value: "Open Water" },
        { value: "Running Track" },
        { value: "Running Trails" },
        { value: "Gym" }
    ];

    const programs = [
        { value: "Training Peaks" },
        { value: "Garmin" },
        { value: "TrainerLoad" },
        { value: "Zwift" },
        { value: "Wahoo" },
        { value: "Perfpro" },
    ];

    const isEquipmentInData = (equipment) => {
        return data.general?.equipment?.some((item) => item === equipment);
    };

    const isFacilitiesInData = (facilities) => {
        return data.general?.facilities?.some((item) => item === facilities);
    };

    const isProgramInData = (program) => {
        return data.general?.program?.some((item) => item === program);
    };

    return useMemo(() => (

        <Row edge gutter={"auto"}>

            <Col col={{ xs: 14 }}>

                <CoachingReportHeading
                    text={data.athlete?.fullName}
                />

                <ReportMetaTable
                    columnSize={{ xs: 12 }}
                    data={data}
                    columnA={[
                        { label: "Gender", key: "athlete.gender", format: "titleCase" },
                        { label: "Age", key: "athlete.age", format: "number", suffix: " yrs" },
                        { label: "DOB", key: "athlete.dob", format: "date" },
                    ]}
                    columnB={[
                        { label: "Sport", key: "general.sport", format: "titleCase" },
                        { label: "Level", key: "general.level", format: "titleCase" },
                        { label: "Years In Sport", key: "general.experience" },
                    ]}
                />

            </Col>

            <Col col={{ xs: 10 }}>

                <CoachingReportHeading
                    text="Current Biodata"
                />

                <ReportMetaTable
                    data={data}
                    columnSize={{ xs: 12 }}
                    columnA={[
                        { label: "Weight", key: "general.weight", format: "number", suffix: " kg" },
                        { label: "Height", key: "general.height", format: "number", suffix: "cm" },
                    ]}
                    columnB={[
                        { label: "% Body Fat", key: "general.bodyFat", format: "number", suffix: " %" },
                        { label: "Nutrition Diet", key: "general.glycogenDiet" },
                    ]}
                />

            </Col>

            <Col col={{ xs: 14 }}>

                <CoachingReportHeading
                    text="Performance Goals"
                    icons={require('../../../assets/images/Coaching/performanceGoals.png')}
                />

                <p style={{ lineBreak: "anywhere" }}>{data.general?.performanceGoals}</p>

            </Col>

            <Col col={{ xs: 10 }}>

                <CoachingReportHeading
                    text="Preferred Events"
                    icons={require('../../../assets/images/Coaching/preferredEvents.png')}
                />

                <Row edge gutter={16}>

                    {events && events.map((event, i) => (
                        <Col col={{ xs: 8, lg: 12 }}>- {event}</Col >
                    ))}

                </Row>

            </Col>

            <Col col={{ xs: 14 }}>

                <CoachingReportHeading
                    text="Training Avaibility"
                    icons={require('../../../assets/images/Coaching/trainingAvailability.png')}
                />

                <ReportMetaTable
                    data={data}
                    columnSize={{ xs: 24 }}
                    columnA={[
                        { label: "Days/Times", key: "general.trainingAvailability", format: "nl2br" },
                        { label: "Training Hours", key: "general.trainingHour" },
                    ]}
                />

            </Col>

            <Col col={{ xs: 10 }}>

                <CoachingReportHeading
                    text="Preferred Environment"
                    icons={require('../../../assets/images/Coaching/indoorOutdoor.png')}
                />

                <Row edge gutter={16}>

                    <Col col={{ xs: 16 }} />

                    <Col col={{ xs: 4 }}>
                        <p className={styles.heading}>
                            Bike
                        </p>
                    </Col>

                    <Col col={{ xs: 4 }}>
                        <p className={styles.heading}>
                            Run
                        </p>
                    </Col>

                    {environments && environments.map((environment, i) => (
                        <>
                            <Col col={{ xs: 16 }}>
                                {environment.label}
                            </Col >
                            <Col col={{ xs: 4 }}>
                                <ReportCheck radio checked={environment.bike} />
                            </Col>
                            <Col col={{ xs: 4 }}>
                                <ReportCheck radio checked={environment.run} />
                            </Col>
                        </>
                    ))}

                </Row>

            </Col>

            <Col col={{ xs: 24 }}>

                <Row edge gutter={"auto"}>

                    <Col col={{ xs: 14 }}>

                        <CoachingReportHeading
                            text="Accessible For Training"
                            classes="has-image"
                        />

                        <Row edge gutter={8}>

                            <Col col={{ xs: 4 }}></Col>

                            <Col col={{ xs: 20 }} justify='center'>
                                <p className={styles.heading}>Equipment:</p>
                            </Col>

                            <Col col={{ xs: 4 }}><img className={styles.equipmentImage} alt='equipment' src={require('../../../assets/images/Coaching/equipment.png')} /></Col>

                            <Col col={{ xs: 20 }} justify='center'>

                                <Row edge gutter={8}>

                                    {equipments && equipments.map((equipment, i) => (

                                        <Col col={{ xs: 8 }} >
                                            <div className={styles.checkbox}>
                                                <ReportCheck checked={isEquipmentInData(equipment.value)} />
                                                {equipment.value}
                                            </div>
                                        </Col>

                                    ))}

                                </Row>

                            </Col>

                            <Col col={{ xs: 4 }}></Col>

                            <Col col={{ xs: 20 }} justify='center'>
                                <p className={styles.heading}>Facilities:</p>
                            </Col>

                            <Col col={{ xs: 4 }}><img className={styles.equipmentImage} alt='equipment' src={require('../../../assets/images/Coaching/facilities.png')} /></Col>

                            <Col col={{ xs: 20 }} justify='center'>

                                <Row edge gutter={8}>

                                    {facilities && facilities.map((facility, i) => (

                                        <Col col={{ xs: 8 }} >
                                            <div className={styles.checkbox}>
                                                <ReportCheck checked={isFacilitiesInData(facility.value)} />
                                                {facility.value}
                                            </div>
                                        </Col>

                                    ))}

                                </Row>

                            </Col>

                            <Col col={{ xs: 4 }}></Col>

                            <Col col={{ xs: 20 }} justify='center'>
                                <p className={styles.heading}>Program:</p>
                            </Col>

                            <Col col={{ xs: 4 }}><img className={styles.equipmentImage} alt='equipment' src={require('../../../assets/images/Coaching/programs.png')} /></Col>

                            <Col col={{ xs: 20 }} justify='center'>

                                <Row edge gutter={8}>

                                    {programs && programs.map((program, i) => (

                                        <Col col={{ xs: 8 }} >
                                            <div className={styles.checkbox}>
                                                <ReportCheck checked={isProgramInData(program.value)} />
                                                {program.value}
                                            </div>
                                        </Col>

                                    ))}

                                </Row>

                            </Col>

                            <Col col={{ xs: 4 }}></Col>

                            <Col col={{ xs: 20 }} justify='center'>
                                <p className={styles.heading}>Other:</p>
                            </Col>

                            <Col col={{ xs: 4 }}></Col>

                            <Col col={{ xs: 20 }} >
                                <span>{nl2br(data.general?.otherTraining)}</span>
                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 10 }}>

                        <Row edge gutter={"auto"}>

                            <Col col={{ xs: 24 }}>

                                <CoachingReportHeading
                                    text="Total Training Stats"
                                    icons={require('../../../assets/images/Coaching/trainingStats.png')}
                                />

                                <table style={{ width: "100%" }}>

                                    <tr>
                                        <td></td>
                                        <td>
                                            <div className={styles.heading}>
                                                <p className={styles.heading}>This Year</p>
                                                <Line top={"none"} bottom={"small"} />
                                            </div>
                                        </td>

                                        <td>
                                            <div className={styles.heading}>
                                                <p className={styles.heading}>From Start</p>
                                                <Line top={"none"} bottom={"small"} />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <p className={styles.heading}>
                                                Date:
                                            </p>
                                        </td>

                                        <td>{moment().format("YYYY")}</td>

                                        <td>{date(data.general?.startDate)}</td>

                                    </tr>

                                    <tr>
                                        <td>
                                            <p className={styles.heading}>
                                                Hours:
                                            </p>
                                        </td>

                                        <td>{getClockTime(data?.total?.thisYear?.duration / 60)}</td>

                                        <td>{getClockTime(data?.total?.fromStart?.duration / 60)}</td>

                                    </tr>

                                    <tr>
                                        <td>
                                            <p className={styles.heading}>
                                                KM:
                                            </p>
                                        </td>

                                        <td>{data?.total?.thisYear?.distance}</td>

                                        <td>{data?.total?.fromStart?.distance}</td>

                                    </tr>

                                </table>

                            </Col>

                            <Col col={{ xs: 24 }}>
                                <Space />
                            </Col>

                            <Col col={{ xs: 24 }}>

                                <CoachingReportHeading
                                    text="Coach"
                                    icons={require('../../../assets/images/Coaching/coach.png')}
                                />

                                <ReportMetaTable
                                    data={data}
                                    columnA={[
                                        { label: "Name", key: "coach.name" },
                                    ]}
                                    columnB={[
                                        { label: "Since", key: "general.startDate", render: (value) => date(value) },
                                    ]}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

            </Col>

        </Row >

    ), [data]);

}


export default CoachingReportAthlete;