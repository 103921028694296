import { clone, loop } from '@dex/bubl-helpers';
import React, { useContext } from 'react';
import { Area, Bar, CartesianGrid, Label, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { fancyTimeFormat } from '../../functions/sharedChart';
import ChartProps from './Chart.Props';
import ReportContext from '../../context/ReportContext';
import ChartLegend from './ChartLegend';

class ChartAxis {

    x: axis = {
        index: 6,
        key: "time",
        suffix: "",
        label: "Time - M",
        format: "time",
    };

    y: Array<axis> = [

    ];

    legend: boolean | any = true;

    tooltip: boolean | any = true;

    grid: boolean | any = true;

    constructor() {

    }

    setXByActivity = (activity, intensity) => {

        activity = activity.toLowerCase();
        intensity = (intensity || "").toLowerCase();

        if (activity === "running") {

            if (intensity === "peak") {

                this.x = {
                    key: "time",
                    index: 6,
                    label: "Time - min",
                    props: {
                        tickFormatter: fancyTimeFormat
                    },

                };

            } else {

                this.x = {
                    key: "speed",
                    index: 6,
                    label: "Speed - kph",
                    props: {
                        // type: "number",
                        // domain: ['dataMin', 'dataMax'],
                    },

                };

            }


        } else if (activity === "time") {

            this.x = {
                key: "time",
                index: 6,
                label: "Time - min",
                props: {
                    // type: "number",
                    // domain: [0, 'dataMax'],
                    tickFormatter: fancyTimeFormat
                }
            };

        } else if (activity === "date" || activity === "history") {

            this.x = {
                key: "date",
                index: 6,
                label: "Session Date",
                props: {
                    interval: 0,
                }
            };

        } else {

            this.x = {
                key: "power_raw",
                index: 6,
                label: "Power - w",
                props: {
                    // type: "number",
                    // domain: ['dataMin', 'dataMax'],
                    // tickFormatter: Math.round
                }
            };

        }

    }

    prepareHistoryData = (data, keyA, keyB) => {

        const history: any = [];

        const keyX = this.x.key || "";

        loop(clone(data), (row) => {

            const rowA: any = [];
            const rowB: any = [];

            loop(row, (value, key) => {

                const keyDate = key.substr(key.length - 11, key.length);
                const keyStr = key.substr(0, key.length - 12);

                if (keyDate === keyA) rowA[key] = value;
                if (keyDate === keyB) rowB[key] = value;

                if (key === keyX + "_" + keyA) rowA[keyX] = value;
                if (key === keyX + "_" + keyB) rowB[keyX] = value;

            });

            history.push(rowA);
            history.push(rowB);

        });

        this.x.props = {
            ...this.x.props,
            type: "number",
            ticks: data.map((item) => item[this.x.key || "x"]),
            domain: ["dataMin", "dataMax"],
            interval: 0,
        }

        return history;

    };

    prepareTrackingData = (data, type) => {

        const tracking: any = [];

        if (type === "sprint") {

            // include sprint only
            loop(clone(data), (row) => {

                if (row.type === "Sprint") tracking.push(row);

            });

        } else {

            // exclude sprint
            loop(clone(data), (row) => {

                if (row.type !== "Sprint") tracking.push(row);

            });

        }

        return tracking;

    };

    getAxisFromKey = (key) => {

        if (key === this.x.key) return this.x;

        let axis: any = null;

        loop(this.y, (y) => {

            if (y.key === key) axis = y;

        });

        return axis;

    }

    formatName = (name) => {

        const axis = this.getAxisFromKey(name);

        if (axis) name = axis.label;

        return name;

    }

    formatValue = (value, name) => {

        const axis = this.getAxisFromKey(name);

        if (axis) {

            if (axis?.props?.tickFormatter) value = axis.props.tickFormatter(value);

        }

        return value;

    }

    render = () => {

        const context = useContext(ReportContext);

        return (
            <>

                {this.grid !== false &&
                    <CartesianGrid
                        {...ChartProps.CartesianGrid()}
                        {...this.grid}
                    />
                }

                {this.tooltip !== false &&
                    <Tooltip
                        labelFormatter={(value) => {

                            let label = "";

                            label += this.formatName(this.x.key);
                            label += " : ";
                            label += this.formatValue(value, this.x.key);

                            return label;

                        }}
                        formatter={(value, name, props) => {

                            const axis = this.getAxisFromKey(name);

                            if (axis && axis?.hideToolTip) return [];

                            return [this.formatValue(value, name), this.formatName(name)];

                        }}
                        cursor={false}
                        {...this.tooltip}
                    />
                }

                {this.x.key &&
                    <XAxis
                        {...ChartProps.XAxis(this.x.index, this.x.axisLabel || this.x.label, this.x.props)}
                        dataKey={this.x.key}
                    />
                }

                {this.y.map((y, i) => (
                    <React.Fragment key={y.key || i}>
                        {y.axis &&
                            <YAxis
                                {...ChartProps.YAxis(y.index, y.axisLabel || y.label, y.pos, y.props)}
                            />
                        }

                        {y.line &&
                            <Line
                                label={context.report ? ChartProps.Label({}, y.props, y) : undefined}
                                {...ChartProps.Line(y.index, y.pos, y.props)}
                                dataKey={y.key}
                                data={y.data}
                            />
                        }

                        {y.circle &&
                            <Line
                                label={context.report ? ChartProps.Label({}, y.props, y) : undefined}
                                {...ChartProps.Circle(y.index, y.pos, y.props)}
                                dataKey={y.key}
                                data={y.data}
                            />
                        }

                        {y.area &&
                            <Area
                                label={context.report ? ChartProps.Label({}, y.props, y) : undefined}
                                {...ChartProps.Area(y.index, y.pos, y.props)}
                                dataKey={y.key}
                                data={y.data}
                            />
                        }

                        {y.bar &&
                            <Bar
                                label={context.report ? ChartProps.Label({}, y.props, y) : undefined}
                                {...ChartProps.Bar(y.index, y.pos, y.props)}
                                dataKey={y.key}
                                data={y.data}
                            />
                        }

                    </React.Fragment>
                ))}

                {this.legend !== false &&
                    <ChartLegend
                        verticalAlign="bottom"
                        height={36}
                        align={"right"}
                        iconSize={24}
                        items={this.y}
                        formatter={(value, entry, props) => {

                            return this.formatName(value);

                        }}
                        {...this.legend}
                    />
                }

            </>

        )

    }

}


interface axis {
    key: string | null,
    pos?: string,
    [key: string]: any,
}


export default ChartAxis;