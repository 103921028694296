import { date, get, hash, loop } from '@dex/bubl-helpers';
import React, { useCallback, useEffect } from 'react';
import FieldTestsApi from '../FieldTestsApi';
import { Col, FieldNumber, Row, Panel, InputTable, InputTableRow, InputTableCell, Space, FieldSelect, FieldCheckbox, useForm, Button, useApp, ButtonRow } from '@dex/bubl-dash';
import qs from 'query-string';
import { DataTableCell } from '@dex/bubl-dash/src/lib/components/DataTable/DataTableRow';

const FieldTestsScores: React.FC<FieldTestsScoresProps> = (props) => {

    const { form, data, session } = props;

    const tests = FieldTestsApi.format(data.id, "getTestList");

    const filter = useForm({ hideColumn: [] });

    const app = useApp();

    useEffect(() => {

        const enabled: any = [];

        loop(form.values?.tests?.enabled || [], (value, key) => {

            if (value) enabled.push(key);

        });

        tests.run({
            params: { enabled: enabled || [], noTrials: true }
        });

    }, [hash(form.values.tests.list)]);

    const athletes = session.athletes || [];

    const columns: any = [
        {
            id: "no",
            label: "#",
        },
        {
            id: "athlete",
            label: "Athlete Name",
        },
        {
            id: "date",
            label: "Test Date",
        },
    ];

    tests?.data?.forEach(test => {

        if (test.trial <= 1 && test.score) {

            if (filter.values.hideColumn && filter.values.hideColumn.includes(test.id)) return;

            columns.push({
                label: test.name,
                id: test.id,
                className: test.isFirst ? "table-bdr-left-gray" : ""
            });

        }

    });

    const options = tests?.data?.reduce((acc, test) => {

        if (test.isChild) return acc;

        if (test.trial <= 1 && test.score || test.isParent) {

            acc.push({
                label: test.name,
                value: test.id,
            });

        }

        return acc;

    }, []);

    const handleDownloadFile = useCallback((enabled, type) => {

        const params: any = { enabled: [] };

        loop(enabled, (value, key) => {

            if (!value) return;

            params.enabled.push(key);

        });

        const query = qs.stringify(params, { arrayFormat: 'bracket' });

        window.open(app.options.endpoint.slice(0, -4) + `/field-tests/download/${type}/${data.id}?` + query, "_blank");

        return;

    }, []);

    return (

        <>
            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldCheckbox
                        form={filter}
                        name="hideColumn"
                        defaultValue={filter.defaults.hideColumn}
                        label='Hide Column'
                        options={options}
                    />

                    <Space />

                    <InputTable
                        columns={columns}
                        style={{ overflow: "auto" }}
                    >

                        {athletes.map((athlete, i) => (

                            <InputTableRow>

                                <DataTableCell>
                                    {i + 1}
                                </DataTableCell>

                                <DataTableCell>
                                    {athlete.callName}
                                </DataTableCell>

                                <DataTableCell>
                                    {date(data.date)}
                                </DataTableCell>

                                {tests.data && tests.data.map((test, index) => {

                                    if (!test.score) return <React.Fragment key={index}></React.Fragment>;
                                    if (test.trial > 1) return <React.Fragment key={index}></React.Fragment>;
                                    if (filter.values?.hideColumn?.includes(test.id)) return null;

                                    let className = "";

                                    if (test.isFirst) className += "table-bdr-left-gray";

                                    return (
                                        <InputTableCell
                                            key={index}
                                            className={className}
                                        >

                                            <FieldNumber
                                                form={form}
                                                // label={test.name}
                                                name={`scores.${athlete.id}.${test.index}`}
                                                defaultValue={get(form.defaults, `scores.${athlete.id}.${test.index}`)}
                                                suffix={test.units ? " " + test.units : ""}
                                                allowNegative={true}
                                            />
                                        </InputTableCell>
                                    )
                                })}

                            </InputTableRow>

                        ))}

                    </InputTable>

                    <Space />

                    <ButtonRow>

                        <Button
                            label="Export Saved Data"
                            type="secondary"
                            onClick={handleDownloadFile.bind(null, form.values.tests.enabled, "export")}
                        />

                    </ButtonRow>

                </Col>

            </Row>

        </>

    )

}

interface FieldTestsScoresProps {
    [key: string]: any,
}

export default FieldTestsScores;