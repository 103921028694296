import React, { useMemo, useState } from 'react';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import { Col, InputTableCell, InputTableRow, Row, Space } from '@dex/bubl-dash';
import InputTable from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';

const SessionsEditTabsBikePressure: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;

    const columns = [
        {
            key: "prop",
            label: "Property / Description",
            style: { width: 120 }
        },
        {
            key: "pre",
            label: "Pre",
            style: { width: 120 }
        },
        {
            key: "prost",
            label: "Post",
            style: { width: 120 }
        },
    ]

    return useMemo(() => (

        <>

            <InputTable
                columns={columns}
            >

                <InputTableRow>

                    <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                        Maximum Load:
                    </InputTableCell>

                    <InputTableCell style={{ width: 220 }}>
                        <FieldSelect
                            button={true}
                            form={form}
                            name={`${prefix}.preFitMaximumLoad`}
                            required={false}
                            defaultValue={bike.preFitMaximumLoad}
                            options={[
                                { value: "Very High (>1000 mbar)", label: "Very High (>1000 mbar)" },
                                { value: "High (800-1000 mbar)", label: "High (800-1000 mbar)" },
                                { value: "Moderately High (600-800 mbar)", label: "Moderately High (600-800 mbar)" },
                                { value: "Moderate (400-600 mbar)", label: "Moderate (400-600 mbar)" },
                                { value: "Low (300-400 mbar)", label: "Low (300-400 mbar)" },
                                { value: "Very Low (<300 mbar)", label: "Very Low (<300 mbar)" }
                            ]}

                        />

                    </InputTableCell>

                    <InputTableCell style={{ width: 220 }}>
                        <FieldSelect
                            button={true}
                            form={form}
                            name={`${prefix}.postFitMaximumLoad`}
                            required={false}
                            defaultValue={bike.postFitMaximumLoad}
                            options={[
                                { value: "Very High (>1000 mbar)", label: "Very High (>1000 mbar)" },
                                { value: "High (800-1000 mbar)", label: "High (800-1000 mbar)" },
                                { value: "Moderately High (600-800 mbar)", label: "Moderately High (600-800 mbar)" },
                                { value: "Moderate (400-600 mbar)", label: "Moderate (400-600 mbar)" },
                                { value: "Low (300-400 mbar)", label: "Low (300-400 mbar)" },
                                { value: "Very Low (<300 mbar)", label: "Very Low (<300 mbar)" }
                            ]}
                        />
                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                        Fore-Aft:
                    </InputTableCell>

                    <InputTableCell style={{ width: 220 }}>
                        <FieldSelect
                            button={true}
                            form={form}
                            name={`${prefix}.preFitForeAft`}
                            required={false}
                            defaultValue={bike.preFitForeAft}
                            options={[
                                { value: "Front-Loaded", label: "Front-Loaded" },
                                { value: "Rear-Loaded", label: "Rear-Loaded" },
                                { value: "Centrally-Loaded", label: "Centrally-Loaded" },
                                { value: "Not Measured", label: "Not Measured" }
                            ]}
                        />
                    </InputTableCell>

                    <InputTableCell style={{ width: 220 }}>
                        <FieldSelect
                            button={true}
                            form={form}
                            name={`${prefix}.postFitForeAft`}
                            required={false}
                            defaultValue={bike.postFitForeAft}
                            options={[
                                { value: "Front-Loaded", label: "Front-Loaded" },
                                { value: "Rear-Loaded", label: "Rear-Loaded" },
                                { value: "Centrally-Loaded", label: "Centrally-Loaded" },
                                { value: "Not Measured", label: "Not Measured" }
                            ]}
                        />
                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                        Side-to-Side:
                    </InputTableCell>

                    <InputTableCell style={{ width: 220 }}>
                        <FieldSelect
                            button={true}
                            form={form}
                            name={`${prefix}.preFitSideToSide`}
                            required={false}
                            defaultValue={bike.preFitSideToSide}
                            options={[
                                { value: "Left-Side Greater", label: "Left-Side Greater" },
                                { value: "Right-Side Greater", label: "Right-Side Greater" },
                                { value: "Balanced", label: "Balanced" },
                                { value: "Not Measured", label: "Not Measured" },
                            ]}
                        />
                    </InputTableCell>

                    <InputTableCell style={{ width: 220 }}>
                        <FieldSelect
                            button={true}
                            form={form}
                            name={`${prefix}.postFitSideToSide`}
                            required={false}
                            defaultValue={bike.postFitSideToSide}
                            options={[
                                { value: "Left-Side Greater", label: "Left-Side Greater" },
                                { value: "Right-Side Greater", label: "Right-Side Greater" },
                                { value: "Balanced", label: "Balanced" },
                                { value: "Not Measured", label: "Not Measured" },
                            ]}
                        />
                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                        Fit Stability:
                    </InputTableCell>

                    <InputTableCell style={{ width: 220 }}>
                        <FieldSelect
                            button={true}
                            form={form}
                            name={`${prefix}.preFitStability`}
                            required={false}
                            defaultValue={bike.preFitStability}
                            options={[
                                { value: "Lots of Shifting", label: "Lots of Shifting" },
                                { value: "Some Shifting", label: "Some Shifting" },
                                { value: "Minimal Shifting", label: "Minimal Shifting" },
                                { value: "Not Measured", label: "Not Measured" }
                            ]}
                        />
                    </InputTableCell>

                    <InputTableCell style={{ width: 220 }}>
                        <FieldSelect
                            button={true}
                            form={form}
                            name={`${prefix}.postFitStability`}
                            required={false}
                            defaultValue={bike.postFitStability}
                            options={[
                                { value: "Lots of Shifting", label: "Lots of Shifting" },
                                { value: "Some Shifting", label: "Some Shifting" },
                                { value: "Minimal Shifting", label: "Minimal Shifting" },
                                { value: "Improved", label: "Improved" },
                                { value: "Unchanged", label: "Unchanged" },
                                { value: "Worsened", label: "Worsened" },
                                { value: "Not Measured", label: "Not Measured" }
                            ]}
                        />
                    </InputTableCell>

                </InputTableRow>

                <InputTableRow>

                    <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                        Overall Rating:
                    </InputTableCell>

                    <InputTableCell style={{ width: 220 }}>
                        <FieldSelect
                            button={true}
                            form={form}
                            name={`${prefix}.preFitOverallRating`}
                            required={false}
                            defaultValue={bike.preFitOverallRating}
                            options={[
                                { value: "Ideal", label: "Ideal" },
                                { value: "Good", label: "Good" },
                                { value: "Fair", label: "Fair" },
                                { value: "Poor", label: "Poor" },
                                { value: "Not Measured", label: "Not Measured" }
                            ]}
                        />
                    </InputTableCell>

                    <InputTableCell style={{ width: 220 }}>
                        <FieldSelect
                            button={true}
                            form={form}
                            name={`${prefix}.postFitOverallRating`}
                            required={false}
                            defaultValue={bike.postFitOverallRating}
                            options={[
                                { value: "Ideal", label: "Ideal" },
                                { value: "Good", label: "Good" },
                                { value: "Fair", label: "Fair" },
                                { value: "Poor", label: "Poor" },
                                { value: "Improved", label: "Improved" },
                                { value: "Unchanged", label: "Unchanged" },
                                { value: "Worsened", label: "Worsened" },
                                { value: "Not Measured", label: "Not Measured" }
                            ]}
                        />
                    </InputTableCell>

                </InputTableRow>

            </InputTable>

            <Space />

            <Row gutter={16} edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldUpload
                        form={form}
                        name={`${prefix}.pressurePhoto`}
                        folder='admin-media'
                        label='Saddle Pressure Photo'
                        defaultValue={bike.pressurePhoto}
                        multiple={false}
                        accept='image/*'
                    />

                </Col>

            </Row>

            <Row edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldTextArea
                        form={form}
                        label={"Saddle Pressure Comments"}
                        name={`${prefix}.saddlePressureComments`}
                        defaultValue={bike.saddlePressureComments}
                        rows={4}
                        maxLength={450}
                    />

                </Col>

            </Row>

        </>


    ), [props]);

}

export default SessionsEditTabsBikePressure;
