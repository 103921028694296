import { Row, Col, Box, FieldSelect, FieldCheckbox, FieldRadio, useForm, FieldAutocomplete, useUpdateEffect, Space } from '@dex/bubl-dash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import ChartCoachingWorkoutBikeInterval from '../../../elements/ChartCoaching/ChartCoachingWorkoutBikeInterval';
import ChartCoachingWorkoutBikeEndurance from '../../../elements/ChartCoaching/ChartCoachingWorkoutBikeEndurance';
import ChartCoachingWorkoutBikeSummary from '../../../elements/ChartCoaching/ChartCoachingWorkoutBikeSummary';
import CoachingReportWeeklyAverage from '../../../elements/CoachingReportWeeklyAverage/CoachingReportWeeklyAverage';
import CoachingReportAverageTable from '../../../elements/CoachingReportAverageTable/CoachingReportAverageTable';
import { PeriodFilter } from './CoachingReportTracking';
import CoachingApi from '../CoachingApi';
import { getClockTime } from '../../../functions/sharedTests';
import ChartProps from '../../../elements/Chart/Chart.Props';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import { fixedNum, get, hashCode, set, unslug } from '@dex/bubl-helpers';

const CoachingReportWorkoutsBike: React.FC<any> = (props: any) => {

    const { data, form, type } = props;

    const filter = useForm({ period: "last-3-months", interval: { duration: "60-180 sec" }, endurance: {} });

    const [trackingData, setTrackingData]: any = useState([]);
    const fetch = CoachingApi.getReportData(data.id);

    useEffect(() => {

        fetch.run({
            params: { filters: filter.values, type: "workouts-bike" },
            onComplete: (response: any) => {

                setTrackingData(response);

            }

        })

    }, [filter.values.to, filter.values.from, hashCode(filter.values.interval), hashCode(filter.values.endurance)]);

    const handleChange = (name, resetName, value) => {

        filter.handleChange({ name: name, value: value, reset: true });

        filter.handleChange({ name: resetName, value: "", reset: true });

    };

    const enduranceDuration = [
        { label: "<30 min", value: "<30 min" },
        { label: "30-60 min", value: "30-60 min" },
        { label: "1-2 hrs", value: "60-120 min" },
        { label: "2-4 hrs", value: "120-240 hrs" },
        { label: "4-6 hrs", value: "240-460 hrs" },
        { label: "6-10 hrs", value: "460-600 hrs" },
        { label: ">10 hrs", value: ">600 hrs" }
    ];

    const enduranceDistance = [
        { label: "<50 KM", value: "<50 KM" },
        { label: "50-100 KM", value: "50-100 KM" },
        { label: "100-150 KM", value: "100-150 KM" },
        { label: "150-200 KM", value: "150-200 KM" },
        { label: ">200 KM", value: ">200 KM" },
    ];

    const intervalDuration = [
        { label: "<30 sec", value: "<30 sec" },
        { label: "30-60 sec", value: "30-60 sec" },
        { label: "1-3 min", value: "60-180 sec" },
        { label: "3-5 min", value: "180-300 sec" },
        { label: "5-10 min", value: "300-600 sec" },
        { label: "10-20 min", value: "600-1200 sec" },
        { label: ">20 min", value: ">1200 sec" }
    ]

    const intervalDistance = [
        { label: "<1 km", value: "<1 km" },
        { label: "1-3 km", value: "1-3 km" },
        { label: "3-5 km", value: "3-5 km" },
        { label: "5-10 km", value: "5-10 km" },
        { label: ">10 km", value: ">10 km" },
    ];

    const getLabelForValue = (value, options) => {

        const duration = options.find(opt => opt.value === value);

        return duration ? duration.label : "";

    };

    const enduranceDesc = filter.values?.endurance?.duration ?
        `-  ${getLabelForValue(filter.values?.endurance?.duration, enduranceDuration)} ` :
        filter.values?.endurance?.distances ? `-  ${getLabelForValue(filter.values?.endurance?.distances, enduranceDistance)}` : ""

    const intervalDesc = filter.values?.interval?.duration ?
        `-  ${getLabelForValue(filter.values?.interval?.duration, intervalDuration)} ` :
        filter.values?.interval?.distances ? `-  ${getLabelForValue(filter.values?.interval?.distances, intervalDistance)}` : ""

    return useMemo(() => (

        <Row edge gutter={8}>

            <PeriodFilter data={data} filter={filter} />

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Bike Summary By Week"
                    classes="seperator"
                />

                <PanelChart
                    heading={"Bike Summary By Week"}
                    renderForm={(form, heading) => (

                        <Row edge gutter={8}>

                            <Col>
                                <FieldRadio
                                    form={form}
                                    name="bikeSummaryVolume"
                                    defaultValue={form.defaults?.bikeSummaryVolume}
                                    required
                                    // label='metric'
                                    options={[
                                        { label: "Hours", value: "Hours", color: ChartProps.indexColor(0) },
                                        { label: "KM", value: "KM", color: ChartProps.indexColor(1) },
                                        { label: "# Of Rides", value: "# Of Rides", color: ChartProps.indexColor(2) },
                                    ]}
                                />
                            </Col>

                            <Col>
                                <FieldCheckbox
                                    form={form}
                                    name="bikeSummaryIntensity"
                                    defaultValue={form.defaults?.bikeSummaryIntensity}
                                    wrapClass={'white-checkbox'}
                                    options={[
                                        { label: "HR Hours > Zone 2", value: "HR Hours > Zone 2", color: ChartProps.metricColor("HR Hours > Zone 2") },
                                        { label: "W Hours > Zone 2", value: "W Hours > Zone 2", color: ChartProps.metricColor("W Hours > Zone 2") },
                                    ]}
                                />
                            </Col>

                        </Row>

                    )}
                    addZero={false}
                    formDefaults={{ bikeSummaryVolume: "Hours", bikeSummaryIntensity: ["HR Hours > Zone 2"] }}
                    renderChart={(chartType, chartData, values) => (
                        <ChartCoachingWorkoutBikeSummary
                            data={trackingData.weeks}
                            type={chartType}
                            filters={values}
                            activity={type}
                            intensity={data.intensity}
                        />
                    )}
                />

            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text="Weekly Bike Summary"
                />

                <ReportDesc
                    className="no-box"
                    value={`Your total cycling statistics for each week are shown here- Select duration, mileage, intensity or workout count to view your changes over time. Targets met for each week are also given by symbols at the bottom of each column as well as overall averages for the time period selected.`}
                />

            </Col>

            <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                <CoachingReportWeeklyAverage
                    desc={unslug(filter.values?.period)}
                    columnA={[
                        { label: "Duration", value: getClockTime(trackingData?.averages?.duration / 60), suffix: "hh:mm" },
                        { label: "Mileage", value: fixedNum(trackingData?.averages?.distance, 1), suffix: "km" },
                        { label: "HR > Z2*", value: getClockTime(trackingData?.averages?.hrZone3 / 60), suffix: "hh:mm" },
                        { label: "W > Z2*", value: getClockTime(trackingData?.averages?.intensityDuration / 60), suffix: "hh:mm" }
                    ]}
                    columnANote="*Hours above Zone 2"
                    metTarget={trackingData?.averages?.distanceTargetMet}
                    belowTarget={trackingData?.averages?.distanceTargetAbove}
                    aboveTarget={trackingData?.averages?.distanceTargetBelow}
                    classes="mini"
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Endurance Rides"
                    classes="seperator"
                />

                <PanelChart
                    heading={"Endurance Rides"}
                    renderForm={(form, heading) => (

                        <Row edge gutter={8}>

                            <Col>
                                <FieldRadio
                                    form={form}
                                    name="bikeEnduranceDuration"
                                    defaultValue={form.defaults?.bikeEnduranceDuration}
                                    required
                                    options={[
                                        { label: "Hours", value: "Hours", color: ChartProps.indexColor(0) },
                                        { label: "KM", value: "KM", color: ChartProps.indexColor(1) },
                                    ]}
                                />
                            </Col>

                            <Col col={{ xs: 19 }}>
                                <FieldCheckbox
                                    form={form}
                                    name="bikeIntervalMetric"
                                    defaultValue={form.defaults?.bikeIntervalMetric}
                                    options={[
                                        { label: "Avg W", value: "Avg W", color: ChartProps.metricColor("Avg W") },
                                        { label: "Norm W", value: "Norm W", color: ChartProps.metricColor("Norm W") },
                                        { label: "Hrs Z2+", value: "Hrs Zone 2+", color: ChartProps.metricColor("Hrs Zone 2+") },
                                        { label: "Avg HR", value: "Avg HR", color: ChartProps.metricColor("Avg HR") },
                                        { label: "Peak HR", value: "Peak HR", color: ChartProps.metricColor("Peak HR") },
                                        { label: "Climbing M", value: "Climbing M", color: ChartProps.metricColor("Climbing M") },
                                        { label: "Avg KPH", value: "Avg KPH", color: ChartProps.metricColor("Avg KPH") },
                                    ]}
                                />
                            </Col>

                        </Row>

                    )}
                    addZero={false}
                    formDefaults={{ bikeEnduranceDuration: "Hours", bikeIntervalMetric: ["Avg W"] }}
                    renderChart={(chartType, chartData, values) => (
                        <ChartCoachingWorkoutBikeEndurance
                            data={trackingData?.endurances}
                            type={chartType}
                            filters={values}
                            activity={type}
                            intensity={data.intensity}
                        />
                    )}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Box
                    className='coaching-training-filters'
                    gutter={"auto"}
                >

                    <Row edge gutter={8}>

                        <Col col={{ xs: 6 }}>

                            <FieldSelect
                                form={filter}
                                name="endurance.mode"
                                defaultValue={filter.defaults?.endurance.mode}
                                //@ts-ignore
                                options={[
                                    // { label: "All", value: "all" },
                                    { label: "Road", value: "road" },
                                    { label: "Off-Road", value: "off-road" },
                                    { label: "Trainer", value: "trainer" },
                                    { label: "Other", value: "other" }
                                ]}
                                label="Mode"
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 6 }}>

                            <FieldSelect
                                form={filter}
                                name="endurance.type"
                                defaultValue={filter.defaults?.endurance.type}
                                options={[
                                    { label: "All", value: "all" },
                                    { label: "Training", value: "training" },
                                    { label: "Race Simulation", value: "race-simulation" },
                                    { label: "Event", value: "event" },
                                    { label: "Test", value: "test" },
                                    { label: "Other", value: "other" },
                                ]}
                                label="Type"
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 12 }}></Col>

                        <Col col={{ xs: 12 }}
                        >

                            <FieldRadio
                                key={filter.defaults?.endurance.duration}
                                form={filter}
                                name="endurance.duration"
                                defaultValue={filter.defaults?.endurance.duration}
                                label='Duration'
                                wrapClass={'manual-input'}
                                options={enduranceDuration}
                                onChange={handleChange.bind(null, "endurance.duration", "endurance.distances")}
                            />

                        </Col>

                        <Col col={{ xs: 12 }}
                        >

                            <FieldRadio
                                key={filter.defaults?.endurance.distances}
                                form={filter}
                                name="endurance.distances"
                                defaultValue={filter.defaults?.endurance.distances}
                                options={enduranceDistance}
                                label="Distances"
                                wrapClass={'manual-input'}
                                onChange={handleChange.bind(null, "endurance.distances", "endurance.duration")}
                            />

                        </Col>

                    </Row>

                </Box>

                <Space />

            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text="Endurance Rides"
                    desc={enduranceDesc}
                />

                <ReportDesc
                    className="no-box"
                    value={`This chart lists all the endurance or "Long" rides you have done thus far making it easier to see changes in your cycling fitness, volume or intensity over time. Select the measures you wish to view and filter down by time, distance or type.`}
                />

            </Col>

            <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                <CoachingReportWeeklyAverage
                    title="Average"
                    desc={unslug(filter.values?.period)}
                    columnA={[
                        { label: "Duration", value: getClockTime(trackingData?.enduranceAverages?.duration / 60), suffix: "hh:mm" },
                        { label: "Mileage", value: fixedNum(trackingData?.enduranceAverages?.distance, 1), suffix: "km" },
                        { label: "Speed", value: trackingData?.enduranceAverages?.avgSpeed, suffix: "kph" },
                        { label: "Power*", value: trackingData?.enduranceAverages?.powerAvg + " / " + trackingData?.enduranceAverages?.powerNorm, suffix: "w" }
                    ]}
                    columnANote="*Power is Avg and Norm"
                    columnB={[
                        { label: "HR**", value: trackingData?.enduranceAverages?.hrAvg + " / " + trackingData?.enduranceAverages?.hrPeak, suffix: "bpm" },
                        { label: "HR Z2+", value: getClockTime(trackingData?.enduranceAverages?.raceTime / 60), suffix: "hh:mm" },
                        { label: "Cadence", value: "", suffix: "rpm" },
                        { label: "Climbing", value: trackingData?.enduranceAverages?.elevation, suffix: "m" },
                    ]}
                    columnBNote="**HR is Avg and Peak"
                    columnBNotePlus="+ HR Hours at or above Zone 2"
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Interval Workouts - Bikes"
                    classes="seperator"
                />

                <PanelChart
                    heading={"Interval Workouts - Bikes"}
                    renderForm={(form, heading) => {

                        const handleIntervalChange = (name, resetName, value) => {

                            form.handleChange({ name: name, value: value, reset: true });

                            form.handleChange({ name: resetName, value: "", reset: true });

                        };
                        return (

                            <Row edge gutter={8}>

                                <Col>
                                    <FieldRadio
                                        key={form.defaults?.bikeIntervalDuration}
                                        form={form}
                                        name="bikeIntervalDuration"
                                        defaultValue={form.defaults?.bikeIntervalDuration}
                                        label='Duration'
                                        options={[
                                            { label: "Interval", value: "Interval", color: ChartProps.indexColor(0) },
                                            { label: "Total", value: "Total", color: ChartProps.indexColor(0) },
                                        ]}
                                        onChange={handleIntervalChange.bind(null, "bikeIntervalDuration", "bikeIntervalDistance")}
                                    />
                                </Col>

                                <Col>
                                    <FieldRadio
                                        key={form.defaults?.bikeIntervalDistance}
                                        form={form}
                                        name="bikeIntervalDistance"
                                        defaultValue={form.defaults?.bikeIntervalDistance}
                                        label='KM'
                                        options={[
                                            { label: "Interval", value: "Interval", color: ChartProps.indexColor(3) },
                                            { label: "Total", value: "Total", color: ChartProps.indexColor(1) },
                                        ]}
                                        onChange={handleIntervalChange.bind(null, "bikeIntervalDistance", "bikeIntervalDuration")}
                                    />
                                </Col>

                                <Col>
                                    <FieldCheckbox
                                        form={form}
                                        name="bikeIntervalMetric"
                                        defaultValue={form.defaults?.bikeIntervalMetric}
                                        wrapClass={'white-checkbox'}
                                        label='Metric'
                                        options={[
                                            { label: "Avg W", value: "Avg W", color: ChartProps.metricColor("Avg W") },
                                            { label: "Best W", value: "Best W", color: ChartProps.metricColor("Best W") },
                                            { label: "Avg HR", value: "Avg HR", color: ChartProps.metricColor("Avg HR") },
                                            { label: "Peak HR", value: "Peak HR", color: ChartProps.metricColor("Peak HR") },
                                        ]}
                                    />
                                </Col>

                            </Row>

                        )
                    }}
                    addZero={false}
                    formDefaults={{ bikeIntervalDuration: "Interval", bikeIntervalMetric: ["Avg W"] }}
                    renderChart={(chartType, chartData, values) => (
                        <ChartCoachingWorkoutBikeInterval
                            data={trackingData?.intervals}
                            type={chartType}
                            filters={values}
                            activity={type}
                            intensity={data.intensity}
                        />
                    )}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Box
                    className='coaching-training-filters'
                    gutter={"auto"}
                >

                    <Row edge gutter={8}>

                        <Col col={{ xs: 6 }}>

                            <FieldSelect
                                form={filter}
                                name="interval.mode"
                                defaultValue={filter.defaults?.interval.mode}
                                //@ts-ignore
                                options={[
                                    // { label: "All", value: "all" },
                                    { label: "Road", value: "road" },
                                    { label: "Off-Road", value: "off-road" },
                                    { label: "Trainer", value: "trainer" },
                                    { label: "Other", value: "other" }
                                ]}
                                label="Mode"
                                wrapClass={"manual-input"}
                            />

                        </Col>

                        <Col col={{ xs: 6 }}>

                            <FieldSelect
                                form={filter}
                                name="interval.type"
                                defaultValue={filter.defaults?.interval.type}
                                options={[
                                    { label: "All", value: "all" },
                                    { label: "Training", value: "training" },
                                    { label: "Race Simulation", value: "race-simulation" },
                                    { label: "Event", value: "event" },
                                    { label: "Test", value: "test" },
                                    { label: "Other", value: "other" },
                                ]}
                                label="Type"
                                wrapClass={'manual-input'}
                            />

                        </Col>

                        <Col col={{ xs: 6 }}>

                            <FieldAutocomplete
                                form={filter}
                                label="Intervals"
                                name="interval.intervals"
                                defaultValue={filter.defaults?.interval.intervals}
                                wrapClass={"manual-input"}
                                group='intervalsList'
                                preload
                                options={[
                                    { value: "Tempo-HR", label: "Tempo-HR" },
                                    { value: "Repeats", label: "Repeats" },
                                    { value: "Pyramids", label: "Pyramids" },
                                    { value: "Ladders", label: "Ladders" },
                                    { value: "Race Pace", label: "Race Pace" },
                                    { value: "Triple 3's", label: "Triple 3's" },
                                    { value: "Devil 6's", label: "Devil 6's" },
                                    { value: "Breakouts on Avg kph", label: "Breakouts on Avg kph" },
                                    { value: "1 min On/Offs", label: "1 min On/Offs" },
                                    { value: "30s On/Offs", label: "30s On/Offs" },
                                    { value: "40s:20s On/Offs", label: "40s:20s On/Offs" },
                                    { value: "Tempo-Sprint", label: "Tempo-Sprint" },
                                    { value: "Strength", label: "Strength" },
                                    { value: "Hills", label: "Hills" },
                                    { value: "Anaerobic Ladders", label: "Anaerobic Ladders" },
                                    { value: "Sprints - Short <15s)", label: "Sprints - Short <15s)" },
                                    { value: "Sprints - Long (15-30s)", label: "Sprints - Long (15-30s)" },
                                    { value: "Recovery", label: "Recovery" },
                                ]}
                                sort={false}
                            />

                        </Col>

                        <Col col={{ xs: 12 }}>

                            <FieldRadio
                                key={filter.defaults?.interval.duration}
                                form={filter}
                                name="interval.duration"
                                defaultValue={filter.defaults?.interval.duration}
                                label='Duration'
                                wrapClass={'manual-input'}
                                options={intervalDuration}
                                onChange={handleChange.bind(null, "interval.duration", "interval.ditances")}
                            />

                        </Col>

                        <Col col={{ xs: 12 }}>

                            <FieldRadio
                                key={filter.defaults?.interval.distances}
                                form={filter}
                                name="interval.distances"
                                defaultValue={filter.defaults?.interval.distances}
                                options={intervalDistance}
                                label="Distances"
                                wrapClass={'manual-input'}
                                onChange={handleChange.bind(null, "interval.distances", "interval.duration")}
                            />

                        </Col>

                    </Row>

                </Box>

                <Space />

            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text="Bike Interval Workouts"
                    desc={intervalDesc}
                />

                <ReportDesc
                    className="no-box"
                    value={`Interval Workouts are those that were completed to achieve a specifc training effect usually consisting of intervals or repeat efforts. Select the measures your wish to view and filter down to see how you did on specific key workouts over tinme.`}
                />

            </Col>

            <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                <CoachingReportAverageTable
                    title="Interval Average"
                    desc={unslug(filter.values?.period)}
                    avgATitle="Interval/Total"
                    avgA={[
                        { title: "Duration", valueA: getClockTime(trackingData?.intervalAverages?.intervalDuration / 3600), valueB: getClockTime(trackingData?.intervalAverages?.totalDuration / 60, "hh:mm:ss"), suffix: "h:mm:ss" },
                        { title: "Mileage", valueA: trackingData?.intervalAverages?.intervalDistance, valueB: trackingData?.intervalAverages?.totalDistance, suffix: "km" },
                    ]}
                    avgBTitle="Avg/Peak"
                    avgB={[
                        { title: "Power", valueA: trackingData?.intervalAverages?.powerAvg, valueB: trackingData?.intervalMax?.powerBest, suffix: "w" },
                        { title: "Speed", valueA: trackingData?.intervalAverages?.speedAvg, valueB: trackingData?.intervalMax?.speedBest, suffix: "kph" },
                        { title: "Heartrate", valueA: trackingData?.intervalAverages?.hrAvg, valueB: trackingData?.intervalMax?.hrPeak, suffix: "bpm" },
                    ]}
                />

            </Col>

        </Row>

    ), [data, form.hash, trackingData, filter.hash]);

}

export default CoachingReportWorkoutsBike;