import React from 'react';
import { BarChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartPerformanceTrackingSprint: React.FC<ChartPerformanceTrackingSprintProps> = (props) => {

    let { data, activity, date, index, intensity, chart, type } = props;

    const Axis = new ChartAxis();

    Axis.legend = false;

    Axis.setXByActivity("history", intensity);

    const trackingData = Axis.prepareTrackingData(data, 'sprint');

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    if (chart == "HR Peak") {

        if (type == "%Hrmax") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 5,
                key: "perfThresholdPercentHR",
                label: date,
                axisLabel: "HR - %"
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 5,
                key: "hrMax",
                label: date,
                axisLabel: "HR - Bpm"
            });
        }

    } else if (chart == "Peak Lactate") {

        if (type == "mmol/L") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 2,
                key: "peakLactate",
                label: date,
                axisLabel: "Lactate - mmol/L"
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 2,
                key: "peakVlaMax",
                label: date,
                axisLabel: "Lactate - mmol/L/sec"
            });
        }
    } else if (chart == "Rpm - 5s") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 1,
            key: "peakRpm5",
            label: date,
            axisLabel: "Rpm - 5s"
        });

    } else if (chart == "Rpm - 20s") {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 1,
            key: "peakRpm20",
            label: date,
            axisLabel: "Rpm - 20s"
        });

    } else if (chart == "Speed - 20s") {

        if (type == "speed") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "peakSpeed20",
                label: date,
                axisLabel: "Speed - kph"
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "peakPace20",
                label: date,
                axisLabel: "Speed - min/km"
            });
        }

    } else if (chart == "Speed - 5s") {

        if (type == "speed") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "peakSpeed5",
                label: date,
                axisLabel: "Speed - kph"
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "peakPace5",
                label: date,
                axisLabel: "Speed - min/km"
            });
        }

    } else if (chart == "Power - 20s") {

        if (type == "Watts") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "peakPower20",
                label: date,
                axisLabel: "Power - w"
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "peakPower20perKg",
                label: date,
                axisLabel: "Power - w/kg"
            });
        }

    } else if (chart == "Power - 5s") {

        if (type == "Watts") {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "peakPower5",
                label: date,
                axisLabel: "Power - w"
            });
        } else {
            Axis.y.push({
                axis: true,
                bar: true,
                pos: "left",
                index: 0,
                key: "peakPower5perKg",
                label: date,
                axisLabel: "Power - w/kg"
            });
        }

    }

    return (

        <Chart>

            <BarChart
                {...ChartProps.BarChart()}
                data={trackingData}
            >
                {Axis.render()}

            </BarChart>

        </Chart>


    )

}

interface ChartPerformanceTrackingSprintProps {
    [key: string]: any,
}

export default ChartPerformanceTrackingSprint;