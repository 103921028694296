import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import AthletesSingleSessionHistory from './AthletesSingleSessionHistory';

const AthletesSingleHistory: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col col={{ xs: 24 }}>

                <AthletesSingleSessionHistory data={data} />

            </Col>

        </Row>

    ), [data]);

}

export default AthletesSingleHistory;
