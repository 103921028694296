import { Button, ButtonRow, Col, FieldAutocomplete, FieldDate, FieldList, FieldNumber, FieldPopup, FieldSelect, FieldText, FieldTextTime, Form, Line, Panel, Row, useUpdateEffect } from '@dex/bubl-dash';
import { date, fixedNum, get, hashCode, loop } from '@dex/bubl-helpers';
import React from 'react';
import { getClockTime, getDecimalTime } from '../../../functions/sharedTests';

const CoachingLogCreateEdit: React.FC<any> = (props: any) => {

    const { form, handleSubmit, process, mode } = props;

    const calculateLog = (values) => {

        let total: any = 0;
        let rate = 0;
        let price = get(form.values, `price`, "rm 0");

        if (price.toLowerCase().includes("rm")) {
            price = price.toLowerCase().replace('rm', '');
        }

        loop(values, (row) => {
            total += getDecimalTime(row.hour)
        })

        rate = fixedNum(price / total, 0);
        total = getClockTime(total)

        return { total, rate }
    }

    useUpdateEffect(() => {

        form.handleChange({ reset: true, silent: true, name: `totalHour`, value: calculateLog(get(form.values, `records`)).total })
        form.handleChange({ reset: true, silent: true, name: `earningRate`, value: calculateLog(get(form.values, `records`)).rate })

    }, [hashCode(form.values)]);

    return (

        <Panel
            heading={mode === "create" ? "Add Coaching Log" : "Edit Coaching Log"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row edge gutter={8} >

                    <Col col={{ xs: 8 }}>

                        <FieldAutocomplete
                            form={form}
                            name={`package`}
                            defaultValue={get(form.values, `package`)}
                            group='log_package'
                            options={[
                                { label: "Premium", value: "Premium" },
                                { label: "Basic", value: "Basic" },
                            ]}
                            label="Package"
                            wrapClass={"manual-input"}
                            required
                        />

                    </Col>

                    <Col col={{ xs: 8 }}>

                        <FieldAutocomplete
                            form={form}
                            name={`period`}
                            defaultValue={get(form.values, `period`)}
                            group='log_period'
                            options={[
                                { label: "1 Month", value: "1 Month" },
                                { label: "3 Months", value: "3 Months" },
                                { label: "6 Months", value: "6 Months" },
                            ]}
                            label="Time Period"
                            wrapClass={"manual-input"}
                            required
                        />

                    </Col>

                    <Col col={{ xs: 8 }}>

                        <FieldAutocomplete
                            form={form}
                            name={`price`}
                            defaultValue={get(form.values, `price`)}
                            group='log_price'
                            options={[
                                { label: "RM 1000", value: "RM 1000" },
                                { label: "RM 2000", value: "RM 2000" },
                                { label: "RM 3000", value: "RM 3000" },
                                { label: "RM 4000", value: "RM 4000" },
                                { label: "RM 5000", value: "RM 5000" },
                                { label: "RM 6000", value: "RM 6000" },
                            ]}
                            label="Price Paid"
                            wrapClass={"manual-input"}
                            preload={true}
                            sort={false}
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldDate
                            form={form}
                            name={`startDate`}
                            defaultValue={get(form.values, `startDate`)}
                            label="Start Date"
                            wrapClass={"manual-input"}
                            required
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldDate
                            form={form}
                            name={`endDate`}
                            defaultValue={get(form.values, `endDate`)}
                            label="End Date"
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldTextTime
                            key={get(form.values, `totalHour`)}
                            form={form}
                            name={`totalHour`}
                            defaultValue={get(form.values, `totalHour`)}
                            label="Total Hours"
                            disabled
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldNumber
                            dynamicValue
                            key={get(form.values, `earningRate`)}
                            form={form}
                            name={`earningRate`}
                            defaultValue={get(form.values, `earningRate`)}
                            label="Current Earning Rate"
                            prefix="RM "
                            disabled
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldList
                            form={form}
                            name={`records`}
                            required={false}
                            addButtonLabel={"Add Record"}
                            prepend={true}
                        >

                            {(get(form.values, `records`) || []).map((row, i) => {

                                return (

                                    <Row edge gutter={8} key={i}>

                                        <Col col={{ xs: 4 }}>
                                            <FieldDate
                                                form={form}
                                                name={`records[${i}].date`}
                                                defaultValue={get(form.values, `records[${i}].date`)}
                                                label={!i ? "End Date" : ""}
                                                wrapClass={"manual-input"}
                                            />
                                        </Col>

                                        <Col col={{ xs: 4 }}>
                                            <FieldTextTime
                                                form={form}
                                                name={`records[${i}].hour`}
                                                defaultValue={get(form.values, `records[${i}].hour`)}
                                                label={!i ? "Logged Hour" : ""}
                                                wrapClass={"manual-input"}
                                            />
                                        </Col>

                                        <Col col={{ xs: 16 }}>
                                            <FieldText
                                                form={form}
                                                name={`records[${i}].comment`}
                                                defaultValue={get(form.values, `records[${i}].comment`)}
                                                label={!i ? "Comment" : ""}
                                                wrapClass={"manual-input"}
                                            />
                                        </Col>

                                    </Row>
                                )
                            })}

                        </FieldList>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    )

}

export default CoachingLogCreateEdit;