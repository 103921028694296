import React, { useMemo } from 'react';
import { Box, Col, FieldAutocomplete, FieldList, FieldNumber, FieldRelationship, FieldSelect, Panel, Row, Seperator } from '@dex/bubl-dash';
import FieldSessionsEditTabsPanel from './FieldSessionsEditTabsPanel';
import { getFieldTestCategories, getFieldTestSubCategories } from '../../../functions/categories';

const FieldSessionsEditTabsSelection: React.FC<any> = (props: any) => {

    const { form, data, process, handleSubmit } = props;

    // let categoryOptions = getFieldTestCategories();

    return useMemo(() => (

        <FieldSessionsEditTabsPanel
            heading={"Test Selection"}
            {...props}
        >

            <FieldList
                form={form}
                name={'tests'}
                label={'Tests'}
                required={true}
                addButtonLabel={"Add Test"}
                defaultValues={{ trials: 1 }}
            >

                {(form.values.tests || []).map((test, n) => (

                    <React.Fragment key={form.key + "-" + n}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 4 }}>

                                <FieldAutocomplete
                                    form={form}
                                    name={`tests[${n}].category`}
                                    label={!n ? "Test Category" : ""}
                                    defaultValue={test.category}
                                    group={"FieldTestCategory"}
                                    required
                                    preload
                                    transform={"capitalize"}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 4 }}>

                                <FieldAutocomplete
                                    key={test.category}
                                    form={form}
                                    name={`tests[${n}].subcategory`}
                                    label={!n ? "Test Sub Category" : ""}
                                    defaultValue={test.subcategory}
                                    group={"FieldTestSubCategory." + test.category}
                                    required
                                    preload
                                    transform={"capitalize"}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 6 }}>

                                <FieldRelationship
                                    key={test.category + "-" + test.subcategory}
                                    form={form}
                                    name={`tests[${n}].templateId`}
                                    required={true}
                                    defaultValue={test.templateId}
                                    label={!n ? "Test Name | Units" : ""}
                                    endpoint='FieldTemplates'
                                    preload
                                    where={{ category: test.category, subcategory: test.subcategory }}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 2 }}>

                                <FieldNumber
                                    form={form}
                                    name={`tests[${n}].trials`}
                                    required={true}
                                    defaultValue={test.trials}
                                    min={1}
                                    max={10}
                                    label={!n ? "Trials" : ""}
                                />

                            </Col>

                            {/* <Col col={{ xs: 24, md: 4 }}>

                                <FieldSelect
                                    form={form}
                                    name={`tests[${n}].options`}
                                    defaultValue={test.options}
                                    label={!n ? "Options" : ""}
                                    options={[
                                        { value: "left/right", label: "Left / Right" },
                                    ]}
                                />

                            </Col> */}

                            {/* <Col col={{ xs: 24, md: 4 }}>

                                <FieldSelect
                                    form={form}
                                    name={`tests[${n}].calc`}
                                    defaultValue={test.calc}
                                    label={!n ? "Calculation" : ""}
                                    info={"How to calculate score when multiple trials are needed."}
                                    options={[
                                        { value: "min", label: "Min" },
                                        { value: "max", label: "Max" },
                                        { value: "avg", label: "Avg" },
                                        { value: "sum", label: "Sum" },
                                    ]}
                                />

                            </Col> */}

                        </Row>

                    </React.Fragment>

                ))}

            </FieldList>

        </FieldSessionsEditTabsPanel>


    ), [props]);

}

export default FieldSessionsEditTabsSelection;
