import { Row, Col, Seperator, FieldNumber } from '@dex/bubl-dash';
import { fixedNum, get } from '@dex/bubl-helpers';
import React, { useEffect, useMemo } from 'react';
import { getMinutesTime } from '../../../functions/sharedTests';
import { getEnergyBurn } from '../../../functions/sweatTest';

const LabTestsSweatWorkoutBody: React.FC<any> = (props: any) => {

    const { form, data, prefix, workout, session } = props;

    const key = props._key;

    const preWeight = parseFloat(get(form.values, `${prefix}.preWeight`));
    const postWeight = parseFloat(get(form.values, `${prefix}.postWeight`));
    const fluidIntake = parseFloat(get(form.values, `${prefix}.fluidIntake`));
    const foodIntake = parseFloat(get(form.values, `${prefix}.foodIntake`));
    const workoutDuration = get(form.values, `${prefix}.duration`);
    const workoutDurationMinute = getMinutesTime(workoutDuration);
    const vo2Estimate = parseFloat(get(form.values, `${prefix}.estimateVo2Absolute`));
    const RER = parseFloat(get(form.values, `${prefix}.rerEstimated`));
    const urineLoss = parseFloat(get(form.values, `${prefix}.urineLoss`))

    const getWeightLoss = fixedNum(preWeight - postWeight, 1);
    const getPercentWeightLoss = fixedNum((getWeightLoss / preWeight) * 100, 1) || 0;
    const getFluidRate = fixedNum(fluidIntake / workoutDurationMinute * 60, 0);
    const getRespiratoryLoss = fixedNum(0.019 * (vo2Estimate * 35.5 * workoutDurationMinute), 0);

    const getTotalFluidLoss = (((getWeightLoss * 1000) + fluidIntake) - getEnergyBurn(RER, vo2Estimate, workoutDurationMinute));
    const getfluidLossRate = fixedNum(getTotalFluidLoss / workoutDurationMinute * 60, 0);

    const getTotalSweatLoss = fixedNum(getTotalFluidLoss - (urineLoss + getRespiratoryLoss), 0);
    const getSweatLossRate = fixedNum(getTotalSweatLoss / workoutDurationMinute * 60, 0);

    const getRehydration = fixedNum((fluidIntake / getTotalFluidLoss) * 100, 0);

    useEffect(() => {
        form.handleChange({ silent: true, name: `${prefix}.weightloss`, value: getWeightLoss });
        form.handleChange({ silent: true, name: `${prefix}.rehydration`, value: getRehydration });
        form.handleChange({ silent: true, name: `${prefix}.fluidIntakeRate`, value: getFluidRate });
        form.handleChange({ silent: true, name: `${prefix}.sweatLossRate`, value: getSweatLossRate });
        form.handleChange({ silent: true, name: `${prefix}.totalFluidLoss`, value: getTotalFluidLoss });
        form.handleChange({ silent: true, name: `${prefix}.totalSweatLoss`, value: getTotalSweatLoss });
        form.handleChange({ silent: true, name: `${prefix}.fluidLossRate`, value: getfluidLossRate });
        form.handleChange({ silent: true, name: `${prefix}.respiratoryLoss`, value: getRespiratoryLoss });
        form.handleChange({ silent: true, name: `${prefix}.energyBurn`, value: getEnergyBurn(RER, vo2Estimate, workoutDurationMinute) });
        form.handleChange({ silent: true, name: `${prefix}.height`, value: get(session, "bio.height") });

    }, [getWeightLoss, getRehydration, getFluidRate, getSweatLossRate, getTotalFluidLoss, getfluidLossRate, getTotalSweatLoss, getRespiratoryLoss, getEnergyBurn(RER, vo2Estimate, workoutDurationMinute)])

    return useMemo(() => (

        <>

            <Row gutter={8} edge>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Pre-Weight'
                        name={`${prefix}.preWeight`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.preWeight}
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Post-Weight'
                        name={`${prefix}.postWeight`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.postWeight}
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Weight Loss'
                        name={`${prefix}.weightloss`}
                        defaultValue={getWeightLoss}
                        suffix={` kg / ${getPercentWeightLoss}%`}
                        key={getWeightLoss}
                        disabled
                    />

                </Col>

            </Row>

            <Seperator
                heading={"Intake Details"}
                top={"auto"}
                bottom={"auto"}
            />

            <Row gutter={8} edge>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Fluid Intake'
                        name={`${prefix}.fluidIntake`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.fluidIntake}
                        suffix={" ml"}
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Solid Food Intake'
                        name={`${prefix}.foodIntake`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.foodIntake}
                        suffix={' g'}
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Fluid Intake Rate'
                        name={`${prefix}.fluidIntakeRate`}
                        defaultValue={getFluidRate}
                        suffix={' ml/hr'}
                        key={getFluidRate}
                        disabled
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Rehydration'
                        name={`${prefix}.rehydration`}
                        defaultValue={getRehydration}
                        suffix={` %`}
                        key={getRehydration}
                        disabled
                    />

                </Col>

            </Row>

            <Seperator
                heading={"Loss Details"}
                top={"auto"}
                bottom={"auto"}
            />

            <Row gutter={8} edge>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Urine Loss'
                        name={`${prefix}.urineLoss`}
                        wrapClass={"manual-input"}
                        defaultValue={workout.urineLoss}
                        suffix=" ml"
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Respiratory Loss'
                        name={`${prefix}.respiratoryLoss`}
                        defaultValue={getRespiratoryLoss}
                        suffix={` ml`}
                        key={getRespiratoryLoss}
                        disabled
                        info="Reference
Mitchell JW, Nadel ER, Stolwijk JA. Respiratory weight losses during exercise. J Appl Physiol 32: 474 – 476, 1972."
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Total Fluid Loss'
                        name={`${prefix}.totalFluidLoss`}
                        defaultValue={getTotalFluidLoss}
                        suffix={` ml`}
                        key={getTotalFluidLoss}
                        disabled
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Fluid Loss Rate'
                        name={`${prefix}.fluidLossRate`}
                        defaultValue={getfluidLossRate}
                        suffix={` ml/hr`}
                        key={getfluidLossRate}
                        disabled
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Energy Burn'
                        name={`${prefix}.energyBurn`}
                        defaultValue={getEnergyBurn(RER, vo2Estimate, workoutDurationMinute)}
                        suffix={` g`}
                        key={getEnergyBurn(RER, vo2Estimate, workoutDurationMinute)}
                        disabled
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Total Sweat Loss'
                        name={`${prefix}.totalSweatLoss`}
                        defaultValue={getTotalSweatLoss}
                        suffix={` ml`}
                        key={getTotalSweatLoss}
                        disabled
                    />

                </Col>

                <Col col={{ xs: 24, md: 6 }}>

                    <FieldNumber
                        form={form}
                        label='Sweat Loss Rate'
                        name={`${prefix}.sweatLossRate`}
                        defaultValue={getSweatLossRate}
                        suffix={` ml/hr`}
                        key={getSweatLossRate}
                        disabled
                    />

                </Col>


            </Row>

        </>

    ), [form.hash, data, session]);

}

export default LabTestsSweatWorkoutBody;
