import React from 'react';
import { Panel } from '@dex/bubl-dash';
import { formatNum, get } from '@dex/bubl-helpers';
import { getRandomInt } from '../../functions/sharedGeneral';
import { stepsMinMax, stepLabels, arrowPosition, DisplayAs } from './RatingScaleElements';
import { sumData, normalData } from './RatingWellness'

import styles from "./SimpleRatingTable.module.scss";

const SimpleRatingTable: React.FC<SimpleRatingTableProps> = (props) => {

    let { title, rows, footer } = props;

    return (

        <>

            <Panel full={true} heading={title} className={styles.panel}>

                <div className={styles.inner}>

                    <div className={styles.rows}>

                        {rows.map((row, index) => (

                            <SimpleRatingTableRow
                                key={index}
                                demographic={"untrained"}
                                gender={"male"}
                                metric={row.metric}
                                value={row.value}
                                index={index}
                            />

                        ))}

                    </div>

                    {footer &&
                        <SimpleRatingTableRow
                            demographic={"untrained"}
                            gender={"male"}
                            metric={footer.metric}
                            value={footer.value}
                            classname={styles.footer}
                            rating={footer.rating}
                        />
                    }

                </div>

            </Panel>

        </>

    )

}

const SimpleRatingTableRow: React.FC<SimpleRatingTableProps> = (props) => {

    let { metric, value, demographic, gender, classname, rating } = props;

    const { steps, min, max }: any = stepsMinMax(metric == "Overall Score" ? sumData : normalData, demographic, gender);

    value = (props.value === "rand") ? getRandomInt(min, max) : parseFloat(value);

    const labels = stepLabels(steps);

    const left = arrowPosition(value, min, max);

    const rangeSteps = metric == "Overall Score" ? [{ num: 5 }, { num: 10 }, { num: 15 }, { num: 20 }, { num: 25 }] : [{ num: 2 }, { num: 3 }, { num: 4 }, { num: 5 }];

    return (

        <>

            <div className={classname ? classname : styles.row + " row"}>

                <div className={styles.cols}>

                    <div className={styles.metric}>
                        {metric}:
                        {rating &&
                            <div className={styles.rating}>{rating}</div>
                        }
                    </div>

                    <div className={styles.measurement}>
                        {value ? formatNum(value) : "-"}
                    </div>

                    <div className={styles.displayShow}>

                        <DisplayAs
                            left={left}
                            labels={labels}
                            steps={steps}
                            data={normalData || sumData}
                            min={min}
                            max={max}
                            demographic={demographic}
                            gender={gender}
                            rangeSteps={rangeSteps}
                        />

                    </div>

                </div>

            </div>

        </>

    )

};

interface SimpleRatingTableProps {
    [key: string]: any,
}

export default SimpleRatingTable;