import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Button, FieldRadio, FieldSelect, MetaTable, Panel, Seperator, Space } from '@dex/bubl-dash';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import EventTargetCard from '../../../elements/EventTargetCard/EventTargetCard';
import _ from 'lodash';
import { get } from '@dex/bubl-helpers';

const EventTargetsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [gender, setGender] = useState("male");

    const form = useForm({ activity: "run", level: "pro" });

    const arrayActivity = Object.keys(data.steps.male)
    const activity = _.sample(arrayActivity)

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Event Target Details"}
                    actions={
                        <Button
                            label={"Copy To New Scale"}
                            type={"faded"}
                            path={`/lab/event-targets/create?cloneId=${data.id}`}
                        />
                    }
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Event Type",
                                key: "eventType",
                                format: "titleCase",
                            },
                            {
                                label: "Metric",
                                key: "metric",
                            },
                            {
                                label: "Title",
                                key: "title",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Unit",
                                key: "suffix",
                            },
                            {
                                label: "Display As",
                                key: "displayAs",
                                format: "titleCase",
                            },
                            {
                                label: "Best Score",
                                key: "bestScore",
                                format: "titleCase",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Decription",
                                key: "description",
                            },
                            {
                                label: "Source",
                                key: "source",
                            },
                        ]}
                    />

                    <Seperator
                        heading={"Preview"}
                        top={"auto"}
                        bottom={"auto"}
                        activeTab={gender}
                        setTab={setGender}
                        tabs={[
                            { name: "male", label: "Male" },
                            { name: "female", label: "Female" },
                        ]}
                    />


                    <Row
                        gutter={'auto'}
                        edge
                    >

                        <Col
                            col={{ xs: 24, md: 8 }}
                        >

                            <FieldRadio
                                form={form}
                                name='activity'
                                defaultValue={get(form.defaults, "activity")}
                                options={[
                                    { value: "swim", label: "Swim" },
                                    { value: "bike", label: "Bike" },
                                    { value: "run", label: "Run" },
                                ]}
                                label='Activity'
                                button
                            />

                        </Col>

                        <Col
                            col={{ xs: 24, md: 8 }}
                        >

                            <FieldRadio
                                form={form}
                                name='level'
                                defaultValue={get(form.defaults, "level")}
                                options={[
                                    { value: "recreational", label: "Recreational" },
                                    { value: "competitive", label: "Competitive" },
                                    { value: "pro", label: "Pro" },
                                ]}
                                label='Level'
                                button
                            />

                        </Col>

                    </Row>

                    <Space />

                    <EventTargetCard
                        data={data}
                        values={"rand"}
                        gender={gender}
                        id={data.id}
                        metric={data.metric}
                        eventType={data.eventType}
                        activity={form.values.activity}
                        level={form.values.level}
                    />

                    {/* <EventTargetCard
                        metric={"%FTP"}
                        gender={session.athlete.gender}
                        level={form.values?.manual?.level}
                        activity={activity}
                        eventType={form.values?.manual?.type}
                        values={form.values?.result?.ftpCurrent || []}

                    /> */}

                </Panel>

            </Col>

        </Row>

    ), [data, gender, form.values]);

}

export default EventTargetsSingleView;
