import { Box, Button, Modal, Panel, Space, useApp, useForm } from '@dex/bubl-dash';
import React, { useCallback, useState } from 'react';
import CoachingReportsTable from '../Reports/CoachingReportsTable';
import CoachingReportsForm from '../Reports/CoachingReportsForm';
import CoachingReportsApi from '../../CoachingReports/CoachingReportsApi';

const CoachingEditTabsReport: React.FC<any> = (props: any) => {

    const { data } = props;

    const [showForm, setShowForm] = useState(false);

    const form = useForm({ fixedWeek: true });

    const app = useApp()

    const process = CoachingReportsApi.create();

    const handleSubmit = useCallback((values) => {

        values.coachingId = data.id;

        process.run({
            data: values,
            onComplete: (data) => {

                app.alert('Report Saved', 'success');

                setShowForm(false);

                form.reset({ fixedWeek: true });

            }
        });

    }, [props]);

    return (

        <Box gutter={"auto"}>

            <Panel
                heading={"Reports"}
                actions={
                    <Button
                        label={"Add New Report"}
                        onClick={setShowForm.bind(null, true)}
                    />
                }
                full={true}
            >

                <Space />

                {!showForm &&
                    <CoachingReportsTable
                        data={data}
                    />
                }

                {showForm &&

                    <Modal
                        show={showForm}
                        onClose={setShowForm.bind(null, false)}
                        width={1080}
                    >

                        <CoachingReportsForm
                            form={form}
                            process={process}
                            handleSubmit={handleSubmit}
                            mode={"create"}
                            data={data}
                        />

                    </Modal>
                }

            </Panel>

        </Box>

    )
}

export default CoachingEditTabsReport;