import { Row, Col, Space, useUpdateEffect } from "@dex/bubl-dash";
import { get } from "@dex/bubl-helpers";
import React, { useEffect } from "react";
import { withinRange } from "../../functions/raceReadyTest";
import styles from "./RaceReadySideTable.module.scss";

const RaceReadySideTable: React.FC<any> = (props: any) => {

    const { avgData, option, data, activity, targets, activeTab } = props;

    const sum = data.test?.result?.sum || {};

    const gender = data.athlete.gender || "male";

    const getEffort = (item, index) => {

        if (item == "Breathing") {
            return activeTab == "brpm" ? sum?.beRate[index] + " brpm" : sum?.beVolume[index] + " L"
        } else if (item == "Power") {
            return activeTab == "w" ? sum?.ftp[index] + " W" : sum?.ftpKg[index] + " W/kg"
        } else if (item == "Speed") {
            return activeTab == "speed" ? sum?.ftp[index] + " kph" : sum?.pace[index] + " min/km"
        } else if (item == "SMO2") {
            return sum?.smo2[index] + " %"
        } else if (item == "VO2") {
            return activeTab == "vo2relative" ? sum?.vo2relative[index] + " ml/kg*min" : sum?.vo2absolute[index] + " l/min"
        } else if (item == "Energy") {
            return activeTab == "kcal/hr" ? sum?.carbBurnKcal[index] + " kcal/hr" : sum?.carbBurnG[index] + " g/hr"
        } else if (item == "HR") {
            return sum?.hr[index] + " bpm"
        } else if (item == "RPE") {
            return sum?.rpe[index]
        } else if (item == "Lactate") {
            return activeTab == "mmol/L/min" ? sum?.vla[index] + " mmol/L/min" : sum?.lactate[index] + " mmol/L"
        } else if (item == "Stride") {
            return activeTab == "per min" ? sum?.strideFrequency[index] + " strides/min" : sum?.strideLength[index] + " m/strides"
        } else {
            return ""
        }

    };

    const getResult = (item, index) => {

        if (item == "Breathing") {
            return {
                "metric": "Breathing",
                "value": `beRate[${index}]`
            }
        } else if (item == "Stride") {
            return {
                "metric": "Stride Frequency",
                "value": `strideFrequency[${index}]`
            }
        } else if (item == "vla") {
            return {
                "metric": "Lactate VLA",
                "value": `vla[${index}]`
            }
        } else if (item == "RPE") {
            return {
                "metric": "RPE",
                "value": `rpe[${index}]`
            }
        } else if (item == "Lactate") {
            return {
                "metric": "Lactate",
                "value": `lactate[${index}]`,
            }
        } else if (item == "Power" || item == "Speed") {
            return {
                "metric": "%FTP",
                "value": `ftp[${index}]`,
                "ftp": `ftpCurrent[${index}]`
            }
        } else if (item == "VO2") {
            return {
                "metric": "%VO2max",
                "value": `vo2relative[${index}]`,
                "ftp": `vo2maxVo2Current[${index}]`
            }
        } else if (item == "HR") {
            return {
                "metric": "%HRmax",
                "value": `hr[${index}]`,
                "ftp": `vo2maxHrCurrent[${index}]`
            }
        } else {
            return {
                "metric": "",
                "value": "",
                "ftp": ""
            }
        }

    };

    return (

        <Row edge gutter={8}>

            {avgData.map((avg, avgIndex) => {

                const result = getResult(option.label, avgIndex);
                let target: any;

                if (result.ftp) {
                    target = withinRange(result.metric, get(data.test.result, `${result.ftp}`), targets, gender, activity, data.test);
                } else {
                    target = withinRange(result.metric, get(data.test.result, `sum.${result.value}`), targets, gender, activity, data.test);

                }

                return (

                    <Col col={{ xs: 24 / avgData.length }} key={avgIndex}>

                        <Row edge>

                            <Col col={{ xs: 24 }} align="center">
                                <p className={[styles.subHeading, styles["subHeading-" + avgIndex]].join(" ")}>
                                    <strong>Effort {avgIndex + 1}</strong>
                                </p>
                            </Col>

                            <Col col={{ xs: 24 }} align="center" justify="center">
                                <strong className={[styles.heading, styles["heading-" + avgIndex]].join(" ")}>
                                    {getEffort(option.label, avgIndex)}
                                </strong>
                            </Col>

                            {result.ftp &&
                                <Col col={{ xs: 24 }} align="center">
                                    <span className={styles.text}>
                                        {get(data.test.result, result.ftp)} {option.label == "Power" || option.label == "Speed" ? " % FTP" : " % Max"}
                                    </span>
                                </Col>
                            }

                            <Col col={{ xs: 24 }} align="center">
                                <Space />
                                {target}
                            </Col>

                        </Row>

                    </Col>

                )
            })}

            <Col col={{ xs: 24 }} align="center">
                <p className={styles.heading}><strong>Target</strong></p>
                <p className={styles.text}>{get(data.test.result?.target, `${option.label}`, "-")}</p>
            </Col>

        </Row>
    )
}

export default RaceReadySideTable;