import TeamsIndex from './TeamsIndex';
import TeamsCreate from './TeamsCreate';
import TeamsSettings from './TeamsSettings';
import TeamsSingle from './TeamsSingle';

export default function (app) {

    const settings = TeamsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 3,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }
        ],
    });

    TeamsIndex(app, settings);

    TeamsCreate(app, settings);

    TeamsSingle(app, settings);

}