import React, { useMemo, useCallback, useState, useEffect, Suspense } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import LabTestsApi from '../LabTestsApi';
import { Button, ButtonRow, Col, FieldHidden, Line, Row, Seperator, Space } from '@dex/bubl-dash';
import TestSessionsApi from '../../TestSessions/TestSessionsApi';
import LabTestsSweat from '../Sweat/LabTestsSweat';
import LabTestsGlycogen from '../Glycogen/LabTestsGlycogen';
import LabTestsRaceReady from '../RaceReady/LabTestsRaceReady';
import { loop } from '@dex/bubl-helpers';
import LabTestsSprint from '../Sprint/LabTestsSprint';

const LabTestsVo2Max = React.lazy( () => import( '../Vo2Max/LabTestsVo2Max' ) );
const LabTestsRMR = React.lazy( () => import( '../RMR/LabTestsRMR' ) );

const LabTestsSingleEdit: React.FC<any> = ( props: any ) => {

    const { id, session, save, setSave } = props;

    const labTest = LabTestsApi.getOne( id );

    useEffect( () => {

        labTest.run();

    }, [ id ] );

    return useMemo( () => (

        <>

            {labTest.data &&
                <LabTestsSingleEditForm
                    {...props}
                    data={labTest.data}
                />
            }

        </>

    ), [ labTest.loading, save, setSave ] );

}

const LabTestsSingleEditForm: React.FC<any> = ( props: any ) => {

    const { data, session, save, setSave, editable } = props;

    const app = useApp();

    const [ nav ] = app.store( "nav" );

    const [ testTab, setTestTab ] = app.store( "lab-test-tab", null );

    const [ key, setKey ] = useState( 0 );

    const form = useForm( { ...data, athlete: session.athlete } );

    const trash = TestSessionsApi.update( session.id, "removeLabTest", {
        onComplete: ( data ) => {

            setSave( false );

            setTestTab( null );

            app.alert( 'Lab Test Removed', 'success' );

            app.navigate( nav, 'update', {} );

        }
    } );

    const update = LabTestsApi.update( data.id, "update", {
        onComplete: ( data ) => {

            setSave( false );

            app.alert( 'Lab Test Saved', 'success' );

            // refresh page
            // app.navigate(nav, 'update', {});

        }
    } );

    const handleSubmit = useCallback( ( values ) => {

        update.run( {
            data: values,
        } );

    }, [ props ] );

    const increaseKey = useCallback( () => {

        setKey( ( key ) => key + 1 );

    }, [] );

    useEffect( () => {

        if ( save ) form.triggerSubmit();

    }, [ save ] );

    const handleTrash = useCallback( () => {

        trash.run( {
            data: { id: data.id }
        } );

    }, [] );

    const [ heading ] = useState( () => [ data.type, data.activity, data.intensity ].filter( Boolean ).join( ", " ) );

    let tabs: any = [
        { name: "import", label: "Import" },
        { name: "manual", label: "Manual Data" },
        { name: "equipment", label: "Equipment" },
        { name: "vo2", label: "VO2" },
        { name: "hr", label: "HR" },
        { name: "breathing", label: "Breathing" },
        { name: "muscle", label: "Muscle" },
        { name: "lactate", label: "Lactate" },
        { name: "energy", label: "Energy" },
        { name: "performance", label: "Performance" },
        { name: "training", label: "Zones" },
        { name: "analysis", label: "Analysis" },
        { name: "time-charts", label: "Time Charts" },
    ];

    if ( data.type === "Race Ready" ) {

        tabs = [
            { name: "import", label: "Import" },
            { name: "manual", label: "Manual Data" },
            { name: "equipment", label: "Equipment" },
            { name: "result", label: "Result" },
            { name: "rating", label: "Rating" },
            { name: "comments", label: "Appraiser's Summary" },
        ]

    } else if ( data.type === "RMR" ) {

        tabs = [
            { name: "import", label: "Import" },
            { name: "manual", label: "Manual Data" },
            { name: "equipment", label: "Equipment" },
            { name: "rmr", label: "RMR" },
        ]

    } else if ( data.type === "Sweat Test" ) {

        tabs = [
            { name: "workouts", label: "Workouts" },
            { name: "currentMax", label: "Current Max" },
            { name: "fluid", label: "Fluid/Salt Rates" },
            { name: "rehydration", label: "Rehydration" },
            { name: "dehydration", label: "Dehydration" },
            { name: "comments", label: "Comments" },
        ];

    } else if ( data.type === "Glycogen Depletion" ) {

        tabs = [
            { name: "manual", label: "Manual Data" },
        ];

    } else if ( data.type === "Sprint" ) {

        tabs = [
            { name: "import", label: "Import" },
            { name: "manual", label: "Manual Data" },
            { name: "equipment", label: "Equipment" },
            { name: "hr", label: "HR" },
            { name: "muscle", label: "Muscle" },
            { name: "lactate", label: "Lactate" },
            { name: "performance", label: "Performance" },
        ];

    }

    const [ tab, setTab ] = app.store( "lab-test-subtab", tabs[ 0 ].name );

    useEffect( () => {

        if ( !tabs.length ) return;

        let hasActiveTab = false;

        loop( tabs, ( item ) => {

            if ( tab === item.name ) hasActiveTab = true;

        } )

        if ( !hasActiveTab ) setTab( tabs[ 0 ].name );

    }, [ tabs ] );

    return useMemo( () => (

        <>

            <Seperator
                heading={heading}
                activeTab={tab || "-"}
                setTab={( x ) => { setTab( x ) }}
                tabs={tabs}
            />

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={update.loading}
            >

                <FieldHidden
                    name={'rawData'}
                    value={form.values.rawData}
                    form={form}
                />
                <FieldHidden
                    name={'sumRawData'}
                    value={form.values.sumRawData}
                    form={form}
                />
                <FieldHidden
                    name={'avgData'}
                    value={form.values.avgData}
                    form={form}
                />
                <FieldHidden
                    name={'effortData'}
                    value={form.values.effortData}
                    form={form}
                />
                <FieldHidden
                    name={'sumData'}
                    value={form.values.sumData}
                    form={form}
                />
                <FieldHidden
                    name={'sumAvgData'}
                    value={form.values.sumAvgData}
                    form={form}
                />
                <FieldHidden
                    name={'rollingData'}
                    value={form.values.rollingData}
                    form={form}
                />
                <FieldHidden
                    name={'duration'}
                    value={form.values.duration}
                    form={form}
                />


                <Space />

                <Suspense fallback={<></>}>

                    {( data.type === "VO2max" || data.type === "MET" || data.type === "Performance" ) &&
                        <LabTestsVo2Max
                            form={form}
                            data={data}
                            session={session}
                            tab={tab}
                            _key={key}
                            setKey={increaseKey}
                            editable={editable}
                        />
                    }

                    {( data.type === "Race Ready" ) &&
                        <LabTestsRaceReady
                            form={form}
                            data={data}
                            session={session}
                            tab={tab}
                            _key={key}
                            setKey={increaseKey}
                            editable={editable}
                        />
                    }

                    {( data.type === "RMR" ) &&
                        <LabTestsRMR
                            form={form}
                            data={data}
                            session={session}
                            tab={tab}
                            _key={key}
                            setKey={increaseKey}
                            editable={editable}
                        />

                    }

                    {( data.type === "Sweat Test" ) &&
                        <LabTestsSweat
                            form={form}
                            data={data}
                            session={session}
                            tab={tab}
                            _key={key}
                            setKey={increaseKey}
                            editable={editable}
                        />
                    }

                    {( data.type === "Glycogen Depletion" ) &&
                        <LabTestsGlycogen
                            form={form}
                            data={data}
                            session={session}
                            tab={tab}
                            _key={key}
                            setKey={increaseKey}
                            editable={editable}
                        />
                    }

                    {( data.type === "Sprint" ) &&
                        <LabTestsSprint
                            form={form}
                            data={data}
                            session={session}
                            tab={tab}
                            _key={key}
                            setKey={increaseKey}
                            editable={editable}
                        />
                    }

                </Suspense>

                {editable &&
                    <>
                        <Line />

                        <Row>

                            <Col col={{ xs: 24, sm: 16 }}>

                                <ButtonRow>
                                    <Button
                                        label={"Save"}
                                        // onPress={form.handleSubmit}
                                        loading={update.loading}
                                    />
                                    <Button
                                        label={"Reset"}
                                        type={"faded"}
                                        onClick={form.reset}
                                    />
                                </ButtonRow>

                            </Col>
                            <Col col={{ xs: 24, sm: 8 }} align={"end"}>

                                <ButtonRow>
                                    <Button
                                        label={"Remove Lab Test"}
                                        type={"light"}
                                        onClick={handleTrash}
                                        loading={trash.loading}
                                        confirm={{
                                            title: "Are you sure you want continue?",
                                            message: "This data cannot be recovered once removed.",
                                        }}
                                    />
                                </ButtonRow>

                            </Col>

                        </Row>
                    </>
                }

            </Form>

        </>

    ), [ data, form.hash, update.loading, tab, key, trash.loading ] );

}

export default LabTestsSingleEdit;
