import { Col, FieldRelationship, Row, useMountEffect } from '@dex/bubl-dash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CoachingTrainingZonesApi from '../../CoachingTrainingZones/CoachingTrainingZonesApi';
import CoachingTrainingZoneTable, { CoachingTrainingZoneMiniTable } from '../../../elements/CoachingTrainingZoneTable/CoachingTrainingZoneTable';

const CoachingTrainingsWeekTrainingZoneTab: React.FC<any> = (props: any) => {

    const { form, data, week, weeks, block, mode } = props;

    const id = form.values.trainingZone?.zone;

    const getZone: any = CoachingTrainingZonesApi.getOne(":id");

    const [zone, setzone]: any = useState(null);

    useEffect(() => {

        if (!id) return setzone(null);

        getZone.run({
            url: getZone.options.url.replace(":id", id),
            onComplete: (response) => {
                setzone(response);
            }
        });

    }, [id]);

    useMountEffect(() => {

        if (!form.values.trainingZone?.swim) form.handleChange({ name: "trainingZone.swim", value: "pace", reset: true });
        if (!form.values.trainingZone?.run) form.handleChange({ name: "trainingZone.run", value: "pace", reset: true });

        if (!form.values.trainingZone?.zone || mode == "create") form.handleChange({ name: "trainingZone.zone", value: data?.activeZoneId, reset: true });

    });

    return (

        <>

            <Row
                gutter={16}
                edge
            >

                <Col col={{ xs: 8 }}>

                    <FieldRelationship
                        form={form}
                        label="Current Training Zone"
                        name="trainingZone.zone"
                        defaultValue={form.defaults.trainingZone?.zone}
                        preload={true}
                        endpoint='coachingTrainingZones'
                        where={{ coachingId: data.id }}
                        wrapClass={"manual-input"}
                    />

                </Col>

                {zone &&

                    <>

                        <CoachingTrainingZoneMiniTable
                            zone={zone}
                            data={data}
                        />

                        <CoachingTrainingZoneTable
                            form={form}
                            zone={zone}
                        />

                    </>

                }

            </Row>

        </>

    );

}

export default CoachingTrainingsWeekTrainingZoneTab
