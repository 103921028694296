import { Col, DataTable, FieldHidden, FieldSwitch, FieldTextArea, Row, Seperator, useUpdateEffect } from '@dex/bubl-dash';
import React, { useEffect, useMemo } from 'react';
import { fixedNum, get, hashCode, loop } from '@dex/bubl-helpers';
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import * as _ from "lodash"
import styles from "./LabTestsSwat.module.scss"
import { calculateMinMaxAvgValues, getEnabled } from '../../../functions/sweatTest';
import SweatTestAverageChart from '../../../elements/SweatTestAverageChart/SweatTestAverageChart';

const LabTestsSweatFluid: React.FC<LabTestsSweatFluidProps> = (props) => {

    const { form, data, session } = props;

    const workoutData = get(form.values, "workouts");

    const columns = [
        {
            label: "Select",
            key: 'enabled',
            render: (value, data) => {
                return (
                    <FieldSwitch
                        form={form}
                        name={`workouts[${data.__index}].enabled`}
                        defaultValue={get(form.values, `workouts[${data.__index}].enabled`)}
                    />
                )
            },
        },
        {
            label: "Workout",
            key: "__num"
        },
        {
            label: "Date",
            key: "date",
            format: "date"
        },
        {
            label: "Activity",
            key: "activity",
            format: "titleCase"
        },
        {
            label: "Location",
            key: "location",
            format: "titleCase"
        },
        {
            label: "Temp",
            key: "temperature",
            format: "titleCase"
        },
        {
            label: "Duration",
            key: "duration",
        },
        {
            label: "Intensity",
            key: "intensity",
            format: "titleCase"
        },
        {
            label: "Fluid Loss Rate",
            key: "fluidLossRate",
        },
        {
            label: "Sodium Loss Rate",
            key: "sodiumLossRate",
        },
    ];

    const rowEnabledClass = (row, index) => {

        const enabled = get(form.values, `workouts[${row.__index}].enabled`);

        return enabled ? "table-bg-green" : "";

    };

    // const enabled = get(form.values, "fluid.enabled")
    const allWordouts = get(form.values, "workouts");
    const enabledWorkouts = getEnabled(allWordouts);

    const separatedData = enabledWorkouts?.reduce((acc, data) => {

        const { type } = data;

        if (!acc[type]) {
            acc[type] = [];
        }

        acc[type].push(data);

        return acc;

    }, {});

    const calculateAvg = arr => {

        const sum = arr?.reduce((total, value) => total + value, 0);
        return fixedNum(sum / arr.length, 0);

    };

    const activityTypes = Object.keys(separatedData || []);

    const totalSodiumLossArray = activityTypes.map(type => {
        const activityData = separatedData[type];
        return {
            type,
            ...calculateMinMaxAvgValues(activityData, 'sodiumLossRate')
        };
    });

    const totalFluidLossArray = activityTypes.map(type => {
        const activityData = separatedData[type];
        return {
            type,
            ...calculateMinMaxAvgValues(activityData, 'fluidLossRate')
        };
    });

    //@ts-ignore
    const sodiumLossValues = enabledWorkouts.map(data => data["sodiumLossRate"]);
    //@ts-ignore
    const fluidLossValues = enabledWorkouts.map(data => data["fluidLossRate"]);

    const totalAvgSodiumLoss = calculateAvg(sodiumLossValues);
    const totalAvgFluidLoss = calculateAvg(fluidLossValues);

    const fluidData = [
        {},
        ...totalFluidLossArray,
        totalFluidLossArray.length > 1 ?
            { avg: totalAvgFluidLoss, type: "Average" }
            :
            null
    ].filter(Boolean)

    const sodiumData = [
        {},
        ...totalSodiumLossArray,
        totalSodiumLossArray.length > 1 ?
            { avg: totalAvgSodiumLoss, type: "Average" }
            :
            null
    ].filter(Boolean);

    useUpdateEffect(() => {

        form.handleChange({ silent: true, name: `fluid.avgSodium`, value: totalSodiumLossArray });
        form.handleChange({ silent: true, name: `fluid.avgFluid`, value: totalFluidLossArray });
        form.handleChange({ silent: true, name: `fluid.sodiumLossRating`, value: totalAvgSodiumLoss });
        form.handleChange({ silent: true, name: `fluid.fluidLossRating`, value: totalAvgFluidLoss });
        form.handleChange({ silent: true, name: `fluid.enabledData`, value: separatedData });

    }, [totalFluidLossArray, totalSodiumLossArray, totalAvgFluidLoss, totalAvgSodiumLoss, separatedData]);

    return useMemo(() => (

        <>

            <FieldHidden
                form={form}
                name={"fluid.avgSodium"}
                value={form.defaults.totalSodiumLossArray}
            />

            <FieldHidden
                form={form}
                name={"fluid.avgFluid"}
                value={form.defaults.totalSodiumLossArray}
            />

            <FieldHidden
                form={form}
                name={"fluid.enabledData"}
                value={form.defaults.enabledData || []}
            />

            <FieldHidden
                form={form}
                name={"fluid.sodiumLossRating"}
                value={form.defaults.sodiumLossRating}
            />

            <FieldHidden
                form={form}
                name={"fluid.fluidLossRating"}
                value={form.defaults.fluidLossRating}
            />

            <Row gutter={20} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <DataTable
                        columns={columns}
                        data={workoutData}
                        rowClassName={rowEnabledClass}
                        className={"input-table"}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }} justify='center'>

                    <SweatTestAverageChart
                        data={fluidData}
                        suffix="ml/hr"
                        title="Your Fluid Loss Rate"
                        img={true}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        form={form}
                        metric={'Fluid Loss Rating'}
                        value={totalAvgFluidLoss}
                        name="fluid.fluidLossRatingScale"
                        demographic={get(form.values, 'fluid.fluidLossRatingScale', session.demographic)}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldTextArea
                        form={form}
                        name="fluid.fluidDescription"
                        label='Fluid Loss Description'
                        defaultValue={form.defaults.fluid.fluidDescription}
                    />

                </Col>

            </Row>

            <Seperator
                heading={"Sodium Loss Rate"}
                top={"auto"}
                bottom={"auto"}
            />

            <Row gutter={20} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <SweatTestAverageChart
                        data={sodiumData}
                        suffix="mg/hr"
                        title="Your Sodium Loss Rate"
                        img={true}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={'Sodium Loss Rating'}
                        value={totalAvgSodiumLoss}
                        form={form}
                        name="fluid.sodiumLossRatingScale"
                        demographic={get(form.values, 'fluid.sodiumLossRatingScale', session.demographic)}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldTextArea
                        form={form}
                        name="fluid.sodiumDescription"
                        label='Sodium Loss Description'
                        defaultValue={form.defaults.fluid.sodiumDescription}
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, data, session])

}

interface LabTestsSweatFluidProps {
    [key: string]: any,
}

export default LabTestsSweatFluid;